import {
  GET_COMPONENT_JSON,
  GET_COMPONENT_JSON_SUCCESS,
  GET_COMPONENT_JSON_FAIL,
  RESET_COMPONENT_JSON,
} from "panel/src/redux/constants/getComponentJSON";

export interface IHomeData {
  componentsData: {
    ComponentId: number;
    DisplayOrder: number;
    JSON: string | null;
    PageId: number;
  }[];
  initialized: boolean;
}

const initialState = {
  data: false,
  loading: false,
  error: false,
  reset: false,
};

const homeReducer = (state: Object = initialState, action: any) => {
  switch (action.type) {
    case GET_COMPONENT_JSON:
      return {
        ...state,
        loading: true,
        reset: false,
      };
    case GET_COMPONENT_JSON_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        reset: false,
      };
    case GET_COMPONENT_JSON_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case RESET_COMPONENT_JSON:
      return {
        ...initialState,
        loading: false,
        reset: true,
      };
    default:
      return state;
  }
};

export default homeReducer;
