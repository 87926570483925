import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  Platform,
  TouchableOpacity,
  FlatList,
  Dimensions,
  LayoutChangeEvent,
  View,
} from "react-native";
import Container from "./Container";
import Image from "./Image";
import PaginationContainer from "./PaginationContainer";
import PageDot from "./PageDot";
import { dimenHeight } from "shared-components/src/utils/helper";

interface ISlider {
  Images: {
    ImgUrl: string;
  }[];
  onImageClick?: Function;
  coverScreen?: boolean;
  currentImageIndex?: number;
  bottomHeight?: number;
  imageResizeType?: "cover" | "contain";
  onLayout: (e: LayoutChangeEvent) => void;
  imgVariantUrl?: string;
}

const { width } = Dimensions.get("window");

const ProductSlider = ({
  Images,
  onImageClick,
  coverScreen,
  currentImageIndex = 0,
  bottomHeight = 0,
  imageResizeType = "contain",
  onLayout,
  imgVariantUrl,
}: ISlider) => {
  const [currentPage, setCurrentPage] = useState(currentImageIndex);
  const indexRef = useRef(currentPage);
  indexRef.current = currentPage;
  const windowWidth = Math.round(Dimensions.get("window").width);
  const flatListRef = useRef<FlatList>(null);
  const flatListOptimizationProps = {
    initialNumToRender: 0,
    maxToRenderPerBatch: 1,
    removeClippedSubviews: true,
    scrollEventThrottle: 16,
    windowSize: 2,
    getItemLayout: useCallback(
      (_, index) => ({
        index,
        length: windowWidth,
        offset: index * windowWidth,
      }),
      []
    ),
  };
  const onScroll = useCallback((event) => {
    const slideSize = event.nativeEvent.layoutMeasurement.width;
    const index = event.nativeEvent.contentOffset.x / slideSize;
    const roundIndex = Math.round(index);

    const distance = Math.abs(roundIndex - index);

    // Prevent one pixel triggering setCurrentPage in the middle
    // of the transition. With this we have to scroll a bit
    // more to trigger the index change.
    const isNoMansLand = distance > 0.4;

    if (roundIndex !== indexRef.current && !isNoMansLand) {
      setCurrentPage(roundIndex);
    }
  }, []);

  useEffect(() => {
    if (imgVariantUrl) {
      const findImgIndex = Images.findIndex(
        (item) => item.ImgUrl === imgVariantUrl
      );
      if (findImgIndex >= 0) {
        setCurrentPage(findImgIndex);
        flatListRef?.current?.scrollToIndex({
          animated: true,
          index: findImgIndex,
        });
      }
    }
  }, [imgVariantUrl]);

  const renderImage = (index: number, uri: string) => {
    const ImageJSX = (
      <Image
        source={{ uri }}
        coverScreen={coverScreen}
        resizeMode={imageResizeType}
        style={{
          width,
          height: width,
        }}
      />
    );

    const imageComponent = onImageClick ? (
      <TouchableOpacity onPress={() => onImageClick({ index })}>
        {ImageJSX}
      </TouchableOpacity>
    ) : (
      ImageJSX
    );

    return (
      <View
        style={{
          width,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {imageComponent}
      </View>
    );
  };

  return (
    <Container coverScreen={coverScreen} onLayout={onLayout}>
      {Platform.OS === "web" ? (
        <Image source={{ uri: Images[0].ImgUrl }} resizeMode={imageResizeType} />
      ) : (
        <FlatList
          ref={flatListRef}
          style={{ flex: 1 }}
          data={Images}
          renderItem={({ item, index }) => renderImage(index, item.ImgUrl)}
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item, index) => index.toString()}
          onScroll={onScroll}
          initialScrollIndex={currentPage}
          {...flatListOptimizationProps}
        />
      )}
      <PaginationContainer bottom={bottomHeight}>
        {Images.map((item, index) => (
          <PageDot
            key={index.toString()}
            style={
              index !== Images.length - 1 && {
                marginRight: Platform.OS === "web" ? 5 : dimenHeight(5),
              }
            }
            isActive={currentPage === index}
          />
        ))}
      </PaginationContainer>
    </Container>
  );
};

export default ProductSlider;
