import React, { Component } from "react";
import { Platform } from "react-native";
import styled from "styled-components/native";
import { ColorListData } from "shared-components/src/models/colorListData";
import Dropdown from "./Dropdown";
import {
  dimenFont,
  dimenHeight,
  dimenWidth,
} from "shared-components/src/utils/helper";
import { colors, shadow } from "shared-components/src/utils/constants";

interface IProps {
  title?: string;
  data: {
    Customizations: { Id: number; Name: string; Value: string }[];
    FeatureOptions: {
      Id: string;
      Name: string;
      Options: { label: string; value: string; selected?: boolean }[];
    }[];
  };
  colorList?: ColorListData[];
  productImageUploadedText?: string;
  handleProductInputs?: Function;
  handlePropertyChange?: Function;
  loadingStart?: Function;
  loadingEnd?: Function;
  fontFamily?: any;
  noDetailText?: string;
}

interface IState {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  imageFileName?: string;
  fontFamily?: any;
}

const Container = styled.View`
  ${Platform.OS === "web" ? `padding: 5px 10px;` : `margin: 8px 16px;`}
`;

const Wrapper = styled.View`
  display: flex;
  flex-direction: column;
  padding: ${Platform.OS === "web" ? "12" : dimenWidth(16)}px;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(15, 54, 73, 0.25);
`;

const BlockGroup = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Platform.OS === "web" ? "6" : dimenHeight(8)}px;
`;

const ImagePickerContainer = styled.TouchableOpacity`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Platform.OS === "web" ? "6" : dimenHeight(8)}px;
`;

const Field = styled.Text<IState>`
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
  color: ${(props) => props.primary};
`;

const FieldBold = styled.Text<IState>`
  flex: 1;
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
  color: ${(props) => props.primary};
`;

const FieldMedium = styled.Text<IState>`
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web" ? props.fontFamily : props.fontFamily!.Regular};
  color: ${(props) => props.primary};
`;

const TextInput = styled.TextInput<IState>`
  flex: 1;
  border-radius: ${Platform.OS === "web" ? "4" : dimenFont(4)}px;
  padding: ${Platform.OS === "web"
    ? "8px"
    : `${dimenHeight(7)}px ${dimenHeight(8)}px`};
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web" ? props.fontFamily : props.fontFamily!.Regular};
  background-color: ${colors.iceBlue};
  color: ${(props) => props.primary};
`;

class Options extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      primary: "#0F3649",
      secondary: "#3196F6",
      tertiary: "#0F3649",
    };
  }

  render() {
    const { Customizations, FeatureOptions } = this.props.data;
    const { title, colorList, fontFamily } = this.props;
    const { primary } = this.state;

    return (
      <Container>
        <Wrapper style={Platform.OS === "web" ? {} : shadow}>
          <BlockGroup>
            <Field
              fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
              primary={colorList ? colorList[0].colorCode : primary}
            >
              {title ? title : "Details"}
            </Field>
          </BlockGroup>
          {Customizations &&
            Customizations.map((item) => (
              <BlockGroup key={item.Id}>
                <FieldBold
                  fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                  primary={colorList ? colorList[0].colorCode : primary}
                >
                  {item.Name}
                </FieldBold>
                <TextInput
                  fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                  primary={colorList ? colorList[0].colorCode : primary}
                  defaultValue={item.Value}
                />
              </BlockGroup>
            ))}
          {FeatureOptions &&
            FeatureOptions.map(
              (item: {
                Id: string;
                Name: string;
                Options: { label: string; value: string; selected?: boolean }[];
              }) => (
                <BlockGroup key={item.Id}>
                  <FieldBold
                    fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                    primary={colorList ? colorList[0].colorCode : primary}
                  >
                    {item.Name}
                  </FieldBold>
                  <Dropdown
                    fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                    items={item.Options}
                    primary={colorList ? colorList[0].colorCode : primary!}
                  />
                </BlockGroup>
              )
            )}
          {((FeatureOptions === null ||
            (FeatureOptions && FeatureOptions.length === 0)) &&
            Customizations === null) ||
          (Customizations && Customizations.length === 0) ? (
            <FieldMedium
              fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
              primary={colorList ? colorList[0].colorCode : primary}
            >
              {this.props.noDetailText
                ? this.props.noDetailText
                : "There are no product details for the sample product used. Details for different products will appear here."}
            </FieldMedium>
          ) : null}
        </Wrapper>
      </Container>
    );
  }
}

export default Options;
