export enum PageType {
  Home = 1,
  ProductList = 2,
  ProductDetail = 3,
}

export enum EPages {
  SplashScreen = "SplashScreen",
  PushNotification = "Push Notification",
}
