import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classes from "./engageNavigation.module.scss";

const EngageNavigation = () => {
  const [isOpen, setOpen] = useState(true);
  const [isSubNavigation, setSubNavigation] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setSubNavigation(window.location.href.includes("/engage/content-settings"));
  });

  useEffect(() => {
    const contentSettings = [
      "/engage/content-settings/edit-content",
      "/engage/content-settings/create-new-language",
    ];

    const isCurrentPath = contentSettings.find((item) =>
      location.pathname.includes(item)
    );

    if (isCurrentPath) {
      setOpen(true);
    }
  }, [location]);

  return (
    <ul className={classes.navigationList}>
      <li key="/engage/content-settings">
        <a
          className={`${isSubNavigation && classes.subNavigation}`}
          onClick={() => {}}
        >
          Content Settings
        </a>

        {isOpen && (
          <div className={classes.menuWrapper}>
            <ul>
              <li key="Edit Content">
                <NavLink
                  className={classes.subNavigationItem}
                  to="/engage/content-settings/edit-content"
                  activeClassName={classes.active}
                >
                  Edit Content
                </NavLink>
              </li>

              <li key="Create a New Language">
                <NavLink
                  className={classes.subNavigationItem}
                  to="/engage/content-settings/create-new-language"
                  activeClassName={classes.active}
                >
                  Create a New Language
                </NavLink>
              </li>
            </ul>
          </div>
        )}
      </li>
    </ul>
  );
};

export default EngageNavigation;
