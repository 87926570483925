import { Platform } from "react-native";
import styled from "styled-components/native";
import { dimenHeight } from "shared-components/src/utils/helper";
import FastImage from "react-native-fast-image-web-support";

export default styled(FastImage)`
  height: ${Platform.OS === "web" ? "80" : dimenHeight(80)}px;
  width: 100%;
  margin-bottom: 16px;
`;
