import styled from "styled-components";

interface IRadioButtonGroup {
  flexDirection: "row" | "column";
}

export default styled.div<IRadioButtonGroup>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  row-gap: 5px;
`;
