import React from "react";
import { Linking, TouchableOpacity } from "react-native";
import BannerImage from "./BannerImage";

interface ILargeBanner {
  bannerInfo: {
    Image: {
      ImgUrl: string;
    };
    Link: {
      Type: number;
      LinkTo: string;
      CategoryId?: string;
      CategoryName?: string;
      ProductId?: string;
      ProductName?: string;
      Search?: string;
    };
  };
  showProduct?: Function;
  showWebview?: Function;
  navigate?: (route: string, params: Record<string, any>) => void;
  imageResizeType: "cover" | "contain";
}

enum enumLinkTypes {
  Category = 1,
  Product,
  Href,
  WebViewURL,
  Search,
}

const LargeBanner = ({
  bannerInfo,
  showProduct,
  showWebview,
  imageResizeType,
  navigate,
}: ILargeBanner) => {
  const handleOnPress = (link: ILargeBanner["bannerInfo"]["Link"]) => {
    switch (link.Type) {
      case enumLinkTypes.Category:
        link?.CategoryId && navigate && navigate('ProductList', {
          categoryId: link.CategoryId,
          title: link.CategoryName,
        })
        break;
      case enumLinkTypes.Product:
        showProduct && link?.ProductId && showProduct(link.ProductId);
        break;
      case enumLinkTypes.Href:
        Linking.openURL(link.LinkTo);
        break;
      case enumLinkTypes.WebViewURL:
        showWebview && showWebview(link.LinkTo);
        break;
      case enumLinkTypes.Search:
        link?.Search && navigate && navigate('ProductList', {
          title: link.Search,
          search: link.Search,
        });
        break;

      default:
        break;
    }
  };
  return bannerInfo.Link.Type ? (
    <TouchableOpacity onPress={() => handleOnPress(bannerInfo.Link)}>
      <BannerImage
        source={{ uri: bannerInfo.Image.ImgUrl }}
        resizeMode={imageResizeType}
      />
    </TouchableOpacity>
  ) : (
    <BannerImage
      source={{ uri: bannerInfo.Image.ImgUrl }}
      resizeMode={imageResizeType}
    />
  );
};

export default LargeBanner;
