import React from "react";

interface IDesignProps {
  width: string;
  height: string;
  fillColor: string;
  type: string;
}

const Design = ({ width, height, fillColor }: IDesignProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M8.27 7.45l8.251 8.265-.737.738c-.785.788-1.964.69-3.241.099-.933-.443-2.063-.197-2.75.59L4.71 23.12a2.683 2.683 0 0 1-3.88.098l-.05-.05a2.694 2.694 0 0 1 .099-3.886l5.967-5.092c.786-.688 1.031-1.82.59-2.755-.59-1.279-.664-2.484.098-3.247l.736-.738zM1.566 22.43a1.118 1.118 0 0 0 1.572 0c.417-.417.417-1.155 0-1.573a1.118 1.118 0 0 0-1.572 0 1.122 1.122 0 0 0 0 1.574zm22.28-13.904l-6.262 6.125-8.25-8.265L14.808.753c.86-.861 1.179-.837 1.375-.64l5.6 5.608s.024.025 0 .05l-.91 1.55c-.024.024 0 .049.05.049l1.547-.91s.024-.025.049 0l1.375 1.377c.147.148.147.492-.05.689z"
    />
  </svg>
);

export default Design;
