import * as Yup from "yup";
import { splashScreenLogoMessage, splashLogoExtensionMessage, splashLogoSizeMessage } from "../../../utils/validationMessage";
const ICON_WIDTH = 1024;
const ICON_HEIGHT = 1024;
const SUPPORTED_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif"
];

const Schema = Yup.object().shape({
  logo: Yup.mixed().required(splashScreenLogoMessage),
  logoExtension: Yup.string().required(splashLogoExtensionMessage).test(
    "logoExtension",
    splashLogoExtensionMessage,
    value => value && SUPPORTED_FORMATS.includes(value)
  ),
  logoWidth: Yup.number().max(ICON_WIDTH, splashLogoSizeMessage).min(ICON_WIDTH, splashLogoSizeMessage),
  logoHeight: Yup.number().max(ICON_HEIGHT, splashLogoSizeMessage).min(ICON_HEIGHT, splashLogoSizeMessage),
});

export default Schema;
