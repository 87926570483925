import React from "react";
import styles from "./CustomDragPreview.module.scss";

type Props = {
  monitorProps: any;
};

export const CustomDragPreview: React.FC<Props> = (props) => {
  const { item, clientOffset: offset } = props.monitorProps;
  return (
    <div
      className={styles.root}
      style={{ transform: `translate(-${offset.x - 520}px,-50%)` }}
    >
      <p className={styles.label}>{item.text}</p>
    </div>
  );
};
