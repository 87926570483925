import React from "react";
import { IconType } from "../../../utils/enums/iconType";

interface IProps {
  isSmall?: boolean;
  iconType: string;
}

const PublishIcon = (props: IProps) => {
  let width = null;
  let height = null;
  let fillColor = null;

  if (props.isSmall) {
    width = height = "20";
  } else {
    width = height = "32";
  }

  switch (props.iconType) {
    case IconType.Disabled:
      fillColor = "#6D8694";
      break;
    case IconType.Active:
      fillColor = "#3196F6";
      break;
    case IconType.Enabled:
      fillColor = "#FFFFFF";
      break;
    default:
      fillColor = "#6D8694";
      break;
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M4.648 15.346c.624.628.397 1.827-.51 2.741-1.049 1.085-3.6 1.77-3.713 1.799L0 20l.113-.428c.029-.114.794-2.741 1.786-3.712.538-.542 1.219-.856 1.814-.856.368-.029.68.114.935.342zM20 .576c0 5.008-.978 8.765-5.734 13.164.068 2.368-.64 4.263-2.158 5.786l-.472.474-2.934-4.331-.713-.2a5 5 0 0 1-3.473-3.503l-.199-.73L0 8.258l.506-.474c1.518-1.523 3.44-2.233 5.8-2.132C10.727.915 14.47-.033 19.46.001H20v.575zm-6.153 3.178c-1.356-.077-2.518 1.006-2.596 2.4a2.53 2.53 0 0 0 2.402 2.592c1.395.077 2.518-1.006 2.596-2.4a2.53 2.53 0 0 0-2.402-2.592z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
};

export default PublishIcon;
