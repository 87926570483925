import * as Yup from "yup";

export default Yup.object().shape({
  socialMediaPlatforms: Yup.array().of(
    Yup.object().shape({
      socialMediaType: Yup.string().required(
        "Social platforms can't be blank."
      ),
      url: Yup.string().required("URL can't be blank.").url("Must be an URL"),
    })
  ),
});
