import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPackageFeatureListStart } from "../redux/actions/getPackageFeature";
import { IComponent } from "../Components/DragnDrop/Components";

type TUsedComponents = {
  home: number;
  productList: number;
  productDetail: number;
  total: number;
};

export default function usePackage() {
  const dispatch = useDispatch();
  const { AppId } = useSelector((store: any) => store.userInfo.selectedApp);
  const { productDetails } = useSelector(
    (store: any) => store.productDetail.data
  );
  const { productList } = useSelector((store: any) => store.productList.data);
  const { home } = useSelector((store: any) => store.home.data);
  const { featureData } = useSelector((store: any) => store.packageFeature);

  const [usedComponents, _setSelectedComponents] = useState<TUsedComponents>({
    home: 0,
    productList: 0,
    productDetail: 0,
    total: 0,
  });

  const setSelectedComponents = (
    value: Partial<Omit<TUsedComponents, "total">>
  ) => {
    _setSelectedComponents((previosValue) => ({ ...previosValue, ...value }));
  };

  const packageFeaturesLimited = useMemo(
    (): number =>
      featureData?.PackageFeature.Features?.find((s: any) => s.Id === 1)
        ?.MaxCount ?? -1,
    [featureData?.PackageFeature?.Features]
  );

  const isPackageFeaturesLimited = useMemo(
    () => packageFeaturesLimited > -1,
    [featureData?.PackageFeature?.Features]
  );

  const getPackageFeature = useCallback(
    (featureName: string): any => {
      const tmpIndex = featureData?.PackageFeature?.Features?.findIndex(
        (obj: { Name: string }) => obj.Name === featureName
      );
      return featureData?.PackageFeature?.Features[tmpIndex];
    },
    [featureData?.PackageFeature?.Features]
  );

  useEffect(() => {
    const packageFeatureObj = {
      applicationId: AppId,
    };
    dispatch(getPackageFeatureListStart(packageFeatureObj));
  }, []);

  /**
   * Page Initialize
   */
  useEffect(() => {
    setSelectedComponents({
      home: home?.SelectedComponents?.length ?? 0,
      productList: productList?.SelectedComponents?.length ?? 0,
      productDetail: productDetails?.SelectedComponents?.length ?? 0,
    });
  }, [
    home?.SelectedComponents,
    productList?.SelectedComponents,
    productDetails?.SelectedComponents,
  ]);

  /**
   * Total
   */
  useEffect(() => {
    _setSelectedComponents((previosValue) => ({
      ...previosValue,
      total:
        (usedComponents?.home ?? 0) +
        (usedComponents?.productDetail ?? 0) +
        (usedComponents?.productList ?? 0),
    }));
  }, [
    usedComponents.home,
    usedComponents.productDetail,
    usedComponents.productList,
  ]);

  const handleExceptComponents = (
    selectedComponents: (IComponent | undefined)[]
  ) => {
    let exceptList: (IComponent | undefined)[] = [];
    const exceedLimit = usedComponents.total - packageFeaturesLimited;
    if (isPackageFeaturesLimited) {
      const newSelectedComponent = selectedComponents.filter(
        (s: any) => s.isNewAdded
      );
      if (exceedLimit > 0) {
        exceptList = newSelectedComponent.slice(
          newSelectedComponent.length - exceedLimit,
          newSelectedComponent.length
        );
      }

      /**
       * html startup planda kullanılamayan component
       */
      const customHTMLComponents = selectedComponents.filter(
        (x) => x?.componentId === 31
      );
      if (customHTMLComponents.length) {
        exceptList = [...exceptList, ...customHTMLComponents];
      }
    }

    return selectedComponents.filter(
      (s) =>
        !exceptList.some((exceptComponent: any) => exceptComponent.Id === s?.Id)
    );
  };

  return {
    usedComponents,
    packageFeaturesLimited,
    isPackageFeaturesLimited,
    getPackageFeature,
    setSelectedComponents,
    handleExceptComponents,
  };
}
