import styled from "styled-components";
import { OS } from "../../ToggleOS";

interface IWhiteBackground {
  currentOS: OS;
}

export default styled.div<IWhiteBackground>`
  width: 100%;
  height: 100%;
  ${(props) =>
    props.currentOS === OS.IOS &&
    "border-radius: 25px 25px 0px 0px; -webkit-border-radius: 25px 25px 0px 0px;"}
  background: #fff;
  box-shadow: 2px 0px 15px 0px rgb(15 54 73 / 10%);
`;
