import {
  SHOW_MODAL,
  HIDE_MODAL,
  START_LOADING,
  FINISH_LOADING,
  SHOW_ERROR_MODAL,
} from "panel/src/redux/constants/modal";

interface IProps {
  isOpen: boolean;
  isError: boolean;
  errorMessage: string;
}

const defaultLoadingText = ``;

export const showModal = (isOpen: IProps) => {
  return {
    type: SHOW_MODAL,
    isOpen,
  };
};

export const hideModal = () => {
  return {
    type: HIDE_MODAL,
    payload: {},
  };
};
export const showErrorModal = (isError: IProps) => {
  return {
    type: SHOW_ERROR_MODAL,
    isError,
  };
};

export const startLoading = ({ text = defaultLoadingText } = {}) => ({
  type: START_LOADING,
  payload: { text },
});

export const finishLoading = () => ({
  type: FINISH_LOADING,
});
