import styled from "styled-components";

export default styled.span`
  margin-top: 5px;
  font-size: 9px;
  font-weight: 600;
  color: #272727;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;
