export const requiredMessage =
  "Your email or password is incorrect. Please try again.";
export const emailErrorMessage =
  "Your email must be in 'email@email.com/org/ etc.' format. ";
export const passwordsMatch = "Passwords must match.";
export const minMessage = "Your password must contain 8-20 characters.";
export const maxMessage = "Your password must contain 8-20 characters.";
export const passwordMatches =
  " Password must be 8-20 characters and contain at least 1 uppercase, 1 lowercase and 1 number";

export const webServiceRequiredErrorMessage =
  "You must fill the information to proceed";
export const urlErrorMessage = "The link must be a URL";

export const appNameMaxMessage = "max 30 character";
export const appNameRequiredMessage = "App name can't be blank";

export const appAndroidIconMessage =
  "Please add Android app icon for your app.";
export const appIosIconMessage = "Please add iOS app icon for your app.";
export const appIOSIconExtensionMessage = "The image must be in PNG format.";
export const appAndroidIconExtensionMessage =
  "The image must be in 3 bit PNG  (with alpha) format.";
export const appIconSizeMessage = "The image is not 512*512 px";
export const navigationBarLogoMessage =
  "Please add a navigation bar logo for your app.";
export const navigationBarLogoExtensionMessage =
  "The image must be PNG, JPG or SVG format.";
export const navigationBarLogoSizeMessage = "The image is not 90 px * 18 px";
export const navigationBarLogoRatioMessage =
  "The image’s aspect ratio is not 5:1 (e.g. 90x18)";
export const googleMerchantFileExtensionMessage =
  "The file must be p12 format.";

export const splashScreenLogoMessage = "Splash logo must be added.";

export const colorSpecialCharacterValidMessage = "Must start with #";
export const hexColorNotValidMessage = "Colour code is not exist";

export const splashLogoExtensionMessage =
  "Your image must be in png, jpeg, or gif formats";
export const splashLogoSizeMessage = "The image is not 1024 px * 1024 px";

export const askDemoLinkCheckboxErrorMessage =
  "You must select at least one device.";
export const askDemoLinkEmailErrorMessage =
  "Your email must be in the format of @ and .com, .org etc.";

export const notificationImageSizeMessage =
  "The image size should be a maximum of 300 kb.";
export const notificationImageExtensionMessage =
  "The image must be PNG or JPG format.";
