import React, { Component } from "react";
import { Platform, TouchableWithoutFeedback } from "react-native";
import styled from "styled-components/native";
import ArrowUp from "../../icons/ArrowUp";
import ArrowDown from "../../icons/ArrowDown";
import { PropertiesdDataModel } from "shared-components/src/models/propertiesDataModel";
import { ColorListData } from "shared-components/src/models/colorListData";
import {
  dimenFont,
  dimenHeight,
  dimenWidth,
} from "shared-components/src/utils/helper";
import { colors, shadow } from "shared-components/src/utils/constants";

interface IProps {
  title?: string;
  data: PropertiesdDataModel;
  colorList?: ColorListData[];
  fontFamily?: any;
}

interface IState {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  isDetailShown?: boolean;
  fontFamily?: any;
}

const Container = styled.View`
  ${Platform.OS === "web" ? `padding: 5px 10px;` : `margin: 8px 16px;`}
`;

const Wrapper = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.white};
  padding: ${Platform.OS === "web" ? "12" : dimenWidth(16)}px;
  box-shadow: 0px 0px 5px rgba(15, 54, 73, 0.25);
`;

const HeaderContainer = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BlockGroup = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${Platform.OS === "web" ? "6" : dimenWidth(8)}px;
`;

const Field = styled.Text<IState>`
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
  color: ${(props) => props.primary};
`;

const FieldBold = styled.Text<IState>`
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
  color: ${(props) => props.primary};
`;

const FieldMedium = styled.Text<IState>`
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web" ? props.fontFamily : props.fontFamily!.Regular};
  color: ${(props) => props.primary};
`;

class ProductDetails extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      primary: "#0F3649",
      secondary: "#3196F6",
      tertiary: "#0F3649",
      isDetailShown: true,
    };
  }

  render() {
    const { title, data, colorList, fontFamily } = this.props;
    const { primary, secondary, tertiary, isDetailShown } = this.state;
    return (
      <Container>
        <Wrapper style={Platform.OS === "web" ? {} : shadow}>
          <TouchableWithoutFeedback
            onPress={() => this.setState({ isDetailShown: !isDetailShown })}
          >
            <HeaderContainer>
              <Field
                fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                primary={colorList ? colorList[0].colorCode : primary}
                style={
                  isDetailShown && {
                    marginBottom: Platform.OS === "web" ? "8" : dimenHeight(16),
                  }
                }
              >
                {title ? title : "Product Details"}
              </Field>
              {isDetailShown ? (
                <ArrowUp
                  fillColor={colorList ? colorList[0].colorCode : primary!}
                  height={
                    Platform.OS === "web" ? "12" : dimenHeight(16).toString()
                  }
                  width={
                    Platform.OS === "web" ? "12" : dimenWidth(16).toString()
                  }
                />
              ) : (
                <ArrowDown
                  fillColor={colorList ? colorList[0].colorCode : primary!}
                  height={
                    Platform.OS === "web" ? "12" : dimenHeight(16).toString()
                  }
                  width={
                    Platform.OS === "web" ? "12" : dimenWidth(16).toString()
                  }
                />
              )}
            </HeaderContainer>
          </TouchableWithoutFeedback>
          {isDetailShown &&
            data.Properties.map((item) => (
              <BlockGroup key={item.PropertyId}>
                <FieldBold
                  fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                  primary={colorList ? colorList[0].colorCode : primary}
                >
                  {item.PropertyName}
                </FieldBold>
                <FieldMedium
                  fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                  primary={colorList ? colorList[0].colorCode : primary}
                >
                  {item.Pairs[0].Value}
                </FieldMedium>
              </BlockGroup>
            ))}
        </Wrapper>
      </Container>
    );
  }
}

export default ProductDetails;
