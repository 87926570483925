import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// COMPONENTS START
import Modal from "panel/src/_components/common/Modal";
import ConfirmButton from "panel/src/_components/common/Button";
import ScreenSelection from "../components/ScreenSelection";
import Content from "./Content";
import ComponentsContainer from "./ComponentsContainer";
import Components, {
  IComponent,
} from "panel/src/Components/DragnDrop/Components";
import Emulator from "panel/src/Components/DragnDrop/Emulator";
// COMPONENTS END

//REDUX ACTIONS START
import { resetHomeData } from "panel/src/redux/actions/productList";
import {
  saveScreenData,
  resetSaveScreenData,
} from "panel/src/redux/actions/SaveScreen";
// REDUX ACTIONS END

// SHARED COMPONENTS START
import {
  ProductCard,
  ListView,
  MediumListView,
  SmallListView,
  CustomHtml,
} from "shared-components/src/App";
// SHARED COMPONENTS END

//MODELS START
import IGetProductListDataRequest from "panel/src/models/Design/productList/IGetProductListDataRequest";
import { IProductListData } from "panel/src/models/Design/productList/IGetProductListDataResponse";
import {
  IColorData,
  ColorList,
} from "../../../models/Design/colors/IGetColorsPageDataResponse";
import ISaveScreenModel, {
  ISaveScreenComponent,
} from "panel/src/models/SaveScreenModel";
import SelectModel from "panel/src/models/Design/selectModel";
//MODELS END

import { PageType } from "panel/src/utils/enums/pageType";
import { ScreenType } from "panel/src/utils/enums/screenType";
import { ComponentType } from "panel/src/utils/enums/componentType";
import classes from "panel/src/screens/Design/ProductList/productList.module.scss";
import closeIcon from "panel/src/assets/images/close.svg";
import { screenNumbers } from "../constants";
import ProductListEditForm from "../utils/EditForms/ProductList";
import HtmlEditForm from "./../utils/EditForms/Html";
import { getDesignData } from "../../../redux/actions/design";
import UpgradeGuide from "../../../Components/UpgradeGuide";
import close from "../../../assets/images/close.svg";
import Button from "panel/src/_components/common/Button";
import UpgradeModal from "../../../Components/UpgradeModal";
import { usePackage } from "../../../hooks";

interface IProductReRenderComponent {
  ImageResizeType: "cover" | "contain";
  IsAddToCart?: boolean;
}

interface IProductList {
  appId: string;
  platformId: number;
  userId: string;
  productListData: IProductListData["data"]["ResultData"];
  saveScreenRes: boolean | number;
  _getProductListData: Function;
  _resetHomeData: Function;
  _saveScreenData: Function;
  _resetSaveScreenData: Function;
  isCompletion: boolean;
  selectedValue: string;
  designScreens: SelectModel[];
  designScreensChange: Function;
  selectChange: Function;
  loading: boolean;
  reset: boolean;
  colors: IColorData;
  fonts: any;
  isOnModalSubmit: boolean;
  setError: Function;
}

export const requiredEditForms = [
  ComponentType.GalleryView,
  ComponentType.ListView,
  ComponentType.MediumListView,
  ComponentType.SmallListView,
];

const oneRuleComponents = [
  ComponentType.MediumListView,
  ComponentType.GalleryView,
  ComponentType.SmallListView,
  ComponentType.ListView,
];

const ProductList = ({
  appId,
  platformId,
  userId,
  productListData,
  _getProductListData,
  _resetHomeData,
  _saveScreenData,
  _resetSaveScreenData,
  saveScreenRes,
  isCompletion,
  selectedValue,
  designScreens,
  designScreensChange,
  selectChange,
  loading,
  reset,
  colors,
  fonts,
  isOnModalSubmit,
  setError,
}: IProductList) => {
  const packageHook = usePackage();

  useEffect(() => {
    getScreenComponentsData();
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [modalInfo, setModalInfo] = useState({
    visible: false,
    droppedItem: {},
    handleDroppedItem: (e: any, componentsArr: any) => {},
  });

  const [noComponentSelectedError, setNoComponentSelectedError] =
    useState(false);
  const [selectedComponents, setSelectedComponents] = useState<
    (IComponent | undefined)[]
  >([]);
  const [componentsList, setComponentsList] = useState<
    (IComponent | undefined)[]
  >([]);
  const [componentsData, setComponentsData] =
    useState<IProductListData["data"]["ResultData"]["Components"] | undefined>(
      undefined
    );
  const [selectedColorList, setSelectedColorList] = useState<ColorList[]>([]);
  const [selectedFont, setSelectedFont] = useState("");
  const [isUpgradeGuide, setIsUpgradeGuide] = useState<boolean>(false);
  const [isSaveModal, setIsSaveModal] = useState<boolean>(false);
  const [isOpenUpgradeModal, setIsOpenUpgradeModal] = useState<boolean>(false);

  useEffect(() => {
    packageHook.setSelectedComponents({
      productList: selectedComponents.length ?? 0,
    });
  }, [selectedComponents]);

  useEffect(() => {
    if (isOnModalSubmit && selectedValue === screenNumbers.productList) {
      saveScreenComponents();
    }
  }, [isOnModalSubmit]);

  useEffect(() => {
    if (productListData && !loading && !reset) {
      const colorList = colors.selectedColorModel
        ? colors.selectedColorModel.colorList
        : [];
      setSelectedColorList(colorList);

      const selectedFontFamily = fonts.find((opt: any) => opt.IsUsed);
      const selectedFontName = selectedFontFamily
        ? selectedFontFamily.Name
        : "Helvetica Neue";
      setSelectedFont(selectedFontName);

      const componentsData = productListData.Components;
      setComponentsData(componentsData);
    }
  }, [productListData]);

  useEffect(() => {
    if (componentsData && !reset && !loading) {
      const selectedComponentsList = productListData.SelectedComponents.map(
        (obj) => {
          return getComponent(
            obj.ComponentId,
            JSON.parse(obj.ResponseJSON),
            obj.ImageResizeType,
            JSON.parse(obj.CustomJSON)?.IsAddToCart
          );
        }
      ).filter((obj) => obj !== undefined);

      if (
        packageHook.isPackageFeaturesLimited &&
        packageHook.usedComponents.total > packageHook.packageFeaturesLimited
      ) {
        setIsUpgradeGuide(true);
      }
      setSelectedComponents(selectedComponentsList);

      const componentsList = productListData.Components.map((obj) => {
        const tempComponent = getComponent(
          obj.ComponentId,
          JSON.parse(obj.ResponseJSON),
          obj.ImageResizeType,
          obj.IsAddToCart
        );
        if (tempComponent) tempComponent.IsNewBadge = obj.IsNewBadge;
        return tempComponent;
      }).filter((obj) => obj !== undefined);
      setComponentsList(componentsList);
      setIsLoading(false);
    }
  }, [componentsData]);

  useEffect(() => {
    if (saveScreenRes) {
      setIsLoading(false);
    }
  }, [saveScreenRes]);

  useEffect(() => {
    return () => {
      // _resetHomeData();
      _resetSaveScreenData();
    };
  }, []);

  const getComponent = (
    componentId: number,
    responseJSON: any,
    ImageResizeType: "cover" | "contain",
    IsAddToCart?: boolean
  ) => {
    let component: IComponent | undefined;
    switch (componentId) {
      case ComponentType.GalleryView:
        component = {
          componentId: ComponentType.GalleryView,
          pageId: PageType.ProductList,
          componentTitle: "Gallery View",
          component: (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                backgroundColor: "#fff",
                padding: 10,
                borderRadius: 5.2,
              }}
            >
              {responseJSON.map((item: any, index: number) => {
                return (
                  <ProductCard
                    fontFamily={selectedFont}
                    colorList={selectedColorList}
                    style={{
                      marginRight: index % 2 === 0 ? 10 : undefined,
                      marginBottom: index < 2 ? 10 : undefined,
                      flexGrow: 1,
                      flexShrink: 0,
                      flexBasis: "35%",
                      maxWidth: "47%",
                    }}
                    imageResizeType={ImageResizeType}
                    product={item}
                    key={index.toString()}
                    isAddToCart={IsAddToCart}
                  />
                );
              })}
            </div>
          ),
          editForm: {
            component: <ProductListEditForm />,
            isShown: false,
            reRenderComponent: ({
              ImageResizeType,
              IsAddToCart,
            }: IProductReRenderComponent) =>
              getComponent(
                componentId,
                responseJSON,
                ImageResizeType,
                IsAddToCart
              ),
            editFormData: {
              ImageResizeType,
              IsAddToCart,
            },
          },
          preventEvents: true,
        };
        break;
      case ComponentType.ListView:
        component = {
          componentId: ComponentType.ListView,
          pageId: PageType.ProductList,
          componentTitle: "List View",
          component: (
            <div
              style={{
                backgroundColor: "#fff",
                padding: 10,
                borderRadius: 5.2,
              }}
            >
              {responseJSON.map((item: any, index: number) => (
                <ListView
                  fontFamily={selectedFont}
                  colorList={selectedColorList}
                  style={{
                    marginBottom:
                      responseJSON.length - 1 !== index ? 10 : undefined,
                  }}
                  imageResizeType={ImageResizeType}
                  product={item}
                  key={index.toString()}
                  isAddToCart={IsAddToCart}
                />
              ))}
            </div>
          ),
          editForm: {
            component: <ProductListEditForm />,
            isShown: false,
            reRenderComponent: ({
              ImageResizeType,
              IsAddToCart,
            }: IProductReRenderComponent) =>
              getComponent(
                componentId,
                responseJSON,
                ImageResizeType,
                IsAddToCart
              ),
            editFormData: {
              ImageResizeType,
              IsAddToCart,
            },
          },
          preventEvents: true,
        };
        break;
      case ComponentType.MediumListView:
        component = {
          componentId: ComponentType.MediumListView,
          pageId: PageType.ProductList,
          componentTitle: "Medium List View",
          component: (
            <div
              style={{
                backgroundColor: "#fff",
                padding: 10,
                borderRadius: 5.2,
              }}
            >
              {responseJSON.map((item: any, index: number) => (
                <React.Fragment>
                  <MediumListView
                    fontFamily={selectedFont}
                    colorList={selectedColorList}
                    imageResizeType={ImageResizeType}
                    product={item}
                    key={index.toString()}
                    isAddToCart={IsAddToCart}
                  />
                  {responseJSON.length - 1 !== index && (
                    <div
                      style={{
                        height: 1,
                        backgroundColor: "rgb(243, 245, 246)",
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          ),
          editForm: {
            component: <ProductListEditForm />,
            isShown: false,
            reRenderComponent: ({
              ImageResizeType,
              IsAddToCart,
            }: IProductReRenderComponent) =>
              getComponent(
                componentId,
                responseJSON,
                ImageResizeType,
                IsAddToCart
              ),
            editFormData: {
              ImageResizeType,
              IsAddToCart,
            },
          },
          preventEvents: true,
        };
        break;
      case ComponentType.SmallListView:
        component = {
          componentId: ComponentType.SmallListView,
          pageId: PageType.ProductList,
          componentTitle: "Small List View",
          component: (
            <div
              style={{
                backgroundColor: "#fff",
                padding: 10,
                borderRadius: 5.2,
              }}
            >
              {responseJSON.map((item: any, index: number) => (
                <React.Fragment>
                  <SmallListView
                    fontFamily={selectedFont}
                    colorList={selectedColorList}
                    imageResizeType={ImageResizeType}
                    product={item}
                    key={index.toString()}
                    isAddToCart={IsAddToCart}
                  />
                  {responseJSON.length - 1 !== index && (
                    <div
                      style={{
                        height: 1,
                        backgroundColor: "rgb(243, 245, 246)",
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          ),
          editForm: {
            component: <ProductListEditForm />,
            isShown: false,
            reRenderComponent: ({
              ImageResizeType,
              IsAddToCart,
            }: IProductReRenderComponent) =>
              getComponent(
                componentId,
                responseJSON,
                ImageResizeType,
                IsAddToCart
              ),
            editFormData: {
              ImageResizeType,
              IsAddToCart,
            },
          },
          preventEvents: true,
        };
        break;
      case ComponentType.CustomHtml:
        component = {
          componentId: ComponentType.CustomHtml,
          pageId: PageType.ProductDetail,
          componentTitle: "Custom Component",
          component: (
            <CustomHtml
              htmlInfo={responseJSON}
              colorList={selectedColorList}
              fontFamily={selectedFont}
            />
          ),
          editForm: {
            editCardPlacement: "right-start",
            editCardWidth: "265px",
            component: <HtmlEditForm fontFamily={selectedFont} />,
            isShown: false,
            reRenderComponent: (editedJSON: any) =>
              getComponent(componentId, editedJSON, ImageResizeType),
            rawFormData: responseJSON,
            editFormData: { ...responseJSON },
          },
        };
        break;

      default:
        break;
    }
    return component;
  };

  const getScreenComponentsData = () => {
    const homeComponentsDataReqObj: IGetProductListDataRequest = {
      applicationId: appId,
      userId: userId,
      platformId: platformId,
    };

    _getProductListData(homeComponentsDataReqObj);
  };

  const saveScreenComponents = () => {
    if (
      packageHook.isPackageFeaturesLimited &&
      packageHook.usedComponents.total > packageHook.packageFeaturesLimited &&
      !isSaveModal
    ) {
      setIsSaveModal(true);
      return;
    }

    setNoComponentSelectedError(false);
    setIsLoading(true);

    const _selectedComponents: ISaveScreenComponent[] = packageHook
      .handleExceptComponents(selectedComponents)
      .map((obj, index) => ({
        ComponentId: obj!.componentId,
        PageId: obj!.pageId,
        DisplayOrder: index + 1,
        JSON: obj!.editForm ? JSON.stringify(obj!.editForm.editFormData) : null,
      }));

    if (!selectedComponents.length) {
      setNoComponentSelectedError(true);
      setError(true);
      setIsLoading(false);
      return;
    }

    if (handleEmptyEditForms(_selectedComponents)) {
      setIsLoading(false);
      return;
    }

    const saveScreenDataReqObj: ISaveScreenModel = {
      ApplicationId: appId,
      PageId: PageType.ProductList,
      Components: _selectedComponents,
    };
    _saveScreenData(saveScreenDataReqObj);
    if (!isOnModalSubmit && selectedValue === screenNumbers.productList) {
      selectChange(ScreenType.ProductDetail.toString());
    }
  };

  const handleEmptyEditForms = (
    saveScreenComponents: ISaveScreenComponent[]
  ) => {
    const _saveScreenComponents = saveScreenComponents
      .filter((obj) => obj.JSON === null)
      .filter((obj) => requiredEditForms.includes(obj.ComponentId));

    if (_saveScreenComponents.length) return true;

    return false;
  };

  const handleItemDropped = (e: any, handleOnDrop: Function) => {
    if (
      packageHook.isPackageFeaturesLimited &&
      packageHook.usedComponents.total >= packageHook.packageFeaturesLimited
    )
      setIsUpgradeGuide(true);

    if (
      packageHook.isPackageFeaturesLimited &&
      e?.payload?.componentId === ComponentType.CustomHtml
    ) {
      setIsOpenUpgradeModal(true);
    }

    if (selectedComponents.length) {
      if (
        oneRuleComponents.includes(e.payload.componentId) &&
        selectedComponents.some((s) =>
          oneRuleComponents.includes(s?.componentId || 0)
        )
      ) {
        setModalInfo({
          visible: true,
          droppedItem: e,
          handleDroppedItem: (e: any, componentsArr: any) =>
            handleOnDrop(e, componentsArr),
        });
      } else {
        handleOnDrop(e);
      }
    } else {
      handleOnDrop(e);
    }
  };

  const discardChange = () => {
    setModalInfo({
      visible: false,
      droppedItem: {},
      handleDroppedItem: (e: any, componentsArr: any) => {},
    });
  };

  const confirmChange = () => {
    const tempArr = [...selectedComponents].filter(
      (s) => !oneRuleComponents.includes(s?.componentId || 0)
    );
    modalInfo.handleDroppedItem(modalInfo.droppedItem, tempArr);
    setModalInfo({
      visible: false,
      droppedItem: {},
      handleDroppedItem: (args: any) => {},
    });
  };
  const closeUpgradeModal = () => {
    setIsUpgradeGuide(false);
  };
  const closeSaveModal = () => {
    setIsSaveModal(false);
  };

  return (
    <Content>
      {!isLoading ? (
        <React.Fragment>
          <ComponentsContainer>
            {!isCompletion && (
              <ScreenSelection
                options={designScreens}
                selectedValue={selectedValue}
                selectChangeHandler={designScreensChange}
              />
            )}
            <Components
              infoText={
                "Design your product list by dragging and dropping one of the components below"
              }
              errorMessage={
                "You have to select one of these components below for your product list page."
              }
              components={componentsList}
              hasError={noComponentSelectedError}
              componentTotalCount={packageHook.usedComponents.total}
            />
          </ComponentsContainer>
          <Emulator
            fontFamily={selectedFont}
            screen="Product List"
            navigateBack={selectChange}
            backTo={ScreenType.Home.toString()}
            appScreen={{
              screen: "Product List",
              selectedComponents: selectedComponents,
              setSelectedComponents: setSelectedComponents,
              requiredEdit: requiredEditForms,
              itemDropped: handleItemDropped,
            }}
            productListHeader={{ visible: true, itemCount: 4 }}
            saveScreen={saveScreenComponents}
          />
          {modalInfo.visible && (
            <Modal
              backdropClickedHandler={() => discardChange()}
              show={true}
              width="448"
            >
              <div className={classes.Close} onClick={() => discardChange()}>
                <img src={closeIcon} alt="close_modal" />
              </div>
              <div className={classes.ModalBody}>
                <span className={classes.ModalBodyText}>
                  You have to select only one component for your product list.
                  Do you want to change with this component?
                </span>
              </div>
              <div className={classes.ModalFooter}>
                <div className={classes.ButtonContainer}>
                  <ConfirmButton
                    type="button"
                    className="Ghost"
                    width="192px"
                    onClick={() => discardChange()}
                  >
                    Cancel
                  </ConfirmButton>
                  <ConfirmButton
                    type="button"
                    className="Primary"
                    width="192px"
                    onClick={() => confirmChange()}
                  >
                    Change
                  </ConfirmButton>
                </div>
              </div>
            </Modal>
          )}
        </React.Fragment>
      ) : null}
      <Modal show={isUpgradeGuide} width="300">
        <UpgradeGuide screen="components" setIsOpenModal={closeUpgradeModal} />
      </Modal>
      <Modal
        //backdropClickedHandler={() => closeSaveModal()}
        show={isSaveModal}
        width="448"
      >
        <div className={classes.Close} onClick={() => closeSaveModal()}>
          <img src={close} />
        </div>
        <div className={classes.ModalBody}>
          <span className={classes.ModalBodyText}>
            The changes you make will not be saved. Do you want to continue?
          </span>
        </div>
        <div className={classes.ModalFooter}>
          <div className={classes.ButtonContainer}>
            <Button
              type="button"
              className="Ghost"
              width="192px"
              onClick={closeSaveModal}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="Primary"
              width="192px"
              onClick={() => {
                setTimeout(() => {
                  saveScreenComponents();
                }, 1);
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <UpgradeModal
        setIsOpenModal={setIsOpenUpgradeModal}
        isOpenModal={isOpenUpgradeModal}
      />
    </Content>
  );
};

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    platformId: state.userInfo.selectedApp.PlatformId,
    userId: state.userInfo.userId,
    productListData: state.productList.data.productList,
    saveScreenRes: state.saveScreen.data,
    loading: state.productList.loading,
    reset: state.productList.reset,
    colors: state.productList.data.colors,
    fonts: state.productList.data.fonts,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _getProductListData: (reqObject: IGetProductListDataRequest) =>
      dispatch(getDesignData(reqObject)),
    _resetHomeData: () => dispatch(resetHomeData()),
    _saveScreenData: (reqObject: ISaveScreenModel) =>
      dispatch(saveScreenData(reqObject)),
    _resetSaveScreenData: () => dispatch(resetSaveScreenData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
