import React from "react";
import Container from "./Container";
import Label from "./Label";
import Wrapper from "./Wrapper";

interface IRadioButtonGroup {
  title: string;
  style?: object;
  children: React.ReactNode;
  flexDirection?: "row" | "column";
}

const RadioButtonGroup = ({
  title,
  style,
  children,
  flexDirection = "row",
}: IRadioButtonGroup) => {
  return (
    <Wrapper style={style}>
      <Label>{title}</Label>
      <Container flexDirection={flexDirection}>{children}</Container>
    </Wrapper>
  );
};

export default RadioButtonGroup;
