import React from "react";
import StarIcon from "panel/src/assets/images/icons-dark-gray-star-filled.svg";
import InfoIcon from "panel/src/assets/images/information.svg";
import classes from "./tooltip.module.scss";
import Popup from "reactjs-popup";
import Card from "./Card";
import IconContainer from "./IconContainer";
import Image from "./Image";
import { useSelector } from "react-redux";

interface ITooltip {
  label: string;
  info?: boolean;
  customStyle?: any;
  customDescription?: string;
}

const UpgradeTooltip = ({
  label,
  info,
  customStyle,
  customDescription,
}: ITooltip) => {
  const { PackageName, UpperPackName } = useSelector(
    (store: any) => store?.userInfo?.UserPackage
  );
  return (
    <Popup
      trigger={() => (
        <IconContainer>
          <Image
            style={{ width: 15, height: 15, ...customStyle }}
            src={info ? InfoIcon : StarIcon}
          />
        </IconContainer>
      )}
      position={"top right"}
      on={["hover"]}
      arrow
      arrowStyle={{ color: "#3e5e6e", marginTop: "1px" }}
    >
      <Card>
        {info ? (
          customDescription ? (
            <span className={classes.TextLayout}>{customDescription}</span>
          ) : (
            <span className={classes.TextLayout}>
              This feature is not used in the {PackageName} package. Upgrade to
              the
              <span className={classes.GrowthText}>
                <a
                  style={{ color: "#ff8360" }}
                  href="https://mowico.com/pricing/"
                  target="_blank"
                >
                  {` ${UpperPackName} `}
                </a>
              </span>
              pack.
            </span>
          )
        ) : (
          <span className={classes.TextLayout}>
            {label} is only available with our
            <span className={classes.GrowthText}>
              <a
                style={{ color: "#ff8360" }}
                href="https://mowico.com/pricing/"
                target="_blank"
              >
                {` ${UpperPackName} `}
              </a>
            </span>
            plan.
          </span>
        )}
      </Card>
    </Popup>
  );
};

export default UpgradeTooltip;
