import React from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";

interface IPublish {
  isDemoSent: boolean;
}

const Publish = ({ isDemoSent }: IPublish) => {
  return (
    <Switch>
      <Route
        exact
        path='/publish'
        render={() => {
          return isDemoSent ? (
            <Redirect to='/publish/install-to-store' />
          ) : (
            <Redirect to='/publish/qr' />
          );
        }}
      />
    </Switch>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isDemoSent: state.userInfo.selectedApp.IsStep12Completed,
  };
};

export default connect(mapStateToProps, null)(Publish);
