import { put, call, takeLatest } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import IResendDemoRequest from "../../models/Publish/resendDemo";
import IGetDemoInfoResponse from "../../models/Publish/demoInfoResponse";
import { demoInfoSuccess, demoInfoFail } from "panel/src/redux/actions/demoInfo";

import {
  resendDemoLinkSuccess,
  resendDemoLinkFail,
} from "panel/src/redux/actions/resendDemo";
import { RESEND_DEMO } from "panel/src/redux/constants/askDemo";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";

function* resendDemoLink(payload: {
  requestObj: IResendDemoRequest;
  type: string;
}) {
  yield put(startAppLoading(RESEND_DEMO));
  try {
    const resendDemoResponse: IResendDemoRequest = yield call(
      axios.post,
      "api/Manage/ResendDemo",
      {
        ApplicationId: payload.requestObj.applicationId,
      }
    );
    yield put(resendDemoLinkSuccess(resendDemoResponse));
    try {
      const demoInfoResponse: IGetDemoInfoResponse = yield call(
        axios.post,
        "api/Manage/GetDemoInfo",
        {
          ApplicationId: payload.requestObj.applicationId,
        }
      );
      yield put(demoInfoSuccess(demoInfoResponse.data.ResultData));
    } catch (error) {
      yield put(demoInfoFail(error.message));
    }
  } catch (error) {
    yield put(resendDemoLinkFail(error.message));
  }
  yield put(endAppLoading(RESEND_DEMO));
}

export default function* resendDemoLinkSaga() {
  yield takeLatest(RESEND_DEMO, resendDemoLink);
}
