import React, { useState, useEffect, useCallback, useMemo } from "react";
import classes from "./createNewLanguage.module.scss";
import Select from "panel/src/_components/common/Select";
import SelectModel from "../../../../models/Design/selectModel";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as generalActions from "panel/src/redux/actions/generalSettings";
import {
  GetGeneralSettingsResponseModel,
  Language,
} from "../../../../models/Design/generalSettings/getGeneralSettingsResponse";
import { GetGeneralSettingsRequestModel } from "../../../../models/Design/generalSettings/getGeneralSettingsRequestModel";
import SelectLanguageModel from "../../../../models/Manage/selectLanguageModel";

import Button from "panel/src/_components/common/Button";
import ErrorField from "panel/src/_components/common/ErrorField";
import { History } from "history";
import EngageNavigation from "panel/src/_components/primitives/EngageNavigation";
import AddIcon from "panel/src/assets/images/addBlue.svg";
import Spinner from "../../../../_components/common/Spinner";
import Dropdown from "../../../../Components/Dropdown";
import Modal from "../../../../_components/common/Modal";
import { SetInsertLanguageRequestModel } from "../../../../models/Design/generalSettings/setInsertLanguageRequestModel";
import close from "../../../../assets/images/close.svg";
import tick from "../../../../assets/images/tick.svg";
import UpgradeModal from "../../../../Components/UpgradeModal";
import useIntercomChat from "../../../../Components/Intercom/useIntercomChat";

interface IProps {
  appId?: string;
  generalActions?: any;
  generalSettings: GetGeneralSettingsResponseModel["ResultData"];
  history: History;
  allLanguages?: any;
  insertMessage?: string | undefined;
  packageFeatures: any;
}

const CreateNewLanguage = (props: IProps) => {
  const [referanceLanguageId, setReferanceLanguageId] = useState("");
  const [referanceLanguageName, setReferanceLanguageName] = useState("");
  const [createLanguageId, setCreateLanguageId] = useState("");
  const [createLanguageName, setCreateLanguageName] = useState("");
  const [activeLanguages, setActiveLanguages] = useState([
    { value: "", displayValue: "", isSelected: true, isComplated: false },
  ]);
  const [inactiveLanguages, setInactiveLanguages] = useState([
    { value: "", displayValue: "", isSelected: true, isComplated: false },
  ]);
  const [isReferanceLanguageError, setReferanceLanguageError] = useState(false);
  const [isCreateLanguageError, setCreateLanguageError] = useState(false);
  const [newLanguageModalVisible, setNewLanguageModalVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [showScreenInsertLanguage, setShowScreenInsertLanguage] =
    useState<boolean>(false);
  const [isUpgradeModal, setIsUpgradeModal] = useState<boolean>(false);

  useIntercomChat({});
  const packageFeaturesLimited = useMemo(
    () =>
      (props.packageFeatures?.find((s: any) => s.Id === 1)?.MaxCount || -1) >
      -1,
    [props.packageFeatures]
  );

  useEffect(() => {
    const getGeneralSettingsData = async () => {
      if (props.appId) {
        const requestObj: GetGeneralSettingsRequestModel = {
          ApplicationId: props.appId,
        };
        await props.generalActions.getGeneralSettingsStart(requestObj);
        await props.generalActions.getAllLanguageStart();
      }
    };
    getGeneralSettingsData();
  }, [props.appId]);

  useEffect(() => {
    if (props.generalSettings) {
      const { LanguageList } = props.generalSettings;

      const selectedOption = LanguageList && LanguageList.SelectedLanguage;
      const selectedId = selectedOption ? selectedOption.LanguageID : "";
      const selectedName = selectedOption ? selectedOption.Name : "";
      setReferanceLanguageId(selectedId.toString());
      setReferanceLanguageName(selectedName);

      let languageOptionList: SelectLanguageModel[] = [];
      LanguageList &&
        LanguageList.Languages.map((item: Language, index: number) => {
          if (selectedId == item.LanguageID) {
            languageOptionList.push({
              value: item.LanguageID.toString(),
              displayValue: item.Name,
              isClientActive: item.IsClientActive,
              cultureSettingKey: item.CultureSettingKey,
              isSelected: true,
              isComplated: false,
              isUsed: item.IsUsed,
            });
          } else {
            languageOptionList.push({
              value: item.LanguageID.toString(),
              displayValue: item.Name,
              isClientActive: item.IsClientActive,
              cultureSettingKey: item.CultureSettingKey,
              isSelected: false,
              isComplated: false,
              isUsed: item.IsUsed,
            });
          }
        });

      setActiveLanguages(languageOptionList);
      setInactiveLanguages(languageOptionList);
    }
  }, [props.generalSettings]);

  const selectReferanceLanguage = (
    e: React.ChangeEvent<SelectModel & HTMLSelectElement>
  ) => {
    const updatedOptions = activeLanguages.map((option: SelectModel) => {
      return {
        ...option,
        isSelected: option.value === e.target.value,
      };
    });

    setActiveLanguages(updatedOptions);
    setReferanceLanguageId(e.target.value);
    setReferanceLanguageName(e.target.displayValue);
    setReferanceLanguageError(false);
  };

  const selectCreatedLanguage = (
    e: React.ChangeEvent<SelectModel & HTMLSelectElement>
  ) => {
    const updatedOptions = inactiveLanguages.map((option: SelectModel) => {
      return {
        ...option,
        isSelected: option.value === e.target.value,
      };
    });

    setInactiveLanguages(updatedOptions);
    setCreateLanguageId(e.target.value);
    setCreateLanguageName(e.target.displayValue);
    setCreateLanguageError(false);
  };

  const submitHandler = () => {
    if (!referanceLanguageId && !createLanguageId) {
      setCreateLanguageError(true);
      setReferanceLanguageError(true);
      return;
    } else if (!referanceLanguageId) {
      setReferanceLanguageError(true);
      return;
    } else if (!createLanguageId) {
      setCreateLanguageError(true);
      return;
    }
    props.history.push(
      `/engage/content-settings/create-new-language/${referanceLanguageId}/${referanceLanguageName}/${createLanguageId}/${createLanguageName}`
    );
  };
  const onClickAddButton = useCallback(() => {
    if (packageFeaturesLimited) {
      setIsUpgradeModal(true);
      return;
    }
    setNewLanguageModalVisible(true);
  }, []);

  const onClickCreateButton = () => {
    const setInsertLanguageData = async () => {
      if (props.appId) {
        let requestObj: SetInsertLanguageRequestModel = {
          ApplicationId: props.appId,
          LanguageName: "",
          CultureSettingKey: "",
        };
        props.allLanguages?.map(
          (item: {
            LanguageId: number;
            Name: string;
            CultureSettingKey: string;
          }) => {
            if (`${selectedLanguage}` === `${item.LanguageId}`) {
              requestObj.LanguageName = item.Name;
              requestObj.CultureSettingKey = item.CultureSettingKey;
            }
          }
        );
        await props.generalActions.setInsertLanguageStart(requestObj);
        setNewLanguageModalVisible(false);
        setShowScreenInsertLanguage(true);
      }
    };
    setInsertLanguageData();
  };

  const handlePropertyChange = (val: any) => {
    setSelectedLanguage(val?.target?.value);
  };

  const onCloseModal = () => {
    setShowScreenInsertLanguage(false);
    setSelectedLanguage("");
    const reqObj: GetGeneralSettingsRequestModel = {
      ApplicationId: props.appId,
    };
    props.generalActions.getGeneralSettingsStart(reqObj);
  };
  return (
    <React.Fragment>
      <EngageNavigation />
      <div className={classes.contentContainer}>
        <div className={classes.EngageSubRouteContainer}>
          <h3 className={classes.Heading}>Create a New Language</h3>
          <p className={classes.Info}>
            You may create a new language for your mobile application by simply
            selecting a referral language, and the new language you like to add.
          </p>
          <div className={classes.LanguageContainer}>
            <div className={classes.LanguageItem}>
              <span className={classes.BlockLabel}>
                Select Language as Reference
              </span>
              <Select
                options={activeLanguages}
                value={referanceLanguageId}
                placeholder="Choose..."
                name="language"
                changeHandler={selectReferanceLanguage}
                isUsedEnable={false}
              />
              {isReferanceLanguageError && (
                <ErrorField text="A language must be selected first." />
              )}
            </div>
            <div className={classes.LanguageItem}>
              <span className={classes.BlockLabel}>
                Select Language to Create
              </span>
              <Select
                options={inactiveLanguages}
                value={createLanguageId}
                placeholder="Choose..."
                name="language"
                changeHandler={selectCreatedLanguage}
                isUsedEnable={true}
                isAddButton={true}
                isAddButtonLabel={"Add New Language"}
                onClickAddButton={onClickAddButton}
              />
              {isCreateLanguageError && (
                <ErrorField text="A language must be selected first." />
              )}
            </div>
          </div>
        </div>
        <div className={classes.SaveButtonContainer}>
          <Button width="160px" height="40px" onClick={submitHandler}>
            Create
          </Button>
        </div>
      </div>
      <Modal
        show={newLanguageModalVisible}
        width="450"
        style={{ alignItems: "flex-start" }}
      >
        <Spinner.Default isLoading={false} />
        <div className={classes.ModalTitle}>
          <h5 className={classes.ModalTitleText}>Add New Language</h5>
        </div>

        <div className={`${classes.ModalBody}`} style={{ marginTop: "15px" }}>
          <div>
            <p>Please select the language you want to create.</p>

            <div className={classes.ModalForm}>
              <Dropdown
                loading={false}
                searchable={false}
                positionValue={true}
                disabled={false}
                filterable={true}
                id=""
                style={{}}
                options={
                  props.allLanguages &&
                  props.allLanguages.map((item: any) => {
                    return { label: item.Name, value: item.LanguageId };
                  })
                }
                label=""
                placeholder="Select a new language"
                value={selectedLanguage}
                onChangeHandler={(val: React.ReactText) =>
                  handlePropertyChange(val)
                }
              />
            </div>
          </div>
        </div>
        <div className={classes.ModalFooter}>
          <div className={classes.ButtonContainer}>
            <Button
              type="button"
              className="Ghost"
              width="48%"
              onClick={() => {
                setNewLanguageModalVisible(false);
                setSelectedLanguage("");
              }}
              disabled={false}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="Primary"
              width="48%"
              onClick={() => onClickCreateButton()}
              disableButton={!selectedLanguage}
            >
              Create
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        show={showScreenInsertLanguage && props.insertMessage}
        backdropClickedHandler={() => onCloseModal()}
        width="500"
      >
        <div className={classes.Close} onClick={() => onCloseModal()}>
          <img src={close} />
        </div>
        <img
          className={classes.ConfirmIcon}
          src={tick}
          width={48}
          height={48}
        />
        <div className={classes.Container}>
          <p className={classes.Description}>{props.insertMessage}</p>
          <Button
            type="button"
            className="Primary"
            width="192px"
            onClick={() => onCloseModal()}
            marginTop="40px"
          >
            Ok
          </Button>
        </div>
      </Modal>
      <UpgradeModal
        setIsOpenModal={setIsUpgradeModal}
        isOpenModal={isUpgradeModal}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
  };
};

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    generalSettings: state.generalSettings.generalSettings,
    allLanguages: state.generalSettings.getAllLanguage,
    insertMessage: state.generalSettings.insertMessage,
    packageFeatures:
      state.packageFeature?.featureData?.PackageFeature?.Features,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewLanguage);
