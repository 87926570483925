import styled from "styled-components";

export const ComponentsContainer = styled.div`
  height: 100%;
  width: 272px;
  border-right: 1px solid #e7eaec;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;
