import {
  GET_NAVIGATION_MENU,
  GET_NAVIGATION_MENU_SUCCESS,
  GET_NAVIGATION_MENU_FAILED,
  SET_NAVIGATION_MENU,
  UPDATE_NAVIGATION_MENU,
  UPDATE_NAVIGATION_MENU_SUCCESS,
  UPDATE_NAVIGATION_MENU_FAILED,
} from "panel/src/redux/constants/navigationMenu";

export const getNavigationMenu = () => ({
  type: GET_NAVIGATION_MENU,
});

export const getNavigationMenuSuccess = (data: any) => ({
  type: GET_NAVIGATION_MENU_SUCCESS,
  data,
});

export const getNavigationMenuFailed = () => ({
  type: GET_NAVIGATION_MENU_FAILED,
});

export const setNavigationMenu = (data: any) => ({
  type: SET_NAVIGATION_MENU,
  data,
});

export const updateNavigationMenu = () => ({
  type: UPDATE_NAVIGATION_MENU,
});

export const updateNavigationMenuSuccess = () => ({
  type: UPDATE_NAVIGATION_MENU_SUCCESS,
});

export const updateNavigationMenuFailed = () => ({
  type: UPDATE_NAVIGATION_MENU_FAILED,
});