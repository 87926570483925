import * as Yup from "yup";
import { ELinkTypes } from "../../utils/enums/design";
import {
  notificationImageExtensionMessage,
  notificationImageSizeMessage,
} from "../../utils/validationMessage";

const IMAGE_SIZE = 300000;
const SUPPORTED_FORMATS = ["image/png", "image/jpeg"];

const Schema = Yup.object().shape({
  title: Yup.string()
    .required("Title can't be blank.")
    .max(255, "Title can't be longer than 50 characters."),
  body: Yup.string()
    .required("Body can't be blank.")
    .max(255, "Body can't be longer than 200 characters."),
  imageExtension: Yup.string()
    .required(notificationImageExtensionMessage)
    .test(
      "imageExtension",
      notificationImageExtensionMessage,
      (value) => value && SUPPORTED_FORMATS.includes(value)
    ),
  imageSize: Yup.number().max(IMAGE_SIZE, notificationImageSizeMessage),
  Link: Yup.object().shape({
    CategoryId: Yup.string()
      .nullable()
      .when("Type", {
        is: (val) => val === ELinkTypes.Category,
        then: Yup.string().required("Category can't be blank."),
      }),
    ProductId: Yup.string()
      .nullable()
      .when("Type", {
        is: (val) => val === ELinkTypes.Product,
        then: Yup.string().required("Please enter a valid product"),
      }),
    ProductName: Yup.string()
      .nullable()
      .when("Type", {
        is: (val) => val === ELinkTypes.Product,
        then: Yup.string().required(
          "Please enter a valid product. You must select a product from the list."
        ),
      }),
  }),
});

export default Schema;
