import React from "react";
import Svg, { Path, G } from "react-native-svg";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
}

const ArrowRightSmall = ({ width, height, fillColor }: IProps) => (
  <Svg width={width} height={height} viewBox="0 0 24 24">
    <G fill="none" fillRule="evenodd">
      <G>
        <Path d="M0 0H24V24H0z" />
        <Path
          fill={fillColor}
          d="M16.666 12.845l-5.669 5.804c-.45.462-1.188.469-1.648.016-.46-.453-.466-1.194-.015-1.656L14.226 12 9.334 6.99c-.451-.46-.444-1.202.015-1.655.46-.453 1.198-.446 1.648.016l5.67 5.804c.228.235.34.54.333.845.006.304-.105.61-.334.845z"
        />
      </G>
    </G>
  </Svg>
);

export default ArrowRightSmall;
