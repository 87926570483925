import axios, { AxiosResponse } from "axios";

const {
  REACT_APP_FIREBASE_WEB_API_KEY,
  REACT_APP_FIREBASE_DEEP_LINK_PREFIX,
  REACT_APP_FIREBASE_ANDROID_IDENTIFIER,
  REACT_APP_FIREBASE_IOS_IDENTIFIER,
  REACT_APP_FIREBASE_IOS_APPSTORE_ID,
} = process.env;

export const shortLinksService = async (
  url: string
): Promise<
  AxiosResponse<{
    shortLink: string;
    previewLink: string;
  }>
> => {
  return await axios.post(
    `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${REACT_APP_FIREBASE_WEB_API_KEY}`,
    {
      dynamicLinkInfo: {
        domainUriPrefix: REACT_APP_FIREBASE_DEEP_LINK_PREFIX,
        link: url,
        androidInfo: {
          androidPackageName: REACT_APP_FIREBASE_ANDROID_IDENTIFIER,
        },
        iosInfo: {
          iosBundleId: REACT_APP_FIREBASE_IOS_IDENTIFIER,
          iosAppStoreId: REACT_APP_FIREBASE_IOS_APPSTORE_ID,
        },
      },
    }
  );
};
