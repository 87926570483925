import React from "react";
import classes from "./spinner.module.scss";

const Small = () => {
  return (
    <div className={classes.center}>
      <div className={`${classes.image} ${classes.smallImage}`}>
        <img
          src={require("panel/src/assets/images/mowico_loading.gif")}
          width="32px"
          alt="mowico_loading"
        />
      </div>
    </div>
  );
};

export default Small;
