import {
  GET_SPLASH_SCREEN_START,
  GET_SPLASH_SCREEN_FINISH,
  GET_SPLASH_SCREEN_REJECTED,
  SET_SPLASH_SCREEN_START,
  SET_SPLASH_SCREEN_FINISH,
  SET_SPLASH_SCREEN_REJECTED,
} from "panel/src/redux/constants/splashScreen";
import { SetSplashModule } from "../../models/Design/Splash";

export const getSplashScreenStart = (appId: any) => {
  return {
    type: GET_SPLASH_SCREEN_START,
    appId,
  };
};

export const getSplashScreenFinish = (splashScreenData: any) => {
  return {
    type: GET_SPLASH_SCREEN_FINISH,
    splashScreenData,
  };
};

export const getSplashScreenRejected = (error: string) => ({
  type: GET_SPLASH_SCREEN_REJECTED,
  error,
});

export const setSplashScreenStart = (
  splashScreenObj: SetSplashModule.Request,
  changeScreen: Function
) => {
  return {
    type: SET_SPLASH_SCREEN_START,
    splashScreenObj,
    changeScreen,
  };
};

export const setSplashScreenFinish = () => {
  return {
    type: SET_SPLASH_SCREEN_FINISH,
  };
};

export const setSplashScreenRejected = (error: string) => ({
  type: SET_SPLASH_SCREEN_REJECTED,
  error,
});
