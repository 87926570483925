import React, { FC, useRef, CSSProperties } from 'react';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';
import EditIcon from './Icons/Edit';
import VisibleIcon from './Icons/Visible';
import HiddenIcon from './Icons/Hidden';
import HandlerIcon from './Icons/Handler';

import type { Identifier, XYCoord } from 'dnd-core';
import { TMenuItem } from './MenuEditor';
import changePngColor from '../../utils/pngColorHelper';

type MenuItemProps = TMenuItem & { 
  index: number;
  disableDropIndexes?: number[];
  moveItem: Function;
  toggleEnabled: (index: number, isEnabled: boolean) => void;
  editIcon: (index: number) => void;
  isTabBar?: boolean;
  isCentered?: boolean;
  withoutIcon?: boolean;
  style?: CSSProperties;
};

type StyleProps = {
  isEnabled?: boolean;
  isActive?: boolean;
  isSortable?: boolean;
  isTabBar?: boolean;
};

const Container = styled.div<StyleProps>`
  display: flex;
  font-size: 14px;
  color: ${(props) => !!props?.isActive ? '#3e5e6e' : '#9fafb6'};
  margin-bottom: 8px;
  ${(props) => props?.isSortable && (`
    cursor: move;
  `)}
`;

const ContentContainer = styled.div<{ isCentered?: boolean }>`
  display: flex;
  border-radius: 4px;
  height: 40px;
  align-items: center;
  background-color: #e7eaec;
  padding: 8px 16px;
  flex-grow: 1;
  ${({ isCentered }) => isCentered && `
    padding-left: 40px;
  `};
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`;

//
// Icons must be black in color and have a transparent background.
// You can use `changePngColor('#0f3649')` helper function to get css filters.
//

const defaultIconColorFilter = `
  invert(34%) sepia(17%) saturate(930%) hue-rotate(156deg) brightness(92%) contrast(87%);
`
const disabledIconColorFilter = `
  invert(73%) sepia(13%) saturate(273%) hue-rotate(153deg) brightness(93%) contrast(88%);
`;

const IconImage = styled.img<StyleProps>`
  width: 10px;
  height: 10px;
  object-fit: contain;
  filter: ${({ isActive }) => !!isActive ? defaultIconColorFilter : disabledIconColorFilter };
`;

const Text = styled.div`
  font-weight: 500;
`;

const Actions = styled.div<StyleProps>`
  display: flex;
  margin-left: auto;
  color: ${(props) => !props?.isActive || !props.isEnabled ?  '#9fafb6': '#3196f6'};
  ${props => props.isTabBar && `padding-left: 10px;`}
`;

const Button = styled.button`
  border: 0;
  width: 20px;
  height: 20px;
  background-color: #f3f5f6;
  cursor: pointer;
  overflow: hidden;
  margin-left: 4px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
`;

const DragHandler = styled.div<StyleProps>`
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9fafb6;
  ${props => !props.isSortable && `opacity: 0;`}
  ${props => !props.isSortable && props.isTabBar && `
    padding: 0 5px;
    & > * {
      display: none;
    }
  `};
`;

interface DragItem {
  index: number
  id: string
  type: string
}

const MenuItem: FC<MenuItemProps> = ({
  Name,
  IconUrl,
  IsEnabled,
  IsActive,
  IsSortable,
  index,
  moveItem,
  toggleEnabled,
  disableDropIndexes = [],
  editIcon,
  isTabBar,
  isCentered,
  withoutIcon,
  style,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (disableDropIndexes.length && disableDropIndexes.includes(hoverIndex)) {
        return
      }
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveItem(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    canDrag: IsSortable,
    item: () => {
      return { index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref));

  const onClickEditIcon = () => {
    if (IsEnabled && IsActive && editIcon) {
      editIcon(index);
    }
  }

  const onClickVisibility = () => {
    if (IsActive) {
      toggleEnabled(index, IsEnabled);
    }
  }

  const styleProps: CSSProperties = {
    ...style,
  };
  
  return (
    <Container
      ref={ref}
      isActive={IsActive}
      style={styleProps}
      isSortable={IsSortable}
      data-handler-id={handlerId}
    >
      <ContentContainer isCentered={isCentered}>
        {(!withoutIcon || index === 0) && (
          <Icon>
            <IconImage src={IconUrl} isActive={IsActive} />
          </Icon>
        )}
        <Text>{Name}</Text>
        <Actions isActive={IsActive} isEnabled={IsEnabled} isTabBar={isTabBar}>
          {IsSortable && (
            <Button onClick={onClickVisibility}>
              {IsEnabled ? <VisibleIcon /> : <HiddenIcon />}            
            </Button>
          )}
          {(!withoutIcon || index === 0) && <Button onClick={onClickEditIcon}><EditIcon /></Button>}
        </Actions>
      </ContentContainer>
      <DragHandler isSortable={IsSortable} isTabBar={isTabBar}>
        <HandlerIcon />
      </DragHandler>
    </Container>
  );
};

export default MenuItem;
