import React, { useEffect, useMemo, useRef, useState } from "react";
import SelectModel from "../../models/Design/selectModel";
import { connect } from "react-redux";
import informationIcon from "../../assets/images/information.svg";
import classes from "./notification.module.scss";
import { History } from "history";
import { Helmet } from "react-helmet";
import { ScreenType } from "../../utils/enums/screenType";
import Emulator from "../../Components/DragnDrop/Emulator";
import { Field, Form, Formik, FormikProps } from "formik";
import Input from "../../_components/common/Input";
import ErrorField from "../../_components/common/ErrorField";
import Upload from "../../_components/common/Upload";
import { EPages } from "../../utils/enums/pageType";
import { NotificationModule } from "../../models/Notification";
import { getGeneralSettingsStart } from "../../redux/actions/generalSettings";
import { GetGeneralSettingsRequestModel } from "../../models/Design/generalSettings/getGeneralSettingsRequestModel";
import {
  getNotifications,
  sendNotification,
} from "../../redux/actions/notification";
import ScreenSelection from "./ScreenSelection";
import { dateToString, fileToBase64 } from "../../utils/helper";
import notificationValidation from "./validationSchema";
import { ELinkTypes } from "../../utils/enums/design";
import { ComponentProductModule } from "../../models/Design/home/ComponentProduct";
import { getProductListByCategoryId } from "../../redux/actions/productList";
import Dropdown from "../../Components/Dropdown";
import Select from "./Select";
import InfoPopup from "../../Components/InfoPopup";
import UpgradeComponent from "../../_components/common/UpgradeComponent";
import { getPackageFeatureListStart } from "../../redux/actions/getPackageFeature";
import UpgradeModal from "../../Components/UpgradeModal";
import useIntercomChat from "../../Components/Intercom/useIntercomChat";

interface IDesignProps {
  history: History;
  _resetHomeData: Function;
  _getColorsPageData: Function;
  appId: string;
  appName: string;
  iosIcon: string;
  androidIcon: string;
  productListLoading: boolean;
  packageFeature: any;
  packageFeatures: any;
  _getProductListByCategoryId: (
    data: ComponentProductModule.TGetProductListByCategoryIdData
  ) => void;
  productList: ComponentProductModule.IProduct[];
  categories: { label: string; value: string }[];
  notifications: NotificationModule.NotificationResponse[];
  _getGeneralSettingsStart: Function;
  _sendNotification: Function;
  _getNotifications: Function;
  _getPackageFeatureListStart: Function;
}

const IMAGE_EXTENSION = "image/png";
const IMAGE_SIZE = 300;

let timeout: ReturnType<typeof setTimeout> = setTimeout(() => "", 1000);

const Notification = ({
  appId,
  appName,
  iosIcon,
  androidIcon,
  notifications,
  categories,
  productList,
  productListLoading,
  _getProductListByCategoryId,
  _getGeneralSettingsStart,
  _sendNotification,
  _getNotifications,
  packageFeature,
  packageFeatures,
  _getPackageFeatureListStart,
}: IDesignProps) => {
  const formikRef = useRef<FormikProps<any> | null>(null);
  const [notificationType, setNotificationType] = useState("0");
  useIntercomChat({});

  const initialValues = {
    notificationId: "",
    title: "",
    body: "",
    imageTemp: null,
    image: null,
    imageExtension: IMAGE_EXTENSION,
    imageSize: IMAGE_SIZE,
    Link: {
      Type: -1,
      CategoryId: null,
      ProductId: null,
      ProductName: null,
    },
  };

  const [notification, setNotification] =
    useState<NotificationModule.Notification>({
      title: "",
      body: "",
    });
  const [notifPackageFeature, setNotifPackageFeature] = useState<any>([]);
  const [isComponentDisabled, setIsComponentDisabled] =
    useState<boolean>(false);
  const [isOpenUpgradeModal, setIsOpenUpgradeModal] = useState<boolean>(false);

  const packageFeaturesLimited = useMemo(
    () => (packageFeatures?.find((s: any) => s.Id === 1)?.MaxCount || -1) > -1,
    [packageFeatures]
  );
  const notificationFeature = useMemo(
    () => packageFeatures?.find((s: any) => s.Id === 2),
    [packageFeatures]
  );

  useEffect(() => {
    const requestObj: GetGeneralSettingsRequestModel = {
      ApplicationId: appId,
    };
    _getGeneralSettingsStart(requestObj);
    _getNotifications();
    const packageFeatureObj = {
      applicationId: appId,
    };
    _getPackageFeatureListStart(packageFeatureObj);
  }, []);

  useEffect(() => {
    if (notifications && Number(notificationType) === 1) {
      getNotification(Number(notifications[0]?.Id));
    }
  }, [notifications, notificationType]);

  useEffect(() => {
    let tmpIndex = packageFeature?.PackageFeature?.Features.findIndex(
      (obj: { Name: string }) => obj.Name === "Push Notifications"
    );
    let currentFeature = packageFeature?.PackageFeature?.Features[tmpIndex];
    setNotifPackageFeature(currentFeature);
    if (
      currentFeature?.MaxCount === currentFeature?.UsedCount &&
      packageFeaturesLimited
    ) {
      setIsComponentDisabled(true);
    } else {
      setIsComponentDisabled(false);
    }
  }, [packageFeature]);

  const onSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotificationType(e.target.value);
    formikRef.current?.setValues(initialValues);
    setNotification({
      title: "",
      body: "",
      image: null,
    });
  };
  const getNotification = async (id: number) => {
    const item = notifications.find((s) => s.Id === id);
    if (item) {
      const isThereCategory =
        item.CategoryId !== null && item.CategoryId !== "";
      const isThereProduct = item.ProductId !== null && item.ProductId !== "";
      let linkType = -1;
      if (isThereCategory) {
        if (isThereProduct) linkType = ELinkTypes.Product;
        else linkType = ELinkTypes.Category;
      }

      setNotification({
        title: item.Title,
        body: item.Body,
        image: item.Image || null,
      });

      formikRef.current?.setValues({
        notificationId: item.Id,
        title: item.Title,
        body: item.Body,
        imageExtension: IMAGE_EXTENSION,
        imageSize: IMAGE_SIZE,
        imageTemp: item.Image
          ? {
              name: "mowico_notification_image.png",
            }
          : null,
        image: item.Image ? item.Image : null,
        Link: {
          Type: linkType,
          CategoryId: item.CategoryId,
          ProductId: item.ProductId,
          ProductName: item?.ProductName,
        },
      });
    }
  };

  const onChangeFileInput = async (fileInput: any) => {
    var file = fileInput.files[0];
    if (file) {
      const res = await fileToBase64(file);
      var _URL = window.URL || window.webkitURL;
      var img = new Image();
      img.onload = () => {
        formikRef.current?.setValues({
          ...formikRef.current.values,
          imageSize: file.size,
          imageExtension: file.type,
          image: res as string,
        });
        setNotification({
          ...notification,
          image: res as string,
        });
      };
      img.onerror = function () {
        console.log("not a valid file: " + file.type);
      };
      img.src = _URL.createObjectURL(file);
    } else {
      formikRef.current?.setValues({
        ...formikRef.current.values,
        imageExtension: IMAGE_EXTENSION,
        image: "",
      });
      setNotification({
        ...notification,
        image: null,
      });
    }
  };

  const onSubmitForm = (values: any) => {
    let request: NotificationModule.Notification = values;
    request.CategoryId = values.Link.CategoryId ?? null;
    request.ProductId = values.Link.ProductId ?? null;
    request.ProductName = values.Link.ProductName ?? null;
    _sendNotification(request);
    setTimeout(() => {
      const packageFeatureObj = {
        applicationId: appId,
      };
      _getPackageFeatureListStart(packageFeatureObj);
    }, 500);
  };

  const getProductList = (search: string) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      _getProductListByCategoryId({
        CategoryId: formikRef.current?.values.CategoryId,
        SearchWord: search,
      });
    }, 500);
  };

  const notificationsList = notifications
    ?.sort((a, b) => (a.Id > b.Id ? -1 : b.Id > a.Id ? 1 : 0))
    .map((item) => ({
      value: item.Id,
      displayValue: item.Title,
      time: item.SendTime ? dateToString(item.SendTime) : null,
    }));

  const notificationSelects: SelectModel[] = [
    {
      value: "0",
      displayValue: "New notification",
      isSelected: true,
      isComplated: true,
      isDisabled: false,
    },
    {
      value: "1",
      displayValue: "Past notifications",
      isSelected: false,
      isComplated: true,
      isDisabled: notificationsList.length <= 0,
    },
  ];

  const linkTypes = [
    { label: "None", value: -1 },
    { label: "Category", value: ELinkTypes.Category },
    { label: "Product", value: ELinkTypes.Product },
  ];

  const productListHandled = productList.map((product) => ({
    label: product.ProductName,
    value: product.Id,
  }));

  const categoriesHandled = categories?.sort((a, b) =>
    a.label > b.label ? 1 : b.label > a.label ? -1 : 0
  );

  const customHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formikRef.current?.handleChange(e);
    setNotification({
      ...notification,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <React.Fragment>
      <Helmet onChangeClientState={() => {}}>
        <title>{`Mowico Wizard Panel | Notification`}</title>
      </Helmet>
      <div className={classes.DesignContainer}>
        <div>
          <div className={classes.DesignConfigContainer}>
            <div>
              <div className={classes.DesignConfigContainerLeft}>
                <ScreenSelection
                  options={notificationSelects}
                  selectedValue={notificationType}
                  selectChangeHandler={onSelectChange}
                />
                <div className={classes.FormContainer}>
                  <Formik
                    enableReinitialize={true}
                    onSubmit={
                      !isComponentDisabled
                        ? onSubmitForm
                        : () => {
                            setIsOpenUpgradeModal(true);
                          }
                    }
                    initialValues={initialValues}
                    validationSchema={
                      !isComponentDisabled && notificationValidation
                    }
                    innerRef={formikRef}
                  >
                    {({
                      handleChange,
                      values,
                      errors,
                      touched,
                      setFieldValue,
                    }) => (
                      <Form>
                        {notificationType === "1" && (
                          <div className={classes.inputGroup}>
                            <Field
                              value={values.notificationId}
                              label={"Past notifications"}
                              placeholder="Past notifications"
                              options={notificationsList}
                              changeHandler={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                getNotification(Number(e.target.value));
                              }}
                              labelMarginBottom={"13px"}
                              name="notificationId"
                              component={Select}
                            />
                          </div>
                        )}
                        <div className={classes.inputWrapper}>
                          {packageFeaturesLimited && (
                            <UpgradeComponent
                              description={`Startup plan is limited to ${notificationFeature?.MaxCount} notification per day.`}
                              maxCount={notificationFeature?.MaxCount}
                              usedCount={notificationFeature?.UsedCount}
                              pageType={"Push Notification"}
                            />
                          )}

                          <div className={classes.inputGroup}>
                            <Field
                              elementType={"textarea"}
                              value={values.title}
                              label={"Title"}
                              name="title"
                              placeholder="Type your title"
                              changeHandler={customHandleChange}
                              labelMarginBottom={"13px"}
                              component={Input}
                              minHeight={80}
                              emoji={true}
                              disabled={isComponentDisabled}
                            />
                            <div className={classes.InfoGroup}>
                              <img
                                src={informationIcon}
                                alt="information"
                                className={classes.Information}
                              />
                              <span className={classes.InfoText}>
                                Maximum 50 characters.
                              </span>
                            </div>
                          </div>
                          <div className={classes.inputGroup}>
                            <Field
                              elementType={"textarea"}
                              value={values.body}
                              label={"Message"}
                              name="body"
                              placeholder="Type your message"
                              changeHandler={customHandleChange}
                              labelMarginBottom={"13px"}
                              component={Input}
                              minHeight={200}
                              emoji={true}
                              disabled={isComponentDisabled}
                            />
                            <div className={classes.InfoGroup}>
                              <img
                                src={informationIcon}
                                alt="information"
                                className={classes.Information}
                              />
                              <span className={classes.InfoText}>
                                Maximum 255 characters.
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${classes.UploadContainer} ${classes.inputGroup}`}
                        >
                          <div>
                            <Field
                              placeholder="File"
                              name="imageTemp"
                              onChange={(e: any) => {
                                onChangeFileInput(e);
                                setFieldValue("imageTemp", e.files[0]);
                              }}
                              label="Image"
                              logoName={values.imageTemp?.name}
                              component={Upload}
                              disabled={isComponentDisabled}
                            />
                            <div className={classes.InfoGroup}>
                              <img
                                src={informationIcon}
                                alt="information"
                                className={classes.Information}
                              />
                              <span className={classes.InfoText}>
                                Image format should be JPEG or PNG, and the size
                                should be a maximum of 300 kb.
                              </span>
                            </div>
                          </div>

                          {errors["image"] && (
                            <ErrorField
                              type={"relative"}
                              text={errors["image"]}
                            />
                          )}
                          {!errors["image"] && errors["imageExtension"] && (
                            <ErrorField
                              type={"relative"}
                              text={errors["imageExtension"]}
                            />
                          )}
                          {!errors["image"] && errors["imageSize"] && (
                            <ErrorField
                              type={"relative"}
                              text={errors["imageSize"]}
                            />
                          )}
                        </div>
                        {categories && categories.length > 0 && (
                          <>
                            <div className={classes.linkType}>
                              <InfoPopup
                                position={"top right"}
                                field={
                                  <Field
                                    id="Link.Type"
                                    name="Link.Type"
                                    style={{ marginBottom: "1rem" }}
                                    label="Link Type"
                                    description="You can optionally select a category or product. If you do not make any selection, the notification will open the homepage directly."
                                    placeholder="Select a type"
                                    options={linkTypes}
                                    value={values.Link.Type}
                                    onChangeHandler={(e: any) => {
                                      handleChange(e);
                                      setFieldValue("Link.CategoryId", "");
                                      setFieldValue("Link.ProductName", "");
                                      setFieldValue("Link.ProductId", "");
                                    }}
                                    handleError={errors}
                                    handleTouched={touched}
                                    component={Dropdown}
                                    positionValue={true}
                                    disabled={isComponentDisabled}
                                  />
                                }
                                descriptions={[
                                  {
                                    key: "Category",
                                    value: `Select a specific category to which the user will be redirected when the notification is touched.`,
                                  },
                                  {
                                    key: "Product",
                                    value: `Select a specific product to which the user will be redirected when the notification is touched.`,
                                  },
                                ]}
                                height={"142"}
                              />
                            </div>

                            <div className={classes.inputGroup}>
                              {(values.Link.Type === ELinkTypes.Category ||
                                values.Link.Type === ELinkTypes.Product) && (
                                <Field
                                  id="Link.CategoryId"
                                  name="Link.CategoryId"
                                  label="Link to"
                                  placeholder="Select a category"
                                  options={categoriesHandled}
                                  value={values.Link.CategoryId}
                                  onChangeHandler={(e: any) => {
                                    handleChange(e);
                                    setFieldValue("Link.ProductId", "");
                                    setFieldValue("Link.ProductName", "");
                                  }}
                                  handleError={errors}
                                  handleTouched={touched}
                                  component={Dropdown}
                                  positionValue={true}
                                />
                              )}
                            </div>
                            <div className={classes.inputGroup}>
                              {values.Link.Type === ELinkTypes.Product && (
                                <Field
                                  id="Link.ProductName"
                                  name="Link.ProductName"
                                  placeholder="Type product name"
                                  value={values.Link.ProductName}
                                  onChangeHandler={(e: any) => {
                                    setFieldValue(
                                      "Link.ProductName",
                                      e.target.label
                                    );
                                    setFieldValue(
                                      "Link.ProductId",
                                      e.target.value
                                    );
                                  }}
                                  handleError={errors}
                                  handleTouched={touched}
                                  component={Dropdown}
                                  positionValue={true}
                                  options={productListHandled}
                                  disabled={!values.Link.CategoryId}
                                  loading={productListLoading}
                                  searchable={true}
                                  onSearchValueChange={(e: any) => {
                                    getProductList(e.target.value);
                                    setFieldValue("Link.ProductName", "");
                                    setFieldValue("Link.ProductId", "");
                                  }}
                                />
                              )}
                            </div>
                          </>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.MobileScreen}>
            <Emulator
              fontFamily={"Red Hat Display"}
              navigateBack={() => {}}
              backTo={ScreenType.ProductDetail.toString()}
              colorList={{}}
              saveScreen={() => formikRef.current?.submitForm()}
              saveScreenText="Send Notification"
              homeScreen={{
                iosIcon: iosIcon,
                androidIcon: androidIcon,
                notification,
                appName,
              }}
              screen={EPages.PushNotification}
            />
          </div>
        </div>
      </div>
      <UpgradeModal
        setIsOpenModal={setIsOpenUpgradeModal}
        isOpenModal={isOpenUpgradeModal}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    appName: state.generalSettings.generalSettings.AppName,
    iosIcon: state.generalSettings.generalSettings.IOSIconUrl,
    androidIcon: state.generalSettings.generalSettings.AndroidIconUrl,
    notifications: state.notification.notifications,
    categories: state.home.data.home?.Categories,
    productList: state.productList.productList,
    productListLoading: state.productList.productListLoading,
    packageFeature: state.packageFeature?.featureData,
    packageFeatures:
      state.packageFeature?.featureData?.PackageFeature?.Features,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _getProductListByCategoryId: (
      data: ComponentProductModule.TGetProductListByCategoryIdData
    ) => dispatch(getProductListByCategoryId(data)),
    _getGeneralSettingsStart: (value: GetGeneralSettingsRequestModel) =>
      dispatch(getGeneralSettingsStart(value)),
    _getPackageFeatureListStart: (value: any) =>
      dispatch(getPackageFeatureListStart(value)),
    _getNotifications: () => dispatch(getNotifications()),
    _sendNotification: (notification: NotificationModule.Notification) =>
      dispatch(sendNotification(notification)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
