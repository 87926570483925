import React from "react";
import Draggable from "../../primitives/Draggable";
import classes from "./hueSlider.module.scss";

interface IProps {
    value: number;
    max: number;
    min: number;
    onChange: Function;
}
const HueSlider = ({ value, max, onChange }: IProps) => {
    const [allowance, setAllowance] = React.useState(0);
    const element = React.useRef<HTMLDivElement>(null);
    const handle = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        setAllowance(handle.current!.getBoundingClientRect().width / 2);
    });

    const clamp = (value: number, min: number, max: number) => {
        return Math.min(Math.max(value, min), max);
    };

    const handleDrag = (position: { x: number; }) => {
        const box = element.current!.getBoundingClientRect();
        const handlex = clamp(position.x, box.left, box.right) - box.left;
        const value = (handlex / box.width) * max;
        onChange(value);
    };

    const x = (value / max) * 100;

    const clampedX = clamp(x, 0, 100 - allowance);

    return (
        <Draggable onDrag={handleDrag}>
            {(dragging: boolean) => (
                <div
                    className={
                        dragging ? `${classes.hueRangePicker} ${classes.dragging}` : `${classes.hueRangePicker}`
                    }
                    ref={element}
                >
                    <div
                        className={classes.handle}
                        style={{ left: `${clampedX}%` }}
                        ref={handle}
                    />
                </div>
            )}
        </Draggable>
    );
}

export default HueSlider;