import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { dimenHeight, dimenWidth } from 'shared-components/src/utils/helper';
import { colors } from 'shared-components/src/utils/constants';

export default styled.TouchableOpacity`
  min-height: ${Platform.OS === 'web' ? '200' : dimenHeight(284)}px;
  width: ${Platform.OS === 'web' ? '102' : dimenWidth(136)}px;
  box-shadow: 0px 0px 5px rgba(15, 54, 73, 0.25);
  background: ${colors.white};
  border-radius: 4px;
`;
