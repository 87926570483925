import { Platform } from "react-native";

const shadow = {
  shadowColor:
    Platform.OS === "android" ? "rgba(15,54,73,0.6)" : "rgba(15,54,73,0.2)",
  shadowOffset: {
    width: 0,
    height: 3,
  },
  shadowOpacity: 1,
  shadowRadius: 5,
  elevation: 5,
  backgroundColor: "white",
};

const colors = {
  waterBlue: "rgb(31, 129, 223)",
  darkSkyBlue: "rgb(49, 150, 246)",
  softBlue: "rgb(77, 168, 255)",

  darkSlateBlue: "rgb(15, 54, 73)",
  slate: "rgb(62, 94, 110)",
  steel: "rgb(109, 134, 148)",
  coolGrey: "rgb(159, 175, 182)",
  lightBlueGrey: "rgb(207, 215, 219)",
  veryLightBlue: "rgb(231, 234, 236)",
  iceBlueTwo: "rgb(243, 245, 246)",
  iceBlue: "rgb(247, 249, 249)",

  white: "rgb(255, 255, 255)",
  darkMint: "rgb(63, 196, 138)",
  coral: "rgb(230, 101, 101)",

  darkSlateBlueOpacity: "rgba(15, 54, 73, .8)",
  coolGreyOpacity: "rgba(159, 175, 182, .5)",
  whiteOpacity: "rgba(255, 255, 255, .5)",
};

const AsyncStorageKeys = {
  LOGIN: "@LOGIN",
  DEVICE_ID: "@DEVICE_ID",
  LAST_SEARCHES: "@LAST_SEARCHES",
  LANGUAGE_CODE: "@LANGUAGE_CODE",
};

export { shadow, colors, AsyncStorageKeys };
