import styled from "styled-components";
import RadioButtonLabel from "./RadioButtonLabel";

export default styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 20px;
  height: 20px;

  ${(props) =>
    props.checked &&
    ` 
  &:checked + ${RadioButtonLabel} {
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background: #3e5e6e;
    }
  }
`}
`;
