import { Platform } from "react-native";
import styled from "styled-components/native";
import { dimenHeight, dimenFont } from "shared-components/src/utils/helper";

interface ICategoryName {
  primary?: string;
  fontFamily?: any;
}

export default styled.Text.attrs({ numberOfLines: 2 })<ICategoryName>`
  font-size: ${Platform.OS === "web" ? "9.1" : dimenFont(12)}px;
  font-family: ${(props) =>
    Platform.OS === "web" ? props.fontFamily : props.fontFamily!.Regular};
  color: ${(props) => props.primary};
  margin-top: ${Platform.OS === "web" ? "5" : dimenHeight(6)}px;
  max-width: ${Platform.OS === "web" ? "55" : dimenHeight(64)}px;
  text-align: center;
`;
