import {
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
} from "panel/src/redux/constants/logout";

interface BaseLoginAction {
  type: string;
  error?: any;
  loading?: any;
}

export const logoutStart = () => {
  return {
    type: LOGOUT_START
  }
};

export const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS,
  }
};

export const logoutRejected = () => {
  return {
    type: LOGOUT_FAIL,
    error: true,
  }
};
