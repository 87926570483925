import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas/rootSaga";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const enhancer = composeWithDevTools(
  applyMiddleware(routerMiddleware(history), sagaMiddleware)
);

export const store = createStore(createRootReducer(history), enhancer);

sagaMiddleware.run(rootSaga);
