import { 
  SET_LOGO_POSITION,
  GET_LOGO_POSITION_START,
  GET_LOGO_POSITION_SUCCESS,
  GET_LOGO_POSITION_FAILED,
  UPDATE_LOGO_POSITION_START,
  UPDATE_LOGO_POSITION_SUCCESS,
  UPDATE_LOGO_POSITION_FAILED,
} from "../constants/logoPosition";

export const setLogoPosition = ( values: any ) => {
  return {
    type: SET_LOGO_POSITION,
    payload: values
  }
};

export const getLogoPositionStart = (values: any) => {
  return {
    type: GET_LOGO_POSITION_START,
    values,
  }
};

export const getLogoPositionSuccess = (values: any) => {
  return {
    type: GET_LOGO_POSITION_SUCCESS,
    payload: values
  }
};

export const getLogoPositionFailed = () => {
  return {
    type: GET_LOGO_POSITION_FAILED,
  }
};

export const updateLogoPositionStart = (values: any) => {
  return {
    type: UPDATE_LOGO_POSITION_START,
    values
  }
};

export const updateLogoPositionSuccess = () => {
  return {
    type: UPDATE_LOGO_POSITION_SUCCESS,
  }
};

export const updateLogoPositionFailed = () => {
  return {
    type: UPDATE_LOGO_POSITION_FAILED,
  }
};

