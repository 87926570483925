import styled from "styled-components";

export default styled.div<{ itemSize?: number}>`
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: ${({ itemSize }) => 
    itemSize && itemSize > 0 ? `repeat(${itemSize}, 1fr)` :  '1fr 1fr 1fr 1fr'
  };
  grid-column: 3;
  height: 56px;
  object-fit: cover;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 -10px 15px -4px rgba(15, 54, 73, 0.1);
`;
