import React, { useEffect } from "react";
import { Form, Field, Formik, FieldArray } from "formik";
import validationSchema from "./validationSchema";
import Input from "panel/src/Components/Input";
import Dropdown from "panel/src/Components/Dropdown";
import Button from "panel/src/Components/Button";
import InputGroup from "panel/src/Components/RadioButtonGroup";
import RadioButton from "panel/src/Components/RadioButton";
import Separator from "panel/src/Components/Separator";
import DynamicToolsBar from "panel/src/Components/DynamicToolsBar";
import { connect } from "react-redux";
import { hasChange } from "panel/src/redux/actions/bannerSettings";
import { usePrevious } from "panel/src/utils/helper";

enum LinkTypes {
  Category = 1,
  ProductGroup,
}

interface ILinkListview {
  hideComponentEdit?: Function;
  editFormClickedOutside?: number;
  setComponentFormData?: Function;
  Id?: string;
  formData?: ILinkListviewFormData;
  categories: { label: string; value: string }[];
  productGroups: { label: string; value: string }[];
  setInfoPopup?: Function;
  hasChangeReq: Function;
}

type ILinkListviewFormData = {
  Items: {
    Title: string;
    CategoryId?: string;
    GroupId?: string;
  }[];
};

const LinkListview = ({
  hideComponentEdit,
  editFormClickedOutside,
  setComponentFormData,
  Id,
  formData,
  categories,
  productGroups,
  setInfoPopup,
  hasChangeReq,
}: ILinkListview) => {
  const setDefaultLinkType = (listItem: ILinkListviewFormData["Items"][0]) => {
    if (listItem.CategoryId !== "") return LinkTypes.Category;
    else if (listItem.GroupId !== "") return LinkTypes.ProductGroup;
    else return LinkTypes.Category;
  };

  const prevProps = usePrevious({ formData });

  useEffect(() => {
    if (prevProps && formData) {
      if (prevProps.formData !== formData) {
        hasChangeReq();
      }
    }
  });

  useEffect(() => {
    if (editFormClickedOutside !== 0) {
      hideComponentEdit && hideComponentEdit(Id);
    }
  }, [editFormClickedOutside]);

  const renderProductGroups = () => {
    return productGroups.length > 0;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        Items: formData!.Items.map((obj) => {
          return { ...obj, linkType: setDefaultLinkType(obj) };
        }),
      }}
      onSubmit={(values) => {
        let resultObject: ILinkListviewFormData;
        resultObject = {
          Items: values.Items.map((obj) => {
            if (obj.linkType === LinkTypes.Category)
              return {
                Title: obj.Title,
                CategoryId: obj.CategoryId,
                GroupId: "",
              };
            else
              return { Title: obj.Title, CategoryId: "", GroupId: obj.GroupId };
          }),
        };
        setComponentFormData && setComponentFormData(Id, resultObject);
        setInfoPopup && setInfoPopup(true);
      }}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, errors, touched, setFieldValue }) => {
        return (
          <Form>
            <FieldArray name="Items">
              {({ push, remove }) => (
                <React.Fragment>
                  {values.Items.map((formValue, index) => (
                    <React.Fragment key={index}>
                      <Field
                        id={`Items[${index}].Title`}
                        style={{ marginBottom: "1rem" }}
                        label="Link Name"
                        placeholder="Link Name"
                        value={formValue.Title}
                        onChangeHandler={handleChange}
                        component={Input}
                      />
                      {renderProductGroups() && (
                        <InputGroup
                          title="Link Type"
                          style={{ marginBottom: "1rem" }}
                        >
                          <Field
                            id={`Items[${index}].linkType`}
                            name={`Items[${index}].linkType`}
                            label="Category"
                            value={LinkTypes.Category}
                            checked={formValue.linkType === LinkTypes.Category}
                            onChangeHandler={() =>
                              setFieldValue(
                                `Items.${index}.linkType`,
                                LinkTypes.Category
                              )
                            }
                            component={RadioButton}
                          />
                          <Field
                            id={`Items[${index}].linkType`}
                            name={`Items[${index}].linkType`}
                            label="Product Group"
                            value={LinkTypes.ProductGroup}
                            checked={
                              formValue.linkType === LinkTypes.ProductGroup
                            }
                            onChangeHandler={() =>
                              setFieldValue(
                                `Items.${index}.linkType`,
                                LinkTypes.ProductGroup
                              )
                            }
                            component={RadioButton}
                          />
                        </InputGroup>
                      )}
                      {formValue.linkType === LinkTypes.Category ? (
                        <Field
                          id={`Items[${index}].CategoryId`}
                          name={`Items[${index}].CategoryId`}
                          style={{ marginBottom: "1rem" }}
                          label="Category"
                          placeholder="Select Category"
                          options={categories}
                          value={formValue.CategoryId}
                          onChangeHandler={handleChange}
                          handleError={errors}
                          handleTouched={touched}
                          component={Dropdown}
                        />
                      ) : (
                        <Field
                          id={`Items[${index}].GroupId`}
                          name={`Items[${index}].GroupId`}
                          style={{ marginBottom: "1rem" }}
                          label="Product Group"
                          placeholder="Select Product Group"
                          options={productGroups}
                          value={formValue.GroupId}
                          onChangeHandler={handleChange}
                          handleError={errors}
                          handleTouched={touched}
                          component={Dropdown}
                        />
                      )}
                      {values.Items.length > 1 &&
                        values.Items.length - 1 !== index && <Separator />}
                    </React.Fragment>
                  ))}
                  <DynamicToolsBar
                    push={() => {
                      push(values.Items[0]);
                    }}
                    remove={() => remove(values.Items.length - 1)}
                    isAddable
                    isRemovable={values.Items.length > 1}
                  />
                  <Button
                    id="apply-button"
                    type="submit"
                    style={{ marginTop: "1.5rem" }}
                    buttonText="Apply"
                  />
                  <Button
                    id="cancel-button"
                    type="button"
                    style={{ marginTop: "0.5rem" }}
                    theme="white"
                    buttonText="Cancel"
                    onClick={() => hideComponentEdit && hideComponentEdit(Id)}
                  />
                </React.Fragment>
              )}
            </FieldArray>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  return {
    hasChange: state.bannerSettings.changes,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    hasChangeReq: () => dispatch(hasChange()),
    disCardChanges: () => dispatch(hasChange()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkListview);
