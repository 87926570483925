import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import IGetHomeDataRequest from "../../models/Design/home/IGetHomeDataRequest";
import { IHomeData } from "../../models/Design/home/IGetHomeDataResponse";
import { IProductDetailData } from "../../models/Design/productDetail/IGetProductDetailDataResponse";
import { IProductListData } from "../../models/Design/productList/IGetProductListDataResponse";
import {
  getColorPageDataSuccess,
  getColorPageDataFail,
} from "panel/src/redux/actions/colorsPage";
import { GET_COLOR_PAGE_DATA } from "panel/src/redux/constants/colorsPage";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import notification from "panel/src/utils/notification";

function* getColorPageData(payload: {
  requestObj: IGetHomeDataRequest;
  type: string;
}) {
  yield put(startAppLoading(GET_COLOR_PAGE_DATA));
  try {
    const request = {
      ApplicationId: payload.requestObj.applicationId,
      SsoUserId: payload.requestObj.userId,
      PlatformId: payload.requestObj.platformId,
    };

    const [
      homePageDataResponse,
      productDetailDataResponse,
      productListDataResponse,
      colorsData,
      fontTypeData,
    ]: [IHomeData, IProductDetailData, IProductListData, any, any] = yield all([
      call(axios.post, "/Component/api/Component/GetHomePageData", request),
      call(
        axios.post,
        "/Component/api/Component/GetProductDetailPageData",
        request
      ),
      call(axios.post, "/Component/api/Component/GetProductPageData ", request),
      call(axios.post, "/api/Design/GetColors", {
        ApplicationId: request.ApplicationId,
      }),
      call(axios.post, "/api/Manage/GetFonts", {
        ApplicationId: request.ApplicationId,
      }),
    ]);

    const colorPageData = {
      productDetail: productDetailDataResponse.data.ResultData,
      home: homePageDataResponse.data.ResultData,
      productList: productListDataResponse.data.ResultData,
      colors: colorsData.data.ResultData,
      fonts: fontTypeData.data.ResultData,
    };
    yield put(getColorPageDataSuccess(colorPageData));
  } catch (error) {
    notification.error(
      "We are temporarily unable to process transactions at this time. We apologize for the inconvenience.",
      "/manage/config-settings"
    );
    yield put(getColorPageDataFail(error.message));
  }
  yield put(endAppLoading(GET_COLOR_PAGE_DATA));
}

export default function* getColorsPageSaga() {
  yield takeLatest(GET_COLOR_PAGE_DATA, getColorPageData);
}
