import React, { useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./richTextEditor.css";
import { uploadBase64ImageToUrlService } from "../../../services/api/design";
import notification from "../../../utils/notification";
import { Id, toast } from "react-toastify";

interface IRichTextEditor {
  onChangeHandler: (value: string) => void;
  value: string;
  fontFamily?: any;
}

const RichTextEditor = ({
  onChangeHandler,
  value,
  fontFamily,
}: IRichTextEditor) => {
  const quillRef = useRef<ReactQuill>(null);

  const imgageHandler = () => {
    try {
      const input = document.createElement("input");

      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();

      let loading: Id;
      input.onchange = async () => {
        const file: any = input?.files?.[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          try {
            loading = toast.loading("Please wait...");
            const regex = /^data:image\/(\w+);base64,/i;
            const imageType = regex.exec(reader.result as string);
            if (imageType) {
              const serviceResponse = await uploadBase64ImageToUrlService({
                FileContent: reader.result
                  ?.toString()
                  .replace(imageType[0], "") as string,
                FileExtension: imageType[1],
              });

              const range = quillRef.current?.getEditorSelection();
              quillRef.current
                ?.getEditor()
                .insertEmbed(
                  range?.index ?? 0,
                  "image",
                  serviceResponse.data?.ResultData
                );
            } else {
              notification.error("This image type is not allowed");
            }
            toast.update(loading, {
              render: "Image has been loaded",
              type: "success",
              isLoading: false,
              autoClose: 5000,
            });
          } catch (error) {
            notification.error(error.message);
            toast.update(loading, {
              render: error.message,
              type: "error",
              isLoading: false,
              autoClose: 5000,
            });
          }
        };
        reader.onerror = (error: any) => {
          notification.error(error.message);
          toast.update(loading, {
            render: error.message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
        };
      };
    } catch (error) {
      notification.error(error.message);
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        handlers: {
          image: imgageHandler,
        },
        container: [
          [{ header: [1, 2, false] }],
          ["bold", "underline", "blockquote", { list: "bullet" }],
          ["link", "image", "video", "clean"],
        ],
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  return (
    <ReactQuill
      ref={quillRef}
      theme="snow"
      modules={modules}
      style={{ fontFamily: fontFamily }}
      value={value}
      onChange={onChangeHandler}
    />
  );
};

export default RichTextEditor;
