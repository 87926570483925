import axios from "panel/src/services/api/api";
import * as getEngagementInfoActions from "panel/src/redux/actions/getEngagementInfo";
import {
  GET_ENGAGEMENT_INFO_START,
  SET_ENGAGEMENT_INFO_START,
} from "panel/src/redux/constants/getEngagementInfo";
import { takeLatest, put, call } from "redux-saga/effects";
import * as userInfoActions from "panel/src/redux/actions/userInfo";
import * as pageActions from "panel/src/redux/actions/page";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";

function* getEngagementInfoProcess(data: any) {
  yield put(startAppLoading(GET_ENGAGEMENT_INFO_START));
  try {
    const response = yield call(axios.post, "/api/Manage/GetEngagementInfo", {
      ...data.data,
    });
    yield put(
      getEngagementInfoActions.getEngagementInfoSuccess(
        response.data.ResultData
      )
    );
  } catch (error) {
    yield put(getEngagementInfoActions.getEngagementInfoRejected(error));
  }
  yield put(endAppLoading(GET_ENGAGEMENT_INFO_START));
}

function* setEngagementInfoProcess({ data }: any) {
  yield put(startAppLoading(SET_ENGAGEMENT_INFO_START));
  try {
    yield call(axios.post, "/api/Manage/SetEngagementInfo", { ...data });
    yield put(getEngagementInfoActions.setEngagementInfoSuccess());
    yield put(pageActions.pageUpdated(true));
  } catch (error) {
    yield put(getEngagementInfoActions.setEngagementInfoRejected(error));
  }

  yield put(userInfoActions.getUserInfo());
  yield put(endAppLoading(SET_ENGAGEMENT_INFO_START));
}

export function* setEngagementInfoProcessSaga() {
  yield takeLatest(SET_ENGAGEMENT_INFO_START, setEngagementInfoProcess);
}

export function* getEngagementInfoProcessSaga() {
  yield takeLatest(GET_ENGAGEMENT_INFO_START, getEngagementInfoProcess);
}
