import * as Yup from "yup";
import {
  appAndroidIconMessage,
  appIosIconMessage,
  appAndroidIconExtensionMessage,
  appIOSIconExtensionMessage,
  appIconSizeMessage,
} from "../../../utils/validationMessage";
import * as Shapes from "../../../utils/validationShapes";

const ICON_WIDTH = 512;
const ICON_HEIGHT = 512;
const SUPPORTED_ICON_FORMATS = ["image/png"];

const Schema = Yup.object().shape({
  appName: Shapes.appName,
  androidIcon: Yup.mixed().required(appAndroidIconMessage).nullable(),
  iosIconExtension: Yup.string()
    .required(appIOSIconExtensionMessage)
    .test(
      "iosFileFormat",
      appIOSIconExtensionMessage,
      (value) => value && SUPPORTED_ICON_FORMATS.includes(value)
    ),
  iosIcon: Yup.mixed().required(appIosIconMessage).nullable(),
  androidIconExtension: Yup.string()
    .required(appAndroidIconExtensionMessage)
    .test(
      "androidFileFormat",
      appAndroidIconExtensionMessage,
      (value) => value && SUPPORTED_ICON_FORMATS.includes(value)
    ),

  iosIconWidth: Yup.number()
    .max(ICON_WIDTH, appIconSizeMessage)
    .min(ICON_WIDTH, appIconSizeMessage),
  iosIconHeight: Yup.number()
    .max(ICON_HEIGHT, appIconSizeMessage)
    .min(ICON_HEIGHT, appIconSizeMessage),
  androidIconWidth: Yup.number()
    .max(ICON_WIDTH, appIconSizeMessage)
    .min(ICON_WIDTH, appIconSizeMessage),
  androidIconHeight: Yup.number()
    .max(ICON_HEIGHT, appIconSizeMessage)
    .min(ICON_HEIGHT, appIconSizeMessage),
});

export default Schema;
