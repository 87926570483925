import React from "react";
import { connect } from "react-redux";
import Container from "./Container";
import Icon from "panel/src/_components/common/Icon";
import { OS } from "../../ToggleOS";

interface IProductDetailHeader {
  isTransparent: boolean;
  colorList?: any;
  selectedApp?: any;
  currentOS: OS;
}

const Header = ({
  isTransparent,
  colorList,
  selectedApp,
  currentOS,
}: IProductDetailHeader) => {
  return (
    <Container currentOS={currentOS}>
      <Icon
        type="iconback"
        fillColor={isTransparent ? "#fff" : "#0f3649"}
        height="20"
        width="20"
      />
      <Icon
        type="iconshare"
        fillColor={isTransparent ? "#fff" : "#0f3649"}
        height="20"
        width="20"
      />
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    selectedApp: state.userInfo.selectedApp,
  };
};

export default connect(mapStateToProps, null)(Header);
