import React from "react";

const EmptyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
    <g fill="none" fill-rule="evenodd">
      <path d="M-4-4h20v20H-4z"/>
      <g fill="currentColor" fill-rule="nonzero">
        <path d="M1.779.579C1.459.259.93.269.6.6.275.925.26 1.44.56 1.76l.019.019 9.642 9.642c.32.32.848.31 1.179-.021.325-.325.34-.84.04-1.16l-.019-.019L1.78.58Z"/><path d="M.506 10.306c-.246.246-.18.712.148 1.04.322.322.776.392 1.026.162l.014-.014 9.8-9.8c.246-.246.18-.712-.148-1.04-.322-.322-.776-.392-1.026-.162l-.014.014-9.8 9.8Z"/>
      </g>
    </g>
  </svg>
)

export default EmptyIcon;