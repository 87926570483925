import {
  GET_HOME_DATA,
  GET_HOME_DATA_SUCCESS,
  GET_HOME_DATA_FAIL,
  RESET_HOME_DATA,
  COMPONENT_CHANGE,
  RESET_CHANGES,
} from "panel/src/redux/constants";

export interface IHomeData {
  componentsData: {
    ComponentId: number;
    DisplayOrder: number;
    JSON: string | null;
    PageId: number;
  }[];
  initialized: boolean;
}

const initialState = {
  data: false,
  loading: false,
  reset: false,
  changes: false,
  changedComponent: false,
};

const homeReducer = (state: Object = initialState, action: any) => {
  switch (action.type) {
    case GET_HOME_DATA:
      return {
        ...state,
        loading: true,
        reset: false,
      };
    case GET_HOME_DATA_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        reset: false,
      };
    case GET_HOME_DATA_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        reset: false,
      };
    case RESET_HOME_DATA:
      return {
        ...initialState,
        loading: false,
        reset: true,
      };
    case COMPONENT_CHANGE:
      return {
        ...state,
        changes: true,
        changedComponent: action.componentObj,
      };
    case RESET_CHANGES:
      return {
        ...state,
        changes: false,
        changedComponent: false,
      };
    default:
      return state;
  }
};

export default homeReducer;
