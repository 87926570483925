import { Platform, Dimensions } from "react-native";
import styled from "styled-components/native";
import FastImage from "react-native-fast-image-web-support";
interface IImage {
  coverScreen?: boolean;
}

const screenWidth = Math.round(Dimensions.get("window").width);
const screenHeight = Math.round(Dimensions.get("window").height);

export default styled(FastImage)<IImage>`
  height: ${Platform.OS === "web"
    ? "165"
    : (props) => (props.coverScreen ? screenHeight : screenWidth)}px;
  width: ${Platform.OS === "web" ? "100%" : `${screenWidth}px`};
`;
