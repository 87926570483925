import React from "react";
import { NotificationModule } from "../../../../../models/Notification";
import { OS } from "panel/src/Components/DragnDrop/Emulator/ToggleOS";
import {
  AppIcon,
  Clock,
  DateText,
  HomeScreenContent,
  HomeScreenHeader,
  Notification,
} from "./Home.styles";
import Icon from "../../../../../_components/common/Icon";

export interface IEmulatorHome {
  notification?: NotificationModule.Notification;
  androidIcon: string;
  iosIcon: string;
  appName: string;
}

export interface IEmulatorHomeProps extends IEmulatorHome {
  activeOS: OS.IOS | OS.Android;
}

const EmulatorHome = ({
  notification,
  activeOS,
  androidIcon,
  iosIcon,
  appName,
}: IEmulatorHomeProps) => {
  const date = new Date();

  return (
    <>
      <HomeScreenHeader>
        <Clock>
          {date.getHours().toString().padStart(2, "0")}:
          {date.getMinutes().toString().padStart(2, "0")}
        </Clock>
        <DateText>{date.toDateString()}</DateText>
      </HomeScreenHeader>
      <HomeScreenContent>
        <Notification>
          {activeOS === OS.IOS && <span className="times">Time</span>}

          <div
            className={`notification_top ${
              activeOS === OS.IOS ? "ios" : "android"
            }`}
          >
            <div className="left">
              <div className="icon">
                {activeOS === OS.IOS ? (
                  <AppIcon src={iosIcon} activeOS={activeOS} />
                ) : (
                  <div className="android_header">
                    <AppIcon src={androidIcon} activeOS={activeOS} />
                    <span className="android_app_name">{appName}</span>
                    <span className="time">
                      now
                      <Icon
                        type="arrowDown"
                        width="5"
                        height="5"
                        fillColor="#222222"
                      />
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="middle">
              <h5 className={ activeOS === OS.IOS ? "title" : "lineClamp"} >{notification?.title || "Still interested? 🎈💪🏼"}</h5>
              <p className="lineClamp">
                {notification?.body ||
                  "“Six later birds” is an item you recently viewed. Take another look now."}
              </p>
            </div>

            <div className="right">

              {notification?.image && <img className={ activeOS === OS.IOS ? "imageOS" : ""} src={notification?.image || ""} />}
            </div>
          </div>
        </Notification>
        {notification?.image && (
          <Notification>
            {activeOS === OS.IOS && <span className="times">Time</span>}
            <div
              className={`notification_top ${
                activeOS === OS.IOS ? "ios" : "android"
              }`}
            >
              <div className="left">
                <div className="icon">
                  {activeOS === OS.IOS ? (
                    <AppIcon src={iosIcon} activeOS={activeOS} />
                  ) : (
                    <div className="android_header">
                      <AppIcon src={androidIcon} activeOS={activeOS} />
                      <span className="android_app_name">{appName}</span>
                      <span className="time">
                        now
                        <Icon
                          type="arrowDown"
                          width="5"
                          height="5"
                          fillColor="#222222"
                          className="arrow_up"
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="middle">
                <h5>{notification?.title || "Still interested? 🎈💪🏼"}</h5>
                <p>
                  {notification?.body ||
                    "“Six later birds” is an item you recently viewed. Take another look now."}
                </p>
              </div>
            </div>
            <div
              className={`notification_bottom ${
                activeOS === OS.IOS ? "ios" : "android"
              }`}
            >
              <img src={notification?.image || ""} />
            </div>
          </Notification>
        )}
      </HomeScreenContent>
    </>
  );
};

export default EmulatorHome;
