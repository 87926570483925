import React from "react";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
  type: string;
  className?: string;
}

const EditIcon = ({ width, height, fillColor, ...props }: IProps) => (
  <svg
    width={width}
    height={height}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h16v16H0z' />
      <path
        d='M10.03 1.635a1.03 1.03 0 0 1 1.457 0l2.878 2.878a1.03 1.03 0 0 1 0 1.457l-1.767 1.77a.52.52 0 0 1-.194.193l-5.767 5.765a1.031 1.031 0 0 1-.039.037.525.525 0 0 1-.55.257h-.004L5.908 14H3.03A1.03 1.03 0 0 1 2 12.97v-2.878c0-.047.003-.094.01-.14a.52.52 0 0 1 .254-.549l.038-.04L8.07 3.591a.52.52 0 0 1 .19-.189zm-7 9.471v1.864h1.862L3.03 11.105zm5.497-6.512L3.266 9.855l2.878 2.878 5.26-5.26-2.877-2.879zm2.231-2.23L9.261 3.86l2.878 2.878 1.497-1.496-2.878-2.878z'
        fill='currentColor'
      />
    </g>
  </svg>
);

export default EditIcon;
