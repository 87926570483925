import React from "react";
import AddIcon from "panel/src/assets/images/add.svg";
import Content from "./Content";
import Container from "./Container";
import Image from "./Image";

interface IEndOfPage {
  screen: string;
}

const EndOfPage = ({ screen }: IEndOfPage) => {
  return (
    <Container>
      <Image src={AddIcon} />
      <Content>
        Design your {screen.toLowerCase()} simply by dragging and dropping
        components here.
      </Content>
    </Container>
  );
};

export default EndOfPage;
