import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Emulator from "panel/src/Components/DragnDrop/Emulator";
import ScreenSelection from "../components/ScreenSelection";
import classes from "./navigationType.module.scss";
import Select from "../../../_components/common/Select";
import ChooseLogo from "../../../_components/common/ChooseLogo";
import SelectModel from "../../../models/Design/selectModel";
import * as actions from "panel/src/redux/actions/navigationType";
import * as logoActions from "panel/src/redux/actions/logoPosition";
import * as generalActions from "panel/src/redux/actions/generalSettings";
import { devices } from "../../../utils/constants";
import { IconType } from "../../../utils/enums/iconType";
import { DeviceType } from "../../../utils/enums/deviceType";
import { Field, Formik, Form, FormikProps } from "formik";
import navigationValidation from "./validationSchema";
import { GetGeneralSettingsRequestModel } from "../../../models/Design/generalSettings/getGeneralSettingsRequestModel";
import { DeviceModel } from "../../../models/Design/deviceModel";
import BlockingForm from "../../../_components/common/BlockingForm";
import { ScreenType } from "../../../utils/enums/screenType";
import { fileToBase64, getFileExtension } from "panel/src/utils/helper";
import Upload from "../../../_components/common/Upload";
import { validURL } from "panel/src/utils/regex";
import ErrorField from "panel/src/_components/common/ErrorField";
import informationIcon from "../../../assets/images/information.svg";
import { screenNumbers } from "../constants";
import { setChangedSthDesignPage } from "panel/src/redux/actions/manageApp";
import { navigationBarLogo } from "../../../utils/initialImages";
import { THeaderTabBarIconAlign } from "../../../Components/DragnDrop/Emulator/Components/Header";
import Modal from "../../../_components/common/Modal";
import UpgradeGuide from "../../../Components/UpgradeGuide";
import { LOGO_ALIGNS } from "../../../_components/common/ChooseLogo";
import { saveCategories } from "../../../redux/actions/category";
import { EPlatform } from "../../../utils/enums/manage";

interface IProps {
  appId?: any;
  actions?: any;
  logoActions?: any;
  generalActions?: any;
  navigationType?: any;
  navigationTypesLoading?: boolean;
  generalSettings?: any;
  selectChange?: any;
  deviceType: DeviceType;
  deviceTypeHandler: Function;
  isCompletion: boolean;
  selectedValue: string;
  designScreens: SelectModel[];
  designScreensChange: Function;
  isOnModalSubmit: boolean;
  _setChangedSthDesign: Function;
  setError: Function;
  saveCategories: Function;
  isCompleted: boolean;
  platformId: number;
  packageFeatures: any;
  logoPosition: string;
  userId: string;
}

interface IState {
  options: SelectModel[];
  activeIndex: DeviceType;
  devices: DeviceModel[];
  navigationBarLogo: string;
  navigationType: string;
  isBlocking: boolean;
  logo: any;
  logoWidth: number;
  logoHeight: number;
  logoRatio: number;
  navigationLogo: any;
  navigationBarIconFileExtension: string;
  isUpgradeModalShown: boolean;
  isUpgradeGuide: boolean;
  tabBarIconAlign: any;
}

const dummyImg = {
  name: "Navigation Bar.png",
  type: "image/png",
};
class NavigationType extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      options: [],
      activeIndex: DeviceType.IOS,
      devices: devices,
      navigationBarLogo: "",
      navigationType: "",
      isBlocking: false,
      logo: dummyImg,
      logoWidth: 90,
      logoHeight: 18,
      logoRatio: 5,
      navigationLogo: navigationBarLogo,
      navigationBarIconFileExtension: "image/png",
      isUpgradeGuide: false,
      isUpgradeModalShown: false,
      tabBarIconAlign: ''
    };
  }
  formikRef: FormikProps<any> | null = null;

  packageFeaturesLimited =
    (this.props.packageFeatures?.find((s: any) => s.Id === 1)?.MaxCount || -1) >
    -1;

  componentDidMount = async () => {
    this.state.devices.map((item) => {
      item.type = IconType.Disabled;

      if (item.index === DeviceType.IOS) {
        item.type = IconType.Active;
      }
    });
    const requestObj: GetGeneralSettingsRequestModel = {
      ApplicationId: this.props.appId,
    };
    await this.props.actions.getNavigationTypeStart(this.props.appId);
    await this.props.generalActions.getGeneralSettingsStart(requestObj);
    await this.props.logoActions.getLogoPositionStart(requestObj);
  };

  generateNavigationTypeOptions = () => {
    const { navigationTypeList, selectedNavigationType } = this.props.navigationType;
    const selectedValue = selectedNavigationType?.navigationTypeId?.toString() || '';
    let navigationOptionList: any = navigationTypeList?.map((item: any) => ({
        value: item.navigationTypeId.toString(),
        displayValue: item.navigationTypeName,
        isSelected: selectedValue == item.navigationTypeId,
        isComplated: false,
        isPackageSupported: item.navigationTypeId === 1 && this.packageFeaturesLimited,
    }));
    this.setState({ options: navigationOptionList ?? [], navigationType: selectedValue });
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (
      !this.props.navigationTypesLoading &&
      (!this.state.options || this.state.options.length === 0) &&
      !this.state.navigationType
    ) {
      this.generateNavigationTypeOptions();
    }

    if (
      this.props.isOnModalSubmit &&
      this.props.selectedValue === screenNumbers.navigationType
    ) {
      this.formikRef?.submitForm();
    }
    if (this.props.generalSettings != prevProps.generalSettings) {
      const { NavigationBarIconUrl } = this.props.generalSettings;
      const logoExtension = getFileExtension(NavigationBarIconUrl);

      if (NavigationBarIconUrl) {
        this.setState({
          navigationBarLogo: NavigationBarIconUrl,
          navigationLogo: NavigationBarIconUrl,
          navigationBarIconFileExtension: `image/${logoExtension}`,
        });
      }
    }
  }

  selectChangeHandler = (e: any) => {
    const updatedOptions = this.state.options.map((option) => {
      return {
        ...option,
        isSelected: option.value == e.target.value,
      };
    });
    this.setState({
      options: updatedOptions,
      navigationType: e.target.value,
      isBlocking: true,
    });
    this.props._setChangedSthDesign(true);
  };

  handleClick = (device: any) => {
    this.setState({ activeIndex: device.index });
    this.state.devices.map((item) => {
      item.type = IconType.Disabled;

      if (device.index === DeviceType.IOS) {
        device.type = IconType.Active;
      } else if (device.index === DeviceType.Android) {
        device.type = IconType.Active;
      }
    });
    this.props.deviceTypeHandler(device.index);
  };

  getInitialValues = () => {
    const values = {
      navigationType: this.state.navigationType,
      logo: this.state.navigationLogo,
      logoExtension: this.state.navigationBarIconFileExtension,
      logoWidth: this.state.logoWidth,
      logoHeight: this.state.logoHeight,
      logoRatio: this.state.logoRatio,
    };
    return values;
  };

  onSubmitForm = async () => {
    this.setState({ isBlocking: false }, async () => {
      this.props._setChangedSthDesign(false);
      if (
        !this.state.navigationLogo ||
        !this.state.navigationBarIconFileExtension
      ) {
        this.props.setError(true);
        return;
      }

      // TabBar seçeneği sadece growth pakette olacak
      let tempNavigationType = this.state.navigationType;
      if (this.state.navigationType === "1" && this.packageFeaturesLimited) {
        if (this.state.isUpgradeModalShown) {
          tempNavigationType = "2";
        } else {
          this.setState({
            isUpgradeGuide: true,
          });
          return;
        }
      }

      if (!this.props.isOnModalSubmit) {
        // If is WooCommerce skip Category layout step
        if (
          [EPlatform.WooCommerce, EPlatform.Prestashop].includes(
            this.props.platformId
          )
        ) {
          this.props.saveCategories({ categories: [] });
          this.props.selectChange(ScreenType.Home.toString());
        } else {
          this.props.selectChange(ScreenType.CategoryLayout.toString());
        }
      }

      try {
        let navigationObj: any = {
          ApplicationId: this.props.appId,
          navigationTypeId: +tempNavigationType,
        };
        let newData = {
          ...this.props.generalSettings,
          ApplicationId: this.props.appId,
          NavigationBarIconFile: !validURL(this.state.navigationLogo)
            ? this.state.navigationLogo.split(";base64,").pop()
            : null,
          NavigationBarIconFileExtension:
            this.state.navigationBarIconFileExtension,
        };

        this.props.generalActions.setGeneralSettingsStart(newData);

        await this.props.actions.setNavigationTypeStart(
          navigationObj
        );
        await this.props.logoActions.updateLogoPositionStart({
          ApplicationId: this.props.appId,
          UserId: this.props.userId,
          SelectedLogoAlignId: LOGO_ALIGNS.find(item => item.value === this.props.logoPosition)?.id,
        })
      } catch (error) {
        console.log("error", error);
      }
    });
  };

  onChangeHandler = async (fileInput: any) => {
    const file = fileInput.files[0];
    if (file) {
      const res = await fileToBase64(file);
      var _URL = window.URL || window.webkitURL;
      var img = new Image();
      img.onload = () => {
        this.setState({
          logo: file,
          navigationLogo: res,
          navigationBarIconFileExtension: file.type ? file.type : "jpg",
          logoWidth: img.width,
          logoHeight: img.height,
          logoRatio: Math.round(img.width / img.height),
        });
      };
      img.onerror = function () {
        console.log("not a valid file: " + file.type);
      };
      img.src = _URL.createObjectURL(file);
      this.props._setChangedSthDesign(true);
      setTimeout(() => {
        this.formikRef?.validateForm();
      }, 100);
    }
  };

  render() {
    const { options, logo, navigationLogo } = this.state;
    const selectedOption = options?.find((opt) => opt?.isSelected);
    const selectedValue = selectedOption ? selectedOption?.value : "";
    let logoName = logo ? logo?.name : "";
    if (validURL(navigationLogo)) {
      logoName = navigationLogo
        .toString()
        .substring(navigationLogo.toString().lastIndexOf("/") + 1)
        .slice(0, -1);
    }

    let tabBarIconAlign: THeaderTabBarIconAlign = "default";
    const userCustom = this.props.navigationType?.userCustoms
      ? JSON.parse(this.props.navigationType?.userCustoms)
      : null;

    if (userCustom) {
      tabBarIconAlign = userCustom?.tabBarIconAlign
        ? userCustom?.tabBarIconAlign
        : "default";
    }

    return (
      <React.Fragment>
        <div className={classes.DesignConfigContainer}>
          {!this.props.isCompletion && (
            <ScreenSelection
              options={this.props.designScreens}
              selectedValue={this.props.selectedValue}
              selectChangeHandler={this.props.designScreensChange}
            />
          )}
          <div className={classes.componentsContainer}>
            <Formik
              enableReinitialize={true}
              initialValues={this.getInitialValues()}
              validationSchema={navigationValidation}
              innerRef={(r) => (this.formikRef = r)}
              onSubmit={this.onSubmitForm}
            >
              {({ errors, setFieldValue }) => {
                if (
                  errors["logo"] ||
                  errors["logoExtension"] ||
                  errors["logoWidth"] ||
                  errors["logoRatio"] ||
                  errors["logoHeight"]
                ) {
                  this.props.setError(true);
                } else {
                  this.props.setError(false);
                }

                return (
                  <Form>
                    <BlockingForm isBlocking={this.state.isBlocking} />
                    <div className={classes.NavigationContainer}>
                      <span className={classes.BlockLabel}>Navigation</span>
                      <Field
                        value={this.state.navigationType}
                        placeholder="Select navigation type"
                        options={this.state.options}
                        changeHandler={this.selectChangeHandler}
                        name="navigationType"
                        component={Select}
                      />
                    </div>
                    <div className={classes.NavigationBarLogoContainer}>
                      <span className={classes.BlockLabel}>
                        Navigation Bar Logo
                      </span>
                      <Field
                        placeholder="Upload Image"
                        onChange={(e: any) => {
                          this.onChangeHandler(e);
                          setFieldValue("logo", e?.files[0] && e.files[0]);
                        }}
                        logoName={logoName}
                        name="logo"
                        component={Upload}
                      />
                      {!errors["logo"] &&
                        errors["logoExtension"] &&
                        !!this.state.navigationLogo && (
                          <ErrorField
                            type={"relative"}
                            text={errors["logoExtension"]}
                          />
                        )}
                      {(errors["logoWidth"] ||
                        errors["logoHeight"] ||
                        errors["logoRatio"]) && (
                        <ErrorField
                          type={"relative"}
                          text={errors["logoRatio"]}
                        />
                      )}
                      <div className={classes.InfoGroup}>
                        <img
                          src={informationIcon}
                          alt="information"
                          className={classes.Information}
                        />
                        <span className={classes.TheChangesYouMake}>
                          Aspect ratio 5:1. PNG, JPEG or SVG
                        </span>
                      </div>
                    </div>
                    <div className={classes.NavigationBarLogoContainer}>
                      <span className={classes.BlockLabel}>Logo Alignment</span>
                      <Field
                          value={selectedValue}
                          placeholder="Select navigation type"
                          options={this.state.options}
                          changeHandler={this.selectChangeHandler}
                          name="navigationType"
                          component={ChooseLogo}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>

        <div className={classes.MobileScreen}>
          <Emulator
            screen="Navigation Type"
            navigateBack={this.props.selectChange}
            backTo={ScreenType.SplashScreen.toString()}
            navigation={{
              tabBarIconAlign: tabBarIconAlign,
              navigationType: selectedOption
                ? parseInt(selectedOption.value)
                : undefined,
              navigationBarIcon: this.state.navigationLogo
                ? this.state.navigationLogo
                : this.state.navigationBarLogo,
            }}
            saveScreen={() => this.formikRef?.submitForm()}
          />
        </div>
        <Modal
          show={this.state.isUpgradeGuide}
          width="300"
        >
          <UpgradeGuide
            screen="Navigation Type"
            setIsOpenModal={() => {
              this.setState({
                isUpgradeGuide: false,
                isUpgradeModalShown: true,
              });
            }}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    logoActions: bindActionCreators(logoActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
    saveCategories: (values: { categories: any[]; callback?: Function }) =>
      dispatch(saveCategories(values)),
    _setChangedSthDesign: (value: boolean) =>
      dispatch(setChangedSthDesignPage(value)),
  };
};

const mapStateToProps = (state: any) => {
  return {
    platformId: state.userInfo.UserPackage.PlatformId,
    appId: state.userInfo.selectedAppId,
    navigationType: state.navigationType.navigationType,
    navigationTypesLoading: state.navigationType.navigationTypesLoading,
    generalSettings: state.generalSettings.generalSettings,
    isCompleted: state.userInfo.selectedApp.IsStep7Completed,
    packageFeatures:
      state.packageFeature?.featureData?.PackageFeature?.Features,
    logoPosition: state.logoPosition.position,
    userId: state.userInfo.userId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationType);
