import React, { FC } from 'react';
import styled from 'styled-components';
import { StyleProps } from './Sidebar';
import ArrowIcon from "./arrow.svg";

type SidebarMenuItemProps = {
  fontFamily?: string;
  name?: string;
  iconUrl?: string;
  index?: number;
  isCentered?: boolean;
  withoutIcon?: boolean;
};


const Container = styled.div<StyleProps & SidebarMenuItemProps>`
  font-family: ${(props) => `${props.fontFamily};`};
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px;
  ${({ isCentered }) => isCentered && `padding-left: 40px`};
  color: #0f3649;
  & + .menu-item {
    border-top: 1px solid #e7eaec;
  }
`;

const Text = styled.div<SidebarMenuItemProps>`
  font-family: inherit;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const iconColorFilter = `
  invert(34%) sepia(42%) saturate(393%) hue-rotate(155deg) brightness(88%) contrast(87%);
`;

const styleFlexCenter = `
  display: flex;
  align-items: center;
  justify-content: center;
`

const IconLeft = styled.div`
  ${styleFlexCenter};
  margin-right: 8px;
  filter: ${iconColorFilter};
`;

const IconRight = styled.div`
  ${styleFlexCenter};
  margin-left: auto;
`;

const IconLeftImage = styled.img`
  width: 16px;
  height: 16px;
`;

const IconRightImage = styled.img`
  width: 10px;
  height: 10px;
`;

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  fontFamily,
  isCentered,
  name,
  iconUrl,
  index,
  withoutIcon,
}) => {
  return (
    <Container
      fontFamily={fontFamily}
      className="menu-item"
      isCentered={isCentered}
    >
      {(!withoutIcon || index === 0) && (
        <IconLeft>
          <IconLeftImage src={iconUrl} />
        </IconLeft>
      )}
      <Text isCentered={isCentered} withoutIcon={withoutIcon && index !==0}>
        {name}
      </Text>
      {index !== 0 && (
        <IconRight>
          <IconRightImage src={ArrowIcon} />
        </IconRight>
      )}
    </Container>
  );
};

export default SidebarMenuItem;
