import axios from "panel/src/services/api/api";
import jwtDecode from "jwt-decode";
import * as loginActions from "panel/src/redux/actions/login";
import * as userInfoActions from "panel/src/redux/actions/userInfo";
import { LOGIN_START } from "panel/src/redux/constants/login";
import { takeLatest, put, call } from "redux-saga/effects";
import * as pageActions from "panel/src/redux/actions/page";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";

interface ISagaProps {
  values: { password: string; username: string };
  type: string;
}

function* getLoginProcess({ values, type }: ISagaProps) {
  yield put(startAppLoading(LOGIN_START));
  try {
    const { data } = yield call(axios.post, "/api/login/Authenticate", values);
    //yield put( loginActions.loginSuccess( data.ResultData.Token ) )
    const token = data.ResultData.Token;

    yield put(loginActions.loginSuccess(token));

    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push([
      "identify",
      {
        email: values.username,
      },
    ]);

    // set token to header
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    //login success - navigate to user next page

    const { exp, UserToken: userToken } = jwtDecode(token);

    //convert it to Milliseconds past since 1970
    const expirationDateInMilliseconds = exp * 1000;

    //create a future date object when the token will expire
    const expirationDate = new Date(expirationDateInMilliseconds);

    //calculate the difference between now and the token expiry date
    //in ms to execute a function to log the user out
    const expirationTime = expirationDateInMilliseconds - new Date().getTime();
    //set localstorage Token and expirationDate
    yield localStorage.setItem("AUTH_TOKEN", token);
    yield localStorage.setItem("AUTH_EXP_DATE", expirationDate.toString());

    yield put(userInfoActions.fetchUserInfoStart(token));
    yield call(axios.get, "/api/login/GetLanguageResources");
    yield put(pageActions.pageUpdated(false));
  } catch (error) {
    yield put(loginActions.loginRejected(error));
  }
  yield put(endAppLoading(LOGIN_START));
}

export default function* loginProcessSaga() {
  yield takeLatest(LOGIN_START, getLoginProcess);
}
