import React, { Component } from "react";
import styled from "styled-components/native";
import { Platform, TouchableOpacity, View } from "react-native";
import { CommentSectionDataModel } from "shared-components/src/models/commentSectionDataModel";
import { ColorListData } from "shared-components/src/models/colorListData";
import {
  dimenFont,
  dimenWidth,
  dimenHeight,
} from "shared-components/src/utils/helper";
import { colors, shadow } from "shared-components/src/utils/constants";
import RatedRating from "../../icons/RatedRating";
import UnratedRating from "../../icons/UnratedRating";

interface IProps {
  title?: string;
  addCommentText?: string;
  showCommentText?: string;
  data: CommentSectionDataModel;
  colorList?: ColorListData[];
  onPressViewAll?: Function;
  onPressAddAComment?: Function;
  fontFamily?: any;
}

interface IState {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  fontFamily?: any;
}

const Container = styled.View`
  ${Platform.OS === "web" ? `padding: 5px 10px;` : `margin: 8px 16px;`}
  background-color: ${colors.white};
`;

const Wrapper = styled.View`
  padding: ${Platform.OS === "web" ? "12" : dimenWidth(16)}px;
  box-shadow: 0px 0px 5px rgba(15, 54, 73, 0.25);
`;

const Title = styled.Text<IState>`
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Black};
  font-size: ${Platform.OS === "web" ? 10.1 : dimenFont(14)}px;
  color: ${(props: IState) => props.primary};
  ${Platform.OS === "web"
    ? `padding-top: 10px; padding-bottom: 5px;`
    : `margin-bottom: 8px;`}
`;

const CommentCard = styled.View`
  background-color: #fff;
  ${Platform.OS === "web" ? `padding: 10px;` : `padding: 16px;`}
  ${Platform.OS === "web" && "box-shadow: 0px 0px 5px rgba(15, 54, 73, 0.25);"}
  ${Platform.OS === "web" ? `margin-vertical: 5px;` : `margin-vertical: 8px;`}
`;

const RatingContainer = styled.View`
  display: flex;
  flex-direction: row;
`;

const CommentTitle = styled.Text<IState>`
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
  font-size: ${Platform.OS === "web" ? 10.1 : dimenFont(14)}px;
  color: ${(props: IState) => props.primary};
  ${Platform.OS === "web" ? `margin-top: 10px;` : `margin-top: 16px;`}
  margin-bottom: 4px;
`;

const CommentText = styled.Text<IState>`
  font-family: ${(props) =>
    Platform.OS === "web" ? `${props.fontFamily}` : props.fontFamily!.Regular};
  font-size: ${Platform.OS === "web" ? 10.1 : dimenFont(14)}px;
  color: ${(props: IState) => props.primary};
`;

const CommentInfoContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CommentDate = styled.Text<IState>`
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
  font-size: ${Platform.OS === "web" ? 9.1 : dimenFont(12)}px;
  color: ${(props: IState) => props.primary};
`;

const CommentUser = styled.Text<IState>`
  font-family: ${(props) =>
    Platform.OS === "web" ? `${props.fontFamily}` : props.fontFamily!.Regular};
  font-size: ${Platform.OS === "web" ? 9.1 : dimenFont(12)}px;
  color: ${(props: IState) => props.primary};
  margin-top: 8px;
`;

const CommentLinks = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
`;

const Link = styled.Text<IState>`
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
  font-size: ${Platform.OS === "web" ? 9.1 : dimenFont(12)}px;
  color: ${(props: IState) => props.secondary};
`;

class CommentSection extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      primary: "#0F3649",
      secondary: "#3196F6",
      tertiary: "#0F3649",
    };
  }

  renderViewAllCommentText = () => {
    const { secondary } = this.state;

    const { showCommentText, data, colorList, fontFamily } = this.props;
    return (
      <Link
        fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
        secondary={colorList ? colorList[1].colorCode : secondary}
      >
        {showCommentText
          ? showCommentText.replace(
              "{0}",
              data !== null ? data.CommentCount.toString() : "0"
            )
          : `View all comments (${data !== null ? data.CommentCount : 0})`}
      </Link>
    );
  };

  render() {
    const { primary, secondary } = this.state;

    const {
      title,
      addCommentText,
      data,
      onPressViewAll = () => {},
      onPressAddAComment = () => {},
      colorList,
      fontFamily,
    } = this.props;

    return (
      <Container>
        <Wrapper style={Platform.OS === "web" ? {} : shadow}>
          <Title
            fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
            primary={colorList ? colorList[0].colorCode : primary}
          >
            {title ? title : "Comments"}
          </Title>
          {data !== null &&
            data.Comments &&
            data.Comments.slice(0, 2).map((item, index) => (
              <CommentCard
                key={index}
                style={Platform.OS === "web" ? {} : shadow}
              >
                <CommentInfoContainer>
                  <RatingContainer>
                    {[1, 2, 3, 4, 5].map((rating, index) => (
                      <React.Fragment key={index.toString()}>
                        {item.Rank >= rating ? (
                          <View
                            style={
                              rating !== 5 && {
                                marginRight:
                                  Platform.OS === "web" ? 4 : dimenWidth(4),
                              }
                            }
                          >
                            <RatedRating
                              height={
                                Platform.OS === "web"
                                  ? "12"
                                  : dimenHeight(12).toString()
                              }
                              width={
                                Platform.OS === "web"
                                  ? "12"
                                  : dimenWidth(12).toString()
                              }
                              fillColor="#3196F6"
                            />
                          </View>
                        ) : (
                          <View
                            style={
                              rating !== 5 && {
                                marginRight:
                                  Platform.OS === "web" ? 4 : dimenWidth(4),
                              }
                            }
                          >
                            <UnratedRating
                              height={
                                Platform.OS === "web"
                                  ? "12"
                                  : dimenHeight(12).toString()
                              }
                              width={
                                Platform.OS === "web"
                                  ? "12"
                                  : dimenWidth(12).toString()
                              }
                              fillColor="#CFD7DB"
                            />
                          </View>
                        )}
                      </React.Fragment>
                    ))}
                  </RatingContainer>
                  <CommentDate
                    fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                    primary={colorList ? colorList[0].colorCode : primary}
                  >
                    {item.CommentDate}
                  </CommentDate>
                </CommentInfoContainer>
                <CommentTitle
                  fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                  primary={colorList ? colorList[0].colorCode : primary}
                >
                  {item.CommentTitle}
                </CommentTitle>
                <CommentText
                  fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                  primary={colorList ? colorList[0].colorCode : primary}
                >
                  {item.CommentText}
                </CommentText>
                {item.Username !== "" && (
                  <CommentUser
                    fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                    primary={colorList ? colorList[0].colorCode : primary}
                  >
                    {item.Username}
                  </CommentUser>
                )}
              </CommentCard>
            ))}
          <CommentLinks>
            {data.CommentCount ? (
              <TouchableOpacity
                activeOpacity={0.5}
                onPress={() => {
                  onPressViewAll();
                }}
              >
                {this.renderViewAllCommentText()}
              </TouchableOpacity>
            ) : (
              this.renderViewAllCommentText()
            )}
            <TouchableOpacity
              activeOpacity={0.5}
              onPress={() => {
                onPressAddAComment();
              }}
            >
              <Link
                fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                secondary={colorList ? colorList[1].colorCode : secondary}
              >
                {addCommentText ? addCommentText : "Add a comment"}
              </Link>
            </TouchableOpacity>
          </CommentLinks>
        </Wrapper>
      </Container>
    );
  }
}

export default CommentSection;
