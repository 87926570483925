import { useEffect, useRef } from "react";

export const updateObject = (oldObject: any, updatedProperties: any) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const checkValidity = (value: string, rules: any) => {
  let isValid = true;

  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isUrl) {
    const pattern =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

export const fileToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const resizeImage = (
  base64Str: string,
  maxWidth = 512,
  maxHeight = 512
) => {
  return new Promise((resolve) => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement("canvas");
      const MAX_WIDTH = maxWidth;
      const MAX_HEIGHT = maxHeight;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });
};

export const getFileExtension = (fileName: string) => {
  try {
    return /[.]/.exec(fileName) ? /[^.]+$/.exec(fileName)!.toString() : "";
  } catch (error) {
    return null;
  }
};

export const isTokenExpired = (exp: number) => {
  return exp * 1000 < new Date().getTime();
};

export const getToken = () => {
  const token = localStorage.getItem("AUTH_TOKEN");
  if (!token) return null;

  return token;
};

export const schedulerTimeFormat = (time: number) => {
  let seconds = (time % 60).toString();
  let minutes = Math.floor(time / 60).toString();
  minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
  seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
  return minutes + ":" + seconds;
};

export const convertHexToRgba = (hex: string) => {
  const tempHex = hex.replace("#", "");
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);
  return { h: r, s: g, l: b, a: 1 };
};

export const HSLToHex = (h: number, s: number, l: number) => {
  s /= 100;
  l /= 100;

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    rc = 0,
    gc = 0,
    bc = 0;

  if (0 <= h && h < 60) {
    rc = c;
    gc = x;
    bc = 0;
  } else if (60 <= h && h < 120) {
    rc = x;
    gc = c;
    bc = 0;
  } else if (120 <= h && h < 180) {
    rc = 0;
    gc = c;
    bc = x;
  } else if (180 <= h && h < 240) {
    rc = 0;
    gc = x;
    bc = c;
  } else if (240 <= h && h < 300) {
    rc = x;
    gc = 0;
    bc = c;
  } else if (300 <= h && h < 360) {
    rc = c;
    gc = 0;
    bc = x;
  }
  // Having obtained RGB, convert channels to hex
  let r = Math.round((rc + m) * 255).toString(16);
  let g = Math.round((gc + m) * 255).toString(16);
  let b = Math.round((bc + m) * 255).toString(16);

  // Prepend 0s, if necessary
  if (r.length == 1) r = "0" + r;
  if (g.length == 1) g = "0" + g;
  if (b.length == 1) b = "0" + b;

  return "#" + r + g + b;
};

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const navigateAway = (selectedApp: any) => {
  if (selectedApp === undefined || selectedApp.AppId === undefined) {
    return "/welcome";
  } else if (selectedApp.IsStep13Completed && selectedApp.IsStep12Completed) {
    return "/engage";
  } else if (selectedApp.IsStep13Completed) {
    return "/publish";
  } else if (selectedApp.IsStep4Completed) {
    return "/design";
  } else if (selectedApp.IsStep3Completed) {
    return "/manage/engagement-options";
  } else if (selectedApp.IsStep2Completed) {
    return "/manage/payment-options";
  } else if (selectedApp.IsStep1Completed) {
    return "/manage/config-settings";
  } else {
    return "/manage/web-service-control/begin";
  }
};

export const isScreenRestricted = (
  whereUserLeft: string,
  whereUserWantToGo: string
) => {
  const pageSequence = [
    "/manage/web-service-control/begin",
    "/manage/config-settings",
    "/manage/engagement-options",
    "/design",
    "/push-notification",
    "/publish",
    "/engage",
  ];
  const iWhereUserLeft = pageSequence.indexOf(whereUserLeft);
  const iWhereUserWantToGo = pageSequence.indexOf(whereUserWantToGo);

  return iWhereUserLeft >= iWhereUserWantToGo;
};

export const flattenObject = (data: any) => {
  var result: any = {};
  function recurse(cur: any, prop: any) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++)
        recurse(cur[i], prop + "[" + i + "]");
      if (l == 0) result[prop] = [];
    } else {
      var isEmpty = true;
      for (var p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + "." + p : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, "");
  return result;
};

export const popupWindow = ({
  url,
  windowName,
  w,
  h,
}: {
  url: string;
  windowName: string;
  w: number;
  h: number;
}) => {
  const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
  const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
  return window.open(
    url,
    windowName,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
  );
};

export const dateToString = (
  date: string | Date,
  type: "dd.mm.yyyy" | "dd.mm.yyyy, hh:mm" | "hh:mm" = "dd.mm.yyyy"
): string => {
  if (typeof date === "string") {
    date = new Date(date);
  }

  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  switch (type) {
    case "dd.mm.yyyy":
      return `${day}.${month}.${year}`;
    case "dd.mm.yyyy, hh:mm":
      return `${day}.${month}.${year}, ${hours}:${minutes}`;
    case "hh:mm":
      return `${hours}:${minutes}`;
  }
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)?.[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
