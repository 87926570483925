import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Steps from "./components/Steps";
import StepsToggler from "./components/StepsToggler";
import Button from "panel/src/_components/common/Button";
import Progress from "panel/src/_components/common/Progress";
import * as manageAppActions from "panel/src/redux/actions/manageApp";
import classes from "./verify.module.scss";
import { baseUrl } from "panel/src/services/api/config";
import $ from "jquery";
import Icon from "panel/src/_components/common/Icon";
import BlockingForm from "panel/src/_components/common/BlockingForm";
import SubNavigation from "panel/src/_components/primitives/SubNavigation";
import Intercom from "../../../../Components/Intercom/Intercom";

window.jQuery = $;
require("signalr");

interface IVerifyProps {
  platformId: string;
  appUrl: string;
  loading: boolean;
  error: string;
  appId: string;
  setAppStart?: any;
  onSetApp?: any;
  history?: any;
  setPlatformObject?: any;
  actions?: any;
  isPageLoading: boolean;
  isTrial: boolean;
}

interface IVerifyState {
  verificationSteps?: any;
  proxy?: any;
  isDetailVisible?: boolean;
  completedPercentace?: string;
  serviceStatus?: number;
  isBlocking: boolean;
}

export enum StepStatusEnum {
  Inprogress = 0,
  Succeeded = 1,
  Failed = 2,
  Skippable = 3,
  AllSucceeded = 4,
  Skipped = 5,
}

class Verify extends Component<IVerifyProps, IVerifyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      proxy: null,
      isDetailVisible: false,
      serviceStatus: StepStatusEnum.Inprogress,
      completedPercentace: "0%",
      verificationSteps: [],
      isBlocking: false,
    };
  }

  messageHandler = (
    title: any,
    description: any,
    compoletedPercentace: any,
    status: any
  ) => {
    const index = this.state.verificationSteps.findIndex(
      (verificationStep: any) => {
        return verificationStep.title === title;
      }
    );

    const verificationStep = { ...this.state.verificationSteps[index] };

    verificationStep.state = status;
    if (status !== StepStatusEnum.Skippable) {
      verificationStep.description = description;
    }

    const updatedVerificationSteps = [...this.state.verificationSteps];
    updatedVerificationSteps[index] = verificationStep;

    this.setState({
      verificationSteps: updatedVerificationSteps,
    });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const connection = $.hubConnection(`${baseUrl}/ServiceCheckHub`);

    const proxy = connection.createHubProxy("WSCHub");

    let indexCounter = 0;
    proxy.on(
      "sendMessage",
      (
        title: any,
        description: any,
        status: any,
        completedPercentace: any,
        skipKey: any
      ) => {
        this.setState({ serviceStatus: status });
        indexCounter++;
        let newObj = {
          title: title,
          state: -1,
          description: description,
          skipKey: skipKey,
          orderNo: indexCounter,
        };
        this.setState({
          verificationSteps: [...this.state.verificationSteps, newObj],
          completedPercentace: `${completedPercentace}%`,
          isBlocking: true,
        });
        this.messageHandler(title, description, completedPercentace, status);
      }
    );

    connection.logging = true;
    connection
      .start()
      .done(() => {
        proxy
          .invoke(
            "StartWSCheck",
            this.props.appUrl,
            connection.id,
            true,
            this.props.platformId,
            {
              ShopifyKey:
                this.props.setPlatformObject &&
                this.props.setPlatformObject.parameters &&
                this.props.setPlatformObject.parameters.ShopifyKey
                  ? this.props.setPlatformObject.parameters.ShopifyKey
                  : "",
              IsTrial: this.props.isTrial?.toString(),
            }
          )
          .done(() => {
            this.setState({ proxy });
          })
          .fail(() => {
            console.log("Could not invoke server hub method.");
          });
      })
      .fail(() => {
        console.log("Could not connect to hub.");
      });
  };

  getProgressType = () => {
    const finishedSteps = this.state.verificationSteps.filter(
      (step: any) => step.state > 0
    );

    let progressType = null;
    if (
      finishedSteps.every(
        (step: any) =>
          step.state === StepStatusEnum.Succeeded ||
          step.state === StepStatusEnum.Skipped
      )
    ) {
      progressType = "Success";
    } else if (
      finishedSteps.some((step: any) => step.state === StepStatusEnum.Failed)
    ) {
      progressType = "Danger";
    } else if (
      finishedSteps.some((step: any) => step.state === StepStatusEnum.Skippable)
    ) {
      progressType = "Warning";
    } else if (
      finishedSteps.some(
        (step: any) => step.state === StepStatusEnum.AllSucceeded
      )
    ) {
      progressType = "Success";
    } else {
      console.log("There is a problem with the getProgressType method!");
    }

    return progressType;
  };

  toggleHandler = () => {
    this.setState((prevState) => {
      return {
        isDetailVisible: !prevState.isDetailVisible,
      };
    });
  };

  setApp = () => {
    this.setState({ isBlocking: false });
    const { setPlatformObject } = this.props;
    const obj = setPlatformObject;
    this.props.actions.setAppStart(obj);
  };

  backButton = () => {
    this.setState({ isBlocking: false }, async () => {
      const { history, platformId, appUrl } = this.props;

      history.push("/manage/web-service-control/begin", {
        previousPlatformId: platformId,
        previousUrl: appUrl,
      });
    });
  };

  reloadPage = () => {
    this.setState(
      {
        verificationSteps: [],
        completedPercentace: `${0}%`,
        serviceStatus: StepStatusEnum.Inprogress,
      },
      () => {
        this.loadData();
      }
    );
  };
  render() {
    const finishedSteps = this.state.verificationSteps
      .filter((step: any) => {
        return step.state > 0;
      })
      .sort((a: any, b: any) => b.orderNo - a.orderNo);

    const stepDetails = this.state.isDetailVisible ? (
      <Steps steps={finishedSteps} />
    ) : null;

    const commands: any = [];

    this.state.verificationSteps
      .filter((step: any) => step.state === StepStatusEnum.Failed)
      .reduce((prev: any, cur: any) => {
        const items = cur.title.split(".");
        const commandName = items[items.length - 1];

        commands.push(commandName);
        return commands;
      }, commands);

    const verificationErrorSummary =
      this.state.serviceStatus === StepStatusEnum.Failed ? (
        <p className={classes.VerificationErrorSummary}>
          If this issue persists please contact us directly via live-chat or
          <span>
            <a
              className={classes.mailLink}
              target="_blank"
              href="mailto: support@mowico.com"
            >
              support@mowico.com
            </a>
          </span>
        </p>
      ) : null;
    return (
      <React.Fragment>
        <SubNavigation />
        <Intercom />
        <div className={classes.contentContainer}>
          <div className={classes.ManageSubRouteContainer}>
            {(this.state.serviceStatus === StepStatusEnum.Failed ||
              this.state.serviceStatus === StepStatusEnum.AllSucceeded) && (
              <div
                className={classes.backIconContainer}
                onClick={this.backButton}
              >
                <Icon
                  className={classes.backIcon}
                  type="arrowLeft"
                  width="16"
                  height="16"
                  fillColor="#3196f6"
                />
                <span className={classes.backIconLabel}>Back</span>
              </div>
            )}
            <div>
              {this.state.serviceStatus !== StepStatusEnum.Failed &&
                this.state.serviceStatus !== StepStatusEnum.AllSucceeded && (
                  <div>
                    <h3 className={classes.Heading}>
                      Your information is currently being validated.
                    </h3>
                    {this.props.isTrial ? (
                      <p className={classes.Paragraph}>
                        Your e-commerce store data is now being validated. This
                        may take up a few minutes.
                      </p>
                    ) : (
                      <p className={classes.Paragraph}>
                        We are validating your information for a flawless
                        integration. This may take up a few minutes.
                      </p>
                    )}
                  </div>
                )}
              {this.state.serviceStatus === StepStatusEnum.AllSucceeded &&
                (this.props.isTrial ? (
                  <div>
                    <h3 className={classes.Heading}>
                      The data has successfully been validated.
                    </h3>
                  </div>
                ) : (
                  <div>
                    <h3 className={classes.Heading}>
                      Your information is successfully validated.
                    </h3>
                    <p className={classes.Paragraph}>
                      You may start configuring your app setting by pressing
                      “Continue”.
                    </p>
                  </div>
                ))}
              {this.state.serviceStatus === StepStatusEnum.Failed && (
                <div>
                  <h3 className={classes.Heading}>
                    Your information cannot be validated.
                  </h3>
                  <p className={classes.Paragraph}>
                    Please check the details below to fix the problem(s)
                    “Branches by location service” and try again.
                  </p>
                </div>
              )}
              <Progress
                progressLoadingShow={
                  this.state.serviceStatus === StepStatusEnum.Inprogress
                }
                type={this.getProgressType()}
                width={this.state.completedPercentace}
              />
              <StepsToggler
                isDetailVisible={this.state.isDetailVisible}
                toggleHandler={this.toggleHandler}
              />
              {stepDetails}
              {verificationErrorSummary}
            </div>
          </div>
          <div className={classes.SaveButtonContainer}>
            {this.state.serviceStatus === StepStatusEnum.Failed && (
              <div className={classes.tryAgainButtonContainer}>
                <Button color={"Ghost"} width="160px" onClick={this.reloadPage}>
                  Try Again
                </Button>
              </div>
            )}
            <Button
              type="Primary"
              disableButton={
                 !(this.state.serviceStatus === StepStatusEnum.AllSucceeded && parseFloat(this.state.completedPercentace??'') > 99)
              }
              width="160px"
              onClick={this.setApp}
            >
              Continue
            </Button>
          </div>
        </div>
        <BlockingForm isBlocking={this.state.isBlocking} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    platformId: state.manageApp.platformId,
    appUrl: state.manageApp.appUrl,
    loading: state.manageApp.loading,
    isPageLoading: state.modal.isPageLoading,
    error: state.manageApp.error,
    appId: state.userInfo.selectedAppId,
    setPlatformObject: state.manageApp.setPlatformObject,
    isTrial: state.userInfo.IsTrial,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(manageAppActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
