import styled from "styled-components";

interface IButton {
  theme?: "blue" | "white" | "whiteWithBlueBorder" | undefined;
  width?: string;
}

export default styled.button<IButton>`
  display: block;
  background-color: ${(props) => {
    let bgColor = "#3196f6";

    switch (props.theme) {
      case "white":
        bgColor = "#fff";
        break;
      case "whiteWithBlueBorder":
        bgColor = "#fff";
        break;
      default:
        break;
    }
    return bgColor;
  }};
  color: ${(props) => {
    let fontColor = "#fff";

    switch (props.theme) {
      case "white":
        fontColor = "#3196f6";
        break;
      case "whiteWithBlueBorder":
        fontColor = "#3196f6";
        break;
      default:
        break;
    }
    return fontColor;
  }};
  border: 2px solid
    ${(props) => {
      let borderColor = "#3196f6";

      switch (props.theme) {
        case "white":
          borderColor = "#fff";
          break;
        case "whiteWithBlueBorder":
          borderColor = "#3196f6";
          break;
        default:
          break;
      }
      return borderColor;
    }};
  font-size: 14px;
  font-weight: 700;
  border-radius: 5px;
  padding: ${(props) => (props.width ? "8px 10px" : "10px 25px")};
  cursor: pointer;
  letter-spacing: 0.5px;
  width: ${(props) => (props.width ? props.width : "100%")};
  &:hover {
    background-color: ${(props) => {
      let bgColor = "#1f81df";

      switch (props.theme) {
        case "white":
          bgColor = "#fff";
          break;
        case "whiteWithBlueBorder":
          bgColor = "#fff";
          break;
        default:
          break;
      }
      return bgColor;
    }};
    border: 2px solid
      ${(props) => {
        let borderColor = "#1f81df";

        switch (props.theme) {
          case "white":
            borderColor = "#fff";
            break;
          case "whiteWithBlueBorder":
            borderColor = "#1f81df";
            break;
          default:
            break;
        }
        return borderColor;
      }};
    cursor: pointer;
  }
`;
