import React from "react";

interface IWatchVideoProps {
  width: string;
  height: string;
  fillColor: string;
  type: string;
}

const WatchVideo = ({ width, height, fillColor }: IWatchVideoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M24 5.571V4.03C24 3.46 23.561 3 23.02 3H.98C.439 3 0 3.46 0 4.029V5.57h2.449v2.572H0v2.571h2.449v2.572H0v2.571h2.449v2.572H0v1.542C0 20.54.439 21 .98 21h22.04c.541 0 .98-.46.98-1.029V18.43h-2.449v-2.572H24v-2.571h-2.449v-2.572H24V8.143h-2.449V5.57H24zM9.306 15.857V8.143L15.43 12l-6.123 3.857z"
    />
  </svg>
);

export default WatchVideo;
