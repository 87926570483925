import axios from "panel/src/services/api/api";
import * as clientConfigListActions from "panel/src/redux/actions/clientConfigList";
import {
  CLIENT_CONFIG_LIST_START,
  SAVE_APP_CONFIG_LIST_START,
} from "panel/src/redux/constants/clientConfigList";
import { takeLatest, put, call } from "redux-saga/effects";
import * as userInfoActions from "panel/src/redux/actions/userInfo";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";

function* clientConfigListProcess({ configListObj }: any) {
  yield put(startAppLoading(CLIENT_CONFIG_LIST_START));
  try {
    const response = yield call(
      axios.post,
      "/ConfigService/api/Home/GetClientConfigList",
      { ...configListObj }
    );

    yield put(
      clientConfigListActions.clientConfigListSuccess(
        response.data.ResultData.ConfigGroups
      )
    );
  } catch (error) {
    yield put(clientConfigListActions.clientConfigListRejected(error));
  }
  yield put(endAppLoading(CLIENT_CONFIG_LIST_START));
}

function* saveAppConfigListProcess({ data }: any) {
  yield put(startAppLoading(SAVE_APP_CONFIG_LIST_START));

  try {
    const response = yield call(
      axios.post,
      "ConfigService/api/Home/SaveApplicationConfigList",
      { ...data }
    );
    yield put(
      clientConfigListActions.saveAppConfigListSuccess(
        response.data.ResultData.ConfigGroups,
        response.data.ResultCode
      )
    );
  } catch (error) {
    yield put(clientConfigListActions.saveAppConfigListRejected(error));
  }

  yield put(userInfoActions.getUserInfo());
  yield put(endAppLoading(SAVE_APP_CONFIG_LIST_START));
}

export function* saveAppConfigListProcessSaga() {
  yield takeLatest(SAVE_APP_CONFIG_LIST_START, saveAppConfigListProcess);
}

export function* clientConfigListProcessSaga() {
  yield takeLatest(CLIENT_CONFIG_LIST_START, clientConfigListProcess);
}
