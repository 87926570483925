import IGetProductDetailDataRequest from "../../models/Design/productDetail/IGetProductDetailDataRequest";

import {
  GET_PRODUCT_DETAIL_DATA,
  GET_PRODUCT_DETAIL_DATA_SUCCESS,
  GET_PRODUCT_DETAIL_DATA_FAIL,
  RESET_PRODUCT_DETAIL_DATA,
} from "panel/src/redux/constants/productDetail";

interface BaseAction {
  type: string;
  data?: Object;
  error?: string;
  requestObj?: IGetProductDetailDataRequest;
}

export const getProductDetailData = (
  requestObj: IGetProductDetailDataRequest
): BaseAction => ({
  type: GET_PRODUCT_DETAIL_DATA,
  requestObj,
});

export const getProductDetailDataSuccess = (data: Object): BaseAction => ({
  type: GET_PRODUCT_DETAIL_DATA_SUCCESS,
  data,
});

export const getProductDetailDataFail = (error: string): BaseAction => ({
  type: GET_PRODUCT_DETAIL_DATA_FAIL,
  error,
});

export const resetProductDetailData = () => ({
  type: RESET_PRODUCT_DETAIL_DATA,
});
