import styled from "styled-components";

export default styled.div`
  font-family: Red Hat Display;
  font-weight: 500;
  font-size: 14px;
  color: #3e5e6e;
  margin-top: 5px;
  text-align: center;
`;
