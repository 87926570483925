import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import BannerSettings from "./BannerSettings";
import EditContent from "./ContentSettings/EditContent";
import CreateNewLanguage from "./ContentSettings/CreateNewLanguage";
import EditContentLanguage from "./ContentSettings/EditContent/EditContentLanguage";
import CreateContentLanguage from "./ContentSettings/CreateNewLanguage/CreateContentLanguage";
import ConfigSettings from "./ConfigSettings";

class Engage extends Component {
  render() {
    const routes = (
      <Switch>
        <Route
          exact
          path="/engage/content-settings/edit-content"
          component={EditContent}
        />
        <Route
          exact
          path="/engage/content-settings/create-new-language"
          component={CreateNewLanguage}
        />
        <Route
          exact
          path="/engage/content-settings/edit-content/:languageId/:languageName"
          component={EditContentLanguage}
        />
        <Route
          exact
          path="/engage/content-settings/create-new-language/:rLanguageId/:rLanguageName/:cLanguageId/:cLanguageName"
          component={CreateContentLanguage}
        />
        <Redirect from="/engage/" to="/engage/content-settings/edit-content" />
      </Switch>
    );

    return <React.Fragment>{routes}</React.Fragment>;
  }
}

export default Engage;
