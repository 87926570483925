import React from "react";

interface IProps {
    width: string;
    height: string;
    fillColor: string;
    type: string;
}

const Time = ({ width, height, fillColor }: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
        <path fill={fillColor} fillRule="evenodd" d="M8 0C3.582 0 0 3.582 0 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zm0 1.74c3.458 0 6.26 2.802 6.26 6.26 0 3.458-2.802 6.26-6.26 6.26-3.458 0-6.26-2.802-6.26-6.26 0-3.458 2.802-6.26 6.26-6.26zm.403 2.122h-1.23V8.63l2.992 2.957.87-.86-2.632-2.6V3.862z" />
    </svg>
);

export default Time;
