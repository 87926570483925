import {
    GET_CMS_KEYS_START,
    GET_CMS_KEYS_FINISH,
    GET_CMS_KEYS_REJECTED,
    GET_CMS_KEY_VALUES_START,
    GET_CMS_KEY_VALUES_FINISH,
    GET_CMS_KEY_VALUES_REJECTED,
    UPDATE_CMS_KEY_VALUES_START,
    UPDATE_CMS_KEY_VALUES_FINISH,
    UPDATE_CMS_KEY_VALUES_REJECTED,
} from "panel/src/redux/constants/cmsKeys";

const initialState = {
    cmsKeys: [],
    cmsKeyValues: [],
    updateCmsKeyValues: false
};

const cmsKeysReducer = (state: Object = initialState, action: any) => {
    switch (action.type) {
        case GET_CMS_KEYS_START:
            return {
                ...state,
            };

        case GET_CMS_KEYS_FINISH:
            return {
                ...state,
                cmsKeys: action.cmsKeys,
            };
        case GET_CMS_KEYS_REJECTED:
            return {
                ...state,
                error: action.error,
            };
        case GET_CMS_KEY_VALUES_START:
            return {
                ...state,
            };

        case GET_CMS_KEY_VALUES_FINISH:
            return {
                ...state,
                cmsKeyValues: action.cmsKeyValues,
            };
        case GET_CMS_KEY_VALUES_REJECTED:
            return {
                ...state,
                error: action.error,
            };
        case UPDATE_CMS_KEY_VALUES_START:
            return {
                ...state,
            };

        case UPDATE_CMS_KEY_VALUES_FINISH:
            return {
                ...state,
                updateCmsKeyValues: action.responseObj
            };
        case UPDATE_CMS_KEY_VALUES_REJECTED:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};

export default cmsKeysReducer;
