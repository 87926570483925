import ISaveScreenModel from "../../models/SaveScreenModel";
import {
  SAVE_SCREEN_DATA,
  SAVE_SCREEN_DATA_SUCCESS,
  SAVE_SCREEN_DATA_FAIL,
  RESET_SAVE_SCREEN_DATA,
} from "panel/src/redux/constants";

interface BaseAction {
  type: string;
  data?: Object;
  error?: string;
  requestObj?: ISaveScreenModel;
}

export const saveScreenData = (requestObj: ISaveScreenModel): BaseAction => ({
  type: SAVE_SCREEN_DATA,
  requestObj,
});

export const saveScreenDataSuccess = (data: Object): BaseAction => ({
  type: SAVE_SCREEN_DATA_SUCCESS,
  data,
});

export const saveScreenDataFail = (error: string): BaseAction => ({
  type: SAVE_SCREEN_DATA_FAIL,
  error,
});

export const resetSaveScreenData = () => ({
  type: RESET_SAVE_SCREEN_DATA,
});
