import { Platform } from "react-native";
import styled from "styled-components/native";
import { dimenFont } from "shared-components/src/utils/helper";
import { colors } from "shared-components/src/utils/constants";

interface IProductPrice {
  hasdiscount?: number;
  tertiary?: string;
  fontFamily?: any;
}

export default styled.Text<IProductPrice>`
  font-size: ${Platform.OS === "web" ? "10.5" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web" ? props.fontFamily : props.fontFamily!.Bold};
  color: ${(props) => (props.hasdiscount ? colors.coolGrey : props.tertiary)};
  ${(props) => props.hasdiscount && "text-decoration-line: line-through;"}
  line-height:${Platform.OS === "web" ? "12.5" : dimenFont(16)}px;
`;
