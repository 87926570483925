export const GET_GENERAL_SETTINGS_START: string = "GET_GENERAL_SETTINGS_START";
export const GET_GENERAL_SETTINGS_FINISH: string =
  "GET_GENERAL_SETTINGS_FINISH";
export const GET_GENERAL_SETTINGS_REJECTED: string =
  "GET_GENERAL_SETTINGS_REJECTED";

export const SET_GENERAL_SETTINGS_START: string = "SET_GENERAL_SETTINGS_START";
export const SET_GENERAL_SETTINGS_FINISH: string =
  "SET_GENERAL_SETTINGS_FINISH";
export const SET_GENERAL_SETTINGS_REJECTED: string =
  "SET_GENERAL_SETTINGS_REJECTED";

export const GET_ALL_LANGUAGE_START: string = "GET_ALL_LANGUAGE_START";
export const GET_ALL_LANGUAGE_FINISH: string = "GET_ALL_LANGUAGE_FINISH";
export const GET_ALL_LANGUAGE_REJECTED: string = "GET_ALL_LANGUAGE_REJECTED";
export const SET_INSERT_LANGUAGE_START: string = "SET_INSERT_LANGUAGE_START";
export const SET_INSERT_LANGUAGE_FINISH: string = "SET_INSERT_LANGUAGE_FINISH";
export const SET_INSERT_LANGUAGE_REJECTED: string = "SET_INSERT_LANGUAGE_REJECTED";
