import styled from "styled-components";

interface IIcon {
  marginRight?: string;
}

export default styled.img<IIcon>`
  ${(props) => props.marginRight && `margin-right: ${props.marginRight}px;`}
  object-fit: contain;
  height: 15px;
  width: 15px;
`;
