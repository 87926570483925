import styled from "styled-components";

interface ILabelProps {
  isPrimary?: boolean;
}

export default styled.label<ILabelProps>`
  color: ${(props) => (props.isPrimary ? "#3196f6" : "#fff")};
  font-family: Red Hat Display;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
`;
