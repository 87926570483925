import {
  FETCH_USERINFO_START,
  FETCH_USERINFO_SUCCESS,
  FETCH_USERINFO_FAIL,
  DELETE_APP_SUCCESS,
  CREATE_APP_SUCCESS,
  RENAME_APP_SUCCESS,
  PRICING_BANNER_TOOGLE,
  SET_SELECTED_APP,
} from "panel/src/redux/constants/userInfo";
import { LOGOUT_SUCCESS } from "../constants/logout";

export type LoginStateTypes = Readonly<{
  loading?: boolean;
  error?: boolean;
  userName?: string;
  userId?: string;
  emailAddress?: string;
  apps?: any;
  isShowPricingBanner: boolean;
  RemainingDays: number | null;
  IsTrial: boolean;
  UserPackage?:any
  selectedAppInfo?: any;
}>;

const initialState: LoginStateTypes = {
  loading: false,
  error: false,
  userName: "",
  userId: "",
  emailAddress: "",
  apps: [],
  isShowPricingBanner: true,
  RemainingDays: 0,
  IsTrial: false,
  UserPackage:[],
  selectedAppInfo: null,
};

const userInfoReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_USERINFO_START:
      return {
        ...state,
        userName: action.username,
        userId: action.userId,
        apps: action.apps,
      };
    case FETCH_USERINFO_SUCCESS:
      let selectedAppId = undefined;
      let selectedApp = action.payload.apps.find(
        (item: any) => item.IsSelected
      );

      if (selectedApp) {
        selectedAppId = selectedApp.AppId;
      } else {
        selectedApp = {
          AppId: undefined,
          AppName: undefined,
          CreateDate: undefined,
          IsSelected: undefined,
          IsStep1Completed: undefined,
          IsStep2Completed: undefined,
          IsStep3Completed: undefined,
          IsStep4Completed: undefined,
          IsStep5Completed: undefined,
          IsStep6Completed: undefined,
          IsStep7Completed: undefined,
          IsStep8Completed: undefined,
          IsStep9Completed: undefined,
          IsStep10Completed: undefined,
          IsStep11Completed: undefined,
          IsStep12Completed: undefined,
          IsStep13Completed: undefined,
          PlatformId: undefined,
        };
      }

      return {
        ...state,
        ...action.payload,
        selectedApp,
        selectedAppId,
      };
    case FETCH_USERINFO_FAIL:
      return {
        ...state,
        error: true,
      };
    case DELETE_APP_SUCCESS:
      return {
        ...state,
        apps: state.apps.filter(
          (app: any) => app.AppId !== action.appId.ApplicationId
        ),
      };

    case CREATE_APP_SUCCESS:
      return {
        ...state,
        apps: [
          ...state.apps,
          {
            AppId: action.responseObj.AppId,
            IsStoreApp: false,
            IsStep1Completed: false,
            IsStep2Completed: false,
            IsStep3Completed: false,
            IsStep4Completed: false,
            IsStep5Completed: false,
            IsStep6Completed: false,
            IsStep7Completed: false,
            IsStep8Completed: false,
            IsStep9Completed: false,
            IsStep10Completed: false,
            IsStep11Completed: false,
            PlatformId: 0,
            AppName: action.responseObj.Name,
            CreateDate: new Date().toLocaleDateString(),
          },
        ],
      };
    case RENAME_APP_SUCCESS:
      let app = state.apps.find(
        (app: any) => app.AppId === action.responseObj.ApplicationId
      );
      app.AppName = action.responseObj.Name;
      let applist = state.apps.filter(
        (app: any) => app.AppId !== action.responseObj.ApplicationId
      );
      return {
        ...state,
        apps: [...applist, app],
      };
    case PRICING_BANNER_TOOGLE:
      return {
        ...state,
        isShowPricingBanner: !state.isShowPricingBanner,
      };
    case LOGOUT_SUCCESS: 
      return initialState;
    case SET_SELECTED_APP:
      return {
        ...state,
        selectedAppInfo: action.payload,
      }
    default:
      return state;
  }
};

export default userInfoReducer;
