import React, { useState, useEffect, useRef } from "react";
import StarIcon from "panel/src/assets/images/icons-dark-gray-star-filled.svg";
import SelectModel from "../../../../models/Design/selectModel";
import classes from "./screenSelection.module.scss";
import Icon from "panel/src/_components/common/Icon";
import tick from "../../../../assets/images/tick.svg";
import { useSelector } from "react-redux";
import YoutubeTutorial, {
  YOUTUBE_LINKS,
} from "../../../../Components/YoutubeTutorial";
import UpgradeTooltip from "../../../../Components/UpgradeTooltip";

interface ISelectionProps {
  options: SelectModel[];
  selectedValue: string;
  selectChangeHandler: Function;
}

interface IYoutubeComponent {
  selectedValue: string;
}

const YoutubeComponent = (props: IYoutubeComponent) => {
  let url;

  switch (props.selectedValue) {
    case "1":
      url = YOUTUBE_LINKS.GENERAL_SETTINGS;
      break;
    case "2":
      url = YOUTUBE_LINKS.SPLASH;
      break;
    case "3":
      url = YOUTUBE_LINKS.NAVIGATION_TYPE;
      break;
    case "4":
      url = YOUTUBE_LINKS.CATEGORY_LAYOUT;
      break;
    case "5":
      url = YOUTUBE_LINKS.SLIDERS_BANNERS;
      break;
    case "6":
      url = YOUTUBE_LINKS.PRODUCT_LISTING;
      break;
    case "7":
      url = YOUTUBE_LINKS.PRODUCT_DETAIL;
      break;
    case "8":
      url = YOUTUBE_LINKS.COLOR;
      break;
    case "9":
      url = YOUTUBE_LINKS.FONT;
      break;

    default:
      url = null;
      break;
  }

  if (url === null) {
    return null;
  }

  return <YoutubeTutorial videoUrl={url} iconSize={16} />;
};

const ScreenSelection = (props: ISelectionProps) => {
  const selectNode: any = useRef();
  const [isOpen, setOpen] = useState(false);

  const storeState: any = useSelector((state) => state);

  const isDesignCompleted =
    storeState?.userInfo?.selectedApp?.IsStep13Completed;

  const selectedOption = props.options.find(
    (option: any) => option.value === props.selectedValue
  );

  const selectOption = (option: any) => {
    changeHandler({ target: option });
    setOpen(false);
  };

  const clickOutside = (e: any) => {
    if (selectNode.current && !selectNode.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", clickOutside);

    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);

  const changeHandler = (e: any) => {
    props.selectChangeHandler(e);
    setOpen(false);
  };

  const attachedClasses = [classes.menuActivator];
  return (
    <React.Fragment>
      <div className={classes.DesignConfigContainer}>
        <p className={classes.Label}>
          {isDesignCompleted ? "Select a screen to design" : "Category"}
        </p>

        <div className={classes.HeaderDropdown} ref={selectNode}>
          <div
            className={attachedClasses.join(" ")}
            onClick={() => setOpen(!isOpen)}
          >
            {selectedOption ? (
              <span className={`${classes.SelectInput}`}>
                {selectedOption.displayValue}
              </span>
            ) : (
              <span className={classes.empty}></span>
            )}
            <div className={classes.RightSection}>
              <YoutubeComponent selectedValue={props.selectedValue} />
              <Icon
                className={`${classes.arrowIcon} ${
                  isOpen && classes.rotateIcon
                }`}
                type="arrowDown"
                fillColor="#3e5e6e"
                width="16"
                height="16"
              />
            </div>
          </div>

          {isOpen && (
            <div className={classes.menuWrapper}>
              <ul className={classes.menu}>
                {props.options.map((option: any) => (
                  <li key={option.value}>
                    <button
                      type="button"
                      disabled={props.selectedValue === option.value}
                      className={`${classes.menuBtn} ${
                        !option.isComplated ? classes.Disabled : ""
                      } ${
                        option.value === props.selectedValue
                          ? classes.ActiveItem
                          : ""
                      }`}
                      onClick={() => {
                        if (option.isComplated) selectOption(option);
                      }}
                    >
                      {option.displayValue}
                      {option.isComplated && (
                        <img src={tick} className={classes.TickIcon} />
                      )}
                      {!option.isComplated && option.isPackageSupported && (
                        <UpgradeTooltip label={option.displayValue || ""} />
                      )}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <hr className={classes.Seperator} />
      </div>
    </React.Fragment>
  );
};

export default ScreenSelection;
