import React from "react";
import { ColorList } from "../../../models/Design/colors/IGetColorsPageDataResponse";
// SHARED COMPONENTS START
import {
  Rayon,
  ProductCard,
  ProductName,
  ProductDetails,
  DescriptionField,
  StockAvailability,
  InstallmentDetails,
  SubscriptionOption,
  CommentSection,
  Options,
  ProductSlider,
  MetaField,
  CustomHtml,
  AddToCartBar,
} from "shared-components/src/App";
// SHARED COMPONENTS END

import { IComponent } from "panel/src/Components/DragnDrop/Components";
import { ComponentType } from "panel/src/utils/enums/componentType";
import { PageType } from "panel/src/utils/enums/pageType";
import ArrowRight from "panel/src/_components/common/Icon/Icons/ArrowRight";

export const getProductDetailComponent = (
  fontFamily: string,
  colorList: ColorList[],
  componentId: number,
  ResponseJSON: any,
  setProductSliderDimension: any,
  CustomJSON?: any
) => {
  let component: IComponent | undefined;
  switch (componentId) {
    case ComponentType.ProductSlider:
      component = {
        componentId: ComponentType.ProductSlider,
        pageId: PageType.ProductDetail,
        componentTitle: "Product Slider",
        component: (
          <ProductSlider
            Images={ResponseJSON.Images}
            imageResizeType={ResponseJSON.imageResizeType}
            onLayout={(e) => setProductSliderDimension(e)}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.Properties:
      component = {
        componentId: ComponentType.Properties,
        pageId: PageType.ProductDetail,
        componentTitle: "Product Details",
        component: (
          <ProductDetails
            fontFamily={fontFamily}
            colorList={colorList}
            data={ResponseJSON}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.ProductSubscription:
      component = {
        componentId: ComponentType.ProductSubscription,
        pageId: PageType.ProductDetail,
        componentTitle: "Subscription Option",
        component: (
          <SubscriptionOption
            onSubscribe={() => {}}
            fontFamily={fontFamily}
            colorList={colorList}
            data={ResponseJSON}
            isBuyable
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.Installment:
      component = {
        componentId: ComponentType.Installment,
        pageId: PageType.ProductDetail,
        componentTitle: "Installment List",
        component: (
          <InstallmentDetails
            fontFamily={fontFamily}
            colorList={colorList}
            data={ResponseJSON}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.Header:
      component = {
        componentId: ComponentType.Header,
        pageId: PageType.ProductDetail,
        componentTitle: "Product Name",
        component: (
          <ProductName
            fontFamily={fontFamily}
            colorList={colorList}
            name={ResponseJSON.ProductName}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.Features:
      component = {
        componentId: ComponentType.Features,
        pageId: PageType.ProductDetail,
        componentTitle: "Product variant",
        component: (
          <Options
            fontFamily={fontFamily}
            colorList={colorList}
            data={ResponseJSON}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.Description:
      component = {
        componentId: ComponentType.Description,
        pageId: PageType.ProductDetail,
        componentTitle: "Description",
        component: (
          <DescriptionField
            fontFamily={fontFamily}
            colorList={colorList}
            description={ResponseJSON.Description}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.ProductAvailability:
      component = {
        componentId: ComponentType.ProductAvailability,
        pageId: PageType.ProductDetail,
        componentTitle: "Stock Availability",
        component: (
          <StockAvailability
            fontFamily={fontFamily}
            colorList={colorList}
            stock={ResponseJSON.StockAmount}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.Slider:
      component = {
        componentId: ComponentType.Slider,
        pageId: PageType.ProductDetail,
        componentTitle: "Rayon",
        component: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#fff",
              paddingTop: 5,
              paddingRight: 10,
              paddingBottom: 5,
              paddingLeft: 10,
              borderRadius: 5.2,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  fontSize: 10.4,
                  fontFamily: `${fontFamily} Bold`,
                  color: colorList ? colorList[0].colorCode : "#0f3649",
                }}
              >
                You may also like
              </div>
              <ArrowRight
                type="arrowRight"
                width="10"
                height="10"
                fillColor={colorList ? colorList[0].colorCode : "#0f3649"}
              />
            </div>
            <div style={{ display: "flex" }}>
              {ResponseJSON.Products.slice(0, 2).map(
                (item: any, index: number) => {
                  return (
                    <ProductCard
                      style={{
                        marginRight: index % 2 === 0 ? 10 : undefined,
                        flexGrow: 1,
                        flexShrink: 0,
                        flexBasis: "35%",
                      }}
                      fontFamily={fontFamily}
                      colorList={colorList}
                      product={item}
                      imageResizeType={ResponseJSON.imageResizeType}
                      key={index.toString()}
                    />
                  );
                }
              )}
            </div>
          </div>
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.Comment:
      component = {
        componentId: ComponentType.Comment,
        pageId: PageType.ProductDetail,
        componentTitle: "Comment Section",
        component: (
          <CommentSection
            fontFamily={fontFamily}
            colorList={colorList}
            data={ResponseJSON}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;

    case ComponentType.MetaField:
      component = {
        componentId: ComponentType.MetaField,
        pageId: PageType.ProductDetail,
        componentTitle: "Meta Field",
        component: (
          <MetaField
            fontFamily={fontFamily}
            colorList={colorList}
            metafields={ResponseJSON.metafields}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.CustomHtml:
      component = {
        componentId: ComponentType.CustomHtml,
        pageId: PageType.ProductDetail,
        componentTitle: "HTML",
        component: (
          <CustomHtml
            htmlInfo={ResponseJSON}
            fontFamily={fontFamily}
            colorList={colorList}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.ProductPrice:
      component = {
        componentId: ComponentType.ProductPrice,
        pageId: PageType.ProductDetail,
        componentTitle: "Add To Basket",
        component: (
          <AddToCartBar
            fontFamily={fontFamily}
            colorList={colorList}
            onCartAdd={() => {}}
            isBuyable={true}
            isPreorder={false}
            productPrice={"$5,00"}
            cms={{
              product_detail_total_price: "Total",
              product_detail_addtobasket: "Add to basket",
              product_sold_out: "Sold out",
              product_detail_preorder: "Pre-order",
            }}
          />
        ),
        editForm: null,
        // editForm: {
        //   component: <div />,
        //   editFormData: { ButtonType: buttonType },
        //   isShown: false,
        // },
      };
      break;
    default:
      break;
  }
  return component;
};
