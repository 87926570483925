import { put, call, takeLatest } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import IDemoInfoRequest from "../../models/Publish/demoInfoRequest";
import IGetDemoInfoResponse from "../../models/Publish/demoInfoResponse";
import { demoInfoSuccess, demoInfoFail } from "panel/src/redux/actions/demoInfo";
import { DEMO_INFO } from "panel/src/redux/constants/demoInfo";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";

function* demoInfo(payload: { requestObj: IDemoInfoRequest; type: string }) {
  yield put(startAppLoading(DEMO_INFO));

  try {
    const demoInfoResponse: IGetDemoInfoResponse = yield call(
      axios.post,
      "api/Manage/GetDemoInfo",
      {
        ApplicationId: payload.requestObj.applicationId,
      }
    );
    yield put(demoInfoSuccess(demoInfoResponse.data.ResultData));
  } catch (error) {
    yield put(demoInfoFail(error.message));
  }
  yield put(endAppLoading(DEMO_INFO));
}

export default function* demoInfoSaga() {
  yield takeLatest(DEMO_INFO, demoInfo);
}
