import React from "react";
import checkIcon from "../../../../../../assets/images/check-icon.svg";
import exclamationIcon from "../../../../../../assets/images/exclamation-icon.svg";
import crossIcon from "../../../../../../assets/images/cross-icon.svg";
import classes from "./Step.module.scss";
import { StepStatusEnum } from "panel/src/screens/Manage/WebServiceControl/Verify";

const step = (props: any) => {
  let icon = checkIcon;

  if (props.step.state === StepStatusEnum.Succeeded) {
    icon = checkIcon;
  } else if (props.step.state === StepStatusEnum.Failed) {
    icon = crossIcon;
  } else if (props.step.state === StepStatusEnum.Skippable) {
    icon = exclamationIcon;
  } else if (props.step.state === StepStatusEnum.Skipped) {
    icon = exclamationIcon;
  } else if (props.step.state === StepStatusEnum.AllSucceeded) {
    icon = checkIcon;
  }

  return (
    <div key={props.step.title} className={classes.Step}>
      <img src={icon} alt="state-icon" className={classes.StepIcon} />
      <p className={classes.Message}>{props.step.title}</p>
    </div>
  );
};

export default step;
