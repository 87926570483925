import React from "react";
import fullLogo from "../../../assets/images/logo-full.svg";
import shortLogo from "../../../assets/images/logo-short.svg";
import classes from "./logo.module.scss";

interface ILogoProps {
  isFullLogo: boolean;
}

const Logo = (props: ILogoProps) => {
  const logo = props.isFullLogo ? fullLogo : shortLogo;

  return <img src={logo} className={classes.Logo} alt="logo" />;
};

export default Logo;
