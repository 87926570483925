import Design from "./Design";
import Engage from "./Engage";
import Manage from "./Manage";
import Monitor from "./Monitor";
import WatchVideo from "./WatchVideo";
import ArrowDown from "./ArrowDown";
import AddIcon from "./AddIcon";
import Sort from "./Sort";
import Filter from "./Filter";
import Heart from "./Heart";
import LogOut from "./LogOut";
import ArrowLeft from "./ArrowLeft";
import Share from "./Share";
import Back from "./Back";
import Publish from "./Publish";
import Plus from "./Plus";
import Remove from "./Remove";
import Time from "./Time";
import LiveChat from "./LiveChat";
import ArrowUp from "./ArrowUp";
import Search from "./Search";
import ArrowRight from "./ArrowRight";
import Info from "./Info";
import Star from "./Star";
import Whatsapp from "./Whatsapp";
import Home from "./Home";
import IconBack from "./IconBack";
import IconShare from "./IconShare";
import Mail from "./Mail";
import HideEye from "./HideEye";
import ShowEye from "./ShowEye";
import EditIcon from "./EditIcon";
import TrashIcon from "./TrashIcon";
import CloseIcon from "./Close";

interface IIconProps {
  width: string;
  height: string;
  fillColor: string;
  type: string;
  className?: string;
}

const Icons = {
  design: (props: IIconProps) => Design(props),
  engage: (props: IIconProps) => Engage(props),
  manage: (props: IIconProps) => Manage(props),
  monitor: (props: IIconProps) => Monitor(props),
  watchVideo: (props: IIconProps) => WatchVideo(props),
  arrowDown: (props: IIconProps) => ArrowDown(props),
  addIcon: (props: IIconProps) => AddIcon(props),
  arrowLeft: (props: IIconProps) => ArrowLeft(props),
  sort: (props: IIconProps) => Sort(props),
  filter: (props: IIconProps) => Filter(props),
  heart: (props: IIconProps) => Heart(props),
  logOut: (props: IIconProps) => LogOut(props),
  share: (props: IIconProps) => Share(props),
  back: (props: IIconProps) => Back(props),
  publish: (props: IIconProps) => Publish(props),
  plus: (props: IIconProps) => Plus(props),
  remove: (props: IIconProps) => Remove(props),
  time: (props: IIconProps) => Time(props),
  liveChat: (props: IIconProps) => LiveChat(props),
  arrowUp: (props: IIconProps) => ArrowUp(props),
  search: (props: IIconProps) => Search(props),
  arrowRight: (props: IIconProps) => ArrowRight(props),
  info: (props: IIconProps) => Info(props),
  star: (props: IIconProps) => Star(props),
  whatsapp: (props: IIconProps) => Whatsapp(props),
  home: (props: IIconProps) => Home(props),
  iconBack: (props: IIconProps) => IconBack(props),
  iconShare: (props: IIconProps) => IconShare(props),
  mail: (props: IIconProps) => Mail(props),
  showEye: (props: IIconProps) => ShowEye(props),
  hideEye: (props: IIconProps) => HideEye(props),
  editIcon: (props: IIconProps) => EditIcon(props),
  trashIcon: (props: IIconProps) => TrashIcon(props),
  closeIcon: (props: IIconProps) => CloseIcon(props),
};

export default Icons;
