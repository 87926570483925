import styled from "styled-components";
import { OS } from "../../ToggleOS";

interface IContainer {
  currentOS: OS;
}

export default styled.div<IContainer>`
  position: absolute;
  top: ${(props) => (props.currentOS === OS.IOS ? "35" : "54")}px;
  right: ${(props) => (props.currentOS === OS.IOS ? "20" : "14")}px;
  left: ${(props) => (props.currentOS === OS.IOS ? "20" : "14")}px;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  pointer-events: none;
`;
