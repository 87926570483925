import React from "react";
import Container from "./Container";
import RadioButton from "./RadioButton";
import RadioButtonLabel from "./RadioButtonLabel";
import Label from "./Label";

interface IRadioButton extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  onChangeHandler: Function;
}

const RadioButtonComponent = ({
  label,
  onChangeHandler,
  ...rest
}: IRadioButton) => {
  return (
    <Container>
      <RadioButton
        type="radio"
        onChange={(e) => onChangeHandler(e)}
        {...rest}
      />
      <RadioButtonLabel />
      <Label>{label}</Label>
    </Container>
  );
};

export default RadioButtonComponent;
