import styled from "styled-components";

export default styled.div<{ relative: boolean; }>`
  position: ${props => props?.relative ? "relative" : "absolute"};
  top: 23px;
  left: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
