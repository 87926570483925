import React from "react";
import Wrapper from "./Wrapper";
import Input from "./Input";
import Label from "./Label";
import ErrorMessage from "./ErrorMessage";
import FormikError from "../FormikError";

interface IInput {
  id: string;
  style?: object;
  label: string;
  placeholder: string;
  value: string;
  onChangeHandler: Function;
  handleError?: any;
  handleTouched?: any;
  disabled?: boolean;
}

const InputComponent = ({
  id,
  style,
  label,
  placeholder,
  value,
  onChangeHandler,
  handleError,
  handleTouched,
  disabled,
}: IInput) => {
  const errorMessage = FormikError(handleError, handleTouched, id);
  return (
    <Wrapper style={style}>
      <Label htmlFor={id}>{label}</Label>
      <Input
        id={id}
        name={id}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChangeHandler(e)}
        hasError={errorMessage ? true : false}
        disabled={disabled}
      />
      {handleError && handleTouched && (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      )}
    </Wrapper>
  );
};

export default InputComponent;
