import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// COMPONENTS START
import ScreenSelection from "../components/ScreenSelection";
import Content from "./Content";
import ComponentsContainer from "./ComponentsContainer";
import Components, {
  IComponent,
} from "panel/src/Components/DragnDrop/Components";
import Emulator from "panel/src/Components/DragnDrop/Emulator";
// COMPONENTS END

//REDUX ACTIONS START
import { resetProductDetailData } from "panel/src/redux/actions/productDetail";
import {
  saveScreenData,
  resetSaveScreenData,
} from "panel/src/redux/actions/SaveScreen";
// REDUX ACTIONS END

//MODELS START
import {
  IColorData,
  ColorList,
} from "../../../models/Design/colors/IGetColorsPageDataResponse";
import IGetProductDetailDataRequest from "panel/src/models/Design/productDetail/IGetProductDetailDataRequest";
import { IProductDetailData } from "panel/src/models/Design/productDetail/IGetProductDetailDataResponse";
import ISaveScreenModel, {
  ISaveScreenComponent,
} from "panel/src/models/SaveScreenModel";
import SelectModel from "panel/src/models/Design/selectModel";
//MODELS END

import { PageType } from "panel/src/utils/enums/pageType";
import { ScreenType } from "panel/src/utils/enums/screenType";
import { ComponentType } from "panel/src/utils/enums/componentType";
import { getComponent } from "./componentHandler";
import { screenNumbers } from "../constants";
import { getDesignData } from "../../../redux/actions/design";
import Modal from "../../../_components/common/Modal";
import UpgradeGuide from "../../../Components/UpgradeGuide";
import classes from "../design.module.scss";
import close from "../../../assets/images/close.svg";
import Button from "../../../_components/common/Button";
import UpgradeModal from "../../../Components/UpgradeModal";
import { usePackage } from "../../../hooks";
interface IProductDetail {
  appId: string;
  platformId: number;
  userId: string;
  productDetailData: IProductDetailData["data"]["ResultData"];
  saveScreenRes: boolean | number;
  _getProductDetailData: Function;
  _resetProductDetailData: Function;
  _saveScreenData: Function;
  _resetSaveScreenData: Function;
  isCompletion: boolean;
  selectedValue: string;
  designScreens: SelectModel[];
  designScreensChange: Function;
  selectChange: Function;
  loading: boolean;
  reset: boolean;
  colors: IColorData;
  fonts: any;
  isOnModalSubmit: boolean;
}

export const requiredEditForms = [
  ComponentType.InstagramShowcase,
  ComponentType.SocialMedia,
  ComponentType.SmallBanner,
  ComponentType.MediumBanner,
  ComponentType.LargeBanner,
  ComponentType.CategorySlider,
];

const ProductDetail = ({
  appId,
  platformId,
  userId,
  productDetailData,
  _getProductDetailData,
  _resetProductDetailData,
  _saveScreenData,
  _resetSaveScreenData,
  saveScreenRes,
  isCompletion,
  selectedValue,
  designScreens,
  designScreensChange,
  selectChange,
  loading,
  reset,
  colors,
  fonts,
  isOnModalSubmit,
}: IProductDetail) => {
  const packageHook = usePackage();

  useEffect(() => {
    getScreenComponentsData();
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [noComponentSelectedError, setNoComponentSelectedError] =
    useState(false);
  const [selectedComponents, setSelectedComponents] = useState<
    (IComponent | undefined)[]
  >([]);
  const [componentsList, setComponentsList] = useState<
    (IComponent | undefined)[]
  >([]);
  const [componentsData, setComponentsData] =
    useState<
      IProductDetailData["data"]["ResultData"]["Components"] | undefined
    >(undefined);
  const [selectedColorList, setSelectedColorList] = useState<ColorList[]>([]);

  const [selectedFont, setSelectedFont] = useState("");
  const [productSliderDimension, setProductSliderDimension] = useState(null);
  const [isUpgradeGuide, setIsUpgradeGuide] = useState<boolean>(false);
  const [isSaveModal, setIsSaveModal] = useState<boolean>(false);
  const [isOpenUpgradeModal, setIsOpenUpgradeModal] = useState<boolean>(false);

  useEffect(() => {
    packageHook.setSelectedComponents({
      productDetail: selectedComponents.length ?? 0,
    });
  }, [selectedComponents]);

  useEffect(() => {
    if (isOnModalSubmit && selectedValue === screenNumbers.productDetail) {
      saveScreenComponents();
    }
  }, [isOnModalSubmit]);

  useEffect(() => {
    if (productDetailData && !loading && !reset) {
      const colorList = colors.selectedColorModel
        ? colors.selectedColorModel.colorList
        : [];
      setSelectedColorList(colorList);

      const selectedFontFamily = fonts.find((opt: any) => opt.IsUsed);
      const selectedFontName = selectedFontFamily
        ? selectedFontFamily.Name
        : "Helvetica Neue";
      setSelectedFont(selectedFontName);

      const componentsData = productDetailData.Components;
      setComponentsData(componentsData);
    }
  }, [productDetailData]);

  useEffect(() => {
    if (componentsData && !reset && !loading) {
      const selectedComponentsList = productDetailData.SelectedComponents.map(
        (obj) =>
          getComponent(
            selectedFont,
            selectedColorList,
            obj.ComponentId,
            obj.ResponseJSON ? JSON.parse(obj.ResponseJSON) : {},
            setProductSliderDimension
          )
      ).filter((obj) => obj !== undefined);

      if (
        packageHook.isPackageFeaturesLimited &&
        packageHook.usedComponents.total > packageHook.packageFeaturesLimited
      ) {
        setIsUpgradeGuide(true);
      }
      setSelectedComponents(selectedComponentsList);

      const componentsList = productDetailData.Components.map((obj) => {
        const tempComponent = getComponent(
          selectedFont,
          selectedColorList,
          obj.ComponentId,
          JSON.parse(obj.ResponseJSON),
          setProductSliderDimension
        );
        if (tempComponent) tempComponent.IsNewBadge = obj.IsNewBadge;
        return tempComponent;
      })
        .filter((obj) => obj !== undefined)
        .filter((obj) => obj!.componentId !== ComponentType.ProductSlider);
      setComponentsList(componentsList);
      setIsLoading(false);
    }
  }, [componentsData]);

  useEffect(() => {
    if (saveScreenRes) {
      setIsLoading(false);
      // _resetProductDetailData();
      _resetSaveScreenData();
    }
  }, [saveScreenRes]);

  useEffect(() => {
    return () => {
      //  _resetProductDetailData();
      _resetSaveScreenData();
    };
  }, []);

  const getScreenComponentsData = () => {
    const productDetailComponentsDataReqObj: IGetProductDetailDataRequest = {
      applicationId: appId,
      userId: userId,
      platformId: platformId,
    };

    _getProductDetailData(productDetailComponentsDataReqObj);
  };

  const saveScreenComponents = () => {
    if (
      packageHook.isPackageFeaturesLimited &&
      packageHook.usedComponents.total > packageHook.packageFeaturesLimited &&
      !isSaveModal
    ) {
      setIsSaveModal(true);
      return;
    }

    setNoComponentSelectedError(false);
    setIsLoading(true);

    const _selectedComponents: ISaveScreenComponent[] = packageHook
      .handleExceptComponents(selectedComponents)
      .map((obj, index) => ({
        ComponentId: obj!.componentId,
        PageId: obj!.pageId,
        DisplayOrder: index + 1,
        JSON: obj!.editForm ? JSON.stringify(obj!.editForm.editFormData) : null,
      }));

    if (!selectedComponents.length) {
      setNoComponentSelectedError(true);
      setIsLoading(false);
      return;
    }

    if (handleEmptyEditForms(_selectedComponents)) {
      setIsLoading(false);
      return;
    }

    const saveScreenDataReqObj: ISaveScreenModel = {
      ApplicationId: appId,
      PageId: PageType.ProductDetail,
      Components: _selectedComponents,
    };
    _saveScreenData(saveScreenDataReqObj);
    if (!isOnModalSubmit && selectedValue === screenNumbers.productDetail) {
      selectChange(ScreenType.Color.toString());
    }
  };

  const handleEmptyEditForms = (
    saveScreenComponents: ISaveScreenComponent[]
  ) => {
    const _saveScreenComponents = saveScreenComponents
      .filter((obj) => obj.JSON === null)
      .filter((obj) => requiredEditForms.includes(obj.ComponentId));

    if (_saveScreenComponents.length) return true;

    return false;
  };

  const componentsListHandle = componentsList.filter(
    (component) => component?.componentId !== ComponentType.ProductPrice
  );

  const handleItemDropped = (e: any, handleOnDrop: Function) => {
    if (
      packageHook.isPackageFeaturesLimited &&
      packageHook.usedComponents.total >= packageHook.packageFeaturesLimited
    ) {
      setIsUpgradeGuide(true);
    }
    if (
      packageHook.isPackageFeaturesLimited &&
      e?.payload?.componentId === ComponentType.CustomHtml
    ) {
      setIsOpenUpgradeModal(true);
    }
    handleOnDrop(e);
  };
  const closeUpgradeModal = () => {
    setIsUpgradeGuide(false);
  };
  const closeSaveModal = () => {
    setIsSaveModal(false);
  };

  return (
    <Content>
      {!isLoading ? (
        <React.Fragment>
          <ComponentsContainer>
            {!isCompletion && (
              <ScreenSelection
                options={designScreens}
                selectedValue={selectedValue}
                selectChangeHandler={designScreensChange}
              />
            )}
            <Components
              infoText={
                "Design your product detail page by dragging and dropping one of the components below"
              }
              errorMessage={
                "You have to select one of these components below for your product detail page."
              }
              components={componentsListHandle}
              hasError={noComponentSelectedError}
              componentTotalCount={packageHook.usedComponents.total}
            />
          </ComponentsContainer>
          <Emulator
            fontFamily={selectedFont}
            screen="Product Details"
            navigateBack={selectChange}
            backTo={ScreenType.ProductList.toString()}
            colorList={selectedColorList}
            appScreen={{
              screen: "Product Details",
              selectedComponents: selectedComponents,
              setSelectedComponents: setSelectedComponents,
              requiredEdit: requiredEditForms,
              itemDropped: handleItemDropped,
            }}
            showAddToCartBar
            productSliderDimension={productSliderDimension}
            saveScreen={saveScreenComponents}
          />
        </React.Fragment>
      ) : null}
      <Modal show={isUpgradeGuide} width="300">
        <UpgradeGuide screen="components" setIsOpenModal={closeUpgradeModal} />
      </Modal>
      <Modal
        //backdropClickedHandler={() => closeSaveModal()}
        show={isSaveModal}
        width="448"
      >
        <div className={classes.Close} onClick={() => closeSaveModal()}>
          <img src={close} />
        </div>
        <div className={classes.ModalBody}>
          <span className={classes.ModalBodyText}>
            The changes you make will not be saved. Do you want to continue?
          </span>
        </div>
        <div className={classes.ModalFooter}>
          <div className={classes.ButtonContainer}>
            <Button
              type="button"
              className="Ghost"
              width="192px"
              onClick={closeSaveModal}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="Primary"
              width="192px"
              onClick={() => {
                setTimeout(() => {
                  saveScreenComponents();
                }, 1);
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <UpgradeModal
        setIsOpenModal={setIsOpenUpgradeModal}
        isOpenModal={isOpenUpgradeModal}
      />
    </Content>
  );
};

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    platformId: state.userInfo.selectedApp.PlatformId,
    userId: state.userInfo.userId,
    productDetailData: state.productDetail.data.productDetails,
    saveScreenRes: state.saveScreen.data,
    loading: state.productDetail.loading,
    reset: state.productDetail.reset,
    colors: state.productDetail.data.colors,
    fonts: state.productDetail.data.fonts,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _getProductDetailData: (reqObject: IGetProductDetailDataRequest) =>
      dispatch(getDesignData(reqObject)),
    _resetProductDetailData: () => dispatch(resetProductDetailData()),
    _saveScreenData: (reqObject: ISaveScreenModel) =>
      dispatch(saveScreenData(reqObject)),
    _resetSaveScreenData: () => dispatch(resetSaveScreenData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
