import React from "react";
import TargetIcon from "panel/src/assets/images/target.svg";
import CloseIcon from "panel/src/assets/images/close.svg";
import classes from "./PricingBanner.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { pricingBannerToggle } from "../../../redux/actions/userInfo";

export default function PricingBanner() {
  const dispatch = useDispatch();
  const { userInfo }: any = useSelector((state) => state);

  const onClose = () => {
    dispatch(pricingBannerToggle());
  };

  if (!(userInfo.isShowPricingBanner && userInfo.IsTrial)) return null;
  return (
    <div className={classes.alert}>
      <div className={classes.alertHead}>
        <a
          href="https://mowico.com/pricing/"
          target="_blank"
          className={classes.alertTitleButton}
        >
          Upgrade now!
        </a>
        <img className={classes.alertIcon} src={TargetIcon} alt="upgrade-now" />
        <button className={classes.alertClose} onClick={onClose}>
          <img
            className={classes.alertIcon}
            src={CloseIcon}
            alt="upgrade-now-close"
          />
        </button>
      </div>
      <div className={classes.alertBody}>
        <p className={classes.alertDescription}>
          <b>
            Your free trial will end in {userInfo?.RemainingDays}
            {userInfo?.RemainingDays > 1 ? " days!" : " day!"}
          </b>{" "}
          See our
          <a
            href="https://mowico.com/pricing/"
            target="_blank"
            className={classes.pricingLink}
          >
            {" "}
            plans & pricing page
          </a>{" "}
          to find out what suits you the best.
        </p>
      </div>
    </div>
  );
}
