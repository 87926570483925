export enum socialMediaEnum {
  Facebook = 1,
  Instagram,
  Pinterest,
  TwitterX,
  Swarm,
  Tumblr,
  Youtube,
  Tiktok,
}

export const SocialMedias: {
  [key in keyof typeof socialMediaEnum]?: string;
} = {
  [socialMediaEnum.Facebook]: require('./SocialMediaIcons/PNG/facebook.png'),
  [socialMediaEnum.Instagram]: require('./SocialMediaIcons/PNG/instagram.png'),
  [socialMediaEnum.Pinterest]: require('./SocialMediaIcons/PNG/pinterest.png'),
  [socialMediaEnum.TwitterX]: require('./SocialMediaIcons/PNG/twitter-x.png'),
  [socialMediaEnum.Swarm]: require('./SocialMediaIcons/PNG/swarm.png'),
  [socialMediaEnum.Tumblr]: require('./SocialMediaIcons/PNG/tumblr.png'),
  [socialMediaEnum.Youtube]: require('./SocialMediaIcons/PNG/youtube.png'),
  [socialMediaEnum.Tiktok]: require('./SocialMediaIcons/PNG/tiktok.png'),
};
