import IAskDemoRequest from "../../models/Publish/askDemo";
import IResendDemoRequest from "../../models/Publish/resendDemo";

import {
  ASK_DEMO,
  ASK_DEMO_FAIL,
  ASK_DEMO_SUCCESS,
  RESET_ASK_DEMO,
} from "panel/src/redux/constants/askDemo";

interface BaseAction {
  type: string;
  data?: Object;
  error?: string;
  requestObj?: IAskDemoRequest;
  resendRequestObj?: IResendDemoRequest;
}

export const askDemoLink = (requestObj: IAskDemoRequest): BaseAction => ({
  type: ASK_DEMO,
  requestObj,
});

export const askDemoLinkSuccess = (data: Object): BaseAction => ({
  type: ASK_DEMO_SUCCESS,
  data,
});

export const askDemoLinkFail = (error: string): BaseAction => ({
  type: ASK_DEMO_FAIL,
  error,
});
export const resetAskDemo = () => ({
  type: RESET_ASK_DEMO,
});
