import React, { ChangeEventHandler, useEffect, useRef, useState } from "react";
import Spinner from "../../_components/common/Spinner";
import classes from "./dropdown.module.scss";
import FormikError from "../FormikError";
import {
  Description,
  DropdownContainer,
  DropdownInput,
  DropdownItem,
  DropdownMenu,
  ErrorMessage,
  DropdownIcon,
  Input,
  Label,
} from "./Dropdown.styles";
import Icon from "../../_components/common/Icon";
import UpgradeTooltip from "../UpgradeTooltip";
import {useSelector} from "react-redux";

interface IDropdown {
  id: string;
  style: object;
  label: string;
  placeholder: string;
  options: { label: string; value: number, isPackageSupported?:boolean }[];
  value: number | string;
  onChangeHandler: Function;
  handleError?: any;
  handleTouched?: any;
  loading?: boolean;
  disabled?: boolean;
  searchable?: boolean;
  description?: string;
  positionValue?: boolean;
  filterable?: boolean;
  onSearchValueChange?: ChangeEventHandler<HTMLInputElement>;
  isPackageSupportControl?: boolean;
}

let timeout: ReturnType<typeof setTimeout> = setTimeout(() => "", 1000);

const Dropdown = ({
  id,
  style,
  label,
  placeholder,
  options,
  value,
  onChangeHandler,
  handleError,
  handleTouched,
  description,
  disabled,
  searchable = false,
  loading = false,
  positionValue = false,
  onSearchValueChange,
  filterable = false,
  isPackageSupportControl=false
}: IDropdown) => {
  const selectNode: any = useRef();
  const errorMessage = FormikError(handleError, handleTouched, id);
  const [isOpen, setIsOpen] = useState(false);
  const [searchWord, setSearchWord] = useState(searchable ? value : "");
  const [filteredKey, setFilteredKey] = useState("");
  const [isWriting, setIsWriting] = useState(false);
  const [filteredOptions, setFilteredOptions] =useState<IDropdown['options']>([{ label: "", value: 0, isPackageSupported: false }]);
  const { userInfo }: any = useSelector((state) => state);

  useEffect(() => {
    document.addEventListener("click", clickOutside);

    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);

  const toggling = () => {
    if (!disabled) {
      if (!searchable || (searchable && searchWord !== "")) {
        setIsOpen(!isOpen);
      }
    }
  };

  const onItemClick = (item: { label: string; value: number }) => {
    setSearchWord(item.label);
    onChangeHandler({
      target: { name: id, value: item.value, label: item.label },
    });
    setIsOpen(false);
    setFilteredKey('');
  };

  const clickOutside = (e: any) => {
    if (selectNode.current && !selectNode.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const renderSelectedOption = () => {
    if (value === "" || value === null || value === undefined)
      return placeholder;

    const option = options.find((obj) => obj.value === value);
    let optionValue = option ? option!.label : placeholder;

    // setSearchWord(optionValue);

    return optionValue;
  };

  const _onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
    onSearchValueChange?.(e);
    setIsOpen(true);
  };

  const _onFilterValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredKey(e.target.value);
    if (e.target.value !== "") {
      const filteredData = options.filter((item) => {
        return (
            Object.values(item.label)
                .join("")
                .toLowerCase()
                .indexOf(e.target.value) > -1
        );
      });
      setFilteredOptions(filteredData);
    } else {
      setFilteredOptions(options);
    }
  };

  const renderInput = () => {
    if (searchable)
      return (
        <Input
          disabled={disabled}
          placeholder={renderSelectedOption()}
          type="text"
          value={searchWord}
          onChange={_onSearchValueChange}
          onKeyDown={() => setIsWriting(true)}
          onKeyUp={() => {
            clearTimeout(timeout);
            timeout = setTimeout(() => setIsWriting(false), 500);
          }}
        />
      );

    return (
      <>
        {renderSelectedOption()}
        <DropdownIcon
          isOpen={isOpen}
          type="arrowDown"
          fillColor="#3e5e6e"
          width="16"
          height="16"
        />
      </>
    );
  };

  return (
    <DropdownContainer style={style} ref={selectNode}>
      <Label htmlFor={id}>{label}</Label>
      {description && <Description>{description}</Description>}
      <DropdownInput
        id={id}
        onClick={() => toggling()}
        isSelected={value !== ""}
        hasError={errorMessage ? true : false}
        searchable={searchable}
      >
        {renderInput()}
      </DropdownInput>
      {isOpen && (
        <DropdownMenu position={positionValue} isSearching={isWriting || loading}>
          {searchable && (loading || isWriting) && <Spinner.Small />}
          {filterable &&
            <div className={classes.searchContainer}>
              <div className={classes.search}>
                <Input
                    id=""
                    placeholder={"Search"}
                    value={filteredKey}
                    onChange={_onFilterValueChange}
                    onKeyDown={() => setIsWriting(true)}
                    onKeyUp={() => {
                      clearTimeout(timeout);
                      timeout = setTimeout(() => setIsWriting(false), 500);
                    }}
                />
                <div className={classes.searchIcon}>
                  <Icon type="search" width="16" height="16" fillColor="#0f3649" />
                </div>
              </div>
            </div>
          }
          {(filteredKey ? filteredOptions : options).map((item, index) => (
              userInfo?.UserPackage?.IsUpgradePossible && isPackageSupportControl ?
                  <div className={classes.itemContainer}>
                    <DropdownItem
                        isPackageSupported={item.isPackageSupported}
                        className={`${!item.isPackageSupported && classes.Disabled}`}
                        key={index.toString()}
                        onClick={() => item.isPackageSupported && onItemClick(item)}
                    >
                      {item.label}
                    </DropdownItem>
                    {!item.isPackageSupported && <UpgradeTooltip label={item.label}/>}
                  </div>
                  :
                  <DropdownItem
                      key={index.toString()}
                      onClick={() => onItemClick(item)}
                  >
                    {item.label}
                  </DropdownItem>
          ))}
        </DropdownMenu>
      )}
      {handleError && handleTouched && (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
