import * as Yup from "yup";
import { urlPattern } from "panel/src/utils/regex";
import { ELinkTypes } from "../../../../../utils/enums/design";

const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "jpg",
  "jpeg",
  "png",
];
const SUPPORTED_WIDTH = 960;

export default Yup.object().shape({
  Image: Yup.object().shape({
    ImgUrl: Yup.mixed()
      .required("Image can't be blank.")
      .test(
        "Dummy File",
        "Image can't be blank.",
        (value) => value && value.img?.match(/images\/dummy/gm) === null
      )
      // .test(
      //   "Max Length",
      //   "Unable to convert image to mobile format",
      //   (value) => value && value.img?.length <= 100000
      // )
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      )
      .test(
        "fileHeight",
        (params) =>
          `The image dimensions cannot be smaller than ${SUPPORTED_WIDTH}x${params.value.supportedHeight} px`,
        (value) => value && value.height >= value.supportedHeight
      )
      .test(
        "fileWidth",
        (params) =>
          `The image dimensions cannot be smaller than ${SUPPORTED_WIDTH}x${params.value.supportedHeight} px`,
        (value) => value && value.width >= SUPPORTED_WIDTH
      ),
  }),
  Link: Yup.object().shape({
    Type: Yup.number().required("Link type can't be blank."),
    LinkTo: Yup.string().when("Type", {
      is: (val) => val === ELinkTypes.WebViewURL || val === ELinkTypes.Href,
      then: Yup.string()
        .required("URL can't be blank.")
        .url("Please enter a valid URL")
        .matches(urlPattern, "Please enter a valid URL"),
    }),
    Search: Yup.string().when("Type", {
      is: (val) => val === ELinkTypes.Search,
      then: Yup.string()
        .required("Search can't be blank.")
        .min(2, "Search word must be at least 3 characters"),
    }),
    CategoryId: Yup.string().when("Type", {
      is: (val) => [ELinkTypes.Category, ELinkTypes.Product].includes(val),
      then: Yup.string().required("Category can't be blank."),
    }),
    CategoryName: Yup.string().when("Type", {
      is: (val) => val === ELinkTypes.Category,
      then: Yup.string().required("Category can't be blank."),
    }),
    ProductId: Yup.string().when("Type", {
      is: (val) => val === ELinkTypes.Product,
      then: Yup.string().required("Please enter a valid product"),
    }),
    ProductName: Yup.string().when("Type", {
      is: (val) => val === ELinkTypes.Product,
      then: Yup.string().required(
        "Please enter a valid product. You must select a product from the list."
      ),
    }),
  }),
});
