import React from "react";
import LeftBackground from "./LeftBackground";
import MowicoLogo from "./MowicoLogo";
import Container from "./Container";
import RightPaneContainer from "./RightPaneContainer";
import ErrorText from "./ErrorText";
import Header from "./Header";
import Description from "./Description";
import Button from "./Button";
import Label from "./Label";
import Buttons from "./Buttons";
import Logout from "../../assets/images/icons-dark-gray-logout.svg";
import LogoutButton from "./LogoutButton";
import { useDispatch } from "react-redux";
import { logoutStart } from "../../redux/actions/logout";

const TrialExpired = () => {
  const dispatch = useDispatch();

  const onLogoutClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch(logoutStart());
  };

  return (
    <Container>
      <LeftBackground />
      <MowicoLogo />
      <RightPaneContainer>
        <ErrorText>Your Account Has Been Suspended</ErrorText>
        <Header>Please check your payment methods.</Header>
        <Description>
          Your Mowico Wizard account is suspended & your mobile application is
          unavailable due to payment issues. Please contact us via email at
          <a href="mailto:support@mowico.com" style={{ color: "#3196f6" }}>
            {" "}
            support@mowico.com
          </a>
        </Description>
        <Buttons>
          <Button isPrimary href="https://meetings.hubspot.com/kardelen">
            <Label isPrimary>Let's chat</Label>
          </Button>
          <Button href="https://mowico.com/pricing/" target="_blank">
            <Label>Plans and Pricing</Label>
          </Button>
        </Buttons>
        <LogoutButton onClick={onLogoutClick} target="_blank">
          <img src={Logout} alt="Mowico" />
          <Label>Log out of account</Label>
        </LogoutButton>
      </RightPaneContainer>
    </Container>
  );
};

export default TrialExpired;
