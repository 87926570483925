import {
  SET_PAYMENT_OPTIONS_START,
  SET_PAYMENT_OPTIONS_SUCCESS,
  SET_PAYMENT_OPTIONS_FAIL,
} from "panel/src/redux/constants/setPaymentOptions";


export type setPaymentOptionsStateTypes = Readonly<{
  error?: boolean;
  loading?: boolean;
}>;

const initialState: setPaymentOptionsStateTypes = {
  error: false,
  loading: false,
}


const setPaymentOptionsReducer = ( state = initialState, action: any ) => {
  switch ( action.type ) {
    case SET_PAYMENT_OPTIONS_START:
      return {
        ...state,
        data: action.data
      };

    case SET_PAYMENT_OPTIONS_SUCCESS:
      return {
        ...state,
      };
    case SET_PAYMENT_OPTIONS_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default setPaymentOptionsReducer;
