import {
  GET_NAVIGATION_TYPE_START,
  GET_NAVIGATION_TYPE_FINISH,
  GET_NAVIGATION_TYPE_REJECTED,
  SET_NAVIGATION_TYPE_START,
  SET_NAVIGATION_TYPE_FINISH,
  SET_NAVIGATION_TYPE_REJECTED,
  ADD_NAVIGATION_TYPE_ICON,
} from "panel/src/redux/constants/navigationType";

const initialState = {
  navigationType: {
    NavigationBarIconList: [],
  },
};

const navigationTypeReducer = (state: any = initialState, action: any) => {

  switch (action.type) {
    case ADD_NAVIGATION_TYPE_ICON:
      return {
        ...state,
        navigationType: {
          ...state.navigationType,
          NavigationBarIconList: [
            ...state.navigationType.NavigationBarIconList,
            action.payload,
          ]
        }
      }
    case GET_NAVIGATION_TYPE_START:
      return {
        ...state,
        navigationTypesLoading: true,
      };

    case GET_NAVIGATION_TYPE_FINISH:
      return {
        ...state,
        navigationType: action.navigationType,
        navigationTypesLoading: false,
      };
    case GET_NAVIGATION_TYPE_REJECTED:
      return {
        ...state,
        error: action.error,
      };

    case SET_NAVIGATION_TYPE_START:
      return {
        ...state,
      };

    case SET_NAVIGATION_TYPE_FINISH:
      return {
        ...state,
      };
    case SET_NAVIGATION_TYPE_REJECTED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default navigationTypeReducer;
