import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { store, history } from "./redux/store";
import { Provider } from "react-redux";
import "./index.scss";
import { ConnectedRouter } from "connected-react-router";
import { IntercomProvider } from 'react-use-intercom';


document.addEventListener(
  "invalid",
  (function () {
    return function (e: { preventDefault: () => void }) {
      e.preventDefault();
    };
  })(),
  true
);

const INTERCOM_APP_ID = 'mcn8n34k';

const app = (
  <Provider store={store}>
    <IntercomProvider
      appId={INTERCOM_APP_ID}
    >
      <BrowserRouter>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </BrowserRouter>
    </IntercomProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
