import React from "react";
import Popup from "reactjs-popup";
import {
  InfoContainer,
  InfoIcon,
  ToolTipContainer,
  ToolTipText,
} from "../Tooltip/Tooltip.styles";
import Icon from "../../_components/common/Icon";
import { PopupPosition } from "reactjs-popup/dist/types";

interface IInfoPopupProps {
  position?: PopupPosition | PopupPosition[];
  height?: string;
  descriptions: { key: string; value: string }[];
  field: any;
  style?: React.CSSProperties;
}

export default function InfoPopup({
  descriptions,
  position = "bottom right",
  height = "250",
  field,
  style,
}: IInfoPopupProps) {
  return (
    <InfoContainer style={style}>
      {field}
      <Popup
        trigger={
          <InfoIcon>
            <Icon type="info" fillColor="#3e5e6e" width="16px" height="16px" />
          </InfoIcon>
        }
        position={position}
        on={["hover", "focus"]}
        arrow={true}
        arrowStyle={{
          color: "#3e5e6e",
          right: position === "bottom right" ? 2 : -4,
          bottom: position === "bottom right" ? 0 : "initial",
        }}
      >
        <ToolTipContainer height={height}>
          <ToolTipText>
            {descriptions?.map((description, index) => (
              <p key={index}>
                <b>{description.key}:</b>
                {description.value}
              </p>
            ))}
          </ToolTipText>
        </ToolTipContainer>
      </Popup>
    </InfoContainer>
  );
}
