import React from "react";
import classes from "./warningModal.module.scss";
import Button from "../../../_components/common/Button";
import Modal from "../../../_components/common/Modal";
import close from "panel/src/assets/images/close.svg";
import warning from "panel/src/assets/images/warning.svg";

interface IState {
  isVisible: boolean;
  setIsVisible: () => void;
  text: string;
}

const WarningModal = ({ isVisible, setIsVisible, text }: IState) => {
  return (
    <Modal show={isVisible} backdropClickedHandler={setIsVisible} width="500">
      <div className={classes.Close} onClick={setIsVisible}>
        <img src={close} />
      </div>
      <img
        className={classes.ConfirmIcon}
        src={warning}
        width={48}
        height={48}
      />
      <div className={classes.Container}>
        <p className={classes.Description}>{text}</p>
        <Button
          type="button"
          className="Primary"
          width="192px"
          onClick={setIsVisible}
          marginTop="40px"
        >
          Okay
        </Button>
      </div>
    </Modal>
  );
};

export default WarningModal;
