import React from "react";
import classes from "./sideMenu.module.scss";
import collapseIcon from "../../../assets/images/sidemenu-collapse-icon.svg";
import expandIcon from "../../../assets/images/sidemenu-expand-icon.svg";
import MainNavigation from "../MainNavigation";
import BottomNavigation from "../BottomNavigation";
import Logo from "../Logo";
import Dropdown from "../../common/Dropdown";

interface ISideMenuProps {
  isSideMenuFull: boolean;
  sideMenuToggleHandler: () => void;
  appsOptions: any;
  appsSelectValue: any;
  logoClickHandler: Function;
  createAppHandler: Function;
  renameAppHandler: Function;
  deleteAppHandler: Function;
  logoutClickHandler: Function;
}

const SideMenu = (props: ISideMenuProps) => {
  const toggleIcon = props.isSideMenuFull ? collapseIcon : expandIcon;

  const attachedClasses = [classes.SideMenu];
  if (props.isSideMenuFull) {
    attachedClasses.push(classes.Full);
  } else {
    attachedClasses.push(classes.Narrow);
  }

  const myAppsText = props.isSideMenuFull ? (
    <p className={classes.MyApplicationsText}>My Projects</p>
  ) : null;

  const logoClickHandler = () => {
    props.logoClickHandler();
  };

  const showApp =
    props.appsOptions && props.appsOptions.length > 0 ? true : false;
  return (
    <header className={attachedClasses.join(" ")}>
      <div className={classes.topMenu}>
        <img
          src={toggleIcon}
          className={classes.ToggleIcon}
          alt="menu-toggle"
          onClick={props.sideMenuToggleHandler}
        />
        <div onClick={logoClickHandler}>
          <Logo isFullLogo={props.isSideMenuFull} />
        </div>
        <div className={!showApp ? classes.hide : ""}>
          {myAppsText}

          {props.isSideMenuFull && (
            <Dropdown
              options={props.appsOptions}
              placeholder="Choose..."
              value={props.appsSelectValue}
              createAppHandler={props.createAppHandler}
              renameAppHandler={props.renameAppHandler}
              deleteAppHandler={props.deleteAppHandler}
            />
          )}
        </div>
        <MainNavigation isSideMenuFull={props.isSideMenuFull} />
      </div>

      <BottomNavigation
        isSideMenuFull={props.isSideMenuFull}
        logoutClickHandler={props.logoutClickHandler}
      />
    </header>
  );
};

export default SideMenu;
