import React from "react";
import { Platform } from "react-native";
import { dimenHeight, dimenWidth } from "shared-components/src/utils/helper";
import { colors } from "shared-components/src/utils/constants";
import HeaderContainer from "./HeaderContainer";
import Header from "./Header";
import Card from "./Card";
import Content from "./Content";
import ImageWrapper from "./ImageWrapper";
import Image from "./Image";
import CategoryName from "./CategoryName";
import ArrowRightSmall from "../../icons/ArrowRightSmall";
import { ColorListData } from "shared-components/src/models/colorListData";

interface ISmallCategoryView {
  title?: string;
  categories: {
    Id: string;
    Name: string;
    IconUrl: string;
  }[];
  handleHeaderClick?: Function;
  colorList?: ColorListData[];
  fontFamily?: any;
  navigate?: (route: string, params: Record<string, any>) => void;
}

const SmallCategoryView = ({
  title,
  categories,
  handleHeaderClick,
  colorList,
  fontFamily,
  navigate,
}: ISmallCategoryView) => {
  return (
    <Card>
      <HeaderContainer onPress={() => handleHeaderClick && handleHeaderClick()}>
        <Header
          fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
          primary={colorList ? colorList[0].colorCode : colors.darkSlateBlue}
        >
          {title ? title : "Categories"}
        </Header>
        <ArrowRightSmall
          fillColor={colorList ? colorList[0].colorCode : colors.darkSlateBlue}
          height={Platform.OS === "web" ? "11" : dimenHeight(16).toString()}
          width={Platform.OS === "web" ? "11" : dimenWidth(16).toString()}
        />
      </HeaderContainer>

      <Content contentContainerStyle={{ justifyContent: "flex-start" }}>
        {categories.map((item, index) => {
          let categoryNameTemp = item?.Name;
          let categoryNameTempWordList = categoryNameTemp?.trim()?.split(" ");

          const tempText = categoryNameTempWordList.reduce(
            (temp, value, index) => {
              if (index > 1) return temp;
              if (value?.length > 8) temp += value.substring(0, 6) + "... ";
              else temp += value + " ";
              return temp;
            },
            ""
          );

          return (
            <ImageWrapper
              onPress={() =>
                navigate && navigate('ProductList', {
                  categoryId: item.Id,
                  title: item.Name,
                })
              }
              key={index.toString()}
            >
              <Image
                source={{
                  uri: item.IconUrl,
                }}
              />
              <CategoryName
                fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                primary={
                  colorList ? colorList[0].colorCode : colors.darkSlateBlue
                }
              >
                {tempText?.trim()}
              </CategoryName>
            </ImageWrapper>
          );
        })}
      </Content>
    </Card>
  );
};

export default SmallCategoryView;
