import { put, call, takeLatest, select } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import { SEND_QR_MAIL } from "../constants/qr";
import { AxiosResponse } from "axios";
import { QrModule } from "../../models/Publish/qr";

function* onSendQrMail(action: { payload: QrModule.QrMail; type: string }) {
  yield put(startAppLoading(SEND_QR_MAIL));
  try {
    const { selectedAppId } = yield select((state) => state.userInfo);
    const { data }: AxiosResponse<string> = yield call(
      axios.post,
      "api/Manage/QRCodeMailSend",
      {
        SendMailAddreses: action.payload.EMailAddreses,
        ApplicationId: selectedAppId,
      }
    );

    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push(["email_request_for_demo_app", action.payload.EMailAddreses]);
  } catch (error) {}
  yield put(endAppLoading(SEND_QR_MAIL));
}

export function* qrSaga() {
  yield takeLatest(SEND_QR_MAIL, onSendQrMail);
}
