import SelectModel from "../models/Design/selectModel";
import GeneralSettingsFormModel from "../models/Design/generalSettings/generalSettingsFormModel";
import { DeviceModel } from "../models/Design/deviceModel";
export const designScreens: SelectModel[] = [
  {
    value: "1",
    displayValue: "General Settings",
    isSelected: true,
    isComplated: false,
  },
  {
    value: "2",
    displayValue: "Splash Screen",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "3",
    displayValue: "Navigation Type",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "4",
    displayValue: "Category Layout",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "5",
    displayValue: "Home",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "6",
    displayValue: "Product List",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "7",
    displayValue: "Product Detail",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "8",
    displayValue: "Color",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "9",
    displayValue: "Font Type",
    isSelected: false,
    isComplated: false,
  },
];

export const generalSettingsForm: GeneralSettingsFormModel = {
  appname: {
    label: "App Name",
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Mowico App",
    },
    value: "",
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
  },
};

export const languages: SelectModel[] = [
  {
    value: "1",
    displayValue: "English",
    isSelected: true,
    isComplated: false,
  },
  {
    value: "2",
    displayValue: "Italian",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "3",
    displayValue: "Spanish",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "4",
    displayValue: "French",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "5",
    displayValue: "Germen",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "6",
    displayValue: "Turkish",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "7",
    displayValue: "Portuguese",
    isSelected: false,
    isComplated: false,
  },
];

export const splashBackgroundOptions: SelectModel[] = [
  {
    value: "1",
    displayValue: "None",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "2",
    displayValue: "Colour",
    isSelected: true,
    isComplated: false,
  },
  {
    value: "3",
    displayValue: "Linear Gradient",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "4",
    displayValue: "Radial Gradient",
    isSelected: false,
    isComplated: false,
  },
];

export const navigationOptions: SelectModel[] = [
  {
    value: "1",
    displayValue: "Left Menu",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "2",
    displayValue: "Tab Bar",
    isSelected: false,
    isComplated: false,
  },
];

export const themeOptions: SelectModel[] = [
  {
    value: "1",
    displayValue: "Theme 1",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "2",
    displayValue: "Theme 2",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "3",
    displayValue: "Theme 3",
    isSelected: false,
    isComplated: false,
  },
  {
    value: "4",
    displayValue: "Theme 4",
    isSelected: false,
    isComplated: false,
  },
];

export const devices: DeviceModel[] = [
  { index: "1", name: "ios", label: "İOS", type: "active" },
  { index: "2", name: "android", label: "Android", type: "disabled" },
];

export const screenTabs: any[] = [
  { index: 5, name: "Home", label: "Home" },
  { index: 6, name: "ProductList", label: "Product List" },
  { index: 7, name: "ProductDetails", label: "Product Details" },
];

export const installToStoreTime = "1000"; //30:00
export const infoCardInterval = 7000;

export const platformList = {
  36: "Mowico Demo Commerce Platform",
  23: "Shopify",
  27: "Ideasoft",
  2: "Magento",
  21: "Prestashop",
  7: "BigCommerce",
  3: "nopCommerce",
  16: "OpenCart",
  5: "3dCart",
  30: "WooCommerce",
  33: "Salesforce Commerce Cloud",
  4: "Custom",
};

export const routeNames: any = {
  "/": "Home",
  "/login": "Login",
  "/forgotpassword": "Forgot Password",
  "/forgotpasswordlink": "Resend Link",
  "/newpassword": "New Password",
  "/instagram-success": "Instagram Success",
  "/welcome": "Welcome",
  "/manage": "Connect",
  "/manage/web-service-control/begin": "Connect - Web Service Control Begin",
  "/manage/web-service-control/verify": "Connect - Web Service Control Verify",
  "/manage/config-settings": "Connect - Config Settings",
  "/manage/payment-options": "Connect - Payment Options",
  "/manage/engagement-options": "Connect - Engagement Options ",
  "/manage/completion": "Connect - Completion",
  "/publish": "Publish",
  "/publish/ask-demo-link": "Publish - Ask Demo Link",
  "/publish/install-to-store": "Publish - Install to Store",
  "/design": "Design",
  "/engage": "Engage",
  "/engage/banner-settings": "Engage - Banner Settings",
  "/engage/config-settings": "Engage - Config Settings",
  "/engage/content-settings/edit-content":
    "Engage - Content Settings - Edit Content",
  "/engage/content-settings/create-new-language":
    "Engage - Content Settings - Create New Language",
  "/watch-tutorials": "Watch Tutorials",
  "/design/general-settings": "Design - General Settings",
  "/design/splash-screen": "Design - Splash Screen ",
  "/design/navigation-type": "Design - Navigation Type",
  "/design/home": "Design - Home",
  "/design/product-list": "Design - Product List",
  "/design/product-detail": "Design - Product Detail",
  "/design/color": "Design - Color",
  "/design/font-type": "Design - Font Type",
  "/design/design-completed": "Design - Design Completed",
};

export const LiveChatWidgetLicense = "14376798";
