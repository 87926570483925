import styled from "styled-components";

export default styled.div`
  margin-top: 12px;
  display: flex;
  width: 224px;
  height: 64px;
  background-color: #4da8ff;
  align-items: center;
  justify-content: center;
`;
