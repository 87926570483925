import * as Yup from "yup";

export default Yup.object().shape({
  ComponentTitle: Yup.mixed()
    .test(
      "ComponentTitle",
      "Component title can not be blank.",
      (value) => value && value !== null
    )
    .required("Component title can not be blank."),
  Items: Yup.array().of(
    Yup.object().shape({
      CategoryId: Yup.string().required("Component title can not be blank."),
    })
  ),
});
