import styled from "styled-components/native";
import { colors } from "shared-components/src/utils/constants";
import { Platform } from "react-native";

export default styled.View`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  padding: 7px 0;
  ${Platform.OS === "web" ? `flex-shrink: initial !important;` : `flex: 1;`}
`;
