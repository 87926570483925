import { Platform } from "react-native";
import styled from "styled-components/native";
import { dimenHeight } from "shared-components/src/utils/helper";
import { colors } from "shared-components/src/utils/constants";
import FastImage from "react-native-fast-image-web-support";

export default styled(FastImage).attrs({ resizeMode: "cover" })`
  height: ${Platform.OS === "web" ? "36" : dimenHeight(55)}px;
  width: ${Platform.OS === "web" ? "36" : dimenHeight(55)}px;
  border-radius: ${Platform.OS === "web" ? "18" : dimenHeight(32)}px;
  border: 1px solid ${colors.veryLightBlue};
`;
