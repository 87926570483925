import { put, call, takeEvery } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import {
  setInstagramTokenSuccess,
  setInstagramTokenFail,
} from "panel/src/redux/actions/setInstagramToken";
import * as pageActions from "panel/src/redux/actions/page";
import { SET_INSTAGRAM_TOKEN } from "panel/src/redux/constants/setInstagramToken";
import { push } from "connected-react-router";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";

function* setInstagramToken(payload: {
  code: string;
  appId: string;
  type: string;
}) {
  yield put(startAppLoading(SET_INSTAGRAM_TOKEN));
  try {
    const response = yield call(axios.post, "/api/Manage/SetInstagramToken", {
      ApplicationId: payload.appId,
      Token: payload.code,
    });

    yield put(setInstagramTokenSuccess(response.data));
    yield put(pageActions.pageUpdated(true));
    yield put(push("/welcome"));
    yield localStorage.setItem("INSTAGRAM_UPDATED", "true");
  } catch (error) {
    yield put(push("/welcome"));
    yield localStorage.setItem("INSTAGRAM_UPDATED", "true");
    setInstagramTokenFail(error);
  }
  yield put(endAppLoading(SET_INSTAGRAM_TOKEN));
}

export function* setInstagramTokenSaga() {
  yield takeEvery(SET_INSTAGRAM_TOKEN, setInstagramToken);
}
