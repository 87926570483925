import React, { FC, Dispatch, SetStateAction } from "react";
import Modal from "./Modal";
import Container from "./Container";
import RowContainer from "./RowContainer";
import ReactDOM from "react-dom";
import CloseIcon from "./CloseIcon";
import closeIcon from "panel/src/assets/images/close.svg";

interface IYoutubeTutorialModal {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  youtubeVideUrl: string;
}

const YoutubeTutorialModal: FC<IYoutubeTutorialModal> = ({
  isModalOpen,
  setIsModalOpen,
  youtubeVideUrl,
}) => {
  const closeModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  return isModalOpen
    ? ReactDOM.createPortal(
        <Container>
          <Modal onClick={(e) => e.stopPropagation()}>
            <RowContainer>
              <CloseIcon onClick={closeModal} src={closeIcon} />
            </RowContainer>
            <iframe
              src={youtubeVideUrl}
              height="100%"
              width="100%"
              frameBorder="0"
            ></iframe>
          </Modal>
        </Container>,
        document.getElementById("root") as Element
      )
    : null;
};

export default YoutubeTutorialModal;
