import React, { ChangeEventHandler } from "react";
import classes from "./radiobutton.module.scss";

interface IRadioButtonProps {
  checked: boolean;
  changeHandler?: ChangeEventHandler;
  label: string;
  name?: string;
  disabled?: boolean;
}

const RadioButton = (props: IRadioButtonProps) => {
  return (
    <div className={classes.MainContainer}>
      <label
        className={
          props.disabled ? classes.DisabledContainer : classes.Container
        }
      >
        <input
          type="radio"
          name={props.name}
          checked={props.checked}
          onChange={props.changeHandler}
          disabled={props.disabled}
        />
        <span
          className={
            props.disabled ? classes.DisabledCheckmark : classes.Checkmark
          }
        />
      </label>
      <span className={props.disabled ? classes.DisabledLabel : classes.Label}>
        {props.label}
      </span>
    </div>
  );
};

export default RadioButton;
