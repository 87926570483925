import Button from "panel/src/_components/common/Button";
import React, { FC, useState } from "react";
import classes from "./contactContainer.module.scss";
import ContactModal from "panel/src/Components/ContactModal";
import { useDispatch } from "react-redux";
import { showChat } from "../../../redux/actions/liveChat";

interface IContactContainer {
  isInList?: boolean;
  screen: CONTACT_SCREENS;
}

export enum CONTACT_SCREENS {
  CONFIG,
  DESIGN,
}

const CONTACT_TITLES = {
  0: "Add new features",
  1: "Customize your design",
};

const CONTACT_DESC = {
  0: "Would you like to have more features on your app? Contact us for any feature/tool integration.",
  1: "Have a design idea other than the component library we provide? Contact us and let us realize the design you have in mind.",
};

const ContactContainer: FC<IContactContainer> = ({ isInList, screen }) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onOpenChat = () => {
    dispatch(showChat());
  };

  return (
    <div
      style={{
        width: isInList ? "100%" : undefined,
        height: isInList ? "auto" : undefined,
      }}
      id={classes.contactContainer}
    >
      <ContactModal setIsOpenModal={setIsOpenModal} isOpenModal={isOpenModal} />
      <div className={classes.contactContainer}>
        <div className={classes.topRow}>
          <div className={classes.newContainer}>
            <p>NEW!</p>
          </div>
          <p className={classes.emoji}>🥳</p>
        </div>
        <p className={classes.title}>{CONTACT_TITLES[screen]}</p>
        <p className={classes.desc}>{CONTACT_DESC[screen]}</p>
        <Button
          onClick={onOpenChat}
          backgroundColor="transparent"
          borderColor="#3e5e6e"
          color="Ghost"
          width={"100%"}
          height="40px"
        >
          <p className={classes.btnText}>Contact Us</p>
        </Button>
      </div>
    </div>
  );
};

export default ContactContainer;
