import styled from "styled-components";

enum backgroundTypeEnum {
  none = "1",
  classic = "2",
  linearGradient = "3",
  radialGradient = "4",
}

export interface ColorSets {
  backgroundColor?: string;
  linearColorStart?: string;
  linearColorEnd?: string;
  radialColorStart?: string;
  radialColorEnd?: string;
}

interface IBackground {
  backgroundType: string;
  colorSets: ColorSets;
}

export default styled.div<IBackground>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  ${(props) =>
    props.backgroundType === backgroundTypeEnum.classic &&
    `background:${props.colorSets.backgroundColor}`}
  ${(props) =>
    props.backgroundType === backgroundTypeEnum.linearGradient &&
    `background: linear-gradient(60deg, ${props.colorSets.linearColorStart}, ${props.colorSets.linearColorEnd})`}
  ${(props) =>
    props.backgroundType === backgroundTypeEnum.radialGradient &&
    `background: radial-gradient(circle,${props.colorSets.radialColorStart}, ${props.colorSets.radialColorEnd})`}
`;
