import React from "react";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
  type: string;
}

const Info = ({ width, height, fillColor }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd">
      <g fill={fillColor}>
        <g>
          <g>
            <path
              d="M8 0C3.583 0 0 3.579 0 7.996c0 4.422 3.583 8.005 8 8.005 4.42 0 8-3.583 8-8.005C16 3.58 12.42 0 8 0zm.57 12.448c-.185.165-.4.248-.645.248-.252 0-.472-.081-.66-.245-.188-.163-.283-.392-.283-.685 0-.261.092-.48.274-.658.181-.178.405-.266.67-.266.26 0 .48.088.657.266.178.178.267.397.267.658 0 .289-.093.516-.28.682zm2.317-5.744c-.142.265-.312.493-.509.685-.196.193-.548.516-1.057.97-.14.13-.254.242-.338.339-.085.097-.148.186-.19.267-.041.08-.073.161-.095.242-.023.08-.057.222-.103.425-.078.43-.325.645-.738.645-.215 0-.396-.07-.543-.211-.147-.14-.22-.35-.22-.626 0-.348.054-.649.161-.903.107-.255.25-.478.428-.67.178-.193.418-.42.72-.686.265-.231.456-.406.574-.524.118-.118.217-.249.297-.394.081-.144.121-.301.121-.47 0-.332-.123-.61-.369-.838-.246-.228-.563-.341-.952-.341-.455 0-.79.114-1.005.344-.215.23-.397.567-.546 1.014-.14.467-.408.701-.8.701-.232 0-.427-.082-.587-.245-.159-.163-.238-.34-.238-.53 0-.393.126-.791.378-1.194.253-.404.62-.738 1.105-1.002.483-.265 1.048-.397 1.693-.397.6 0 1.129.11 1.588.332.459.22.813.522 1.064.902.25.38.375.794.375 1.241 0 .35-.07.659-.214.924z"
              transform="translate(-746 -330) translate(498 91) translate(248 239)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Info;
