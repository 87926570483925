import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { setEmulatorOS } from "panel/src/redux/actions/emulator";
import EmulatorOS, {
  IEmulatorOS,
} from "panel/src/Components/DragnDrop/Emulator/Screens/OS";
import EmulatorSplash, {
  IEmulatorSplash,
} from "panel/src/Components/DragnDrop/Emulator/Screens/Splash";
import EmulatorApp, {
  IEmulatorApp,
} from "panel/src/Components/DragnDrop/Emulator/Screens/App";
import ToggleOS, { OS } from "./ToggleOS";
import Components, { NavigationEnum } from "./Components";
import Button from "./Button";
import EmulatorContainer from "./EmulatorContainer";
import EmulatorFrame from "./EmulatorFrame";
import IOSFrame from "panel/src/assets/images/emulated-app/ios-frame.png";
import IOSFrameHome from "panel/src/assets/images/emulated-app/ios-frame-home.png";
import AndroidFrame from "panel/src/assets/images/emulated-app/android3.png";
import AndroidFrameHome from "panel/src/assets/images/emulated-app/android-home.png";
import EmulatorContent from "./EmulatorContent";
import BackIcon from "./BackIcon";
import { setChangedSthDesignPage } from "panel/src/redux/actions/manageApp";
import { PricingBanner } from "../../../_components/common/PricingBanner";
import Content from "./Content";
import { EPages } from "../../../utils/enums/pageType";
import EmulatorHome, { IEmulatorHome } from "./Screens/Home";
import { THeaderTabBarIconAlign } from "./Components/Header";
import { AddToCartBar } from "shared-components/src/App";
import Sidebar from "../../Sidebar/Sidebar";
import MenuEditor from "../../MenuEditor/MenuEditor";
import { usePackage } from "../../../hooks";

interface IEmulator {
  osScreen?: IEmulatorOS;
  homeScreen?: IEmulatorHome;
  splashScreen?: IEmulatorSplash;
  appScreen?: IEmulatorApp;
  saveScreen?: Function;
  navigation?: {
    tabBarIconAlign?: THeaderTabBarIconAlign;
    navigationType?:
      | NavigationEnum.tabNavigation
      | NavigationEnum.drawerNavigation;
    navigationBarIcon?: string | undefined;
  };
  productListHeader?: { visible: boolean; itemCount: number };
  showAddToCartBar?: boolean;
  showDiscardButton?: boolean;
  colorList?: any;
  navigateBack?: Function;
  backTo?: string;
  screen: string;
  fontFamily?: string;
  showInfoCard?: boolean;
  emulatorOS: number;
  _setEmulatorOS: Function;
  productSliderDimension?: any;
  _setChangedSthDesign: Function;
  saveScreenText?: string;
}

const blackListPricingBanner: string[] = [
  EPages.SplashScreen,
  EPages.PushNotification,
];

const Emulator = ({
  screen,
  osScreen,
  homeScreen,
  splashScreen,
  appScreen,
  saveScreen,
  saveScreenText = "Save and Continue",
  navigation,
  productListHeader,
  showAddToCartBar,
  colorList,
  navigateBack,
  backTo,
  fontFamily,
  showInfoCard,
  emulatorOS,
  _setEmulatorOS,
  productSliderDimension,
  _setChangedSthDesign,
}: IEmulator) => {
  const containerRef = useRef(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [isTransparent, setIsTransparent] = useState(true);
  const packageHook = usePackage();

  const canEditDynamicMenu = packageHook?.getPackageFeature('Dynamic Navigation')?.MaxCount > 0;

  // MENU EDITOR
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleShowSidebar = () => {
    setShowSidebar(prev => !prev);
  };

  const [showMenuEditor, setShowMenuEditor] = useState(false);

  useEffect(() => {
    setShowSidebar(false);
  }, [navigation?.navigationType])

  const toggleShowMenuEditor = () => {
    setShowMenuEditor(prev => !prev);
  }

  // MENU EDITOR 

  useEffect(() => {
    preLoadImages();
  }, []);

  useEffect(() => {
    setIsTransparent(true);
    contentRef.current?.scrollTo(0, 0);
  }, [appScreen?.screen]);

  // Resimleri preload eder.
  const preLoadImages = () => {
    const imagesPreload = [IOSFrame, AndroidFrame];
    imagesPreload.forEach((image) => {
      const newImage = new Image();
      newImage.src = image;
      // @ts-ignore
      window[image] = newImage;
    });
  };

  const getActiveOS = (currentOS: number) => {
    _setEmulatorOS(currentOS);
  };

  const onContentScroll = () => {
    if (contentRef.current?.scrollTop && productSliderDimension) {
      if (
        contentRef.current?.scrollTop >
        productSliderDimension.nativeEvent.layout.height - 20
      ) {
        setIsTransparent(false);
      } else {
        setIsTransparent(true);
      }
    }
  };

  const isProductDetailScreen = screen === 'Product Details';
  const canEditMenu = screen === 'Navigation Type' && canEditDynamicMenu;

  const renderSidebar = () => {
    if (showSidebar) {
      return (
        <Sidebar
          fontFamily={fontFamily}
          editSidebar={toggleShowMenuEditor}
          toggleSidebar={toggleShowSidebar}
        />
      )
    }
    return null;
  }

  const renderMenuEditor = () => {
    if (showMenuEditor) {
      const isTabBar = navigation?.navigationType !== NavigationEnum.drawerNavigation;
      return <MenuEditor toggleMenu={toggleShowMenuEditor} isTabBar={isTabBar} />
    }
    return null;
  }

  return (
    <EmulatorContainer>
      {renderMenuEditor()}
      {navigateBack && backTo && (
        <BackIcon navigateBack={navigateBack} backTo={backTo} />
      )}
      <Content>
        <EmulatorFrame
          isProductDetails={isProductDetailScreen}
          isSplashScreen={screen === "Splash Screen"}
          OS={emulatorOS}
          IOSFrame={homeScreen ? IOSFrameHome : IOSFrame}
          AndroidFrame={homeScreen ? AndroidFrameHome : AndroidFrame}
          ref={containerRef}
        >
          <Components.StatusBar
            platform={emulatorOS}
            hideStatusbar={blackListPricingBanner.includes(screen)}
          />
          {isProductDetailScreen && (
            <Components.ProductDetailHeader
              colorList={colorList}
              isTransparent={isTransparent}
              currentOS={emulatorOS}
            />
          )}
          {isProductDetailScreen && (
            <Components.ProductDetailHeaderBackground
              isTransparent={isTransparent}
              currentOS={emulatorOS}
            />
          )}
          {navigation && !productListHeader?.visible && (
            <Components.Header
              tabBarIconAlign={navigation?.tabBarIconAlign}
              onClickMenu={toggleShowSidebar}
              canEditMenu={canEditMenu}
              isDrawer={
                navigation.navigationType === NavigationEnum.drawerNavigation
              }
              navigationBarIcon={navigation.navigationBarIcon}
            />
          )}
          {productListHeader?.visible && (
            <Components.ProductListHeader
              fontFamily={fontFamily && fontFamily.toString()}
              itemCount={productListHeader.itemCount}
            />
          )}
          <EmulatorContent
            className="emulator-content"
            ref={contentRef}
            onScroll={() =>
              isProductDetailScreen && onContentScroll()
            }
            splashScreen={splashScreen && splashScreen}
            isProductDetails={isProductDetailScreen}
            isProductList={appScreen?.screen === "Product List"}
            OS={emulatorOS}
            height={
              navigation
                ? navigation.navigationType === NavigationEnum.tabNavigation
                  ? "398"
                  : "454"
                : productListHeader?.visible
                ? "398"
                : splashScreen
                ? "514"
                : showAddToCartBar
                ? emulatorOS === OS.IOS
                  ? "458"
                  : "442"
                : "497"
            }
            navigationType={navigation && navigation.navigationType}
          >
            {renderSidebar()}
            {osScreen && (
              <EmulatorOS
                appName={osScreen.appName}
                activeOS={emulatorOS}
                iosIcon={osScreen.iosIcon}
                androidIcon={osScreen.androidIcon}
              />
            )}
            {homeScreen && (
              <EmulatorHome activeOS={emulatorOS} {...homeScreen} />
            )}
            {splashScreen && (
              <EmulatorSplash
                isBasic={splashScreen.isBasic}
                splashImage={splashScreen.splashImage}
                splashLogo={splashScreen.splashLogo}
                backgroundType={splashScreen.backgroundType}
                backgroundColor={splashScreen.backgroundColor}
                linearColorStart={splashScreen.linearColorStart}
                linearColorEnd={splashScreen.linearColorEnd}
                radialColorStart={splashScreen.radialColorStart}
                radialColorEnd={splashScreen.radialColorEnd}
              />
            )}
            {appScreen && (
              <EmulatorApp
                screen={screen}
                selectedComponents={appScreen.selectedComponents || []}
                setSelectedComponents={appScreen.setSelectedComponents}
                requiredEdit={appScreen.requiredEdit}
                itemDropped={appScreen.itemDropped}
                editFormPlacement={appScreen.editFormPlacement}
                showOptionsMenu={appScreen.showOptionsMenu}
                containerRef={containerRef}
                applyOpacity={appScreen.applyOpacity}
              />
            )}
          </EmulatorContent>
          {navigation &&
            !showAddToCartBar &&
            navigation.navigationType === NavigationEnum.tabNavigation && (
              <Components.TabNavigation
                fontFamily={fontFamily}
                canEdit={canEditMenu}
                onClickEditButton={toggleShowMenuEditor}
              />
            )}
          {showAddToCartBar && (
            <AddToCartBar
              fontFamily={fontFamily && fontFamily.toString()}
              colorList={colorList}
              productPrice={"$5,00"}
              cms={{
                product_detail_total_price: "Total",
                product_detail_addtobasket: "Add to basket",
                product_sold_out: "Sold out",
                product_detail_preorder: "Pre-order",
              }}
            />
          )}
        </EmulatorFrame>
        {saveScreen && (
          <Button
            onClick={() => {
              saveScreen();
              _setChangedSthDesign(false);
            }}
          >
            {saveScreenText}
          </Button>
        )}
      </Content>
      {!blackListPricingBanner.includes(screen) && <PricingBanner />}
      <ToggleOS callback={getActiveOS} showOSInfoCard={showInfoCard} />
    </EmulatorContainer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    emulatorOS: state.emulator.os,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _setEmulatorOS: (os: number) => dispatch(setEmulatorOS(os)),
    _setChangedSthDesign: (value: boolean) =>
      dispatch(setChangedSthDesignPage(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Emulator);
