import * as Yup from "yup";
import {
  emailErrorMessage,
  requiredMessage,
  minMessage,
} from "../../utils/validationMessage";

const Schema = Yup.object().shape({
  username: Yup.string().email(emailErrorMessage).required(emailErrorMessage),
  password: Yup.string()
    .required(requiredMessage)
    .min(8, minMessage)
    .max(20, minMessage),
});

export default Schema;
