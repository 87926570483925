import { put, call, takeEvery } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import {
  setFontTypeFail,
  setFontTypeSuccess,
} from "panel/src/redux/actions/setFontType";
import * as userInfoActions from "panel/src/redux/actions/userInfo";
import * as pageActions from "panel/src/redux/actions/page";
import { SET_FONT_TYPE } from "panel/src/redux/constants/fontType";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import { clearDesignError, setDesignError } from "../actions/DesignError";

function* setFontType({ requestObj, callback }: any) {
  yield put(startAppLoading(SET_FONT_TYPE));
  try {
    yield call(axios.post, "/api/Manage/SetFont", { ...requestObj });

    yield put(clearDesignError());
    yield put(setFontTypeSuccess());
    yield put(pageActions.pageUpdated(true));
    if (callback) callback?.();
  } catch (error) {
    const err = error as any;
    yield put(setDesignError(`/api/Design/SetFont - ${err?.response?.status}`));
    yield put(setFontTypeFail(err.message));
  }

  yield put(userInfoActions.getUserInfo());
  yield put(endAppLoading(SET_FONT_TYPE));
}

export function* setFontTypeSaga() {
  yield takeEvery(SET_FONT_TYPE, setFontType);
}
