import React, { Component } from "react";
import { connect } from "react-redux";
import { History } from "history";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import SideMenu from "panel/src/_components/primitives/SideMenu";
import Modal from "panel/src/_components/common/Modal";
import close from "panel/src/assets/images/close.svg";
import warning from "panel/src/assets/images/warning.svg";
import Button from "panel/src/_components/common/Button";
import * as actions from "panel/src/redux/actions/apps";
import * as logoutActions from "panel/src/redux/actions/logout";
import * as userActions from "panel/src/redux/actions/userInfo";
import Input from "panel/src/_components/common/Input";
import {
  createAppStart,
  renameAppStart,
  deleteAppStart,
} from "panel/src/redux/actions/apps";
import { setAppReset } from "panel/src/redux/actions/App";
import classes from "./layout.module.scss";
import ValidationSchema from "./validationSchema";

interface ILayoutProps {
  apps?: any;
  isUserExpired: boolean;
  children: any;
  history: History;
  actions?: any;
  logoutActions?: any;
  isBlocked: boolean;
  isBlocking: boolean;
  userActions?: any;
  appUrl?: string;
  selectedApp: {
    AppId: string;
    PlatformId: number;
    IsSelected: boolean;
    IsStep1Completed: boolean;
    IsStep2Completed: boolean;
    IsStep3Completed: boolean;
    IsStep4Completed: boolean;
    IsStep5Completed: boolean;
    IsStep6Completed: boolean;
    IsStep7Completed: boolean;
    IsStep8Completed: boolean;
    IsStep9Completed: boolean;
    IsStep10Completed: boolean;
    IsStep11Completed: boolean;
    IsStep12Completed: boolean;
    IsStep13Completed: boolean;
  };
  userInfo: any;
  _setAppReset: Function;
  applicationError: string;
  _createApp: (name: string) => void;
  _renameApp: (appId: string, name: string) => void;
  _deleteApp: (appId: string, isSelected: boolean) => void;
}

interface ILayoutState {
  isSideMenuFull: boolean;
  appName: string;
  isShowDeleteModal: boolean;
  appId: string;
  isRenameModalVisible: boolean;
  isCreateAppModalVisible: boolean;
  isShowLogoutBlockingModal: boolean;
}

class Layout extends Component<ILayoutProps, ILayoutState> {
  state = {
    isSideMenuFull: true,
    showNewApplicationModal: false,
    appName: "",
    isShowDeleteModal: false,
    appId: "",
    isRenameModalVisible: false,
    isCreateAppModalVisible: false,
    isShowLogoutBlockingModal: false,
  };

  sideMenuToggleHandler = () => {
    this.setState((prevState) => {
      return {
        isSideMenuFull: !prevState.isSideMenuFull,
      };
    });
  };

  clickHandler = () => {
    if (!this.props.history.location.pathname.includes("/welcome"))
      this.props.history.push("/welcome");
  };

  appNameInputChangeHandler = (e: any) => {
    this.setState({ appName: e.target.value });
  };

  getInitialValues = () => {
    return {
      appName: this.state.appName,
    };
  };

  logoutClickHandler = async () => {
    if (this.props.isBlocked) {
      this.setState({ isShowLogoutBlockingModal: true });
    } else {
      this.onLogout();
    }
  };

  onLogout = async () => {
    try {
      await this.props.logoutActions.logoutStart();
    } catch (error) {
      console.log(error);
    }
  };

  closeBlockingModal = () => {
    this.setState({ isShowLogoutBlockingModal: false });
  };

  handleLogoutClick = () => {
    this.onLogout();
  };

  showCreateAppModal = () => {
    this.setState({
      isCreateAppModalVisible: true,
    });
  };

  hideCreateAppModal = () => {
    this.setState({
      isCreateAppModalVisible: false,
      appId: "",
      appName: "",
    });
  };

  handleCreateApp = (values: any) => {
    this.props._createApp(values.appName);
    this.hideCreateAppModal();
  };

  showRenameAppModal = (id: string, name: string) => {
    this.setState({
      isRenameModalVisible: true,
      appId: id,
      appName: name,
    });
  };

  hideRenameAppModal = () => {
    this.setState({
      isRenameModalVisible: false,
      appId: "",
      appName: "",
    });
  };

  handleRenameApp = (values: any) => {
    this.props._renameApp(this.state.appId, values.appName);
    this.hideRenameAppModal();
  };

  showDeleteAppModal = (id: string, name: string) => {
    this.setState({
      isShowDeleteModal: true,
      appId: id,
      appName: name,
    });
  };

  hideDeleteApp = () => {
    this.setState({
      isShowDeleteModal: false,
      appId: "",
      appName: "",
    });
  };

  handleDeleteApp = async () => {
    const isSelectedAppDeleted = this.props.apps.find(
      (app: any) => app.AppId === this.state.appId
    ).IsSelected;

    this.props._deleteApp(this.state.appId, isSelectedAppDeleted);
    this.hideDeleteApp();
  };

  cancelClickHandler = async () => {
    this.props._setAppReset();
  };

  cancelErrorClickHandler = async () => {
    this.props._setAppReset();
    this.props.history.push("/manage/web-service-control/begin", {
      previousPlatformId: this.props.selectedApp.PlatformId,
      previousUrl: this.props.appUrl,
    });
  };

  render() {
    const { apps, userName, emailAddress } = this.props.userInfo;
    const attachedClasses = [classes.Main];
    const appsOptions =
      apps &&
      apps.map((app: any) => {
        return {
          value: app.AppId,
          displayValue: app.AppName,
          createDate: app.CreateDate,
        };
      });

    return (
      <React.Fragment>
        <SideMenu
          sideMenuToggleHandler={this.sideMenuToggleHandler}
          isSideMenuFull={this.state.isSideMenuFull}
          appsSelectValue={this.props.selectedApp.AppId}
          appsOptions={appsOptions}
          logoClickHandler={this.clickHandler}
          createAppHandler={this.showCreateAppModal}
          renameAppHandler={this.showRenameAppModal}
          deleteAppHandler={this.showDeleteAppModal}
          logoutClickHandler={this.logoutClickHandler}
        />
        <main className={attachedClasses.join(" ")}>{this.props.children}</main>
        <Modal show={this.state.isShowDeleteModal} width="448">
          <div className={classes.Close} onClick={() => this.hideDeleteApp()}>
            <img src={close} />
          </div>
          <div className={`${classes.ModalBody} ${classes.ModalBodyConfirm}`}>
            <span className={classes.ModalBodyText}>
              Are you sure you want to delete “{this.state.appName}” ?
            </span>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type="button"
                className="Ghost"
                width="192px"
                onClick={this.handleDeleteApp}
              >
                Delete
              </Button>
              <Button
                type="button"
                className="Primary"
                width="192px"
                onClick={() => this.hideDeleteApp()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
        <Modal show={this.state.isRenameModalVisible} width="448">
          <Formik
            enableReinitialize={true}
            onSubmit={(values) => this.handleRenameApp(values)}
            initialValues={this.getInitialValues()}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={ValidationSchema}
          >
            {() => (
              <Form>
                <div className={classes.ModalTitle}>
                  <span className={classes.ModalTitleText}>Rename</span>
                </div>
                <div className={classes.ModalBody}>
                  <span className={classes.ModalBodyText}>
                    Please type the new name of your project. Note that this
                    name will not be the name of your mobile application
                  </span>
                  <Field
                    value={this.state.appName}
                    name="appName"
                    elementType="input"
                    placeholder="New Application"
                    type="text"
                    changeHandler={this.appNameInputChangeHandler}
                    component={Input}
                  />
                </div>
                <div className={classes.ModalFooter}>
                  <div className={classes.ButtonContainer}>
                    <Button
                      type="button"
                      className="Ghost"
                      width="192px"
                      onClick={() => this.hideRenameAppModal()}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" width="192px">
                      Change
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        <Modal show={this.state.isCreateAppModalVisible} width="448">
          <Formik
            enableReinitialize={true}
            onSubmit={(values) => this.handleCreateApp(values)}
            initialValues={this.getInitialValues()}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={ValidationSchema}
          >
            {() => (
              <Form>
                <div className={classes.ModalTitle}>
                  <span className={classes.ModalTitleText}>
                    New Project Name
                  </span>
                </div>
                <div className={classes.ModalBody}>
                  <span className={classes.ModalBodyText}>
                    Please type the name of your project.{" "}
                  </span>
                  <Field
                    value={this.state.appName}
                    name="appName"
                    elementType="input"
                    placeholder="New project "
                    type="text"
                    changeHandler={this.appNameInputChangeHandler}
                    component={Input}
                  />
                </div>
                <div className={classes.ModalFooter}>
                  <div className={classes.ButtonContainer}>
                    <Button
                      type="button"
                      className="Ghost"
                      width="192px"
                      onClick={() => this.hideCreateAppModal()}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" width="192px">
                      Create
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        <Modal
          backdropClickedHandler={() => this.closeBlockingModal()}
          show={this.state.isShowLogoutBlockingModal}
          width="448"
        >
          <div
            className={classes.Close}
            onClick={() => this.closeBlockingModal()}
          >
            <img src={close} />
          </div>
          <div className={classes.ModalBody}>
            <span className={classes.ModalBodyText}>
              Are you sure you like to leave this page? <br />
              The changes you made will be lost.
            </span>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type="button"
                className="Ghost"
                width="192px"
                onClick={this.handleLogoutClick}
              >
                Leave
              </Button>
              <Button
                type="button"
                className="Primary"
                width="192px"
                onClick={() => this.closeBlockingModal()}
              >
                Stay
              </Button>
            </div>
          </div>
        </Modal>
        <Modal show={this.props.applicationError} width="448">
          <div className={classes.Close} onClick={this.cancelClickHandler}>
            <img src={close} />
          </div>
          <div className={`${classes.ModalBody} ${classes.ModalBodyConfirm}`}>
            <img
              className={classes.ConfirmIcon}
              src={warning}
              width={48}
              height={48}
            />
            <span className={classes.ModalBodyText}>
              {this.props.applicationError}
            </span>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ErrorButtonGroup}>
              <Button
                type="button"
                className="Primary"
                width="192px"
                onClick={
                  this.props.history.location.pathname ===
                  "/manage/web-service-control/verify"
                    ? this.cancelErrorClickHandler
                    : this.cancelClickHandler
                }
              >
                OK
              </Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    appUrl: state.manageApp.appUrl,
    apps: state.userInfo.apps,
    isBlocked: state.page.isBlocked,
    userInfo: state.userInfo,
    selectedApp: state.userInfo.selectedApp,
    applicationError: state.application.error,
    // liveChatVisibility: state.liveChat.visibility,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    logoutActions: bindActionCreators(logoutActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    _createApp: (name: string) => dispatch(createAppStart({ Name: name })),
    _renameApp: (appId: string, name: string) =>
      dispatch(renameAppStart({ ApplicationId: appId, Name: name })),
    _deleteApp: (appId: string, isSelected: boolean) =>
      dispatch(deleteAppStart({ ApplicationId: appId, isSelected })),
    _setAppReset: () => dispatch(setAppReset()),
  };
};

export default withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(Layout)
);
