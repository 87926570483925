import React, { useState } from "react";
import classes from "./mobile.module.scss";
import Modal from "panel/src/_components/common/Modal";
import close from "panel/src/assets/images/close.svg";
import Button from "panel/src/_components/common/Button";
import tick from "panel/src/assets/images/tick.svg";

const MobileWarn = () => {
  const [showScreenWarn, setShowScreenWarn] = useState<boolean>(true);

  return (
    <Modal
      show={showScreenWarn}
      backdropClickedHandler={() => setShowScreenWarn(false)}
      width='500'
    >
      <div className={classes.Close} onClick={() => setShowScreenWarn(false)}>
        <img src={close} />
      </div>
      <img className={classes.ConfirmIcon} src={tick} width={48} height={48} />
      <div className={classes.Container}>
        <p className={classes.Description}>
          To engage easily with Mowico Wizard features, design components and to
          publish your mobile app well, we highly recommended you to keep your
          Mowico Wizard experience going on desktop or tablet
        </p>
        <Button
          type='button'
          className='Primary'
          width='192px'
          onClick={() => setShowScreenWarn(false)}
          marginTop='40px'
        >
          Okay
        </Button>
      </div>
    </Modal>
  );
};

export default MobileWarn;
