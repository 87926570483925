import React, { useState, useEffect } from "react";
import classes from "./editContent.module.scss";
import Select from "panel/src/_components/common/Select";
import SelectModel from "../../../../models/Design/selectModel";
import * as generalActions from "panel/src/redux/actions/generalSettings";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Icon from "panel/src/_components/common/Icon";
import WatchVideoModal from "../../../../_components/primitives/WatchVideoModal";
import {YOUTUBE_LINKS} from "../../../../Components/YoutubeTutorial";
import { GetGeneralSettingsRequestModel } from "../../../../models/Design/generalSettings/getGeneralSettingsRequestModel";
import {
  GetGeneralSettingsResponseModel,
  Language,
} from "../../../../models/Design/generalSettings/getGeneralSettingsResponse";
import Button from "panel/src/_components/common/Button";
import { History } from "history";
import SelectLanguageModel from "../../../../models/Manage/selectLanguageModel";
import EngageNavigation from "panel/src/_components/primitives/EngageNavigation";
import useIntercomChat from "../../../../Components/Intercom/useIntercomChat";

interface IProps {
  appId?: string;
  generalActions?: any;
  generalSettings?: GetGeneralSettingsResponseModel["ResultData"];
  history: History;
}

const EditContent = (props: IProps) => {
  const [referanceLanguageId, setReferanceLanguageId] = useState("");
  const [referanceLanguageName, setReferanceLanguageName] = useState("");
  const [createLanguageId, setCreateLanguageId] = useState("");
  const [createLanguageName, setCreateLanguageName] = useState("");
  const [activeLanguages, setActiveLanguages] = useState([
    { value: "", displayValue: "", isSelected: true, isComplated: false },
  ]);
  const [inactiveLanguages, setInactiveLanguages] = useState([
    { value: "", displayValue: "", isSelected: true, isComplated: false },
  ]);
  const [isReferanceLanguageError, setReferanceLanguageError] = useState(false);
  const [isCreateLanguageError, setCreateLanguageError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleShowTutorial = () => {
    setShowModal(prevState => !prevState);
  };

  useIntercomChat({});
  useEffect(() => {
    const getGeneralSettingsData = async () => {
      if (props.appId) {
        const requestObj: GetGeneralSettingsRequestModel = {
          ApplicationId: props.appId,
        };
        await props.generalActions.getGeneralSettingsStart(requestObj);
      }
    };
    getGeneralSettingsData();
  }, [props.appId]);

  useEffect(() => {
    if (props.generalSettings) {
      const { LanguageList } = props.generalSettings;

      const selectedOption = LanguageList && LanguageList.SelectedLanguage;
      const selectedId = selectedOption ? selectedOption.LanguageID : "";
      const selectedName = selectedOption ? selectedOption.Name : "";
      setReferanceLanguageId(selectedId.toString());
      setReferanceLanguageName(selectedName);

      let languageOptionList: SelectLanguageModel[] = [];
      let referenceLanguageOptionList: SelectLanguageModel[] = [];
      LanguageList &&
        LanguageList.Languages.map((item: Language, index: number) => {
          if (selectedId == item.LanguageID) {
            referenceLanguageOptionList.push({
              value: item.LanguageID.toString(),
              displayValue: item.Name,
              isClientActive: item.IsClientActive,
              cultureSettingKey: item.CultureSettingKey,
              isSelected: true,
              isComplated: false,
              isUsed: item.IsUsed,
            });
          } else {
            referenceLanguageOptionList.push({
              value: item.LanguageID.toString(),
              displayValue: item.Name,
              isClientActive: item.IsClientActive,
              cultureSettingKey: item.CultureSettingKey,
              isSelected: false,
              isComplated: false,
              isUsed: item.IsUsed,
            });
          }
        });

      LanguageList &&
        LanguageList.Languages.map((item: Language, index: number) => {
          if (item.IsUsed == true) {
            languageOptionList.push({
              value: item.LanguageID.toString(),
              displayValue: item.Name,
              isClientActive: item.IsClientActive,
              cultureSettingKey: item.CultureSettingKey,
              isSelected: true,
              isComplated: false,
              isUsed: item.IsUsed,
            });
          }
        });

      setActiveLanguages(referenceLanguageOptionList);
      setInactiveLanguages(languageOptionList);
    }
  }, [props.generalSettings]);

  const selectReferanceLanguage = (
    e: React.ChangeEvent<SelectModel & HTMLSelectElement>
  ) => {
    const updatedOptions = activeLanguages.map((option: SelectModel) => {
      return {
        ...option,
        isSelected: option.value === e.target.value,
      };
    });

    setActiveLanguages(updatedOptions);
    setReferanceLanguageId(e.target.value);
    setReferanceLanguageName(e.target.displayValue);
    setReferanceLanguageError(false);
  };

  const selectCreatedLanguage = (
    e: React.ChangeEvent<SelectModel & HTMLSelectElement>
  ) => {
    const updatedOptions = inactiveLanguages.map((option: SelectModel) => {
      return {
        ...option,
        isSelected: option.value === e.target.value,
      };
    });

    setInactiveLanguages(updatedOptions);
    setCreateLanguageId(e.target.value);
    setCreateLanguageName(e.target.displayValue);
    setCreateLanguageError(false);
  };

  const submitHandler = () => {
    if (!referanceLanguageId && !createLanguageId) {
      setCreateLanguageError(true);
      setReferanceLanguageError(true);
      return;
    } else if (!referanceLanguageId) {
      setReferanceLanguageError(true);
      return;
    } else if (!createLanguageId) {
      setCreateLanguageError(true);
      return;
    }
    props.history.push(
      `/engage/content-settings/edit-content/${referanceLanguageId}/${referanceLanguageName}/${createLanguageId}/${createLanguageName}`
    );
  };

  return (
    <React.Fragment>
      <EngageNavigation />
      <div className={classes.contentContainer}>
        <div className={classes.EngageSubRouteContainer}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h3 className={classes.Heading}>Edit Content</h3>
            <div style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={toggleShowTutorial}>
              <Icon
                  className=""
                  type="watchVideo"
                  fillColor="#e66565"
                  width="16"
                  height="16"
              />
            </div>
            <WatchVideoModal
                watchVideoClickHandler={toggleShowTutorial}
                show={showModal}
                videoUrl={YOUTUBE_LINKS.LANGUAGE_SETTINGS}
            />
          </div>
          <p className={classes.Info}>
            You may edit the language contents shown in your mobile application
            by simply selecting any language below.
          </p>
          <div className={classes.LanguageContainer}>
            <div className={classes.LanguageItem}>
              <span className={classes.BlockLabel}>
                Select Language as Reference
              </span>
              <Select
                options={activeLanguages}
                value={referanceLanguageId}
                placeholder="Choose..."
                name="language"
                changeHandler={selectReferanceLanguage}
                isUsedEnable={false}
              />
              {isReferanceLanguageError && (
                <span className={classes.errorLabel}>
                  A language must be selected first.
                </span>
              )}
            </div>
            <div className={classes.LanguageItem}>
              <span className={classes.BlockLabel}>
                Select a Language to Edit
              </span>
              <Select
                options={inactiveLanguages}
                value={createLanguageId}
                placeholder="Choose..."
                name="language"
                changeHandler={selectCreatedLanguage}
                isUsedEnable={false}
              />
              {isCreateLanguageError && (
                <span className={classes.errorLabel}>
                  A language must be selected first.
                </span>
              )}
            </div>
          </div>
        </div>
        <div className={classes.SaveButtonContainer}>
          <Button width="160px" height="40px" onClick={submitHandler}>
            Edit
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
  };
};

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    generalSettings: state.generalSettings.generalSettings,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditContent);
