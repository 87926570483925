import {
    UPDATE_PAGE,
} from "panel/src/redux/constants/page";

export const pageUpdated = (isBlocked: boolean) => {
    return {
        type: UPDATE_PAGE,
        isBlocked
    };
};


