import React from "react";
import { History } from "history";
import classes from "./notFound.module.scss";

interface IProps {
  history: History;
}

const NotFound = (props: IProps) => {
  const goBack = () => {
    props.history.goBack();
  };

  return (
    <div className={classes.Container}>
      <span className={classes.Heading}>404</span>
      <p className={classes.Description}>
        Sorry! We cant seem to find the page you were looking for. If you think
        this page as missing, please click the live chat box or send us an
        e-mail to report and we will check it immediately.
      </p>
      <button className={classes.GoBack} onClick={goBack}>
        Go Back
      </button>
    </div>
  );
};

export default NotFound;
