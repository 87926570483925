import styled from "styled-components";

interface IInput {
  hasError: boolean;
}

export default styled.input<IInput>`
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(22, 33, 43, 0.12);
  padding: 11px 16px;
  font-family: inherit;
  font-size: 14px;
  color: #3e5e6e;
  font-weight: 500;
  border: 1px solid ${(props) => (props.hasError ? "#e66565" : "transparent")};

  &::placeholder {
    color: #9fafb6;
  }
`;
