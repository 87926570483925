import React from "react";
import { colors, shadow } from "../../utils/constants";
import { ColorListData } from "../../models/colorListData";
import { GestureResponderEvent, Platform } from "react-native";
import {
  Container,
  PreviousPrice,
  PriceContainer,
  PriceInfoWrapper,
  Text,
  Label,
  Price,
  Button,
} from "./AddToCartBar.styles";
import { DiscountCard } from ".";
import { ProductQty } from "../ProductQty";

interface IAddToCartBar {
  cms: {
    product_detail_total_price: string;
    product_detail_addtobasket: string;
    product_sold_out: string;
    product_detail_preorder: string;
  };
  previous?: {
    price: number;
    description: string;
  };
  discountPercentage?: string;
  productPrice: string;
  onCartAdd?: Function;
  isBuyable?: boolean;
  isPreorder?: boolean;
  colorList?: ColorListData[];
  fontFamily?: any;
  paddingBottom?: number;
  qty?: number;
  onPressMinus?: (event: GestureResponderEvent) => void;
  onPressPlus?: (event: GestureResponderEvent) => void;
}

const AddToCartBar = ({
  cms: {
    product_detail_total_price,
    product_detail_addtobasket,
    product_sold_out,
    product_detail_preorder,
  },
  previous = { price: 0, description: "" },
  discountPercentage,
  productPrice,
  onCartAdd,
  isBuyable = true,
  isPreorder = false,
  colorList,
  fontFamily,
  paddingBottom = Platform.OS === "web" ? 16 : 0,
  qty = 0,
  onPressMinus,
  onPressPlus,
}: IAddToCartBar) => {
  return (
    <Container style={{ paddingBottom, ...shadow }}>
      <PriceInfoWrapper>
        {previous?.price > 0 && discountPercentage && (
          <DiscountCard
            secondary={
              colorList && colorList?.length > 0
                ? colorList[1].colorCode
                : colors.darkSlateBlue
            }
            fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
            discountAmount={discountPercentage}
          />
        )}
        <PriceContainer>
          {previous?.price > 0 ? (
            <PreviousPrice
              primary={
                colorList && colorList?.length > 0
                  ? colorList[0].colorCode
                  : colors.darkSlateBlue
              }
              fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
            >
              {previous.description}
            </PreviousPrice>
          ) : (
            <Text
              primary={
                colorList && colorList?.length > 0
                  ? colorList[0].colorCode
                  : colors.darkSlateBlue
              }
              fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
            >
              {product_detail_total_price}
            </Text>
          )}
          <Price
            primary={
              colorList && colorList?.length > 0
                ? colorList[0].colorCode
                : colors.darkSlateBlue
            }
            fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
          >
            {productPrice}
          </Price>
        </PriceContainer>
      </PriceInfoWrapper>
      {qty > 0 && (
        <ProductQty
          onPressMinus={onPressMinus}
          onPressPlus={onPressPlus}
          qty={qty}
          color={
            colorList && colorList?.length > 0
              ? colorList[1].colorCode
              : colors.darkSkyBlue
          }
        />
      )}
      {qty <= 0 && (
        <Button
          onPress={() => onCartAdd?.()}
          disabled={!isPreorder && !isBuyable}
          secondary={
            colorList && colorList?.length > 0
              ? colorList[1].colorCode
              : colors.darkSlateBlue
          }
        >
          <Label fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}>
            {isPreorder || qty > 0
              ? product_detail_preorder
              : isBuyable
              ? product_detail_addtobasket
              : product_sold_out}
          </Label>
        </Button>
      )}
    </Container>
  );
};
export default AddToCartBar;
