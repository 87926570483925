import * as Yup from "yup";

enum LinkTypes {
  Category = 1,
  ProductGroup,
}

export default Yup.object().shape({
  Items: Yup.array().of(
    Yup.object().shape({
      linkType: Yup.number().required("Link type is required."),
      CategoryId: Yup.string().when("linkType", {
        is: LinkTypes.Category,
        then: Yup.string().required("Category can't be blank."),
      }),
      GroupId: Yup.string().when("linkType", {
        is: LinkTypes.ProductGroup,
        then: Yup.string().required("Product Group can't be blank."),
      }),
    })
  ),
});
