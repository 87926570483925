import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ISpinner } from ".";
import classes from "./spinner.module.scss";

const Default = ({ isLoading }: ISpinner) => {
  const [textMessage, setTextMessage] = useState<string>("");
  const { pathname } = useLocation();


  enum StepStatusEnum {
    generalFirstText = "Loading... ⏳",
    integrationFirstText = "Integration is provided",
    configurationFirstText = "Configuration is provided",
    paymentFirstText = "You are being redirected to the payment service...",
    engagamentFirstText = "Engagement Services is provided",
    designFirstText = "Design changes are being made",
    publishFirstText = "Application being published",
    pushFirstText = "Notification is being sent",
    generalSecondText = "This process may take some time... ⏳",
    redirectedText = "You are being redirected to the page",
    thanksForWaiting = "Thanks for waiting 🥳",
  }

  enum PathNames {
    integration = "/manage/web-service-control/begin",
    configuration = "/manage/config-settings",
    paymentOption = "/manage/payment-options",
    engagementOption = "/manage/engagement-options",
    design = '/design',
    publish = '/publish/qr',
    pushNotification = '/push-notification',
    }

   const integrationStepOptions = [StepStatusEnum.integrationFirstText, StepStatusEnum.generalSecondText, StepStatusEnum.redirectedText, StepStatusEnum.thanksForWaiting];
   const configurationStepOptions = [StepStatusEnum.configurationFirstText, StepStatusEnum.generalSecondText, StepStatusEnum.redirectedText, StepStatusEnum.thanksForWaiting];
   const paymentOptionStepOptions = [StepStatusEnum.redirectedText, StepStatusEnum.generalSecondText, StepStatusEnum.thanksForWaiting];
   const engagementStepOptions = [StepStatusEnum.engagamentFirstText, StepStatusEnum.generalSecondText, StepStatusEnum.redirectedText, StepStatusEnum.thanksForWaiting];
   const designStepOptions = [StepStatusEnum.designFirstText, StepStatusEnum.generalSecondText, StepStatusEnum.thanksForWaiting];
   const publishStepOptions = [StepStatusEnum.publishFirstText, StepStatusEnum.generalSecondText];
   const pushNotificationStepOptions = [StepStatusEnum.pushFirstText, StepStatusEnum.generalSecondText, StepStatusEnum.thanksForWaiting];
   const defaultOptions = [StepStatusEnum.generalFirstText, StepStatusEnum.generalSecondText, StepStatusEnum.thanksForWaiting];

  const timedText = () => {
    setTextMessage('');
    if(isLoading){
    switch (pathname) {
      case PathNames.integration:
        timedTextChangeMessage(integrationStepOptions);      
        break;

      case PathNames.configuration:
        timedTextChangeMessage(configurationStepOptions);      
        break;
        
        case PathNames.paymentOption:
          timedTextChangeMessage(paymentOptionStepOptions);     
        break;

        case PathNames.engagementOption:
          timedTextChangeMessage(engagementStepOptions); 
        break;

        case PathNames.design:
            timedTextChangeMessage(designStepOptions); 
        break;

        case PathNames.publish:
            timedTextChangeMessage(publishStepOptions); 
        break;

        case PathNames.pushNotification:
            timedTextChangeMessage(pushNotificationStepOptions);
        break;
            
      default:
        timedTextChangeMessage(defaultOptions);
        break;
    }}
    else 
    {
     return;
    }
  };

  const timedTextChangeMessage = (optionType: Array<any>) => {
    optionType.forEach((text, count) =>   
    setTimeout(() => {
    setTextMessage(text);
  }, count * 2000));
  }

  useEffect(() => {    
    timedText();
  }, [isLoading]);

 

  return (
    <div
      className={`${classes.spinnerWrapper} ${
        isLoading ? classes.shown : classes.hidden
      }`}
    >
      <div className={classes.gifContainer}>
        <div className={classes.image}>
          <img
            src={require("panel/src/assets/images/mowico_loading.gif")}
            width="62px"
            height="92px"
            alt="mowico_loading"
          />
        </div>
        <span className={classes.spinnerText}>{textMessage}</span>
      </div>
    </div>
  );
};

export default Default;
