import { NotificationModule } from "../../models/Notification";
import {
  GET_NOTIFICATIONS,
  SEND_NOTIFICATION,
  SET_NOTIFICATIONS,
} from "../constants/notifications";

export const sendNotification = (
  notification: NotificationModule.Notification
) => ({
  type: SEND_NOTIFICATION,
  notification,
});

export const setNotifications = (
  notifications: NotificationModule.NotificationResponse[]
) => ({
  type: SET_NOTIFICATIONS,
  payload: notifications,
});

export const getNotifications = () => ({
  type: GET_NOTIFICATIONS,
});
