import styled from "styled-components";

interface ITypeWrapper {
  hasMarginRight: boolean;
}

export default styled.div<ITypeWrapper>`
  cursor: pointer;
  ${(props) => props.hasMarginRight && "margin-right:16px"}
`;
