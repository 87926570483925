import React from "react";
import ProductTools from "./ProductTools";
import Container from "./Container";
import Icon from "./Icon";
import Wrapper from "./Wrapper";
import HeaderContainer from "./HeaderContainer";
import Title from "./Title";
import SubTitle from "./SubTitle";

import basket from "panel/src/assets/images/emulated-app/basket.svg";
import search from "panel/src/assets/images/emulated-app/search.svg";
import back from "panel/src/assets/images/emulated-app/back.svg";

interface IProductListHeader {
  fontFamily?: string;
  itemCount: number;
}

const Header = ({ fontFamily, itemCount }: IProductListHeader) => {
  return (
    <React.Fragment>
      <Container>
        <Wrapper>
          <Icon src={back} alt="back" />
        </Wrapper>
        <HeaderContainer>
          <Title fontFamily={fontFamily}>Sample Products</Title>
          <SubTitle fontFamily={fontFamily}>{itemCount} Items</SubTitle>
        </HeaderContainer>
        <Wrapper>
          <Icon marginRight="12" src={search} alt="search" />
          <Icon src={basket} alt="basket" />
        </Wrapper>
      </Container>
      <ProductTools fontFamily={fontFamily} />
    </React.Fragment>
  );
};

export default Header;
