import {
  ASK_DEMO,
  ASK_DEMO_FAIL,
  ASK_DEMO_SUCCESS,
  RESET_ASK_DEMO,
} from "panel/src/redux/constants/askDemo";

const initialState = {
  data: false,
  loading: false,
  success: false,
};

const askDemoReducer = (state: Object = initialState, action: any) => {
  switch (action.type) {
    case ASK_DEMO:
      return {
        ...state,
        loading: true,
      };
    case ASK_DEMO_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.data,
      };
    case ASK_DEMO_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case RESET_ASK_DEMO:
      return {
        data: false,
        loading: false,
        success: false,
        error: false,
      };
    default:
      return state;
  }
};

export default askDemoReducer;
