import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// COMPONENTS START
import Modal from "panel/src/_components/common/Modal";
import ConfirmButton from "panel/src/_components/common/Button";
import ScreenSelection from "../components/ScreenSelection";
// COMPONENTS END

//REDUX ACTIONS START
import { saveScreenData } from "panel/src/redux/actions/SaveScreen";
// REDUX ACTIONS END

//MODELS START
import ISaveScreenModel from "panel/src/models/SaveScreenModel";
import SelectModel from "panel/src/models/Design/selectModel";
//MODELS END

import { ComponentType } from "panel/src/utils/enums/componentType";
import classes from "./categories.module.scss";
import closeIcon from "panel/src/assets/images/close.svg";
import {
  getCategories,
  getEditCategories,
  saveCategories,
} from "../../../redux/actions/category";
import AddIcon from "panel/src/assets/images/addBlue.svg";
import Input from "../../../Components/Input";
import Button from "../../../Components/DragnDrop/Emulator/Button";
import { ScreenType } from "../../../utils/enums/screenType";
import {
  Tree,
  getBackendOptions,
  MultiBackend,
} from "@minoru/react-dnd-treeview";
import { DndProvider } from "react-dnd";
import { CustomNode } from "./Components/CustomNode";
import { CustomDragPreview } from "./Components/CustomDragPreview";
import { Placeholder } from "./Components/Placeholder";
import {
  addMenuItem,
  convertCategoriesForSource,
  convertCategoriesForTreeView,
  editMenuItem,
  getChildIdListByParentId,
  removeFromTargetCategory,
  removeMenuItem,
  treeViewHandleByParent,
} from "./utils";
import { TNode } from ".";
import { ComponentsContainer, Content } from "./Categories.styles";
import Checkbox from "../../../_components/common/Checkbox";
import { MultipleDragPreview } from "./Components/MultipleDragPreview";
import dragDrop from "../../../assets/images/drag-drop.png";
import UpgradeComponent from "../../../_components/common/UpgradeComponent";
import UpgradeModal from "../../../Components/UpgradeModal";
import UpgradeGuide from "../../../Components/UpgradeGuide";
import { usePackage } from "../../../hooks";

interface ICategories {
  appId: string;
  _saveScreenData: Function;
  isCompletion: boolean;
  selectedValue: string;
  designScreens: SelectModel[];
  designScreensChange: Function;
  selectChange: Function;
  isOnModalSubmit: boolean;
  setError: Function;
  editCategories: any[]; //TODO: type düzeltilmeli
  categories: any[];
  _getEditCategories: Function;
  _getCategories: Function;
  _saveCategories: Function;
}

export const requiredEditForms = [
  ComponentType.GalleryView,
  ComponentType.ListView,
  ComponentType.MediumListView,
  ComponentType.SmallListView,
];

const Categories = ({
  isCompletion,
  selectedValue,
  selectChange,
  designScreens,
  designScreensChange,
  editCategories,
  categories,
  _getEditCategories,
  _getCategories,
  _saveCategories,
}: ICategories) => {
  const packageHook = usePackage();

  const [sourceCategory, setSourceCategory] = useState<TNode[]>([]);
  const [filteredCategory, setFilteredCategory] = useState<TNode[]>([]);
  const [treeData, setTreeData] = useState<TNode[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [parentId, setParentId] = useState<string>("");
  const [selectedNodes, setSelectedNodes] = useState<TNode[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [oldMenuItemNode, setOldMenuItemNode] = useState<TNode | null>(null);
  const [menuItemModalVisible, setMenuItemModalVisible] = useState(false);
  const [menuItemRemoveModalVisible, setMenuItemRemoveModalVisible] =
    useState(false);
  const [menuItemName, setMenuItemName] = useState("");
  const [isUpgradeModal, setIsUpgradeModal] = useState<boolean>(false);
  const [isUpgradeModalShown, setIsUpgradeModalShown] =
    useState<boolean>(false);
  const [isUpgradeGuide, setIsUpgradeGuide] = useState<boolean>(false);
  useEffect(() => {
    const list = convertCategoriesForSource(categories, treeData);
    setSourceCategory(list);
  }, [categories, treeData]);

  useEffect(() => {
    const list = convertCategoriesForTreeView(editCategories);
    setTreeData(list);
  }, [editCategories]);

  useEffect(() => {
    _getEditCategories();
    _getCategories();
  }, []);

  useEffect(() => {
    if (packageHook.isPackageFeaturesLimited) {
      setIsUpgradeGuide(true);
    }
  }, []);

  const toggleEditModal = (node: TNode) => {
    setOldMenuItemNode(node);
    setMenuItemName(node.text);
    setMenuItemModalVisible(true);
  };

  const discardMenuItemChange = () => {
    setMenuItemModalVisible(false);
    setMenuItemName("");
    setOldMenuItemNode(null);
    setMenuItemRemoveModalVisible(false);
  };

  const onRemoveMenuItem = (node: TNode) => {
    setOldMenuItemNode(node);
    setMenuItemRemoveModalVisible(true);
  };

  const onRemoveMenuItemHandle = () => {
    const newTreeData = removeFromTargetCategory(treeData, parentId);
    setTreeData(treeViewHandleByParent(newTreeData));
    setMenuItemRemoveModalVisible(false);
    /*if (oldMenuItemNode) {
      const tempCategoryList = removeMenuItem(treeData, oldMenuItemNode);
      setTreeData(tempCategoryList);
      discardMenuItemChange();
    }*/
  };

  const onItemHandle = () => {
    if (menuItemName) {
      if (oldMenuItemNode === null) {
        const tempList = addMenuItem(treeData, menuItemName);
        setTreeData(tempList);
      } else {
        const tempList = editMenuItem(treeData, oldMenuItemNode, menuItemName);
        setTreeData(tempList);
      }
      discardMenuItemChange();
    }
  };

  const onSubmitForm = () => {
    setIsUpgradeModalShown(true);
    if (packageHook.isPackageFeaturesLimited) {
      if (isUpgradeModalShown) {
        let data = convertCategoriesForSource(categories, [], 0);
        _saveCategories({
          categories: data,
          callback: () => {
            selectChange(ScreenType.Home.toString());
          },
        });
      } else {
        setIsUpgradeModal(true);
      }
    } else {
      _saveCategories({
        categories: treeData,
        callback: () => {
          selectChange(ScreenType.Home.toString());
        },
      });
    }
  };

  const searchItems = (e: any) => {
    setSearchInput(e.target.value);
    if (e.target.value !== "") {
      const filteredData = sourceCategory.filter((item) => {
        return (
          Object.values(item.text)
            .join("")
            .toLowerCase()
            .indexOf(e.target.value) > -1
        );
      });
      setFilteredCategory(filteredData);
    } else {
      setFilteredCategory(sourceCategory);
    }
  };
  const checkboxChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: TNode
  ) => {
    let temp = [...selectedNodes];

    if (e.target.checked) temp.push(item);
    else temp = temp.filter((s) => s.id !== item.id);

    setSelectedNodes(temp);
  };

  const isSelectedNode = (id: any) => {
    return selectedNodes.some((s) => s.id === id);
  };

  const categoryLayoutSourceOnDrop = (_: TNode[], config: any) => {
    if (
      (config.dropTargetId === "mowico_source_category" ||
        config?.dropTarget.parent === "mowico_source_category") &&
      config?.dragSource?.parent !== "mowico_source_category"
    ) {
      setParentId(config?.dragSource?.id!);
      // @ts-ignore
      onRemoveMenuItem();
      // const parentId = config?.dragSource?.id!;
      // const newTreeData = removeFromTargetCategory(treeData, parentId);
      // setTreeData(treeViewHandleByParent(newTreeData));
    }

    return [];
  };

  const onSelectAll = () => {
    if (searchInput.length > 0) {
      setSelectedNodes(filteredCategory);
      if (selectedNodes === filteredCategory) {
        setSelectedNodes([]);
      }
    } else {
      setSelectedNodes(sourceCategory);
      if (selectedNodes === sourceCategory) {
        setSelectedNodes([]);
      }
    }
  };

  const categoryLayoutTargetOnDrop = (
    newTargetTreeData: TNode[],
    config: any
  ) => {
    const { dragSource, dropTargetId } = config;

    // Start Validations
    if (dropTargetId === dragSource?.id) return;
    if (config.dropTarget?.parent === dragSource?.id) return;

    if (dropTargetId) {
      const tempChildList = getChildIdListByParentId(treeData, dragSource.id);
      if (tempChildList.includes(dropTargetId)) return;
    }
    // End Validations

    // Sol menüde seçili olan kategori var ve seçilen kategori sayısı 1 den büyükse
    if (selectedNodes.length > 1) {
      let tempTree = [...newTargetTreeData];

      // Seçili olan kategorilerden mevcut sürüklenen kategoriyi çıkart, çünkü mevcut sürüklenen kategori "treeData" parametresinde ekli olarak geliyor
      const remainingSources = selectedNodes.filter(
        (s) => s.id !== dragSource?.id
      );
      const remainingSourceIds = remainingSources.map((s) => s.id);

      // Seçili olan kategoriler sağ taraftaki mevcut kategorilerde varsa bunları filtrele
      tempTree = tempTree.filter((s) => !remainingSourceIds.includes(s.id));

      // Sol taraftan taşınan tüm kategorilerin parentlarını sürüklenen yerin parenti olarak değiştir
      const checkedList = remainingSources.map((s) => ({
        ...s,
        parent: dropTargetId,
      }));

      // Kategorileri filtreledikten sonra artık eklenen index değeri artık geçersiz olduğundan tekrardan sürüklenen yere eklenen değerin index ini alalım
      const tempDestinationIndex = tempTree.findIndex(
        (s) => s.id === dragSource?.id
      );
      const tempTreeData = [
        ...tempTree.slice(0, (tempDestinationIndex || 0) + 1),
        ...checkedList,
        ...tempTree.slice((tempDestinationIndex || 0) + 1),
      ];

      // Tüm işlem bittikten sonra seçilin olan kategorilerin seçimlerini kaldıralım
      setSelectedNodes([]);
      return setTreeData(treeViewHandleByParent(tempTreeData));
    }

    // Sol kategorilerden sürüklendi ise bu kategorinin alt kategorilerini sağ taraftan kaldır. Bunu ilerde kaldırmamızı isteyecekler. Bu bir hata 27.01.2023 kagancin
    if (dragSource.parent === "mowico_source_category") {
      const tempAllChildListOfParent = getChildIdListByParentId(
        newTargetTreeData,
        dragSource.id
      );

      if (tempAllChildListOfParent.length > 0)
        newTargetTreeData = newTargetTreeData.filter(
          (s) => !tempAllChildListOfParent.includes(s.id)
        );
    }

    return setTreeData(treeViewHandleByParent(newTargetTreeData));
  };
  
  const closeUpgradeModal = () => {
    setIsUpgradeGuide(false);
  };

  return (
    <Content>
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <React.Fragment>
          <ComponentsContainer>
            {!isCompletion && (
              <ScreenSelection
                options={designScreens}
                selectedValue={selectedValue}
                selectChangeHandler={designScreensChange}
              />
            )}
            <div className={classes.Container}>
              <span className={classes.BlockLabel}>Search category</span>
              <Input
                style={{ marginBottom: 16 }}
                id=""
                label=""
                placeholder={"Search Category"}
                value={searchInput}
                onChangeHandler={searchItems}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span className={classes.BlockLabel}>Categories</span>
                <button
                  className={classes.button}
                  onClick={() => onSelectAll()}
                >
                  <span>Select all</span>
                </button>
              </div>

              <Tree
                tree={
                  searchInput.length > 0 ? filteredCategory : sourceCategory
                }
                rootId={"mowico_source_category"}
                render={(node: any) => (
                  <div
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: 4,
                      marginTop: 5,
                      padding: 5,
                    }}
                  >
                    <Checkbox
                      key={`${node.id}`}
                      label={node.text}
                      marginBottom="2px"
                      labelStyle={{ fontWeight: 500, marginLeft: 10 }}
                      checkBoxStyle={{
                        borderColor: "#9fafb6",
                        color: "white",
                        width: 15,
                        height: 15,
                        borderWidth: 1,
                        marginTop: 5,
                      }}
                      labelMarginLeft="5px"
                      checked={isSelectedNode(node.id)}
                      changeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                        checkboxChangeHandler(e, node)
                      }
                      //isCustom={node.IsCustom}
                      //description={node?.Description}
                    />
                  </div>
                )}
                sort={false}
                insertDroppableFirst={false}
                dropTargetOffset={10}
                dragPreviewRender={(monitorProps) => {
                  if (selectedNodes.length > 0)
                    return <MultipleDragPreview dragSources={selectedNodes} />;
                  return <CustomDragPreview monitorProps={monitorProps} />;
                }}
                onDragStart={() => setIsDragging(true)}
                onDragEnd={() => setIsDragging(false)}
                onDrop={categoryLayoutSourceOnDrop}
                canDrop={() => true}
                rootProps={{
                  style: {
                    maxHeight: "400px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  },
                }}
              />
              {/* <button
                  className={classes.button}
                  onClick={() => setMenuItemModalVisible(true)}
              >
                <img style={{alignSelf:'center',}} src={AddIcon} alt="" /> Add Menu Item
              </button> */}
            </div>
          </ComponentsContainer>
          <div className={classes.content}>
            <div className={classes.contentHead}>
              <h4>Category Layout</h4>
              <p>
                You can arrange category items in the menu as you want by drag
                and drop.
              </p>
            </div>
            <div className={classes.upgrade} id={classes.upgradeComponent}>
              {packageHook.isPackageFeaturesLimited && (
                <UpgradeComponent pageType={"Category Layout"} />
              )}
            </div>
            <div className={classes.contentBody}>
              {treeData.length > 0 ? (
                <div className={classes.contentBodyTitle}>
                  <h5>Menu items</h5>
                </div>
              ) : (
                <>
                  {!isDragging && (
                    <img
                      style={{ position: "absolute", top: 250 }}
                      src={dragDrop}
                      alt="more"
                      width={200}
                    />
                  )}
                </>
              )}
              <div className={classes.categories}>
                <div>
                  <Tree
                    tree={treeData}
                    rootId={0}
                    render={(node, { depth, isOpen, onToggle }) => (
                      <CustomNode
                        node={node}
                        depth={depth}
                        isOpen={isOpen}
                        onToggle={onToggle}
                        toggleEditModal={toggleEditModal}
                        onRemoveMenuItem={onRemoveMenuItem}
                      />
                    )}
                    sort={false}
                    insertDroppableFirst={false}
                    dropTargetOffset={10}
                    dragPreviewRender={(monitorProps) => {
                      if (isDragging) return <></>;
                      return <CustomDragPreview monitorProps={monitorProps} />;
                    }}
                    // onDragStart={() => setIsDragging(true)}
                    // onDragEnd={() => setIsDragging(false)}
                    onDrop={categoryLayoutTargetOnDrop}
                    classes={{
                      draggingSource: classes.draggingSource,
                      dropTarget: classes.dropTarget,
                      placeholder: classes.placeholderContainer,
                    }}
                    placeholderRender={(node, { depth }) => (
                      <Placeholder node={node} depth={depth} />
                    )}
                    canDrop={() => true}
                    rootProps={{
                      style: {
                        minHeight: "50px",
                        paddingBottom: isDragging ? 30 : 0,
                      },
                    }}
                  />
                </div>
              </div>
              <Button onClick={() => onSubmitForm()}>Continue</Button>
            </div>
          </div>
          <Modal show={isUpgradeGuide} width="270">
            <UpgradeGuide
              screen="categories"
              setIsOpenModal={closeUpgradeModal}
            />
          </Modal>
          {menuItemModalVisible && (
            <Modal
              backdropClickedHandler={() => discardMenuItemChange()}
              show={true}
              width="448"
            >
              <div
                className={classes.Close}
                onClick={() => discardMenuItemChange()}
              >
                <img src={closeIcon} alt="close_modal" />
              </div>
              <div className={classes.ModalBody}>
                <h4>Menu Item Name</h4>
                <div className={classes.ModalForm}>
                  <Input
                    id=""
                    label=""
                    placeholder="Menu Item"
                    value={menuItemName}
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setMenuItemName(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className={classes.ModalFooter}>
                <div className={classes.ButtonContainer}>
                  <ConfirmButton
                    type="button"
                    className="Ghost"
                    onClick={() => discardMenuItemChange()}
                  >
                    Cancel
                  </ConfirmButton>
                  <ConfirmButton
                    type="button"
                    className="Primary"
                    disabled={!menuItemName || menuItemName?.length <= 0}
                    onClick={() => onItemHandle()}
                  >
                    {oldMenuItemNode === null ? "Add" : "Change"}
                  </ConfirmButton>
                </div>
              </div>
            </Modal>
          )}
          {menuItemRemoveModalVisible && (
            <Modal
              backdropClickedHandler={() => discardMenuItemChange()}
              show={true}
              width="448"
            >
              <div
                className={classes.Close}
                onClick={() => discardMenuItemChange()}
              >
                <img src={closeIcon} alt="close_modal" />
              </div>
              <div className={classes.ModalBody}>
                <p
                  className={classes.ModalBodyText}
                  style={{ marginBottom: 40 }}
                >
                  Do you want to delete this menu item?
                </p>
              </div>
              <div className={classes.ModalFooter}>
                <div className={classes.ButtonContainer}>
                  <ConfirmButton
                    type="button"
                    className="Ghost"
                    width="192px"
                    onClick={() => discardMenuItemChange()}
                  >
                    Cancel
                  </ConfirmButton>
                  <ConfirmButton
                    type="button"
                    className="Primary"
                    width="192px"
                    onClick={() => onRemoveMenuItemHandle()}
                  >
                    Delete
                  </ConfirmButton>
                </div>
              </div>
            </Modal>
          )}
        </React.Fragment>
      </DndProvider>
      <UpgradeModal
        setIsOpenModal={setIsUpgradeModal}
        isOpenModal={isUpgradeModal}
      />
    </Content>
  );
};

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    categories: state.category.categories,
    editCategories: state.category.editCategories,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _saveCategories: (values: { categories: any[]; callback?: Function }) =>
      dispatch(saveCategories(values)),
    _getCategories: () => dispatch(getCategories()),
    _getEditCategories: () => dispatch(getEditCategories()),
    _saveScreenData: (reqObject: ISaveScreenModel) =>
      dispatch(saveScreenData(reqObject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
