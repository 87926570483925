import React, { FC, useState } from 'react';
import styled from 'styled-components';
import MenuEditorAlignment from './MenuEditorAlignment';
import MenuEditorSorting from './MenuEditorSorting';
import IconEditor from './IconEditor';
import Button from 'panel/src/Components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setNavigationMenu } from '../../redux/actions/navigationMenu';
import { filterActiveMenuItems } from '../Sidebar/Sidebar';

type MenuEditorProps = {
  toggleMenu: () => void;
  isTabBar?: boolean;
};

export type TMenuItem = {
  Name?: string;
  IconUrl: string;
  IsEnabled: boolean;
  IsActive: boolean;
  IsSortable: boolean;
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  user-select: none;
  * {
    user-select: none;
  }
`;

const Overlay = styled(Container)`
  background-color: #0f3649;
  opacity: 0.5;
  z-index: 0;
`;

type ModalContainerProps = {
  isIconEditor?: boolean;
  isTabBar?: boolean;
}

const Actions = styled.div<ModalContainerProps>`
  ${props => props.isTabBar && `
    width: 356px;
    margin-left: auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 16px;
    margin-top: 18px;
    button {
      margin: 0 !important;
      border: 2px solid #3196f6;
    }
  `}
`;

const ModalContainer = styled.div<ModalContainerProps>`
  position: relative;
  background-color: #fff;
  width: ${props => props?.isTabBar ? '1006' : props?.isIconEditor ? '654' : '334'}px;
  max-height: 90vh;
  height: auto;
  overflow: hidden;
  border-radius: 4px;
  padding: 16px;
  z-index: 1;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);
`;

const getDefaultMenuItems = (items: TMenuItem[], isTabBar = false) => {
  if (isTabBar) {
    const activeItemSize = items?.filter(filterActiveMenuItems)?.length;
    if (activeItemSize > 5) {
      let activeElementCount = 0;
      return items.map((item, idx) => {
        if (item?.IsEnabled) {
          activeElementCount++;
        }
        if (activeElementCount > 5) {
          return { ...item, IsEnabled: false };
        }
        return item;
      })
    }
  }
  return items;
};

const MenuEditor: FC<MenuEditorProps> = ({ toggleMenu, isTabBar }) => {
  const dispatch = useDispatch();
  const {
    MenuItems: menuItems,
    NameAlignment: menuItemPosition
  } = useSelector((state: any) => state.navigationMenu);

  // Local State
  const initialMenuItems = getDefaultMenuItems(menuItems, isTabBar);
  const [items, setItems] = useState<TMenuItem[]>(initialMenuItems);
  const [showIconEditor, setShowIconEditor] = useState(false);
  const [currentMenuItem, setCurrentMenuItem] = useState<TMenuItem | null>(null);
  const [currentMenuItemIndex, setCurrentMenuItemIndex] = useState<number>(-1);
  const [itemPosition, setItemPosition] = useState(menuItemPosition);

  const toggleIconEditor = () => {
    setShowIconEditor(prev => !prev);
  }

  const editIcon = (index: number) => {
    const currentItem = items.find((_, idx) => idx === index);
    setCurrentMenuItemIndex(index);
    if (currentItem) {
      setCurrentMenuItem(currentItem);
    }
    toggleIconEditor();
  }

  const saveIcon = (IconUrl: string) => {
    if (currentMenuItem) {
      setItems(prev => prev.map((item, idx) => {
        if (idx === currentMenuItemIndex) {
          return { ...item, IconUrl }
        }
        return item;
      }));
    }
    setCurrentMenuItem(null);
    toggleIconEditor();
  }

  const saveMenuItemPosition = (value: string) => {
    setItemPosition(value);
  }

  const applyChanges = () => {
    dispatch(setNavigationMenu({ MenuItems: items, NameAlignment: itemPosition }));
    toggleMenu();
  }

  const isCentered = !isTabBar && itemPosition === 'center';
  const withoutIcon = !isTabBar && itemPosition === 'empty';

  const renderMenuEditor = () => {
    return (
      <ModalContainer isTabBar={isTabBar}>
        {!isTabBar && (
          <MenuEditorAlignment
            value={itemPosition}
            setValue={saveMenuItemPosition}
          />
        )}
        <MenuEditorSorting
          menuItems={items}
          setMenuItems={setItems}
          editIcon={editIcon}
          isTabBar={isTabBar}
          isCentered={isCentered}
          withoutIcon={withoutIcon}
        />
        <Actions isTabBar={isTabBar}>
          <Button
            type="submit"
            style={{ marginTop: "24px" }}
            buttonText="Apply"
            onClick={applyChanges}
          />
          <Button
            type="button"
            style={{ marginTop: "0.5rem" }}
            theme="white"
            buttonText="Cancel"
            onClick={toggleMenu}
          />
        </Actions>
      </ModalContainer>
    )
  }

  const renderIconEditor = () => {
    return currentMenuItem?.IconUrl ? (
      <ModalContainer isIconEditor>
        <IconEditor
          cancel={toggleIconEditor}
          saveIcon={saveIcon}
          itemName={currentMenuItem?.Name || ''}
          selectedIconUrl={currentMenuItem?.IconUrl || ''}
        />
      </ModalContainer>
    ) : null;
  }

  return (
    <Container className="menu-editor">
      {!showIconEditor ? renderMenuEditor() : renderIconEditor()}  
      <Overlay />
    </Container>
  );
};

export default MenuEditor;
