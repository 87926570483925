import React from "react";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
  type: string;
}

const IconShare = ({ width, height, fillColor }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 25"
  >
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M14.558 8.7v1.446h3.926v11.408H5.516V10.146h3.926V8.7H4V23h16V8.7h-5.442zM11.34 3.948V16.4h1.547V3.948l2.133 2.125 1.093-1.089-4-3.984-4 3.984 1.094 1.09 2.133-2.126z"
      transform="translate(-280 -28) translate(280 28)"
    />
  </svg>
);

export default IconShare;
