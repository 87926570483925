import * as Yup from "yup";
import {
  emailErrorMessage,
  urlErrorMessage,
  webServiceRequiredErrorMessage,
} from "panel/src/utils/validationMessage";
import { storeId, httpsUrlPattern } from "panel/src/utils/regex";

interface FormValues {
  selectbox: number;
  url: string;
  ApiUserName: string;
  ApiPassword: string;
  storeId: string;
  GoogleMerchantName: string;
}

const coreShape = Yup.object().shape({
  selectbox: Yup.number(),
  url: Yup.string()
    .url(urlErrorMessage)
    .matches(httpsUrlPattern, urlErrorMessage)
    .required(webServiceRequiredErrorMessage),
  GoogleMerchantEmail: Yup.string()
    .email(emailErrorMessage)
    .required(webServiceRequiredErrorMessage),
  GoogleMerchantID: Yup.string().required(webServiceRequiredErrorMessage),
  GoogleMerchantName: Yup.string().required(webServiceRequiredErrorMessage),
  GoogleMerchantKeyFile: Yup.string().required(webServiceRequiredErrorMessage),
});

const Schema = Yup.lazy<FormValues>((values): any => {
  switch (values.selectbox) {
    case 1:
      return coreShape.concat(
        Yup.object().shape({
          storeId: Yup.string()
            .matches(storeId, webServiceRequiredErrorMessage)
            .required(webServiceRequiredErrorMessage),
        })
      );
    case 21:
      return coreShape.concat(
        Yup.object().shape({
          ApiUserName: Yup.string().required(webServiceRequiredErrorMessage),
          ApiPassword: Yup.string().required(webServiceRequiredErrorMessage),
        })
      );
    case 23:
      return coreShape.concat(
        Yup.object().shape({
          ShopifyKey: Yup.string().required(webServiceRequiredErrorMessage),
        })
      );
    case 28:
      return coreShape.concat(
        Yup.object().shape({
          ApiUserName: Yup.string().required(webServiceRequiredErrorMessage),
          ApiPassword: Yup.string().required(webServiceRequiredErrorMessage),
        })
      );

    default:
      return coreShape;
  }
});

export default Schema;
