import React from "react";
import styled from "styled-components/native";
import ArrowDown from "../../icons/ArrowDown";
import { Option } from "shared-components/src/models/featuresDataModel";

interface IDropdownWeb {
  primary: string;
  items: Option[];
  fontFamily?: any;
}

interface IFieldMedium {
  primary: string;
  fontFamily?: any;
}

const DropdownWeb = ({ primary, items, fontFamily }: IDropdownWeb) => {
  const FieldMedium = styled.Text.attrs({ numberOfLines: 1 })<IFieldMedium>`
    width: 75%;
    font-size: 10.1px;
    font-family: ${(props) => props.fontFamily};
    color: ${(props) => props.primary};
  `;

  const OptionGroup = styled.View`
    flex: 1;
    border-radius: 4px;
    padding: 7px 8px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f7f9f9;
  `;

  return (
    <OptionGroup>
      <FieldMedium
        fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
        primary={primary}
      >
        {items[0].Key}
      </FieldMedium>
      <ArrowDown fillColor={primary!} height="12" width="12" />
    </OptionGroup>
  );
};

export default DropdownWeb;
