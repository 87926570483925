import React from "react";
import ReactDOM from "react-dom";
import Overlay, { OverlayProps } from "react-overlays/Overlay";
import Container from "./Container";
import ButtonContainer from "./ButtonContainer";
import InfoMessage from "./InfoMesage";
import Background from "./Background";
import Card from "./Card";
import Content from "./Content";
import Arrow from "./Arrow";
import Button from "./Button";
import { IComponent } from "panel/src/Components/DragnDrop/Components/index";

export interface IEditCard {
  showEditCard: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
  targetRef?: HTMLDivElement | null;
  editCardPlacement?: OverlayProps["placement"];
  setInfoPopup: Function;
  selectedComponents: (IComponent | undefined)[];
}

const EditCard = ({
  showEditCard,
  containerRef,
  targetRef,
  editCardPlacement,
  setInfoPopup,
  selectedComponents,
}: IEditCard) => {
  return (
    <Container>
      <Overlay
        show={selectedComponents.length === 1 && showEditCard}
        container={containerRef}
        target={targetRef!}
        placement={editCardPlacement || "left"}
      >
        {({ props, arrowProps, placement }) => (
          <Card placement={placement} {...props}>
            <Arrow {...arrowProps} placement={placement} />
            <Content>
              <InfoMessage>
                You can click on edit or trash icons on the slider components to
                change the design of or remove your visuals. To do this, just
                roll your mouse over the slider components.
              </InfoMessage>
            </Content>
            <ButtonContainer>
              <Button onClick={setInfoPopup}>Okay</Button>
            </ButtonContainer>
          </Card>
        )}
      </Overlay>
      {showEditCard &&
        selectedComponents.length === 1 &&
        ReactDOM.createPortal(
          <Background onClick={() => setInfoPopup && setInfoPopup()} />,
          document.getElementById("root") as Element
        )}
    </Container>
  );
};

export default EditCard;
