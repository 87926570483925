import { Platform } from "react-native";
import styled from "styled-components/native";

interface IContainer {
  isInEmulator: boolean;
  isPadding: boolean;
}

export const Container = styled.View<IContainer>`
  ${(props: IContainer) => {
    if (props.isPadding) {
      if (Platform.OS === "web") {
        if (props.isInEmulator) return `padding: 5px 10px;`;
        return `padding: 5px 0;`;
      }
      return `margin: 13px 16px`;
    }
    return `margin-bottom: 13px`;
  }}
`;
