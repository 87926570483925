import { put, call, takeLatest, select, takeEvery } from "redux-saga/effects";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import { SEND_NOTIFICATION } from "../constants/notifications";
import { AxiosResponse } from "axios";
import { ResponseModel } from "../../models/responseModel";
import notification from "panel/src/utils/notification";
import {
  getCategoriesService,
  getEditCategoriesService,
  saveCategoriesService,
} from "../../services/api/design";
import {
  GET_CATEGORIES,
  GET_EDIT_CATEGORIES,
  SAVE_CATEGORIES,
} from "../constants/category";
import { setCategories, setEditCategories } from "../actions/category";
import { convertCategoriesForRequest } from "../../screens/Design/Categories/utils";
import { TNode } from "../../screens/Design/Categories";
import { getUserInfo } from "../actions/userInfo";
import { clearDesignError, setDesignError } from "../actions/DesignError";

function* onSaveCategories(action: {
  payload: {
    categories: TNode[];
    callback?: Function;
  };
  type: string;
}) {
  yield put(startAppLoading(SEND_NOTIFICATION));
  try {
    yield put(clearDesignError());
    const categories = action.payload.categories;
    const categoryRequest = convertCategoriesForRequest(categories);

    const { selectedAppId } = yield select((state) => state.userInfo);
    const { data }: AxiosResponse<ResponseModel> = yield call(
      saveCategoriesService,
      {
        Categories: {
          AppId: selectedAppId,
          JSON: JSON.stringify({
            categories: categoryRequest,
          }),
        },
      }
    );
    if (data && data.ResultCode === 200) {
      yield put(getUserInfo());
      if (action.payload.callback) action.payload.callback?.();
    } else {
      notification.error(data.ResultData);
    }
  } catch (error) {
    const err = error as any;
    yield put(setDesignError(`/Component/api/Component/SaveCategories - ${err?.response?.status}`));
  }
  
  yield put(endAppLoading(SEND_NOTIFICATION));
}

function* onGetCategories() {
  yield put(startAppLoading(GET_CATEGORIES));
  try {
    yield put(clearDesignError());
    const { selectedAppId } = yield select((state) => state.userInfo);

    const request = {
      ApplicationId: selectedAppId,
    };

    const categoriesResponse: AxiosResponse<ResponseModel> = yield call(
      getCategoriesService,
      request
    );

    if (categoriesResponse.data) {
      yield put(setCategories(categoriesResponse.data?.ResultData?.Categories));
    }
  } catch (error) {
    const err = error as any;
    yield put(setDesignError(`/Component/api/Component/GetCategories - ${err?.response?.status}`));
    yield put(setCategories([]));
  }
  yield put(endAppLoading(GET_CATEGORIES));
}

function* onGetEditCategories() {
  yield put(startAppLoading(GET_EDIT_CATEGORIES));
  try {
    yield put(clearDesignError());
    const { selectedAppId } = yield select((state) => state.userInfo);

    const request = {
      ApplicationId: selectedAppId,
    };

    const categoriesResponse: AxiosResponse<ResponseModel> = yield call(
      getEditCategoriesService,
      request
    );

    if (categoriesResponse.data?.ResultData) {
      yield put(
        setEditCategories(
          JSON.parse(categoriesResponse.data?.ResultData?.Json)?.Categories
        )
      );
    }
  } catch (error) {
    const err = error as any;
    yield put(setDesignError(`/Component/api/Component/EditCategories - ${err?.response?.status}`));
    yield put(setEditCategories([]));
  }
  yield put(endAppLoading(GET_EDIT_CATEGORIES));
}

export default function* notificationSaga() {
  yield takeEvery(SAVE_CATEGORIES, onSaveCategories);
  yield takeLatest(GET_CATEGORIES, onGetCategories);
  yield takeLatest(GET_EDIT_CATEGORIES, onGetEditCategories);
}
