import styled from "styled-components";
import { OS } from "panel/src/Components/DragnDrop/Emulator/ToggleOS";

interface IAppIcon {
  activeOS: OS.IOS | OS.Android;
}

export default styled.img<IAppIcon>`
  align-self: center;
  height: 33px;
  width: 33px;
  ${(props) => props.activeOS === OS.IOS && "border-radius: 5px;"}
`;
