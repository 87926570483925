import styled from "styled-components";

interface IToolTip {
  height?: string;
}

const ToolTipContainer = styled.div<IToolTip>`
  display: flex;
  overflow: visible;
  z-index: 9999999999 !important;
  width: 252px !important;
  height: ${(props) => props.height}px;
  border-radius: 4px;
  padding: 12px;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);
  background-color: #3e5e6e;
`;
const ToolTipText = styled.span`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  b {
    font-size: 13px;
    font-weight: bold;
    margin-right: 3px;
  }
  p {
    &:not(:last-child) {
      margin-bottom: 13px;
    }
  }
`;

const InfoIcon = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  transform: translateY(-15%);
`;

const InfoContainer = styled.div`
  position: relative;
`;

export { ToolTipContainer, InfoIcon, ToolTipText, InfoContainer };
