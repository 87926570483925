import { Platform } from "react-native";
import styled from "styled-components/native";
import { dimenFont, dimenWidth } from "../../utils/helper";

interface IButton {
  color?: string;
}

const QuantityWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  width: 100%;
  max-width: ${Platform.OS === "web" ? "80" : dimenWidth(90)}px;
`;

const QtyButton = styled.TouchableOpacity<IButton>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color};
  border-radius: 3px;
  border: 1px solid ${(props) => props.color};
  width: ${Platform.OS === "web" ? "21" : dimenWidth(22)}px;
  height: ${Platform.OS === "web" ? "21" : dimenWidth(22)}px;
  padding-bottom: 2px;
`;

const QtyText = styled.Text`
  font-size: ${Platform.OS === "web" ? "10.5" : dimenFont(14)}px;
  text-align: center;
  font-weight: bold;
`;

const QtyIcon = styled.Text`
  font-size: ${Platform.OS === "web" ? "10.5" : dimenFont(14)}px;
  text-align: center;
  color: #fff;
  text-align: center;
`;

const MinusButton = styled(QtyButton)`
  background: #fff;
`;

const PlusButton = styled(QtyButton)``;

const MinusButtonIcon = styled(QtyIcon)<IButton>`
  color: ${(props) => props.color};
`;

export {
  QuantityWrapper,
  MinusButtonIcon,
  MinusButton,
  PlusButton,
  QtyText,
  QtyIcon,
};
