import React from "react";
import Svg, { Path } from "react-native-svg";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
}

const ArrowDown = ({ width, height, fillColor }: IProps) => (
  <Svg width={width} height={height} viewBox="0 0 24 24">
    <Path
      fill={fillColor}
      fillRule="evenodd"
      d="M12 19a1.984 1.984 0 0 1-1.448-.584l-9.95-9.92A2.063 2.063 0 0 1 .573 5.61a1.985 1.985 0 0 1 2.839-.027L12 14.145l8.587-8.561a1.985 1.985 0 0 1 2.839.027 2.063 2.063 0 0 1-.027 2.884l-9.951 9.921c-.402.4-.927.595-1.448.584z"
    />
  </Svg>
);

export default ArrowDown;
