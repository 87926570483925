import styled from "styled-components";

interface IContainerProps {
  display: "flex" | "none";
}

export default styled.div<IContainerProps>`
  display: ${(props) => props.display};
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: rgba(15, 54, 73, 0.5);
  top: 0;
  left: 0;
  z-index: 104;
`;
