import {
  GET_GENERAL_SETTINGS_START,
  GET_GENERAL_SETTINGS_FINISH,
  GET_GENERAL_SETTINGS_REJECTED,
  SET_GENERAL_SETTINGS_START,
  SET_GENERAL_SETTINGS_FINISH,
  SET_GENERAL_SETTINGS_REJECTED,
  GET_ALL_LANGUAGE_START,
  GET_ALL_LANGUAGE_FINISH,
  GET_ALL_LANGUAGE_REJECTED,
  SET_INSERT_LANGUAGE_START,
  SET_INSERT_LANGUAGE_FINISH,
  SET_INSERT_LANGUAGE_REJECTED,
} from "panel/src/redux/constants/generalSettings";

const initialState = {
  generalSettings: [],
  insertMessage: undefined,
};

const generalSettingsReducer = (state: Object = initialState, action: any) => {
  switch (action.type) {
    case GET_GENERAL_SETTINGS_START:
      return {
        ...state,
      };

    case GET_GENERAL_SETTINGS_FINISH:
      return {
        ...state,
        generalSettings: action.generalSettings,
      };
    case GET_GENERAL_SETTINGS_REJECTED:
      return {
        ...state,
        error: action.error,
      };

    case SET_GENERAL_SETTINGS_START:
      return {
        ...state,
      };
    case SET_GENERAL_SETTINGS_FINISH:
      return {
        ...state,
      };
    case SET_GENERAL_SETTINGS_REJECTED:
      return {
        ...state,
        error: action.error,
      };
    case GET_ALL_LANGUAGE_START:
      return {
        ...state,
      };

    case GET_ALL_LANGUAGE_FINISH:
      return {
        ...state,
        getAllLanguage: action.getAllLanguage,
      };
    case GET_ALL_LANGUAGE_REJECTED:
      return {
        ...state,
        error: action.error,
      };
    case SET_INSERT_LANGUAGE_START:
      return {
        ...state,
      };
    case SET_INSERT_LANGUAGE_FINISH:
      return {
        ...state,
        insertMessage:action.response,
      };
    case SET_INSERT_LANGUAGE_REJECTED:
      return {
        ...state,
        insertMessage: action.error,
      };
    default:
      return state;
  }
};

export default generalSettingsReducer;
