import React, { FC } from 'react';
import styled from 'styled-components';

type EditButtonProps = {
  onClick?: () => void;
};

const Container = styled.div`
  position: absolute;
  width: 40px;
  left: 0;
  top: 0;
  height: 43px;
  padding-left: 21px;
  padding-top: 6px;
`;

const Oval = styled.div<{ size: number; inner?: boolean }>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background-color: #3196f6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${props => props?.inner ? ` 
    border: 2px solid #fff;
  ` : `transform: scale(0.6);`}
`;

const EditButton: FC<EditButtonProps> = ({ onClick }) => {
  return (
    <Container onClick={onClick}>
      <Oval size={18}>
        <Oval size={16} inner/>
      </Oval>
    </Container>
  );
};

export default EditButton;
