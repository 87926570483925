import React, { useState, useRef, useCallback } from "react";
import {
  Linking,
  TouchableOpacity,
  Platform,
  Dimensions,
  FlatList,
} from "react-native";
import Container from "./Container";
import Image from "./Image";
import PaginationContainer from "./PaginationContainer";
import PageDot from "./PageDot";
import { dimenHeight } from "shared-components/src/utils/helper";

interface ISlider {
  Items: {
    Image: {
      ImgUrl: string;
    };
    Link: {
      Type: number;
      LinkTo: string;
      CategoryId?: string;
      CategoryName?: string;
      ProductId?: string;
      ProductName?: string;
      Search?: string;
    };
  }[];
  navigate?: (route: string, params: Record<string, any>) => void;
  showProduct?: Function;
  showWebview?: Function;
  imageResizeType: "cover" | "contain";
}

enum enumLinkTypes {
  Category = 1,
  Product,
  Href,
  WebViewURL,
  Search,
  navigate,
}

const Slider = ({
  Items,
  showProduct,
  showWebview,
  imageResizeType,
  navigate
}: ISlider) => {
  const [currentPage, setCurrentPage] = useState(0);
  const indexRef = useRef(currentPage);
  indexRef.current = currentPage;
  const windowWidth = Math.round(Dimensions.get("window").width);
  const flatListOptimizationProps = {
    initialNumToRender: 0,
    maxToRenderPerBatch: 1,
    removeClippedSubviews: true,
    scrollEventThrottle: 16,
    windowSize: 2,
    getItemLayout: useCallback(
      (_, index) => ({
        index,
        length: windowWidth,
        offset: index * windowWidth,
      }),
      []
    ),
  };
  const onScroll = useCallback((event) => {
    const slideSize = event.nativeEvent.layoutMeasurement.width;
    const index = event.nativeEvent.contentOffset.x / slideSize;
    const roundIndex = Math.round(index);

    const distance = Math.abs(roundIndex - index);

    // Prevent one pixel triggering setCurrentPage in the middle
    // of the transition. With this we have to scroll a bit
    // more to trigger the index change.
    const isNoMansLand = 0.4 < distance;

    if (roundIndex !== indexRef.current && !isNoMansLand) {
      setCurrentPage(roundIndex);
    }
  }, []);

  const handleOnPress = (link: ISlider["Items"][0]["Link"]) => {
    switch (link.Type) {
      case enumLinkTypes.Category:
        link?.CategoryId && navigate && navigate('ProductList', {
          categoryId: link.CategoryId,
          title: link.CategoryName,
        })
        break;
      case enumLinkTypes.Product:
        showProduct && link?.ProductId && showProduct(link.ProductId);
        break;
      case enumLinkTypes.Href:
        Linking.openURL(link.LinkTo);
        break;
      case enumLinkTypes.WebViewURL:
        showWebview && showWebview(link.LinkTo);
        break;
      case enumLinkTypes.Search:
        link?.Search && navigate && navigate('ProductList', {
          title: link.Search,
          search: link.Search,
        });
        break;

      default:
        break;
    }
  };

  return (
    <Container>
      {Platform.OS === "web" ? (
        <Image
          source={{ uri: Items[0].Image.ImgUrl }}
          resizeMode={imageResizeType}
        />
      ) : (
        <FlatList
          style={{ flex: 1 }}
          data={Items}
          renderItem={({ item, index }) => {
            return item.Link.Type ? (
              <TouchableOpacity
                onPress={() => handleOnPress(item.Link)}
                key={index.toString()}
              >
                <Image
                  source={{ uri: item.Image.ImgUrl }}
                  resizeMode={imageResizeType}
                />
              </TouchableOpacity>
            ) : (
              <Image
                source={{ uri: item.Image.ImgUrl }}
                resizeMode={imageResizeType}
                key={index.toString()}
              />
            );
          }}
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item, index) => index.toString()}
          onScroll={onScroll}
          initialScrollIndex={currentPage}
          {...flatListOptimizationProps}
        />
      )}
      <PaginationContainer>
        {Items.map((_, index) => (
          <PageDot
            key={index.toString()}
            style={
              index !== Items.length - 1 && {
                marginRight: Platform.OS === "web" ? 5 : dimenHeight(5),
              }
            }
            isActive={currentPage === index}
          />
        ))}
      </PaginationContainer>
    </Container>
  );
};

export default Slider;
