import { NodeModel } from "@minoru/react-dnd-treeview";
import { default as Categories } from "./Categories";

export type TNode = NodeModel<{
  categoryId: any;
  menuItemId: any;
  hasChild: boolean;
  isRoot?: boolean;
  translatableContents?: any;
}>;

export default Categories;
