import React from "react";
import { Field } from "formik";
import Input from "panel/src/_components/common/Input";
import classes from "./analyticsOption.module.scss";
import ToggleSwitch from "panel/src/_components/common/ToggleSwitch";
import Tooltip from "panel/src/Components/Tooltip";

interface IAnalyticsOptionProps {
  formElements?: any;
  checked: boolean;
  toggleSwitchHandler?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  label: string;
  description: string;
  inputChangeHandler?: any;
  isValid?: boolean;
  disabled?: boolean;
  isTrial?: boolean;
}

const AnalyticsOption = (props: IAnalyticsOptionProps) => {
  const inputs =
    props.formElements &&
    props.formElements.map((formElement: any) => {
      const checkValueIsEmpty = formElement.config.value || "";
      return (
        <div className={classes.analyticsOptionContainer}>
          <Field
            key={formElement.id}
            name={formElement.config.name}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            value={checkValueIsEmpty}
            changeHandler={props.inputChangeHandler}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            label={formElement.config.label}
            component={Input}
            error={!props.isValid && !checkValueIsEmpty}
          />
          {!props.isValid && !checkValueIsEmpty && (
            <p className={classes.errorField}>Cannot be left blank.</p>
          )}
        </div>
      );
    });

  let details = null;
  if (props.checked) {
    details = <div>{inputs}</div>;
  }

  return (
    <React.Fragment>
      <div>
        <div className={classes.Title}>
          <h4>Analytics Option</h4>
          <Tooltip text="Your Google Analytics account will automatically be integrated during the app submission process." />
        </div>
        <p className={classes.Select}>Please Select to Activate </p>
        <div className={classes.ToggleSwitchContainer}>
          <ToggleSwitch
            label={props.label}
            checked={props.checked}
            changeHandler={props.toggleSwitchHandler}
            labelMarginLeft={"16px"}
            marginBottom={"0px"}
            disabled={props.disabled}
          />
          {props.disabled && props.isTrial ? (
            <Tooltip text="Trial account does not support this feature." />
          ) : null}
        </div>
        {props.checked && (
          <p className={classes.Description}>{props.description}</p>
        )}
        {details}
      </div>
    </React.Fragment>
  );
};

export default AnalyticsOption;
