import axios from "panel/src/services/api/api";
import * as paymentOptionsActions from "panel/src/redux/actions/paymentOptions";
import * as modalActions from "panel/src/redux/actions/modal";
import { PAYMENT_OPTIONS_START } from "panel/src/redux/constants/paymentOptions";
import { takeLatest, put, call } from "redux-saga/effects";
import * as userInfoActions from "panel/src/redux/actions/userInfo";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";

function* paymentOptionsProcess({
  paymentObj,
  type,
}: {
  paymentObj: { ApplicationId: string };
  type: string;
}) {
  yield put(startAppLoading("PAYMENT_OPTIONS_PROCESS"));
  try {
    yield put(modalActions.startLoading());

    const token = localStorage.getItem("AUTH_TOKEN");
    const userInfoRequestObj = {
      UserToken: token,
    };

    const userInfoResponse = yield call(
      axios.post,
      "/api/login/GetUserInfo",
      userInfoRequestObj
    );

    const {
      name: userName,
      userId,
      AppList: apps,
      IsTrial: IsTrial,
      IsExpired,
      emailAddress,
      RemainingDays,
      UserPackage
    } = userInfoResponse.data.ResultData;

    yield put(
      userInfoActions.fetchUserInfoSuccess({
        userName,
        userId,
        apps,
        IsTrial,
        IsExpired,
        emailAddress,
        RemainingDays,
        UserPackage
      })
    );
    if (userInfoResponse.data.ResultCode === 101) {
      const selectedApp = apps.find((a: any) => a.IsSelected === true);
      const selectedAppId = selectedApp.AppId;
      const response = yield call(axios.post, "/api/Manage/GetPaymentOptions", {
        ApplicationId: selectedAppId,
      });
      yield put(
        paymentOptionsActions.paymentOptionsSuccess(response.data.ResultData)
      );
    }
  } catch (error) {
    yield put(userInfoActions.fetchUserInfoFail(error));
    yield put(paymentOptionsActions.paymentOptionsRejected(error));
  }
  yield put(endAppLoading("PAYMENT_OPTIONS_PROCESS"));
}

export default function* paymentOptionsProcessSaga() {
  yield takeLatest(PAYMENT_OPTIONS_START, paymentOptionsProcess);
}
