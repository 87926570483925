import { Platform } from "react-native";
import styled from "styled-components/native";
import { colors } from "../../utils/constants";
import { dimenHeight, dimenWidth, dimenFont } from "../../utils/helper";

interface IButton {
  disabled: boolean;
  secondary?: string;
}

export const Button = styled.TouchableOpacity<IButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Platform.OS === "web" ? "8" : dimenWidth(8)}px
    ${Platform.OS === "web" ? "13" : dimenWidth(31)}px;
  border-radius: ${Platform.OS === "web" ? "5" : dimenHeight(3)}px;
  background-color: ${(props) =>
    props.disabled ? colors.lightBlueGrey : props.secondary};
`;

export const Container = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: ${Platform.OS === "web" ? "16" : dimenWidth(16)}px;
  border-bottom-left-radius: ${Platform.OS === "web" ? "20px" : "0"}
  border-bottom-right-radius: ${Platform.OS === "web" ? "20px" : "0"}
`;

interface ILabel {
  fontFamily?: any;
}

export const Label = styled.Text<ILabel>`
  font-size: ${Platform.OS === "web" ? "9" : dimenFont(14)}px;
  color: ${colors.white};
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
`;

interface IPreviousPrice {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  fontFamily?: any;
}

export const PreviousPrice = styled.Text<IPreviousPrice>`
  font-size: ${Platform.OS === "web" ? "12" : dimenFont(12)}px;
  text-decoration: line-through;
  color: ${(props) => props.primary};
  font-family: ${(props) =>
    Platform.OS === "web" ? `${props.fontFamily}` : props.fontFamily!.Regular};
`;

interface IPrice {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  fontFamily?: any;
}

export const Price = styled.Text<IPrice>`
  font-size: ${Platform.OS === "web" ? "11" : dimenFont(14)}px;
  color: ${(props) => props.primary};
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
`;

export const PriceContainer = styled.View`
  flex-direction: column;
`;

export const PriceInfoWrapper = styled.View`
  flex-direction: row;
  flex: 1;
`;

interface IText {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  fontFamily?: any;
}

export const Text = styled.Text<IText>`
  font-size: ${Platform.OS === "web" ? "10" : dimenFont(12)}px;
  color: ${(props) => props.primary};
  font-family: ${(props) =>
    Platform.OS === "web" ? `${props.fontFamily}` : props.fontFamily!.Regular};
`;
