import React, { Component } from "react";
import classes from "./upload.module.scss";
import uploadIcon from "../../../assets/images/upload.svg";
import ErrorField from "../ErrorField";
interface IUploadProps {
  placeholder: string;
  onChange: Function;
  logoName?: string;
  field?: any;
  form?: any;
  errorMessage?: string;
  touched?: boolean;
  label?: string;
  uploadKeyFile?: boolean;
  uploadCSVFile?: boolean;
  containerMarginBottom?: string;
  labelMarginBottom?: string;
  disabled?: boolean;
}

class Upload extends Component<IUploadProps> {
  fileInput: any;
  inputElement: any = null;

  constructor(props: IUploadProps) {
    super(props);
  }
  openFileInput = (e: any) => {
    e.preventDefault();

    this.fileInput.click();
  };

  render() {
    const { placeholder, onChange, logoName, label, disabled } = this.props;

    const fieldName: string = this.props.field.name;

    const isError =
      this.props.form &&
      this.props.form.errors &&
      this.props.form.errors[fieldName] &&
      this.props.form.touched[fieldName];

    const errorMessage = this.props.form.errors
      ? this.props.form.errors[fieldName]
      : undefined;
    const inputClasses = [classes.BlockInput, classes.UploadCustom];
    if (isError) {
      inputClasses.push(classes.Invalid);
    }

    return (
      <div
        className={classes.Container}
        style={{ marginBottom: this.props.containerMarginBottom }}
      >
        {label && <label className={classes.BlockLabel}>{label}</label>}
        <label
          className={classes.Upload}
          style={{ marginBottom: this.props.labelMarginBottom }}
        >
          <input
            ref={(element) => (this.fileInput = element)}
            type="file"
            className={classes.UploadDefault}
            onChange={() => onChange(this.fileInput)}
            accept={
              this.props.uploadCSVFile
                ? ".csv"
                : this.props.uploadKeyFile
                ? ".p12"
                : " image/png, image/jpeg, image/jpg, image/gif, image/svg+xml, "
            }
            spellCheck="false"
            autoComplete="off"
            disabled={disabled}
          />
          <span className={inputClasses.join(" ")}>
            <p
              className={
                !logoName
                  ? classes.UploadCustomPlaceholder
                  : classes.UploadCustomText
              }
            >
              {logoName ? logoName : placeholder}
            </p>
            <img src={uploadIcon} alt="Upload" />
          </span>
          {isError && errorMessage && (
            <ErrorField type={"relative"} text={errorMessage} />
          )}
        </label>
      </div>
    );
  }
}

export default Upload;
