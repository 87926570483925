import React from "react";
import Svg, { Path } from "react-native-svg";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
}

const ArrowUp = ({ width, height, fillColor }: IProps) => (
  <Svg width={width} height={height} viewBox="0 0 13 13">
    <Path
      fill={fillColor}
      fillRule="evenodd"
      d="M5.97 3.244L1.178 8.021c-.38.38-.386 1.001-.013 1.388.374.388.986.393 1.367.014L6.667 5.3 10.8 9.423c.381.38.993.374 1.367-.014.374-.387.368-1.008-.013-1.388L7.364 3.244c-.194-.193-.446-.286-.697-.28-.251-.006-.504.087-.697.28z"
    />
  </Svg>
);

export default ArrowUp;
