import styled from "styled-components";

export default styled.label`
  display: block;
  font-family: Poppins;
  font-size: 14px;
  margin-bottom: 4px;
  line-height: 1.43;
  letter-spacing: 0.03px;
  color: #211065;
`;
