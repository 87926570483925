import { Platform } from "react-native";
import styled from "styled-components/native";
import { dimenFont } from "shared-components/src/utils/helper";
import { colors } from "shared-components/src/utils/constants";

interface ICategoryTitle {
  isactive: number;
  primary?: string;
  fontFamily?: any;
}

export default styled.Text<ICategoryTitle>`
  font-size: ${Platform.OS === "web" ? "9.1" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web" ? props.fontFamily : props.fontFamily!.Bold};
  color: ${(props) => (props.isactive ? props.primary : colors.coolGrey)};
`;
