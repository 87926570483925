import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { setAppId } from "panel/src/redux/actions/manageApp";

interface IReRoute {
  _setApp: (appId: string) => void;
}

interface IRouteParams {
  appId: string;
}

const ReRoute = ({ _setApp }: IReRoute) => {
  const { appId } = useParams<IRouteParams>();
  const history = useHistory();

  useEffect(() => {
    if (history.action === "POP") {
      history.push("/welcome");
      return;
    }

    _setApp(appId);
  }, []);

  return null;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _setApp: (appId: string) => dispatch(setAppId(appId)),
  };
};

export default connect(null, mapDispatchToProps)(ReRoute);
