import styled from "styled-components";

export default styled.div`
  position: absolute;
  top: 0px;
  right: 47px;
  width: 130px;
  height: 19px;
  color: #e7eaec;
  border-radius: 0px 0px 68px 68px;
  background-color: #e7eaec;
`;
