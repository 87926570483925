import React, { Component } from "react";
import classes from "./qr.module.scss";
import { connect } from "react-redux";
import Icon from "panel/src/_components/common/Icon";
import { History } from "history";
import QRCode from "react-qr-code";
import { Formik, Form, Field, FieldArray, FormikValues } from "formik";
import BlockingForm from "panel/src/_components/common/BlockingForm";
import Button from "panel/src/_components/common/Button";
import validationSchema from "./validationSchema";
import { v1 as uuid } from "uuid";
import TextFiled from "panel/src/_components/common/TextField";
import EmailListModel from "panel/src/models/emailListModel";
import { Helmet } from "react-helmet";
import { UpgradeWidget } from "../../../_components/common/UpgradeWidget";
import { sendQrMail } from "../../../redux/actions/Qr";
import { QrModule } from "../../../models/Publish/qr";
import { buildLink } from "../../../utils/deepLink";
import Spinner from "../../../_components/common/Spinner";
import Logo from "../../../_components/primitives/Logo";
import AppleLogo from "../../../assets/images/apple.svg";
import AndroidLogo from "../../../assets/images/android.svg";
import WatchVideoModal from "../../../_components/primitives/WatchVideoModal";
import { YOUTUBE_LINKS } from "../../../Components/YoutubeTutorial";
import Intercom from "../../../Components/Intercom/Intercom";

interface IProps {
  history: History;
  appId: string;
  isMailSend: boolean;
  emailAddress: string;
  _sendQrMail: Function;
  loading: boolean;
}
interface IState {
  isBlocking: boolean;
  emailList: EmailListModel[];
  qrCode: string;
  qrCodeLoading: boolean;
  showModal: boolean;
}

class Qr extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isBlocking: false,
      emailList: [{ id: uuid(), email: this.props.emailAddress }],
      qrCode: "",
      qrCodeLoading: false,
      showModal: false,
    };
  }

  backButton = () => {
    this.setState({ isBlocking: false }, async () => {
      this.props.history.push("/design");
    });
  };

  getInitialValues = () => {
    return {
      emailList: this.state.emailList,
    };
  };

  componentDidUpdate() {
    if (this.props.isMailSend) {
      this.props.history.push("/publish/install-to-store");
    }
  }

  componentDidMount() {
    this.qrCodeGenerate();
  }

  qrCodeGenerate = async () => {
    this.setState({ qrCodeLoading: true });
    const qrLink = await buildLink(
      `https://mowico.com/?mowico=mowico&mowico_app=${this.props.appId}`
    );
    if (qrLink) this.setState({ qrCode: qrLink });
    this.setState({ qrCodeLoading: false });
  };

  onSubmitForm = (values: FormikValues) => {
    let emailAdresses: string[] = [];

    emailAdresses = values.emailList.map((obj: any) => {
      return obj.email.trim();
    });

    this.props._sendQrMail({
      EMailAddreses: emailAdresses,
    });
  };

  toggleShowTutorial = () => {
    this.setState(prev => ({ ...prev, showModal: !prev.showModal }))
  }

  render() {
    const { isBlocking, qrCodeLoading, qrCode } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Mowico Wizard Panel | Qr</title>
        </Helmet>
        <Intercom />
        <div className={classes.contentContainer}>
          <div className={classes.container}>
            <div
              className={classes.backIconContainer}
              onClick={this.backButton}
            >
              <Icon
                className={classes.backIcon}
                type="arrowLeft"
                width="16"
                height="16"
                fillColor="#3196f6"
              />
              <span className={classes.backIconLabel}>Back</span>
            </div>
            <UpgradeWidget />
            <h3 className={classes.heading}>Publish options

            <WatchVideoModal
              watchVideoClickHandler={this.toggleShowTutorial}
              show={this.state.showModal}
              videoUrl={YOUTUBE_LINKS.PUBLISH}
            />
              <span className={classes.tutorialButton} onClick={this.toggleShowTutorial} style={{ cursor: 'pointer' }}>
                <Icon
                  className=""
                  type="watchVideo"
                  fillColor="#e66565"
                  width="16"
                  height="16"
                />
              </span>
            </h3>
            <div className={classes.headInfo}>
              <h4 className={classes.subHeading}>Demo App</h4>
              <p>
                Here’s how you can test and review your demo app on your mobile
                device by scanning the QR code.
              </p>
            </div>
            <div className={classes.cards}>
              <div className={classes.card}>
                <div className={classes.cardHead}>
                  <img src={AppleLogo} alt="Apple" />
                  <h3>IOS</h3>
                </div>
                <div className={classes.cardBody}>
                  <ul>
                    <li>
                      <p>
                        1. Download Apple’s Testflight App to your mobile app
                        from Apple Store.
                      </p>
                    </li>
                    <li>
                      <p>
                        2. Using your iPhone’s camera app, scan this QR code.
                        Testflight app would prompt you to accept and install
                        the Mowico App.
                      </p>
                      {!qrCodeLoading && qrCode && (
                        <QRCode
                          value="https://testflight.apple.com/join/njRd4uoB"
                          size={140}
                        />
                      )}
                    </li>
                    <li>
                      <p>
                        3. Open the Mowico Preview App on Testflight and click
                        the “Scan QR Code” button to review your own app design
                        by scanning the QR code below.
                      </p>
                      {!qrCodeLoading && qrCode && (
                        <QRCode value={qrCode} size={140} />
                      )}
                    </li>
                  </ul>
                  <img
                    src="https://api.mowico.com/images/ios_qr.gif"
                    className={classes.qrGif}
                    alt="Qr | Mowico"
                  />
                </div>
                <Spinner.Default
                  isLoading={qrCodeLoading && !this.props.loading}
                />
              </div>
              <div className={classes.card}>
                <div className={classes.cardHead}>
                  <img
                    src={AndroidLogo}
                    alt="Android"
                    style={{ height: "33px" }}
                  />
                  <h3>ANDROID</h3>
                </div>
                <div className={classes.cardBody}>
                  <ul>
                    <li>
                      <p>
                        1. Download Mowico’s Preview App from Google Play Store.
                      </p>
                    </li>
                    <li>
                      <p>
                        2. Open the Mowico App and click the “Scan QR Code”
                        button to review your own app design by scanning the QR
                        code below.
                      </p>
                      {!qrCodeLoading && qrCode && (
                        <QRCode value={qrCode} size={140} />
                      )}
                    </li>
                  </ul>
                  <img
                    src="https://api.mowico.com/images/android_qr.gif"
                    className={classes.qrGif}
                    alt="Qr | Mowico"
                  />
                </div>
                <Spinner.Default
                  isLoading={qrCodeLoading && !this.props.loading}
                />
              </div>
            </div>
            <div className={classes.bookWidget}>
              <div>
                <Logo isFullLogo={false} />
                <p>Need help with your app launch process?</p>
              </div>
              <a
                href="https://mowico.pipedrive.com/scheduler/1m1d9zu8/meet-with-mowico"
                target="_blank"
                className={classes.bookWidgetButton}
              >
                Book a meeting
              </a>
            </div>

            <div className={classes.emailGroupArea}>
              <Formik
                initialValues={this.getInitialValues()}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={this.onSubmitForm}
              >
                {({ values, submitForm }) => (
                  <Form>
                    <div className={classes.EmailGroup}>
                      <span className={classes.EmailGroupTitle}>
                        Would you like to receive the QR code via e-mail?
                      </span>
                      <FieldArray name="emailList">
                        {({ push, remove }) => (
                          <div>
                            {values.emailList.map((email, index) => {
                              return (
                                <div
                                  className={classes.emailList}
                                  key={email.id}
                                >
                                  <div className={classes.emailInput}>
                                    <Field
                                      name={`emailList[${index}].email`}
                                      placeholder="E-mail"
                                      type="email"
                                      component={TextFiled}
                                    />
                                  </div>
                                  {index !== 0 && (
                                    <div
                                      onClick={() => remove(index)}
                                      className={classes.removeIcon}
                                    >
                                      <Icon
                                        type="remove"
                                        width="14"
                                        height="14"
                                        fillColor="#3196f6"
                                      />
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                            <div
                              className={`${classes.newEmailWrapper} ${
                                values.emailList.length !== 1 &&
                                classes.newEmailMargin
                              }`}
                            >
                              <div
                                className={classes.newEmailContainer}
                                onClick={() => push({ id: uuid(), email: "" })}
                              >
                                <Icon
                                  className={classes.plusIcon}
                                  type="plus"
                                  width="14"
                                  height="14"
                                  fillColor="#3196f6"
                                />
                                <span className={classes.plusIconLabel}>
                                  Add an e-mail
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </FieldArray>
                    </div>
                    <Button
                      disableButton={this.props.loading}
                      type="submit"
                      width="192px"
                      height="40px"
                      className="Primary"
                    >
                      Send QR
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <BlockingForm isBlocking={isBlocking} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    emailAddress: state.userInfo.emailAddress,
    isMailSend: state.askDemo.success,
    loading: state.askDemo.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _sendQrMail: (reqObject: QrModule.QrMail) =>
      dispatch(sendQrMail(reqObject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Qr);
