import React, { ChangeEventHandler, ReactNode } from "react";
import classes from "./checkbox.module.scss";
import CustomFeature from "./CustomFeature";
import UpgradeTooltip from "../../../Components/UpgradeTooltip";

interface ICheckboxProps {
  checked: boolean;
  changeHandler?: ChangeEventHandler;
  name?: string;
  label: string;
  videoElement?: ReactNode;
  disabled?: boolean;
  labelMarginLeft?: string;
  marginBottom?: string;
  isCustom?: boolean;
  description?: string;
  containerMarginLeft?: string;
  labelStyle?:any;
  checkBoxStyle?:any;
  isPackageSupported?:boolean;
}

const Checkbox = ({
  checked,
  changeHandler,
  label,
  name,
  disabled = false,
  labelMarginLeft = "12px",
  marginBottom = "12px",
  isCustom,
  description,
  containerMarginLeft,
  labelStyle,
  checkBoxStyle,
  videoElement,
  isPackageSupported
}: ICheckboxProps) => {
  return (
    <div
      style={{ marginBottom: marginBottom }}
      className={classes.MainContainer}
    >
      <label className={classes.Container}>
        <input
          type='checkbox'
          name={name}
          checked={checked}
          onChange={changeHandler}
          disabled={disabled}
        />
        <span style={{...checkBoxStyle}} className={`${classes.Checkmark} ${checkBoxStyle && classes.CustomCheckmark}`}></span>
      </label>
      <div style={{ marginLeft: containerMarginLeft }}>
        <div
          style={{...labelStyle, marginLeft: labelMarginLeft, display: 'flex' }}
          className={`${classes.Label} ${disabled && classes.DisabledLabel}`}
        >
          {label} {videoElement}
        </div>
        {description && (
          <p
            style={{ marginLeft: labelMarginLeft }}
            className={classes.Description}
          >
            {description}
          </p>
        )}
      </div>
      {isCustom && <CustomFeature />}
      {isPackageSupported===false && <UpgradeTooltip label={label}/>}
    </div>
  );
};

export default Checkbox;
