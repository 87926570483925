import React from "react";
import styled from "styled-components";
import { EHtmlType } from ".";
import { colors } from "../../utils/constants";
import { Container } from "./CustomHtml.styles";
//@ts-ignore
import info from "./info.png";

interface IHtml {
  htmlInfo: {
    HtmlContent: any;
    HtmlType?: "1" | "2";
    Link: {
      Type: number;
      LinkTo: string;
      CategoryId?: string;
      CategoryName?: string;
      ProductId?: string;
      ProductName?: string;
      Search?: string;
    };
  };
  fontFamily?: any;
  colorList?: any;
  isInEmulator?: boolean;
  searchCategory?: Function;
  showProduct?: Function;
  showWebview?: Function;
  searchProducts?: Function;
}

interface IWrapper {
  fontFamily?: any;
  colorList?: any;
  isText: boolean;
  isInEmulator: boolean;
}

const Wrapper = styled.div<IWrapper>`
  min-height: ${(props: IWrapper) => (props.isText ? "0" : "150px")};
  background-color: ${colors.white};
  padding: ${(props: IWrapper) => (props.isText ? "15px" : "0")};
  ${(props: IWrapper) =>
    props.isInEmulator ? "box-shadow: 0px 0px 5px rgba(15, 54, 73, 0.25);" : ""}
  font-size: 11px;
  font-family: ${(props: IWrapper) => props.fontFamily};

  * {
    color: ${(props: IWrapper) =>
      props.colorList ? props.colorList?.[0].colorCode : "#000"};
  }

  iframe {
    font-family: ${(props: IWrapper) => props.fontFamily};
    width: 100%;
    body {
      margin: 0;
    }
  }

  h1 {
    font-size: 17px;
  }

  h2 {
    font-size: 14px;
  }

  ol,
  ul {
    padding-left: 3em;
  }

  blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }

  a {
    text-decoration: underline;
    color: ${(props: IWrapper) =>
      props.colorList ? props.colorList?.[0].colorCode : "#000"};
  }

  img,
  iframe,
  video {
    max-width: 100%;
  }

  iframe {
    width: 100%;
  }
`;

const getBlobURL = (code: string, type: string) => {
  const blob = new Blob([code], { type });
  return URL.createObjectURL(blob);
};

const CustomHtml = ({
  fontFamily,
  colorList,
  isInEmulator,
  htmlInfo,
}: IHtml) => {
  const { HtmlContent, HtmlType = EHtmlType.Text } = htmlInfo;
  const htmlRender = () => {
    if (HtmlContent) {
      if (HtmlType === EHtmlType.Text) {
        //@ts-ignore
        return <div dangerouslySetInnerHTML={{ __html: HtmlContent }} />;
      }

      return (
        //@ts-ignore
        <iframe src={getBlobURL(HtmlContent, "text/html")} frameborder="0" />
      );
    }

    if (isInEmulator) return "<html />";
    //@ts-ignore
    return <img src={info} style={{ width: "100%" }} />;
  };

  return (
    <Container
      isPadding={HtmlType === EHtmlType.Text}
      isInEmulator={isInEmulator === true}
    >
      <Wrapper
        isText={HtmlType === EHtmlType.Text}
        fontFamily={fontFamily}
        colorList={colorList}
        isInEmulator={isInEmulator === true}
      >
        {htmlRender()}
      </Wrapper>
    </Container>
  );
};
export default CustomHtml;
