import React from "react";
import classes from "./pushNotificationOptions.module.scss";
import PushNotificationOption from "./PushNotificationOption";
import Tooltip from "panel/src/Components/Tooltip";
import infoIcon from "panel/src/assets/images/information.svg";

interface IPushProps {
  pushNotificationOptions: any;
  checkBoxChangeHandler: (e: any, pushNotificationOptionKey: number) => void;
  fileHandler?: any;
  disabled?: boolean;
  isTrial?: boolean;
}

const PushNotificationOptions = (props: IPushProps) => {
  const pushNotificationOptions = props.pushNotificationOptions.map(
    (pushNotificationOption: any) => {
      return (
        <div className={classes.CheckboxContainer}>
          <PushNotificationOption
            key={pushNotificationOption.key}
            label={pushNotificationOption.label}
            checked={pushNotificationOption.isChecked}
            name={pushNotificationOption.name}
            checkBoxChangeHandler={(e: any) =>
              props.checkBoxChangeHandler(e, pushNotificationOption.key)
            }
            description={pushNotificationOption.description}
            disabled={props.disabled}
            //onDone={props.fileHandler.bind(this, pushNotificationOption.key)}
          />
          {props.disabled && props.isTrial ? (
            <Tooltip text="Trial account does not support this feature." />
          ) : null}
        </div>
      );
    }
  );

  return (
    <div className={classes.PushNotificationOptions}>
      <div className={classes.Title}>
        <h4>Push Notification Options</h4>
        <Tooltip text="Your notification settings will automatically be activated during the app submission process." />
      </div>
      {props.isTrial && (
        <div className={classes.infoArea}>
          <img src={infoIcon} className={classes.infoIcon} />
          <p className={classes.infoDescription}>
            Push notification and analytics options are only available for
            <a href="https://mowico.com/pricing/" target="_blank">
              paid
            </a>
            users.
          </p>
        </div>
      )}
      <div className={classes.ToggleSwitchContainer}>
        {pushNotificationOptions}
      </div>
    </div>
  );
};

export default PushNotificationOptions;
