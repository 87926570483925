import React, { Component } from "react";
import { Platform } from "react-native";
import styled from "styled-components/native";
import { ColorListData } from "shared-components/src/models/colorListData";
import HtmlView from "./HtmlView";
import {
  dimenFont,
  dimenHeight,
  dimenWidth,
} from "shared-components/src/utils/helper";
import { colors, shadow } from "shared-components/src/utils/constants";

interface IProps {
  title?: string;
  description: string;
  colorList?: ColorListData[];
  fontFamily?: any;
  viewMoreText?: string;
  viewLessText?: string;
}

interface IState {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  isDetailShown?: boolean;
  fontFamily?: any;
}

const Container = styled.View`
  ${Platform.OS === "web" ? `padding: 5px 10px;` : `margin: 8px 16px;`}
`;

const Wrapper = styled.View`
  background-color: ${colors.white};
  padding: ${Platform.OS === "web" ? "12" : dimenWidth(16)}px;
  box-shadow: 0px 0px 5px rgba(15, 54, 73, 0.25);
`;

const BlockGroup = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

const Field = styled.Text<IState>`
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
  color: ${(props) => props.primary};
`;


const ViewMoreText = styled.Text<IState>`
  font-size:  10.1px ;
  font-family: ${(props) =>`${props.fontFamily} Bold`};
  color: ${(props) => props.secondary};
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

class DescriptionField extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      primary: "#0F3649",
      secondary: "#3196F6",
      tertiary: "#0F3649",
      isDetailShown: false,
    };
  }

  render() {
    const { title, description, colorList, fontFamily } = this.props;
    const { primary, secondary } = this.state;

    const viewLessTextHandle = this.props.viewLessText
      ? this.props.viewLessText
      : "View less";
    const viewMoreTextHandle = this.props.viewMoreText
      ? this.props.viewMoreText
      : "View more";

    return (
      <Container>
        <Wrapper style={Platform.OS === "web" ? {} : shadow}>
          <BlockGroup>
            <Field
              style={{
                marginBottom: Platform.OS === "web" ? "8" : dimenHeight(16),
              }}
              primary={colorList ? colorList[0].colorCode : primary}
              fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
            >
              {title ? title : "Description"}
            </Field>
          </BlockGroup>
          <HtmlView
            fontFamily={fontFamily}
            colorList={colorList}
            description={description}
            height={100}
            secondary={secondary}
            viewLessText={viewLessTextHandle}
            viewMoreText={viewMoreTextHandle}
          />
          {Platform.OS === "web" &&
              <ViewMoreText
                  fontFamily={fontFamily}
                  secondary={colorList ? colorList[1].colorCode : secondary}
              >
                {viewMoreTextHandle}
              </ViewMoreText>
          }
        </Wrapper>
      </Container>
    );
  }
}

export default DescriptionField;
