import styled from "styled-components";

interface ITitle {
  fontFamily?: string;
}

export default styled.span<ITitle>`
  font-family: ${(props) =>
    props.fontFamily ? `${props.fontFamily} Bold` : "Helvetica Neue Bold"};
  font-size: 10.5px;
  color: #0f3649;
`;
