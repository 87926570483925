import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import classes from "./subNavigation.module.scss";
import checkIcon from "../../../assets/images/check-icon.svg";
import { SubNavigationType } from "../../../utils/enums/subNavigationType";
import { AppList } from "../../../models/getUserInfoResponseModel";
import ContactContainer, {
  CONTACT_SCREENS,
} from "panel/src/screens/Design/ContactContainer";

interface IProps {
  isSetAppStepCompleted: boolean;
  isSetConfigSettingsCompleted: boolean;
  isSetPaymentOptionsCompleted: boolean;
  isSetEngagementOptionsCompleted: boolean;
  isConfigTab?: boolean;
}
interface IState {
  subNavigationType: SubNavigationType;
}

class SubNavigation extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      subNavigationType: !props.isSetAppStepCompleted
        ? SubNavigationType.WebServiceControl
        : SubNavigationType.ConfigSettings,
    };
  }

  onClickHandler = (e: any, subNavigationType: SubNavigationType) => {
    const {
      isSetAppStepCompleted,
      isSetConfigSettingsCompleted,
      isSetPaymentOptionsCompleted,
      isSetEngagementOptionsCompleted,
    } = this.props;
    switch (subNavigationType) {
      case 1:
        this.state.subNavigationType === subNavigationType &&
          !isSetAppStepCompleted &&
          e.preventDefault();
        break;
      case 2:
        !isSetAppStepCompleted && e.preventDefault();
        break;
      case 3:
        !isSetConfigSettingsCompleted && e.preventDefault();
        break;
      case 4:
        !isSetPaymentOptionsCompleted && e.preventDefault();
        break;
    }
    this.setState({ subNavigationType });
  };
  render() {
    const {
      isSetAppStepCompleted,
      isSetConfigSettingsCompleted,
      isSetPaymentOptionsCompleted,
      isSetEngagementOptionsCompleted,
      isConfigTab,
    } = this.props;
    return (
      <ul className={classes.ManageSubNavigation}>
        <li className={classes.NavigationItem}>
          <NavLink
            className={`${
              !isSetAppStepCompleted ? classes.valid : classes.disable
            } `}
            to={`${
              !isSetAppStepCompleted
                ? "/manage/web-service-control/begin"
                : "/manage/config-settings"
            }`}
            activeClassName={!isSetAppStepCompleted ? classes.active : ""}
            onClick={(e) =>
              this.onClickHandler(e, SubNavigationType.WebServiceControl)
            }
          >
            1. Integration
            {isSetAppStepCompleted ? (
              <img src={checkIcon} alt="check-icon" />
            ) : null}
          </NavLink>
        </li>
        <li>
          <NavLink
            className={isSetAppStepCompleted ? classes.valid : classes.disable}
            to="/manage/config-settings"
            activeClassName={classes.active}
            onClick={(e) =>
              this.onClickHandler(e, SubNavigationType.ConfigSettings)
            }
          >
            2. Configuration
            {isSetConfigSettingsCompleted ? (
              <img src={checkIcon} alt="check-icon" />
            ) : null}
          </NavLink>
        </li>
        <li>
          <NavLink
            className={
              isSetConfigSettingsCompleted ? classes.valid : classes.disable
            }
            to="/manage/payment-options"
            activeClassName={classes.active}
            onClick={(e) =>
              this.onClickHandler(e, SubNavigationType.PaymentOptions)
            }
          >
            3. Payment Services
            {isSetPaymentOptionsCompleted ? (
              <img src={checkIcon} alt="check-icon" />
            ) : null}
          </NavLink>
        </li>
        <li>
          <NavLink
            className={
              isSetPaymentOptionsCompleted ? classes.valid : classes.disable
            }
            to="/manage/engagement-options"
            activeClassName={classes.active}
            onClick={(e) =>
              this.onClickHandler(e, SubNavigationType.EngagementOptions)
            }
          >
            4. Engagement Services
            {this.props.isSetEngagementOptionsCompleted ? (
              <img src={checkIcon} alt="check-icon" />
            ) : null}
          </NavLink>
        </li>
        {isConfigTab && (
          <li style={{ display: "flex", justifyContent: "center" }}>
            <ContactContainer screen={CONTACT_SCREENS.CONFIG} />
          </li>
        )}
      </ul>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isSetAppStepCompleted: state.userInfo.selectedApp.IsStep1Completed,
    isSetConfigSettingsCompleted: state.userInfo.selectedApp.IsStep2Completed,
    isSetPaymentOptionsCompleted: state.userInfo.selectedApp.IsStep3Completed,
    isSetEngagementOptionsCompleted:
      state.userInfo.selectedApp.IsStep4Completed,
  };
};

export default connect(mapStateToProps)(SubNavigation);
