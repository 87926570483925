import styled from "styled-components";

interface IMenuWrapper {}

export default styled.div<IMenuWrapper>`
  position: absolute;
  right: 2px;
  top: 7px;
  display: flex;
  color: white;
`;
