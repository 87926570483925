import React from "react"

const LeftIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12">
    <g fill="none" fill-rule="evenodd">
      <path d="M-2-4h20v20H-2z"/>
      <path fill="currentColor" d="M11.542 5.167c.345 0 .625.373.625.833 0 .452-.27.82-.606.833H1.125C.78 6.833.5 6.46.5 6c0-.452.27-.82.606-.833h10.436Zm3.333-5c.345 0 .625.373.625.833 0 .452-.27.82-.606.833H1.125C.78 1.833.5 1.46.5 1c0-.452.27-.82.606-.833h13.769Zm-6.667 10c.346 0 .625.373.625.833 0 .452-.269.82-.605.833H1.125C.78 11.833.5 11.46.5 11c0-.452.27-.82.606-.833h7.102Z"/>
    </g>
  </svg>
)

export default LeftIcon;