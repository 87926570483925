import React from "react";

interface IEngageProps {
  width: string;
  height: string;
  fillColor: string;
  type: string;
}

const Engage = ({ width, height, fillColor }: IEngageProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M24 .432v19.121c0 .174-.095.321-.242.39a.38.38 0 0 1-.19.044.43.43 0 0 1-.268-.087c-3.48-2.661-7.807-4.23-12.323-4.507l2.141 5.08c.527 1.23-.13 2.704-1.45 3.293a2.835 2.835 0 0 1-1.123.234 2.53 2.53 0 0 1-.95-.173 2.278 2.278 0 0 1-1.278-1.24l-3.032-7.272C2.332 15.124 0 12.862 0 10.105 0 7.22 2.548 4.878 5.683 4.878h3.705c5.12 0 10.06-1.699 13.912-4.784a.424.424 0 0 1 .45-.052c.155.078.25.225.25.39z"
    />
  </svg>
);

export default Engage;
