import React, { Component } from "react";
import { Platform } from "react-native";
import styled from "styled-components/native";
import { ColorListData } from "shared-components/src/models/colorListData";
import Dropdown from "./Dropdown";
import {
  dimenFont,
  dimenHeight,
  dimenWidth,
} from "shared-components/src/utils/helper";
import { colors, shadow } from "shared-components/src/utils/constants";

interface IProps {
  data: {
    description: string;
    dispatchOptions: { label: string; value: string }[];
    periodOptions: { label: string; value: string }[];
  };
  isBuyable: boolean;
  colorList?: ColorListData[];
  onSubscribe: Function;
  fontFamily?: any;
}

interface IState {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  disabled?: boolean;
  SubscriptionInfo?: {
    SelectedPeriod?: string;
    SelectedDispatchCounts?: string;
  };
  fontFamily?: any;
}

const Container = styled.View`
  ${Platform.OS === "web" ? `padding: 5px 10px;` : `margin: 8px 16px;`}
`;

const Wrapper = styled.View`
  ${Platform.OS === "web"
    ? `display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2.9px;
    padding: 11px 16px;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(15, 54, 73, 0.25);`
    : `
    border-radius: ${dimenWidth(4)}px;
    background-color: ${colors.white};
    padding: ${dimenWidth(16)}px;
    flex-direction: column;
    `}
`;

const BlockGroup = styled.View`
  ${Platform.OS === "web"
    ? `width: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    margin-bottom: 6px;
    background-color: white;
    align-items: center;`
    : `
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    `}
  margin-bottom: ${Platform.OS === "web" ? "6" : dimenHeight(8)}px;
`;

const Field = styled.Text<IState>`
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web" ? props.fontFamily : props.fontFamily!.Bold};
  ${Platform.OS === "web" &&
  "font-weight: 900; align-items: center; margin-bottom: 8px;"}
  color: ${(props: IState) => props.primary};
`;

const FieldBanner = styled.Text<IState>`
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web" ? props.fontFamily : props.fontFamily!.Regular};
  ${Platform.OS === "web" &&
  "font-weight: 500; align-items: center; margin-bottom: 8px;"}
  ${Platform.OS !== "web" && `margin-vertical: ${dimenWidth(8)}px;`}
  color: ${(props: IState) => props.primary};
`;

const FieldBold = styled.Text<IState>`
  ${Platform.OS === "web" ? "width: 50%;" : "flex: 1;"}
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web" ? props.fontFamily : props.fontFamily!.Bold};
  ${Platform.OS === "web" && "font-weight: bold; align-items: center;"}
  color: ${(props: IState) => props.primary};
`;

const Subscribe = styled.TouchableOpacity<IState>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: ${Platform.OS === "web" ? "4" : `${dimenWidth(4)}`}px;
  height: ${Platform.OS === "web" ? "24px" : `${dimenWidth(34)}px`};
  background-color: ${(props: IState) =>
    props.disabled ? "#cfd7db" : props.secondary};
`;

const FieldSubscribe = styled.Text<IState>`
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web" ? props.fontFamily : props.fontFamily!.Bold};
  ${Platform.OS === "web" && "font-weight: bold; align-items: center;"}
  color:white;
`;

class SubscriptionOption extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      primary: "#0F3649",
      secondary: "#3196F6",
      tertiary: "#0F3649",
      SubscriptionInfo: { SelectedPeriod: "", SelectedDispatchCounts: "" },
    };
  }

  render() {
    const { primary, secondary, tertiary } = this.state;
    const { data, colorList, fontFamily } = this.props;

    return (
      <Container>
        <Wrapper style={Platform.OS === "web" ? {} : shadow}>
          <BlockGroup>
            <Field
              fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
              primary={colorList ? colorList[0].colorCode : primary}
            >
              Subscription
            </Field>
          </BlockGroup>
          <BlockGroup>
            <FieldBanner
              fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
              primary={colorList ? colorList[0].colorCode : primary}
            >
              {data.description}
            </FieldBanner>
          </BlockGroup>
          <BlockGroup>
            <FieldBold
              fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
              primary={colorList ? colorList[0].colorCode : primary}
            >
              Period
            </FieldBold>
            <Dropdown
              fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
              items={data.periodOptions}
              onValueChange={(value: string) =>
                this.setState((prevState) => ({
                  SubscriptionInfo: {
                    ...prevState.SubscriptionInfo,
                    SelectedPeriod: value,
                  },
                }))
              }
              primary={colorList ? colorList[0].colorCode : primary!}
            />
          </BlockGroup>
          <BlockGroup>
            <FieldBold
              fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
              primary={colorList ? colorList[0].colorCode : primary}
            >
              For
            </FieldBold>
            <Dropdown
              fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
              items={data.dispatchOptions}
              onValueChange={(value: string) =>
                this.setState((prevState) => ({
                  SubscriptionInfo: {
                    ...prevState.SubscriptionInfo,
                    SelectedDispatchCounts: value,
                  },
                }))
              }
              primary={colorList ? colorList[0].colorCode : primary!}
            />
          </BlockGroup>
          <BlockGroup>
            <Subscribe
              onPress={() =>
                this.props.onSubscribe(this.state.SubscriptionInfo)
              }
              disabled={!this.props.isBuyable}
              secondary={colorList ? colorList[1].colorCode : secondary}
            >
              <FieldSubscribe
                fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                primary={colorList ? colorList[0].colorCode : primary}
              >
                Subscribe
              </FieldSubscribe>
            </Subscribe>
          </BlockGroup>
        </Wrapper>
      </Container>
    );
  }
}

export default SubscriptionOption;
