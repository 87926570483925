import {
  GET_GENERAL_SETTINGS_START,
  GET_GENERAL_SETTINGS_FINISH,
  GET_GENERAL_SETTINGS_REJECTED,
  SET_GENERAL_SETTINGS_START,
  SET_GENERAL_SETTINGS_FINISH,
  SET_GENERAL_SETTINGS_REJECTED,
  GET_ALL_LANGUAGE_START,
  GET_ALL_LANGUAGE_FINISH,
  GET_ALL_LANGUAGE_REJECTED,
  SET_INSERT_LANGUAGE_START,
  SET_INSERT_LANGUAGE_FINISH,
  SET_INSERT_LANGUAGE_REJECTED,
} from "panel/src/redux/constants/generalSettings";
import { GetGeneralSettingsRequestModel } from "../../models/Design/generalSettings/getGeneralSettingsRequestModel";
import { GetGeneralSettingsResponseModel } from "../../models/Design/generalSettings/getGeneralSettingsResponse";
import { SetGeneralSettingsRequestModel } from "../../models/Design/generalSettings/setGeneralSettingsRequestModel";
import {SetInsertLanguageRequestModel} from "../../models/Design/generalSettings/setInsertLanguageRequestModel";
import {GetAllLanguageRequestModel} from "../../models/Design/generalSettings/getAllLanguageRequestModel";
import {GetAllLanguageResponseModel} from "../../models/Design/generalSettings/getAllLanguageResponse";

export const getGeneralSettingsStart = (requestObj: GetGeneralSettingsRequestModel) => {
  return {
    type: GET_GENERAL_SETTINGS_START,
    requestObj,
  };
};

export const getGeneralSettingsFinish = (generalSettings: GetGeneralSettingsResponseModel["ResultData"]) => {
  return {
    type: GET_GENERAL_SETTINGS_FINISH,
    generalSettings,
  };
};

export const getGeneralSettingsRejected = (error: string) => ({
  type: GET_GENERAL_SETTINGS_REJECTED,
  error,
});

export const setGeneralSettingsStart = (generalObj: SetGeneralSettingsRequestModel) => {
  return {
    type: SET_GENERAL_SETTINGS_START,
    generalObj,
  };
};

export const setGeneralSettingsFinish = () => {
  return {
    type: SET_GENERAL_SETTINGS_FINISH,
  };
};

export const setGeneralSettingsRejected = (error: string) => ({
  type: SET_GENERAL_SETTINGS_REJECTED,
  error,
});


export const getAllLanguageStart = (requestObj: GetAllLanguageRequestModel) => {
  return {
    type: GET_ALL_LANGUAGE_START,
    requestObj,
  };
};

export const getAllLanguageFinish = (getAllLanguage: GetAllLanguageResponseModel["ResultData"]) => {
  return {
    type: GET_ALL_LANGUAGE_FINISH,
    getAllLanguage,
  };
};

export const getAllLanguageRejected = (error: string) => ({
  type: GET_ALL_LANGUAGE_REJECTED,
  error,
});


export const setInsertLanguageStart = (generalObj: SetInsertLanguageRequestModel) => {
  return {
    type: SET_INSERT_LANGUAGE_START,
    generalObj,
  };
};

export const setInsertLanguageFinish = (response: string) => {
  return {
    type: SET_INSERT_LANGUAGE_FINISH,
    response,
  };
};

export const setInsertLanguageRejected = (error: string) => ({
  type: SET_INSERT_LANGUAGE_REJECTED,
  error,
});

