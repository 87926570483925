import React from 'react';

interface IProps {
    width: string,
    height: string,
    fillColor: string,
    type: string
}

const Search = ({ width, height, fillColor }: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
        <path fill={fillColor} fillRule="evenodd" d="M12.797 12.008l-.308-.305c1.087-1.267 1.748-2.91 1.748-4.71 0-4-3.243-7.243-7.244-7.243-4 0-7.243 3.243-7.243 7.243s3.243 7.244 7.243 7.244c1.8 0 3.441-.66 4.708-1.746l.308.306v.882l3.741 3.571 1.5-1.5-3.568-3.742h-.885zm-5.804 0c-2.77 0-5.014-2.244-5.014-5.015 0-2.769 2.244-5.014 5.014-5.014s5.015 2.245 5.015 5.014c0 2.77-2.246 5.015-5.015 5.015z" />
    </svg>
);

export default Search;
