import styled from "styled-components";

export default styled.div`
  height: 512px;
  width: 683px;
  background: #fff;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: column;
  padding: 24px 19px;
  margin: auto;
  position: relative;
`;
