import styled from "styled-components";

interface IButtonProps {
  isPrimary?: boolean;
}

export default styled.a<IButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 192px;
  height: 48px;
  background-color: ${(props) => (props.isPrimary ? "#fff" : "#3196f6")};
  border: 2px solid #3196f6;
  border-radius: 4px;
  margin-top: 40px;
  margin-right: 15px;
  cursor: pointer;
`;
