import styled from "styled-components";
import { OS } from "../../ToggleOS";

interface IBackground {
  currentOS: OS;
}

export default styled.img<IBackground>`
  width: 100%;
  height: 100%;
  ${(props) =>
    props.currentOS === OS.IOS &&
    "border-radius: 25px 25px 0px 0px; -webkit-border-radius: 25px 25px 0px 0px;"}
`;
