import styled from "styled-components";

interface IOSText {
  isActiveOS: boolean;
}

export default styled.span<IOSText>`
  font-size: 14px;
  color: ${(props) => (props.isActiveOS ? "#3e5e6e" : "#e7eaec")};
  margin-top: 8px;
`;
