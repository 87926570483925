import {
  DEMO_INFO,
  DEMO_INFO_FAIL,
  DEMO_INFO_SUCCESS,
  RESET_DEMO_INFO,
} from "panel/src/redux/constants/demoInfo";

const initialState = {
  data: false,
  loading: false,
  success: false,
};

const demoInfoReducer = (state: Object = initialState, action: any) => {
  switch (action.type) {
    case DEMO_INFO:
      return {
        ...state,
        loading: true,
      };
    case DEMO_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.data,
      };
    case DEMO_INFO_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case RESET_DEMO_INFO:
      return {
        data: false,
        loading: false,
        success: false,
        error: false,
      };
    default:
      return state;
  }
};

export default demoInfoReducer;
