import React from "react";
import Container from "./Container";
import Background from "./Background";
import linearBackground from "panel/src/assets/images/emulated-app/degrade.png";
import WhiteBackground from "./WhiteBackground";
import { OS } from "../../ToggleOS";

interface IProductDetailHeaderBackground {
  isTransparent: boolean;
  currentOS: OS;
}

const ProductDetailHeaderBackground = ({
  isTransparent,
  currentOS,
}: IProductDetailHeaderBackground) => {
  return (
    <Container currentOS={currentOS}>
      {isTransparent ? (
        <Background
          src={linearBackground}
          currentOS={currentOS}
          alt="degrade-header"
        />
      ) : (
        <WhiteBackground currentOS={currentOS} />
      )}
    </Container>
  );
};

export default ProductDetailHeaderBackground;
