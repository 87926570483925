import React from "react";
import classes from "./Steps.module.scss";
import Step from "../Step";

const steps = (props: any) =>
  props.steps.map((step: any, index: number) => (
    <div className={classes.Steps} key={index}>
      <Step step={step} />
    </div>
  ));

export default steps;
