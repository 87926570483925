import React from "react";
import Image from "./Image";
import Container from "./Container";
import PlusIcon from "../../../../../../../assets/images/blue-plus.svg";

const Hover = () => {
  return (
    <Container>
      <Image src={PlusIcon} />
    </Container>
  );
};

export default Hover;
