import React from "react";
import styles from "./MultipleDragPreview.module.scss";

export const MultipleDragPreview = (props: any) => {
  return (
    <div>
      <div className={styles.root} data-testid="custom-drag-preview">
        {props.dragSources.map((node: any, index: number) => (
          <div key={`${index}_${node.id}`} className={styles.item}>
            <div className={styles.label}>{node.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
