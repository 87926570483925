import React, { Component } from "react";
import classes from "./createContentLanguage.module.scss";
import Icon from "panel/src/_components/common/Icon";
import Button from "panel/src/_components/common/Button";
import * as H from "history";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cmsActions from "panel/src/redux/actions/cmsKeys";
import { GetCmsKeyValuesRequestModel } from "panel/src/models/Manage/getCmsKeyValuesRequestModel";
import {
  GetCmsKeyValuesResponseModel,
  CMSKeyValue,
} from "panel/src/models/Manage/getCmsKeyValuesResponseModel";
import ContentTable from "panel/src/_components/common/ContentTable";
import { ContentTableDataModel } from "panel/src/models/Manage/contentTableDataModel";
import EngageNavigation from "panel/src/_components/primitives/EngageNavigation";
import Search from "panel/src/_components/common/Search";

interface MatchParams {
  rLanguageId: number;
  rLanguageName: string;
  cLanguageId: number;
  cLanguageName: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  cmsActions: any;
  appId: string;
  cmsKeyValues: GetCmsKeyValuesResponseModel["ResultData"];
}

// from typings
interface RouteComponentProps<P> {
  match: match<P>;
  location: H.Location;
  history: H.History;
  staticContext?: any;
}

interface match<P> {
  params: P;
  isExact: boolean;
  path: string;
  url: string;
}

interface IState {
  languageContents: ContentTableDataModel[];
  hasChange: boolean;
  isShowDiscardModal: boolean;
  isShowConfirmModal: boolean;
  totalPageCount: number;
  pageIndex: number;
  searchText: string;
}

class CreateContentLanguage extends Component<IProps, IState> {
  contentTable: any;
  constructor(props: IProps) {
    super(props);
    this.state = {
      languageContents: [],
      hasChange: false,
      isShowDiscardModal: false,
      isShowConfirmModal: false,
      totalPageCount: 0,
      pageIndex: 0,
      searchText: "",
    };
  }

  backButton = () => {
    const { history } = this.props;
    history!.goBack();
  };
  timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  saveSettings = async (dataset: ContentTableDataModel[]) => {
    const { appId, match } = this.props;
    let KeyValues = {};
    dataset.map((data) => {
      let key = data.Key;
      let value = data.Value;
      KeyValues = { ...KeyValues, [key]: value };
    });

    await this.props.cmsActions.updateCmsKeyValuesStart({
      ApplicationId: appId,
      LanguageId: match.params.cLanguageId,
      KeyValues: KeyValues,
    });
    this.setState(
      { isShowConfirmModal: true, hasChange: false, languageContents: [] },
      async () => {
        this.contentTable && this.contentTable.resetTable();
        await this.timeout(500);
        return this.getLanguageContents(
          this.state.pageIndex,
          this.state.searchText
        );
      }
    );
  };

  componentDidMount = async () => {
    await this.getLanguageContents(0, "");
  };

  getLanguageContents = async (pageIndex: number, searchText: string) => {
    const languageId = this.props.match.params.cLanguageId;
    if (!languageId || !this.props.appId) return;
    const requestObj: GetCmsKeyValuesRequestModel = {
      ApplicationId: this.props.appId,
      LanguageId: languageId,
      LanguageRefId: this.props.match.params.rLanguageId,
      PageIndex: pageIndex,
      SearchCriteria: searchText,
    };
    await this.props.cmsActions.getCmsKeyValuesStart(requestObj);
  };

  searchHandler = async (searchText: string) => {
    this.setState(
      { languageContents: [], searchText, pageIndex: 0 },
      async () => {
        await this.getLanguageContents(this.state.pageIndex, searchText);
      }
    );
  };

  componentWillReceiveProps = (prevProps: IProps, prevState: IState) => {
    let cmsKeyValues: ContentTableDataModel[] = [];
    let totalPageCount = 0;
    if (prevProps.cmsKeyValues) {
      prevProps.cmsKeyValues.CmsKeyValues &&
        prevProps.cmsKeyValues.CmsKeyValues.map(
          (item: CMSKeyValue, index: number) => {
            cmsKeyValues.push({
              KId: item.KeyId,
              Key: item.KeyName,
              Name: item.KeyRefName,
              Value: item.KeyValue,
            });
          }
        );
      totalPageCount = prevProps.cmsKeyValues.TotalPageCount;
    }
    this.setState({ languageContents: cmsKeyValues, totalPageCount });
  };

  nextPage = (pageNumber: number) => {
    this.setState({ languageContents: [], pageIndex: pageNumber }, async () => {
      await this.getLanguageContents(pageNumber, this.state.searchText);
    });
  };

  searchChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: e.target.value }, () => {
      this.searchHandler(this.state.searchText);
    });
  };

  searchClickHandler = () => {
    this.searchHandler(this.state.searchText);
  };

  keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      this.searchHandler(this.state.searchText);
    }
  };

  render() {
    const { languageContents } = this.state;

    return (
      <React.Fragment>
        <EngageNavigation />
        <div className={classes.contentContainer}>
          <div className={classes.EngageSubRouteContainer}>
            <div
              className={classes.backIconContainer}
              onClick={this.backButton}
            >
              <Icon
                className={classes.backIcon}
                type="arrowLeft"
                width="16"
                height="16"
                fillColor="#3196f6"
              />
              <span className={classes.backIconLabel}>Back</span>
            </div>
            <h3 className={classes.Heading}>Create a New Language</h3>
            <p className={classes.Info}>
              You may create a new language for your mobile application by
              simply selecting a referral language, and the new language you
              like to add.
            </p>
            <div className={classes.searchContainer}>
              <div className={classes.search}>
                <Search
                  changeHandler={this.searchChangeHandler}
                  value={this.state.searchText}
                  placeholder="Search"
                  searchClickHandler={this.searchClickHandler}
                  keyDownHandler={this.keyDownHandler}
                />
              </div>
            </div>
            <div className={classes.tableWrapper}>
              {!!this.props.match.params.rLanguageId &&
                !!languageContents.length && (
                  <ContentTable
                    labels={[
                      this.props.match.params.rLanguageName.toString(),
                      this.props.match.params.cLanguageName.toString(),
                    ]}
                    initialDataset={languageContents}
                    ref={(element) => (this.contentTable = element)}
                    saveCallback={this.saveSettings}
                    updateCallback={() => this.setState({ hasChange: true })}
                    totalPageCount={this.state.totalPageCount}
                    nextPage={this.nextPage}
                    currentPage={this.state.pageIndex}
                  />
                )}
            </div>
          </div>
          <div className={classes.SaveButtonContainer}>
            <Button
              width="160px"
              height="40px"
              type="button"
              disableButton={!this.state.hasChange}
              onClick={() => this.contentTable.save()}
            >
              Save and Update
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    cmsActions: bindActionCreators(cmsActions, dispatch),
  };
};

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    cmsKeyValues: state.cmsKeys.cmsKeyValues,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateContentLanguage);
