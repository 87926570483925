import React, { Component } from "react";
import classes from "./editContentLanguage.module.scss";
import Icon from "panel/src/_components/common/Icon";
import Button from "panel/src/_components/common/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cmsActions from "panel/src/redux/actions/cmsKeys";
import { GetCmsKeyValuesRequestModel } from "panel/src/models/Manage/getCmsKeyValuesRequestModel";
import {
  GetCmsKeyValuesResponseModel,
  CMSKeyValue,
} from "panel/src/models/Manage/getCmsKeyValuesResponseModel";
import ContentTable from "panel/src/_components/common/ContentTable";
import { ContentTableDataModel } from "panel/src/models/Manage/contentTableDataModel";
import Modal from "panel/src/_components/common/Modal";
import close from "../../../../../assets/images/close.svg";
import tick from "../../../../../assets/images/tick.svg";
import Search from "panel/src/_components/common/Search";

interface IProps {
  cmsActions: any;
  appId: string;
  cmsKeyValues: GetCmsKeyValuesResponseModel["ResultData"];
  cLanguageId: number;
  rLanguageId: number;
  cLanguageName: string;
  rLanguageName: string;
  onPressGoBack: Function;
}

interface IState {
  languageContents: ContentTableDataModel[];
  hasChange: boolean;
  isShowDiscardModal: boolean;
  isShowConfirmModal: boolean;
  totalPageCount: number;
  pageIndex: number;
  searchText: string;
}

class EditContentLanguageContent extends Component<IProps, IState> {
  contentTable: any;
  constructor(props: IProps) {
    super(props);
    this.state = {
      languageContents: [],
      hasChange: false,
      isShowDiscardModal: false,
      isShowConfirmModal: false,
      totalPageCount: 0,
      pageIndex: 0,
      searchText: "",
    };
  }

  backButton = () => {
    this.props.onPressGoBack();
  };

  timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  saveSettings = async (dataset: ContentTableDataModel[]) => {
    const { appId, cLanguageId } = this.props;
    let KeyValues = {};
    dataset.map((data) => {
      let key = data.Key;
      let value = data.Value;
      KeyValues = { ...KeyValues, [key]: value };
    });

    await this.props.cmsActions.updateCmsKeyValuesStart({
      ApplicationId: appId,
      LanguageId: cLanguageId,
      KeyValues: KeyValues,
    });
    this.setState(
      { isShowConfirmModal: true, hasChange: false, languageContents: [] },
      async () => {
        this.contentTable && this.contentTable.resetTable();
        await this.timeout(500);
        return this.getLanguageContents(
          this.state.pageIndex,
          this.state.searchText
        );
      }
    );
  };

  componentDidMount = () => {
    this.getLanguageContents(0, "");
  };

  getLanguageContents = async (pageIndex: number, searchText: string) => {
    const { rLanguageId, cLanguageId } = this.props;

    const requestObj: GetCmsKeyValuesRequestModel = {
      ApplicationId: this.props.appId,
      LanguageId: cLanguageId,
      LanguageRefId: rLanguageId,
      PageIndex: pageIndex,
      SearchCriteria: searchText,
    };
    await this.props.cmsActions.getCmsKeyValuesStart(requestObj);
  };

  componentWillReceiveProps = (prevProps: IProps, prevState: IState) => {
    let cmsKeyValues: ContentTableDataModel[] = [];

    let totalPageCount = 0;
    if (prevProps.cmsKeyValues !== this.props.cmsKeyValues) {
      prevProps.cmsKeyValues.CmsKeyValues &&
        prevProps.cmsKeyValues.CmsKeyValues.map(
          (item: CMSKeyValue, index: number) => {
            cmsKeyValues.push({
              KId: item.KeyId,
              Key: item.KeyName,
              Name: item.KeyRefName,
              Value: item.KeyValue,
            });
          }
        );
      totalPageCount = prevProps.cmsKeyValues.TotalPageCount;
    }
    this.setState({ languageContents: cmsKeyValues, totalPageCount });
  };

  cancelSettings = () => {
    this.setState({ isShowDiscardModal: true });
  };

  closeDiscardModal = async () => {
    this.setState({ isShowDiscardModal: false });
  };

  discardChanges = async () => {
    this.contentTable.resetTable();
    this.setState({ hasChange: false, isShowDiscardModal: false });
  };

  closeConfirmModal = async () => {
    this.setState({ isShowConfirmModal: false });
  };

  searchHandler = async (searchText: string) => {
    this.setState(
      { languageContents: [], searchText, pageIndex: 0 },
      async () => {
        await this.getLanguageContents(this.state.pageIndex, searchText);
      }
    );
  };

  nextPage = (pageNumber: number) => {
    this.setState({ languageContents: [], pageIndex: pageNumber }, async () => {
      await this.getLanguageContents(pageNumber, this.state.searchText);
    });
  };

  searchChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: e.target.value }, () => {
      this.searchHandler(this.state.searchText);
    });
  };

  searchClickHandler = () => {
    this.searchHandler(this.state.searchText);
  };

  keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      this.searchHandler(this.state.searchText);
    }
  };

  render() {
    const { languageContents } = this.state;
    return (
      <React.Fragment>
        <div className={classes.contentContainer}>
          <div className={classes.EngageSubRouteContainer}>
            <div
              className={classes.backIconContainer}
              onClick={this.backButton}
            >
              <Icon
                className={classes.backIcon}
                type="arrowLeft"
                width="16"
                height="16"
                fillColor="#3196f6"
              />
              <span className={classes.backIconLabel}>Back</span>
            </div>
            <h3 className={classes.Heading}>Edit Content</h3>
            <p className={classes.Info}>
              You may edit the language contents shown in your mobile
              application by simply selecting any language below.
            </p>
            <div className={classes.searchContainer}>
              <div className={classes.search}>
                <Search
                  changeHandler={this.searchChangeHandler}
                  value={this.state.searchText}
                  placeholder="Search"
                  searchClickHandler={this.searchClickHandler}
                  keyDownHandler={this.keyDownHandler}
                />
              </div>
            </div>
            <div className={classes.tableWrapper}>
              {!!this.props.rLanguageName && !!languageContents.length && (
                <ContentTable
                  labels={[
                    this.props.rLanguageName.toString(),
                    this.props.cLanguageName.toString(),
                  ]}
                  initialDataset={languageContents}
                  ref={(element) => (this.contentTable = element)}
                  saveCallback={this.saveSettings}
                  updateCallback={() => this.setState({ hasChange: true })}
                  totalPageCount={this.state.totalPageCount}
                  nextPage={this.nextPage}
                  currentPage={this.state.pageIndex}
                />
              )}
            </div>
          </div>
          <div className={classes.ButtonGroup}>
            <div className={classes.DiscardButton}>
              {this.state.hasChange && (
                <Button
                  width="160px"
                  height="40px"
                  type="button"
                  className="Ghost"
                  onClick={this.cancelSettings}
                >
                  Discard
                </Button>
              )}
            </div>
            <Button
              width="160px"
              height="40px"
              type="button"
              disableButton={!this.state.hasChange}
              onClick={() => this.contentTable.save()}
            >
              Save and Update
            </Button>
          </div>
        </div>
        <Modal show={this.state.isShowDiscardModal} width="448">
          <div className={classes.Close} onClick={this.closeDiscardModal}>
            <img src={close} />
          </div>
          <div className={`${classes.ModalBody} ${classes.ModalBodyConfirm}`}>
            <span className={classes.ModalBodyText}>
              Are you sure you want to discard all your changes? <br /> Your
              changes will be lost.
            </span>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type="button"
                className="Ghost"
                width="192px"
                onClick={this.closeDiscardModal}
              >
                Continue
              </Button>
              <Button
                type="button"
                className="Primary"
                width="192px"
                onClick={this.discardChanges}
              >
                Discard
              </Button>
            </div>
          </div>
        </Modal>
        <Modal show={this.state.isShowConfirmModal} width="448">
          <div className={classes.Close} onClick={this.closeConfirmModal}>
            <img src={close} />
          </div>
          <div className={`${classes.ModalBody} ${classes.ModalBodyConfirm}`}>
            <img
              className={classes.ConfirmIcon}
              src={tick}
              width={48}
              height={48}
            />
            <span className={classes.ModalBodyText}>
              The changes you made are successfully saved and updated in your
              application.
            </span>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type="button"
                className="Primary"
                width="192px"
                onClick={this.closeConfirmModal}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    cmsActions: bindActionCreators(cmsActions, dispatch),
  };
};

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    cmsKeys: state.cmsKeys.cmsKeys,
    cmsKeyValues: state.cmsKeys.cmsKeyValues,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditContentLanguageContent);
