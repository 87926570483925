export enum ComponentType {
  BrandList = 1,
  SocialMedia = 2,
  SmallBanner = 3,
  Rayon = 4,
  Slider = 5,
  LargeCategoryView = 6,
  CategorySlider = 7,
  HomeSlider = 8,
  Comment = 9,
  ProductPrice = 10,
  Installment = 11,
  Description = 12,
  Properties = 13,
  Features = 14,
  Header = 15,
  InstagramShowcase = 16,
  LinkListView = 17,
  Text = 19,
  ProductSubscription = 20,
  GalleryView = 21,
  ListView = 22,
  SmallListView = 23,
  ProductAvailability = 24,
  SmallCategoryView = 25,
  MediumBanner = 26,
  LargeBanner = 27,
  ProductSlider = 28,
  MediumListView = 29,
  MetaField = 30,
  CustomHtml = 31,
}
