import React from "react";
import Container from "./Container";
import Wrapper from "./Wrapper";
import Text from "./Text";
import Remove from "./Remove";
import Icon from "../../_components/common/Icon";

interface IDynamicToolsBar {
  push:
    | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
    | undefined;
  remove:
    | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
    | undefined;
  isAddable: boolean;
  isRemovable: boolean;
}

const DynamicToolsBar = ({
  push,
  remove,
  isAddable,
  isRemovable,
}: IDynamicToolsBar) => {
  return (
    <Container align={isAddable ? "space-between" : "flex-end"}>
      {isAddable && (
        <Wrapper onClick={push}>
          <Icon type="addIcon" fillColor="#3196f6" width="16" height="16" />
          <Text style={{ marginLeft: 10 }}>Add New</Text>
        </Wrapper>
      )}
      {isRemovable && (
        <Wrapper onClick={remove}>
          <Remove>Remove</Remove>
        </Wrapper>
      )}
    </Container>
  );
};

export default DynamicToolsBar;
