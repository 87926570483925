import {
    GET_CMS_KEYS_START,
    GET_CMS_KEYS_FINISH,
    GET_CMS_KEYS_REJECTED,
    GET_CMS_KEY_VALUES_START,
    GET_CMS_KEY_VALUES_FINISH,
    GET_CMS_KEY_VALUES_REJECTED,
    UPDATE_CMS_KEY_VALUES_START,
    UPDATE_CMS_KEY_VALUES_FINISH,
    UPDATE_CMS_KEY_VALUES_REJECTED,
} from "panel/src/redux/constants/cmsKeys";

import { GetCmsKeysResponseModel } from "../../models/Manage/getCmsKeysResponseModel";
import { GetCmsKeyValuesResponseModel } from "../../models/Manage/getCmsKeyValuesResponseModel";
import { GetCmsKeyValuesRequestModel } from "../../models/Manage/getCmsKeyValuesRequestModel";
import { UpdateCmsKeyValuesRequstModel } from "../../models/Manage/updateCmsKeyValuesRequestModel";
import { UpdateCmsKeyValuesResponseModel } from "../../models/Manage/updateCmsKeyValuesResponseModel";

export const getCmsKeysStart = () => {
    return {
        type: GET_CMS_KEYS_START,
    };
};

export const getCmsKeysFinish = (cmsKeys: GetCmsKeysResponseModel["ResultData"]) => {
    return {
        type: GET_CMS_KEYS_FINISH,
        cmsKeys,
    };
};

export const getCmsKeysRejected = (error: string) => ({
    type: GET_CMS_KEYS_REJECTED,
    error,
});


export const getCmsKeyValuesStart = (requestObj: GetCmsKeyValuesRequestModel) => {
    return {
        type: GET_CMS_KEY_VALUES_START,
        requestObj
    };
};

export const getCmsKeyValuesFinish = (cmsKeyValues: GetCmsKeyValuesResponseModel["ResultData"]) => {
    return {
        type: GET_CMS_KEY_VALUES_FINISH,
        cmsKeyValues,
    };
};

export const getCmsKeyValuesRejected = (error: string) => ({
    type: GET_CMS_KEY_VALUES_REJECTED,
    error,
});

export const updateCmsKeyValuesStart = (requestObj: UpdateCmsKeyValuesRequstModel) => {
    return {
        type: UPDATE_CMS_KEY_VALUES_START,
        requestObj
    };
};

export const updateCmsKeyValuesFinish = (responseObj: UpdateCmsKeyValuesResponseModel["ResultData"]) => {
    return {
        type: UPDATE_CMS_KEY_VALUES_FINISH,
        responseObj,
    };
};

export const updateCmsKeyValuesRejected = (error: string) => ({
    type: UPDATE_CMS_KEY_VALUES_REJECTED,
    error,
});
