import * as Yup from "yup";
import { urlPattern } from "panel/src/utils/regex";
import { ELinkTypes } from "../../../../../utils/enums/design";

export default Yup.object().shape({
  Link: Yup.object().shape({
    Type: Yup.number().required("Link type can't be blank."),
    LinkTo: Yup.string().when("Type", {
      is: (val) => val === ELinkTypes.WebViewURL || val === ELinkTypes.Href,
      then: Yup.string()
        .required("URL can't be blank.")
        .url("Please enter a valid URL")
        .matches(urlPattern, "Please enter a valid URL"),
    }),
    Search: Yup.string().when("Type", {
      is: (val) => val === ELinkTypes.Search,
      then: Yup.string()
        .required("Search can't be blank.")
        .min(2, "Search word must be at least 3 characters"),
    }),
    CategoryId: Yup.string().when("Type", {
      is: (val) => val === ELinkTypes.Category,
      then: Yup.string().required("Category can't be blank."),
    }),
    CategoryName: Yup.string().when("Type", {
      is: (val) => val === ELinkTypes.Category,
      then: Yup.string().required("Category can't be blank."),
    }),
    ProductId: Yup.string().when("Type", {
      is: (val) => val === ELinkTypes.Product,
      then: Yup.string().required("Please enter a valid product"),
    }),
    ProductName: Yup.string().when("Type", {
      is: (val) => val === ELinkTypes.Product,
      then: Yup.string().required(
        "Please enter a valid product. You must select a product from the list."
      ),
    }),
  }),
});
