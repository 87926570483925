import React, { Dispatch, FC, SetStateAction, useState } from "react";
import Container from "./Container";
import classes from "./TootipGuide.module.scss";
import Button from "../../_components/common/Button";
import UpgradeModal from "../UpgradeModal";
import close from "../../assets/images/close.svg";

interface IContactModal {
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  screen: string;
}

const UpgradeGuide: FC<IContactModal> = ({ setIsOpenModal, screen }) => {
  const [isOpenUpgradeModal, setIsOpenUpgradeModal] = useState<boolean>(false);
  const onOpenModal = () => {
    setIsOpenUpgradeModal(true);
    closeModal();
  };
  const closeModal = () => {
    setIsOpenModal((prev) => !prev);
  };
  const renderComponent = () => {
    switch (screen) {
      case "components":
        return (
          <span className={classes.info}>
            You have reached your component limit. You can continue to try
            components but your new components will not be saved.
            <a
              className={classes.infoText}
              href="https://mowico.com/pricing/"
              target="_blank"
            >
              {" "}
              Upgrade
            </a>{" "}
            to increase your component limit.
          </span>
        );
      case "categories":
        return (
          <span className={classes.info}>
            You can keep trying the categories
            <a
              className={classes.infoText}
              href="https://mowico.com/pricing/"
              target="_blank"
            >
              {" "}
              Upgrade
            </a>{" "}
            to Growth to use your plan.
          </span>
        );
      default:
        return (
          <span className={classes.info}>
            You can keep trying the custom {screen}.
            <a
              className={classes.infoText}
              href="https://mowico.com/pricing/"
              target="_blank"
            >
              {" "}
              Upgrade
            </a>{" "}
            to Growth to use your plan.
          </span>
        );
    }
  };

  return (
    <Container>
      <div className={classes.Close} onClick={closeModal}>
        <img src={close} />
      </div>
      {renderComponent()}
      <div className={classes.btnContainer}>
        <Button
          marginTop="15px"
          type="button"
          className="Ghost"
          width="60%"
          onClick={closeModal}
          disabled={false}
          borderColor="#fff"
        >
          Don't Show
        </Button>

        <Button
          marginTop="15px"
          type="button"
          className="Primary"
          width="30%"
          onClick={onOpenModal}
          disabled={false}
        >
          Okay
        </Button>
      </div>
      <UpgradeModal
        setIsOpenModal={setIsOpenUpgradeModal}
        isOpenModal={isOpenUpgradeModal}
      />
    </Container>
  );
};

export default UpgradeGuide;
