import React from "react";

interface IProps {
    width: string;
    height: string;
    fillColor: string;
    type: string;
}

const Publish = ({ width, height, fillColor }: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
        <g fill="none" fillRule="evenodd" opacity=".4">
            <path d="M0 0H20V20H0z" />
            <path fill={fillColor} d="M4.648 15.346c.624.628.397 1.827-.51 2.741-1.049 1.085-3.6 1.77-3.713 1.799L0 20l.113-.428c.029-.114.794-2.741 1.786-3.712.538-.542 1.219-.856 1.814-.856.368-.029.68.114.935.342zM20 .576c0 5.008-.978 8.765-5.734 13.164.068 2.368-.64 4.263-2.158 5.786l-.472.474-2.934-4.331-.713-.2c-1.693-.476-3.012-1.806-3.473-3.503l-.199-.73L0 8.258l.506-.474c1.518-1.523 3.44-2.233 5.8-2.132C10.727.915 14.47-.033 19.46.001H20v.575zm-6.153 3.178c-1.356-.077-2.518 1.006-2.596 2.4-.039 1.354 1.007 2.515 2.402 2.592 1.395.077 2.518-1.006 2.596-2.4.039-1.354-1.007-2.515-2.402-2.592z" />
        </g>
    </svg>
);

export default Publish;
