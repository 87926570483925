export const GET_CMS_KEYS_START: string = "GET_CMS_KEYS_START";
export const GET_CMS_KEYS_FINISH: string = "GET_CMS_KEYS_FINISH";
export const GET_CMS_KEYS_REJECTED: string = "GET_CMS_KEYS_REJECTED";

export const GET_CMS_KEY_VALUES_START: string = "GET_CMS_KEY_VALUES_START";
export const GET_CMS_KEY_VALUES_FINISH: string = "GET_CMS_KEY_VALUES_FINISH";
export const GET_CMS_KEY_VALUES_REJECTED: string = "GET_CMS_KEY_VALUES_REJECTED";

export const UPDATE_CMS_KEY_VALUES_START: string = "UPDATE_CMS_KEY_VALUES_START";
export const UPDATE_CMS_KEY_VALUES_FINISH: string = "UPDATE_CMS_KEY_VALUES_FINISH";
export const UPDATE_CMS_KEY_VALUES_REJECTED: string = "UPDATE_CMS_KEY_VALUES_REJECTED";

