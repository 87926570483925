import { Platform } from "react-native";
import styled from "styled-components/native";

export default styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${Platform.OS === "web" && `flex-shrink: 0 !important;`}
`;
