import { Platform } from "react-native";
import styled from "styled-components/native";

export default styled.ScrollView`
  display: flex;
  flex-direction: row;
  padding-horizontal: 8px;
  margin-bottom: 3px;

  ${Platform.select({
    web: `overflow-x: hidden;`,
  })};
`;
