import React from "react";
import { connect } from "react-redux";
import Icon from "../Icon";
import Container from "./Container";
import Content from "./Content";
import TextContainer from "./TextContainer";
import Text from "./Text";
import Separator from "./Separator";

import sort from "panel/src/assets/images/emulated-app/sort.svg";
import filter from "panel/src/assets/images/emulated-app/filter.svg";

interface IProductTools {
  fontFamily?: string;
  platformId?: number;
}

const noFilterPlatforms = [23, 27, 36];

const ProductTools = ({ fontFamily, platformId }: IProductTools) => {
  const showFilter = () => {
    let show = true;

    if (platformId) {
      show = noFilterPlatforms.includes(platformId);
    }

    return show;
  };

  return (
    <Container>
      <Content isHalf={showFilter()}>
        <TextContainer>
          <Icon src={sort} alt="menu" />
          <Text fontFamily={fontFamily}>Sort</Text>
        </TextContainer>
        {!showFilter() && (
          <>
            <Separator />
            <TextContainer>
              <Icon src={filter} alt="menu" />
              <Text fontFamily={fontFamily}>Filter</Text>
            </TextContainer>
          </>
        )}
      </Content>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    platformId: state.userInfo.selectedApp.PlatformId,
  };
};

export default connect(mapStateToProps, null)(ProductTools);
