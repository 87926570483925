import React, { Component } from "react";
import EditableCell from "./EditableCell";
import classes from "./contentTable.module.scss";
import Icon from "../Icon";
import Pagination from "../Pagination";
import { ContentTableDataModel } from "../../../models/Manage/contentTableDataModel";

interface IProps {
  labels: String[];
  initialDataset: ContentTableDataModel[];
  saveCallback: Function;
  updateCallback: Function;
  totalPageCount: number;
  nextPage: Function;
  currentPage: number;
}

interface IState {
  dataset: ContentTableDataModel[];
  currentPage: number;
}

class ContentTable extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dataset: props.initialDataset,
      currentPage: props.currentPage + 1,
    };
  }

  static defaultProps = {
    saveCallback: () => null,
    updateCallback: () => null,
  };

  updateFieldValue = (newValue: string, updateIndex: number) => {
    const { dataset } = this.state;
    const { updateCallback } = this.props;

    const updatedDataset = dataset.map(
      (data: ContentTableDataModel, index: number) => {
        if (index === updateIndex) {
          return { ...data, Value: newValue };
        }

        return data;
      }
    );

    this.setState((prevState) => ({
      ...prevState,
      dataset: updatedDataset,
    }));
    updateCallback();
  };

  resetTable = () => {
    const { initialDataset } = this.props;

    this.setState({ dataset: initialDataset });
  };

  save = () => {
    const { saveCallback, initialDataset } = this.props;
    const { dataset } = this.state;
    const updatedDataset = dataset.filter(
      (data: ContentTableDataModel) => !initialDataset.includes(data)
    );

    saveCallback(updatedDataset);
  };

  nextPage = (pageNumber: number) => {
    this.setState({ currentPage: pageNumber });
    this.props.nextPage(pageNumber - 1);
  };

  render() {
    const { labels, totalPageCount } = this.props;
    const { dataset } = this.state;

    return (
      <div>
        <table className={classes.contentTable}>
          <thead>
            <tr>
              {labels.map((label, index) => (
                <th
                  key={`table_${index}`}
                  className={
                    labels.length - 1 === index ? classes.tableCol : ""
                  }
                >
                  <div className={classes.tableHeader}>
                    <span>{label}</span>
                    <div className={classes.filter}>
                      <Icon
                        type="arrowUp"
                        width="7"
                        height="7"
                        fillColor="#0f3649"
                      />
                      <Icon
                        type="arrowDown"
                        width="7"
                        height="7"
                        fillColor="#9fafb6"
                      />
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataset.map((data: ContentTableDataModel, index: number) => (
              <tr key={data.KId}>
                <td title={data.Name}>
                  <span>{data.Name}</span>
                </td>
                {Object.hasOwnProperty.call(data, "referenceValue") && (
                  <td title={data.referenceValue}>
                    <EditableCell initialValue={data.referenceValue} readOnly />
                  </td>
                )}
                <td title={data.Value}>
                  <EditableCell
                    initialValue={data.Value}
                    onUpdateValue={(newValue: string) =>
                      this.updateFieldValue(newValue, index)
                    }
                    showUpdateInfo={
                      !Object.hasOwnProperty.call(data, "referenceValue")
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {totalPageCount > 1 ? (
          <Pagination
            pages={totalPageCount}
            nextPage={this.nextPage}
            currentPage={this.state.currentPage}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default ContentTable;
