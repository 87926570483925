import styled from "styled-components";
import { OS } from "./ToggleOS";
import { NavigationEnum } from "./Components";

interface IEmulatorContent {
  OS: number;
  height: string;
  navigationType?: number;
  splashScreen?: any;
  isProductDetails?: boolean;
  isProductList?: boolean;
}

export default styled.div<IEmulatorContent>`
  width: 240px;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => getBorderRadius(props)};
  -webkit-border-radius: ${(props) => getBorderRadius(props)};
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const getBorderRadius = (props: IEmulatorContent) => {
  let radiusPixel = props.OS === OS.IOS ? 25 : 20;

  let borderRadius = "0px";

  if (
    props.navigationType === NavigationEnum.drawerNavigation ||
    props.isProductList
  ) {
    borderRadius = `0px 0px ${radiusPixel}px ${radiusPixel}px`;
  } else if (props.splashScreen) {
    borderRadius = `${radiusPixel}px`;
  } else if (props.isProductDetails && props.OS === OS.IOS) {
    borderRadius = `${radiusPixel}px ${radiusPixel}px 0px 0px`;
  }

  return borderRadius;
};
