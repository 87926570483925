import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classes from "./paymentOption.module.scss";
import Button from "panel/src/_components/common/Button";
import PaymentOption from "./components/PaymentOption/PaymentOption";
import { updateObject, checkValidity } from "panel/src/utils/helper";
import * as getPaymentOptionsActions from "panel/src/redux/actions/paymentOptions";
import * as setPaymentOptionsActions from "panel/src/redux/actions/setPaymentOptions";
import BlockingForm from "panel/src/_components/common/BlockingForm";
import SubNavigation from "panel/src/_components/primitives/SubNavigation";
import YoutubeTutorial, {
  YOUTUBE_LINKS,
} from "panel/src/Components/YoutubeTutorial";
import HybridCheckoutItem from "./components/HybridCheckoutItem/HybridCheckoutItem";
import Intercom from "../../../Components/Intercom/Intercom";

interface IPaymentProps {
  formElements?: any;
  checked: boolean;
  label: string;
  description: string;
  appId: string;
  history?: any;
  actions?: any;

  getPaymentList?: any;
  platformId?: number;
  clientConfigs: any;
}

interface IPaymentState {
  isLoading?: any;
  error?: any;
  isFormValid?: any;
  paymentOptions?: any;
  isBlocking: boolean;
  isDisableCheckbox: boolean;
  pageLoaded: boolean;
  isHybridCheckout?: boolean;
}

class PaymentOptions extends Component<IPaymentProps, IPaymentState> {
  state: any = {
    isLoading: false,
    error: null,
    isFormValid: true,
    paymentOptions: [
      {
        key: "paypal",
        isChecked: false,
        label: "Paypal",
        description:
          "Please fill in the following required fields to activate PayPal on your app. If you do not have a sandbox and a live client ID please follow the link to register. Then, return to the App Wizard to enter your sandbox and live client ID.",
        form: {
          PaypalLiveClientId: {
            label: "Live Client ID",
            elementType: "input",
            elementConfig: {
              type: "text",
              placeholder: "Live Client ID",
            },
            value: "",
            validation: {
              required: true,
            },
            valid: false,
            touched: false,
          },
          PaypalSandboxClientId: {
            label: "Sandbox Client ID",
            elementType: "input",
            elementConfig: {
              type: "text",
              placeholder: "Sandbox Client ID",
            },
            value: "",
            validation: {
              required: true,
            },
            valid: false,
            touched: false,
          },
        },
        isEnabled: false,
        checkKey: "IsPaypalEnabled",
      },
      {
        key: "stripe",
        isChecked: false,
        label: "Stripe",
        description:
          "Please fill the following fields to activate Stripe in your app. If you do not have a sandbox and a live client ID please follow the link to register.",
        form: {
          StripeSecretKey: {
            label: "Secret Key",
            elementType: "input",
            elementConfig: {
              type: "text",
              placeholder: "Secret Key",
            },
            value: "",
            validation: {
              required: true,
            },
            valid: false,
            touched: false,
          },
        },
        isEnabled: false,
        checkKey: "IsStripeEnabled",
      },
      {
        key: "iyzico",
        isChecked: false,
        label: "Iyzico",
        description:
          "Please fill in the following required fields to activate Iyzico on your app.",
        form: {
          IyzicoApiKey: {
            label: "Merchant name",
            elementType: "input",
            elementConfig: {
              type: "text",
              placeholder: "Merchant name",
            },
            value: "",
            validation: {
              required: true,
            },
            valid: false,
            touched: false,
          },
          IyzicoSecretKey: {
            label: "Merchant key",
            elementType: "input",
            elementConfig: {
              type: "text",
              placeholder: "Merchant key",
            },
            value: "",
            validation: {
              required: true,
            },
            valid: false,
            touched: false,
          },
        },
        isEnabled: false,
        checkKey: "IsIyzicoEnabled",
      },
    ],
    isBlocking: false,
    isDisableCheckbox: true,
    pageLoaded: false,
    isHybridCheckout: false,
  };
  timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async componentDidMount() {
    const { actions } = this.props;
    const paymentObj = { ApplicationId: this.props.appId };

    const config = this.props.clientConfigs?.find((s: any) => s.Id === "2");
    if (config) {
      const configList = config.Configs.filter((s: any) => s.IsChecked);
      // Hybrid checkout
      const isCheckedHybridCheckout = configList.some(
        (s: any) => s.Id === "46"
      );
      if (isCheckedHybridCheckout) {
        this.setState({
          isHybridCheckout: isCheckedHybridCheckout,
        });
      }
    }

    await actions.getPaymentOptions.paymentOptionsStart(paymentObj);
  }

  componentWillReceiveProps = (nextProps: any) => {
    const { getPaymentList } = this.props;

    if (getPaymentList != nextProps.getPaymentList) {
      const paymentOptions = this.state.paymentOptions.map(
        (paymentOption: any) => {
          let isChecked = false;
          let updatedForm = { ...paymentOption.form };
          let isEnabled = false;

          Object.keys(updatedForm).forEach((key: any) => {
            isChecked = !!nextProps.getPaymentList[key];
            const updatedFormElement = updateObject(updatedForm[key], {
              value: nextProps.getPaymentList[key],
            });

            updatedForm = updateObject(updatedForm, {
              [key]: updatedFormElement,
            });
          });

          if (isChecked) {
            this.setState({ isFormValid: isChecked });
          }

          isEnabled = nextProps.getPaymentList[paymentOption.checkKey];

          if (isEnabled) {
            this.setState({ isDisableCheckbox: false });
          }

          return {
            ...paymentOption,
            isChecked: isChecked,
            form: updatedForm,
            valid: isChecked,
            isEnabled,
          };
        }
      );

      this.setState({ paymentOptions, pageLoaded: true });
    }
  };

  submitHandler = () => {
    this.setState({ isBlocking: false }, () => {
      const { actions } = this.props;
      const data: any = { ApplicationId: this.props.appId };
      let isFormValid = true;
      this.state.paymentOptions.forEach((paymentOption: any) => {
        Object.keys(paymentOption.form).forEach((key: any) => {
          if (paymentOption.isChecked) {
            data[key] = paymentOption.form[key].value;
            if (isFormValid) isFormValid = !!data[key];
          }
        });
      });
      this.setState({ isFormValid: isFormValid });
      if (!isFormValid) return;

      try {
        this.setState({ isLoading: true });
        actions.setPaymentOptions.setPaymentOptionsStart(data);
        this.setState({ isLoading: false });
        this.props.history.push("/manage/engagement-options");
      } catch (error) {
        this.setState({ error, isLoading: false });
      }
    });
  };

  checkBoxChangeHandler = (e: any, key: any) => {
    const index = this.state.paymentOptions.findIndex(
      (paymentOption: any) => paymentOption.key === key
    );
    const updatedPaymentOption = updateObject(
      this.state.paymentOptions[index],
      {
        isChecked: e.target.checked,
      }
    );

    const updatedPaymentOptions = [...this.state.paymentOptions];
    updatedPaymentOptions[index] = updatedPaymentOption;

    this.setState({
      paymentOptions: updatedPaymentOptions,
      isFormValid: true,
      isBlocking: true,
    });
  };

  inputChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputIdentifier: any
  ) => {
    this.setState({ isBlocking: true });
    const index = this.state.paymentOptions.findIndex(
      (paymentOption: any) => paymentOption.form[inputIdentifier]
    );

    const updatedFormElement = updateObject(
      this.state.paymentOptions[index].form[inputIdentifier],
      {
        value: e.target.value,
        valid: checkValidity(
          e.target.value,
          this.state.paymentOptions[index].form[inputIdentifier].validation
        ),
        touched: true,
      }
    );

    const updatedForm = updateObject(this.state.paymentOptions[index].form, {
      [inputIdentifier]: updatedFormElement,
    });

    const updatedPaymentOption = updateObject(
      this.state.paymentOptions[index],
      {
        form: updatedForm,
      }
    );

    const updatedPaymentOptions = [...this.state.paymentOptions];
    updatedPaymentOptions[index] = updatedPaymentOption;
    let isFormValid = true;
    for (let inputIdentifier in updatedForm) {
      isFormValid = updatedForm[inputIdentifier].valid && isFormValid;
    }

    this.setState({
      paymentOptions: updatedPaymentOptions,
      isFormValid: true,
      isBlocking: true,
    });
  };

  render() {
    const { isHybridCheckout } = this.state;
    let paymentOptions = null;
    if (this.state.isLoading) {
      paymentOptions = null;
    } else {
      paymentOptions = this.state.paymentOptions.map((paymentOption: any) => {
        const formElements = [];
        for (let key in paymentOption.form) {
          formElements.push({
            id: key,
            config: paymentOption.form[key],
          });
        }
        return (
          <PaymentOption
            key={paymentOption.key}
            label={paymentOption.label}
            checked={paymentOption.isChecked}
            checkBoxChangeHandler={(e: any) =>
              this.checkBoxChangeHandler(e, paymentOption.key)
            }
            description={paymentOption.description}
            formElements={formElements}
            disableCheckbox={!paymentOption.isEnabled}
            inputChangeHandler={this.inputChangeHandler}
            error={!this.state.isFormValid}
          />
        );
      });
    }

    return (
      <React.Fragment>
        <SubNavigation />
        <Intercom />
        <div className={classes.contentContainer}>
          <div className={classes.ManageSubRouteContainer}>
            {this.state.pageLoaded && (
              <>
                <div className={classes.HeadingWrapper}>
                  <h3 className={classes.Heading}>Payment Services</h3>
                  <YoutubeTutorial
                    videoUrl={YOUTUBE_LINKS.PAYMENT_SERVICES}
                    iconSize={16}
                  />
                </div>
                <p className={classes.Info}>
                  You may integrate third-party payment services to your app in
                  addition to the payment options provided by your e-commerce
                  business.
                </p>
                {this.state.isDisableCheckbox && !isHybridCheckout && (
                  <p className={classes.Warn}>
                    Your e-commerce platform does not support this feature.
                  </p>
                )}
                {isHybridCheckout && <HybridCheckoutItem />}
                <div className={classes.paymentFormContainer}>
                  {paymentOptions}
                </div>
              </>
            )}
          </div>
          <div className={classes.SaveButtonContainer}>
            <Button width="160px" type="Primary" onClick={this.submitHandler}>
              Continue
            </Button>
          </div>
        </div>
        <BlockingForm isBlocking={this.state.isBlocking} />
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: {
      getPaymentOptions: bindActionCreators(getPaymentOptionsActions, dispatch),
      setPaymentOptions: bindActionCreators(setPaymentOptionsActions, dispatch),
    },
  };
}

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    getPaymentList: state.paymentOptionsList.paymentOptionList,
    platformId: state.userInfo.selectedApp.PlatformId,
    clientConfigs: state.clientConfigList.configList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOptions);
