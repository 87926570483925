import React from 'react';
import Background, { ColorSets } from './Background';
import Image from './Image';
import SplashImage from './SplashImage';

export interface IEmulatorSplash extends ColorSets {
  isBasic: boolean;
  splashImage?: string;
  splashLogo: string;
  backgroundType: string;
}

const EmulatorSplash = ({
  isBasic,
  splashImage,
  splashLogo,
  backgroundType,
  backgroundColor,
  linearColorStart,
  linearColorEnd,
  radialColorStart,
  radialColorEnd,
}: IEmulatorSplash) => {
  return isBasic ? (
    <Background
      backgroundType={backgroundType}
      colorSets={{
        backgroundColor,
        linearColorStart,
        linearColorEnd,
        radialColorStart,
        radialColorEnd,
      }}
    >
      <Image src={splashLogo} />
    </Background>
  ) : splashImage ? (
    <SplashImage imgUrl={splashImage} />
  ) : null;
};

export default EmulatorSplash;
