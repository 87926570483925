import React from "react";
import { DiscountAmount, DiscountContainer } from "./DiscountCard.styles";

interface IAddToCartBar {
  fontFamily?: any;
  discountAmount?: string;
  secondary?: string;
}

const DiscountCard = ({
  discountAmount,
  fontFamily,
  secondary,
}: IAddToCartBar) => {
  return (
    <DiscountContainer secondary={secondary}>
      <DiscountAmount fontFamily={fontFamily}>{discountAmount}</DiscountAmount>
    </DiscountContainer>
  );
};

export default DiscountCard;
