import React, { FC } from "react";
import { History } from "history";
import classes from "./stepHelper.module.scss";
import Icon from "../../../_components/common/Icon";
import step1 from "../../../assets/images/step1.png";
import step2 from "../../../assets/images/step2.png";
import step3 from "../../../assets/images/step3.png";
import step4 from "../../../assets/images/step4.png";
import step5 from "../../../assets/images/step5.png";
import step6 from "../../../assets/images/step6.png";
import step7 from "../../../assets/images/step7.png";
import settingIcon from "../../../assets/images/settings.png";

interface IStepHelper {
  history: History;
}

const StepHelper: FC<IStepHelper> = ({ history }) => {
  const backButton = () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      <div className={classes.Container}>
        <div className={classes.backIconContainer} onClick={backButton}>
          <Icon
            className={classes.backIcon}
            type="arrowLeft"
            width="16"
            height="16"
            fillColor="#3196f6"
          />
          <span className={classes.backIconLabel}>Back</span>
        </div>

        <h3 className={classes.Heading}>Demo App Installment Guideline</h3>
        <p className={classes.Paragraph}>
          Please follow the steps below carefully.
        </p>
        <p className={classes.stepTitle}>
          1. When you get the demo app link email, please click on “
          <b>View App</b>” button and it will be open in your internet browser.
        </p>
        <ImageContainer imagePath={step1} />
        <p className={classes.stepTitle}>
          2. Click on “<b>Install</b>” button on your App Center webpage.
        </p>
        <ImageContainer imagePath={step2} />
        <p className={classes.stepTitle}>
          3. Just in case, please check the Help section on your downloading
          page.
        </p>
        <ImageContainer imagePath={step3} />
        <p className={classes.stepTitle}>
          4. If you have an IOS device, you will see “
          <b>Untrusted Enterprise Developer</b>” pop-up on your mobile screen.’
        </p>
        <ImageContainer imagePath={step4} />
        <p className={classes.stepTitle}>
          5. Go to
          <img src={settingIcon} alt="Settings" className={classes.stepIcon} />
          <b className={classes.stepBoldText}>Settings</b> on your IOS device, →
          then click on <b className={classes.stepBoldText}>General</b> →
          finally click on
          <b className={classes.stepBoldText}>Device Management</b> to find the
          developer information.
        </p>
        <ImageContainer imagePath={step5} />
        <p className={classes.stepTitle}>
          6. Click on Trust ”<b>TMOB BILISIM YAZILIM TEKNOLOJI SISTEMLERI AS</b>
          ”, then click on ”<b>Trust</b>” button on the pop up.
        </p>
        <ImageContainer imagePath={step6} />
        <p className={classes.stepTitle}>
          7. Go back to your demo app and try to open again.
        </p>
        <ImageContainer imagePath={step7} />
      </div>
    </React.Fragment>
  );
};

interface IImageContainer {
  imagePath?: string;
}

const ImageContainer: FC<IImageContainer> = ({ imagePath }) => {
  return (
    <div className={classes.imageContainer}>
      <img className={classes.stepImg} src={imagePath} />
    </div>
  );
};

export default StepHelper;
