import styled from "styled-components/native";

interface IPaginationContainer {
  bottom: number;
}

export default styled.View<IPaginationContainer>`
  position: absolute;
  flex-direction: row;
  right: 0;
  bottom: ${(props) => props.bottom + 10}px;
  left: 0;
  justify-content: center;
`;
