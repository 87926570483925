import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { setEmulatorOS } from "panel/src/redux/actions/emulator";
import IconContainer from "./IconContainer";
import IconWrapper from "./IconWrapper";
import OSText from "./OSText";
import { infoCardInterval } from "panel/src/utils/constants";
import { IconType } from "panel/src/utils/enums/iconType";
import ReactDOM from "react-dom";
import AndroidIcon from "panel/src/_components/primitives/AndroidIcon";
import IOSIcon from "panel/src/_components/primitives/IOSIcon";
import InfoCard from "panel/src/Components/InfoCard";
import Background from "../Screens/App/InfoPopUp/Background";

interface IToggleOS {
  callback: Function;
  showOSInfoCard?: boolean;
  selectedApp: {
    AppId: string;
    AppName: string;
    CreateDate: string;
    IsSelected: boolean;
    IsStep1Completed: boolean;
    IsStep2Completed: boolean;
    IsStep3Completed: boolean;
    IsStep4Completed: boolean;
    IsStep5Completed: boolean;
    IsStep6Completed: boolean;
    IsStep7Completed: boolean;
    IsStep8Completed: boolean;
    IsStep9Completed: boolean;
    IsStep10Completed: boolean;
    IsStep11Completed: boolean;
    IsStep12Completed: boolean;
    IsStep13Completed: boolean;
    PlatformId: number;
  };
  emulatorOS: number;
  _setEmulatorOS: Function;
  infoCardVisible?: boolean;
}

export enum OS {
  IOS = 1,
  Android,
}

const ToggleOS = ({
  callback,
  showOSInfoCard,
  selectedApp,
  emulatorOS,
  _setEmulatorOS,
  infoCardVisible,
}: IToggleOS) => {
  const selectedOS = useRef(emulatorOS);
  const [iosInfoCardVisible, setIosInfoCardVisible] = useState(false);
  const [androidInfoCardVisible, setAndroidInfoCardVisible] = useState(false);
  const IOSWrapperRef = useRef(null);
  const AndroidWrapperRef = useRef(null);

  useEffect(() => {
    if (showOSInfoCard && !selectedApp.IsStep8Completed && infoCardVisible) {
      setTimeout(() => {
        if (selectedOS.current === emulatorOS) {
          showInfoCard();
        }
      }, infoCardInterval);
    }
  }, [infoCardVisible]);

  const showInfoCard = () => {
    if (selectedOS.current === OS.IOS) {
      setAndroidInfoCardVisible(true);
    } else {
      setIosInfoCardVisible(true);
    }
  };

  const getActiveIconType = (OS: number) =>
    emulatorOS === OS ? IconType.Active : IconType.Disabled;

  const getActiveIcon = (OS: number) => emulatorOS === OS;

  const changeActiveOS = (OS: number) => {
    selectedOS.current = OS;
    _setEmulatorOS(OS);
    callback(OS);
  };

  return (
    <IconContainer className="OS-Icon-Container">
      <IconWrapper
        ref={IOSWrapperRef}
        onClick={() => changeActiveOS(OS.IOS)}
        isActiveOS={getActiveIcon(OS.IOS)}
      >
        <IOSIcon iconType={getActiveIconType(OS.IOS)} />
        <OSText isActiveOS={getActiveIcon(OS.IOS)}>IOS</OSText>
      </IconWrapper>
      <InfoCard
        targetRef={IOSWrapperRef}
        showInfoCard={iosInfoCardVisible}
        setShowInfoCard={setIosInfoCardVisible}
        text="You may also check IOS Design here"
      />
      <IconWrapper
        ref={AndroidWrapperRef}
        onClick={() => changeActiveOS(OS.Android)}
        isActiveOS={getActiveIcon(OS.Android)}
      >
        <AndroidIcon iconType={getActiveIconType(OS.Android)} />
        <OSText isActiveOS={getActiveIcon(OS.Android)}>Android</OSText>
      </IconWrapper>
      <InfoCard
        targetRef={AndroidWrapperRef}
        showInfoCard={androidInfoCardVisible}
        setShowInfoCard={setAndroidInfoCardVisible}
        text="You may also check Android Design here"
      />

      {(androidInfoCardVisible || iosInfoCardVisible) &&
        ReactDOM.createPortal(
          <Background
            onClick={() => {
              setAndroidInfoCardVisible(false);
              setIosInfoCardVisible(false);
            }}
          />,
          document.getElementById("root") as Element
        )}
    </IconContainer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    selectedApp: state.userInfo.selectedApp,
    emulatorOS: state.emulator.os,
    infoCardVisible: state.infoCards.toggleOSInfoCardVisible,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _setEmulatorOS: (os: number) => dispatch(setEmulatorOS(os)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToggleOS);
