import IGetColorPageDataRequest from "../../models/Design/colors/IGetColorPageDataRequest";

import {
  GET_COLOR_PAGE_DATA,
  GET_COLOR_PAGE_DATA_FAIL,
  GET_COLOR_PAGE_DATA_SUCCESS,
  RESET_COLOR_PAGE_DATA,
} from "panel/src/redux/constants/colorsPage";

interface BaseAction {
  type: string;
  data?: Object;
  error?: string;
  requestObj?: IGetColorPageDataRequest;
}

export const getColorPageData = (
  requestObj: IGetColorPageDataRequest
): BaseAction => ({
  type: GET_COLOR_PAGE_DATA,
  requestObj,
});

export const getColorPageDataSuccess = (data: Object): BaseAction => ({
  type: GET_COLOR_PAGE_DATA_SUCCESS,
  data,
});

export const getColorPageDataFail = (error: string): BaseAction => ({
  type: GET_COLOR_PAGE_DATA_FAIL,
  error,
});

export const resetColorPageData = () => ({
  type: RESET_COLOR_PAGE_DATA,
});
