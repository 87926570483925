import styled from "styled-components";
import { OS } from "./ToggleOS";

interface IEmulatorFrame {
  OS: number;
  IOSFrame: string;
  AndroidFrame: string;
  isProductDetails: boolean;
  isSplashScreen: boolean;
}

export default styled.div<IEmulatorFrame>`
  background-image: url(${(props) =>
    props.OS === OS.IOS ? props.IOSFrame : props.AndroidFrame});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: ${(props) => {
    let padding =
      props.OS === OS.IOS ? "29px 12px 12px 12px" : "28px 7px 23px 7px";

    if (
      (props.isProductDetails || props.isSplashScreen) &&
      props.OS === OS.IOS
    ) {
      padding = "12px";
    }

    return padding;
  }};
  position: relative;
  user-select: none;
`;
