import {
  GET_PRODUCT_LIST_DATA,
  GET_PRODUCT_LIST_DATA_SUCCESS,
  GET_PRODUCT_LIST_DATA_FAIL,
  RESET_PRODUCT_LIST_DATA,
  GET_PRODUCT_LIST_BY_CATEGORY_ID_SUCCESS,
  GET_PRODUCT_LIST_BY_CATEGORY_ID_FAIL,
  GET_PRODUCT_LIST_BY_CATEGORY_ID,
  RESET_PRODUCT_LIST_BY_CATEGORY_ID_PRODUCTS,
} from "panel/src/redux/constants/productList";
import { ComponentProductModule } from "../../models/Design/home/ComponentProduct";

export interface IProductListData {
  componentsData: {
    ComponentId: number;
    DisplayOrder: number;
    JSON: string | null;
    PageId: number;
  }[];
  initialized: boolean;
  productList: ComponentProductModule.IProduct[];
  productListLoading: boolean;
}

const initialState = {
  data: false,
  loading: false,
  reset: false,
  productList: [],
  productListLoading: false,
};

const productListReducer = (state: Object = initialState, action: any) => {
  switch (action.type) {
    case GET_PRODUCT_LIST_DATA:
      return {
        ...state,
        loading: true,
        reset: false,
      };
    case GET_PRODUCT_LIST_DATA_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        reset: false,
      };
    case GET_PRODUCT_LIST_DATA_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        reset: false,
      };
    case GET_PRODUCT_LIST_BY_CATEGORY_ID:
      return {
        ...state,
        productListLoading: true,
      };
    case GET_PRODUCT_LIST_BY_CATEGORY_ID_SUCCESS:
      return {
        ...state,
        productList: action.list,
        loading: false,
        reset: false,
        productListLoading: false,
      };
    case GET_PRODUCT_LIST_BY_CATEGORY_ID_FAIL:
      return {
        ...state,
        loading: false,
        reset: false,
        productList: [],
        productListLoading: false,
      };
    case RESET_PRODUCT_LIST_BY_CATEGORY_ID_PRODUCTS:
      return {
        ...initialState,
        productList: [],
      };
    case RESET_PRODUCT_LIST_DATA:
      return {
        ...initialState,
        loading: false,
        reset: true,
      };
    default:
      return state;
  }
};

export default productListReducer;
