import axios from "../api";

interface IJudgeMeTokenResponse {
  ResultCode?: number;
  Message?: string;
  ResultMesage?: string;
  ResultData?: {
    Succcess: boolean;
    Message: string;
  };
}

export const checkJudgeMeToken = async (appId: string, key: string) => {
  return axios.post<IJudgeMeTokenResponse>(
    "/ConfigService/api/Home/CheckJudgeMeToken",
    {
      ApplicationId: appId,
      Token: key,
    }
  );
};
