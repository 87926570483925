import React from "react";
import Container from "./Container";
import Title from "./Title";
import TypesContainer from "./TypesContainer";
import TypeWrapper from "./TypeWrapper";
import Type from "./Type";
import TypeLabel from "./TypeLabel";
import Shape from "./Shape";

type TImageResizeTypes = TImageResizeType[];
export type TResizeTypeValue = "Fill" | "Fit";
export type TImageResizeType = {
  label: TResizeTypeValue;
  mode: string;
  shape: JSX.Element;
};

interface IImageResizeType {
  resizeTypeValue: TResizeTypeValue;
  typeOnClickHandler: Function;
}

const ImageResizeType = ({
  resizeTypeValue,
  typeOnClickHandler,
  ...props
}: IImageResizeType) => {
  const imageResizeTypes: TImageResizeTypes = [
    { label: "Fill", mode: "cover", shape: <Shape width="100%" /> },
    { label: "Fit", mode: "contain", shape: <Shape width="34px" /> },
  ];

  return (
    <Container {...props}>
      <Title>Image Display</Title>
      <TypesContainer>
        {imageResizeTypes.map((item, index) => (
          <TypeWrapper
            hasMarginRight={imageResizeTypes.length - 1 !== index}
            onClick={() => typeOnClickHandler(item)}
            key={index.toString()}
          >
            <Type isActive={item.mode === resizeTypeValue}>{item.shape}</Type>
            <TypeLabel>{item.label}</TypeLabel>
          </TypeWrapper>
        ))}
      </TypesContainer>
    </Container>
  );
};

export default ImageResizeType;
