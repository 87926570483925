import React, { useEffect, useState, useRef } from "react";
//components start
import { Form, Formik } from "formik";
import ScreenSelection from "../components/ScreenSelection";
import Select from "../../../_components/common/Select";
import Emulator from "panel/src/Components/DragnDrop/Emulator";
import InfoCard from "panel/src/Components/InfoCard";
//components end

//sass starts
import classes from "./fontType.module.scss";
//sass ends

//constanst & enums start/
import { screenTabs, infoCardInterval } from "../../../utils/constants";
import { ScreenType } from "../../../utils/enums/screenType";
import { ComponentType } from "panel/src/utils/enums/componentType";
//constants end//

//models start
import SelectModel from "../../../models/Design/selectModel";
import { ColorList } from "../../../models/Design/colors/IGetColorsPageDataResponse";
import IGetColorsPageDataRequest, {
  SetColorsRequestModel,
} from "../../../models/Design/colors/IGetColorPageDataRequest";
import { IProductListData } from "panel/src/models/Design/productList/IGetProductListDataResponse";
import { IProductDetailData } from "panel/src/models/Design/productDetail/IGetProductDetailDataResponse";
import { IHomeData } from "panel/src/models/Design/home/IGetHomeDataResponse";
import { IComponent } from "panel/src/Components/DragnDrop/Components";
//models end

//redux actions start
import { connect, useDispatch } from "react-redux";
import { setFontType } from "panel/src/redux/actions/setFontType";
import {
  getFontTypeData,
  resetFontTypeData,
} from "panel/src/redux/actions/fontType";
//redux actions end

//component handlers start
import { screenNumbers } from "../constants";
import { setChangedSthDesignPage } from "panel/src/redux/actions/manageApp";
import { getHomeComponents } from "../utils/homeComponentHandler";
import { getProductDetailComponent } from "../utils/productDetailComponentHandler";
import { getProductListComponent } from "../utils/productListComponentHandler";
import Modal from "../../../_components/common/Modal";
import UpgradeGuide from "../../../Components/UpgradeGuide";
import { usePackage } from "../../../hooks";
import { getNavigationMenu } from "../../../redux/actions/navigationMenu";
//component handlers end

interface IProps {
  _selectedApp?: {
    AppId: string;
    AppName: string;
    CreateDate: string;
    IsSelected: boolean;
    IsStep1Completed: boolean;
    IsStep2Completed: boolean;
    IsStep3Completed: boolean;
    IsStep4Completed: boolean;
    IsStep5Completed: boolean;
    IsStep6Completed: boolean;
    IsStep7Completed: boolean;
    IsStep8Completed: boolean;
    IsStep9Completed: boolean;
    IsStep10Completed: boolean;
    IsStep11Completed: boolean;
    IsStep12Completed: boolean;
    IsStep13Completed: boolean;
    PlatformId: number;
  };
  appId?: string;
  platformId: number;
  userId?: string;
  actions?: any;
  colors?: any;
  fonts?: any;
  deviceTypeHandler: Function;
  _getFontTypePageData: Function;
  selectChange: Function;
  _setFontType: Function;
  isCompletion: boolean;
  selectedValue: string;
  designScreens: SelectModel[];
  designScreensChange: Function;
  homeData: IHomeData["data"]["ResultData"];
  productListData: IProductListData["data"]["ResultData"];
  productDetailsData: IProductDetailData["data"]["ResultData"];
  loading: boolean;
  reset: boolean;
  colorPage: {};
  _resetFontTypeData: Function;
  isOnModalSubmit: boolean;
}

const FontType = ({
  _selectedApp,
  appId,
  platformId,
  userId,
  isCompletion,
  designScreens,
  _getFontTypePageData,
  _resetFontTypeData,
  _setFontType,
  designScreensChange,
  homeData,
  loading,
  reset,
  colorPage,
  productListData,
  productDetailsData,
  selectChange,
  colors,
  selectedValue,
  fonts,
  isOnModalSubmit,
}: IProps) => {
  const packageHook = usePackage();
  const dispatch = useDispatch();

  useEffect(() => {
    if (appId) {
      const colorPageRequestObj: IGetColorsPageDataRequest = {
        applicationId: appId,
        userId: userId,
        platformId: platformId,
      };
      _getFontTypePageData(colorPageRequestObj);
      dispatch(getNavigationMenu());
    }
  }, []);

  const [checkOtherScreensInfoVisible, setCheckOtherScreensInfoVisible] =
    useState(false);
  const viewedScreens = useRef(["Home"]);
  const screenItemRef = useRef(new Array());
  const [activeTabIndex, setActiveTabIndex] = useState(ScreenType.Home);
  const [emulatorProps, setEmulatorProps] = useState();
  const [homeComponents, setHomeComponents] = useState<
    (IComponent | undefined)[]
  >([]);
  const [productListComponents, setProductListComponents] = useState<
    (IComponent | undefined)[]
  >([]);
  const [productDetailsComponents, setProductDetailsComponents] = useState<
    (IComponent | undefined)[]
  >([]);
  const [productSliderData, setProductSliderData] = useState(undefined);
  const [productPriceData, setProductPriceData] = useState(undefined);

  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState<any[]>([]);
  const [selectedFontValue, setSelectedFontValue] = useState("");
  const [selectedFont, setSelectedFont] = useState("");

  const [selectedColorList, setSelectedColorList] = useState<ColorList[]>([]);
  const [screenNotViewed, setScreenNotViewed] = useState(1);
  const [itemCount, setItemCount] = useState(0);
  const [productSliderDimension, setProductSliderDimension] = useState(null);
  const [isUpgradeGuide, setIsUpgradeGuide] = useState<boolean>(false);
  const [isUpgradeModalShown, setIsUpgradeModalShown] =
    useState<boolean>(false);

  useEffect(() => {
    if (colorPage && !loading && !reset) {
      const selectedFamily = fonts.find((opt: any) => opt.IsUsed);
      const selectedValue = selectedFamily ? selectedFamily.Id.toString() : "1";

      const colorList = colors.selectedColorModel
        ? colors.selectedColorModel.colorList
        : [];

      var list: any[] = [];
      fonts.map((item: any) => {
        let fontItem = {
          value: item.Id.toString(),
          displayValue: item.Name,
          isSelected: selectedValue == item.Id.toString() ? item.IsUsed : false,
          isComplated: false,
          isPackageSupported:
            item.Id !== 1 ? packageHook.isPackageFeaturesLimited : false,
        };
        list.push(fontItem);
      });

      setOptions(list);
      setSelectedColorList(colorList);

      const selectedOption = list.find((opt) => opt.isSelected);
      const selectedFontValue = selectedOption ? selectedOption.value : "1";
      const selectedFont = selectedOption
        ? selectedOption.displayValue
        : "Helvetica Neue";
      setSelectedFontValue(selectedFontValue);
      setSelectedFont(selectedFont);

      const selectedHomeComponents = homeData.SelectedComponents.map((obj) => {
        return getHomeComponents(
          selectedFont,
          colorList,
          obj.ComponentId,
          JSON.parse(obj.ResponseJSON)
        );
      }).filter((obj) => obj !== undefined);

      setHomeComponents(selectedHomeComponents);

      const selectedProductListComponents =
        productListData.SelectedComponents.map((obj) => {
          return getProductListComponent(
            selectedFont,
            colorList,
            obj.ComponentId,
            JSON.parse(obj.ResponseJSON),
            obj.ImageResizeType,
            JSON.parse(obj.CustomJSON)?.IsAddToCart
          );
        }).filter((obj) => obj !== undefined);

      setItemCount(
        JSON.parse(productListData.Components[0]?.ResponseJSON)?.length
      );
      setProductListComponents(selectedProductListComponents);

      const selectedProductDetailComponents =
        productDetailsData.SelectedComponents.map((obj) => {
          return getProductDetailComponent(
            selectedFont,
            colorList,
            obj.ComponentId,
            JSON.parse(obj.ResponseJSON),
            setProductSliderDimension,
            obj.CustomJSON
          );
        }).filter((obj) => obj !== undefined);

      setProductDetailsComponents(selectedProductDetailComponents);

      const productSliderData = productDetailsData.SelectedComponents.find(
        (obj) => obj.ComponentId === ComponentType.ProductSlider
      )!.ResponseJSON;
      const productPriceData = productDetailsData.Components.find(
        (obj) => obj.ComponentId === ComponentType.ProductPrice
      )!.ResponseJSON;
      setProductPriceData(JSON.parse(productPriceData).ListPrice);

      setProductSliderData(JSON.parse(productSliderData).Images);

      setIsLoading(false);
    }
  }, [colorPage]);

  useEffect(() => {
    if (isOnModalSubmit && selectedValue === screenNumbers.fontType) {
      onSubmitForm();
    }
  }, [isOnModalSubmit]);

  useEffect(() => {
    return () => {
      _resetFontTypeData();
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        if (
          (!viewedScreens.current.includes("ProductList") ||
            !viewedScreens.current.includes("ProductDetails")) &&
          !_selectedApp?.IsStep13Completed
        ) {
          setCheckOtherScreensInfoVisible(true);
        }
      }, infoCardInterval);

      const activeTab = screenTabs.find(
        (tab: any) => tab.index === activeTabIndex
      );
      handleTabClick(activeTab);
    }
  }, [isLoading]);

  const updateTheme = async (selectedFont: number) => {
    fonts &&
      fonts.map((item: any, index: number) => {
        if (selectedFont == item.Id) {
          setSelectedFont(item.Name);
          dispatch(setChangedSthDesignPage(true));

          const selectedHomeComponents = homeData.SelectedComponents.map(
            (obj) => {
              return getHomeComponents(
                item.Name,
                selectedColorList,
                obj.ComponentId,
                JSON.parse(obj.ResponseJSON)
              );
            }
          ).filter((obj) => obj !== undefined);
          setHomeComponents(selectedHomeComponents);

          const selectedProductDetailComponents =
            productDetailsData.SelectedComponents.map((obj) => {
              return getProductDetailComponent(
                item.Name,
                selectedColorList,
                obj.ComponentId,
                JSON.parse(obj.ResponseJSON),
                setProductSliderDimension,
                obj.CustomJSON
              );
            }).filter((obj) => obj !== undefined);

          setProductDetailsComponents(selectedProductDetailComponents);

          const selectedProductListComponents =
            productListData.SelectedComponents.map((obj) => {
              return getProductListComponent(
                item.Name,
                selectedColorList,
                obj.ComponentId,
                JSON.parse(obj.ResponseJSON),
                obj.ImageResizeType,
                JSON.parse(obj.CustomJSON)?.IsAddToCart
              );
            }).filter((obj) => obj !== undefined);

          setProductListComponents(selectedProductListComponents);
        }
      });
  };

  const selectChangeHandler = (e: any) => {
    updateTheme(e.target.value);

    const updatedOptions = options.map((option) => {
      return {
        ...option,
        isSelected: option.value === e.target.value,
      };
    });
    setOptions(updatedOptions);

    const selectedOption = updatedOptions.find((opt) => opt.isSelected);
    const selectedFontValue = selectedOption ? selectedOption.value : "1";
    const selectedFont = selectedOption
      ? selectedOption.displayValue
      : "Helvetica Neue";

    setSelectedFontValue(selectedFontValue);
    setSelectedFont(selectedFont);
    dispatch(setChangedSthDesignPage(true));
  };

  useEffect(() => {
    const activeTab = screenTabs.find(
      (tab: any) => tab.index === activeTabIndex
    );
    handleTabClick(activeTab);
  }, [selectedFont, productSliderDimension]);

  const handleTabClick = async (tab: any) => {
    setActiveTabIndex(tab.index);

    if (!viewedScreens.current.includes(tab.name))
      viewedScreens.current.push(tab.name);

    getScreenNotViewed();

    let _emulatorProps: any;

    if (!isLoading) {
      if (tab.index === ScreenType.Home) {
        _emulatorProps = {
          appScreen: {
            selectedComponents: homeComponents,
            setSelectedComponents: setHomeComponents,
            showOptionsMenu: false,
            screen: "Home",
          },
          navigation: {
            navigationType: homeData?.Navigation.SelectedNavigationType,
            navigationBarIcon: homeData?.Navigation.NavigationBarIcon,
          },
        };
      } else if (tab.index === ScreenType.ProductList) {
        _emulatorProps = {
          appScreen: {
            selectedComponents: productListComponents,
            setSelectedComponents: setProductListComponents,
            showOptionsMenu: false,
            screen: "Product List",
          },
          productListHeader: { visible: true, itemCount: itemCount },
        };
      } else if (tab.index === ScreenType.ProductDetail) {
        _emulatorProps = {
          appScreen: {
            selectedComponents: productDetailsComponents,
            setSelectedComponents: setProductDetailsComponents,
            productSliderData: productSliderData,
            showOptionsMenu: false,
            screen: "Product Details",
          },
          showAddToCartBar: true,
          productPriceData: productPriceData,
          productSliderDimension: productSliderDimension,
        };
      }
    }

    setEmulatorProps(_emulatorProps);
  };

  const onSubmitForm = () => {
    try {
      let tempSelectedFontValue = selectedFontValue;
      const selectedFamily = fonts.find((opt: any) => opt.IsUsed);
      const selectedValue = selectedFamily ? selectedFamily.Id.toString() : "1";
      const change = selectedValue !== selectedFontValue;
      if (packageHook.isPackageFeaturesLimited && change) {
        if (isUpgradeModalShown) {
          tempSelectedFontValue = selectedValue;
        } else {
          setIsUpgradeGuide(true);
          return;
        }
      }

      _setFontType(
        {
          ApplicationId: appId,
          FontId: Number(tempSelectedFontValue),
        },
        () => {
          selectChange("");
        }
      );
    } catch (error) {}
  };

  const getScreenNotViewed = () => {
    const screenNotViewed = screenTabs.find(
      (tab) => !viewedScreens.current.includes(tab.name)
    );

    if (screenNotViewed) {
      setScreenNotViewed(
        screenTabs.map((tab) => tab.name).indexOf(screenNotViewed.name)
      );
    } else {
      setScreenNotViewed(1);
    }
  };

  const toggleScreens = screenTabs.map((item, index) => {
    let navLabel = null;

    const tabClasses = [classes.TabLabel];
    const tabBorderClasses = [];
    if (item.index === activeTabIndex) {
      tabClasses.push(classes.TabActive);
      tabBorderClasses.push(classes.TabActiveBorder);
    }

    navLabel = <span className={tabClasses.join(" ")}>{item.label}</span>;

    return (
      <li
        ref={(element) => screenItemRef.current.push(element)}
        className={tabBorderClasses.join(" ")}
        key={index.toString()}
        onClick={() => handleTabClick(item)}
      >
        {navLabel}
      </li>
    );
  });

  return (
    <React.Fragment>
      {!isLoading ? (
        <div>
          <div className={classes.DesignConfigContainer}>
            {!isCompletion && (
              <ScreenSelection
                options={designScreens}
                selectedValue={selectedValue}
                selectChangeHandler={designScreensChange}
              />
            )}
            <Formik
              enableReinitialize={true}
              onSubmit={onSubmitForm}
              initialValues={{}}
            >
              {() => (
                <Form>
                  <div className={classes.Container}>
                    <span className={classes.BlockLabel}>Font Family</span>
                    <Select
                      fontFamily
                      options={options}
                      value={selectedFontValue}
                      placeholder="Select Font Type"
                      changeHandler={selectChangeHandler}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className={classes.MobileScreen}>
            <div>
              <div className={classes.Tab}>
                <ul>{toggleScreens}</ul>
                <InfoCard
                  targetRef={screenItemRef.current[screenNotViewed]}
                  showInfoCard={checkOtherScreensInfoVisible}
                  setShowInfoCard={setCheckOtherScreensInfoVisible}
                  editCardPlacement="right"
                  text="You may also check Product List and Product Details here."
                />
              </div>
            </div>
            <Emulator
              fontFamily={selectedFont}
              navigateBack={selectChange}
              backTo={ScreenType.ProductDetail.toString()}
              colorList={selectedColorList}
              saveScreen={onSubmitForm}
              {...emulatorProps}
            />
          </div>
        </div>
      ) : null}
      <Modal show={isUpgradeGuide} width="300">
        <UpgradeGuide
          screen="Font Type"
          setIsOpenModal={() => {
            setIsUpgradeGuide(false);
            setIsUpgradeModalShown(true);
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _getFontTypePageData: (reqObject: IGetColorsPageDataRequest) =>
      dispatch(getFontTypeData(reqObject)),
    _resetFontTypeData: () => dispatch(resetFontTypeData()),
    _setFontType: (reqObject: SetColorsRequestModel, callback: Function) =>
      dispatch(setFontType(reqObject, callback)),
  };
};

const mapStateToProps = (state: any) => {
  return {
    _selectedApp: state.userInfo.selectedApp,
    appId: state.userInfo.selectedAppId,
    platformId: state.userInfo.selectedApp.PlatformId,
    userId: state.userInfo.userId,
    colorPage: state.fontType.data,
    homeData: state.fontType.data.home,
    productDetailsData: state.fontType.data.productDetail,
    productListData: state.fontType.data.productList,
    colors: state.fontType.data.colors,
    fonts: state.fontType.data.fonts,
    loading: state.fontType.loading,
    reset: state.fontType.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FontType);
