import { Prompt } from "react-router-dom";
import React, { Component } from "react";
import Modal from "../Modal";
import close from "../../../assets/images/close.svg";
import Button from "../Button";
import classes from "./blockingForm.module.scss";
import { withRouter } from "react-router-dom";

interface IProps {
  isBlocking: boolean;
  history?: any;
}

interface IState {
  isConfirmedNavigation: boolean;
  lastLocation: any;
  isModalVisible: boolean;
}

class BlockingForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isModalVisible: false,
      lastLocation: {
        hash: "",
        pathname: "",
        search: "",
        state: 0,
      },
      isConfirmedNavigation: false,
    };
  }

  handleBlockedNavigation = (nextLocation: any) => {
    const { isConfirmedNavigation } = this.state;
    if (!isConfirmedNavigation) {
      this.showModal(nextLocation);
      return false;
    }

    return true;
  };

  showModal = (location: any) =>
    this.setState({
      isModalVisible: true,
      lastLocation: location,
    });

  closeModal = (callback = () => {}) =>
    this.setState(
      {
        isModalVisible: false,
      },
      callback
    );

  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const { lastLocation } = this.state;
      if (lastLocation) {
        this.setState(
          {
            isConfirmedNavigation: true,
          },
          () => {
            // Navigate to the previous blocked location with your navigate function
            this.props.history.push(lastLocation.pathname);
          }
        );
      }
    });

  render() {
    return (
      <>
        <Prompt
          when={this.props.isBlocking}
          message={this.handleBlockedNavigation}
        />
        <Modal
          backdropClickedHandler={() => this.closeModal(() => {})}
          show={this.state.isModalVisible}
          width="448"
        >
          <div
            className={classes.Close}
            onClick={() => this.closeModal(() => {})}
          >
            <img src={close} />
          </div>
          <div className={classes.ModalBody}>
            <span className={classes.ModalBodyText}>
              Are you sure you like to leave this page? <br />
              The changes you made will be lost.
            </span>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type="button"
                className="Ghost"
                width="192px"
                onClick={this.handleConfirmNavigationClick}
              >
                Leave
              </Button>
              <Button
                type="button"
                className="Primary"
                width="192px"
                onClick={() => this.closeModal(() => {})}
              >
                Stay
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter<any, any>(BlockingForm);
