import React from 'react';

const Upload = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path fill="currentColor" fill-rule="evenodd" d="M13.5 10.5H16v4a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-4h2.5v3h11v-3zM4.512 5.25H6.5v6.25h3V5.25h1.988a.25.25 0 0 0 .212-.415L8.293.15a.362.362 0 0 0-.588 0L4.303 4.83a.25.25 0 0 0 .21.42z"/>
    </svg>
  );
};

export default Upload;
