import {
  SHOW_MODAL,
  HIDE_MODAL,
  START_LOADING,
  FINISH_LOADING,
  SHOW_ERROR_MODAL,
} from "panel/src/redux/constants/modal";

const defaultState = {
  isOpen: false,
  isError: false,
  title: "",
  message: "",
  icon: "",
  buttonName: "",
  isPageLoading: false,
  loadingText: "",
};

export default function (state = defaultState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_MODAL: {
      return {
        ...state,
        isOpen: true,
      };
    }

    case HIDE_MODAL: {
      return {
        ...state,
        isOpen: false,
        isError: false,
      };
    }
    case SHOW_ERROR_MODAL: {
      return {
        ...state,
        isError: true,
      };
    }

    case START_LOADING: {
      return {
        ...state,
        isPageLoading: true,
        loadingText: payload.text,
      };
    }

    case FINISH_LOADING: {
      return {
        ...state,
        isPageLoading: false,
        loadingText: "",
      };
    }

    default:
      return state;
  }
}
