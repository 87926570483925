import { put, call, takeLatest } from "redux-saga/effects";
import ISaveScreenModel from "../../models/SaveScreenModel";
import axios from "panel/src/services/api/api";
import {
  saveScreenDataSuccess,
  saveScreenDataFail,
} from "panel/src/redux/actions/SaveScreen";
import { SAVE_SCREEN_DATA } from "panel/src/redux/constants";
import * as pageActions from "panel/src/redux/actions/page";
import * as userInfoActions from "panel/src/redux/actions/userInfo";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import { clearDesignError, setDesignError } from "../actions/DesignError";

function* saveScreen(payload: { requestObj: ISaveScreenModel; type: string }) {
  yield put(startAppLoading(SAVE_SCREEN_DATA));
  try {
    const saveScreenReq = payload.requestObj;
    const saveScreenRes = yield call(
      axios.post,
      "/Component/api/Component/SaveComponents",
      saveScreenReq
    );
    yield put(clearDesignError());
    yield put(saveScreenDataSuccess(saveScreenRes.data.ResultCode));
    yield put(pageActions.pageUpdated(true));
  } catch (error) {
    const err = error as any;
    yield put(setDesignError(`/Component/api/Component/SaveComponents - ${err?.response?.status}`));
    yield put(saveScreenDataFail(error.message));
  }
  if (!payload.requestObj.IsFromEngagementPage) {
    yield put(userInfoActions.getUserInfo());
  }
  yield put(endAppLoading(SAVE_SCREEN_DATA));
}

export default function* saveScreenSaga() {
  yield takeLatest(SAVE_SCREEN_DATA, saveScreen);
}
