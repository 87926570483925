import { put, call, takeLatest } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import { FORGOT_PASSWORD } from "panel/src/redux/constants";
import {
  forgotPasswordSuccess,
  forgotPasswordFail,
} from "panel/src/redux/actions/ForgotPassword";
import { push } from "react-router-redux";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";

function* forgotPassword(payload: { email: string; type: string }) {
  yield put(startAppLoading(FORGOT_PASSWORD));
  try {
    const forgotPasswordData = yield call(
      axios.post,
      "/api/login/ForgotPassword",
      {
        EmailAddress: payload.email,
      }
    );

    yield put(forgotPasswordSuccess());
    yield put(
      push({
        pathname: "/forgotpasswordlink",
        state: {
          email: payload.email,
        },
      })
    );
  } catch (error) {
    yield put(forgotPasswordFail(error.response.data.ResultMesage));
  }
  yield put(endAppLoading(FORGOT_PASSWORD));
}

export function* forgotPasswordSaga() {
  yield takeLatest(FORGOT_PASSWORD, forgotPassword);
}
