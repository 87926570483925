import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import IGetHomeDataRequest from "../../models/Design/home/IGetHomeDataRequest";
import { IHomeData } from "../../models/Design/home/IGetHomeDataResponse";
import { IProductDetailData } from "../../models/Design/productDetail/IGetProductDetailDataResponse";
import { IProductListData } from "../../models/Design/productList/IGetProductListDataResponse";
import {
  getFontTypeDataSuccess,
  getFontTypeDataFail,
} from "panel/src/redux/actions/fontType";
import { GET_FONT_TYPE_DATA } from "panel/src/redux/constants/fontType";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import notification from "panel/src/utils/notification";

function* getFontTypeData(payload: {
  requestObj: IGetHomeDataRequest;
  type: string;
}) {
  yield put(startAppLoading(GET_FONT_TYPE_DATA));
  try {
    const request = {
      ApplicationId: payload.requestObj.applicationId,
      SsoUserId: payload.requestObj.userId,
      PlatformId: payload.requestObj.platformId,
    };

    const [
      homePageDataResponse,
      productDetailDataResponse,
      productListDataResponse,
      colorsData,
      fontTypeData,
    ]: [IHomeData, IProductDetailData, IProductListData, any, any] = yield all([
      call(axios.post, "/Component/api/Component/GetHomePageData", request),
      call(
        axios.post,
        "/Component/api/Component/GetProductDetailPageData",
        request
      ),
      call(axios.post, "/Component/api/Component/GetProductPageData", request),
      call(axios.post, "/api/Design/GetColors", {
        ApplicationId: request.ApplicationId,
      }),
      call(axios.post, "/api/Manage/GetFonts", {
        ApplicationId: request.ApplicationId,
      }),
    ]);
    const fontTypePageData = {
      productDetail: productDetailDataResponse.data.ResultData,
      home: homePageDataResponse.data.ResultData,
      productList: productListDataResponse.data.ResultData,
      colors: colorsData.data.ResultData,
      fonts: fontTypeData.data.ResultData,
    };
    yield put(getFontTypeDataSuccess(fontTypePageData));
  } catch (error) {
    notification.error(
      "We are temporarily unable to process transactions at this time. We apologize for the inconvenience.",
      "/manage/config-settings"
    );
    yield put(getFontTypeDataFail(error.message));
  } finally {
    yield put(endAppLoading(GET_FONT_TYPE_DATA));
  }
}

export default function* getFontTypeSaga() {
  yield takeLatest(GET_FONT_TYPE_DATA, getFontTypeData);
}
