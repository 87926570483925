import {
  SET_FONT_TYPE,
  SET_FONT_TYPE_SUCCESS,
  SET_FONT_TYPE_FAIL,
} from "panel/src/redux/constants/fontType";

interface BaseAction {
  type: string;
  data?: Object;
  error?: string;
  requestObj?: any;
}

export const setFontType = (requestObj: any, callback: Function) => {
  return {
    type: SET_FONT_TYPE,
    requestObj,
    callback
  };
};

export const setFontTypeSuccess = () => {
  return {
    type: SET_FONT_TYPE_SUCCESS,
  };
};

export const setFontTypeFail = (error: string): BaseAction => ({
  type: SET_FONT_TYPE_FAIL,
  error,
});
