import React from "react";
import WatchVideoIcon from "panel/src/assets/images/watch-video.svg";
import Wrapper from "./Wrapper";
import LabelWrapper from "./LabelWrapper";
import Image from "./Image";
import Label from "./Label";
import Link from "./Link";
import YoutubeTutorialModal from "./Modal";

type TInterpolateValue = {
  [key: string]: string;
};

interface IYoutubeTutorial {
  videoUrl: string;
  iconSize: number;
  inline?: boolean;
  label?: string;
  interpolateValues?: TInterpolateValue;
}

const YoutubeTutorial = ({
  videoUrl,
  iconSize,
  inline,
  label,
  interpolateValues,
}: IYoutubeTutorial) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [hasLink, setHasLink] = React.useState(false);

  const handleOpenModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const renderLink = (matches: RegExpMatchArray | null, part: string) => {
    if (!interpolateValues || !matches) {
      return <Label>{label}</Label>;
    }

    if (matches.includes(part)) {
      setHasLink(true);

      return (
        <Link onClick={(e) => handleOpenModal(e)}>
          {interpolateValues[part]}
        </Link>
      );
    } else {
      return <Label>{part}</Label>;
    }
  };

  const formattedLabel = React.useMemo(() => {
    if (!label) {
      return null;
    }

    const pattern = /([$0-9]+)/g;
    const matches = label.match(pattern);
    const parts = label.split(pattern);

    return parts.map((part, index) => (
      <React.Fragment key={part + index}>
        {renderLink(matches, part)}
      </React.Fragment>
    ));
  }, []);

  return (
    <>
      <Wrapper>
        <Image
          onClick={(e) => (hasLink ? null : handleOpenModal(e))}
          src={WatchVideoIcon}
          height={iconSize}
          width={iconSize}
          isClickable={!hasLink}
        />
        <LabelWrapper>{formattedLabel}</LabelWrapper>
      </Wrapper>
      <YoutubeTutorialModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        youtubeVideUrl={videoUrl}
      />
    </>
  );
};

export const YOUTUBE_LINKS = {
  SHOPIFY: "https://www.youtube.com/embed/Pu8m-jF1nV0",
  WOOCOMMERCE: "https://www.youtube.com/embed/DISxQ8FvrgE",
  DEMO_PLATFORM: "https://www.youtube.com/embed/X2kldbwzSCc",
  GOOGLE_MERCHANT_API: "https://www.youtube.com/embed/V4bY0kGaENk",
  GOOGLE_CSV: "https://www.youtube.com/embed/FfCHrsjZQ90",
  FEATURES: "https://www.youtube.com/embed/7KjoPLd9kTk",
  CHECKOUT_OPTIONS: "https://www.youtube.com/embed/yFROWWPv7ag",
  USER_DATA: "https://www.youtube.com/embed/H5SI-Pmyf44",
  USER_ADDRESS_DETAILS: "https://www.youtube.com/embed/H5SI-Pmyf44",
  CORPORATE_TAX: "https://www.youtube.com/embed/H5SI-Pmyf44",
  PAYMENT_SERVICES: "https://www.youtube.com/embed/ZklE_49Zwrk",
  ENGAGEMENT_OPTIONS: "https://www.youtube.com/embed/rUPRjkANxIQ",
  GENERAL_SETTINGS: "https://www.youtube.com/embed/rgIFhvlHfMg",
  CATEGORY_LAYOUT: "https://www.youtube.com/embed/afJRnWHfmjo",
  SPLASH: "https://www.youtube.com/embed/4J5Ku0tDBrs",
  NAVIGATION_TYPE: "https://www.youtube.com/embed/hlsKIouQoxE",
  SLIDERS_BANNERS: "https://www.youtube.com/embed/jTVHJAKViJk",
  CATEGORY_SLIDER: "https://www.youtube.com/embed/bGuYZhmznwY",
  LARGE_CATEGORY_VIEW: "https://www.youtube.com/embed/G7H1_YUGF8I", // old
  LINK_LIST_VIEW: "https://www.youtube.com/embed/mmWS_puthow", // old
  PRODUCT_LIST: "https://www.youtube.com/embed/0KmKSDx1mIw",
  PRODUCT_DETAIL: "https://www.youtube.com/embed/bDvayJm1jmQ",
  COLOR: "https://www.youtube.com/embed/ZKZ-tI8LEuw",
  FONT: "https://www.youtube.com/embed/iuOuVgmhQT8",
  WELCOME: "https://www.youtube.com/embed/Vml3IDa-U9Y",
  SOCIAL_MEDIA: "https://www.youtube.com/embed/5hReKuznHG4",
  PUBLISH: "https://www.youtube.com/embed/GGYbfquQpSY",
  RAYON_CATEGORIES: "https://www.youtube.com/embed/bGuYZhmznwY",
  PRODUCT_LISTING: "https://www.youtube.com/embed/pycOJeUQDzg",
  PUSH_NOTIFICATION: "https://www.youtube.com/embed/V3FRBIWcXtg",
  LANGUAGE_SETTINGS: "https://www.youtube.com/embed/0-6mJT2Aqy4",
  CUSTOM_COMPONENT: "https://www.youtube.com/embed/BTBKo9YnAQ8",
  MULTI_CURRENCY_FEATURE: "https://www.youtube.com/embed/QMQ9LXBPOBY",
};

export default YoutubeTutorial;
