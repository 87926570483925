import styled from "styled-components/native";
import { colors } from "shared-components/src/utils/constants";
import { Platform } from "react-native";
import { dimenWidth } from "../../utils/helper";

export default styled.View`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  padding: ${Platform.OS === "web" ? "10" : dimenWidth(14)}px;
  ${Platform.OS === "web" && `flex-shrink: initial !important;`}
`;
