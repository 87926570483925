import { ComponentProductModule } from "../../../models/Design/home/ComponentProduct";
import axios from "../api";

export const getGeneralSettingsService = (requestObj: any) => {
  return axios.post, "/api/Design/GetGeneralSettings", requestObj;
};

export const getHomePageService = (requestObj: any) => {
  return axios.post("/Component/api/Component/GetHomePageData", requestObj);
};

export const setGeneralSettingsService = (requestObj: any) => {
  return axios.post("/api/Design/SetGeneralSettings", { ...requestObj });
};

export const getNavigationTypeService = (data: any) => {
  return axios.post("/api/Design/GetNavigationType", data);
};

export const getProductListByCategoryIdService = (
  data: ComponentProductModule.IGetProductListByCategoryIdRequest
) => {
  return axios.post<ComponentProductModule.IGetProductListByCategoryIdResponse>(
    "/Component/api/Component/GetProductDetailsByCategoryData",
    data
  );
};

export const getCategoriesService = (data: any) => {
  return axios.post("/Component/api/Component/GetCategories", data);
};

export const getEditCategoriesService = (data: any) => {
  return axios.post("/Component/api/Component/EditCategories", data);
};

export const saveCategoriesService = (data: any) => {
  return axios.post("/Component/api/Component/SaveCategories", data);
};

export const uploadBase64ImageToUrlService = (data: {
  FileContent: string;
  FileExtension: string;
}) => {
  return axios.post("/api/Design/UploadBase64ImageToUrl", data);
};
