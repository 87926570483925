import { Platform } from "react-native";
import styled from "styled-components/native";

export default styled.ScrollView`
  display: flex;
  flex-direction: row;

  ${Platform.select({
    web: `overflow-x: hidden;`,
  })};
`;
