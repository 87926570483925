import { put, call, takeEvery } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import * as dataActions from "panel/src/redux/actions/saveComponents";
import * as userInfoActions from "panel/src/redux/actions/userInfo";
import * as pageActions from "panel/src/redux/actions/page";
import { SAVE_COMPONENTS_START } from "panel/src/redux/constants/saveComponents";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";

function* saveComponents({ requestObj }: any) {
  yield put(startAppLoading(SAVE_COMPONENTS_START));
  try {
    const response = yield call(
      axios.post,
      "/Component/api/Component/SaveComponents",
      { ...requestObj }
    );
    yield put(dataActions.saveComponentsFinish(response.data));
  } catch (error) {
    yield put(dataActions.saveComponentsRejected(error.message));
  }

  yield put(userInfoActions.getUserInfo());
  yield put(pageActions.pageUpdated(true));
  yield put(endAppLoading(SAVE_COMPONENTS_START));
}

export function* saveComponentsSaga() {
  yield takeEvery(SAVE_COMPONENTS_START, saveComponents);
}
