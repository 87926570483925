import React from "react";
import Overlay, { OverlayProps } from "react-overlays/Overlay";
import Container from "./Container";
import ButtonContainer from "./ButtonContainer";
import InfoMessage from "./InfoMesage";
import Card from "./Card";
import Content from "./Content";
import Arrow from "./Arrow";
import Button from "panel/src/Components/Button";

export interface IInfoCard {
  showInfoCard: boolean;
  setShowInfoCard: Function;
  targetRef: React.RefObject<HTMLDivElement> | null;
  editCardPlacement?: OverlayProps["placement"];
  text: string;
}

const InfoCard = ({
  targetRef,
  showInfoCard,
  setShowInfoCard,
  editCardPlacement,
  text,
}: IInfoCard) => {
  return (
    <Container>
      <Overlay
        show={showInfoCard}
        target={targetRef}
        placement={editCardPlacement || "left"}
      >
        {({ props, arrowProps, placement }) => (
          <Card placement={placement} {...props}>
            <Arrow {...arrowProps} placement={placement} />
            <Content>
              <InfoMessage>{text}</InfoMessage>
            </Content>
            <ButtonContainer>
              <Button
                onClick={() => setShowInfoCard(false)}
                width={"84px"}
                buttonText={"Okay"}
                theme={"white"}
              />
            </ButtonContainer>
          </Card>
        )}
      </Overlay>
    </Container>
  );
};

export default InfoCard;
