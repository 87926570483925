import React, { Component } from "react";
import { Platform } from "react-native";
import styled from "styled-components/native";
import Cart from "../../icons/Cart";
import { ProductAvailabilityDataModel } from "shared-components/src/models/productAvailabilityDataModel";
import { ColorListData } from "shared-components/src/models/colorListData";
import {
  dimenFont,
  dimenHeight,
  dimenWidth,
} from "shared-components/src/utils/helper";
import { shadow } from "shared-components/src/utils/constants";

interface IProps {
  title?: string;
  stock: ProductAvailabilityDataModel["StockAmount"];
  colorList?: ColorListData[];
  fontFamily?: any;
}

interface IState {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  fontFamily?: any;
}

const Container = styled.View`
  ${Platform.OS === "web" ? `padding: 5px 10px;` : `margin: 8px 16px;`}
`;

const Wrapper = styled.View`
  box-shadow: 0px 0px 5px rgba(15, 54, 73, 0.25);
`;

const BlockGroup = styled.View`
  padding: ${Platform.OS === "web" ? "12" : dimenWidth(16)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
`;

const Field = styled.Text<IState>`
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
  color: ${(props) => props.primary};
`;

const StockGroup = styled.View`
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const FieldBold = styled.Text<IState>`
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
  color: ${(props) => props.primary};
`;

const Icon = styled.View`
  margin-right: ${Platform.OS === "web" ? "12" : dimenWidth(16)}px;
`;

class StockAvailability extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      primary: "#0F3649",
      secondary: "#3196F6",
      tertiary: "#0F3649",
    };
  }

  render() {
    const { primary, secondary, tertiary } = this.state;
    const { title, colorList, fontFamily } = this.props;
    return (
      <Container>
        <Wrapper style={Platform.OS === "web" ? {} : shadow}>
          <BlockGroup>
            <Icon>
              <Cart
                fillColor={colorList ? colorList[0].colorCode : primary!}
                height={
                  Platform.OS === "web" ? "29" : dimenHeight(40).toString()
                }
                width={Platform.OS === "web" ? "29" : dimenWidth(40).toString()}
              />
            </Icon>
            <StockGroup>
              <Field
                fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                primary={colorList ? colorList[0].colorCode : primary}
              >
                {title ? title : "Avaliability:"}
              </Field>
              <FieldBold
                fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                primary={colorList ? colorList[0].colorCode : primary}
              >
                {this.props.stock}
              </FieldBold>
            </StockGroup>
          </BlockGroup>
        </Wrapper>
      </Container>
    );
  }
}

export default StockAvailability;
