import React from "react";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
  type: string;
}

const Home = ({ width, height, fillColor }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M14.326 5.062L8.897.475c-.665-.561-1.63-.561-2.295 0L1.174 5.061c-.19.161-.299.399-.299.653v8.63c0 .464.363.845.809.845h3.64c.445 0 .809-.381.809-.845V8.882h3.234v5.462c0 .464.364.845.81.845h3.64c.445 0 .808-.381.808-.845v-8.63c0-.254-.11-.492-.3-.652zm-1.021 8.795h-2.618V8.882c0-.736-.59-1.332-1.32-1.332H6.133c-.73 0-1.32.596-1.32 1.332v4.975H2.195V5.936l5.255-4.44c.174-.147.425-.147.6 0l5.255 4.44v7.921z"
    />
  </svg>
);

export default Home;
