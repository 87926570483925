import styled from "styled-components";

export const NewBadge = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2px;
  align-items: center;
`;

export const NewBadgeTitle = styled.span`
  font-size: 10px;
  font-weight: 700;
  color: #fff;
  flex-direction: row;
  background: #29bb7b;
  padding: 2px 6px;
  margin-right: 5px;
  border-radius: 2px;
`;
