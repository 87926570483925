import React from 'react';
import Icon from 'panel/src/_components/common/Icon';
import Popup from 'reactjs-popup';
import Card from './Card';
import IconContainer from './IconContainer';

const CustomFeature = () => {
  return (
    <Popup
      trigger={(open) => (
        <IconContainer>
          <Icon
            type='star'
            fillColor={open ? '#3196F6' : '#3E5E6E'}
            width='18'
            height='18'
          />
        </IconContainer>
      )}
      position='top center'
      on={['hover']}
      arrow
      arrowStyle={{ color: '#3196f6' }}
    >
      <Card>Custom Feature</Card>
    </Popup>
  );
};

export default CustomFeature;
