import React from "react";
import { Platform, Linking } from "react-native";
import HeaderContainerClickable from "./HeaderContainerClickable";
import HeaderContainer from "./HeaderContainer";
import TitleContainer from "./TitleContainer";
import Header from "./Header";
import Card from "./Card";
import Content from "./Content";
import HeaderIconContainer from "./HeaderIconContainer";
import HeaderIcon from "./HeaderIcon";
import ThumbnailWrapperClickable from "./ThumbnailWrapperClickable";
import ThumbnailWrapper from "./ThumbnailWrapper";
import Thumbnail from "./Thumbnail";
import ArrowRightSmall from "../../icons/ArrowRightSmall";
import { ColorListData } from "shared-components/src/models/colorListData";
import { dimenHeight, dimenWidth } from "shared-components/src/utils/helper";
import { colors } from "shared-components/src/utils/constants";
import Instagram from "shared-components/src/components/SocialMedia/SocialMediaIcons/SVG/instagram.svg";

interface IInstagramShowcase {
  title?: string;
  instagramImages: {
    Username: string | null;
    Images: {
      FileUrl: string;
      ImageUrl: string;
    }[];
    AccountUrl: string;
  };
  colorList?: ColorListData[];
  fontFamily?: any;
}

const InstagramShowcase = ({
  title,
  instagramImages,
  colorList,
  fontFamily,
}: IInstagramShowcase) => {
  const primary = "#0F3649";

  const HeaderWrapper = ({ children }: { children: React.ReactNode }) =>
    instagramImages.AccountUrl !== "" ? (
      <HeaderContainerClickable
        onPress={() => Linking.openURL(instagramImages.AccountUrl)}
      >
        {children}
      </HeaderContainerClickable>
    ) : (
      <HeaderContainer>{children}</HeaderContainer>
    );

  const ThumbnailContainer = ({
    imageUrl,
    children,
  }: {
    imageUrl: string;
    children: React.ReactNode;
  }) =>
    imageUrl !== "" ? (
      <ThumbnailWrapperClickable onPress={() => Linking.openURL(imageUrl)}>
        {children}
      </ThumbnailWrapperClickable>
    ) : (
      <ThumbnailWrapper>{children}</ThumbnailWrapper>
    );

  return (
    <Card>
      <HeaderWrapper>
        <TitleContainer>
          <HeaderIconContainer>
            {Platform.OS === "web" ? (
              <HeaderIcon
                source={require("shared-components/src/components/SocialMedia/SocialMediaIcons/PNG/instagram.png")}
              />
            ) : (
              <Instagram
                height={dimenHeight(40).toString()}
                width={dimenWidth(40).toString()}
              />
            )}
          </HeaderIconContainer>
          <Header
            fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
            primary={colorList ? colorList[0].colorCode : primary}
          >
            {title ? title : "Follow us on Instagram"}
          </Header>
        </TitleContainer>
        <ArrowRightSmall
          fillColor={colorList ? colorList[0].colorCode : colors.darkSlateBlue}
          height={Platform.OS === "web" ? "13" : dimenHeight(20).toString()}
          width={Platform.OS === "web" ? "13" : dimenWidth(20).toString()}
        />
      </HeaderWrapper>
      <Content>
        {instagramImages != null &&
          instagramImages.Images != null &&
          instagramImages.Images.slice(0, 4).map((obj, index) => (
            <ThumbnailContainer imageUrl={obj.ImageUrl} key={index.toString()}>
              <Thumbnail source={{ uri: obj.FileUrl }} />
            </ThumbnailContainer>
          ))}
      </Content>
    </Card>
  );
};

export default InstagramShowcase;
