import React from "react";
import styles from "./errorField.module.scss";

interface IErrorProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  text?: any;
  type?: string;
}

const ErrorField = ({ text, type, ...props }: IErrorProps) => {
  return (
    <div
      style={type === "relative" ? { position: "relative" } : {}}
      className={styles.error}
      {...props}
    >
      <span>{text}</span>
    </div>
  );
};
export default ErrorField;
