import styled from "styled-components";

export default styled.div`
  border-radius: 8px;
  background-color: rgba(230, 101, 101, 0.15);
  padding: 8px 16px;
  margin-top: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: #e66565;
`;
