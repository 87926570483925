/* MANAGE */

export const SET_PLATFORM_INFO: string = "SET_PLATFORM_INFO";
export const SET_APP_START: string = "SET_APP_START";
export const SET_APP_SUCCESS: string = "SET_APP_SUCCESS";
export const SET_APP_FAIL: string = "SET_APP_FAIL";

export const SET_APP_ID: string = "SET_APP_ID";

export const SET_CONFIG_SETTINGS_COMPLETED: string =
  "SET_CONFIG_SETTINGS_COMPLETED";
export const SET_PAYMENT_OPTIONS_COMPLETED: string =
  "SET_PAYMENT_OPTIONS_COMPLETED";
export const SET_ENGAGEMENT_OPTIONS_COMPLETED: string =
  "SET_ENGAGEMENT_OPTIONS_COMPLETED";
export const SET_APP_SHOPIFY: string = "SET_APP_SHOPIFY";
export const SET_APP_INPUTS: string = "SET_APP_INPUTS";
export const SET_APP_GOOGLE_MERCHANT: string = "SET_APP_GOOGLE_MERCHANT";
export const SET_APP_GOOGLE_MERCHANT_SUCCESS: string =
  "SET_APP_GOOGLE_MERCHANT_SUCCESS";
export const SET_APP_GOOGLE_MERCHANT_FAIL: string =
  "SET_APP_GOOGLE_MERCHANT_FAIL";
export const CHECK_APP_INFO: string = "CHECK_APP_INFO";

export const SET_CHANGE_STH_DESIGN_PAGE: string = "SET_CHANGE_STH_DESIGN_PAGE";
