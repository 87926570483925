import React, { useState, useEffect, useRef } from "react";
import classes from "./dropdown.module.scss";
import { useHistory } from "react-router-dom";
import more from "../../../assets/images/more.svg";
import Icon from "../Icon";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

interface IProps {
  options: any;
  value: string;
  placeholder: string;
  field?: any;
  form?: any;
  name?: string;
  renameAppHandler: Function;
  deleteAppHandler: Function;
  createAppHandler: Function;
  history?: History;
}

const Dropdown = (props: IProps) => {
  const history = useHistory();
  const selectNode: any = useRef();
  const [isOpen, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    props.options &&
      props.options.find((option: any) => option.value === props.value)
  );
  const [hideCreateApp, setHideCreateApp] = useState(
    props.options && props.options.length > 1
  );
  const [isCreateNewAppOver, setCreateNewAppOver] = useState(false);
  const [appOption, setAppOption] = useState("");
  useEffect(() => {
    props.value
      ? setSelectedOption(
          props.options.find((option: any) => option.value === props.value)
        )
      : setSelectedOption(
          props.options ? props.options[props.options.length - 1] : ""
        );
  });

  useEffect(() => {
    setHideCreateApp(props.options && props.options.length > 1);
  });

  useEffect(() => {
    document.addEventListener("click", clickOutside);

    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);

  const clickOutside = (e: any) => {
    if (selectNode.current && !selectNode.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const selectOption = (option: any) => {
    setSelectedOption(option);
    setOpen(false);
    history.push(`/reroute/${option.value}`);
  };

  const createAppHandler = (e: any) => {
    e.preventDefault();
    history.push(`/welcome`);
    props.createAppHandler();
  };
  const renameAppHandler = (
    e: any,
    { id, name }: { id: string; name: string }
  ) => {
    props.renameAppHandler(id, name);
  };
  const deleteAppHandler = (
    e: any,
    { id, name }: { id: string; name: string }
  ) => {
    props.deleteAppHandler(id, name);
  };

  const onMouseOver = (option: string) => {
    setAppOption(option);
  };
  const onMouseOut = (option: string) => {
    setAppOption("");
  };

  const attachedClasses = [];
  const fieldName = props.field && props.field.name;
  const isError =
    props.form && props.form.touched
      ? props.form.touched[fieldName]
      : undefined;
  const errorMessage =
    props.form && props.form.errors ? props.form.errors[fieldName] : undefined;

  if (isError) {
    attachedClasses.push(classes.Invalid);
  }

  return (
    <div className={classes.HeaderDropdown} ref={selectNode}>
      <div className={classes.menuActivator} onClick={() => setOpen(!isOpen)}>
        {selectedOption ? (
          <span className={`${classes.DropdownInput}`}>
            {selectedOption.displayValue}
          </span>
        ) : (
          <span className={classes.Empty}>{props.placeholder}</span>
        )}
        <Icon
          className={`${classes.arrowIcon} ${isOpen && classes.rotateIcon}`}
          type="arrowDown"
          fillColor="#ffffff"
          width="10"
          height="10"
        />
      </div>

      {isOpen && (
        <div className={classes.menuWrapper}>
          <ul className={classes.menu}>
            <div className={classes.appMenu}>
              {props.options.map((option: any) => (
                <li
                  key={option.value}
                  onMouseOver={() => onMouseOver(option.value)}
                  onMouseOut={() => onMouseOut(option.value)}
                >
                  <div className={classes.section}>
                    <div
                      className={classes.optionGroup}
                      onClick={() =>
                        option.value !== selectedOption.value &&
                        selectOption(option)
                      }
                    >
                      <span
                        className={`${classes.appName} ${
                          selectedOption &&
                          option.value === selectedOption.value
                            ? classes.activeApp
                            : ""
                        }`}
                      >
                        {option.displayValue}
                      </span>
                      <span className={classes.createdDate}>
                        {new Date(option.createDate).toUTCString()}
                      </span>
                    </div>
                    <ContextMenuTrigger id={option.value} holdToDisplay={0}>
                      {
                        <img
                          className={`${classes.optionMenu} ${
                            appOption !== option.value ? classes.optionImg : ""
                          }`}
                          src={more}
                        />
                      }
                    </ContextMenuTrigger>
                    <ContextMenu
                      className={classes.contextMenu}
                      id={option.value}
                    >
                      <MenuItem
                        data={{ id: option.value, name: option.displayValue }}
                        className={classes.contextMenuItem}
                        onClick={renameAppHandler}
                      >
                        Rename
                      </MenuItem>
                      <MenuItem
                        data={{ id: option.value, name: option.displayValue }}
                        className={classes.contextMenuItem}
                        onClick={deleteAppHandler}
                      >
                        Delete
                      </MenuItem>
                    </ContextMenu>
                  </div>
                </li>
              ))}
              {!hideCreateApp && (
                <a
                  className={classes.createNewApp}
                  href="#"
                  onClick={createAppHandler}
                  onMouseOver={() => setCreateNewAppOver(true)}
                  onMouseOut={() => setCreateNewAppOver(false)}
                >
                  <Icon
                    className={classes.addIcon}
                    type="plus"
                    fillColor={isCreateNewAppOver ? "#3196f6" : "#9FAFB6"}
                    width="16"
                    height="16"
                  />
                  <span className={classes.text}>Create a New Project</span>
                </a>
              )}
            </div>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
