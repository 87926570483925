import React, { MouseEventHandler, ReactNode } from "react";
import styles from "./button.module.scss";

interface IButtonProps {
  width?: string;
  height?: string;
  marginTop?: string;
  clickHandler?: MouseEventHandler;
  disabled?: boolean;
  color?: string;
  children?: ReactNode;
  onClick?: any;
  type?: any;
  disableButton?: any;
  loadingSpinner?: boolean;
  id?: string;
  className?: string;
  fontSize?: string;
  marginRigth?: string;
  backgroundColor?: string;
  borderColor?: string;
  marginBottom?: string;
  customRef?: any;
  style?: any;
}

const Button = ({
  width = "100%",
  height = "100%",
  color = "Primary",
  disabled = false,
  onClick = () => {},
  type = "button",
  className = "",
  children,
  id = "",
  loadingSpinner = false,
  disableButton = false,
  fontSize = "14px",
  marginRigth,
  marginTop,
  backgroundColor,
  borderColor,
  marginBottom,
  customRef,
  style,
}: IButtonProps) => {
  return (
    <button
      ref={customRef}
      style={{
        width: width,
        height: height,
        fontSize: fontSize,
        marginRight: marginRigth,
        marginTop: marginTop,
        backgroundColor,
        borderColor,
        marginBottom,
        ...style,
      }}
      className={`${styles.Button}  ${styles[color]} ${styles[width]} ${styles[height]} ${styles[className]}`}
      id={`${id}-button`}
      onClick={onClick}
      type={type}
      disabled={disableButton}
    >
      {loadingSpinner ? <span className={styles.loader}></span> : children}
    </button>
  );
};

export default Button;
