import IGetFontTypeDataRequest from "../../models/Design/fontType/IGetFontTypeDataRequest";

import {
  GET_FONT_TYPE_DATA,
  GET_FONT_TYPE_DATA_FAIL,
  GET_FONT_TYPE_DATA_SUCCESS,
  RESET_FONT_TYPE_DATA,
} from "panel/src/redux/constants/fontType";

interface BaseAction {
  type: string;
  data?: Object;
  error?: string;
  requestObj?: IGetFontTypeDataRequest;
}

export const getFontTypeData = (
  requestObj: IGetFontTypeDataRequest
): BaseAction => ({
  type: GET_FONT_TYPE_DATA,
  requestObj,
});

export const getFontTypeDataSuccess = (data: Object): BaseAction => ({
  type: GET_FONT_TYPE_DATA_SUCCESS,
  data,
});

export const getFontTypeDataFail = (error: string): BaseAction => ({
  type: GET_FONT_TYPE_DATA_FAIL,
  error,
});

export const resetFontTypeData = () => ({
  type: RESET_FONT_TYPE_DATA,
});
