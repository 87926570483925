import axios from "panel/src/services/api/api";
import * as platformListActions from "panel/src/redux/actions/platformList";
import { GET_PLATFORM_LIST_START } from "panel/src/redux/constants/platformList";
import { takeLatest, put, call } from "redux-saga/effects";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";

function* getPlatformListProcess() {
  yield put(startAppLoading("GET_PLATFORMLIST_PROCESS"));
  try {
    const { data } = yield call(
      axios.get,
      "/api/Manage/GetPlatformAdapterList"
    );
    yield put(
      platformListActions.getPlatformListSuccess(
        data.ResultData.PlatformAdaptors
      )
    );
  } catch (error) {
    yield put(platformListActions.getPlatformListRejected(error));
  }
  yield put(endAppLoading("GET_PLATFORMLIST_PROCESS"));
}

export default function* platformListProcessSaga() {
  yield takeLatest(GET_PLATFORM_LIST_START, getPlatformListProcess);
}
