import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import IGetHomeDataRequest from "../../models/Design/home/IGetHomeDataRequest";
import { IHomeData } from "../../models/Design/home/IGetHomeDataResponse";
import {
  getHomeDataSuccess,
  getHomeDataFail,
} from "panel/src/redux/actions/Home";
import { GET_HOME_DATA } from "panel/src/redux/constants";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import notification from "panel/src/utils/notification";
import { getCategories } from "../actions/category";

function* getHomeData(payload: {
  requestObj: IGetHomeDataRequest;
  type: string;
}) {
  yield put(startAppLoading(GET_HOME_DATA));
  try {
    const request = {
      ApplicationId: payload.requestObj.applicationId,
      SsoUserId: payload.requestObj.userId,
      PlatformId: payload.requestObj.platformId,
    };

    const [homePageData, colorsData, fontTypeData]: [IHomeData, any, any] =
      yield all([
        call(axios.post, "/Component/api/Component/GetHomePageData", request),
        call(axios.post, "/api/Design/GetColors", {
          ApplicationId: request.ApplicationId,
        }),
        call(axios.post, "/api/Manage/GetFonts", {
          ApplicationId: request.ApplicationId,
        }),
        put(getCategories()),
      ]);

    const homePage = {
      home: homePageData.data.ResultData,
      colors: colorsData.data.ResultData,
      fonts: fontTypeData.data.ResultData,
    };

    yield put(getHomeDataSuccess(homePage));
  } catch (error) {
    notification.error(
      "We are temporarily unable to process transactions at this time. We apologize for the inconvenience.",
      "/manage/config-settings"
    );
    yield put(getHomeDataFail(error.message));
  }
  yield put(endAppLoading(GET_HOME_DATA));
}

export default function* getHomeDataSaga() {
  yield takeLatest(GET_HOME_DATA, getHomeData);
}
