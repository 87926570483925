import styled from "styled-components";

export default styled.div`
  padding: 15px;
  max-height: 500px;
  overflow: auto;

  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
