import {
  GET_SPLASH_SCREEN_START,
  GET_SPLASH_SCREEN_FINISH,
  GET_SPLASH_SCREEN_REJECTED,
  SET_SPLASH_SCREEN_START,
  SET_SPLASH_SCREEN_FINISH,
  SET_SPLASH_SCREEN_REJECTED,
} from "panel/src/redux/constants/splashScreen";

const initialState = {
  splashScreenData: {}
};

const splashScreenReducer = ( state: Object = initialState, action: any ) => {
  switch ( action.type ) {
    case GET_SPLASH_SCREEN_START:
      return {
        ...state,
      };

    case GET_SPLASH_SCREEN_FINISH:
      return {
        ...state,
        splashScreenData: action.splashScreenData,
      };
    case GET_SPLASH_SCREEN_REJECTED:
      return {
        ...state,
        error: action.error,
      };

    case SET_SPLASH_SCREEN_START:
      return {
        ...state,
        splashScreenObj: action.splashScreenObj,
      };

    case SET_SPLASH_SCREEN_FINISH:
      return {
        ...state,
      };
    case SET_SPLASH_SCREEN_REJECTED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default splashScreenReducer;
