import {
    GET_NAVIGATION_TYPE_START,
    GET_NAVIGATION_TYPE_FINISH,
    GET_NAVIGATION_TYPE_REJECTED,
    SET_NAVIGATION_TYPE_START,
    SET_NAVIGATION_TYPE_FINISH,
    SET_NAVIGATION_TYPE_REJECTED,
    ADD_NAVIGATION_TYPE_ICON,
} from "panel/src/redux/constants/navigationType";

interface BaseAction {
    type: string;
    generalSettings?: any;
    error?: any;
    appId?: any;
}

export const getNavigationTypeStart = (appId: any) => {
    return {
        type: GET_NAVIGATION_TYPE_START,
        appId,
    };
};

export const getNavigationTypeFinish = (navigationType: any) => {
    return {
        type: GET_NAVIGATION_TYPE_FINISH,
        navigationType,
    };
};

export const getNavigationTypeRejected = (error: string) => ({
    type: GET_NAVIGATION_TYPE_REJECTED,
    error,
});

export const setNavigationTypeStart = (navigationObj: any) => {
    return {
        type: SET_NAVIGATION_TYPE_START,
        navigationObj,
    };
};

export const setNavigationTypeFinish = () => {
    return {
        type: SET_NAVIGATION_TYPE_FINISH,
    };
};

export const setNavigationTypeRejected = (error: string) => ({
    type: SET_NAVIGATION_TYPE_REJECTED,
    error,
});

export const addNavigationTypeIcon = (iconUrl: string) => {
    return {
        type: ADD_NAVIGATION_TYPE_ICON,
        payload: iconUrl,
    };
};
