import React from "react";
import { Linking, TouchableOpacity, Platform } from "react-native";
import { dimenHeight, dimenWidth } from "shared-components/src/utils/helper";
import { colors } from "shared-components/src/utils/constants";
import { ColorListData } from "shared-components/src/models/colorListData";
import HeaderContainer from "./HeaderContainer";
import Header from "./Header";
import Card from "./Card";
import Content from "./Content";
import ImageWrapper from "./ImageWrapper";
import Image from "./Image";
import { SocialMedias, socialMediaEnum } from "./SocialMedias";
import ArrowRightSmall from "../../icons/ArrowRightSmall";
import Facebook from "./SocialMediaIcons/SVG/facebook.svg";
import Instagram from "./SocialMediaIcons/SVG/instagram.svg";
import Pinterest from "./SocialMediaIcons/SVG/pinterest.svg";
import TwitterX from "./SocialMediaIcons/SVG/twitter-x.svg";
import Swarm from "./SocialMediaIcons/SVG/swarm.svg";
import Tumblr from "./SocialMediaIcons/SVG/tumblr.svg";
import Youtube from "./SocialMediaIcons/SVG/youtube.svg";
import Tiktok from "./SocialMediaIcons/SVG/tiktok.svg";
import { Source } from "react-native-fast-image-web-support";

interface ISocialMedia {
  title?: string;
  socialMedias: { socialMediaType: number; url: string }[];
  colorList?: ColorListData[];
  fontFamily?: any;
}

const SocialMedia = ({
  title,
  socialMedias,
  colorList,
  fontFamily,
}: ISocialMedia) => {
  const renderSocialMediaIcon = (socialMediaType: number) => {
    let icon;
    const width = Platform.OS === "web" ? "32" : dimenWidth(48).toString();
    const height = Platform.OS === "web" ? "32" : dimenHeight(48).toString();

    switch (socialMediaType) {
      case socialMediaEnum.Facebook:
        return <Facebook height={height} width={width} />
      case socialMediaEnum.Instagram:
        return <Instagram height={height} width={width} />
      case socialMediaEnum.Pinterest:
        return <Pinterest height={height} width={width} />
      case socialMediaEnum.TwitterX:
        return <TwitterX height={height} width={width} />
      case socialMediaEnum.Swarm:
        return <Swarm height={height} width={width} />
      case socialMediaEnum.Tumblr:
        return <Tumblr height={height} width={width} />
      case socialMediaEnum.Youtube:
        return <Youtube height={height} width={width} />
      case socialMediaEnum.Tiktok:
        return <Tiktok height={height} width={width} />
      default:
        return <Facebook height={height} width={width} />
    }

    return icon;
  };

  return (
    <Card>
      <HeaderContainer>
        <Header
          fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
          primary={colorList ? colorList[0].colorCode : colors.darkSlateBlue}
        >
          {title ? title : "Follow us"}
        </Header>
      </HeaderContainer>
      <Content horizontal showsHorizontalScrollIndicator={false}>
        {socialMedias.map((item, index) => (
          <ImageWrapper
            key={index.toString()}
            style={
              index !== socialMedias.length - 1 && {
                marginRight: Platform.OS === "web" ? 12 : dimenWidth(12),
              }
            }
          >
            <TouchableOpacity onPress={() => Linking.openURL(item.url)}>
              {Platform.OS === "web" ? (
                <Image source={SocialMedias[item.socialMediaType] as Source} />
              ) : (
                renderSocialMediaIcon(item.socialMediaType)
              )}
            </TouchableOpacity>
          </ImageWrapper>
        ))}
      </Content>
    </Card>
  );
};

export { socialMediaEnum };
export default SocialMedia;
