import { put, call, takeLatest, select, all } from "redux-saga/effects";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import {
  getNotificationsService,
  sendNotificationService,
} from "../../services/api/manageApp/notification";
import { NotificationModule } from "../../models/Notification";
import {
  GET_NOTIFICATIONS,
  SEND_NOTIFICATION,
} from "../constants/notifications";
import { getNotifications, setNotifications } from "../actions/notification";
import { AxiosResponse } from "axios";
import { ResponseModel } from "../../models/responseModel";
import notification from "panel/src/utils/notification";
import { getHomePageService } from "../../services/api/design";
import { getHomeDataSuccess } from "../actions/Home";
import { validURL } from "../../utils/regex";

function* onSendNotification(action: {
  notification: NotificationModule.Notification;
  type: string;
}) {
  yield put(startAppLoading(SEND_NOTIFICATION));
  try {
    const { selectedAppId } = yield select((state) => state.userInfo);

    let image = action.notification.image || null;
    if (image && !validURL(image!)) {
      image = image?.replace(/data:image\/.*?;base64,/gm, "");
    }

    const { data }: AxiosResponse<ResponseModel> = yield call(
      sendNotificationService,
      {
        Title: action.notification.title,
        Body: action.notification.body,
        Image: image,
        CategoryId: action.notification.CategoryId,
        ProductId: action.notification.ProductId,
        ProductName: action.notification.ProductName,
        AppId: selectedAppId,
        SendTime: null,
      }
    );
    if (data && data.ResultCode === 100) {
      yield put(getNotifications());
      yield call(notification.success, "Successfully sent notification.");
    } else {
      notification.error(data.ResultData);
    }
  } catch (error) {}
  yield put(endAppLoading(SEND_NOTIFICATION));
}

function* onGetNotifications() {
  yield put(startAppLoading(SEND_NOTIFICATION));
  try {
    const { selectedAppId, userId, selectedApp } = yield select(
      (state) => state.userInfo
    );

    const request = {
      ApplicationId: selectedAppId,
      SsoUserId: userId,
      PlatformId: selectedApp.PlatformId,
    };

    const [homePageDataResponse, notificationResponse]: [
      any,
      AxiosResponse<ResponseModel>
    ] = yield all([
      call(getHomePageService, request),
      call(getNotificationsService, selectedAppId),
    ]);

    if (notificationResponse.data) {
      yield put(setNotifications(notificationResponse.data.ResultData));
    }

    const homePage = {
      home: homePageDataResponse.data.ResultData,
    };

    yield put(getHomeDataSuccess(homePage));
  } catch (error) {}
  yield put(endAppLoading(SEND_NOTIFICATION));
}

export default function* notificationSaga() {
  yield takeLatest(SEND_NOTIFICATION, onSendNotification);
  yield takeLatest(GET_NOTIFICATIONS, onGetNotifications);
}
