import {
  GET_PLATFORM_LIST_START,
  GET_PLATFORM_LIST_SUCCESS,
  GET_PLATFORM_LIST_FAIL,
} from "panel/src/redux/constants/platformList";

interface BaseLoginAction {
  type: string;
  token?: any;
  error?: any;
  loading?: any;
}

export const getPlatformListStart = () => {
  return {
    type: GET_PLATFORM_LIST_START,
  }
};

export const getPlatformListSuccess = ( PlatformAdaptors: any ) => {
  return {
    type: GET_PLATFORM_LIST_SUCCESS,
    PlatformAdaptors
  }
};

export const getPlatformListRejected = ( error: any ) => {
  return {
    type: GET_PLATFORM_LIST_FAIL,
    error
  }
};
