import styled from "styled-components";
import { OS } from "panel/src/Components/DragnDrop/Emulator/ToggleOS";

export const HomeScreenHeader = styled.div`
  width: 100%;
  padding: 40px 20px 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  max-heigth : 200px !important;
`;

export const Clock = styled.span`
  margin-top: 5px;
  font-size: 52px;
  font-weight: 300;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  line-height: 1.24em;
`;

export const DateText = styled.span`
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

export const HomeScreenContent = styled.div`
  padding: 7px 5px;
  max-height: 340px;
  overflow: hidden;
`;

interface NotificationProps {
  size?: "small" | "large";
}

export const Notification = styled.div<NotificationProps>`
  width: 100%;
  border-radius: 11px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  flex-direction: column;
  .notification_top {
    padding: 8px 7px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.android {
      flex-wrap: wrap;
      align-items: flex-start;
      .left {
        width: 100%;
        padding-left: 3px;
        .icon {
          width: 100%;
          background: none !important;
          height: 19px;
          img {
            width: 12px;
            height: 12px;
          }
          .android_header {
            display: flex;
            align-items: center;
            span.android_app_name {
              color: #5b5b5b;
              font-size: 9px;
              margin-left: 5px;
            }
            span.time {
              position: relative;
              color: #222222;
              font-size: 9px;
              margin-left: 11px;
              &:before {
                content: "";
                background: #222222;
                position: absolute;
                width: 2px;
                height: 2px;
                left: -6px;
                top: 51%;
                transform: translateY(-50%);
              }
              i {
                margin-left: 3px;
              }
              i.arrow_up{
                svg{
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
      .middle {
        padding-left: 3px;
        padding-bottom: 3px;
        h5{
          max-width: 200px !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }
        p {
          max-width: 200px !important;
          -webkit-line-clamp: 1 !important;
        }
      }
      .right{
        height:25px;
        img{
          width:25px;
          height:25px;
          margin-left:5px;
        }
      }
    }
    .left {
      .icon {
        width: 24px;
        height: 24px;
        border-radius: 5px;
        background: #ddd;
        margin-right: 8px;
        display: block;
        img {
          width: 24px;
          height: 24px;
          display: block;
        }
      }
    }
    .lineClamp {
      -webkit-line-clamp: 4;
    }
    .middle {
      display: flex;
      flex-direction: column;
      flex: 1;
      h5 {
        font-size: 9px;
        font-weight: 600;
        color: #000;
        font-stretch: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 155px;
      }
      p {
        font-size: 9px;
        color: #3f3f3f;
        max-width: 155px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .right {
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      
      img {
        width: 21px;
        height: 21px;
        object-fit: cover;
        border-radius: 5px;
      }
    }
  }
  .notification_bottom {
    margin-top: 3px;
    img {
      width: 100%;
      height: 132px;
      object-fit: cover;
      display: block;
    }
    &.ios {
      img {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
      }
    }
    &.android {
      padding: 10px;
      padding-top: 0;
      img {
        max-height: 98px;
      }
    }
  }
  .times{
    font-size: 8px;
    font-weight: 500;
    text-align: right;
    position: absolute;
    right: 0;
    padding: 5px 30px 0px;
  }
  .title{
    white-space: nowrap;
  }
  .imageOS{
    margin-top: 10px;
  }

`;

interface IAppIcon {
  activeOS: OS.IOS | OS.Android;
}

export const AppIcon = styled.img<IAppIcon>`
  align-self: center;
  width: 24px;
  height: 24px;
  ${(props) => props.activeOS === OS.IOS && "border-radius: 5px;"}
`;
