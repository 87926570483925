import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { dimenHeight } from 'shared-components/src/utils/helper';

export default styled.View`
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  padding: 16px;
`;
