import { put, call, takeLatest } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import {
  getComponentJSONFail,
  getComponentJSONSuccess,
  resetComponentJSON,
} from "panel/src/redux/actions/getComponentJSON";
import { resetChange } from "panel/src/redux/actions/Home";
import {
  endAppLoading,
  setAppError,
  startAppLoading,
} from "panel/src/redux/actions/App";
import { GET_COMPONENT_JSON } from "panel/src/redux/constants/getComponentJSON";
import notification from "../../utils/notification";

function* getComponentJSON(payload: { requestObj: any; type: string }) {
  yield put(startAppLoading(GET_COMPONENT_JSON));
  try {
    const componentJSON: any = yield call(
      axios.post,
      "/Component/api/Component/GetComponentJson",
      {
        ApplicationId: payload.requestObj.applicationId,
        ComponentId: payload.requestObj.componentId,
        Json: payload.requestObj.formData,
      }
    );

    if (componentJSON.data.ResultCode === 200) {
      const componentJSONData = {
        data: componentJSON.data.ResultData,
      };
      yield put(getComponentJSONSuccess(componentJSONData));
    } else {
      const errorMessage =
        componentJSON.data.ResultMesage ||
        "We encountered a problem. Please try again";
      notification.error(errorMessage);
      yield put(getComponentJSONFail(errorMessage));
      yield put(resetComponentJSON());
      yield put(resetChange());
    }
  } catch (error) {
    const errorMessage =
      error?.response?.data?.ResultMesage ||
      "We encountered a problem. Please try again";
    notification.error(errorMessage);
    yield put(getComponentJSONFail(errorMessage));
    yield put(resetComponentJSON());
    yield put(resetChange());
  }
  yield put(endAppLoading(GET_COMPONENT_JSON));
}

export default function* getComponentJSONSaga() {
  yield takeLatest(GET_COMPONENT_JSON, getComponentJSON);
}
