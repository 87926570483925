import { Platform } from "react-native";
import styled from "styled-components/native";
import { dimenFont } from "shared-components/src/utils/helper";
interface IHeader {
  primary?: string;
  fontFamily?: any;
}
export default styled.Text<IHeader>`
  font-size: ${Platform.OS === "web" ? "10.4" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
  color: ${(props) => props.primary};
`;
