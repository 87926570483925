import {
  GET_ENGAGEMENT_INFO_START,
  GET_ENGAGEMENT_INFO_SUCCESS,
  GET_ENGAGEMENT_INFO_FAIL,
  SET_ENGAGEMENT_INFO_START,
  SET_ENGAGEMENT_INFO_SUCCESS,
  SET_ENGAGEMENT_INFO_FAIL,
} from "panel/src/redux/constants/getEngagementInfo";

interface paymentOptionsAction {
  type: string;
  token?: any;
  error?: any;
  loading?: any;
}

export const getEngagementInfoStart = ( data: any ) => {
  return {
    type: GET_ENGAGEMENT_INFO_START,
    data
  }
};

export const getEngagementInfoSuccess = ( engagementData: Object ) => {
  return {
    type: GET_ENGAGEMENT_INFO_SUCCESS,
    engagementData
  }
};

export const getEngagementInfoRejected = ( error: any ) => {
  return {
    type: GET_ENGAGEMENT_INFO_FAIL
  }
};

export const setEngagementInfoStart = ( data: any ) => {
  return {
    type: SET_ENGAGEMENT_INFO_START,
    data
  }
};

export const setEngagementInfoSuccess = () => {
  return {
    type: SET_ENGAGEMENT_INFO_SUCCESS
  }
};

export const setEngagementInfoRejected = ( error: any ) => {
  return {
    type: SET_ENGAGEMENT_INFO_FAIL,
    error
  }
};
