import axios from "../api";

export const checkLoyaltyToken = async (
  Token: string,
  ApplicationId: string,
  Email: string,
  LoyaltyType: number
) => {
  return await axios.post("/ConfigService/api/Home/CheckLoyaltyToken", {
    ApplicationId,
    Token,
    Email,
    LoyaltyType,
  });
};
