import * as Yup from "yup";
import { appNameRequiredMessage } from "../../utils/validationMessage";

const Schema = Yup.object().shape({
  appName: Yup.string()
    .max(30, "Max character is 30.")
    .required(appNameRequiredMessage),
});

export default Schema;
