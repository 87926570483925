import React from 'react';
import classes from './StepsToggler.module.scss';

const stepsToggler = ( props: any ) => {
  const text = props.isDetailVisible ? 'Hide details' : 'See details';

  const attachedClasses = [classes.StepsToggler];
  if ( props.isDetailVisible ) {
    attachedClasses.push( classes.Dark );
  } else {
    attachedClasses.push( classes.Light );
  }

  return (
    <span
      className={attachedClasses.join( ' ' )}
      onClick={props.toggleHandler}
    >
      {text}
    </span>
  );
}

export default stepsToggler;