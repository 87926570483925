import React from "react";
import { FlatList } from "react-native";
import Card from "./Card";
import Header from "./Header";
import TitleContainer from "./TitleContainer";
import CategoryTitleWrapper from "./CategoryTitleWrapper";
import CategoryTitle from "./CategoryTitle";
import ThumbnailContainer from "./ThumbnailContainer";
import ThumbnailWrapper from "./ThumbnailWrapper";
import Thumbnail from "./Thumbnail";
import { ColorListData } from "shared-components/src/models/colorListData";
import { colors } from "shared-components/src/utils/constants";

interface ICategorySliderProps {
  colorList?: ColorListData[];
  fontFamily?: any;
  categoryList: {
    ComponentTitle: string;
    Items: {
      Title: string;
      CategoryId: string;
      Products: {
        IsInWishList: boolean;
        Price: { Price: number; Currency: string };
        ProductId: string;
        Images: {
          ImgUrl: string;
        }[];
      }[];
    }[];
  };
  handleProductSearch?: Function;
  imageResizeType: "cover" | "contain";
}

interface ICategorySliderState {
  selectedIndex: number;
}

// Shared projesindeki hatadan ötürü functional componentler içerisinde
// hooks kullanımına izin verilmiyor. Bunun sebebi shared içerisindeki
// React sürümü ile Panel içerisindeki React sürümünün çakışması veya
// Aynı anda çalışıyor olmaları. Bunun önüne geçilene kadar geçici olarak
// functional component yerine class olarak yazılmıştır.
class CategorySlider extends React.Component<
  ICategorySliderProps,
  ICategorySliderState
> {
  constructor(props: ICategorySliderProps) {
    super(props);
    this.state = {
      selectedIndex: 0,
    };
  }

  handleChangeCategory = (i: number) => {
    this.setState({ selectedIndex: i });
  };

  isCurrentCategory = (i: number) => (this.state.selectedIndex === i ? 1 : 0);

  render() {
    const { selectedIndex } = this.state;
    const {
      categoryList,
      handleProductSearch,
      colorList,
      fontFamily,
      imageResizeType,
    } = this.props;
    return (
      <Card>
        <Header
          fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
          primary={colorList ? colorList[0].colorCode : colors.darkSlateBlue}
        >
          {categoryList.ComponentTitle}
        </Header>
        <TitleContainer horizontal showsHorizontalScrollIndicator={false}>
          {categoryList.Items.map((category, index) => (
            <CategoryTitleWrapper
              isactive={this.isCurrentCategory(index)}
              primary={
                colorList ? colorList[0].colorCode : colors.darkSlateBlue
              }
              key={index.toString()}
            >
              <CategoryTitle
                fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                primary={
                  colorList ? colorList[0].colorCode : colors.darkSlateBlue
                }
                isactive={this.isCurrentCategory(index)}
                onPress={() => this.handleChangeCategory(index)}
              >
                {category.Title}
              </CategoryTitle>
            </CategoryTitleWrapper>
          ))}
        </TitleContainer>
        {categoryList.Items.map(
          (category, index) =>
            selectedIndex === index && (
              <ThumbnailContainer key={index.toString()}>
                <FlatList
                  data={category.Products}
                  renderItem={({ item }) => (
                    <ThumbnailWrapper
                      key={index.toString()}
                      onPress={() =>
                        handleProductSearch &&
                        handleProductSearch(item.ProductId)
                      }
                    >
                      <Thumbnail
                        source={{ uri: item.Images[0].ImgUrl }}
                        resizeMode={imageResizeType}
                      />
                    </ThumbnailWrapper>
                  )}
                  keyExtractor={(item, index) => index.toString()}
                  numColumns={2}
                  style={{ flex: 1 }}
                  columnWrapperStyle={{
                    flex: 1,
                    justifyContent: "space-around",
                    marginBottom: 16,
                  }}
                />
              </ThumbnailContainer>
            )
        )}
      </Card>
    );
  }
}

export default CategorySlider;
