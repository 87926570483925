import React, { Component } from "react";
import { Platform, TouchableOpacity } from "react-native";
import styled from "styled-components/native";
import { InstallmentDetailsDataModel } from "shared-components/src/models/installmentDetailsDataModel";
import { ColorListData } from "shared-components/src/models/colorListData";
import { dimenFont, dimenWidth } from "shared-components/src/utils/helper";
import { colors, shadow } from "shared-components/src/utils/constants";
import FastImage from "react-native-fast-image-web-support";
interface IProps {
  data: InstallmentDetailsDataModel;
  colorList?: ColorListData[];
  onPressViewAll?: Function;
  fontFamily?: any;
  showInstallmentsScreen?: Function;
  titleText?: string;
  viewAllText?: string;
}

interface IState {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  fontFamily?: any;
}

const Container = styled.View`
  ${Platform.OS === "web" ? `margin: 5px 10px;` : `margin: 8px 16px;`}
`;

const Wrapper = styled.View`
  ${Platform.OS === "web"
    ? `display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2.9px;
    padding: 11px 16px;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(15, 54, 73, 0.25);`
    : `
    border-radius: ${dimenWidth(4)}px;
    background-color: ${colors.white};
    padding: ${dimenWidth(16)}px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 5px rgba(15, 54, 73, 0.25);
    `}
`;

const BlockGroup = styled.View`
  ${Platform.OS === "web"
    ? `width: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 6px;`
    : ``}
`;

const Field = styled.Text<IState>`
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  color: ${(props: IState) => props.primary};
`;

const InstallmentListView = styled.View`
  ${Platform.OS === "web"
    ? `display: flex;
    width: 100%;`
    : `
    display: flex;
    `}
`;

const ItemGroup = styled.View`
  ${Platform.OS === "web"
    ? `width: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 8px 0;`
    : `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${dimenWidth(16)}px;
    `}
`;

const FieldBold = styled.Text<IState>`
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  color: ${(props: IState) => props.primary};
`;

const ViewAllContainer = styled.View<IState>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin-top: ${Platform.OS === "web" ? "12" : dimenFont(20)}px;
`;

const Link = styled.Text<IState>`
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
  font-size: ${Platform.OS === "web" ? 10.1 : dimenFont(14)}px;
  color: ${(props: IState) => props.secondary};
`;

const Icon = styled(FastImage)`
  ${Platform.OS === "web"
    ? `display: flex;
     width: 46px;`
    : `
    width: ${dimenWidth(64)}px;
    resizeMode: contain;
    `}
`;

class InstallmentDetails extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      primary: "#0F3649",
      secondary: "#3196F6",
      tertiary: "#0F3649",
    };
  }

  render() {
    const {
      data: { InstallmentList, SummaryInstallmentList },
      colorList,
      fontFamily,
    } = this.props;
    const { primary, secondary, tertiary } = this.state;
    return (
      <Container>
        <Wrapper style={Platform.OS === "web" ? {} : shadow}>
          <BlockGroup>
            <Field
              fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
              primary={colorList ? colorList[0].colorCode : primary}
            >
              {this.props.titleText ? this.props.titleText : "Monthly from"}
            </Field>
          </BlockGroup>
          <InstallmentListView>
            {SummaryInstallmentList &&
              SummaryInstallmentList.map((item) =>
                item.Installments.map((installment: any) => (
                  <ItemGroup key={item.Code}>
                    <Icon source={{ uri: item.BankLogoUrl }} />
                    <FieldBold
                      fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                      primary={
                        this.props.colorList
                          ? this.props.colorList[0].colorCode
                          : primary
                      }
                    >
                      {`${installment.InstallmentNo} x ${installment.MonthlyPrice} = ${installment.TotalPrice}`}
                    </FieldBold>
                  </ItemGroup>
                ))
              )}
          </InstallmentListView>
          <ViewAllContainer>
            <TouchableOpacity
              onPress={() =>
                this.props.showInstallmentsScreen &&
                this.props.showInstallmentsScreen(InstallmentList)
              }
            >
              <Link
                fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                secondary={colorList ? colorList[1].colorCode : secondary}
              >
                {this.props.viewAllText
                  ? this.props.viewAllText
                  : "View all options"}
              </Link>
            </TouchableOpacity>
          </ViewAllContainer>
        </Wrapper>
      </Container>
    );
  }
}

export default InstallmentDetails;
