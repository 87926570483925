import * as Yup from "yup";
import { askDemoLinkEmailErrorMessage } from "../../../utils/validationMessage";

let Schema = Yup.object().shape({
  emailList: Yup.array().of(
    Yup.object().shape({
      email: Yup.string()
        .trim()
        .email(askDemoLinkEmailErrorMessage)
        .required(askDemoLinkEmailErrorMessage),
    })
  ),
});

export default Schema;
