import React from "react";

interface IProps {
    width: string;
    height: string;
    fillColor: string;
    type: string;
}

const Back = ({ width, height, fillColor }: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 19">
        <path fill={fillColor} fillRule="evenodd" d="M.243 10.517c-.168-.167-.25-.389-.243-.609v-.025c0-.313.174-.584.43-.723l5.832-5.916c.318-.323.836-.326 1.157-.007.321.318.325.837.007 1.16L2.827 9.062h14.355c.452 0 .818.368.818.82 0 .454-.366.82-.818.82H2.798l4.612 4.611c.32.32.32.84.002 1.16-.32.321-.837.322-1.157.002L.436 10.659c-.069-.036-.134-.084-.193-.142z" />
    </svg>
);

export default Back;
