import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
// COMPONENTS START
import ScreenSelection from "../components/ScreenSelection";
import Content from "./Content";
import ComponentsContainer from "./ComponentsContainer";
import Components, {
  IComponent,
} from "../../../Components/DragnDrop/Components";
import Emulator from "../../../Components/DragnDrop/Emulator";
// COMPONENTS END

//REDUX ACTIONS START
import { resetHomeData, resetChange } from "panel/src/redux/actions/Home";
import { resetBannerData } from "panel/src/redux/actions/bannerSettings";
import {
  saveScreenData,
  resetSaveScreenData,
} from "panel/src/redux/actions/SaveScreen";
import {
  getComponentJSON,
  resetComponentJSON,
} from "panel/src/redux/actions/getComponentJSON";
// REDUX ACTIONS END

// SHARED COMPONENTS START
import {
  Rayon,
  Slider,
  LargeCategoryView,
  CategorySlider,
  BrandList,
  LinkListview,
  SmallCategoryView,
  InstagramShowcase,
  SocialMedia,
  socialMediaEnum,
  LargeBanner,
  MediumBanner,
  SmallBanner,
  CustomHtml,
} from "shared-components/src/App";
// SHARED COMPONENTS END

// EDIT FORMS START
import RayonEditForm from "./EditForms/Rayon";
import SliderEditForm from "./EditForms/Slider";
import LargeCategoryViewEditForm from "./EditForms/LargeCategoryView";
import CategorySliderEditForm from "./EditForms/CategorySlider";
import LinkListviewEditForm from "./EditForms/LinkListview";
import InstagramShowcaseEditForm from "./EditForms/InstagramShowcase";
import SocialMediaEditForm from "./EditForms/SocialMedia";
import BannerEditForm from "./EditForms/Banner";
import HtmlEditForm from "./../utils/EditForms/Html";
// EDIT FORMS END

//MODELS START
import IGetHomeDataRequest from "panel/src/models/Design/home/IGetHomeDataRequest";
import { IHomeData } from "panel/src/models/Design/home/IGetHomeDataResponse";
import {
  IColorData,
  ColorList,
} from "../../../models/Design/colors/IGetColorsPageDataResponse";
import ISaveScreenModel, {
  ISaveScreenComponent,
} from "panel/src/models/SaveScreenModel";
import SelectModel from "panel/src/models/Design/selectModel";
//MODELS END

import { PageType } from "panel/src/utils/enums/pageType";
import { ScreenType } from "panel/src/utils/enums/screenType";
import { ComponentType } from "panel/src/utils/enums/componentType";
import { screenNumbers } from "../constants";
import { ELinkTypes } from "../../../utils/enums/design";
import SmallCategoryEditForm from "./EditForms/SmallCategory";
import { getDesignData } from "../../../redux/actions/design";
import Modal from "../../../_components/common/Modal";
import UpgradeGuide from "../../../Components/UpgradeGuide";
import classes from "../design.module.scss";
import close from "../../../assets/images/close.svg";
import Button from "../../../_components/common/Button";
import UpgradeModal from "../../../Components/UpgradeModal";
import { usePackage } from "../../../hooks";
import { getNavigationMenu } from "../../../redux/actions/navigationMenu";

interface IHome {
  appId: string;
  platformId: number;
  userId: string;
  homeData: IHomeData["data"]["ResultData"];
  saveScreenRes: boolean | number;
  _getHomeData: Function;
  _resetHomeData: Function;
  _saveScreenData: Function;
  _resetSaveScreenData: Function;
  _resetBannerData: Function;
  _resetChange: Function;
  isCompletion: boolean;
  selectedValue: string;
  designScreens: SelectModel[];
  designScreensChange: Function;
  selectChange: Function;
  loading: boolean;
  reset: boolean;
  colors: IColorData;
  bannerChange: boolean;
  componentChange: boolean;
  _getComponentJSON: Function;
  changedComponent: any;
  changedComponentData: any;
  _resetComponentJSON: Function;
  fonts: any;
  isOnModalSubmit: boolean;
  setError: Function;
}

const linkTypes = [
  { label: "Category", value: ELinkTypes.Category },
  { label: "Product", value: ELinkTypes.Product },
  { label: "Href", value: ELinkTypes.Href },
  { label: "Web View URL", value: ELinkTypes.WebViewURL },
  { label: "Search", value: ELinkTypes.Search },
];

export const requiredEditForms = [
  ComponentType.HomeSlider,
  ComponentType.InstagramShowcase,
  ComponentType.SocialMedia,
  ComponentType.SmallBanner,
  ComponentType.MediumBanner,
  ComponentType.LargeBanner,
  ComponentType.CategorySlider,
];

const Home = ({
  appId,
  platformId,
  userId,
  homeData,
  _getHomeData,
  _resetHomeData,
  _saveScreenData,
  _resetSaveScreenData,
  _resetChange,
  _resetBannerData,
  saveScreenRes,
  isCompletion,
  selectedValue,
  designScreens,
  designScreensChange,
  selectChange,
  loading,
  reset,
  colors,
  bannerChange,
  componentChange,
  _getComponentJSON,
  changedComponent,
  changedComponentData,
  _resetComponentJSON,
  fonts,
  isOnModalSubmit,
  setError,
}: IHome) => {
  const packageHook = usePackage();
  const dispatch = useDispatch();
  useEffect(() => {
    getScreenComponentsData();
    dispatch(getNavigationMenu());
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [noComponentSelectedError, setNoComponentSelectedError] =
    useState(false);
  const [selectedComponents, setSelectedComponents] = useState<
    (IComponent | undefined)[]
  >([]);
  const [componentsList, setComponentsList] = useState<
    (IComponent | undefined)[]
  >([]);
  const [componentsData, setComponentsData] =
    useState<IHomeData["data"]["ResultData"]["Components"] | undefined>(
      undefined
    );
  const [selectedColorList, setSelectedColorList] = useState<ColorList[]>([]);
  const [selectedFont, setSelectedFont] = useState("");
  const [isUpgradeGuide, setIsUpgradeGuide] = useState<boolean>(false);
  const [isSaveModal, setIsSaveModal] = useState<boolean>(false);
  const [isOpenUpgradeModal, setIsOpenUpgradeModal] = useState<boolean>(false);

  useEffect(() => {
    packageHook.setSelectedComponents({
      home: selectedComponents.length ?? 0,
    });
  }, [selectedComponents]);

  useEffect(() => {
    if (homeData && !reset && !loading) {
      const colorList = colors?.selectedColorModel
        ? colors?.selectedColorModel.colorList
        : [];

      setSelectedColorList(colorList);
      const selectedFontFamily = fonts?.find((opt: any) => opt.IsUsed);
      const selectedFontName = selectedFontFamily
        ? selectedFontFamily.Name
        : "Helvetica Neue";
      setSelectedFont(selectedFontName);
      const componentsData = homeData.Components;
      setComponentsData(componentsData);
    }
  }, [homeData]);

  useEffect(() => {
    if (componentsData && !reset && !loading) {
      const selectedComponentsList = homeData.SelectedComponents.map((obj) => {
        return getComponent(
          obj.ComponentId,
          JSON.parse(obj.ResponseJSON),
          JSON.parse(obj.CustomJSON)
        );
      }).filter((obj) => obj !== undefined);

      setSelectedComponents(selectedComponentsList);
      if (
        packageHook.isPackageFeaturesLimited &&
        packageHook.usedComponents.total > packageHook.packageFeaturesLimited
      ) {
        setIsUpgradeGuide(true);
      }

      const componentsList = homeData.Components.map((obj) => {
        const tempComponent = getComponent(
          obj.ComponentId,
          JSON.parse(obj.ResponseJSON)
        );
        if (tempComponent) tempComponent.IsNewBadge = obj.IsNewBadge;
        return tempComponent;
      }).filter((obj) => obj !== undefined);
      setComponentsList(componentsList);
      setIsLoading(false);
    }
  }, [componentsData]);

  useEffect(() => {
    if (saveScreenRes) {
      setIsLoading(true);
      _resetHomeData();
      _resetSaveScreenData();
    }
  }, [saveScreenRes]);

  //when component change
  useEffect(() => {
    if (changedComponentData) {
      const _selectedComponents: any = selectedComponents.map((obj, index) => {
        return {
          Id:
            [4, 25, 6, 1, 7, 4, 6, 16].includes(obj!.componentId) === false &&
            obj!.editForm
              ? obj!.Id
              : obj!.componentId === changedComponent.componentId &&
                index === changedComponent.index
              ? obj!.Id
              : [7, 4, 6, 16, 25].includes(obj!.componentId) && obj!.editForm
              ? -1
              : obj!.Id,
          componentId: obj!.componentId,
          component: obj!.component,
          PageId: 1,
          DisplayOrder: index + 1,
          JSON:
            [4, 25, 6, 1, 7, 4, 6, 16].includes(obj!.componentId) === false &&
            obj!.editForm
              ? obj!.editForm.editFormData
              : obj!.componentId === changedComponent.componentId
              ? JSON.parse(changedComponentData.JSON)
              : JSON.parse(
                  homeData.Components.filter((a: any) => {
                    if (a.ComponentId === obj!.componentId) {
                      return JSON.parse(a.ResponseJSON);
                    }
                  })[0].ResponseJSON
                ),
          ref: obj!.ref,
        };
      });

      const selectedComponentsList = _selectedComponents.map((obj: any) => {
        return getComponent(obj.componentId, obj.JSON);
      });

      const elements = _selectedComponents
        .map((item: any) => {
          if (item.Id === -1) {
            return item.DisplayOrder;
          }
        })
        .filter((a: any) => {
          return a != undefined;
        });

      const newSelectedComponentList = selectedComponentsList.map(
        (obj: any, index: any) => {
          if (elements.includes(index + 1)) {
            return selectedComponents[index];
          } else {
            return selectedComponentsList[index];
          }
        }
      );
      setSelectedComponents(newSelectedComponentList);
      _resetChange();
    }
  }, [changedComponentData]);

  useEffect(() => {
    return () => {
      _resetChange();
      _resetComponentJSON();
      _resetHomeData();
      _resetSaveScreenData();
    };
  }, []);

  const getComponent = (
    componentId: number,
    JSON: any,
    CustomJSON?: any
  ): IComponent | undefined => {
    let component: IComponent | undefined;
    switch (componentId) {
      case ComponentType.Rayon:
        const rayonList = JSON;
        component = {
          componentId: ComponentType.Rayon,
          pageId: PageType.Home,
          componentTitle: "Rayon",
          component: (
            <Rayon
              fontFamily={selectedFont}
              colorList={selectedColorList}
              imageResizeType={rayonList.imageResizeType}
              rayonList={rayonList}
            />
          ),
          editForm: {
            component: <RayonEditForm categories={homeData.Categories} />,
            isShown: false,
            reRenderComponent: (editedJSON: any) =>
              getComponent(componentId, editedJSON),
            rawFormData: rayonList,
            editFormData: {
              Title: rayonList.Title,
              CategoryId: rayonList.CategoryId,
              GroupId: rayonList.GroupId,
              maxProductCount: rayonList.maxProductCount,
              imageResizeType: rayonList.imageResizeType,
            },
          },
        };
        break;
      case ComponentType.HomeSlider:
        component = {
          componentId: ComponentType.HomeSlider,
          pageId: PageType.Home,
          componentTitle: "Slider",
          component: (
            <Slider Items={JSON.Items} imageResizeType={JSON.imageResizeType} />
          ),
          editForm: {
            component: (
              <SliderEditForm
                categories={homeData.Categories}
                linkTypes={linkTypes}
              />
            ),
            isShown: false,
            reRenderComponent: (editedJSON: any) =>
              getComponent(componentId, editedJSON),
            rawFormData: CustomJSON ? CustomJSON : JSON,
            editFormData: CustomJSON ? CustomJSON : JSON,
          },
        };
        break;
      case ComponentType.LargeCategoryView:
        component = {
          componentId: ComponentType.LargeCategoryView,
          pageId: PageType.Home,
          componentTitle: "Large Category View",
          component: (
            <LargeCategoryView
              fontFamily={selectedFont}
              colorList={selectedColorList}
              imageResizeType={JSON.imageResizeType}
              category={JSON}
            />
          ),
          editForm: {
            component: (
              <LargeCategoryViewEditForm categories={homeData.Categories} />
            ),
            isShown: false,
            reRenderComponent: (editedJSON: any) =>
              getComponent(componentId, editedJSON),
            rawFormData: JSON,
            editFormData: {
              Title: JSON.Title,
              CategoryId: JSON.CategoryId,
              imageResizeType: JSON.imageResizeType,
            },
          },
        };
        break;
      case ComponentType.CategorySlider:
        component = {
          componentId: ComponentType.CategorySlider,
          pageId: PageType.Home,
          componentTitle: "Category Slider",
          component: (
            <CategorySlider
              fontFamily={selectedFont}
              colorList={selectedColorList}
              imageResizeType={JSON.imageResizeType}
              categoryList={JSON}
            />
          ),
          editForm: {
            component: (
              <CategorySliderEditForm categories={homeData.Categories} />
            ),
            isShown: false,
            reRenderComponent: (editedJSON: any) =>
              getComponent(componentId, editedJSON),
            rawFormData: JSON,
            editFormData: JSON,
          },
        };
        break;
      case ComponentType.BrandList:
        component = {
          componentId: ComponentType.BrandList,
          pageId: PageType.Home,
          componentTitle: "Brand List",
          component: (
            <BrandList
              fontFamily={selectedFont}
              colorList={selectedColorList}
              brands={JSON.Brands}
            />
          ),
          editForm: null,
        };
        break;
      case ComponentType.LinkListView:
        component = {
          componentId: ComponentType.LinkListView,
          pageId: PageType.Home,
          componentTitle: "Link Listview",
          component: (
            <LinkListview
              fontFamily={selectedFont}
              colorList={selectedColorList}
              linkList={JSON.Items}
            />
          ),
          editForm: {
            component: (
              <LinkListviewEditForm
                categories={homeData.Categories}
                productGroups={homeData.ProductGroups}
              />
            ),
            isShown: false,
            editFormData: JSON,
          },
        };
        break;
      case ComponentType.SmallCategoryView:
        const CategoryJSON = CustomJSON ? CustomJSON : JSON;
        component = {
          componentId: ComponentType.SmallCategoryView,
          pageId: PageType.Home,
          componentTitle: "Small Category View",
          component: (
            <SmallCategoryView
              fontFamily={selectedFont}
              colorList={selectedColorList}
              categories={CategoryJSON.Categories}
              title={CategoryJSON?.Name}
            />
          ),
          editForm: {
            component: (
              <SmallCategoryEditForm categories={homeData.Categories} />
            ),
            isShown: false,
            rawFormData: CategoryJSON,
            editFormData: CategoryJSON,
          },
        };
        break;
      case ComponentType.InstagramShowcase:
        component = {
          componentId: ComponentType.InstagramShowcase,
          pageId: PageType.Home,
          componentTitle: "Instagram Showcase",
          component: (
            <InstagramShowcase
              fontFamily={selectedFont}
              colorList={selectedColorList}
              instagramImages={JSON}
            />
          ),
          editForm: {
            component: <InstagramShowcaseEditForm />,
            isShown: false,
            editFormData: { accountUrl: JSON.AccountUrl },
          },
        };
        break;
      case ComponentType.SocialMedia:
        let socialMediaData = JSON;
        component = {
          componentId: ComponentType.SocialMedia,
          pageId: PageType.Home,
          componentTitle: "Social Media Icons",
          component: (
            <SocialMedia
              fontFamily={selectedFont}
              colorList={selectedColorList}
              socialMedias={socialMediaData.socialMediaPlatforms}
            />
          ),
          editForm: {
            component: (
              <SocialMediaEditForm
                socialMedias={[
                  { label: "Tiktok", value: socialMediaEnum.Tiktok },
                  { label: "Facebook", value: socialMediaEnum.Facebook },
                  { label: "Instagram", value: socialMediaEnum.Instagram },
                  { label: "Pinterest", value: socialMediaEnum.Pinterest },
                  { label: "X", value: socialMediaEnum.TwitterX },
                  { label: "Swarm", value: socialMediaEnum.Swarm },
                  { label: "Tumblr", value: socialMediaEnum.Tumblr },
                  { label: "Youtube", value: socialMediaEnum.Youtube },

                ]}
              />
            ),
            isShown: false,
            editFormData: socialMediaData,
          },
        };
        break;
      case ComponentType.LargeBanner:
        component = {
          componentId: ComponentType.LargeBanner,
          pageId: PageType.Home,
          componentTitle: "Large banner",
          component: (
            <LargeBanner
              bannerInfo={JSON}
              imageResizeType={JSON.imageResizeType}
            />
          ),
          editForm: {
            component: (
              <BannerEditForm
                categories={homeData.Categories}
                height="459"
                linkTypes={linkTypes}
              />
            ),
            isShown: false,
            reRenderComponent: (editedJSON: any) =>
              getComponent(componentId, editedJSON),
            rawFormData: JSON,
            editFormData: JSON,
          },
        };
        break;
      case ComponentType.MediumBanner:
        component = {
          componentId: ComponentType.MediumBanner,
          pageId: PageType.Home,
          componentTitle: "Medium banner",
          component: (
            <MediumBanner
              bannerInfo={JSON}
              imageResizeType={JSON.imageResizeType}
            />
          ),
          editForm: {
            component: (
              <BannerEditForm
                categories={homeData.Categories}
                height="252"
                linkTypes={linkTypes}
              />
            ),
            isShown: false,
            reRenderComponent: (editedJSON: any) =>
              getComponent(componentId, editedJSON),
            rawFormData: JSON,
            editFormData: JSON,
          },
        };
        break;
      case ComponentType.SmallBanner:
        component = {
          componentId: ComponentType.SmallBanner,
          pageId: PageType.Home,
          componentTitle: "Small banner",
          component: (
            <SmallBanner
              bannerInfo={JSON}
              imageResizeType={JSON.imageResizeType}
            />
          ),
          editForm: {
            component: (
              <BannerEditForm
                categories={homeData.Categories}
                height="144"
                linkTypes={linkTypes}
              />
            ),
            isShown: false,
            reRenderComponent: (editedJSON: any) =>
              getComponent(componentId, editedJSON),
            rawFormData: JSON,
            editFormData: JSON,
          },
        };
        break;
      case ComponentType.CustomHtml:
        component = {
          componentId: ComponentType.CustomHtml,
          pageId: PageType.Home,
          componentTitle: "Custom Component",
          component: (
            <CustomHtml
              htmlInfo={JSON}
              colorList={selectedColorList}
              fontFamily={selectedFont}
            />
          ),
          editForm: {
            editCardPlacement: "right-start",
            editCardWidth: "265px",
            component: <HtmlEditForm fontFamily={selectedFont} />,
            isShown: false,
            reRenderComponent: (editedJSON: any) =>
              getComponent(componentId, editedJSON),
            rawFormData: JSON,
            editFormData: { ...JSON },
          },
        };
        break;
      default:
        break;
    }
    return component;
  };

  const getScreenComponentsData = () => {
    const homeComponentsDataReqObj: IGetHomeDataRequest = {
      applicationId: appId,
      userId: userId,
      platformId: platformId,
    };

    _getHomeData(homeComponentsDataReqObj);
  };

  const saveScreenComponents = () => {
    if (
      packageHook.isPackageFeaturesLimited &&
      packageHook.usedComponents.total > packageHook.packageFeaturesLimited &&
      !isSaveModal
    ) {
      setIsSaveModal(true);
      return;
    }

    setNoComponentSelectedError(false);
    setIsLoading(true);

    const _selectedComponents: ISaveScreenComponent[] = packageHook
      .handleExceptComponents(selectedComponents)
      .map((obj, index) => ({
        ComponentId: obj!.componentId,
        PageId: obj!.pageId,
        DisplayOrder: index + 1,
        JSON: obj!.editForm ? JSON.stringify(obj!.editForm.editFormData) : null,
      }));

    if (!selectedComponents.length) {
      setNoComponentSelectedError(true);
      setError(true);
      setIsLoading(false);
      return;
    }

    if (handleEmptyEditForms(_selectedComponents)) {
      setIsLoading(false);
      return;
    }

    const saveScreenDataReqObj: ISaveScreenModel = {
      ApplicationId: appId,
      PageId: PageType.Home,
      Components: _selectedComponents,
    };
    _saveScreenData(saveScreenDataReqObj);
    if (!isOnModalSubmit && selectedValue === screenNumbers.home) {
      selectChange(ScreenType.ProductList.toString());
    }
  };

  const handleEmptyEditForms = (
    saveScreenComponents: ISaveScreenComponent[]
  ) => {
    const _saveScreenComponents = saveScreenComponents
      .filter((obj) => obj.JSON === null)
      .filter((obj) => requiredEditForms.includes(obj.ComponentId));

    if (_saveScreenComponents.length) return true;

    return false;
  };

  useEffect(() => {
    if (componentChange) {
      _getComponentJSON(changedComponent);
    }
  }, [componentChange]);

  useEffect(() => {
    if (bannerChange) {
      const _selectedComponents: any = selectedComponents.map((obj, index) => {
        return {
          Id:
            [4, 25, 6, 1, 7, 4, 6, 16].includes(obj!.componentId) === false
              ? obj!.Id
              : -1,
          componentId: obj!.componentId,
          component: obj!.component,
          PageId: obj!.pageId,
          DisplayOrder: index + 1,
          JSON:
            [4, 25, 6, 1].includes(obj!.componentId) === false && obj!.editForm
              ? obj!.editForm.editFormData
              : JSON.parse(
                  homeData.Components.filter((a: any) => {
                    if (a.ComponentId === obj!.componentId) {
                      return JSON.parse(a.ResponseJSON);
                    }
                  })[0].ResponseJSON
                ),
          ref: obj!.ref,
        };
      });
      const selectedComponentsList = _selectedComponents.map((obj: any) => {
        return getComponent(obj.componentId, obj.JSON, obj.CustomJSON);
      });
      const elements = _selectedComponents
        .map((item: any) => {
          if (item.Id === -1) {
            return item.DisplayOrder;
          }
        })
        .filter((a: any) => {
          return a != undefined;
        });
      const newSelectedComponentList = selectedComponentsList.map(
        (obj: any, index: any) => {
          if (elements.includes(index + 1)) {
            return selectedComponents[index];
          } else {
            return selectedComponentsList[index];
          }
        }
      );

      setSelectedComponents(newSelectedComponentList);
      _resetBannerData();
    }
  }, [bannerChange]);

  useEffect(() => {
    if (isOnModalSubmit && selectedValue === screenNumbers.home) {
      saveScreenComponents();
    }
  }, [isOnModalSubmit]);

  const handleItemDropped = (e: any, handleOnDrop: Function) => {
    e.payload.isNewAdded = true;
    if (
      packageHook.isPackageFeaturesLimited &&
      packageHook.usedComponents.total >= packageHook.packageFeaturesLimited
    ) {
      setIsUpgradeGuide(true);
    }
    if (
      packageHook.isPackageFeaturesLimited &&
      e?.payload?.componentId === ComponentType.CustomHtml
    ) {
      setIsOpenUpgradeModal(true);
    }
    handleOnDrop(e);
  };
  const closeUpgradeModal = () => {
    setIsUpgradeGuide(false);
  };
  const closeSaveModal = () => {
    setIsSaveModal(false);
  };

  return (
    <Content>
      {!isLoading ? (
        <React.Fragment>
          <ComponentsContainer>
            {!isCompletion && (
              <ScreenSelection
                options={designScreens}
                selectedValue={selectedValue}
                selectChangeHandler={designScreensChange}
              />
            )}
            <Components
              infoText={
                "Start designing your app by dragging and dropping the components below"
              }
              errorMessage={
                "You have to select one of these components below for your home page."
              }
              components={componentsList}
              hasError={noComponentSelectedError}
              componentTotalCount={packageHook.usedComponents.total}
            />
          </ComponentsContainer>
          <Emulator
            screen="Home"
            fontFamily={selectedFont}
            navigateBack={selectChange}
            backTo={ScreenType.CategoryLayout.toString()}
            appScreen={{
              screen: "Home",
              selectedComponents,
              setSelectedComponents,
              requiredEdit: requiredEditForms,
              itemDropped: handleItemDropped,
            }}
            saveScreen={saveScreenComponents}
            navigation={{
              navigationType: homeData.Navigation.SelectedNavigationType,
              navigationBarIcon: homeData.Navigation.NavigationBarIcon,
            }}
            showInfoCard
          />
        </React.Fragment>
      ) : null}
      <Modal show={isUpgradeGuide} width="300">
        <UpgradeGuide screen="components" setIsOpenModal={closeUpgradeModal} />
      </Modal>
      <Modal
        //backdropClickedHandler={() => closeSaveModal()}
        show={isSaveModal}
        width="448"
      >
        <div className={classes.Close} onClick={() => closeSaveModal()}>
          <img src={close} />
        </div>
        <div className={classes.ModalBody}>
          <span className={classes.ModalBodyText}>
            The changes you make will not be saved. Do you want to continue?
          </span>
        </div>
        <div className={classes.ModalFooter}>
          <div className={classes.ButtonContainer}>
            <Button
              type="button"
              className="Ghost"
              width="192px"
              onClick={closeSaveModal}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="Primary"
              width="192px"
              onClick={() => {
                setTimeout(() => {
                  saveScreenComponents();
                }, 1);
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <UpgradeModal
        setIsOpenModal={setIsOpenUpgradeModal}
        isOpenModal={isOpenUpgradeModal}
      />
    </Content>
  );
};

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    platformId: state.userInfo.selectedApp.PlatformId,
    userId: state.userInfo.userId,
    homeData: state.home.data.home,
    colors: state.home.data.colors,
    fonts: state.home.data.fonts,
    loading: state.home.loading,
    reset: state.home.reset,
    bannerChange: state.bannerSettings.bannerChange,
    saveScreenRes: state.saveScreen.data,
    changedComponentData: state.componentJSON.data.data,
    componentChange: state.home.changes,
    changedComponent: state.home.changedComponent,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _getHomeData: (reqObject: IGetHomeDataRequest) =>
      dispatch(getDesignData(reqObject)),
    _getComponentJSON: (reqObject: any) =>
      dispatch(getComponentJSON(reqObject)),
    _resetHomeData: () => dispatch(resetHomeData()),
    _saveScreenData: (reqObject: ISaveScreenModel) =>
      dispatch(saveScreenData(reqObject)),
    _resetSaveScreenData: () => dispatch(resetSaveScreenData()),
    _resetBannerData: () => dispatch(resetBannerData()),
    _resetChange: () => dispatch(resetChange()),
    _resetComponentJSON: () => dispatch(resetComponentJSON()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
