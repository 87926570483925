import React from 'react';

interface IProps {
    width: string,
    height: string,
    fillColor: string,
    type: string
}

const ArrowRight = ({ width, height, fillColor }: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <path fill={fillColor} fillRule="evenodd" d="M18 12c.009-.434-.153-.872-.486-1.207L9.246 2.501c-.657-.66-1.733-.67-2.403-.022-.67.647-.68 1.706-.023 2.365L13.954 12 6.82 19.156c-.658.66-.647 1.718.023 2.365.67.647 1.746.637 2.403-.022l8.268-8.292c.333-.335.495-.773.486-1.207z" />
    </svg>
);

export default ArrowRight;
