import React, { useEffect } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as autoSignupActions from "./redux/actions/userInfo";
import Login from "./screens/Login";
import ForgotPassword from "./screens/ForgotPassword";
import ForgotPasswordLink from "./screens/ForgotPasswordLink";
import NewPassword from "./screens/NewPassword";
import Welcome from "./screens/Welcome";
import ReRoute from "./screens/ReRoute";
import Manage from "./screens/Manage";
import Design from "./screens/Design";
import PushNotification from "./screens/PushNotification";
import Engage from "./screens/Engage";
import PublicRoute from "./plugins/PublicRoute";
import ProtectedRoute from "./plugins/ProtectedRoute";
import NotFound from "./screens/NotFound";
import Publish from "./screens/Publish";
import Spinner from "./_components/common/Spinner";
import { getToken } from "./utils/helper";
import Begin from "./screens/Manage/WebServiceControl/Begin";
import ManageConfigSettings from "./screens/Manage/ConfigSettings";
import PaymentOptions from "./screens/Manage/PaymentOptions";
import EngagementOptions from "./screens/Manage/EngagementOptions";
import Completion from "./screens/Manage/Completion";
import Verify from "./screens/Manage/WebServiceControl/Verify";
import AskDemoLink from "./screens/Publish/AskDemoLink";
import Qr from "./screens/Publish/Qr";
import InstallToStore from "./screens/Publish/InstallToStore";
import EditContent from "./screens/Engage/ContentSettings/EditContent";
import CreateNewLanguage from "./screens/Engage/ContentSettings/CreateNewLanguage";
import CreateContentLanguage from "./screens/Engage/ContentSettings/CreateNewLanguage/CreateContentLanguage";
import EditContentLanguage from "./screens/Engage/ContentSettings/EditContent/EditContentLanguage";
import BannerSettings from "./screens/Engage/BannerSettings";
import EngageConfigSettings from "./screens/Engage/ConfigSettings";
import InstagramSuccess from "./screens/Design/Home/EditForms/InstagramShowcase/redirectScreen";
import { hotjar } from "react-hotjar";
import { navigateAway, isScreenRestricted } from "panel/src/utils/helper";
import { Helmet } from "react-helmet";
import { routeNames } from "panel/src/utils/constants";
import MobileWarn from "panel/src/screens/Mobile";
import PublishStepHelper from "panel/src/screens/Publish/StepHelper";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

declare global {
  interface Window {
    jQuery: JQueryStatic;
    HubSpotConversations: any;
    hsConversationsOnReady: any;
    hsConversationsSettings: any;
    _hsq: any;
    hbspt: any;
  }
}
const redirectRoutes: string[] = [
  "/manage",
  "/manage/config-settings",
  "/manage/web-service-control/verify",
  "/manage/payment-options",
  "/welcome",
  "/manage/engagement-options",
  "/manage/web-service-control/begin",
  "/completion",
  "/manage/completion",
  "/design",
  "/publish",
  "/design/publish/qr",
  // "/publish/install-to-store",
  // "/design/publish/install-to-store",
  "/push-notification",
  "/engage/content-settings/edit-content",
];

declare global {
  interface JQueryStatic {
    signalR: any;
    connection: any;
    hubConnection: any;
  }
}

interface IAppProps {
  onTryAutoSignup?: any;
  isAuthenticated: boolean;
  actions?: any;
  history?: any;
  userId: string;
  userInfo?: any;
  selectedApp: any;
}

const App = ({ actions, userId, selectedApp, userInfo }: IAppProps) => {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    hotjar.initialize(1786185, 6);
    const token = localStorage.getItem("AUTH_TOKEN");

    if (token) {
      actions.fetchUserInfoStart(token);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      const whereUserLeft = navigateAway(selectedApp);

      if (selectedApp !== undefined || selectedApp.AppId !== undefined) {
        const currentPath = history.location.pathname;
        const pathKey = currentPath.split("/")[1].toString();

        if (pathKey === "instagram-success") {
          history.push(currentPath + history.location.search);
          return;
        }

        const isRestricted = isScreenRestricted(whereUserLeft, `/${pathKey}`);
        if (currentPath !== "/" && isRestricted) {
          return;
        }
      }
      history.push(whereUserLeft);
    }
  }, [selectedApp, userId]);

  var _hsq = (window._hsq = window._hsq || []);

  const width = window.innerWidth;
  useEffect(() => {
    const currentPath = history.location.pathname;
    const isRedirected = redirectRoutes.some((route) =>
      route.includes(currentPath)
    );
    const emailAddress = userInfo.emailAddress;
    if (isRedirected && emailAddress) {
      axios.post(
        `${process.env.REACT_APP_API_URL}/api/Manage/SendUserProgressMail`,
        {
          Email: emailAddress,
          Url: window.location.href,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
          },
        }
      );
    }
  }, [history.location.pathname]);
  return (
    <div className="app_container">
      {!(
        location.pathname === "/login" ||
        location.pathname === "/forgotpassword" ||
        location.pathname === "/newpassword"
      ) &&
        width < 1024 && <MobileWarn />}
      <Spinner.AutoWithRedux />
      <Helmet
        onChangeClientState={() => {
          _hsq.push(["setPath", `/design${location.pathname}`]);
          _hsq.push(["trackPageView"]);
        }}
      >
        <title>{`Mowico Wizard Panel | ${
          routeNames[location.pathname]
        }`}</title>
      </Helmet>
      <Switch>
        <ProtectedRoute exact path="/" Component={Welcome} />
        <PublicRoute exact path="/login" Component={Login} />
        <PublicRoute exact path="/forgotpassword" Component={ForgotPassword} />
        <PublicRoute
          exact
          path="/forgotpasswordlink"
          Component={ForgotPasswordLink}
        />
        <PublicRoute exact path="/newpassword" Component={NewPassword} />
        <ProtectedRoute
          exact
          path="/instagram-success"
          Component={InstagramSuccess}
        />
        <ProtectedRoute exact path="/welcome" Component={Welcome} />
        <ProtectedRoute exact path="/reroute/:appId" Component={ReRoute} />
        <ProtectedRoute exact path="/manage" Component={Manage} />
        <ProtectedRoute
          exact
          path="/manage/web-service-control/begin"
          Component={Begin}
        />
        <ProtectedRoute
          exact
          path="/manage/web-service-control/verify"
          Component={Verify}
        />
        <ProtectedRoute
          exact
          path="/manage/config-settings"
          Component={ManageConfigSettings}
        />
        <ProtectedRoute
          exact
          path="/manage/payment-options"
          Component={PaymentOptions}
        />
        <ProtectedRoute
          exact
          path="/manage/engagement-options"
          Component={EngagementOptions}
        />
        <ProtectedRoute
          exact
          path="/manage/completion"
          Component={Completion}
        />
        <ProtectedRoute exact path="/publish" Component={Publish} />
        <ProtectedRoute
          exact
          path="/publish/ask-demo-link"
          Component={AskDemoLink}
        />
        <ProtectedRoute exact path="/publish/qr" Component={Qr} />
        <ProtectedRoute
          exact
          path="/publish/install-to-store"
          Component={InstallToStore}
        />
        <ProtectedRoute path="/design" Component={Design} />
        <ProtectedRoute
          path="/push-notification"
          Component={PushNotification}
        />
        <ProtectedRoute exact path="/engage" Component={Engage} />
        <ProtectedRoute
          exact
          path="/engage/banner-settings"
          Component={BannerSettings}
        />
        <ProtectedRoute
          exact
          path="/engage/config-settings"
          Component={EngageConfigSettings}
        />
        <ProtectedRoute
          exact
          path="/engage/content-settings/edit-content"
          Component={EditContent}
        />
        <ProtectedRoute
          exact
          path="/engage/content-settings/create-new-language"
          Component={CreateNewLanguage}
        />
        <ProtectedRoute
          exact
          path="/engage/content-settings/edit-content/:rLanguageId/:rLanguageName/:cLanguageId/:cLanguageName"
          Component={EditContentLanguage}
        />
        <ProtectedRoute
          exact
          path="/engage/content-settings/create-new-language/:rLanguageId/:rLanguageName/:cLanguageId/:cLanguageName"
          Component={CreateContentLanguage}
        />

        <ProtectedRoute
          exact
          path="/publish/step-helper"
          Component={PublishStepHelper}
        />

        <Route component={NotFound} />
      </Switch>
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: !!getToken(),
    userId: state.userInfo.userId,
    userInfo: state.userInfo,
    selectedApp: state.userInfo.selectedApp,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators(autoSignupActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
