import React from "react";
import Svg, { Path } from "react-native-svg";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
}

const HeartFill = ({ width, height, fillColor }: IProps) => (
  <Svg width={width} height={height} viewBox="0 0 24 24">
    <Path
      fill={fillColor}
      fillRule="evenodd"
      d="M16.9396663,2.00000467 C20.8347963,1.99555064 23.9950451,5.17933051 23.9999895,9.11269789 C24.0129627,18.1525975 12.0172606,23.0000047 12.0172606,23.0000047 C12.0172606,23.0000047 11.9769125,22.9838121 11.9008921,22.9515437 L11.7606918,22.8910989 C10.0199439,22.1301369 0.0102002569,17.3958372 2.70431653e-06,9.14417113 C-0.00337036639,5.21106602 3.14908821,2.01679507 7.04317954,2.01259864 C8.97230761,2.01023815 10.720442,2.79182345 11.9946688,4.05652289 C13.2657796,2.78736474 15.0126156,2.00132073 16.9396663,2.00000467 Z"
    />
  </Svg>
);

export default HeartFill;
