import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";

export type IntercomParams = {
  webUrl?: string;
  platform?: string; 
  accessKey?: string;
  designCategory?: string;
  designState?: any;
  isBegin?: boolean;
}

const useIntercomChat = (params: IntercomParams) => {
  const { boot, startTour } = useIntercom();
  const userInfo = useSelector((state: any) => state.userInfo);
  const error = useSelector((state: any) => state.designError.error);
  const [isInitialized, setIsInitialized] = useState<boolean>(false)

  useEffect(() => {
    if (userInfo?.emailAddress) {
      const { UserPackage, IsExpired, IsTrial, selectedAppInfo } = userInfo;
      const currentPackageName = UserPackage?.PackageName;
      const plan_type = IsExpired ? 'License expired!' : IsTrial ? `Trial - ${currentPackageName}` : currentPackageName;
      const customAttributes: any = { plan_type };
      
      const platform = userInfo.UserPackage.PlatformId === 30 ? 'WooCommerce' : 'Shopify'; // userInfo.
      const platformName = selectedAppInfo?.IsDemoApp ? `DEMO - ${platform}` : platform; 
      const webUrl = selectedAppInfo?.CustomerStoreUrl || params?.webUrl;

      if (params.platform) {
        customAttributes.chosenPlatform = params?.platform;
      }

      if (!!webUrl) {
        customAttributes.webUrl = webUrl;
      }
      
      if (!!platformName) {
        customAttributes.platform = platformName;
      }
      
      if (params?.accessKey) {
        customAttributes.accessKey = params.accessKey;
      }
      
      if (params?.designCategory) {
        customAttributes.designCategory = params.designCategory;
        customAttributes.receivedError = error ? error : '';
      }

      if (params?.designState) {
        customAttributes.designState = params?.designState;
      }

      const bootProps = {
        name: userInfo?.userName,
        email: userInfo?.emailAddress,
        userId: userInfo?.userId,
        customAttributes,
      }
      boot(bootProps);
      setIsInitialized(true);
      return;
    }
    boot();
    setIsInitialized(true);
  }, [userInfo, boot, params]);

  useEffect(() => {
    if (isInitialized && params.isBegin) {
      if (params?.platform === 'Shopify') {
        startTour(448970);
        return;
      }
      if (params?.platform === 'WooCommerce') {
        startTour(449074);
        return;
      } 
    }
  }, [isInitialized, params.isBegin, params.platform]);


}

export default useIntercomChat;