import React from "react";

interface IProps {
    width: string;
    height: string;
    fillColor: string;
    type: string;
}

const Filter = ({ width, height, fillColor }: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 17">
        <path fill={fillColor} fillRule="evenodd" d="M9.333-.033c.387 0 .7.313.7.7l.001 8.072C11 9.037 11.7 9.937 11.7 11c0 .838-.435 1.574-1.092 1.994.038.105.059.22.059.34v2c0 .552-.448 1-1 1-.424 0-.786-.264-.932-.636-.064-.106-.102-.231-.102-.365V14c0-.073.011-.143.032-.21l.002-.457c0-.02 0-.042.002-.062-.983-.285-1.702-1.194-1.702-2.271 0-1.063.7-1.963 1.666-2.261V.667c0-.387.314-.7.7-.7zm-6.666 0c.386 0 .7.313.7.7V2c0 .024-.002.049-.004.072.967.295 1.67 1.196 1.67 2.261 0 1.066-.703 1.966-1.671 2.263l.005.07v8.667c0 .387-.314.7-.7.7-.387 0-.7-.313-.7-.7V6.667c0-.025 0-.049.003-.072C1.003 6.299.3 5.398.3 4.333s.703-1.965 1.67-2.262L1.968 2V.667c0-.387.313-.7.7-.7zm12.666 0c.387 0 .7.313.7.7V2c0 .024 0 .048-.003.071.967.297 1.67 1.197 1.67 2.262s-.703 1.966-1.67 2.263l.003.07v8.667c0 .387-.313.7-.7.7-.386 0-.7-.313-.7-.7V6.667c0-.025.002-.05.004-.073-.967-.295-1.67-1.195-1.67-2.26 0-1.06.698-1.966 1.67-2.264L14.633 2V.667c0-.387.314-.7.7-.7zm-6 10.066c-.534 0-.966.433-.966.967s.432.967.966.967.967-.433.967-.967-.433-.967-.967-.967zM2.667 3.367c-.534 0-.967.432-.967.966s.433.967.967.967.966-.433.966-.967-.432-.966-.966-.966zm12.666 0c-.033 0-.065.001-.097.004-.49.049-.87.464-.87.962 0 .534.433.967.967.967s.967-.433.967-.967-.433-.966-.967-.966z" />
    </svg>
);

export default Filter;
