import { shortLinksService } from "../services/api/firebase";

export const buildLink = async (link: string) => {
  try {
    const { data } = await shortLinksService(link);
    return data.shortLink;
  } catch (error) {
    return null;
  }
};
