import React from "react";

interface IProps {
    width: string;
    height: string;
    fillColor: string;
    type: string;
}

const StarHover = ({ width, height, fillColor }: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14">
        <path fill={fillColor} fillRule="evenodd" d="M6.187.194c.126-.256.333-.256.46 0l1.595 3.232c.126.256.461.5.744.54l3.567.52c.283.041.347.238.142.438L10.114 7.44c-.205.2-.333.593-.284.874l.609 3.553c.048.282-.12.403-.372.27l-3.19-1.678c-.254-.133-.667-.133-.92 0l-3.19 1.677c-.254.133-.42.012-.373-.27l.61-3.553c.048-.282-.08-.675-.285-.875L.14 4.922c-.206-.2-.142-.396.141-.437l3.567-.52c.283-.04.618-.284.744-.54L6.187.194z" />
    </svg>
);

export default StarHover;
