import React from "react";
import { Platform } from "react-native";
import { dimenHeight, dimenWidth } from "shared-components/src/utils/helper";
import { colors } from "shared-components/src/utils/constants";
import HeaderContainer from "./HeaderContainer";
import Header from "./Header";
import Card from "./Card";
import Content from "./Content";
import ImageContainer from "./ImageContainer";
import LargeImageWrapper from "./LargeImageWrapper";
import ImageWrapper from "./ImageWrapper";
import Image from "./Image";
import ArrowRightSmall from "../../icons/ArrowRightSmall";
import { ColorListData } from "shared-components/src/models/colorListData";

interface ILargeCategoryView {
  category: {
    Title: string;
    CategoryId: number | null;
    Products: {
      IsInWishList: boolean;
      Price: { Price: number; Currency: string };
      ProductId: string;
      Images: {
        ImgUrl: string;
      }[];
    }[];
  };
  navigate?: (route: string, params: Record<string, any>) => void;
  handleProductSearch?: Function;
  colorList?: ColorListData[];
  fontFamily?: any;
  imageResizeType: "cover" | "contain";
}

const LargeCategoryView = ({
  category,
  handleProductSearch,
  colorList,
  fontFamily,
  imageResizeType,
  navigate,
}: ILargeCategoryView) => {
  return (
    <Card>
      <HeaderContainer
        onPress={() =>
          navigate && navigate('ProductList', {
            categoryId: category.CategoryId,
            title: category.Title,
          })
        }
      >
        <Header
          fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
          primary={colorList ? colorList[0].colorCode : colors.darkSlateBlue}
        >
          {category.Title}
        </Header>
        <ArrowRightSmall
          fillColor={colorList ? colorList[0].colorCode : colors.darkSlateBlue}
          height={Platform.OS === "web" ? "11" : dimenHeight(16).toString()}
          width={Platform.OS === "web" ? "11" : dimenWidth(16).toString()}
        />
      </HeaderContainer>
      <Content>
        <LargeImageWrapper
          onPress={() =>
            handleProductSearch &&
            handleProductSearch(category?.Products?.[0].ProductId)
          }
        >
          <Image
            source={{ uri: category?.Products?.[0].Images[0].ImgUrl }}
            resizeMode={imageResizeType}
          />
        </LargeImageWrapper>
        <ImageContainer>
          <ImageWrapper
            onPress={() =>
              handleProductSearch &&
              handleProductSearch(category?.Products?.[1].ProductId)
            }
          >
            <Image
              source={{ uri: category?.Products?.[1].Images[0].ImgUrl }}
              resizeMode={imageResizeType}
            />
          </ImageWrapper>
          <ImageWrapper
            onPress={() =>
              handleProductSearch &&
              handleProductSearch(category?.Products?.[2].ProductId)
            }
          >
            <Image
              source={{ uri: category?.Products?.[2].Images[0].ImgUrl }}
              resizeMode={imageResizeType}
            />
          </ImageWrapper>
          <ImageWrapper
            onPress={() =>
              handleProductSearch &&
              handleProductSearch(category?.Products?.[3].ProductId)
            }
            isLast
          >
            <Image
              source={{ uri: category?.Products?.[3].Images[0].ImgUrl }}
              resizeMode={imageResizeType}
            />
          </ImageWrapper>
        </ImageContainer>
      </Content>
    </Card>
  );
};

export default LargeCategoryView;
