import {
  FETCH_USERINFO_START,
  FETCH_USERINFO_SUCCESS,
  FETCH_USERINFO_FAIL,
  DELETE_APP_SUCCESS,
  CREATE_APP_SUCCESS,
  RENAME_APP_SUCCESS,
  PRICING_BANNER_TOOGLE,
  GET_USER_INFO,
  SET_SELECTED_APP,
} from "panel/src/redux/constants/userInfo";

export const getUserInfo = (data?: {
  redirect?: string;
  navigateAway?: boolean;
}) => {
  return {
    type: GET_USER_INFO,
    data,
  };
};

export const fetchUserInfoStart = (token: string) => {
  return {
    type: FETCH_USERINFO_START,
    token,
  };
};

export const fetchUserInfoSuccess = (payload: {
  userName: string;
  userId: string;
  apps: any;
  IsTrial: boolean;
  IsExpired: boolean;
  emailAddress: string;
  RemainingDays: string;
  ExpireDate?: string;
  UserPackage?:any;
}) => {
  return {
    type: FETCH_USERINFO_SUCCESS,
    payload,
  };
};

export const fetchUserInfoFail = (error: any) => {
  return {
    type: FETCH_USERINFO_FAIL,
    error,
  };
};

export const deleteAppSuccess = (appId: string) => {
  return {
    type: DELETE_APP_SUCCESS,
    appId,
  };
};

export const createAppSuccess = (responseObj: any) => {
  return {
    type: CREATE_APP_SUCCESS,
    responseObj,
  };
};

export const renameAppSuccess = (responseObj: any) => {
  return {
    type: RENAME_APP_SUCCESS,
    responseObj,
  };
};

export const pricingBannerToggle = () => {
  return {
    type: PRICING_BANNER_TOOGLE,
  };
};

export const setSelectedApp = (payload: any) => {
  return {
    type: SET_SELECTED_APP,
    payload,
  };
};
