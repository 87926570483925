import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Button from "panel/src/_components/common/Button";
import { forgotPassword } from "panel/src/redux/actions/ForgotPassword";
import logo from "panel/src/assets/images/mobile-logo.svg";
import successIcon from "panel/src/assets/images/auth-success.svg";
import errorIcon from "panel/src/assets/images/red-warning.svg";
import classes from "./forgotpasswordlink.module.scss";
import { useLocation } from "react-router-dom";
import useIntercomChat from "../../Components/Intercom/useIntercomChat";

interface IForgotPasswordProps {
  loading: boolean;
  error: string;
  _forgotPassword: Function;
}

interface ILocationState {
  email: string;
}

const ForgotPasswordLink = ({
  loading,
  error,
  _forgotPassword,
}: IForgotPasswordProps) => {
  useIntercomChat({});
  const history = useHistory();
  const location = useLocation<ILocationState>();

  useEffect(() => {
    if (!location.state) {
      goToLogin();
    }
  }, []);

  const resendEmail = () => {
    _forgotPassword(location.state.email);
  };

  const goToLogin = () => {
    history.replace("login");
  };

  return (
    <div className={classes.bg}>
      <img src={logo} className={classes.logo} />
      <div className={classes.forgotPasswordLinkContainer}>
        <img src={successIcon} className={classes.successIcon} />
        <div className={classes.title}>We’ll email you a reset link</div>
        <Button
          color="Auth"
          width="256px"
          height="44px"
          loadingSpinner={loading}
          type={loading ? "button" : "submit"}
          onClick={() => resendEmail()}
          fontSize="14px"
        >
          Resend the link
        </Button>
        <div className={classes.backContainer}>
          <span className={classes.hyperLink} onClick={() => goToLogin()}>
            Back to sign in
          </span>
        </div>
        {error && (
          <div className={classes.errorContainer}>
            <img src={errorIcon} className={classes.errorIcon} />
            <span className={classes.errorText}>{error}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.forgotPassword.loading,
    error: state.forgotPassword.error,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _forgotPassword: (email: string) => dispatch(forgotPassword(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordLink);
