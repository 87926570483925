import styled from "styled-components";
import Icon from "../../_components/common/Icon";

export const BadgeItemButton = styled.button`
  border: none;
  background: transparent !important;
  margin-left: 4px;
  cursor: pointer;
`;

export const BadgeItem = styled.div`
  background: #1f81df;
  padding: 3px 4px 3px 8px;
  min-height: 20px;
  margin: 0 8px 8px 0;
  border-radius: 2px;
  display: flex;
  align-items: center;
`;

export const BadgeText = styled.span`
  font-family: inherit;
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  color: #fff;
`;

export const Input = styled.input`
  width: 100%;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #3e5e6e;
  border: none;
  font-family: "Red Hat Display", sans-serif;
  &::placeholder {
    color: #9fafb6;
  }
  &:disabled {
    background: #fff !important;
    color: #9fafb6 !important;
    &::placeholder {
      color: #9fafb6 !important;
    }
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
`;

interface IDropdownInput {
  isSelected: boolean;
  hasError: boolean;
  searchable?: boolean;
  multiple?: boolean;
}

export const DropdownInput = styled.div<IDropdownInput>`
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(22, 33, 43, 0.12);
  padding: 11px 16px;
  font-family: inherit;
  font-size: 14px;
  color: ${(props) => (props.isSelected ? "#3e5e6e" : "#9fafb6")};
  font-weight: 500;
  border: 1px solid ${(props) => (props.hasError ? "#e66565" : "transparent")};
  cursor: ${(props) => (props.searchable ? "text" : "pointer")};
  justify-content: ${(props) =>
    props.multiple ? "flex-start" : "space-between"};
  display: flex;
  align-items: ${(props) => (props.multiple ? "flex-start" : "center")};
  flex-wrap: ${(props) => (props.multiple ? "wrap" : "nowrap")};
`;

interface IDropdownItemProps {
  isPackageSupported?: boolean;
}
export const DropdownItem = styled.div<IDropdownItemProps>`
  padding: 5px 16px;
  cursor: ${(props) => (props.isPackageSupported ? "pointer" : "normal")};;
`;

interface IDropdownMenuProps {
  isSearching?: boolean;
  position?: boolean;

}

export const DropdownMenu = styled.div<IDropdownMenuProps>`
  position: ${(props) => (props.position ? "relative" : "absolute")};
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(22, 33, 43, 0.12);
  font-family: inherit;
  font-size: 14px;
  color: #3e5e6e;
  font-weight: 500;
  border: 2px solid transparent;
  margin-top: 10px;
  padding: 5px 0;
  z-index: 100;
  max-height: 200px;
  min-height: ${(props) => (props.isSearching ? "80px" : "0")};
  overflow: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ErrorMessage = styled.div`
  margin: 10px 0;
  color: red;
  font-size: 12px;
  font-weight: 500;
`;

interface IIcon {
  isOpen: boolean;
}

export const DropdownIcon = styled(Icon)<IIcon>`
  transform: ${(props) => props.isOpen && "rotate(180deg)"};
`;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
  color: #3e5e6e;
`;

export const Description = styled.label`
  display: block;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  margin-bottom: 15px;
  margin-top: 10px;
  color: #3e5e6e;
`;
