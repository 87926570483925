import styled from "styled-components";
import { IEditCard } from "./index";

interface ICart {
  placement: IEditCard["editCardPlacement"];
  width?: string;
}

export default styled.div<ICart>`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(15, 54, 73, 0.1);
  width: ${(props) => (props.width ? props.width : "280px")};
  z-index: 103;
  ${(props) =>
    props.placement === "right" ? "margin-left: 15px;" : "margin-right: 15px;"}
`;
