import React, { useState, useEffect, useRef, useMemo } from "react";
import classes from "./select.module.scss";
import ErrorField from "../ErrorField";
import Icon from "../Icon";
import SelectModel from "../../../models/Design/selectModel";
import AddIcon from "../../../assets/images/addBlue.svg";
import UpgradeTooltip from "../../../Components/UpgradeTooltip";

interface ISelectProps {
  options: SelectModel[];
  changeHandler: any;
  value?: string;
  placeholder: string;
  field?: any;
  form?: any;
  name?: string;
  isUsedEnable?: boolean;
  label?: string;
  containerMarginBottom?: string;
  fontFamily?: boolean;
  isAddButton?: boolean;
  isAddButtonLabel?: string;
  onClickAddButton?: Function | undefined;
  disabled?: boolean;
}

const Select = (props: ISelectProps) => {
  const selectNode: any = useRef();
  const [isOpen, setOpen] = useState(false);
  const selectedOption = props?.options?.find(
    (option: any) => option?.value?.toString() === props?.value?.toString()
  );

  const selectOption = (option: any) => {
    changeHandler({ target: option });
    setOpen(false);
  };

  const clickOutside = (e: any) => {
    if (selectNode.current && !selectNode.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", clickOutside);

    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);

  const changeHandler = (e: any) => {
    props.changeHandler(e);
    setOpen(false);
  };

  const attachedClasses = [classes.menuActivator];

  const fieldName = props.field && props.field.name;
  const isError =
    props.form && props.form.touched
      ? props.form.touched[fieldName]
      : undefined;
  const errorMessage =
    props.form && props.form.errors ? props.form.errors[fieldName] : undefined;

  if (isError) {
    attachedClasses.push(classes.Invalid);
  }

  const handleClick: React.MouseEventHandler<
    HTMLButtonElement | HTMLAnchorElement
  > = () => {
    props.onClickAddButton?.();
  };

  const open = () => {
    if (props?.disabled) return;
    setOpen(!isOpen);
  };

  return (
    <div
      style={{ marginBottom: props.containerMarginBottom }}
      className={classes.HeaderDropdown}
      ref={selectNode}
    >
      <label
        className={`${classes.BlockLabel} ${
          props.disabled ? classes.DisableSelect : ""
        }`}
      >
        {props.label}
      </label>

      <div
        className={`${attachedClasses.join(" ")} ${
          props.disabled ? classes.DisableSelect : ""
        }`}
        onClick={() => open()}
      >
        {selectedOption ? (
          <span
            style={{
              fontFamily: `${
                props.fontFamily
                  ? selectedOption.displayValue
                  : "Helvetica Neue"
              }`,
            }}
            className={`${classes.SelectInput}`}
          >
            {selectedOption.displayValue}
          </span>
        ) : (
          <span className={classes.Empty}>{props.placeholder}</span>
        )}
        <Icon
          className={`${classes.arrowIcon} ${isOpen && classes.rotateIcon}`}
          type="arrowDown"
          fillColor="#3e5e6e"
          width="16"
          height="16"
        />
      </div>
      {isError && errorMessage && (
        <ErrorField type={"relative"} text={errorMessage} />
      )}

      {isOpen && (
        <div className={classes.menuWrapper}>
          <ul className={classes.menu}>
            {props.options?.map((option: any) => (
              <li key={option.value}>
                <button
                  style={{
                    fontFamily: `${
                      props.fontFamily ? option.displayValue : "Helvetica Neue"
                    }`,
                  }}
                  type="button"
                  className={`${classes.menuBtn} ${
                    props.isUsedEnable && option.isUsed && classes.Disable
                  } ${option?.disabled && classes.Disable}`}
                  onClick={(e) => {
                    if (option?.disabled) {
                      e.preventDefault();
                      return;
                    }

                    props.isUsedEnable && option.isUsed
                      ? e.preventDefault()
                      : selectOption(option);
                  }}
                >
                  <span>
                    {props.isUsedEnable && option.isUsed
                      ? option.displayValue + " (Already created)"
                      : option.displayValue}
                  </span>
                  {option.isPackageSupported && (
                    <UpgradeTooltip label={option.displayValue || ""} />
                  )}
                </button>
              </li>
            ))}
          </ul>
          {props.isAddButtonLabel && (
            <button className={classes.button} onClick={handleClick}>
              <img style={{ alignSelf: "center" }} src={AddIcon} alt="" />{" "}
              {props.isAddButtonLabel}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Select;
