import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import IGetBannerDataRequest from "../../models/Engage/bannerSettings/IGetBannerDataRequest";
import { IBannerData } from "../../models/Engage/bannerSettings/IGetBannerDataResponse";
import {
  getBannerDataSuccess,
  getBannerDataFail,
} from "panel/src/redux/actions/bannerSettings";
import { GET_BANNER_DATA } from "panel/src/redux/constants/bannerSettings";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import notification from "panel/src/utils/notification";

function* getBannerData(payload: {
  requestObj: IGetBannerDataRequest;
  type: string;
}) {
  yield put(startAppLoading(GET_BANNER_DATA));
  try {
    const request = {
      ApplicationId: payload.requestObj.applicationId,
      SsoUserId: payload.requestObj.userId,
      PlatformId: payload.requestObj.platformId,
    };

    const [homePageData, colorsData, fontTypeData]: [IBannerData, any, any] =
      yield all([
        call(axios.post, "/Component/api/Component/GetHomePageData", request),
        call(axios.post, "/api/Design/GetColors", {
          ApplicationId: request.ApplicationId,
        }),
        call(axios.post, "/api/Manage/GetFonts", {
          ApplicationId: request.ApplicationId,
        }),
      ]);

    const bannerData = {
      home: homePageData.data.ResultData,
      colors: colorsData.data.ResultData,
      fonts: fontTypeData.data.ResultData,
    };

    yield put(getBannerDataSuccess(bannerData));
  } catch (error) {
    notification.error(
      "We are temporarily unable to process transactions at this time. We apologize for the inconvenience.",
      "/manage/config-settings"
    );
    yield put(getBannerDataFail(error.message));
  }
  yield put(endAppLoading(GET_BANNER_DATA));
}

export default function* getBannerDataSaga() {
  yield takeLatest(GET_BANNER_DATA, getBannerData);
}
