import React, { Component } from "react";
import { Platform, TouchableWithoutFeedback } from "react-native";
import styled from "styled-components/native";
import { ColorListData } from "shared-components/src/models/colorListData";
import {
  dimenFont,
  dimenHeight,
  dimenWidth,
} from "shared-components/src/utils/helper";
import { colors, shadow } from "shared-components/src/utils/constants";

interface IProps {
  title?: string;
  metafields: any[] | null;
  colorList?: ColorListData[];
  fontFamily?: any;
  viewMoreText?: string;
  viewLessText?: string;
}

interface IState {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  detailShown?: number[];
  fontFamily?: any;
  isDetailShown?: boolean;
}

const Container = styled.View`
  ${Platform.OS === "web" ? `padding: 5px 10px;` : `margin: 8px 16px;`}
`;

const Wrapper = styled.View`
  background-color: ${colors.white};
  padding: ${Platform.OS === "web" ? "12" : dimenWidth(16)}px;
  box-shadow: 0px 0px 5px rgba(15, 54, 73, 0.25);
`;

const BlockGroup = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

const Field = styled.Text<IState>`
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
  color: ${(props) => props.primary};
`;

const ViewMoreTextContainer = styled.View<IState>`
  display: flex;
  align-items: flex-end;
  margin-top: ${Platform.OS === "web" ? `6` : `${dimenWidth(6)}`}px;
`;

const ViewMoreText = styled.Text<IState>`
  font-size: ${Platform.OS === "web" ? "10.1" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
  color: ${(props) => props.secondary};
`;

class MetaField extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      primary: "#0F3649",
      secondary: "#3196F6",
      tertiary: "#0F3649",
      detailShown: [],
      isDetailShown: false,
    };
  }

  render() {
    const { title, metafields, colorList, fontFamily, viewMoreText, viewLessText } = this.props;
    const { primary, secondary, detailShown, isDetailShown } = this.state;
    const viewLessTextHandle = viewLessText
        ? this.props.viewLessText
        : "View less";
    const viewMoreTextHandle = viewMoreText
        ? this.props.viewMoreText
        : "View more";
    return (
      <>
        {metafields ? (
          metafields.map((metafield, index) => (
            <Container>
              <Wrapper style={shadow}>
                <BlockGroup>
                  <Field
                    primary={colorList ? colorList[0].colorCode : primary}
                    fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                    style={{
                      marginBottom:
                        Platform.OS === "web" ? "8" : dimenHeight(16),
                    }}
                  >
                    {metafield.name
                      ? metafield.name
                      : `Meta Field ${index + 1}`}
                  </Field>
                </BlockGroup>
                <Field
                  primary={colorList ? colorList[0].colorCode : primary}
                  fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                  style={{
                    minHeight: 50,
                    height: detailShown?.includes(index) ? "auto" : 50,
                  }}
                >
                  {metafield.value}
                </Field>
                {metafield.value.length > 40 &&
                  <ViewMoreTextContainer>
                      <TouchableWithoutFeedback
                          onPress={() => {
                            if (!detailShown?.includes(index)) {
                              let temp = detailShown?.concat(index);
                              this.setState({detailShown: temp});
                            } else {
                              let isExist = detailShown?.indexOf(index);
                              if (isExist !== -1) {
                                detailShown?.splice(isExist, 1);
                                this.setState({detailShown});
                              }
                            }
                          }}
                      >
                        <ViewMoreText
                            fontFamily={fontFamily}
                            secondary={colorList ? colorList[1].colorCode : secondary}
                        >
                          {detailShown?.includes(index) ? viewLessTextHandle : viewMoreTextHandle}
                        </ViewMoreText>
                      </TouchableWithoutFeedback>
                  </ViewMoreTextContainer>
                }
              </Wrapper>
            </Container>
          ))
        ) : (
          <Container>
            <Wrapper>
              <BlockGroup>
                <Field
                  style={{
                    marginBottom: 8,
                  }}
                  primary={colorList ? colorList[0].colorCode : primary}
                  fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                >
                  Meta Field Title
                </Field>
              </BlockGroup>
              <Field
                primary={colorList ? colorList[0].colorCode : primary}
                fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
              >
                Just arrived. Buy before it is too late. Limited stock!
              </Field>
              <ViewMoreTextContainer>
                <TouchableWithoutFeedback
                  onPress={() =>
                    this.setState({ isDetailShown: !isDetailShown })
                  }
                >
                  <ViewMoreText
                    fontFamily={fontFamily}
                    secondary={colorList ? colorList[1].colorCode : secondary}
                  >
                    {isDetailShown ? viewLessTextHandle : viewMoreTextHandle}
                  </ViewMoreText>
                </TouchableWithoutFeedback>
              </ViewMoreTextContainer>
            </Wrapper>
          </Container>
        )}
      </>
    );
  }
}

export default MetaField;
