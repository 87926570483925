import React from "react";
import Svg, { Path } from "react-native-svg";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
}

const Cart = ({ width, height, fillColor }: IProps) => (
  <Svg width={width} height={height} viewBox="0 0 30 30">
    <Path
      fill={fillColor}
      fillRule="evenodd"
      d="M.66 2.289c-.156.308-.137.678.05.969.188.29.515.458.858.44h3.063l3.042 14.724c.09.451.484.776.94.777H28.47c.346.005.669-.179.843-.48.175-.302.175-.676 0-.977-.174-.302-.497-.486-.843-.48h-.96V10.48c0-.535-.431-.97-.962-.97h-2.882V2.73c0-.536-.43-.97-.96-.97h-7.787c-.491.052-.863.471-.86.97v6.78h-2.983c-.491.053-.863.472-.86.97v6.781h-.822L6.352 2.528c-.095-.448-.487-.768-.94-.767H1.567c-.033-.002-.067-.002-.1 0-.343.017-.651.219-.807.528zm21.085 1.41V9.51H15.98V3.698h5.765zm-9.608 7.75H17.9v5.812h-5.764v-5.813zm7.686 0H25.588v5.812h-5.765v-5.813zm-7.046 8.396c-2.288 0-4.163 1.89-4.163 4.198 0 2.307 1.875 4.198 4.163 4.198 2.288 0 4.164-1.891 4.164-4.198 0-2.307-1.876-4.198-4.164-4.198zm11.21 0c-2.289 0-4.164 1.89-4.164 4.198 0 2.307 1.875 4.198 4.163 4.198 2.288 0 4.164-1.891 4.164-4.198 0-2.307-1.876-4.198-4.164-4.198zm-11.21 1.937c1.25 0 2.242 1 2.242 2.26 0 1.26-.992 2.261-2.242 2.261s-2.242-1-2.242-2.26c0-1.26.993-2.26 2.242-2.26zm11.21 0c1.249 0 2.241 1 2.241 2.26 0 1.26-.992 2.261-2.242 2.261s-2.241-1-2.241-2.26c0-1.26.992-2.26 2.241-2.26z"
    />
  </Svg>
);

export default Cart;
