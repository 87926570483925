export const isFavorite = ({
  favorites,
  productId,
  variantId,
}: {
  favorites: any[];
  productId: string;
  variantId?: string;
}) => {
  let check: any[] = favorites.filter((s: any) => s.product_id === productId);
  if (variantId) {
    check = check.filter((s: any) => s.variant_id === variantId);
  }
  return check && check.length > 0;
};
