import styled from "styled-components";

export default styled.div`
  flex: 1;
  margin: 32px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
