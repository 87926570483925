import IGetProductListDataRequest from "../../models/Design/productList/IGetProductListDataRequest";
import {
  GET_PRODUCT_LIST_DATA,
  GET_PRODUCT_LIST_DATA_SUCCESS,
  GET_PRODUCT_LIST_DATA_FAIL,
  RESET_PRODUCT_LIST_DATA,
  GET_PRODUCT_LIST_BY_CATEGORY_ID,
  GET_PRODUCT_LIST_BY_CATEGORY_ID_FAIL,
  GET_PRODUCT_LIST_BY_CATEGORY_ID_SUCCESS,
  RESET_PRODUCT_LIST_BY_CATEGORY_ID_PRODUCTS,
} from "panel/src/redux/constants/productList";
import { ComponentProductModule } from "../../models/Design/home/ComponentProduct";

interface BaseAction {
  type: string;
  data?: Object;
  error?: string;
  requestObj?: IGetProductListDataRequest;
}

export const getProductListData = (
  requestObj: IGetProductListDataRequest
): BaseAction => ({
  type: GET_PRODUCT_LIST_DATA,
  requestObj,
});

export const getProductListDataSuccess = (data: Object): BaseAction => ({
  type: GET_PRODUCT_LIST_DATA_SUCCESS,
  data,
});

export const getProductListDataFail = (error: string): BaseAction => ({
  type: GET_PRODUCT_LIST_DATA_FAIL,
  error,
});

export const resetHomeData = () => ({
  type: RESET_PRODUCT_LIST_DATA,
});

export const getProductListByCategoryId = (
  request: ComponentProductModule.TGetProductListByCategoryIdData
) => ({
  type: GET_PRODUCT_LIST_BY_CATEGORY_ID,
  request,
});

export const getProductListByCategoryIdSuccess = (
  list: ComponentProductModule.IProduct[]
) => ({
  type: GET_PRODUCT_LIST_BY_CATEGORY_ID_SUCCESS,
  list,
});

export const getProductListByCategoryIdFail = () => ({
  type: GET_PRODUCT_LIST_BY_CATEGORY_ID_FAIL,
});

export const resetProductListByCategoryIdProducts = () => ({
  type: RESET_PRODUCT_LIST_BY_CATEGORY_ID_PRODUCTS,
});
