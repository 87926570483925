import {
  GET_BANNER_DATA,
  GET_BANNER_DATA_SUCCESS,
  GET_BANNER_DATA_FAIL,
  RESET_BANNER_DATA,
  HAS_CHANGE,
  DISCARD_CHANGES,
  RESET_HAS_CHANGE,
} from "panel/src/redux/constants/bannerSettings";

export interface IBannerData {
  componentsData: {
    ComponentId: number;
    DisplayOrder: number;
    JSON: string | null;
    PageId: number;
  }[];
  initialized: boolean;
}

const initialState = {
  data: false,
  loading: false,
  reset: false,
  bannerChange: false,
  changes: false,
  discardChanges: false,
};

const bannerReducer = (state: Object = initialState, action: any) => {
  switch (action.type) {
    case GET_BANNER_DATA:
      return {
        ...state,
        loading: true,
        reset: false,
      };
    case GET_BANNER_DATA_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        reset: false,
        discard: false,
        changes: false,
      };
    case GET_BANNER_DATA_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        reset: false,
      };
    case RESET_BANNER_DATA:
      return {
        ...initialState,
        loading: false,
        reset: true,
      };
    case HAS_CHANGE:
      return {
        ...state,
        changes: true,
        bannerChange: true,
      };
    case DISCARD_CHANGES:
      return {
        ...state,
        changes: false,
        discard: true,
        bannerChange: false,
      };
    case RESET_HAS_CHANGE:
      return {
        ...state,
        bannerChange: false,
      };
    default:
      return state;
  }
};

export default bannerReducer;
