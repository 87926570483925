import styled from "styled-components";
import { IEditCard } from "./index";

interface ICart {
  placement: IEditCard["editCardPlacement"];
}

export default styled.div<ICart>`
  background-color: #3196f6;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);
  width: 232px;
  padding: 24px;
  z-index: 200;
  ${(props) =>
    props.placement === "right" ? "margin-left: 15px;" : "margin-right: 15px;"}
`;
