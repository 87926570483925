import React from "react";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
  type: string;
}

const Mail = ({ width, height, fillColor }: IProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 17 17'
  >
    <g fill='none' fillRule='evenodd'>
      <g fill={fillColor} fillRule='nonzero'>
        <g>
          <g>
            <g>
              <path
                d='M13.726 0H2.422C1.084 0 0 1.084 0 2.422v8.073c0 1.337 1.084 2.421 2.422 2.421h11.304c1.338 0 2.422-1.084 2.422-2.421V2.422C16.148 1.084 15.064 0 13.726 0zm0 1.615L8.478 5.223c-.25.144-.558.144-.808 0L2.422 1.615h11.304z'
                transform='translate(-135.000000, -546.000000) translate(119.000000, 456.000000) translate(16.148148, 16.227273) translate(0.000000, 74.472727) translate(0.000000, 1.345455)'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Mail;
