import * as Yup from "yup";
import {
  navigationBarLogoMessage,
  navigationBarLogoExtensionMessage,
  navigationBarLogoRatioMessage,
} from "../../../utils/validationMessage";

const ICON_RATIO = 5;
const SUPPORTED_LOGO_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/svg+xml",
  "image/svg"
];

const Schema = Yup.object().shape({
  navigationType: Yup.string().required("Navigation type must be selected"),
  logo: Yup.mixed().required(navigationBarLogoMessage),
  logoExtension: Yup.string()
    .required(navigationBarLogoExtensionMessage)
    .test(
      "logoFileFormat",
      navigationBarLogoExtensionMessage,
      (value) => value && SUPPORTED_LOGO_FORMATS.includes(value)
    ),
  logoRatio: Yup.number()
    .max(ICON_RATIO, navigationBarLogoRatioMessage)
    .min(ICON_RATIO, navigationBarLogoRatioMessage),
});

export default Schema;
