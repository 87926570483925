import styled from "styled-components";

interface ISubTitle {
  fontFamily?: string;
}

export default styled.span<ISubTitle>`
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : "Helvetica Neue"};
  font-size: 9px;
  color: #0f3649;
`;
