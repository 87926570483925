import {
  GET_ENGAGEMENT_INFO_START,
  GET_ENGAGEMENT_INFO_SUCCESS,
  GET_ENGAGEMENT_INFO_FAIL,
  SET_ENGAGEMENT_INFO_START,
  SET_ENGAGEMENT_INFO_SUCCESS,
  SET_ENGAGEMENT_INFO_FAIL,
} from "panel/src/redux/constants/getEngagementInfo";


export type getEngagementInfoStateTypes = Readonly<{
  error?: boolean;
  loading?: boolean;
}>;

const initialState: getEngagementInfoStateTypes = {
  error: false,
  loading: false,
}


const getEngagementInfoReducer = ( state = initialState, action: any ) => {
  switch ( action.type ) {
    case GET_ENGAGEMENT_INFO_START:
      return {
        ...state,
        data: action.data
      };

    case GET_ENGAGEMENT_INFO_SUCCESS:
      return {
        ...state,
        engagementData: action.engagementData
      };
    case GET_ENGAGEMENT_INFO_FAIL:
      return {
        ...state,
        error: true,
      };
    case SET_ENGAGEMENT_INFO_START:
      return {
        ...state,
        data: action.data
      };

    case SET_ENGAGEMENT_INFO_SUCCESS:
      return {
        ...state,
      };
    case SET_ENGAGEMENT_INFO_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default getEngagementInfoReducer;
