import React, { useEffect, useRef, useState } from "react";
import { Form, Field, Formik, FieldArray, FormikProps } from "formik";
import Input from "panel/src/Components/Input";
import Dropdown from "panel/src/Components/Dropdown";
import Button from "panel/src/Components/Button";
import Separator from "panel/src/Components/Separator";
import DynamicToolsBar from "panel/src/Components/DynamicToolsBar";
import FormikErrorFieldFocus from "panel/src/Components/FormikErrorFieldFocus";
import ErrorField from "panel/src/_components/common/ErrorField";
import { connect } from "react-redux";
import { hasChange } from "panel/src/redux/actions/bannerSettings";
import { usePrevious, flattenObject } from "panel/src/utils/helper";
import validationSchema from "./validationSchema";

interface ISocialMedia {
  hideComponentEdit?: Function;
  editFormClickedOutside?: number;
  setComponentFormData?: Function;
  Id?: string;
  formData?: ISocialMediaFormData;
  socialMedias: { label: string; value: number }[];
  setInfoPopup?: Function;
  hasChangeReq: Function;
}

type ISocialMediaFormData = {
  socialMediaPlatforms: {
    socialMediaType: string;
    url: string;
  }[];
};

const SocialMedia = ({
  hideComponentEdit,
  editFormClickedOutside,
  setComponentFormData,
  Id,
  formData,
  socialMedias,
  setInfoPopup,
  hasChangeReq,
}: ISocialMedia) => {
  const formikRef = useRef<FormikProps<ISocialMediaFormData>>(null);
  const [isSaveRequired, setIsSaveRequired] = useState(false);
  const checkIsRequired = () => {
    return formData!.socialMediaPlatforms[0].url === "";
  };

  const prevProps = usePrevious({ formData });

  useEffect(() => {
    if (prevProps && formData) {
      if (prevProps.formData !== formData) {
        hasChangeReq();
      }
    }
  });

  useEffect(() => {
    if (editFormClickedOutside !== 0) {
      formikRef.current?.validateForm().then(() => {
        const isFormValid = formikRef.current?.isValid;

        if (isFormValid && checkIsRequired()) {
          setIsSaveRequired(true);
          const errorElement = document.querySelector(
            "[id='save-changes-error']"
          );
          if (errorElement) {
            errorElement.scrollIntoView({ behavior: "smooth" });
          }
        } else if (isFormValid || !checkIsRequired()) {
          hideComponentEdit && hideComponentEdit(Id, checkIsRequired());
        } else {
          const errorKeys = flattenObject(formikRef.current?.errors);
          Object.keys(errorKeys).forEach((key: any) => {
            formikRef.current?.setFieldTouched(key);
          });
        }
      });
    }
  }, [editFormClickedOutside]);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{ socialMediaPlatforms: formData!.socialMediaPlatforms }}
      onSubmit={(values) => {
        const resultObject: ISocialMediaFormData = values;

        setComponentFormData && setComponentFormData(Id, resultObject);
        setInfoPopup && setInfoPopup(true);
      }}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, errors, touched }) => {
        return (
          <Form>
            <FieldArray name="socialMediaPlatforms">
              {({ push, remove }) => (
                <React.Fragment>
                  {values.socialMediaPlatforms.map((formValue, index) => (
                    <React.Fragment key={index}>
                      <Field
                        id={`socialMediaPlatforms[${index}].socialMediaType`}
                        name={`socialMediaPlatforms[${index}].socialMediaType`}
                        style={{ marginBottom: "1rem" }}
                        label="Social Platform"
                        placeholder="Select Social Media"
                        options={socialMedias}
                        value={formValue.socialMediaType}
                        onChangeHandler={handleChange}
                        handleError={errors}
                        handleTouched={touched}
                        component={Dropdown}
                      />
                      <Field
                        id={`socialMediaPlatforms[${index}].url`}
                        name={`socialMediaPlatforms[${index}].url`}
                        label="Url"
                        placeholder="Url"
                        value={formValue.url}
                        onChangeHandler={handleChange}
                        handleError={errors}
                        handleTouched={touched}
                        component={Input}
                      />
                      {values.socialMediaPlatforms.length > 1 &&
                        values.socialMediaPlatforms.length - 1 !== index && (
                          <Separator />
                        )}
                    </React.Fragment>
                  ))}
                  <DynamicToolsBar
                    push={() => push({ socialMediaType: "", url: "" })}
                    remove={() =>
                      remove(values.socialMediaPlatforms.length - 1)
                    }
                    isAddable={
                      values.socialMediaPlatforms.length < socialMedias.length
                    }
                    isRemovable={values.socialMediaPlatforms.length > 1}
                  />
                  <FormikErrorFieldFocus />
                  <Button
                    id="apply-button"
                    type="submit"
                    style={{ marginTop: "1.5rem" }}
                    buttonText="Apply"
                  />
                  {isSaveRequired && (
                    <ErrorField
                      id="save-changes-error"
                      text="Please save the changes"
                    />
                  )}
                  <Button
                    id="cancel-button"
                    type="button"
                    style={{ marginTop: "0.5rem" }}
                    theme="white"
                    buttonText={checkIsRequired() ? "Delete" : "Cancel"}
                    onClick={() =>
                      hideComponentEdit &&
                      hideComponentEdit(Id, checkIsRequired())
                    }
                  />
                </React.Fragment>
              )}
            </FieldArray>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  return {
    hasChange: state.bannerSettings.changes,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    hasChangeReq: () => dispatch(hasChange()),
    disCardChanges: () => dispatch(hasChange()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialMedia);
