import styled from "styled-components";

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 232px;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);
  background-color: #3e5e6e;
  font-family: Red Hat Display;
  font-size: 12px;
  font-weight: 500;
  color: white;
  padding: 12px;
  margin-left: 125px;
`;
