import styled from "styled-components/native";

interface ICategoryTitleWrapper {
  isactive: number;
  primary?: string;
}

export default styled.View<ICategoryTitleWrapper>`
  padding-vertical: 8px;
  padding-horizontal: 16px;
  ${(props) =>
    props.isactive &&
    `border-style: solid;  border-bottom-color: ${props.primary}; border-bottom-width:2px;`};
`;
