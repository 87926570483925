import styled from "styled-components";
import Button from "./Button";

export default styled(Button)`
  background: #fff;
  color: #224454 !important;
  border: none !important;
  width: 150px;
  margin-top: 50px;
  cursor: pointer;
  label {
    color: #224454 !important;
    margin-left: 6px;
    font-weight: 500;
  }
`;
