import React, { useState, useEffect } from "react";
import classes from "./googlemerchant.module.scss";
import { Formik, Form, Field } from "formik";
import Input from "panel/src/_components/common/Input";
import { bindActionCreators } from "redux";
import * as manageAppActions from "panel/src/redux/actions/manageApp";
import Button from "panel/src/_components/common/Button";
import Select from "panel/src/_components/common/Select";
import { connect } from "react-redux";
import Upload from "panel/src/_components/common/Upload";
import { fileToBase64, getFileExtension } from "panel/src/utils/helper";

interface IGoogleMerchantProps {
  actions?: any;
  history?: any;
  submitGoogleMerchant?: boolean;
  googleMerchantEmail?: string;
  googleMerchantName?: string;
  googleMerchantID?: string;
  googleMerchantKeyFile?: any;
  googleMerchantKeyFileName?: string;
  setInputs?: Function;
  setFile?: Function;
  selectLanguage?: Function;
  validationSchema?: any;
}
interface FormValues {
  selectedLanguage?: string;
  languageList?: ArrayBuffer;
  language?: string;
  companyName?: string;
  productLink?: string;
  actions?: any;
}

const GoogleMerchant = (props: IGoogleMerchantProps) => {
  const languageList = [
    { value: "2", displayValue: "English" },
    { value: "1", displayValue: "Türkçe" },
  ];

  return (
    <Form>
      <Field
        containerMarginBottom="24px"
        labelMarginBottom="16px"
        elementType="input"
        placeholder="merchant-center-1234567890123@merchant-center-1234567890123.iam.gserviceaccount.com"
        name="GoogleMerchantEmail"
        type="GoogleMerchantEmail"
        label="Merchant Email"
        value={props.googleMerchantEmail}
        component={Input}
        changeHandler={props.setInputs}
      />
      <Field
        containerMarginBottom="24px"
        elementType="input"
        placeholder="123456789"
        name="GoogleMerchantID"
        type="GoogleMerchantID"
        value={props.googleMerchantID}
        label="Merchant ID"
        component={Input}
        changeHandler={props.setInputs}
      />
      <Field
        containerMarginBottom="24px"
        elementType="input"
        placeholder="Mowico Store"
        name="GoogleMerchantName"
        type="GoogleMerchantName"
        value={props.googleMerchantName || ""}
        label="Merchant Name"
        component={Input}
        changeHandler={props.setInputs}
      />
      <Field
        containerMarginBottom="24px"
        placeholder="Please upload your unique Merchant File generated by Google Merchant Center"
        label="Merchant Key File"
        name="GoogleMerchantKeyFile"
        type="GoogleMerchantKeyFile"
        value={props.googleMerchantKeyFile || ""}
        onChange={props.setFile}
        logoName={props.googleMerchantKeyFileName}
        uploadKeyFile={true}
        component={Upload}
      />
    </Form>
  );
};

const mapStateToProps = (state: any) => {
  return {
    productLink: "",
    companyName: "",
    language: "",
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: {
      setAppActions: bindActionCreators(manageAppActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleMerchant);
