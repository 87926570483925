import React from "react";
import { Platform } from "react-native";
import { dimenHeight, dimenWidth } from "shared-components/src/utils/helper";
import { colors } from "shared-components/src/utils/constants";
import ListItem from "./ListItem";
import Label from "./Label";
import Card from "./Card";
import Separator from "./Separator";
import ArrowRightSmall from "../../icons/ArrowRightSmall";
import { ColorListData } from "shared-components/src/models/colorListData";

interface ILinkListview {
  linkList: TLinkItem[];
  colorList?: ColorListData[];
  fontFamily?: any;
  navigate?: (route: string, params: Record<string, any>) => void;
}

type TLinkItem = {
  Title: string;
  CategoryId: string | null;
  GroupId: string | null;
};

const LinkListview = ({
  linkList,
  colorList,
  fontFamily,
  navigate,
}: ILinkListview) => {
  const handleOnPress = (linkItem: TLinkItem) => {
    if (linkItem.CategoryId && navigate) {
      navigate('ProductList', {
        categoryId: linkItem.CategoryId,
        title: linkItem.Title,
      })
    } else if (linkItem.GroupId && navigate) {
      navigate('ProductList', {
        categoryId: linkItem.GroupId,
        title: linkItem.Title,
      })
    }
  };

  return (
    <Card>
      {linkList.map((obj, index) => (
        <React.Fragment key={index.toString()}>
          <ListItem onPress={() => handleOnPress(obj)}>
            <Label
              fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
              primary={
                colorList ? colorList[0].colorCode : colors.darkSlateBlue
              }
            >
              {obj.Title}
            </Label>
            <ArrowRightSmall
              fillColor={
                colorList ? colorList[0].colorCode : colors.darkSlateBlue
              }
              height={Platform.OS === "web" ? "11" : dimenHeight(16).toString()}
              width={Platform.OS === "web" ? "11" : dimenWidth(16).toString()}
            />
          </ListItem>
          <Separator />
        </React.Fragment>
      ))}
    </Card>
  );
};

export default LinkListview;
