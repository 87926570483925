import {
  SET_COLORS_START,
  SET_COLORS_FINISH,
  SET_COLORS_REJECTED,
} from "panel/src/redux/constants/colors";

import { SetColorsRequestModel } from "../../models/Design/colors/IGetColorPageDataRequest";
interface BaseAction {
  type: string;
  data?: Object;
  error?: string;
  requestObj?: SetColorsRequestModel;
}

export const setColorsStart = (
  requestObj: SetColorsRequestModel,
  callback: Function
) => {
  return {
    type: SET_COLORS_START,
    requestObj,
    callback,
  };
};

export const setColorsFinish = () => {
  return {
    type: SET_COLORS_FINISH,
  };
};

export const setColorsRejected = (error: string): BaseAction => ({
  type: SET_COLORS_REJECTED,
  error,
});
