import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { setInstagramToken } from "panel/src/redux/actions/setInstagramToken";
import Spinner from "panel/src/_components/common/Spinner";
import qs from "querystring";

interface IInstagramSuccess {
  appId: string;
  _setInstagramToken: Function;
}

const InstagramSuccess = ({ appId, _setInstagramToken }: IInstagramSuccess) => {
  const location = useLocation();

  useEffect(() => {
    if (appId) {
      const params = qs.parse(location.search);
      _setInstagramToken(params["?code"], appId);
    }
  }, [appId]);

  return <Spinner.AutoWithRedux />;
};

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _setInstagramToken: (code: string, appId: string) =>
      dispatch(setInstagramToken(code, appId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstagramSuccess);
