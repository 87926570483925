import {
  SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAIL,
} from "panel/src/redux/constants";

interface ISetNewPassword {
  loading: boolean;
  success: boolean;
  error: boolean | string;
}

const initialState: ISetNewPassword = {
  loading: false,
  success: false,
  error: false,
};

const setNewPasswordReducer = (
  state: ISetNewPassword = initialState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_NEW_PASSWORD:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
    case SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case SET_NEW_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        ...(action.payload.location.pathname === "/newpassword" && {
          loading: false,
          success: false,
          error: false,
        }),
      };
    default:
      return state;
  }
};

export default setNewPasswordReducer;
