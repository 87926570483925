import React, { useEffect, useState, useRef } from "react";
import { Form, Formik } from "formik";
import Button from "panel/src/Components/Button";
import { connect } from "react-redux";
import { componentChange } from "panel/src/redux/actions/Home";
import {
  setInstagramUserData,
  resetInstagramUserData,
} from "panel/src/redux/actions/setInstagramUser";
import { popupWindow } from "panel/src/utils/helper";

interface IInstagramShowcase {
  hideComponentEdit?: Function;
  editFormClickedOutside?: number;
  setComponentFormData?: Function;
  formData?: IInstagramShowcaseFormData;
  Id?: string;
  setInfoPopup?: Function;
  appId?: string;
  setInstagramUsername?: Function;
  instagramData?: any;
  index?: Number;
  componentChange?: Function;
  resetInstagramUserData: Function;
}

type IInstagramShowcaseFormData = {
  accountUrl: string;
};

const InstagramShowcase = ({
  hideComponentEdit,
  editFormClickedOutside,
  setComponentFormData,
  Id,
  formData,
  setInfoPopup,
  appId,
  setInstagramUsername,
  instagramData,
  index,
  componentChange,
  resetInstagramUserData,
}: IInstagramShowcase) => {
  const checkIsRequired = () => {
    return formData!.accountUrl === "" || formData!.accountUrl === null;
  };

  const componentObj = {
    applicationId: appId,
    componentId: 16,
    formData: JSON.stringify(formData),
    index: index,
  };

  const [val, setVal] = useState({});
  const instagramWindow = useRef<Window | null>(null);

  useEffect(() => {
    if (instagramData && instagramWindow.current) {
      localStorage.removeItem("INSTAGRAM_UPDATED");
      instagramWindow.current.location = instagramData;
      resetInstagramUserData();
    }
  }, [instagramData]);

  useEffect(() => {
    if (editFormClickedOutside !== 0) {
      hideComponentEdit && hideComponentEdit(Id, checkIsRequired());
    }
  }, [editFormClickedOutside]);

  useEffect(() => {
    window.addEventListener("storage", localStorageUpdated);

    return () => {
      window.removeEventListener("storage", localStorageUpdated);
    };
  }, []);

  const localStorageUpdated = (e: StorageEvent) => {
    if (e.key === "INSTAGRAM_UPDATED" && e.newValue === "true") {
      if (instagramWindow.current) {
        instagramWindow.current.close();
      }

      handleInstagramSuccess();
      localStorage.removeItem("INSTAGRAM_UPDATED");
    }
  };

  const handleInstagramSuccess = () => {
    setComponentFormData && setComponentFormData(Id, val);
    setInfoPopup && setInfoPopup(true);
    componentChange && componentChange(componentObj);
    resetInstagramUserData();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formData!}
      onSubmit={(values) => {
        instagramWindow.current = popupWindow({
          url: "about:blank",
          windowName: "Instagram Showcase",
          w: 1024,
          h: 768,
        });
        setInstagramUsername && setInstagramUsername();
        setVal(values);
      }}
    >
      {() => {
        return (
          <Form>
            <Button
              id="apply-button"
              type="submit"
              buttonText="Connect Instagram"
            />
            <Button
              id="cancel-button"
              type="button"
              style={{ marginTop: "0.5rem" }}
              theme="white"
              buttonText={checkIsRequired() ? "Delete" : "Cancel"}
              onClick={() =>
                hideComponentEdit && hideComponentEdit(Id, checkIsRequired())
              }
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  return {
    hasChange: state.bannerSettings.changes,
    appId: state.userInfo.selectedAppId,
    instagramData: state.instagram.data,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    componentChange: (val: any) => dispatch(componentChange(val)),
    setInstagramUsername: () => dispatch(setInstagramUserData()),
    resetInstagramUserData: () => dispatch(resetInstagramUserData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstagramShowcase);
