import { put, call, takeEvery } from "redux-saga/effects";
import axios from "panel/src/services/api/api";

import * as dataActions from "panel/src/redux/actions/navigationType";
import * as userInfoActions from "panel/src/redux/actions/userInfo";
import * as pageActions from "panel/src/redux/actions/page";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import {
  GET_NAVIGATION_TYPE_START,
  SET_NAVIGATION_TYPE_START,
} from "panel/src/redux/constants/navigationType";
import { clearDesignError, setDesignError } from "../actions/DesignError";
import { getNavigationMenu, updateNavigationMenu } from "../actions/navigationMenu";
import { getPackageFeatureListStart } from "../actions/getPackageFeature";

function* getNavigationType(navigationObj: any) {
  yield put(startAppLoading("GET_NAVIGATION_TYPE"));
  let appObj: any = {};
  appObj.ApplicationId = navigationObj.appId;
  try {
    const response = yield call(
      axios.post,
      "/api/Design/GetNavigationType",
      appObj
    );
    
    yield put(clearDesignError());
    yield put(dataActions.getNavigationTypeFinish(response.data.ResultData));
    yield put(getNavigationMenu());
  } catch (error) {
    const err = error as any;
    yield put(setDesignError(`/api/Design/GetNavigationType - ${err?.response?.status}`));
    yield put(dataActions.getNavigationTypeRejected(err.message));
  }
  yield put(endAppLoading("GET_NAVIGATION_TYPE"));
}

function* setNavigationType({ navigationObj }: any) {
  yield put(startAppLoading("SET_NAVIGATION_TYPE"));
  try {
    const response = yield call(axios.post, "/api/Design/SetNavigationType", {
      ...navigationObj,
    });
    yield put(clearDesignError());
    yield put(dataActions.setNavigationTypeFinish());
    yield put(updateNavigationMenu());
    yield put(pageActions.pageUpdated(true));
  } catch (error) {
    const err = error as any;
    yield put(setDesignError(`/api/Design/SetNavigationType - ${err?.response?.status}`));
    yield put(dataActions.getNavigationTypeRejected(err.message));
  }

  yield put(userInfoActions.getUserInfo());
  yield put(endAppLoading("SET_NAVIGATION_TYPE"));
}

export function* getNavigationTypeSaga() {
  yield takeEvery(GET_NAVIGATION_TYPE_START, getNavigationType);
}

export function* setNavigationTypeSaga() {
  yield takeEvery(SET_NAVIGATION_TYPE_START, setNavigationType);
}
