export const CLIENT_CONFIG_LIST_START: string = "CLIENT_CONFIG_LIST_START";
export const CLIENT_CONFIG_LIST_SUCCESS: string = "CLIENT_CONFIG_LIST_SUCCESS";
export const CLIENT_CONFIG_LIST_FAIL: string = "CLIENT_CONFIG_LIST_FAIL";

export const SAVE_APP_CONFIG_LIST_START: string = "SAVE_APP_CONFIG_LIST_START";
export const SAVE_APP_CONFIG_LIST_SUCCESS: string =
  "SAVE_APP_CONFIG_LIST_SUCCESS";
export const SAVE_APP_CONFIG_LIST_FAIL: string = "SAVE_APP_CONFIG_LIST_FAIL";

export const CHECK_LOYALTY_TOKEN: string = "CHECK_LOYALTY_TOKEN";
