import styled from "styled-components";

interface IShape {
  width: string;
}

export default styled.div<IShape>`
  width: ${(props) => props.width};
  height: 100%;
  background: #abd6ff;
`;
