import React, {
  FC,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useRef,
} from "react";
import Modal from "./Modal";
import Container from "./Container";
import RowContainer from "./RowContainer";
import ReactDOM from "react-dom";
import CloseIcon from "./CloseIcon";
import closeIcon from "panel/src/assets/images/close.svg";

interface IContactModal {
  isOpenModal: boolean;
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
}

const ContactModal: FC<IContactModal> = ({ isOpenModal, setIsOpenModal }) => {
  // const [isFormRendered, setIsFormRendered] = useState<boolean>(false);
  // const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  // const formContainerRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (!isFormRendered) {
  //     setIsFormRendered(true);
  //     createHubspotForm();
  //   }
  // }, []);

  // const createHubspotForm = () => {
  //   const formRef = formContainerRef.current;

  //   if (formRef?.innerHTML) {
  //     formRef.innerHTML = "";
  //   }

  //   setIsFormSubmitted(false);

  //   window.hbspt.forms.create({
  //     region: "na1",
  //     portalId: "8792680",
  //     formId: "f1a59cbd-dd50-44e4-a6dd-6371fdc46d3f",
  //     target: "#hubspot-form-container",
  //     onFormSubmit: function () {
  //       setIsFormSubmitted(true);
  //     },
  //   });
  // };

  // const closeModal = () => {
  //   setIsOpenModal((prev) => !prev);

  //   if (isFormSubmitted) {
  //     createHubspotForm();
  //   }
  // };

  // return ReactDOM.createPortal(
  //   <Container display={isOpenModal ? "flex" : "none"}>
  //     <Modal onClick={(e) => e.stopPropagation()}>
  //       <RowContainer>
  //         <CloseIcon onClick={closeModal} src={closeIcon} />
  //       </RowContainer>
  //       <div ref={formContainerRef} id="hubspot-form-container" />
  //     </Modal>
  //   </Container>,
  //   document.getElementById("root") as Element
  // );

  return null;
};

export default ContactModal;
