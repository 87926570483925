import {
  CLIENT_CONFIG_LIST_START,
  CLIENT_CONFIG_LIST_SUCCESS,
  CLIENT_CONFIG_LIST_FAIL,
  SAVE_APP_CONFIG_LIST_START,
  SAVE_APP_CONFIG_LIST_SUCCESS,
  SAVE_APP_CONFIG_LIST_FAIL,
} from "panel/src/redux/constants/clientConfigList";
import { ConfigGroup } from "../../models/Manage/configSettingsResponseModel";
interface BaseConfigListAction {
  type: string;
  token?: any;
  error?: any;
  loading?: any;
}

export const clientConfigListStart = (configListObj: Object) => {
  return {
    type: CLIENT_CONFIG_LIST_START,
    configListObj,
  };
};

export const clientConfigListSuccess = (configList: ConfigGroup[]) => {
  return {
    type: CLIENT_CONFIG_LIST_SUCCESS,
    configList,
    saveConfigResult: 0,
  };
};

export const clientConfigListRejected = (error: string) => {
  return {
    type: CLIENT_CONFIG_LIST_FAIL,
  };
};

export const saveAppConfigListStart = (data: Object) => {
  return {
    type: SAVE_APP_CONFIG_LIST_START,
    data,
  };
};

export const saveAppConfigListSuccess = (
  configList: ConfigGroup[],
  saveConfigResult: number
) => {
  return {
    type: SAVE_APP_CONFIG_LIST_SUCCESS,
    configList,
    saveConfigResult,
  };
};

export const saveAppConfigListRejected = (error: string) => {
  return {
    type: SAVE_APP_CONFIG_LIST_FAIL,
    error,
  };
};
