import React from "react";
import classes from "./progress.module.scss";

interface IProgressProps {
  type: any;
  width: any;
  progressLoadingShow?: boolean;
}

const Progress = (props: IProgressProps) => {
  const attachedClasses = [classes.Progress, classes[props.type]];

  return (
    <div className={classes.ProgressContainer}>
      <div
        className={attachedClasses.join(" ")}
        style={{ width: props.width }}
      ></div>
      {props.progressLoadingShow && <div className={classes.loader} />}
    </div>
  );
};

export default Progress;
