import React from "react";

import Icons from "./Icons";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
  type: string;
  className?: string;
}

const Icon = ({ type, width, height, fillColor, className }: IProps) => {
  switch (type) {
    case "design":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.design({ width, height, fillColor, type })}
        </i>
      );
    case "arrowDown":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.arrowDown({ width, height, fillColor, type })}
        </i>
      );
    case "addIcon":
      return Icons.addIcon({ width, height, fillColor, type });
    case "sort":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.sort({ width, height, fillColor, type })}
        </i>
      );
    case "filter":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.filter({ width, height, fillColor, type })}
        </i>
      );
    case "heart":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.heart({ width, height, fillColor, type })}
        </i>
      );
    case "logOut":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.logOut({ width, height, fillColor, type })}
        </i>
      );
    case "arrowLeft":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.arrowLeft({ width, height, fillColor, type })}
        </i>
      );
    case "share":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.share({ width, height, fillColor, type })}
        </i>
      );
    case "back":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.back({ width, height, fillColor, type })}
        </i>
      );
    case "publish":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.publish({ width, height, fillColor, type })}
        </i>
      );
    case "plus":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.plus({ width, height, fillColor, type })}
        </i>
      );
    case "remove":
      return Icons.remove({ width, height, fillColor, type });
    case "time":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.time({ width, height, fillColor, type })}
        </i>
      );
    case "watchVideo":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.watchVideo({ width, height, fillColor, type })}
        </i>
      );
    case "liveChat":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.liveChat({ width, height, fillColor, type })}
        </i>
      );
    case "arrowUp":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.arrowUp({ width, height, fillColor, type })}
        </i>
      );
    case "search":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.search({ width, height, fillColor, type })}
        </i>
      );
    case "arrowRight":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.arrowRight({ width, height, fillColor, type })}
        </i>
      );
    case "info":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.info({ width, height, fillColor, type })}
        </i>
      );
    case "star":
      return Icons.star({ width, height, fillColor, type });
    case "whatsapp":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.whatsapp({ width, height, fillColor, type })}
        </i>
      );
    case "home":
      return Icons.home({ width, height, fillColor, type });
    case "iconback":
      return Icons.iconBack({ width, height, fillColor, type });
    case "iconshare":
      return Icons.iconShare({ width, height, fillColor, type });
    case "mail":
      return (
        <i
          className={className}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {Icons.mail({ width, height, fillColor, type })}
        </i>
      );
    case "showEye":
      return Icons.showEye({ width, height, fillColor, type });
    case "hideEye":
      return Icons.hideEye({ width, height, fillColor, type });
    case "trashIcon":
      return Icons.trashIcon({ width, height, fillColor, type, className });
    case "editIcon":
      return Icons.editIcon({ width, height, fillColor, type, className });
    case "closeIcon":
      return Icons.closeIcon({ width, height, fillColor, type });
    default:
      return null;
  }
};

Icon.defaultProps = {
  width: "20",
  height: "20",
  fillColor: "#ffffff",
};

export default Icon;
