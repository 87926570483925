import { combineReducers } from "redux";
import generalSettingsReducer from "./generalSettingsReducer";
import navigationTypeReducer from "./navigationType";
import { connectRouter } from "connected-react-router";

import HomeReducer from "./Home";
import loginReducer from "./login";
import userInfoReducer from "./userInfo";
import platformListReducer from "./platformList";
import manageAppReducer from "./manageApp";
import clientConfigListReducer from "./clientConfigList";
import paymentOptionsReducer from "./paymentOptions";
import setPaymentOptionsReducer from "./setPaymentOptions";
import getEngagementInfoReducer from "./getEngagementInfo";
import splashScreenReducer from "./splashScreen";
import colorsReducers from "./colors";
import logoutReducer from "./logout";
import saveComponentsReducer from "./saveComponents";
import saveScreenReducer from "./SaveScreen";
import appsReducer from "./apps";
import modalReducer from "./modal";
import cmsKeysReducer from "./cmsKeys";
import pageReducer from "./page";
import askDemoLinkReducer from "./askDemo";
import resenDemoLinkReducer from "./resendDemo";
import demoInfoReducer from "./demoInfo";
import NewProductListReducer from "./productList";
import NewProductDetailReducer from "./productDetail";
import BannerSettings from "./bannerSettings";
import colorsPageReducer from "./colorsPage";
import getComponentJSONReducer from "./getComponentJSON";
import fontTypePageReducer from "./fontType";
import emulatorReducer from "./emulator";
import setInstagramUserReducer from "./setInstagramUser";
import setInstagramTokenReducer from "./setInstagramToken";
import AppReducer from "./App";
import ForgotPasswordReducer from "./ForgotPassword";
import SetNewPasswordReducer from "./SetNewPassword";
import InfoCardsReducer from "./InfoCards";
import NotificationReducer from "./Notification";
import CategoryReducer from "./Category";
import LiveChatReducer from "./liveChat";
import PackageFeatureReducer from "./getPackageFeature";
import designErrorReducer from "./DesignError";
import logoPositionReducer from "./logoPosition";
import navigationMenuReducer from "./navigationMenu";

const rootReducer = (history: any) =>
  combineReducers({
    application: AppReducer,
    home: HomeReducer,
    colorsPage: colorsPageReducer,
    fontType: fontTypePageReducer,
    productList: NewProductListReducer,
    productDetail: NewProductDetailReducer,
    login: loginReducer,
    userInfo: userInfoReducer,
    componentJSON: getComponentJSONReducer,
    PlatformList: platformListReducer,
    manageApp: manageAppReducer,
    clientConfigList: clientConfigListReducer,
    paymentOptionsList: paymentOptionsReducer,
    setPaymentOptions: setPaymentOptionsReducer,
    getEngagementInfo: getEngagementInfoReducer,
    generalSettings: generalSettingsReducer,
    splashScreen: splashScreenReducer,
    navigationType: navigationTypeReducer,
    navigationMenu: navigationMenuReducer,
    colors: colorsReducers,
    instagram: setInstagramUserReducer,
    instagramtoken: setInstagramTokenReducer,
    logout: logoutReducer,
    saveComponents: saveComponentsReducer,
    saveScreen: saveScreenReducer,
    apps: appsReducer,
    modal: modalReducer,
    cmsKeys: cmsKeysReducer,
    page: pageReducer,
    askDemo: askDemoLinkReducer,
    resendDemo: resenDemoLinkReducer,
    demoInfo: demoInfoReducer,
    bannerSettings: BannerSettings,
    emulator: emulatorReducer,
    forgotPassword: ForgotPasswordReducer,
    setNewPassword: SetNewPasswordReducer,
    infoCards: InfoCardsReducer,
    notification: NotificationReducer,
    category: CategoryReducer,
    router: connectRouter(history),
    liveChat: LiveChatReducer,
    packageFeature: PackageFeatureReducer,
    designError: designErrorReducer,
    logoPosition: logoPositionReducer
  });

export default rootReducer;
