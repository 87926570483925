import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form, Field, FormikProps } from "formik";
import classes from "./begin.module.scss";
import validationSchema from "./Validations/validationSchema";
import validationSchemaWithGoogle from "./Validations/validationSchemaWithGoogle";
import validationSchemaWithFreeTrial from "./Validations/validationSchemaWithFreeTrial";
import Tooltip from "panel/src/Components/Tooltip";
import YoutubeTutorial, {
  YOUTUBE_LINKS,
} from "panel/src/Components/YoutubeTutorial";
import Input from "panel/src/_components/common/Input";
import Select from "panel/src/_components/common/Select";
import Button from "panel/src/_components/common/Button";
import Checkbox from "panel/src/_components/common/Checkbox";
import * as platformListActions from "panel/src/redux/actions/platformList";
import * as manageAppActions from "panel/src/redux/actions/manageApp";
import BlockingForm from "panel/src/_components/common/BlockingForm";
import SubNavigation from "panel/src/_components/primitives/SubNavigation";
import GoogleMerchant from "../GoogleMerchant";
import { fileToBase64 } from "panel/src/utils/helper";
import RadioButton from "panel/src/_components/common/RadioButton";
import Upload from "panel/src/_components/common/Upload";
import validationSchemaWithCSV from "./Validations/validationSchemaWithCSV";
import validationSchemaWithFreeTrialCSV from "./Validations/validationSchemaWithFreeTrialCSV";
import * as Yup from "yup";
import InformationIcon from "panel/src/assets/images/icons-dark-gray-information.svg";
import HelperModal from "../../../../Components/HelperModal";
import { webServiceRequiredErrorMessage } from "../../../../utils/validationMessage";
import { EPlatform } from "../../../../utils/enums/manage";
import Intercom from "../../../../Components/Intercom/Intercom";

interface IBeginProps {
  actions?: any;
  platformList?: any;
  history?: any;
  appId?: string;
  setPlatformObject?: any;
  platformId?: number;
  isSetAppStepCompleted: boolean;
  isStep2Completed: boolean;
  isStep3Completed: boolean;
  isTrial: boolean;
  loading: boolean;
  packagePlatformId?: number;
}

interface IBeginState {
  platformId: any;
  form: any;
  isBlocking: boolean;
  GoogleXmlFile?: any;
  GoogleXmlFileExtension?: any;
  isGoogleMerchantCSVShown?: boolean;
  isGoogleMerchantTabActive?: boolean;
  isOpenModal: boolean;
  customParameters: any;
  googleMerchant: {
    GoogleMerchantName: string;
    GoogleMerchantEmail: string;
    GoogleMerchantID: string;
    GoogleMerchantKeyFile: any;
    GoogleMerchantKeyFileName: string;
    CsvContent: string | ArrayBuffer | null;
  };
}

interface FormValues {
  selectbox: number;
  url: string;
  GoogleMerchantEmail: string;
  GoogleMerchantID: string;
  GoogleMerchantName: string;
  GoogleMerchantKeyFile: any;
  GoogleMerchantKeyFileName: any;
  CsvContent: string;
}

const initialValues: FormValues = {
  selectbox: -1,
  url: "",
  GoogleMerchantEmail: "",
  GoogleMerchantName: "",
  GoogleMerchantID: "",
  GoogleMerchantKeyFile: "",
  GoogleMerchantKeyFileName: "",
  CsvContent: "",
};

const googleMerchantActivePlatforms: number[] = [EPlatform.MowicoDemo];
const helperModalActivePlatforms: number[] = [];
const webUrlDisabledPlatform: number[] = [EPlatform.MowicoDemo];

class Begin extends Component<IBeginProps, IBeginState> {
  constructor(props: any) {
    super(props);
    this.state = {
      platformId: props.packagePlatformId,
      form: null,
      isBlocking: false,
      GoogleXmlFile: "",
      GoogleXmlFileExtension: "",
      isGoogleMerchantCSVShown: false,
      isGoogleMerchantTabActive: true,
      isOpenModal: false,
      customParameters: {},
      googleMerchant: {
        GoogleMerchantName: "",
        GoogleMerchantEmail: "",
        GoogleMerchantID: "",
        GoogleMerchantKeyFile: null,
        GoogleMerchantKeyFileName: "",
        CsvContent: null,
      },
    };
  }

  formikRef: FormikProps<any> | null = null;

  componentDidMount() {
    const { actions } = this.props;
    actions.platformListActions.getPlatformListStart();
  }

  componentDidUpdate = (
    prevProps: IBeginProps,
    prevState: IBeginState,
    snapshot: any
  ) => {
    if (snapshot) {
      if (prevProps.appId !== this.props.appId) {
        if (prevProps.isSetAppStepCompleted) {
          this.props.history.push("/manage/config-settings");
        }
      }
      if (prevProps.platformId)
        this.setState({ platformId: prevProps.platformId });
    }
  };

  onFileChangeHandler = async (fileInput: any) => {
    const file = fileInput.files[0];
    if (file) {
      const res = await fileToBase64(file);
      const merchant = {
        GoogleMerchantKeyFile: res,
        GoogleMerchantKeyFileExtension: file.type ? file.type : "p12",
        GoogleMerchantKeyFileName: file.name,
      };

      this.setState({
        GoogleXmlFile: res,
        GoogleXmlFileExtension: file.type ? file.type : "p12",
        isBlocking: true,
        googleMerchant: {
          ...this.state.googleMerchant,
          ...merchant,
        },
      });
    }
  };

  customParameterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.formikRef?.setFieldValue(e.target.name, e.target.value);
    this.setState({
      customParameters: {
        ...this.state.customParameters,
        [e.target.name]: e.target.value,
      },
      isBlocking: true,
    });
  };

  googleMerchantChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      googleMerchant: {
        ...this.state.googleMerchant,
        [e.target.name]: e.target.value,
      },
    });
  };

  uploadCsvFile = (e: any) => {
    var reader = new FileReader();
    reader.onload = () => {
      this.formikRef?.setFieldValue("CsvContent", e.files[0].name);
      this.setState({
        googleMerchant: {
          ...this.state.googleMerchant,
          CsvContent: reader.result,
        },
      });
    };
    reader.readAsBinaryString(e.files[0]);
  };

  selectBoxChange = (e: any) => {
    const value = e.target.value;
    if (this.state.platformId !== value) {
      this.formikRef?.resetForm();
      this.formikRef?.setFieldValue("selectbox", value);
      this.setState({
        isGoogleMerchantCSVShown: false,
        isGoogleMerchantTabActive: true,
        customParameters: {},
        googleMerchant: {
          GoogleMerchantName: "",
          GoogleMerchantEmail: "",
          GoogleMerchantID: "",
          GoogleMerchantKeyFile: null,
          GoogleMerchantKeyFileName: "",
          CsvContent: null,
        },
        platformId: value,
        isBlocking: true,
        isOpenModal: helperModalActivePlatforms.includes(value),
      });
    }
  };

  formValidation = () => {
    const { isTrial } = this.props;

    if (isTrial) {
      if (this.state.isGoogleMerchantCSVShown) {
        if (this.state.isGoogleMerchantTabActive)
          return validationSchemaWithFreeTrial;
        return validationSchemaWithFreeTrialCSV;
      }
    }

    if (this.state.platformId !== EPlatform.MowicoDemo) {
      if (this.state.isGoogleMerchantCSVShown) {
        if (this.state.isGoogleMerchantTabActive)
          return validationSchemaWithGoogle;
        return validationSchemaWithCSV;
      }
      return validationSchema;
    }

    return Yup.object().shape({});
  };

  onSubmitForm = (values: FormValues) => {
    const { platformList, actions } = this.props;
    const { customParameters } = this.state;

    this.setState({ isBlocking: false }, () => {
      const pickPlatformId =
        platformList &&
        platformList.filter((item: any) => {
          return item.PlatformAdaptorId == this.state.platformId;
        });

      const platformId = pickPlatformId[0].PlatformAdaptorId;
      const setPlatformObject = {
        ApplicationId: this.props.appId,
        serviceUrl: values.url,
        includePlatform: true,
        platformId: platformId.toString(),
        parameters: {
          platformId: platformId,
          ...customParameters,
        },
        GoogleMerchantEmail:
          this.state.isGoogleMerchantCSVShown &&
          this.state.isGoogleMerchantTabActive
            ? values.GoogleMerchantEmail
            : "",
        GoogleMerchantId:
          this.state.isGoogleMerchantCSVShown &&
          this.state.isGoogleMerchantTabActive
            ? values.GoogleMerchantID
            : "",
        GoogleMerchantKeyFile:
          this.state.isGoogleMerchantCSVShown &&
          this.state.isGoogleMerchantTabActive
            ? values.GoogleMerchantKeyFile
            : "",
        GoogleMerchantName:
          this.state.isGoogleMerchantCSVShown &&
          this.state.isGoogleMerchantTabActive
            ? values.GoogleMerchantName
            : "",
        CsvContent:
          this.state.isGoogleMerchantCSVShown &&
          !this.state.isGoogleMerchantTabActive
            ? this.state.googleMerchant.CsvContent // Formik içerisinde dosya ismini koyduğumuz için datayı statedeki değerden okuyoruz
            : "",
      };

      actions.setAppActions.checkAppInfo(
        setPlatformObject,
        platformId,
        values.url,
        this.props.appId
      );
    });
  };

  /**
   *
   * @param {{PlatformAdaptorId: number}} option
   * @returns
   */
  checkPlatformIsDisabled = (option: any) => {
    const { isTrial, packagePlatformId } = this.props;
    let platformList = [packagePlatformId];
    if (isTrial) platformList.push(EPlatform.MowicoDemo);

    return !platformList.includes(option.PlatformAdaptorId);
  };

  /**
   *
   * @param options
   * @returns
   */
  platformListHandle = (options: any[]) => {
    const { isTrial } = this.props;
    if (!isTrial)
      return options.filter((s) => s.value !== EPlatform.MowicoDemo.toString());
    return options;
  };

  render() {
    const { platformList, loading, isTrial } = this.props;
    const { customParameters, googleMerchant, platformId } = this.state;
    let platformNameList: any = [];

    platformList &&
      platformList.map((item: any) => {
        platformNameList.push({
          value: item.PlatformAdaptorId,
          displayValue: item.AdaptorName,
          disabled: this.checkPlatformIsDisabled(item),
        });
      });

    platformNameList = this.platformListHandle(platformNameList);

    const selectedOption =
      platformList &&
      platformList.filter((item: any) => {
        return item.PlatformAdaptorId == platformId;
      });

    let inputForm = "";
    if (
      selectedOption &&
      selectedOption[0] &&
      selectedOption[0].PlatformAdaptorParameters
    ) {
      const inputParameters = selectedOption[0].PlatformAdaptorParameters;
      inputForm = inputParameters.map((item: any, index: number) => {
        return (
          <div key={item.PlatformAdaptorParameterKey}>
            <Field
              key={index}
              elementType="input"
              placeholder={item.PlatformAdaptorParameterSubText}
              name={item.PlatformAdaptorParameterKey}
              type={item.PlatformAdaptorParameterName.toLowerCase()}
              label={item.PlatformAdaptorParameterName}
              value={customParameters?.[item.PlatformAdaptorParameterKey]}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.customParameterChange(e)
              }
              validate={() => {
                if (!this.formikRef?.values[item.PlatformAdaptorParameterKey])
                  return webServiceRequiredErrorMessage;
              }}
              component={Input}
            />
          </div>
        );
      });
    }

    const platformName = platformNameList
      .find((s: any) => s.value === this.state.platformId)?.displayValue;

    return (
      <React.Fragment>
        <HelperModal
          onCloseModal={() => this.setState({ isOpenModal: false })}
          isOpenModal={this.state.isOpenModal}
          platformId={this.state.platformId}
        />
        <SubNavigation />
        {platformNameList && platformNameList?.length && (
          <Intercom
            platform={platformName}
            isBegin
            webUrl={this.state.customParameters?.url}
            accessKey={this.state.customParameters?.ShopifyKey}
          />
        )}
        <div className={classes.contentContainer}>
          <div className={classes.ManageSubRouteContainer}>
            <h3 className={classes.Heading}>Let's connect your store!</h3>
            <div className={classes.subTitleContainer}>
              <p className={classes.subTitle}>
                Please select an e-commerce platform. Note that some e-commerce
                platforms may not be available during the demo.
              </p>
              <Tooltip
                text="Integrating open-sourced e-commerce platforms requires a unique development for each store. If your e-commerce platform cannot be selected, you may still upload your own products simply by choosing Google Merchant option below. "
                position="bottom right"
              />
            </div>
            <div>
              {platformList && (
                <Formik<FormValues>
                  enableReinitialize
                  initialValues={initialValues}
                  validateOnBlur={false}
                  validationSchema={() => this.formValidation()}
                  innerRef={(r) => (this.formikRef = r)}
                  onSubmit={this.onSubmitForm}
                >
                  {({ values, handleBlur, setErrors }) => (
                    <Form>
                      <div className={classes.selectPlatformContainer}>
                        <Field
                          elementType="input"
                          placeholder="Select E-commerce platform"
                          name="selectbox"
                          label="E-Commerce Platform"
                          value={this.state.platformId}
                          options={platformNameList}
                          changeHandler={(e: any) => this.selectBoxChange(e)}
                          packagePlatformId={this.props.packagePlatformId}
                          component={Select}
                        />
                        {this.state.platformId === EPlatform.WooCommerce && (
                            <YoutubeTutorial
                                videoUrl={YOUTUBE_LINKS.WOOCOMMERCE}
                                iconSize={16}
                                label="Click to watch tutorials about $0"
                                interpolateValues={{
                                  $0: "WooCommerce",
                                }}
                            />
                        )}
                        {this.state.platformId === EPlatform.Shopify && (
                          <YoutubeTutorial
                            videoUrl={YOUTUBE_LINKS.SHOPIFY}
                            iconSize={16}
                            label="Click to watch tutorials about $0"
                            interpolateValues={{
                              $0: "Shopify URL and API Key",
                            }}
                          />
                        )}
                        {this.state.platformId ===
                          EPlatform.MowicoDemo && (
                          <YoutubeTutorial
                            videoUrl={YOUTUBE_LINKS.DEMO_PLATFORM}
                            iconSize={16}
                            label="Click to watch tutorials about $0"
                            interpolateValues={{
                              $0: "Mowico Demo Commerce Platform",
                            }}
                          />
                        )}
                      </div>
                      <Field
                        disabled={webUrlDisabledPlatform.includes(
                          this.state.platformId
                        )}
                        elementType="input"
                        placeholder="https://mowicostore.com"
                        name="url"
                        label="Website URL"
                        value={customParameters?.url}
                        onBlur={handleBlur}
                        changeHandler={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          this.customParameterChange(e);
                          setErrors({});
                        }}
                        component={Input}
                        validate={() => {
                          if (
                            !webUrlDisabledPlatform.includes(
                              this.state.platformId
                            ) &&
                            !customParameters?.url
                          )
                            return webServiceRequiredErrorMessage;
                        }}
                      />
                      {inputForm}
                      {googleMerchantActivePlatforms.includes(
                        this.state.platformId
                      ) && (
                        <>
                         {/* I want to upload my products via Google Merchant or CSV file. suanlik kaldirildi daha sonra eklenebilir*/}
                          {/*<div className={classes.platformOption}>
                            <Checkbox
                              marginBottom="0px"
                              checked={
                                this.state.isGoogleMerchantCSVShown ?? false
                              }
                              label={
                                "I want to upload my products via Google Merchant or CSV file."
                              }
                              changeHandler={() =>
                                this.setState({
                                  isGoogleMerchantCSVShown:
                                    !this.state.isGoogleMerchantCSVShown,
                                })
                              }
                            />
                          </div>*/}
                          {this.state.isGoogleMerchantCSVShown && (
                            <>
                              <div className={classes.header}>
                                Product installation method
                              </div>
                              <div className={classes.RadioContainer}>
                                <div className={classes.RadioWrapper}>
                                  <RadioButton
                                    checked={
                                      this.state.isGoogleMerchantTabActive ??
                                      false
                                    }
                                    label="Google Merchant API"
                                    changeHandler={() =>
                                      this.setState({
                                        isGoogleMerchantTabActive: true,
                                      })
                                    }
                                  />
                                  <Tooltip text="If your e-commerce platform is not available above, you may upload your e-commerce products with Google Merchant." />
                                  <div className={classes.YoutubeIcon}>
                                    <YoutubeTutorial
                                      videoUrl={
                                        YOUTUBE_LINKS.GOOGLE_MERCHANT_API
                                      }
                                      iconSize={16}
                                    />
                                  </div>
                                </div>
                                <div className={classes.RadioWrapper}>
                                  <RadioButton
                                    checked={
                                      !this.state.isGoogleMerchantTabActive
                                    }
                                    label="Upload CSV File"
                                    changeHandler={() =>
                                      this.setState({
                                        isGoogleMerchantTabActive: false,
                                      })
                                    }
                                  />
                                  <Tooltip text="If your e-commerce platform is not available above, you may upload your e-commerce products with uploading CSV file." />
                                  <div className={classes.YoutubeIcon}>
                                    <YoutubeTutorial
                                      videoUrl={YOUTUBE_LINKS.GOOGLE_CSV}
                                      iconSize={16}
                                    />
                                  </div>
                                </div>
                              </div>
                              {this.state.isGoogleMerchantTabActive ? (
                                <GoogleMerchant
                                  googleMerchantEmail={
                                    googleMerchant.GoogleMerchantEmail
                                  }
                                  googleMerchantID={
                                    googleMerchant.GoogleMerchantID
                                  }
                                  googleMerchantName={
                                    googleMerchant.GoogleMerchantName
                                  }
                                  googleMerchantKeyFile={
                                    googleMerchant.GoogleMerchantKeyFile
                                  }
                                  googleMerchantKeyFileName={
                                    googleMerchant.GoogleMerchantKeyFileName
                                  }
                                  setFile={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => this.onFileChangeHandler(e)}
                                  setInputs={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    this.googleMerchantChange(e);
                                    setErrors({});
                                  }}
                                />
                              ) : (
                                <>
                                  <div className={classes.header}>
                                    Product List File
                                  </div>
                                  <Field
                                    placeholder="Please Upload"
                                    onChange={(e: any) => this.uploadCsvFile(e)}
                                    logoName={values.CsvContent}
                                    name="CsvContent"
                                    uploadCSVFile
                                    component={Upload}
                                  />
                                  <div className={classes.infoTextContainer}>
                                    <img
                                      className={classes.infoIcon}
                                      src={InformationIcon}
                                      alt="mowico-csv-info"
                                    />
                                    <div className={classes.csvInfoText}>
                                      Download a{" "}
                                      <a
                                        className={classes.downloadLink}
                                        target="_blank"
                                        href="https://freetrial.mowico.com/files/Mowico%20Sample%20CSV.csv"
                                      >
                                        sample CSV template
                                      </a>{" "}
                                      to see an example of the format required.
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
          <div className={classes.SaveButtonContainer}>
            <Button
              width="160px"
              loadingSpinner={loading}
              disableButton={isTrial ? false : this.state.platformId === -1}
              onClick={() => this.formikRef?.submitForm()}
            >
              Verify
            </Button>
          </div>
        </div>
        <BlockingForm isBlocking={this.state.isBlocking} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    isTrial: state.userInfo.IsTrial,
    platformId: state.userInfo.selectedApp.PlatformId,
    platformList: state.PlatformList.PlatformAdaptors,
    setPlatformObject: state.manageApp.setPlatformObject,
    isSetAppStepCompleted: state.userInfo.selectedApp.IsStep1Completed,
    isStep2Completed: state.userInfo.selectedApp.IsStep2Completed,
    isStep3Completed: state.userInfo.selectedApp.IsStep3Completed,
    loading: state.manageApp.loading,
    packagePlatformId: state.userInfo.UserPackage.PlatformId,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: {
      platformListActions: bindActionCreators(platformListActions, dispatch),
      setAppActions: bindActionCreators(manageAppActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Begin);
