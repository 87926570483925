import {
  GET_COMPONENT_JSON,
  GET_COMPONENT_JSON_SUCCESS,
  GET_COMPONENT_JSON_FAIL,
  RESET_COMPONENT_JSON,
} from "panel/src/redux/constants/getComponentJSON";

interface BaseAction {
  type: string;
  data?: Object;
  error?: string;
  requestObj?: Object;
}

export const getComponentJSON = (requestObj: any): BaseAction => ({
  type: GET_COMPONENT_JSON,
  requestObj,
});

export const getComponentJSONSuccess = (data: Object): BaseAction => ({
  type: GET_COMPONENT_JSON_SUCCESS,
  data,
});

export const getComponentJSONFail = (error: string): BaseAction => ({
  type: GET_COMPONENT_JSON_FAIL,
  error,
});
export const resetComponentJSON = () => ({
  type: RESET_COMPONENT_JSON,
});
