import { NotificationModule } from "../../models/Notification";
import { SET_NOTIFICATIONS } from "../constants/notifications";

const defaultState: NotificationModule.NotificationStore = {
  notifications: [],
};

export default function (state = defaultState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case SET_NOTIFICATIONS: {
      return {
        notifications: payload,
      };
    }

    default:
      return state;
  }
}
