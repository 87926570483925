import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { dimenHeight, dimenWidth } from 'shared-components/src/utils/helper';
import { colors } from 'shared-components/src/utils/constants';

interface IPageDot {
  isActive: boolean;
}

export default styled.View<IPageDot>`
  height: ${Platform.OS === 'web' ? '6' : dimenHeight(6)}px;
  width: ${Platform.OS === 'web' ? '6' : dimenWidth(6)}px;
  background: ${(props) =>
    props.isActive ? colors.darkSlateBlue : colors.iceBlue};
  ${(props) => props.isActive && `border:1px solid ${colors.white}`}
  border-radius: ${Platform.OS === 'web' ? '3' : dimenHeight(3)}px;
`;
