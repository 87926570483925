import { Platform, Dimensions } from "react-native";
import styled from "styled-components/native";

interface IContainer {
  coverScreen?: boolean;
}
const screenHeight = Math.round(Dimensions.get("window").height);
const screenWidth = Math.round(Dimensions.get("window").width);

export default styled.View<IContainer>`
  position: relative;
  height: ${Platform.OS === "web"
    ? "165"
    : (props) => (props.coverScreen ? screenHeight : screenWidth)}px;
  width: 100%;
  ${Platform.OS === "web" ? `padding-bottom: 5px` : `margin-bottom: 8px;`}
`;
