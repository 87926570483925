import React from "react";
import { ColorList } from "../../../models/Design/colors/IGetColorsPageDataResponse";
// SHARED COMPONENTS START
import {
  Rayon,
  LargeCategoryView,
  CategorySlider,
  BrandList,
  LinkListview,
  SmallCategoryView,
  InstagramShowcase,
  SocialMedia,
  LargeBanner,
  MediumBanner,
  SmallBanner,
  Slider,
  CustomHtml,
} from "shared-components/src/App";
// SHARED COMPONENTS END

import { IComponent } from "panel/src/Components/DragnDrop/Components";
import { ComponentType } from "panel/src/utils/enums/componentType";
import { PageType } from "panel/src/utils/enums/pageType";

export const getHomeComponents = (
  fontFamily: string,
  colorList: ColorList[],
  componentId: number,
  JSON: any,
  CustomJSON?: any
) => {
  let component: IComponent | undefined;
  switch (componentId) {
    case ComponentType.Rayon:
      const rayonList = JSON;
      component = {
        componentId: ComponentType.Rayon,
        pageId: PageType.Home,
        componentTitle: "Rayon",
        component: (
          <Rayon
            fontFamily={fontFamily}
            colorList={colorList}
            imageResizeType={rayonList.imageResizeType}
            rayonList={rayonList}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.HomeSlider:
      component = {
        componentId: ComponentType.HomeSlider,
        pageId: PageType.Home,
        componentTitle: "Slider",
        component: (
          <Slider Items={JSON.Items} imageResizeType={JSON.imageResizeType} />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.LargeCategoryView:
      component = {
        componentId: ComponentType.LargeCategoryView,
        pageId: PageType.Home,
        componentTitle: "Large Category View",
        component: (
          <LargeCategoryView
            fontFamily={fontFamily}
            colorList={colorList}
            imageResizeType={JSON.imageResizeType}
            category={JSON}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.CategorySlider:
      component = {
        componentId: ComponentType.CategorySlider,
        pageId: PageType.Home,
        componentTitle: "Category Slider",
        component: (
          <CategorySlider
            fontFamily={fontFamily}
            colorList={colorList}
            imageResizeType={JSON.imageResizeType}
            categoryList={JSON}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.BrandList:
      component = {
        componentId: ComponentType.BrandList,
        pageId: PageType.Home,
        componentTitle: "Brand List",
        component: (
          <BrandList
            fontFamily={fontFamily}
            colorList={colorList}
            brands={JSON.Brands}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.LinkListView:
      component = {
        componentId: ComponentType.LinkListView,
        pageId: PageType.Home,
        componentTitle: "Link Listview",
        component: (
          <LinkListview
            fontFamily={fontFamily}
            colorList={colorList}
            linkList={JSON.Items}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.SmallCategoryView:
      const CategoryJSON = CustomJSON ? CustomJSON : JSON;
      component = {
        componentId: ComponentType.SmallCategoryView,
        pageId: PageType.Home,
        componentTitle: "Small Category View",
        component: (
          <SmallCategoryView
            fontFamily={fontFamily}
            colorList={colorList}
            categories={CategoryJSON.Categories}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.InstagramShowcase:
      component = {
        componentId: ComponentType.InstagramShowcase,
        pageId: PageType.Home,
        componentTitle: "Instagram Showcase",
        component: (
          <InstagramShowcase
            fontFamily={fontFamily}
            colorList={colorList}
            instagramImages={JSON}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.SocialMedia:
      let socialMediaData = JSON;
      component = {
        componentId: ComponentType.SocialMedia,
        pageId: PageType.Home,
        componentTitle: "Social Media Icons",
        component: (
          <SocialMedia
            fontFamily={fontFamily}
            colorList={colorList}
            socialMedias={socialMediaData.socialMediaPlatforms}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.LargeBanner:
      component = {
        componentId: ComponentType.LargeBanner,
        pageId: PageType.Home,
        componentTitle: "Large banner",
        component: (
          <LargeBanner
            bannerInfo={JSON}
            imageResizeType={JSON.imageResizeType}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.MediumBanner:
      component = {
        componentId: ComponentType.MediumBanner,
        pageId: PageType.Home,
        componentTitle: "Medium banner",
        component: (
          <MediumBanner
            bannerInfo={JSON}
            imageResizeType={JSON.imageResizeType}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.SmallBanner:
      component = {
        componentId: ComponentType.SmallBanner,
        pageId: PageType.Home,
        componentTitle: "Small banner",
        component: (
          <SmallBanner
            bannerInfo={JSON}
            imageResizeType={JSON.imageResizeType}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.CustomHtml:
      component = {
        componentId: ComponentType.CustomHtml,
        pageId: PageType.Home,
        componentTitle: "HTML",
        component: (
          <CustomHtml
            htmlInfo={JSON}
            fontFamily={fontFamily}
            colorList={colorList}
          />
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    default:
      break;
  }
  return component;
};
