import { Platform } from "react-native";
import styled from "styled-components/native";
import { dimenWidth } from "shared-components/src/utils/helper";

interface IContainer {
  backgroundColor: string;
}

export default styled.View<IContainer>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  padding-horizontal: ${Platform.OS === "web" ? "5" : dimenWidth(10)}px;
  height: ${Platform.OS === "web" ? "17" : dimenWidth(20)}px;
  border-radius: ${Platform.OS === "web" ? "2" : dimenWidth(2)}px;
`;
