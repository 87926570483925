import { call, put, takeEvery, select } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import { baseUrl } from "../../services/api/config";
import { endAppLoading, startAppLoading } from "../actions/App";
import { getNavigationMenuFailed, getNavigationMenuSuccess, updateNavigationMenuFailed, updateNavigationMenuSuccess } from "../actions/navigationMenu";
import { clearDesignError, setDesignError } from "../actions/DesignError";
import { GET_NAVIGATION_MENU, UPDATE_NAVIGATION_MENU } from "../constants/navigationMenu";

function* getNavigationMenu() {
  const API_PATH = "/api/Design/GetNavigationTypeMenuItem";
  try {
    yield put(startAppLoading("GET_NAVIGATION_MENU"));
    const ApplicationId = yield select((state) => state.userInfo.selectedAppId);
    const { data } = yield call(axios.post, API_PATH, { ApplicationId });
    let navigationData = data?.ResultData?.NavigationMenuItem;
    navigationData.MenuItems = navigationData?.MenuItems?.map((item: any) => {
      if (!item.IconUrl) {
        const IconUrl = `${baseUrl}/images/IconSets/Default/More.png`
        return { ...item, IconUrl };
      }
      return item;
    });
    yield put(clearDesignError());
    yield put(getNavigationMenuSuccess(navigationData));
  } catch (error) {
    const err = error as any;
    yield put(getNavigationMenuFailed());
    yield put(setDesignError(`${API_PATH} - ${err?.response?.status}`));
  } finally {
    yield put(endAppLoading("GET_NAVIGATION_MENU"));
  }
}

function* updateNavigationMenu() {
  const API_PATH = "/api/Design/SetNavigationTypeMenuItem";
  try {
    yield put(startAppLoading("UPDATE_NAVIGATION_MENU"));
    const ApplicationId = yield select((state) => state.userInfo.selectedAppId);
    const { MenuItems, NameAlignment } = yield select((state) => state.navigationMenu);

    const jsonData = JSON.stringify({
      MenuItems,
      NameAlignment,
      ApplicationId,
    });

    yield call(axios.post, API_PATH, { JSON: jsonData, ApplicationId });
    yield put(clearDesignError());
    yield put(updateNavigationMenuSuccess());
  } catch (error) {
    const err = error as any;
    yield put(updateNavigationMenuFailed());
    yield put(setDesignError(`${API_PATH} - ${err?.response?.status}`));
  } finally {
    yield put(endAppLoading("UPDATE_NAVIGATION_MENU"));
  }
}

export function* getNavigationMenuSaga() {
  yield takeEvery(GET_NAVIGATION_MENU, getNavigationMenu);
}

export function* updateNavigationMenuSaga() {
  yield takeEvery(UPDATE_NAVIGATION_MENU, updateNavigationMenu);
}