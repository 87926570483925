import React, {
  FC,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useRef,
} from "react";
import Container from "./Container";
import ReactDOM from "react-dom";
import classes from "./upgradeModal.module.scss";
import close from "../../assets/images/close.svg";
import Button from "../../_components/common/Button";
import Modal from "../../_components/common/Modal";
import { useSelector } from "react-redux";

interface IContactModal {
  isOpenModal: boolean;
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
}

const plans = [
  {
    title: "Startup",
    description: "The perfect plan to start your Mowico experience.",
    buttonText: "Current plan",
  },
  {
    title: "Growth",
    description: "Boost your sales with unlimited Mowico features.",
    buttonText: "Buy Now",
    link: "https://mowico.com/pricing/",
  },
  {
    title: "Enterprise",
    description:
      "Customised Mowico solutions tailored for your unique business requirements.",
    buttonText: "Talk with an expert",
    link: "https://mowico.pipedrive.com/scheduler/1m1d9zu8/meet-with-mowico",
  },
];

const UpgradeModal: FC<IContactModal> = ({ isOpenModal, setIsOpenModal }) => {
  const [isFormRendered, setIsFormRendered] = useState<boolean>(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const formContainerRef = useRef<HTMLDivElement>(null);

  const { UpperPackName, PackageName } = useSelector(
    (store: any) => store.userInfo.UserPackage
  );

  useEffect(() => {
    if (!isFormRendered) {
      setIsFormRendered(true);
      createHubspotForm();
    }
  }, []);

  const createHubspotForm = () => {
    const formRef = formContainerRef.current;

    if (formRef?.innerHTML) {
      formRef.innerHTML = "";
    }

    setIsFormSubmitted(false);

    window.hbspt.forms.create({
      region: "na1",
      portalId: "8792680",
      formId: "f1a59cbd-dd50-44e4-a6dd-6371fdc46d3f",
      target: "#hubspot-form-container",
      onFormSubmit: function () {
        setIsFormSubmitted(true);
      },
    });
  };

  const closeModal = () => {
    setIsOpenModal((prev) => !prev);

    if (isFormSubmitted) {
      createHubspotForm();
    }
  };

  const currentPlan = plans.find((s) => s.title === PackageName);
  const upgradePlan = plans.find((s) => s.title === UpperPackName);

  return ReactDOM.createPortal(
    <Container display={isOpenModal ? "flex" : "none"}>
      <Modal
        show={isFormRendered}
        width="477"
        style={{ alignItems: "flex-start" }}
      >
        <div className={classes.ModalTitle}>
          <h5 className={classes.ModalTitleText}>Please choose a plan.</h5>
        </div>
        <div className={classes.Close} onClick={closeModal}>
          <img src={close} />
        </div>

        <div className={`${classes.ModalBody}`} style={{ marginTop: "15px" }}>
          <div>
            <p>
              {PackageName} accounts are limited to some features. Upgrade your
              plan to enjoy more features.
            </p>
          </div>
        </div>
        <div className={classes.ModalContent}>
          <div className={classes.ItemContainer}>
            <div className={classes.Item}>
              <span>{currentPlan?.title}</span>
              <p>{currentPlan?.description}</p>
            </div>
            <Button
              marginTop="15px"
              type="button"
              className="Primary-disabled"
              width="127px"
              fontSize="12px"
              onClick={() => {}}
              disabled={false}
            >
              Current plan
            </Button>
          </div>
          <div className={classes.ItemContainer}>
            <div className={classes.Item}>
              <span className={classes.blueText}>{upgradePlan?.title}</span>
              <p className={classes.blueText}>{upgradePlan?.description}</p>
            </div>
            <Button
              type="button"
              className="Primary"
              onClick={() => {
                window.open(upgradePlan?.link, "_blank");
              }}
              width="127px"
              fontSize="12px"
              disabled={false}
            >
              {upgradePlan?.buttonText}
            </Button>
          </div>
        </div>
        <div className={classes.ModalFooter}>
          <a
            className={classes.footerButtonContainer}
            href="https://mowico.com/pricing/"
            target="_blank"
          >
            <span className={classes.pricingFeature}>
              See Pricing + Feature Comparison
            </span>
          </a>

          <div className={classes.footerButtonContainer} onClick={closeModal}>
            <span className={classes.maybeLater}>Maybe Later</span>
          </div>
        </div>
      </Modal>
    </Container>,
    document.getElementById("root") as Element
  );

  return null;
};

export default UpgradeModal;
