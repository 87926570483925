import React from "react";
import { Platform, View } from "react-native";
import ProductCard from "shared-components/src/components/ProductCard";
import { dimenHeight, dimenWidth } from "shared-components/src/utils/helper";
import { colors } from "shared-components/src/utils/constants";
import HeaderContainerTouch from "./HeaderContainerTouch";
import HeaderContainerView from "./HeaderContainerView";
import Header from "./Header";
import Card from "./Card";
import Content from "./Content";
import ArrowRightSmall from "../../icons/ArrowRightSmall";
import { ColorListData } from "shared-components/src/models/colorListData";
import { isFavorite } from "../../utils/favorite";
import { IProduct } from "../../models/Product";

interface IRayon {
  colorList?: ColorListData[];
  rayonList: {
    Title: string;
    CategoryId: string | null;
    GroupId: string;
    maxProductCount: number;
    Products: IProduct[];
  };
  titleText?: string;
  handleProductSearch?: Function;
  isWishList?: boolean;
  setFavorite?: Function;
  navigate?: (route: string, params: Record<string, any>) => void;
  removeFavorite?: Function;
  favorites?: any[];
  fontFamily?: any;
  imageResizeType: "cover" | "contain";
  hasContainer?: boolean;
}

const Rayon = ({
  rayonList,
  titleText,
  handleProductSearch,
  isWishList,
  setFavorite,
  removeFavorite,
  colorList,
  favorites,
  fontFamily,
  imageResizeType,
  hasContainer,
  navigate,
}: IRayon) => {
  const HeaderContainer = ({ children }: { children: React.ReactNode }) => {
    if (rayonList.CategoryId || rayonList.GroupId)
      return (
        <HeaderContainerTouch onPress={() => handleOnPress()}>
          {children}
        </HeaderContainerTouch>
      );
    else return <HeaderContainerView>{children}</HeaderContainerView>;
  };

  const handleOnPress = () => {
    if (rayonList.CategoryId && navigate) {
      navigate('ProductList', {
        categoryId: rayonList.CategoryId,
        title: rayonList.Title,
      })
    } else if (rayonList.GroupId && navigate) {
      navigate('ProductList', {
        categoryId: rayonList.GroupId,
        title: rayonList.Title,
      })
    }
  };

  return (
    <Card>
      <HeaderContainer>
        <Header
          fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
          primary={colorList ? colorList[0].colorCode : colors.darkSlateBlue}
        >
          {rayonList.Title ? rayonList.Title : titleText}
        </Header>
        {rayonList.CategoryId || rayonList.GroupId ? (
          <ArrowRightSmall
            fillColor={
              colorList ? colorList[0].colorCode : colors.darkSlateBlue
            }
            height={Platform.OS === "web" ? "11" : dimenHeight(16).toString()}
            width={Platform.OS === "web" ? "11" : dimenWidth(16).toString()}
          />
        ) : null}
      </HeaderContainer>
      <Content>
        {rayonList &&
          rayonList.Products &&
          rayonList.Products.map((item, index) => {
            let _isFavorite = false;
            if (favorites)
              _isFavorite = isFavorite({
                favorites,
                productId: item.ProductId || item.Id,
              });

            return (
              <View
                style={{
                  padding: 8,
                  marginRight: 8,
                  marginLeft: index === 0 ? 8 : 0,
                }}
                key={index.toString()}
              >
                <ProductCard
                  fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                  product={item}
                  handleProductSearch={handleProductSearch}
                  isFavorite={_isFavorite}
                  isWishList={isWishList}
                  setFavorite={setFavorite}
                  removeFavorite={removeFavorite}
                  colorList={colorList}
                  imageResizeType={imageResizeType}
                />
              </View>
            );
          })}
      </Content>
    </Card>
  );
};

export default Rayon;
