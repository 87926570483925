import React, { Component } from "react";
import { ChromePicker } from 'react-color';
import classes from "./colorPalette.module.scss";
import { HSLToHex } from "../../../utils/helper";
import HueSlider from "../HueSlider";

interface IProps {
  pickedColor?: string;
  onSelectedColorPicker: Function;
  pickerRight?: boolean;
  onChangeColorInput?: React.ChangeEventHandler;
}
interface IState {
  displayColorPicker?: boolean;
  hue: number;
}

class ColorPalettePicker extends Component<IProps, IState> {

  state = {
    displayColorPicker: false,
    hue: 0,
  }
  handleColorClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleColorClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChangeComplete = (color: any) => {
    this.props.onSelectedColorPicker(color.hex)
  };

  handleHueChange = (value: any) => {
    this.setState({ hue: value });
    this.props.onSelectedColorPicker(HSLToHex(value, 100, 50))
  };

  render() {

    return (
      <div className={classes.colorContainer}>
        {this.props.pickerRight == false && (
          <span
            onClick={this.handleColorClick}
            className={classes.colorSquare}
            style={{ backgroundColor: this.props.pickedColor && this.props.pickedColor.replace('O', '0') }}>
          </span>
        )}
        <input
          className={classes.inputColor}
          value={this.props.pickedColor && this.props.pickedColor.replace('O', '0')}
          onChange={this.props.onChangeColorInput}
          maxLength={7} />
        {this.state.displayColorPicker ? <div className={classes.popover}>
          <div className={classes.cover} onClick={this.handleColorClose} />
          <ChromePicker
            className={classes.chromePicker}
            color={this.props.pickedColor && this.props.pickedColor.replace('O', '0')}
            onChange={this.handleChangeComplete}
            onChangeComplete={this.handleChangeComplete}
          />

          <div className={classes.hueSlider} >
            <HueSlider value={this.state.hue} min={0} max={360} onChange={this.handleHueChange} />
          </div>


          <div className={classes.colorPickerInput}>
            <span
              className={classes.colorSquare}
              style={{ backgroundColor: this.props.pickedColor && this.props.pickedColor.replace('O', '0') }}>
            </span>
            <input
              className={classes.inputColor}
              value={this.props.pickedColor && this.props.pickedColor.replace('O', '0')}
              onChange={this.props.onChangeColorInput}
              maxLength={7}
            />
          </div>
        </div> : null}
        {this.props.pickerRight && (
          <span
            onClick={this.handleColorClick}
            className={classes.colorSquare}
            style={{ backgroundColor: this.props.pickedColor && this.props.pickedColor.replace('O', '0') }}>
          </span>
        )}
      </div>
    );
  }
}

export default ColorPalettePicker;