import { put, call, takeLatest } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import { SET_NEW_PASSWORD } from "panel/src/redux/constants";
import {
  setNewPasswordSuccess,
  setNewPasswordFail,
} from "panel/src/redux/actions/SetNewPassword";
import { push } from "react-router-redux";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import { loginStart } from "panel/src/redux/actions/login";

function* setNewPassword(payload: {
  activationCode: string;
  newPassword: string;
  type: string;
  isNewUser: boolean;
}) {
  yield put(startAppLoading(SET_NEW_PASSWORD));
  try {
    const setNewPasswordData: any = yield call(
      axios.post,
      "/api/login/SetNewPassword",
      {
        ActivationCode: payload.activationCode,
        NewPassword: payload.newPassword,
      }
    );

    yield put(setNewPasswordSuccess());
    yield put(
        push({
          pathname: "/login",
          state: {
            successMessage: "Your password has been successfully updated.",
        },
      })
    );
  } catch (error) {
    yield put(setNewPasswordFail(error.response.data.ResultMesage));
  }
  yield put(endAppLoading(SET_NEW_PASSWORD));
}

export function* setNewPasswordSaga() {
  yield takeLatest(SET_NEW_PASSWORD, setNewPassword);
}
