import * as Yup from "yup";
import {
  requiredMessage,
  minMessage,
  passwordsMatch,
  passwordMatches,
} from "panel/src/utils/validationMessage";
import { newPasswordValidation } from "panel/src/utils/regex";

const Schema = Yup.object().shape({
  password: Yup.string()
    .required(requiredMessage)
    .min(8, minMessage)
    .max(20, minMessage)
    .matches(newPasswordValidation, passwordMatches),
  rePassword: Yup.string()
    .oneOf([Yup.ref("password"), null], passwordsMatch)
    .required(requiredMessage)
    .min(8, minMessage)
    .max(20, minMessage)
    .matches(newPasswordValidation, passwordMatches),
});

export default Schema;
