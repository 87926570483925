import {
  START_APP_LOADING,
  END_APP_LOADING,
  SET_APP_ERROR,
  SET_APP_RESET,
} from "panel/src/redux/constants";

interface IApp {
  [key: string]: { isLoading: boolean };
}

const initialState: IApp = {};

const appReducer = (state: IApp = initialState, action: any) => {
  switch (action.type) {
    case START_APP_LOADING:
      return {
        ...state,
        [action.id]: {
          isLoading: true,
        },
      };
    case END_APP_LOADING:
      return {
        ...state,
        [action.id]: {
          isLoading: false,
        },
      };
    case SET_APP_ERROR:
      return {
        ...state,
        error: action.errorMessage,
      };
    case SET_APP_RESET:
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default appReducer;
