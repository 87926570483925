import React, { Component } from "react";

import classes from "./editableCell.module.scss";
import EditIcon from "panel/src/assets/images/icons-dark-gray-edit.svg";

interface IProps {
  initialValue: string;
  onUpdateValue: Function;
  readOnly: boolean;
  showUpdateInfo: boolean;
}

interface IState {
  value: string;
  showInput: boolean;
  isUpdated: boolean;
}

class EditableCell extends Component<IProps, IState> {
  inputRef: HTMLTextAreaElement | null | undefined;
  constructor(props: IProps) {
    super(props);
    this.state = {
      value: props.initialValue,
      showInput: false,
      isUpdated: false,
    };
  }

  static defaultProps = {
    initialValue: "",
    onUpdateValue: () => null,
    readOnly: false,
    showUpdateInfo: true,
  };

  componentDidUpdate(prevProps: IProps) {
    const { initialValue } = this.props;
    const { value } = this.state;

    if (prevProps.initialValue !== initialValue) {
      if (initialValue !== value) {
        this.setState({ value: initialValue, isUpdated: false });
        return;
      }
      this.setState({ isUpdated: true });
    }
  }

  openInput = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.setState({ showInput: true }, () => this.inputRef!.focus());
  };

  changeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ value: e.target.value });
  };

  editValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { onUpdateValue, initialValue } = this.props;

    if (e.target.value !== initialValue) {
      onUpdateValue!(e.target.value);
    }

    this.setState({ showInput: false });
  };

  render() {
    const { readOnly, showUpdateInfo } = this.props;
    const { value, isUpdated, showInput } = this.state;
    const textElement = (
      <a href="#" className={classes.editBtn} onClick={this.openInput}>
        {value}
        <img src={EditIcon} alt="Mowico" />
      </a>
    );
    const inputElement = (
      <textarea
        className={classes.input}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          this.changeValue(e)
        }
        onBlur={(e) => this.editValue(e)}
        ref={(element) => (this.inputRef = element)}
        cols={50}
        readOnly={readOnly}
        value={value || ""}
      />
    );

    return (
      <div
        className={`${classes.wrapper} ${!readOnly ? classes.editable : ""}
      ${isUpdated && showUpdateInfo ? classes.updated : ""}`}
      >
        {showInput ? inputElement : textElement}
      </div>
    );
  }
}

export default EditableCell;
