import {
  SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAIL,
} from "panel/src/redux/constants";

export const setNewPassword = (
  activationCode: string,
  newPassword: string,
  isNewUser: boolean
) => ({
  type: SET_NEW_PASSWORD,
  activationCode,
  newPassword,
  isNewUser,
});

export const setNewPasswordSuccess = () => ({
  type: SET_NEW_PASSWORD_SUCCESS,
});

export const setNewPasswordFail = (payload: any) => ({
  type: SET_NEW_PASSWORD_FAIL,
  payload,
});
