import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 134px;
  height: 30px;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);
  background-color: #3196f6;
  font-family: Red Hat Display;
  font-size: 13px;
  font-weight: 500;
  color: white;
`;
