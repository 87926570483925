import React from "react";
import { Field } from "formik";
import Input from "panel/src/_components/common/Input";
import classes from "./socialMediaOption.module.scss";
import ToggleSwitch from "panel/src/_components/common/ToggleSwitch";
import Tooltip from "panel/src/Components/Tooltip";

interface ISocialProps {
  formElements?: any;
  checked: boolean;
  label: string;
  description: string;
  toggleSwitchHandler?: (e: any) => void;
  inputChangeHandler?: any;
  disabled?: boolean;
  isTrial?: boolean;
}

const SocialMediaOption = (props: ISocialProps) => {
  const inputs =
    props.formElements &&
    props.formElements.map((formElement: any) => {
      const checkValueIsEmpty = formElement.config.value || "";

      return (
        <Field
          key={formElement.id}
          name={formElement.config.name}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={checkValueIsEmpty}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          label={formElement.config.label}
          component={Input}
        />
      );
    });

  let details = null;
  if (props.checked) {
    details = <div>{inputs}</div>;
  }

  return (
    <React.Fragment>
      <div className={classes.SocialMediaOption}>
        <h4 className={classes.Title}>Social Media Option</h4>
        <p className={classes.Select}>Please Select to Activate </p>
        <div className={classes.ToggleSwitchContainer}>
          <ToggleSwitch
            label={props.label}
            changeHandler={props.toggleSwitchHandler}
            checked={props.checked}
            labelMarginLeft={"16px"}
            marginBottom={"0px"}
            disabled={props.disabled}
          />
          {props.disabled && props.isTrial ? (
            <Tooltip text="Trial account does not support this feature." />
          ) : null}
        </div>
        {props.checked && (
          <p className={classes.Description}>{props.description}</p>
        )}
        {details}
      </div>
    </React.Fragment>
  );
};

export default SocialMediaOption;
