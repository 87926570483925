import * as Yup from "yup";
import {
  urlErrorMessage,
  webServiceRequiredErrorMessage,
} from "panel/src/utils/validationMessage";
import { storeId, httpsUrlPattern } from "panel/src/utils/regex";
import { EPlatform } from "../../../../../utils/enums/manage";

interface FormValues {
  selectbox: number;
  url: string;
  ApiUserName: string;
  ApiPassword: string;
  storeId: string;
}

const Schema = Yup.lazy<FormValues>((values): any => {
  switch (values.selectbox) {
    case 1:
      return Yup.object().shape({
        selectbox: Yup.number(),
        url: Yup.string()
          .url(urlErrorMessage)
          .matches(httpsUrlPattern, urlErrorMessage)
          .required(webServiceRequiredErrorMessage),
        storeId: Yup.string()
          .matches(storeId, webServiceRequiredErrorMessage)
          .required(webServiceRequiredErrorMessage),
      });
    case EPlatform.Shopify:
      return Yup.object().shape({
        selectbox: Yup.number(),
        url: Yup.string()
          .url(urlErrorMessage)
          .matches(httpsUrlPattern, urlErrorMessage)
          .required(webServiceRequiredErrorMessage),
        ShopifyKey: Yup.string().required(webServiceRequiredErrorMessage),
      });
    default:
      return Yup.object().shape({
        selectbox: Yup.number(),
      });
  }
});

export default Schema;
