import React from "react";
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";
import { connect } from "react-redux";

import { getToken } from "../../utils/helper";

interface IProps extends RouteProps {
  isAuthenticated: boolean;
  Component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const PublicRoute = ({ isAuthenticated, Component, ...rest }: IProps) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: !!getToken(),
  };
};

export default connect(mapStateToProps)(PublicRoute);
