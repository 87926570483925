import { SET_EMULATOR_OS } from "panel/src/redux/constants";

const initialState = {
  os: 1,
};

const emulatorReducer = (state: Object = initialState, action: any) => {
  switch (action.type) {
    case SET_EMULATOR_OS:
      return {
        ...state,
        os: action.os,
      };
    default:
      return state;
  }
};

export default emulatorReducer;
