import React from "react"

const CenterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12">
    <g fill="none" fill-rule="evenodd">
      <path fill="currentColor" d="M.804 5C.36 5 0 5.373 0 5.833c0 .452.346.82.779.833h13.417c.444 0 .804-.372.804-.833 0-.451-.346-.819-.779-.833H.804ZM.625 0C.28 0 0 .373 0 .833c0 .452.27.82.606.833h13.769c.345 0 .625-.372.625-.833 0-.451-.27-.819-.606-.833H.625ZM3.25 10c-.414 0-.75.373-.75.833 0 .452.323.82.727.833h8.523c.414 0 .75-.372.75-.833 0-.451-.323-.819-.727-.833H3.25Z"/>
      <path d="M-2-4h20v20H-2z"/>
    </g>
  </svg>
)

export default CenterIcon;