import React from "react";
import { ColorList } from "../../../models/Design/colors/IGetColorsPageDataResponse";
// SHARED COMPONENTS START
import {
  ProductCard,
  ListView,
  SmallListView,
  MediumListView,
} from "shared-components/src/App";
// SHARED COMPONENTS END

import { IComponent } from "panel/src/Components/DragnDrop/Components";

import { ComponentType } from "panel/src/utils/enums/componentType";
import { PageType } from "panel/src/utils/enums/pageType";

export const getProductListComponent = (
  fontFamily: string,
  colorList: ColorList[],
  componentId: number,
  JSON: any,
  ImageResizeType: "cover" | "contain",
  IsAddToCart?: boolean
) => {
  let component: IComponent | undefined;
  switch (componentId) {
    case ComponentType.GalleryView:
      component = {
        componentId: ComponentType.GalleryView,
        pageId: PageType.ProductList,
        componentTitle: "Gallery View",
        component: (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              backgroundColor: "#fff",
              padding: 10,
              borderRadius: 5.2,
            }}
          >
            {JSON.map((item: any, index: number) => {
              return (
                <ProductCard
                  fontFamily={fontFamily}
                  style={{
                    marginRight: index % 2 === 0 ? 10 : undefined,
                    marginBottom: index < 2 ? 10 : undefined,
                    flexGrow: 1,
                    flexShrink: 0,
                    flexBasis: "35%",
                    maxWidth: "47%",
                  }}
                  colorList={colorList}
                  imageResizeType={ImageResizeType}
                  product={item}
                  key={index.toString()}
                  isAddToCart={IsAddToCart}
                />
              );
            })}
          </div>
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.ListView:
      component = {
        componentId: ComponentType.ListView,
        pageId: PageType.ProductList,
        componentTitle: "List View",
        component: (
          <div
            style={{
              backgroundColor: "#fff",
              padding: 10,
              borderRadius: 5.2,
            }}
          >
            {JSON.map((item: any, index: number) => (
              <ListView
                fontFamily={fontFamily}
                style={{
                  marginBottom: JSON.length - 1 !== index ? 10 : undefined,
                }}
                colorList={colorList}
                imageResizeType={ImageResizeType}
                product={item}
                key={index.toString()}
              />
            ))}
          </div>
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.MediumListView:
      component = {
        componentId: ComponentType.MediumListView,
        pageId: PageType.ProductList,
        componentTitle: "Medium List View",
        component: (
          <div
            style={{
              backgroundColor: "#fff",
              padding: 10,
              borderRadius: 5.2,
            }}
          >
            {JSON.map((item: any, index: number) => (
              <React.Fragment>
                <MediumListView
                  fontFamily={fontFamily}
                  colorList={colorList}
                  imageResizeType={ImageResizeType}
                  product={item}
                  key={index.toString()}
                />
                {JSON.length - 1 !== index && (
                  <div
                    style={{
                      height: 1,
                      backgroundColor: "rgb(243, 245, 246)",
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    case ComponentType.SmallListView:
      component = {
        componentId: ComponentType.SmallListView,
        pageId: PageType.ProductList,
        componentTitle: "Small List View",
        component: (
          <div
            style={{
              backgroundColor: "#fff",
              padding: 10,
              borderRadius: 5.2,
            }}
          >
            {JSON.map((item: any, index: number) => (
              <React.Fragment>
                <SmallListView
                  fontFamily={fontFamily}
                  colorList={colorList}
                  imageResizeType={ImageResizeType}
                  product={item}
                  key={index.toString()}
                />
                {JSON.length - 1 !== index && (
                  <div
                    style={{
                      height: 1,
                      backgroundColor: "rgb(243, 245, 246)",
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        ),
        editForm: null,
        preventEvents: true,
      };
      break;
    default:
      break;
  }
  return component;
};
