import React, { ChangeEventHandler, KeyboardEventHandler, MouseEventHandler } from "react";
import classes from "./search.module.scss";
import Icon from "../Icon";
interface IInputProps {
    value: string;
    changeHandler: ChangeEventHandler;
    placeholder: string;
    keyDownHandler: KeyboardEventHandler;
    searchClickHandler: MouseEventHandler;
}

const Search = (props: IInputProps) => {

    return (
        <div className={classes.Container}>
            <label className={classes.BlockLabel}></label>
            <input
                className={`${classes.BlockInput}`}
                type="text"
                placeholder={props.placeholder}
                onChange={props.changeHandler}
                value={props.value}
                onKeyDown={props.keyDownHandler}
                spellCheck="false"
                autoComplete="off"
            />
            <button className={classes.SearchIcon} onClick={props.searchClickHandler}>
                <Icon type="search" width="16" height="16" fillColor="#0f3649" />
            </button>

        </div>
    );
};

export default Search;