import React from "react";
import ReactDOM from "react-dom";
import Overlay, { OverlayProps } from "react-overlays/Overlay";
import Container from "./Container";
import Background from "./Background";
import Card from "./Card";
import Content from "./Content";
import Arrow from "./Arrow";

export interface IEditCard {
  showEditCard: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
  targetRef?: HTMLDivElement | null;
  editCardPlacement?: OverlayProps["placement"];
  onHideEditCard: Function;
  children: React.ReactNode;
  editCardWidth?: string;
}

const EditCard = ({
  showEditCard,
  containerRef,
  targetRef,
  editCardPlacement,
  onHideEditCard,
  children,
  editCardWidth = "280px",
}: IEditCard) => {
  return (
    <Container>
      <Overlay
        show={showEditCard}
        container={containerRef}
        target={targetRef!}
        placement={editCardPlacement || "right"}
      >
        {({ props, arrowProps, placement }) => (
          <Card width={editCardWidth} placement={placement} {...props}>
            <Arrow {...arrowProps} placement={placement} />
            <Content>{children}</Content>
          </Card>
        )}
      </Overlay>
      {showEditCard &&
        ReactDOM.createPortal(
          <Background onClick={() => onHideEditCard()} />,
          document.getElementById("root") as Element
        )}
    </Container>
  );
};

export default EditCard;
