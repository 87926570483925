import {
  PAYMENT_OPTIONS_START,
  PAYMENT_OPTIONS_SUCCESS,
  PAYMENT_OPTIONS_FAIL,
} from "panel/src/redux/constants/paymentOptions";

interface paymentOptionsAction {
  type: string;
  token?: any;
  error?: any;
  loading?: any;
}

export const paymentOptionsStart = ( paymentObj: any ) => {
  return {
    type: PAYMENT_OPTIONS_START,
    paymentObj
  }
};

export const paymentOptionsSuccess = ( paymentOptionList: any ) => {
  return {
    type: PAYMENT_OPTIONS_SUCCESS,
    paymentOptionList
  }
};

export const paymentOptionsRejected = ( error: any ) => {
  return {
    type: PAYMENT_OPTIONS_FAIL,
    error
  }
};
