
import * as React from "react";
import classes from "./pagination.module.scss";
import Icon from "../Icon";

interface Props {
    pages: number;
    currentPage: number;
    nextPage: Function;
}

const Pagination: React.SFC<Props> = props => {

    return (
        <div className={classes.Container}>
            <ul className={classes.Pagination}>
                {props.currentPage > 1 ? (
                    <li
                        className={classes.PrevPage}
                        onClick={() => props.nextPage(props.currentPage - 1)}
                    >
                        <a href="#">
                            <Icon type="arrowLeft" width="24" height="24" fillColor="#3E5E6E" />
                        </a>
                    </li>
                ) : (
                        <li
                            className={`${classes.PrevPage} ${classes.DisablePage}`}
                            onClick={() => { }}
                        >
                            <a className={classes.DisablePage}>
                                <Icon type="arrowLeft" width="24" height="24" fillColor="#3E5E6E" />
                            </a>
                        </li>
                    )}

                <li
                    className={classes.CurrentPage}
                    onClick={() => props.nextPage(props.currentPage)}
                >
                    <a href="#">{props.currentPage} / {props.pages}</a>
                </li>

                {props.currentPage < props.pages ? (
                    <li
                        className={classes.NextPage}
                        onClick={() => props.nextPage(props.currentPage + 1)}
                    >
                        <a href="#">
                            <Icon type="arrowRight" width="24" height="24" fillColor="#3E5E6E" />
                        </a>
                    </li>
                ) : (
                        <li
                            className={`${classes.NextPage} ${classes.DisablePage}`}
                            onClick={() => { }}
                        >
                            <a className={classes.DisablePage}>
                                <Icon type="arrowRight" width="24" height="24" fillColor="#3E5E6E" />
                            </a>
                        </li>
                    )}
            </ul>

        </div>
    );
};

export default Pagination;