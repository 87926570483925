import {
  SET_PLATFORM_INFO,
  SET_APP_START,
  SET_APP_SUCCESS,
  SET_APP_FAIL,
  SET_CONFIG_SETTINGS_COMPLETED,
  SET_PAYMENT_OPTIONS_COMPLETED,
  SET_ENGAGEMENT_OPTIONS_COMPLETED,
  SET_APP_SHOPIFY,
  SET_APP_INPUTS,
  SET_APP_GOOGLE_MERCHANT,
  SET_APP_GOOGLE_MERCHANT_SUCCESS,
  SET_APP_GOOGLE_MERCHANT_FAIL,
  CHECK_APP_INFO,
  SET_CHANGE_STH_DESIGN_PAGE,
} from "panel/src/redux/constants/manageApp";
import { updateObject } from "../../utils/helper";

export type ManageAppStateTypes = Readonly<{
  loading?: boolean;
  error?: boolean;
  appUrl?: string;
  platformId?: string;
  appId?: string;
  isSetAppStepCompleted?: boolean;
  isSetConfigSettingsCompleted?: boolean;
  isSetPaymentOptionsCompleted?: boolean;
  isSetEngagementOptionsCompleted?: boolean;
  googleMerchantStatus?: Object;
  isDesignPageChange: boolean;
  isModalSubmitted: boolean;
}>;

const initialState: ManageAppStateTypes = {
  appUrl: "",
  platformId: "",
  appId: "",
  isSetAppStepCompleted: false,
  isSetConfigSettingsCompleted: false,
  isSetPaymentOptionsCompleted: false,
  isSetEngagementOptionsCompleted: false,
  loading: false,
  error: false,
  googleMerchantStatus: {},
  isDesignPageChange: false,
  isModalSubmitted: false,
};

const setAppId = (state = initialState, action: any) => {
  return updateObject(state, {
    appId: action.appId,
  });
};

const manageAppReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_PLATFORM_INFO:
      return {
        ...state,
      };

    case SET_APP_START:
      return {
        ...state,
        setPlatformObject: action.setPlatformObject,
        platformId: action.setPlatformObject.parameters.platformId,
        appUrl: action.setPlatformObject.parameters.url,
        appId: action.setPlatformObject.ApplicationId,
      };
    case CHECK_APP_INFO:
      return {
        ...state,
        setPlatformObject: action.setPlatformObject,
        platformId: action.setPlatformObject.parameters.platformId,
        appUrl: action.setPlatformObject.parameters.url,
        appId: action.setPlatformObject.ApplicationId,
      };
    case SET_APP_INPUTS:
      return {
        ...state,
        setPlatformObject: action.setPlatformObject,
        platformId: action.platformId,
        appUrl: action.appUrl,
        appId: action.appId,
      };
    case SET_APP_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        appId: action.appId,
      };
    case SET_APP_SHOPIFY:
      return {
        ...state,
        shopifyObj: action.shopifyObj,
      };
    case SET_APP_GOOGLE_MERCHANT:
      return {
        ...state,
        googleMerchantObj: action.googleMerchantObj,
        loading: true,
      };
    case SET_APP_GOOGLE_MERCHANT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SET_APP_GOOGLE_MERCHANT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SET_APP_FAIL:
      return {
        ...state,
        loading: false,
        isSetAppStepCompleted: false,
        error: action.error,
      };
    case SET_CONFIG_SETTINGS_COMPLETED:
      return {
        ...state,
        isSetConfigSettingsCompleted: true,
      };
    case SET_PAYMENT_OPTIONS_COMPLETED:
      return {
        ...state,
        isSetPaymentOptionsCompleted: true,
      };
    case SET_ENGAGEMENT_OPTIONS_COMPLETED:
      return {
        ...state,
        isSetEngagementOptionsCompleted: true,
      };
    case SET_CHANGE_STH_DESIGN_PAGE:
      return {
        ...state,
        isDesignPageChange: action.changedValue,
      };

    default:
      return state;
  }
};

export default manageAppReducer;
