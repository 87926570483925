import { connect, FormikContextType, FormikErrors } from "formik";
import { Component } from "react";

interface IProps {
  formik: FormikContextType<any>;
}

class ErrorFocusInternal extends Component<IProps> {
  public componentDidUpdate(prevProps: IProps) {
    const { isSubmitting, isValidating, errors } = prevProps.formik;
    const keys = Object.keys(errors);
    const errorId = Object.getOwnPropertyNames(
      errors.Items ? errors.Items : errors
    );

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const divToScrollTo = document.getElementById(
        `Items.[${Number(errorId[0])}].Image.ImgUrl`
      ) as HTMLElement;

      if (divToScrollTo) {
        divToScrollTo.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  public render = () => null;
}

export const ErrorFocus = connect<{}>(ErrorFocusInternal);
