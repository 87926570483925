import { Platform } from "react-native";
import styled from "styled-components/native";
import { dimenHeight, dimenWidth } from "../../utils/helper";

interface IAddToCartButton {
  bgColor: string;
}

const AddToCartButton = styled.TouchableOpacity<IAddToCartButton>`
  width: 100%;
  max-width: ${Platform.OS === "web" ? "83px" : dimenHeight(100) + "px"};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 3px;
  text-align: center;
  padding: ${Platform.OS === "web" ? "7px" : dimenHeight(5) + "px"} 5px;
`;

interface IAddToCartButtonText {
  fontFamily?: any;
}

const AddToCartButtonText = styled.Text<IAddToCartButtonText>`
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: ${Platform.OS === "web" ? "7" : dimenWidth(10)}px;
  font-family: ${(props) =>
    Platform.OS === "web" ? props.fontFamily : props.fontFamily!.Bold};
  ${Platform.OS === "web" && "font-weight: bold;"}
  letter-spacing: -0.1px;
`;

export { AddToCartButton, AddToCartButtonText };
