import React, { useEffect, useRef, useState } from "react";
import { Form, Field, Formik, FieldArray, FormikProps } from "formik";
import validationSchema from "./validationSchema";
import ImageUpload from "panel/src/Components/ImageUpload";
import Input from "panel/src/Components/Input";
import Dropdown from "panel/src/Components/Dropdown";
import Button from "panel/src/Components/Button";
import ImageResizeType, {
  TImageResizeType,
  TResizeTypeValue,
} from "panel/src/Components/ImageResizeType";
import Text from "./Text";
import Separator from "panel/src/Components/Separator";
import DynamicToolsBar from "panel/src/Components/DynamicToolsBar";
import { connect } from "react-redux";
import { hasChange } from "panel/src/redux/actions/bannerSettings";
import { ErrorFocus } from "./utils/ErrorFocus";
import { flattenObject, usePrevious } from "panel/src/utils/helper";
import Dialog from "panel/src/Components/Dialog";
import ErrorField from "../../../../../_components/common/ErrorField";
import { ELinkTypes } from "../../../../../utils/enums/design";
import { ComponentProductModule } from "../../../../../models/Design/home/ComponentProduct";
import {
  getProductListByCategoryId,
  resetProductListByCategoryIdProducts,
} from "../../../../../redux/actions/productList";
import InfoPopup from "../../../../../Components/InfoPopup";

interface ISlider {
  hideComponentEdit?: Function;
  editFormClickedOutside?: number;
  setComponentFormData?: Function;
  setImageResizeType?: Function;
  Id?: string;
  formData?: ISliderFormData;
  rawFormData?: any;
  linkTypes: { label: string; value: number }[];
  setInfoPopup?: Function;
  hasChange: boolean;
  hasChangeReq: Function;
  _getProductListByCategoryId: (
    data: ComponentProductModule.TGetProductListByCategoryIdData
  ) => void;
  _resetProductListByCategoryIdProducts: Function;
  productListLoading: boolean;
  productList: ComponentProductModule.IProduct[];
  categories: { label: string; value: string }[];
}

type ISliderFormData = {
  Items: {
    Image: {
      ImgUrl: string;
    };
    Link: {
      Type: number;
      LinkTo: string;
      CategoryId: string;
      CategoryName: string;
      ProductId: string;
      ProductName: string;
      Search: string;
    };
    isEdited: boolean;
  }[];
  imageResizeType: TResizeTypeValue;
};

let timeout: ReturnType<typeof setTimeout> = setTimeout(() => "", 1000);

const Slider = ({
  hideComponentEdit,
  editFormClickedOutside,
  setComponentFormData,
  setImageResizeType,
  Id,
  formData,
  rawFormData,
  linkTypes,
  setInfoPopup,
  hasChangeReq,
  categories,
  _getProductListByCategoryId,
  _resetProductListByCategoryIdProducts,
  productListLoading,
  productList,
}: ISlider) => {
  const formikRef = useRef<FormikProps<any>>(null);
  const [isSaveRequired, setIsSaveRequired] = useState(false);
  const removeConfirmationModalInitialValues = {
    isVisible: false,
    index: -1,
  };
  const [removeConfirmationModal, setRemoveConfirmationModal] = useState(
    removeConfirmationModalInitialValues
  );

  const prevProps = usePrevious({ formData });
  const initialFormObject = {
    Priority: 1,
    Image: {
      ImgUrl: {
        height: null,
        img: "",
        supportedHeight: null,
        type: null,
        width: null,
      },
    },
    Link: {
      Type: linkTypes[0].value,
      LinkTo: "",
      CategoryId: "",
      CategoryName: "",
      ProductId: "",
      ProductName: "",
      Search: "",
    },
    Size: 2,
  };

  const checkIsRequired = () => {
    return !formData?.Items.some((s) => s.isEdited);
  };

  useEffect(() => {
    if (prevProps && formData) {
      if (prevProps.formData !== formData) {
        hasChangeReq();
      }
    }
  });

  useEffect(() => {
    if (editFormClickedOutside !== 0) {
      formikRef.current?.validateForm().then(() => {
        const isFormValid = formikRef.current?.isValid;

        if (isFormValid && checkIsRequired()) {
          setIsSaveRequired(true);
          const errorElement = document.querySelector(
            "[id='save-changes-error']"
          );
          if (errorElement) {
            errorElement.scrollIntoView({ behavior: "smooth" });
          }
        } else if (isFormValid || !checkIsRequired()) {
          hideComponentEdit && hideComponentEdit(Id, checkIsRequired());
        } else {
          const errorKeys = flattenObject(formikRef.current?.errors);
          Object.keys(errorKeys).forEach((key: any) => {
            formikRef.current?.setFieldTouched(key);
          });
        }
      });
    }
  }, [editFormClickedOutside]);

  const getInitialValue = () => {
    const _formData = formData?.Items.map((item) => {
      return {
        Image: {
          ImgUrl: {
            height: null,
            img: item.Image.ImgUrl,
            supportedHeight: null,
            type: null,
            width: null,
          },
        },
        Link: {
          Type: item.Link.Type,
          LinkTo: item.Link.LinkTo,
          CategoryId: item.Link?.CategoryId,
          CategoryName: item.Link?.CategoryName,
          ProductId: item.Link?.ProductId,
          ProductName: item.Link?.ProductName,
          Search: item.Link?.Search,
        },
        isEdited: false,
      };
    });

    return { Items: _formData, imageResizeType: formData?.imageResizeType };
  };

  const getProductList = (index: number, search: string) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      _getProductListByCategoryId({
        CategoryId: formikRef.current?.values.Items[index].Link.CategoryId,
        SearchWord: search,
      });
    }, 500);
  };

  const productListHandled = productList.map((product) => ({
    label: product.ProductName,
    value: product.Id,
  }));

  const categoriesHandled = categories?.sort((a, b) =>
    a.label > b.label ? 1 : b.label > a.label ? -1 : 0
  );

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={getInitialValue()}
      onSubmit={(values) => {
        const formData = values.Items?.map((obj: any) => {
          return {
            Image: { ImgUrl: obj.Image.ImgUrl.img },
            Link: { ...obj.Link },
            isEdited: true,
          };
        });

        setComponentFormData &&
          setComponentFormData(Id, {
            Items: formData,
            imageResizeType: values.imageResizeType,
          });
        setInfoPopup && setInfoPopup(true);
      }}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, setFieldValue, errors, touched }) => {
        return (
          <Form>
            <Text>
              The image should be minimum 960x660 px and 1 mb maximum size.
            </Text>
            <FieldArray name="Items">
              {({ push, remove }) => (
                <React.Fragment>
                  {values.Items?.map((formValue: any, index: number) => (
                    <React.Fragment
                      key={`${formValue.Image.ImgUrl.img}-${index}`}
                    >
                      <Field
                        id={`Items[${index}].Image.ImgUrl`}
                        name={`Items[${index}].Image.ImgUrl`}
                        style={{ marginBottom: "1rem" }}
                        height={660}
                        editFormHeight={165}
                        defaultValue={formValue.Image.ImgUrl}
                        onChangeHandler={handleChange}
                        handleError={errors}
                        handleTouched={touched}
                        component={ImageUpload}
                      />

                      <InfoPopup
                        field={
                          <Field
                            id={`Items[${index}].Link.Type`}
                            name={`Items[${index}].Link.Type`}
                            style={{ marginBottom: "1rem" }}
                            label="Link Type"
                            placeholder="Select Link Type"
                            options={linkTypes}
                            value={formValue.Link.Type}
                            onChangeHandler={handleChange}
                            handleError={errors}
                            handleTouched={touched}
                            component={Dropdown}
                          />
                        }
                        descriptions={[
                          {
                            key: "Category",
                            value: `Select a specific category to redirect the related banner in your app.`,
                          },
                          {
                            key: "Product",
                            value: `Select a category & product name to redirect the related banner in your app`,
                          },
                          {
                            key: "Web View",
                            value: `Type a URL to redirect the related banner in a WebView.`,
                          },
                          {
                            key: "Search",
                            value: `Type a text to redirect the related banner to search result page in your app.`,
                          },
                        ]}
                      />

                      {(formValue.Link.Type === ELinkTypes.Category ||
                        formValue.Link.Type === ELinkTypes.Product) && (
                        <Field
                          id={`Items[${index}].Link.CategoryId`}
                          name={`Items[${index}].Link.CategoryId`}
                          label="Link to"
                          placeholder="Select a category"
                          options={categoriesHandled}
                          value={formValue.Link.CategoryId}
                          onChangeHandler={(e: any) => {
                            handleChange(e);
                            _resetProductListByCategoryIdProducts();
                            setFieldValue(
                              `Items[${index}].Link.CategoryName`,
                              e.target.label
                            );
                            setFieldValue(`Items[${index}].Link.ProductId`, "");
                            setFieldValue(
                              `Items[${index}].Link.ProductName`,
                              ""
                            );
                          }}
                          handleError={errors}
                          handleTouched={touched}
                          component={Dropdown}
                        />
                      )}

                      {formValue.Link.Type === ELinkTypes.Product && (
                        <Field
                          id={`Items[${index}].Link.ProductName`}
                          name={`Items[${index}].Link.ProductName`}
                          placeholder="Type product name"
                          value={formValue.Link.ProductName}
                          onChangeHandler={(e: any) => {
                            setFieldValue(
                              `Items[${index}].Link.ProductName`,
                              e.target.label
                            );
                            setFieldValue(
                              `Items[${index}].Link.ProductId`,
                              e.target.value
                            );
                          }}
                          handleError={errors}
                          handleTouched={touched}
                          component={Dropdown}
                          options={productListHandled}
                          disabled={!formValue.Link.CategoryId}
                          loading={productListLoading}
                          searchable={true}
                          onSearchValueChange={(e: any) => {
                            getProductList(index, e.target.value);
                            setFieldValue(
                              `Items[${index}].Link.ProductName`,
                              ""
                            );
                            setFieldValue(`Items[${index}].Link.ProductId`, "");
                          }}
                        />
                      )}

                      {formValue.Link.Type === ELinkTypes.Search && (
                        <Field
                          id={`Items[${index}].Link.Search`}
                          name={`Items[${index}].Link.Search`}
                          label="Link to"
                          placeholder="Type a word"
                          value={formValue.Link.Search}
                          onChangeHandler={handleChange}
                          handleError={errors}
                          handleTouched={touched}
                          component={Input}
                        />
                      )}

                      {(formValue.Link.Type === ELinkTypes.Href ||
                        formValue.Link.Type === ELinkTypes.WebViewURL) && (
                        <Field
                          id={`Items[${index}].Link.LinkTo`}
                          name={`Items[${index}].Link.LinkTo`}
                          label="Link to"
                          placeholder="Type or paste a URL"
                          value={formValue.Link.LinkTo}
                          onChangeHandler={handleChange}
                          handleError={errors}
                          handleTouched={touched}
                          component={Input}
                        />
                      )}
                      <DynamicToolsBar
                        push={() => push(initialFormObject)}
                        remove={() =>
                          setRemoveConfirmationModal({
                            isVisible: true,
                            index: index,
                          })
                        }
                        isAddable={values.Items!.length - 1 === index}
                        isRemovable={values.Items!.length > 1}
                      />
                      {values.Items!.length > 1 &&
                        values.Items!.length - 1 !== index && <Separator />}
                    </React.Fragment>
                  ))}
                  <Field
                    id="imageResizeType"
                    name="imageResizeType"
                    style={{ marginTop: 16 }}
                    resizeTypeValue={values.imageResizeType}
                    typeOnClickHandler={(item: TImageResizeType) => {
                      setFieldValue(`imageResizeType`, item.mode);
                      const editedFormData = {
                        ...rawFormData,
                        imageResizeType: item.mode,
                      };
                      setImageResizeType &&
                        setImageResizeType(Id, editedFormData);
                    }}
                    component={ImageResizeType}
                  />
                  {isSaveRequired && (
                    <ErrorField
                      id="save-changes-error"
                      text="Please save the changes"
                    />
                  )}
                  <Button
                    id="apply-button"
                    type="submit"
                    style={{ marginTop: "1.5rem" }}
                    buttonText="Apply"
                  />
                  <Button
                    id="cancel-button"
                    type="button"
                    style={{ marginTop: "0.5rem" }}
                    theme="white"
                    buttonText={checkIsRequired() ? "Delete" : "Cancel"}
                    onClick={() =>
                      hideComponentEdit &&
                      hideComponentEdit(Id, checkIsRequired())
                    }
                  />
                  <Dialog
                    showModal={removeConfirmationModal.isVisible}
                    backdropClick={() => {}}
                    contentText="You are about to remove this slider."
                    cancelHandler={() =>
                      setRemoveConfirmationModal(
                        removeConfirmationModalInitialValues
                      )
                    }
                    confirmHandler={() => {
                      remove(removeConfirmationModal.index);
                      setRemoveConfirmationModal(
                        removeConfirmationModalInitialValues
                      );
                    }}
                  />
                </React.Fragment>
              )}
            </FieldArray>
            <ErrorFocus />
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  return {
    hasChange: state.bannerSettings.changes,
    productList: state.productList.productList,
    productListLoading: state.productList.productListLoading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _getProductListByCategoryId: (
      data: ComponentProductModule.TGetProductListByCategoryIdData
    ) => dispatch(getProductListByCategoryId(data)),
    _resetProductListByCategoryIdProducts: () =>
      dispatch(resetProductListByCategoryIdProducts()),
    hasChangeReq: () => dispatch(hasChange()),
    disCardChanges: () => dispatch(hasChange()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Slider);
