import React from "react";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
  type: string;
}

const IconBack = ({ width, height, fillColor }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 21"
  >
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M.27 10.852c-.187-.185-.277-.432-.27-.676v-.029C0 9.8.193 9.5.478 9.345l6.48-6.574c.353-.358.928-.362 1.285-.008.358.354.361.93.008 1.289l-5.11 5.184h15.95c.502 0 .909.408.909.911 0 .504-.407.912-.91.912H3.109l5.125 5.122c.356.356.357.933.002 1.29-.354.356-.93.356-1.285.001L.485 11.01c-.077-.04-.15-.093-.215-.158z"
      transform="translate(-16 -28) translate(16 28)"
    />
  </svg>
);

export default IconBack;
