import { Platform } from "react-native";
import styled from "styled-components/native";
import { dimenWidth } from "shared-components/src/utils/helper";

export default styled.View`
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin: ${Platform.OS === "web" ? "10" : dimenWidth(14)}px;
  ${Platform.OS === "web" && `flex-basis: unset !important;`};
`;
