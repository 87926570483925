import {
  GET_PRODUCT_DETAIL_DATA,
  GET_PRODUCT_DETAIL_DATA_SUCCESS,
  GET_PRODUCT_DETAIL_DATA_FAIL,
  RESET_PRODUCT_DETAIL_DATA,
} from "panel/src/redux/constants/productDetail";

export interface IProductDetailData {
  componentsData: {
    ComponentId: number;
    DisplayOrder: number;
    JSON: string | null;
    PageId: number;
  }[];
  initialized: boolean;
}

const initialState = {
  data: false,
  loading: false,
  reset: false,
};

const productDetailReducer = (state: Object = initialState, action: any) => {
  switch (action.type) {
    case GET_PRODUCT_DETAIL_DATA:
      return {
        ...state,
        loading: true,
        reset: false,
      };
    case GET_PRODUCT_DETAIL_DATA_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        reset: false,
      };
    case GET_PRODUCT_DETAIL_DATA_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        reset: false,
      };
    case RESET_PRODUCT_DETAIL_DATA:
      return {
        ...initialState,
        loading: false,
        reset: false,
      };
    default:
      return state;
  }
};

export default productDetailReducer;
