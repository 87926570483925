import React from "react";
import { IconType } from "../../../utils/enums/iconType";

interface IIOSProps {
  iconType: string;
}

const IOSIcon = (props: IIOSProps) => {
  let width = "24";
  let height = "24";
  let fillColor = null;

  switch (props.iconType) {
    case IconType.Disabled:
      fillColor = "#cfd7db";
      break;
    case IconType.Active:
      fillColor = "#3E5E6E";
      break;
    case IconType.Enabled:
      fillColor = "#FFFFFF";
      break;
    default:
      fillColor = "#3E5E6E";
      break;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M16.496 5.796c.698 0 3.225.06 4.868 2.39-.14.107-2.65 1.484-2.65 4.555 0 3.572 3.193 4.828 3.286 4.858-.016.09-.512 1.725-1.69 3.405-1.039 1.468-2.14 2.966-3.814 2.966S14.388 23 12.481 23c-1.892 0-2.574.999-4.093.999-1.52 0-2.59-1.377-3.814-3.057C3.163 18.961 2 15.904 2 12.983c0-4.645 3.116-7.127 6.17-7.127 1.613 0 2.977 1.044 4 1.044.962 0 2.466-1.104 4.326-1.104zm-.868-1.968c-.822.984-2.217 1.71-3.333 1.71-.124 0-.248-.015-.326-.03a2.216 2.216 0 0 1-.047-.439c0-1.24.652-2.466 1.35-3.238C14.154.802 15.642.045 16.867 0c.031.136.047.318.047.484 0 1.226-.527 2.452-1.287 3.344z"
      />
    </svg>
  );
};

export default IOSIcon;
