import React from "react";
import Svg, { Path } from "react-native-svg";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
}

const Heart = ({ width, height, fillColor }: IProps) => (
  <Svg width={width} height={height} viewBox="0 0 24 24">
    <Path
      fill={fillColor}
      fillRule="evenodd"
      d="M16.94 2c-1.927.001-3.674.787-4.945 2.057C10.72 2.792 8.972 2.01 7.043 2.013 3.15 2.017-.003 5.21 0 9.144.011 18.182 12.017 23 12.017 23S24.013 18.153 24 9.113C23.995 5.179 20.835 1.996 16.94 2zm-5.943 18.409c-1.317-.694-2.635-1.52-3.855-2.47-3.333-2.592-5.268-5.55-5.272-8.797-.002-2.875 2.31-5.217 5.175-5.22 1.385-.002 2.68.544 3.646 1.503l1.308 1.298 1.303-1.302c.964-.962 2.257-1.511 3.639-1.512 2.868-.003 5.185 2.332 5.189 5.206.005 3.252-1.926 6.217-5.258 8.819-1.217.95-2.53 1.778-3.844 2.474-.36.191-.7.36-1.013.509-.314-.148-.655-.317-1.018-.508z"
    />
  </Svg>
);

export default Heart;
