import {
  PAYMENT_OPTIONS_START,
  PAYMENT_OPTIONS_SUCCESS,
  PAYMENT_OPTIONS_FAIL,
} from "panel/src/redux/constants/paymentOptions";


export type paymentOptionsStateTypes = Readonly<{
  error?: boolean;
  loading?: boolean;
}>;

const initialState: paymentOptionsStateTypes = {
  error: false,
  loading: false,
}


const paymentOptionsReducer = ( state = initialState, action: any ) => {
  switch ( action.type ) {
    case PAYMENT_OPTIONS_START:
      return {
        ...state,
        paymentObj: action.paymentObj
      };

    case PAYMENT_OPTIONS_SUCCESS:
      return {
        ...state,
        paymentOptionList: action.paymentOptionList
      };
    case PAYMENT_OPTIONS_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default paymentOptionsReducer;
