import {
  SET_INSTAGRAM_USER_DATA,
  SET_INSTAGRAM_USER_DATA_FAIL,
  SET_INSTAGRAM_USER_DATA_SUCCESS,
  RESET_INSTAGRAM_USER_DATA,
} from "panel/src/redux/constants/setInstagramUser";

const initialState = {
  data: false,
  loading: false,
  reset: false,
};

const setInstagramUserReducer = (state: Object = initialState, action: any) => {
  switch (action.type) {
    case SET_INSTAGRAM_USER_DATA:
      return {
        ...state,
        loading: true,
        reset: false,
      };
    case SET_INSTAGRAM_USER_DATA_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        reset: false,
      };
    case SET_INSTAGRAM_USER_DATA_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        reset: false,
      };
    case RESET_INSTAGRAM_USER_DATA:
      return {
        ...initialState,
        loading: false,
        reset: true,
      };
    default:
      return state;
  }
};

export default setInstagramUserReducer;
