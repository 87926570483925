import {
  SAVE_SCREEN_DATA,
  SAVE_SCREEN_DATA_SUCCESS,
  SAVE_SCREEN_DATA_FAIL,
  RESET_SAVE_SCREEN_DATA,
} from "panel/src/redux/constants";

const initialState = {
  data: false,
};

const saveScreenReducer = (state: Object = initialState, action: any) => {
  switch (action.type) {
    case SAVE_SCREEN_DATA:
      return {
        ...state,
      };
    case SAVE_SCREEN_DATA_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case SAVE_SCREEN_DATA_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case RESET_SAVE_SCREEN_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default saveScreenReducer;
