import React, { FC } from 'react';
import styled from 'styled-components';

type TabNavigationItemV2Props = {
  itemSize?: number;
  name?: string;
  iconUrl?: string;
  isActive?: boolean;
  isRowStyle?: boolean;
  fontFamily?: string;
  color?: string;
  iconColorFilter?: string;
};

const Container = styled.div<TabNavigationItemV2Props>`
  height: 38px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-family: ${({ fontFamily, isActive }) =>
    isActive ? `${fontFamily} Bold` : fontFamily};
  ${({ itemSize }) => itemSize && `max-width: ${Math.floor(240 / itemSize)}px;`}
  ${({ isRowStyle }) => isRowStyle && `
    justify-content: center;
  `}
  color: ${({ color }) => color};
`;

const ContentContaniner = styled.div<TabNavigationItemV2Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  ${({ isRowStyle }) => isRowStyle ? `
    flex-direction: row;
    width: auto;
    justify-content: center;
  ` : `
    flex-direction: column;
  `}
`;

const IconContainer = styled.div<TabNavigationItemV2Props>`
  ${({ isRowStyle }) => isRowStyle ? `
    margin-right: 5px;
  ` : `
    margin-bottom: 5px;
  `}
  height: 19px;
  overflow: hidden;
  flex-shrink: 0;
`;

const IconImage = styled.img<TabNavigationItemV2Props>`
  width: 19px;
  height: 19px;
  object-fit: cover;
  filter: ${({ iconColorFilter }) => iconColorFilter};
`;

const Text = styled.div`
  font-size: 9px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  width: 100%;
`;

const TabNavigationItemV2: FC<TabNavigationItemV2Props> = ({
  name,
  iconUrl,
  isActive,
  itemSize,
  fontFamily = 'Helvetica Neue',
  color,
  iconColorFilter,
}) => {
  const isRowStyle = itemSize === 2;
  return (
    <Container
      isActive={isActive}
      color={color}
      fontFamily={fontFamily}
      isRowStyle={isRowStyle}
      itemSize={itemSize || 2}
    >
      <ContentContaniner isRowStyle={isRowStyle}>
        <IconContainer isRowStyle={isRowStyle}>
          <IconImage src={iconUrl} iconColorFilter={iconColorFilter} />
        </IconContainer>
        <Text>{name}</Text>
      </ContentContaniner>
    </Container>
  );
};

export default TabNavigationItemV2;
