import React, { FC, useCallback, useState } from 'react';
import update from 'immutability-helper'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import MenuItem from './MenuItem';
import styled from 'styled-components';
import { TMenuItem } from './MenuEditor';
import { toast } from 'react-toastify';

type MenuEditorSortingProps = {
  menuItems: TMenuItem[];
  setMenuItems: React.Dispatch<React.SetStateAction<TMenuItem[]>>,
  editIcon: (index: number) => void;
  isTabBar?: boolean;
  isCentered?: boolean;
  withoutIcon?: boolean;
};

type ContainerProps = {
  isTabBar?: boolean;
}

const Container = styled.div<ContainerProps>`
  ${props => !props?.isTabBar ? `
    max-height: calc(90vh - 320px);
    overflow-y: auto;
    padding-right: 10px;
  `: `
    display: flex;
    flex-wrap: wrap;
  `}
`;

const Header = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
`;

const TabbarHeading = styled.div`
  font-size: 12px;
  color: #3e5e6e;
  margin-bottom: 20px;
`;

const Heading = styled.div``;
const TooltipButton = styled.div``;

const TABBAR_MIN_ITEM_COUNT = 2;
const TABBAR_MAX_ITEM_COUNT = 5;

const MenuEditorSorting: FC<MenuEditorSortingProps> = ({
  menuItems,
  setMenuItems,
  editIcon,
  isTabBar,
  isCentered,
  withoutIcon,
}) => {

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setMenuItems((prev) => 
      update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev[dragIndex] as any],
        ],
      })
    );
  }, []);

  const toggleEnablad = (index: number, isEnabledItem: boolean) => {
    setMenuItems((prev) => {
      // Check tabbar item limit
      if (isTabBar) {
        let errorMessage;
        const enabledItemCount = prev.filter((item) => !!item.IsEnabled).length;

        if (!isEnabledItem && enabledItemCount >= TABBAR_MAX_ITEM_COUNT) {
          errorMessage = 'You can not enable more than five menu items.';
        } else if (isEnabledItem && enabledItemCount === TABBAR_MIN_ITEM_COUNT) {
          errorMessage = 'You can not use less than two items.';
        }

        if (errorMessage) {
          toast.info(errorMessage, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            pauseOnHover: true,
            progress: undefined,
          });
          return prev;
        }
      }

      return prev.map((item, idx) => {
        return  (idx === index) ? { ...item, IsEnabled: !item.IsEnabled } : item;
      })
    });
  };

  // disableDrop for home item
  const disableDropIndexes = [0];

  const renderMenuItem = (item: TMenuItem, index: number) => (
    <MenuItem
      key={index}
      Name={item?.Name}
      IconUrl={item?.IconUrl}
      IsEnabled={item?.IsEnabled}
      IsActive={item?.IsActive}
      IsSortable={item?.IsSortable}
      disableDropIndexes={disableDropIndexes}
      index={index}
      toggleEnabled={toggleEnablad}
      moveItem={moveItem}
      editIcon={editIcon}
      isTabBar={isTabBar}
      isCentered={isCentered}
      withoutIcon={withoutIcon}
    />
  );

  return (
    <>
      <Header>
        <Heading>Menu items</Heading>
        {/* <TooltipButton>tooltip</TooltipButton> */}
      </Header>
      {isTabBar && (
        <TabbarHeading>
          You cannot use more than <strong>five</strong> and less than <strong>two</strong> destinations.
          You can select the left menu to make more selections.
        </TabbarHeading>
      )}
      <Container isTabBar={isTabBar}>
        <DndProvider backend={HTML5Backend}>
          {menuItems?.map((item, i) => renderMenuItem(item, i))}
        </DndProvider>
      </Container>
    </>
  );
};

export default MenuEditorSorting;
