import React, { useState, useEffect } from "react";
import Container from "./Container";
import Icon from "./Icon";
import BrandLogo from "./BrandLogo";
import Wrapper from "./Wrapper";

import basket from "panel/src/assets/images/emulated-app/basket.svg";
import search from "panel/src/assets/images/emulated-app/search.svg";
import menu from "panel/src/assets/images/emulated-app/menu.svg";
import { ColorList } from "panel/src/models/Design/colors/IGetColorsPageDataResponse";
import { useSelector } from "react-redux";
import EditButton from "./EditButton";

export type THeaderTabBarIconAlign = "default" | "center";
interface IHeader {
  colorList?: ColorList[];
  isDrawer: boolean;
  navigationBarIcon?: string | undefined;
  tabBarIconAlign?: THeaderTabBarIconAlign;
  onClickMenu?: () => void;
  canEditMenu?: boolean;
}

const Header = ({
  isDrawer,
  navigationBarIcon,
  colorList,
  tabBarIconAlign = "default",
  onClickMenu,
  canEditMenu,
}: IHeader) => {
  const [primary, setPrimary] = useState("#0f3649");
  const [secondary, setSecondary] = useState("#3196f6");
  const [tertiary, setTertiary] = useState("#0f3649");
  const { position } = useSelector((state: any) => state.logoPosition);

  useEffect(() => {
    {
      colorList &&
        colorList.map((item, index) => {
          switch (index) {
            case 0:
              setPrimary(item.colorCode);
              break;
            case 1:
              setSecondary(item.colorCode);
              break;
            case 2:
              setTertiary(item.colorCode);
              break;
          }
        });
    }
  });
  const centerIconTabBar = !isDrawer && tabBarIconAlign === "center";

  const editMenu = () => {
    if (canEditMenu && onClickMenu) {
      onClickMenu();
    }
  }

  return (
    <Container flexEnd={centerIconTabBar}>
      {isDrawer && (
        <Wrapper onClick={editMenu} relative>
          <Icon src={menu} alt="menu" />
        </Wrapper>
      )}
      {isDrawer && canEditMenu && (
        <EditButton onClick={editMenu} />
      )}
      {navigationBarIcon && position !== 'none' && (
        <BrandLogo
          isDrawer={isDrawer}
          centered={position === 'center'}
          src={navigationBarIcon}
          alt="brandLogo"
        />
      )}

      <Wrapper pullRight={!isDrawer}>
        <Icon marginRight="12" src={search} alt="search" />
        <Icon src={basket} alt="basket" />
      </Wrapper>
    </Container>
  );
};

export default Header;
