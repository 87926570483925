import {
  GET_PACKAGE_FEATURE_LIST_START,
  GET_PACKAGE_FEATURE_LIST_SUCCESS,
  GET_PACKAGE_FEATURE_LIST_FAIL,
  SET_PACKAGE_FEATURE_LIST_START,
  SET_PACKAGE_FEATURE_LIST_SUCCESS,
  SET_PACKAGE_FEATURE_LIST_FAIL
} from "panel/src/redux/constants/getPackageFeature";


export const getPackageFeatureListStart = ( data: any ) => {
  return {
    type: GET_PACKAGE_FEATURE_LIST_START,
    data
  }
};

export const getPackageFeatureListSuccess = ( featureData: Object ) => {
  return {
    type: GET_PACKAGE_FEATURE_LIST_SUCCESS,
    featureData
  }
};

export const getPackageFeatureListRejected = ( error: any ) => {
  return {
    type: GET_PACKAGE_FEATURE_LIST_FAIL
  }
};

export const setPackageFeatureListStart = ( data: any ) => {
  return {
    type: SET_PACKAGE_FEATURE_LIST_START,
    data
  }
};

export const setPackageFeatureListSuccess = () => {
  return {
    type: SET_PACKAGE_FEATURE_LIST_SUCCESS
  }
};

export const setPackageFeatureListRejected = ( error: any ) => {
  return {
    type: SET_PACKAGE_FEATURE_LIST_FAIL,
    error
  }
};
