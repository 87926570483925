import styled from "styled-components";

interface IContainer {
  align: "space-between" | "flex-end";
}

export default styled.div<IContainer>`
  display: flex;
  justify-content: ${(props) => props.align};
  margin-top: 15px;
`;
