import React, { Component } from "react";
import classes from "./askDemoLink.module.scss";
import { connect } from "react-redux";
import Icon from "panel/src/_components/common/Icon";
import { History } from "history";
import Checkbox from "panel/src/_components/common/Checkbox";
import {
  Formik,
  Form,
  Field,
  FieldArray,
  FormikValues,
  FormikProps,
} from "formik";
import BlockingForm from "panel/src/_components/common/BlockingForm";
import Button from "panel/src/_components/common/Button";
import validationSchema from "./validationSchema";
import { v1 as uuid } from "uuid";
import TextFiled from "panel/src/_components/common/TextField";
import ErrorField from "panel/src/_components/common/ErrorField";
import EmailListModel from "panel/src/models/emailListModel";
import IAskDemoLinkRequest from "panel/src/models/Publish/askDemo";
import IDemoInfoRequest from "panel/src/models/Publish/demoInfoRequest";
import { demoInfo } from "panel/src/redux/actions/demoInfo";
import { askDemoLink } from "panel/src/redux/actions/askDemo";

interface IProps {
  history: History;
  appId: string;
  isMailSend: boolean;
  emailAddress: string;
  _askDemoLink: Function;
  _demoInfo: Function;
  loading: boolean;
}
interface IState {
  isBlocking: boolean;
  isIosChecked: boolean;
  isAndroidChecked: boolean;
  checkboxError: boolean;

  emailList: EmailListModel[];
}

class AskDemoLink extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isBlocking: false,
      isIosChecked: false,
      checkboxError: false,
      isAndroidChecked: false,
      emailList: [{ id: uuid(), email: this.props.emailAddress }],
    };
  }
  formikRef: FormikProps<any> | null = null;

  backButton = () => {
    this.setState({ isBlocking: false }, async () => {
      this.props.history.push("/design");
    });
  };

  getInitialValues = () => {
    return {
      emailList: this.state.emailList,
      isAndroidChecked: this.state.isAndroidChecked,
      isIosChecked: this.state.isIosChecked,
    };
  };

  componentDidUpdate() {
    if (this.props.isMailSend) {
      this.props.history.push("/publish/install-to-store");
    }
  }

  onSubmitForm = (values: FormikValues) => {
    if (
      this.state.isAndroidChecked === false &&
      this.state.isIosChecked === false
    ) {
      this.setState(() => {
        return {
          checkboxError: true,
        };
      });
    } else {
      this.setState(() => {
        return {
          checkboxError: false,
        };
      });
      const emailAdresses = values.emailList.map((obj: any) => {
        return obj.email.trim();
      });

      const askDemoLinkReqObject: IAskDemoLinkRequest = {
        applicationId: this.props.appId,
        IsAndroidBuild: this.state.isAndroidChecked,
        IsIosBuild: this.state.isIosChecked,
        EmailAdresses: emailAdresses,
      };
      this.props._askDemoLink(askDemoLinkReqObject);
    }
  };

  render() {
    const { isBlocking } = this.state;
    return (
      <React.Fragment>
        <div className={classes.contentContainer}>
          <div className={classes.Container}>
            <div
              className={classes.backIconContainer}
              onClick={this.backButton}
            >
              <Icon
                className={classes.backIcon}
                type="arrowLeft"
                width="16"
                height="16"
                fillColor="#3196f6"
              />
              <span className={classes.backIconLabel}>
                Back to design settings
              </span>
            </div>
            <h3 className={classes.Heading}>Ask for a demo</h3>
            <p className={classes.description}>
              Congratulations! You have successfully created your app. Please
              enter your email address to get your demo applications. It takes
              about 30 minutes to build the demo apps.
            </p>
            <p className={classes.description}>
              To get an exclusively designed app for you, please choose IOS
              and/or Android select box below.
            </p>
            <Formik
              initialValues={this.getInitialValues()}
              validationSchema={validationSchema}
              validateOnBlur={false}
              validateOnChange={false}
              innerRef={(r) => (this.formikRef = r)}
              onSubmit={this.onSubmitForm}
            >
              {({ values, errors }) => (
                <Form>
                  <div className={classes.CheckboxList}>
                    <div className={classes.CheckboxGroup}>
                      <React.Fragment>
                        <Field
                          name={"checkbox"}
                          label={"IOS"}
                          value={values.isIosChecked}
                          changeHandler={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            this.setState((prevState) => {
                              return {
                                ...prevState,
                                isIosChecked: !this.state.isIosChecked,
                              };
                            })
                          }
                          component={Checkbox}
                        />
                        <Field
                          name={"checkbox"}
                          label={"Android"}
                          value={values.isAndroidChecked}
                          changeHandler={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            this.setState((prevState) => {
                              return {
                                ...prevState,
                                isAndroidChecked: !this.state.isAndroidChecked,
                              };
                            })
                          }
                          component={Checkbox}
                        />
                      </React.Fragment>
                    </div>
                    {this.state.checkboxError && (
                      <ErrorField
                        type={"relative"}
                        text={"You must select at least one device."}
                      />
                    )}
                  </div>
                  <div className={classes.EmailGroup}>
                    <span className={classes.EmailGroupTitle}>
                      Type your e-mail to get the demo app.
                    </span>
                    <FieldArray name="emailList">
                      {({ push, remove }) => (
                        <div>
                          {values.emailList.map((email, index) => {
                            return (
                              <div className={classes.emailList} key={email.id}>
                                <div className={classes.emailInput}>
                                  <Field
                                    name={`emailList[${index}].email`}
                                    placeholder="E-mail"
                                    type="email"
                                    component={TextFiled}
                                  />
                                </div>
                                {index !== 0 && (
                                  <div
                                    onClick={() => remove(index)}
                                    className={classes.removeIcon}
                                  >
                                    <Icon
                                      type="remove"
                                      width="14"
                                      height="14"
                                      fillColor="#3196f6"
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                          <div
                            className={`${classes.newEmailWrapper} ${
                              values.emailList.length !== 1 &&
                              classes.newEmailMargin
                            }`}
                          >
                            <div
                              className={classes.newEmailContainer}
                              onClick={() => push({ id: uuid(), email: "" })}
                            >
                              <Icon
                                className={classes.plusIcon}
                                type="plus"
                                width="14"
                                height="14"
                                fillColor="#3196f6"
                              />
                              <span className={classes.plusIconLabel}>
                                Add an e-mail
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className={classes.SaveButtonContainer}>
            <Button
              disableButton={this.props.loading}
              type="submit"
              width="192px"
              height="40px"
              className="Primary"
              onClick={() => this.formikRef?.submitForm()}
            >
              Send Demo
            </Button>
          </div>
        </div>
        <BlockingForm isBlocking={isBlocking} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    emailAddress: state.userInfo.emailAddress,
    isMailSend: state.askDemo.success,
    loading: state.askDemo.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _askDemoLink: (reqObject: IAskDemoLinkRequest) =>
      dispatch(askDemoLink(reqObject)),
    _demoInfo: (reqObject: IDemoInfoRequest) => dispatch(demoInfo(reqObject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AskDemoLink);
