import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { History } from "history";
import { bindActionCreators } from "redux";
import * as clientConfigListActions from "panel/src/redux/actions/clientConfigList";
import classes from "./configSettings.module.scss";
import Button from "panel/src/_components/common/Button";
import Checkbox from "panel/src/_components/common/Checkbox";
import RadioButton from "panel/src/_components/common/RadioButton";
import { updateObject } from "panel/src/utils/helper";
import BlockingForm from "panel/src/_components/common/BlockingForm";
import {
  Config,
  ConfigGroup,
} from "panel/src/models/Manage/configSettingsResponseModel";
import Modal from "panel/src/_components/common/Modal";
import Dropdown from "panel/src/Components/Dropdown";
import close from "panel/src/assets/images/close.svg";
import SubNavigation from "panel/src/_components/primitives/SubNavigation";
import Tooltip from "panel/src/Components/Tooltip";
import { platformList } from "panel/src/utils/constants";
import YoutubeTutorial, {
  YOUTUBE_LINKS,
} from "panel/src/Components/YoutubeTutorial";
import Input from "../../../Components/Input";
import smileio1 from "panel/src/assets/images/smileio_1.png";
import smileio2 from "panel/src/assets/images/smileio_2.png";
import smileio3 from "panel/src/assets/images/smileio_3.png";
import smileio4 from "panel/src/assets/images/smileio_4.png";
import smileio5 from "panel/src/assets/images/smileio_5.png";
import recharge1 from "panel/src/assets/images/recharge_1.png";
import recharge2 from "panel/src/assets/images/recharge_2.png";
import recharge3 from "panel/src/assets/images/recharge_3.png";
import growave1 from "panel/src/assets/images/growave_1.png";
import growave2 from "panel/src/assets/images/growave_2.png";
import growave3 from "panel/src/assets/images/growave_3.png";
import judgeme1 from "panel/src/assets/images/judgeme_1.png";
import judgeme2 from "panel/src/assets/images/judgeme_2.png";
import judgeme3 from "panel/src/assets/images/judgeme_3.png";
import { checkLoyaltyToken } from "../../../services/api/loyalty/loyalty";
import { checkRechargeToken } from "../../../services/api/recharge/recharge";
import notification from "../../../utils/notification";
import Spinner from "../../../_components/common/Spinner";
import * as packageFeatureActions from "../../../redux/actions/getPackageFeature";
import UpgradeModal from "../../../Components/UpgradeModal";
import Intercom from "../../../Components/Intercom/Intercom";
import { checkJudgeMeToken } from "../../../services/api/manageApp/feature";

interface IConfigSettingsProps {
  onSetConfigSettingsCompleted: Function;
  startPageLoading: Function;
  finishPageLoading: Function;
  appId: string;
  emailAddress: string;
  platformId: string;
  history: History;
  isSetAppStepCompleted: boolean;
  actions?: any;
  packageFeatureActions?: any;
  configListItems?: ConfigGroup[];
  IsStep13Completed: boolean;
  selectedAppPlatformId: keyof typeof platformList;
  packageFeatures: any;
}

interface IConfigSettingsState {
  ConfigGroups: ConfigGroup[];
  isLoading: boolean;
  isBlocking: boolean;
  isShowSubmitModal: boolean;
  loyaltyVariable: {
    configId: string;
    configGroupId: string;
    variable: any;
  };
  smileioFormVariableItems: any;
  formRechargeVariable: any;
  formJudgeMeVariable: any;
  formRechargeVariableItems: any;
  formJudgeMeVariableItems: any;
  formGrowaveVariableItems: any;
  formVariableFlits: any;
  formVariableGrowave: any;
  formMultiLanguageVariable: any;
  formFlitsInput: any;
  formGrowaveInput: {
    apiKey: string;
    apiSecret: string;
  };
  formSmiloIOVariableIsModalVisible: boolean;
  formFlitsVariableIsModalVisible: boolean;
  formGrowaveVariableIsModalVisible: boolean;
  formRechargeVariableIsModalVisible: boolean;
  formJudgeMeVariableIsModalVisible: boolean;
  formVariableLoading: boolean;
  loyaltyDropdownVisible: boolean;
  selectedLoyalty: number;
  subscriptionDropdownVisible: boolean;
  productPreviewModalVisible: boolean;
  selectedSubscription: number;
  selectedProductReviewOption: number;
  multiLanguageDropdownVisible: boolean;
  selectedMultiLanguage: number;
  augmentedRealityVariable: {
    configId: string;
    configGroupId: string;
    variable: any;
  };
  augmentedRealityDropdownVisible: boolean;
  selectedAugmentedReality: number;
  thirdPartLimit: number;
  isUpgradeModal: boolean;
  isUpgradePopup: boolean;
  isPackageSupportedList: any[];
}

class ConfigSettings extends Component<
  IConfigSettingsProps,
  IConfigSettingsState
> {
  state: IConfigSettingsState = {
    ConfigGroups: [],
    isLoading: false,
    isBlocking: false,
    isShowSubmitModal: false,
    loyaltyVariable: {
      configId: "",
      configGroupId: "",
      variable: {},
    },
    smileioFormVariableItems: {},
    formRechargeVariable: {
      configId: "",
      configGroupId: "",
      variable: {},
    },
    formJudgeMeVariable: {
      configId: "",
      configGroupId: "",
      variable: {},
    },
    formRechargeVariableItems: {},
    formJudgeMeVariableItems: {},
    formVariableFlits: {},
    formGrowaveVariableItems: {},
    formVariableGrowave: {},
    formMultiLanguageVariable: {
      configId: "",
      configGroupId: "",
      variable: {},
    },
    formFlitsInput: {
      userId: "",
      token: "",
    },
    formGrowaveInput: {
      apiKey: "",
      apiSecret: "",
    },
    formSmiloIOVariableIsModalVisible: false,
    formFlitsVariableIsModalVisible: false,
    formGrowaveVariableIsModalVisible: false,
    formRechargeVariableIsModalVisible: false,
    formJudgeMeVariableIsModalVisible: false,
    formVariableLoading: false,
    loyaltyDropdownVisible: false,
    selectedLoyalty: 0,
    subscriptionDropdownVisible: false,
    productPreviewModalVisible: false,
    selectedSubscription: 0,
    selectedProductReviewOption: 0,
    multiLanguageDropdownVisible: false,
    selectedMultiLanguage: 0,
    augmentedRealityDropdownVisible: false,
    selectedAugmentedReality: 0,
    augmentedRealityVariable: {
      configId: "",
      configGroupId: "",
      variable: {},
    },
    thirdPartLimit: 0,
    isUpgradeModal: false,
    isUpgradePopup: false,
    isPackageSupportedList: [],
  };

  async componentDidMount() {
    if (!this.props.isSetAppStepCompleted) {
      this.props.history.push("/manage/web-service-control/begin");
    }
    const { platformId, appId, actions, packageFeatureActions } = this.props;
    const configListObj = {
      applicationId: appId,
      platformId: platformId,
    };
    const packageFeatureObj = {
      applicationId: appId,
    };

    actions.clientConfigListStart(configListObj);
    packageFeatureActions.getPackageFeatureListStart(packageFeatureObj);
  }

  componentWillReceiveProps = (nextProps: any) => {
    if (this.props.configListItems != nextProps.configListItems) {
      // Features
      const configs = nextProps.configListItems[0]?.Configs;
      const features = configs
        .map((config: any) =>
          config?.Variable?.filter(
            (variable: any) => variable.IsPackageSupported
          )
        )
        .filter(Boolean)
        .reduce((result: any[], items: any[]) => [...result, ...items], []);

      //isPackageSupportedList
      this.setState({
        ConfigGroups: nextProps.configListItems,
        isPackageSupportedList: features,
      });
    }
  };

  componentDidUpdate(
    prevProps: IConfigSettingsProps,
    prevState: IConfigSettingsState
  ) {
    // only update chart if the data has changed
    if (prevProps.appId !== this.props.appId) {
      const { platformId, appId, actions } = this.props;
      const configListObj = {
        applicationId: appId,
        platformId: platformId,
      };
      actions.clientConfigListStart(configListObj);
    }
  }

  selectedThirdPartCount = () => {
    const { ConfigGroups } = this.state;
    return ConfigGroups.reduce((total, config) => {
      return (
        total +
        (config.Configs.filter(
          (s) => s.IsChecked === true && s.IsThirdParty === true
        ).length || 0)
      );
    }, 0);
  };

  thirdPartLimit = () =>
    this.props.packageFeatures?.find((s: any) => s.Id === 20)?.MaxCount || -1;

  submitHandler = () => {
    if (this.props.IsStep13Completed)
      this.setState({ isShowSubmitModal: true });
    else this.submitClickHandler();
  };

  changeHandler = (
    value: boolean,
    configGroupId: string,
    configId: string,
    variable?: any
  ) => {
    this.setState({ isBlocking: true });
    const configGroupIndex = this.state.ConfigGroups.findIndex(
      (configGroup: ConfigGroup) => configGroup.Id === configGroupId
    );
    const configIndex = this.state.ConfigGroups[
      configGroupIndex
    ].Configs.findIndex((config: Config) => config.Id === configId);

    const updatedConfigs = [
      ...this.state.ConfigGroups[configGroupIndex].Configs,
    ];

    const config =
      this.state.ConfigGroups[configGroupIndex].Configs[configIndex];

    if (config.ConfigType.Name === "RadioButton") {
      updatedConfigs.map((item: Config) => {
        item.IsChecked = false;
      });
    }

    const updatedConfig = updateObject(config, {
      IsChecked: value,
      Variable: variable ? variable : config.Variable,
    });

    updatedConfigs[configIndex] = updatedConfig;

    const updatedConfigGroup = updateObject(
      this.state.ConfigGroups[configGroupIndex],
      {
        Configs: updatedConfigs,
      }
    );

    const updatedConfigGroups = [...this.state.ConfigGroups];
    updatedConfigGroups[configGroupIndex] = updatedConfigGroup;

    this.setState({ ConfigGroups: updatedConfigGroups });
  };

  checkboxChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    configGroupId: string,
    configId: string
  ) => {
    const configGroupIndex = this.state.ConfigGroups.findIndex(
      (configGroup: ConfigGroup) => configGroup.Id === configGroupId
    );
    const configGroup = this.state.ConfigGroups[configGroupIndex];
    const configIndex = configGroup.Configs.findIndex(
      (config: Config) => config.Id === configId
    );
    const config =
      this.state.ConfigGroups[configGroupIndex].Configs[configIndex];
    const thirdPartLimit = this.thirdPartLimit();
    const selectedThirdPartCount = this.selectedThirdPartCount();
    if (
      e.target.checked &&
      config.IsThirdParty &&
      thirdPartLimit > -1 &&
      selectedThirdPartCount >= thirdPartLimit
    ) {
      this.setState({ isUpgradePopup: true });
      return;
    }
    //dropdown
    if (
      config.IsVariableExist &&
      config.Variable !== null &&
      config.Id === "3" &&
      e.target.checked
    ) {
      this.setState({
        loyaltyVariable: {
          configId,
          configGroupId,
          variable: config.Variable,
        },
        loyaltyDropdownVisible: true,
      });
      return;
    }
    //recharge
    if (
      config.IsVariableExist &&
      config.Variable !== null &&
      config.Id === "59" &&
      e.target.checked
    ) {
      this.setState({
        formRechargeVariable: {
          configId,
          configGroupId,
          variable: config.Variable,
        },
        subscriptionDropdownVisible: true,
      });
      return;
    }

    // ** Judge
    if (
      config.IsVariableExist &&
      config.Variable !== null &&
      config.Id === "77" &&
      e.target.checked
    ) {
      this.setState({
        formJudgeMeVariable: {
          configId,
          configGroupId,
          variable: config.Variable,
        },
        productPreviewModalVisible: true,
      });
      return;
    }

    //Multi language
    if (config.Id === "15" && e.target.checked) {
      this.setState({
        formMultiLanguageVariable: {
          configId,
          configGroupId,
          variable: config.Variable,
        },
        multiLanguageDropdownVisible: true,
      });
      return;
    }
    //Augmented Reality
    if (config.Id === "72" && e.target.checked) {
      this.setState({
        augmentedRealityVariable: {
          configId,
          configGroupId,
          variable: config.Variable,
        },
        augmentedRealityDropdownVisible: true,
      });
      return;
    }

    this.changeHandler(e.target.checked, configGroupId, configId);
  };

  radioChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    configGroupId: string,
    configId: string
  ) => {
    this.changeHandler(e.target.checked, configGroupId, configId);
  };

  closeAllModals = () => {
    this.setState({
      formSmiloIOVariableIsModalVisible: false,
      formRechargeVariableIsModalVisible: false,
      formJudgeMeVariableIsModalVisible: false,
      formFlitsVariableIsModalVisible: false,
      formGrowaveVariableIsModalVisible: false,
      loyaltyDropdownVisible: false,
      selectedLoyalty: 0,
    });
  };

  closeSubscriptionVariableModal = () => {
    this.setState({
      subscriptionDropdownVisible: false,
      selectedSubscription: 0,
    });
  };

  closeProductReviewVariableModal = () => {
    this.setState({
      productPreviewModalVisible: false,
      selectedAugmentedReality: 0,
    });
  };

  closeMultiLanguageVariableModal = () => {
    this.setState({
      multiLanguageDropdownVisible: false,
      selectedMultiLanguage: 0,
    });
  };

  closeAugmentedRealityVariableModal = () => {
    this.setState({
      augmentedRealityDropdownVisible: false,
      selectedAugmentedReality: 0,
    });
  };

  closeSubmitModal = () => {
    this.setState({ isShowSubmitModal: false });
  };

  submitSmileIOFormVariable = () => {
    try {
      const { loyaltyVariable, smileioFormVariableItems, selectedLoyalty } =
        this.state;
      const value = smileioFormVariableItems[loyaltyVariable.variable.Label];
      this.submitLoyaltyFormVariable(value);
    } catch (e) {}
  };

  submitFlitsFormVariable = () => {
    try {
      const { userId, token } = this.state.formFlitsInput;
      const value = Buffer.from(userId + "&" + token).toString("base64");
      this.submitLoyaltyFormVariable(value);
    } catch (e) {}
  };

  submitGrowaveFormVariable = () => {
    try {
      const { apiKey, apiSecret } = this.state.formGrowaveInput;
      const value = Buffer.from(apiKey + "&" + apiSecret).toString("base64");
      this.submitLoyaltyFormVariable(value);
    } catch (e) {}
  };

  submitLoyaltyFormVariable = async (value: string) => {
    try {
      const { loyaltyVariable, selectedLoyalty } = this.state;
      const { appId, emailAddress } = this.props;

      try {
        this.setState({ formVariableLoading: true });
        await checkLoyaltyToken(value, appId, emailAddress, selectedLoyalty);

        let variable = { ...loyaltyVariable.variable };
        let newVariable = [];

        const selectLoyaltyVariableIndex = loyaltyVariable.variable.findIndex(
          (s: any) => s.Id === selectedLoyalty
        );
        if (selectLoyaltyVariableIndex > -1) {
          variable[selectLoyaltyVariableIndex].value = value;
          newVariable.push(variable[selectLoyaltyVariableIndex]);
        }

        this.changeHandler(
          true,
          loyaltyVariable.configGroupId,
          loyaltyVariable.configId,
          newVariable
        );

        this.setState({
          formSmiloIOVariableIsModalVisible: false,
          formGrowaveVariableIsModalVisible: false,
          formFlitsVariableIsModalVisible: false,
          formRechargeVariableIsModalVisible: false,
          formJudgeMeVariableIsModalVisible: false,
          loyaltyVariable: {
            configId: "",
            configGroupId: "",
            variable: {},
          },
          smileioFormVariableItems: {},
          formFlitsInput: {
            userId: "",
            token: "",
          },
          formGrowaveInput: {
            apiKey: "",
            apiSecret: "",
          },
          formVariableLoading: false,
          selectedLoyalty: 0,
        });
      } catch (error) {
        notification.error(error?.response?.data?.ResultMesage || "");
        this.setState({
          formVariableLoading: false,
        });
      }
    } catch (error) {}
  };

  submitRechargeFormVariable = async () => {
    try {
      const { formRechargeVariable, formRechargeVariableItems } = this.state;
      if (
        Object.keys(formRechargeVariable).length > 0 &&
        formRechargeVariableItems?.[formRechargeVariable.variable.Label]
      ) {
        try {
          this.setState({ formVariableLoading: true });
          const value =
            formRechargeVariableItems[formRechargeVariable.variable.Label];
          await checkRechargeToken(value);

          const variable = [...formRechargeVariable.variable];
          variable[0].Value = value;

          this.changeHandler(
            true,
            formRechargeVariable.configGroupId,
            formRechargeVariable.configId,
            variable
          );
          this.setState({
            formRechargeVariableIsModalVisible: false,
            formRechargeVariable: {},
            formRechargeVariableItems: {},
            formVariableLoading: false,
          });
        } catch (error) {
          notification.error(error?.response?.data?.ResultMesage || "");
          this.setState({
            formVariableLoading: false,
          });
        }
      }
    } catch (error) {}
  };

  submitJudgeMeFormVariable = async () => {
    try {
      const { formJudgeMeVariable, formJudgeMeVariableItems } = this.state;
      if (
        Object.keys(formJudgeMeVariable).length > 0 &&
        formJudgeMeVariableItems?.[formJudgeMeVariable.variable.Label]
      ) {
        try {
          this.setState({ formVariableLoading: true });
          const value =
            formJudgeMeVariableItems[formJudgeMeVariable.variable.Label];
          const successToken = await checkJudgeMeToken(this.props.appId, value);

          if (!successToken || !successToken.data?.ResultData?.Succcess) {
            notification.error(successToken.data?.Message || "");
            this.setState({
              formVariableLoading: false,
            });
            return;
          }

          const variable = [...formJudgeMeVariable.variable];
          variable[0].Value = value;

          this.changeHandler(
            true,
            formJudgeMeVariable.configGroupId,
            formJudgeMeVariable.configId,
            variable
          );
          this.setState({
            formJudgeMeVariableIsModalVisible: false,
            formJudgeMeVariable: {},
            formJudgeMeVariableItems: {},
            formVariableLoading: false,
          });
        } catch (error) {
          notification.error(
            error?.response?.data?.ResultMesage ||
              error?.response?.data?.Message ||
              ""
          );
          this.setState({
            formVariableLoading: false,
          });
        }
      }
    } catch (error) {}
  };

  submitDropdownVariable = async () => {
    let selectedLoyalty = this.state.selectedLoyalty;
    //Smileio
    if (selectedLoyalty === 1) {
      this.setState({
        loyaltyDropdownVisible: false,
        formSmiloIOVariableIsModalVisible: true,
      });
      return;
    }
    //Flits
    else if (selectedLoyalty === 5) {
      this.setState({
        loyaltyDropdownVisible: false,
        formFlitsVariableIsModalVisible: true,
      });
      return;
    } else if (selectedLoyalty === 6) {
      this.setState({
        loyaltyDropdownVisible: false,
        formGrowaveVariableIsModalVisible: true,
      });
      return;
    } else {
    }
  };

  submitSubscriptionDropdownVariable = async () => {
    let selectedSubscription = this.state.selectedSubscription;
    //Recharge
    if (selectedSubscription === 3) {
      this.setState({
        subscriptionDropdownVisible: false,
        formRechargeVariableIsModalVisible: true,
      });
      return;
    }
  };

  submitProductReviewDropdownVariable = async () => {
    let selectedProductReviewOption = this.state.selectedProductReviewOption;
    //JudgeMe
    if (selectedProductReviewOption !== 3) {
      this.setState({
        productPreviewModalVisible: false,
        formJudgeMeVariableIsModalVisible: true,
      });
      return;
    }
  };

  submitMultiLanguageDropdownVariable = async () => {
    const { formMultiLanguageVariable, selectedMultiLanguage } = this.state;
    let variable = { ...formMultiLanguageVariable.variable };
    let newVariable = [];

    const selectVariableIndex = formMultiLanguageVariable.variable.findIndex(
      (s: any) => s.Id === selectedMultiLanguage
    );
    if (selectVariableIndex > -1) {
      variable[selectVariableIndex].value = "";
      newVariable.push(variable[selectVariableIndex]);
    }
    this.changeHandler(
      true,
      formMultiLanguageVariable.configGroupId,
      formMultiLanguageVariable.configId,
      newVariable
    );

    this.setState({
      multiLanguageDropdownVisible: false,
      selectedMultiLanguage: 0,
    });
  };

  submitAugmentRealityDropdownVariable = async () => {
    const { augmentedRealityVariable, selectedAugmentedReality } = this.state;
    let variable = { ...augmentedRealityVariable.variable };
    let newVariable = [];

    const selectVariableIndex = augmentedRealityVariable.variable.findIndex(
      (s: any) => s.Id === selectedAugmentedReality
    );
    if (selectVariableIndex > -1) {
      variable[selectVariableIndex].value = "";
      newVariable.push(variable[selectVariableIndex]);
    }
    this.changeHandler(
      true,
      augmentedRealityVariable.configGroupId,
      augmentedRealityVariable.configId,
      newVariable
    );

    this.setState({
      augmentedRealityDropdownVisible: false,
      selectedAugmentedReality: 0,
      augmentedRealityVariable: {
        configId: "",
        configGroupId: "",
        variable: {},
      },
    });
  };

  submitClickHandler = () => {
    this.setState({ isBlocking: false, isShowSubmitModal: false }, async () => {
      const { actions } = this.props;
      const data = {
        applicationId: this.props.appId,
        ConfigGroups: this.state.ConfigGroups,
      };
      try {
        await actions.saveAppConfigListStart(data);
        this.props.history.push("/manage/payment-options");
      } catch (error) {}
    });
  };

  renderNotSupportedText = () => {
    let initialWords;
    if (this.props.selectedAppPlatformId === 36) {
      initialWords = "Mowico demo commerce platform";
    } else {
      initialWords = `Your e-commerce platform ${
        platformList[this.props.selectedAppPlatformId]
      }`;
    }

    return `${initialWords} does not support these features;`;
  };

  getYoutubeUrl = (headerId: string) => {
    let url;

    switch (headerId) {
      case "1":
        url = YOUTUBE_LINKS.FEATURES;
        break;
      case "2":
        url = YOUTUBE_LINKS.CHECKOUT_OPTIONS;
        break;
      case "3":
        url = YOUTUBE_LINKS.USER_DATA;
        break;
      case "4":
        url = YOUTUBE_LINKS.USER_ADDRESS_DETAILS;
        break;
      case "5":
        url = YOUTUBE_LINKS.CORPORATE_TAX;
        break;
      case "999":
        url = YOUTUBE_LINKS.MULTI_CURRENCY_FEATURE;
        break;
      default:
        url = "";
        break;
    }

    return url;
  };

  handlePropertyChange = (val: any, selectKey: string) => {
    this.setState({ [selectKey]: val?.target?.value } as Pick<
      IConfigSettingsState,
      keyof IConfigSettingsState
    >);
  };

  isPackageSupported = (value: number, isConfigId: boolean = false) => {
    if (isConfigId)
      return this.state.isPackageSupportedList?.some(
        (s: any) => s.ConfigId === value
      );
    return this.state.isPackageSupportedList?.some((s: any) => s.Id === value);
  };

  render() {
    this.selectedThirdPartCount();
    const { ConfigGroups } = this.state;
    let formContent = null;
    if (this.state.isLoading) {
      formContent = null;
    } else {
      formContent =
        !!ConfigGroups &&
        ConfigGroups.map((configGroup: any) => {
          let notSupportedConfigs;
          const filteredConfigs = configGroup.Configs.filter(
            (config: any) => !config.IsHidden
          );

          const configs = filteredConfigs.map((config: any) => {
            let input = null;
            if (config.ConfigType.Name === "Checkbox") {
              const videoElement =
                config.Name === "Multi Currency Support" ? (
                  <div style={{ marginLeft: "10px" }}>
                    <YoutubeTutorial
                      inline
                      videoUrl={this.getYoutubeUrl("999")}
                      iconSize={16}
                    />
                  </div>
                ) : undefined;

              input = (
                <div
                  style={{ marginBottom: 12 }}
                  className={
                    config.Description
                      ? classes.ConfigWrapperWithDescription
                      : classes.ConfigWrapper
                  }
                >
                  <Checkbox
                    marginBottom="0px"
                    key={`${configGroup.Id}_${config.Id}`}
                    label={config.Name}
                    videoElement={videoElement}
                    checked={config.IsChecked}
                    disabled={config.IsDisabled}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.checkboxChangeHandler(e, configGroup.Id, config.Id)
                    }
                    isCustom={config.IsCustom}
                    containerMarginLeft="15px"
                    description={config?.Description}
                    isPackageSupported={config.IsPackageSupported}
                  />
                  {!config.IsSupported && (
                    <Tooltip text="Your e-commerce platform does not support this feature" />
                  )}
                </div>
              );
            } else if (config.ConfigType.Name === "RadioButton") {
              input = (
                <div className={classes.ConfigWrapper}>
                  <RadioButton
                    key={`${configGroup.Id}_${config.Id}`}
                    label={config.Name}
                    checked={config.IsChecked}
                    disabled={config.IsDisabled}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.radioChangeHandler(e, configGroup.Id, config.Id)
                    }
                  />
                  {!config.IsSupported && (
                    <Tooltip text="Your e-commerce platform does not support this feature" />
                  )}
                </div>
              );
            }

            return input;
          });

          if (configGroup.NotSupportedConfigs !== null) {
            const filteredNotSupportedConfigs =
              configGroup.NotSupportedConfigs.filter(
                (config: any) => !config.IsHidden
              );

            notSupportedConfigs = filteredNotSupportedConfigs.map(
              (config: any) => {
                let input = null;
                if (config.ConfigType.Name === "Checkbox") {
                  input = (
                    <div
                      style={{ marginBottom: 12 }}
                      className={
                        config.Description
                          ? classes.ConfigWrapperWithDescription
                          : classes.ConfigWrapper
                      }
                    >
                      <Checkbox
                        marginBottom="0px"
                        key={`${configGroup.Id}_${config.Id}`}
                        label={config.Name}
                        checked={config.IsChecked}
                        disabled={config.IsDisabled}
                        changeHandler={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          this.checkboxChangeHandler(
                            e,
                            configGroup.Id,
                            config.Id
                          )
                        }
                        isCustom={config.IsCustom}
                        description={config?.Description}
                        containerMarginLeft="15px"
                        isPackageSupported={config.IsPackageSupported}
                      />
                    </div>
                  );
                } else if (config.ConfigType.Name === "RadioButton") {
                  input = (
                    <div className={classes.ConfigWrapper}>
                      <RadioButton
                        key={`${configGroup.Id}_${config.Id}`}
                        label={config.Name}
                        checked={config.IsChecked}
                        disabled={config.IsDisabled}
                        changeHandler={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          this.radioChangeHandler(e, configGroup.Id, config.Id)
                        }
                      />
                    </div>
                  );
                }

                return input;
              }
            );
          }

          return (
            <fieldset key={configGroup.Id} className={classes.FieldSet}>
              <div className={classes.LegendContainer}>
                <legend className={classes.Legend}>{configGroup.Name}</legend>
                <YoutubeTutorial
                  videoUrl={this.getYoutubeUrl(configGroup.Id)}
                  iconSize={16}
                />
              </div>
              <p className={classes.Description}>{configGroup.Description}</p>
              <div className={classes.ConfigContainer}>{configs}</div>
              {notSupportedConfigs !== undefined && (
                <>
                  <p className={classes.NotSupportedConfigDescription}>
                    {this.renderNotSupportedText()}
                  </p>
                  <div className={classes.ConfigContainer}>
                    {notSupportedConfigs}
                  </div>
                </>
              )}
            </fieldset>
          );
        });

      formContent = <React.Fragment>{formContent}</React.Fragment>;
    }

    const {
      loyaltyVariable,
      formMultiLanguageVariable,
      formFlitsInput,
      formGrowaveInput,
      smileioFormVariableItems,
      formRechargeVariable,
      formVariableFlits,
      formRechargeVariableItems,
      loyaltyDropdownVisible,
      formJudgeMeVariableItems,
      formJudgeMeVariable,
    } = this.state;

    return (
      <React.Fragment>
        <SubNavigation isConfigTab />
        <Intercom />
        <div className={classes.contentContainer}>
          <div className={classes.ManageSubRouteContainer}> {formContent}</div>
          <div className={classes.SaveButtonContainer}>
            <Button width="160px" type="Primary" onClick={this.submitHandler}>
              Save
            </Button>
          </div>
        </div>
        <BlockingForm isBlocking={this.state.isBlocking} />
        <Modal
          show={loyaltyDropdownVisible}
          width="477"
          style={{
            alignItems: "flex-start",
            overflowY: "inherit",
            overflowX: "inherit",
          }}
        >
          <Spinner.Default isLoading={this.state.formVariableLoading} />
          <div className={classes.ModalTitle}>
            <h5 className={classes.ModalTitleText}>Loyalty Points Selection</h5>
          </div>

          <div className={`${classes.ModalBody}`} style={{ marginTop: "15px" }}>
            <div>
              <p>Please choose the loyalty plugin option you prefer.</p>

              <div className={classes.ModalForm}>
                <Dropdown
                  id=""
                  options={[
                    {
                      label: "Smile io",
                      value: 1,
                      isPackageSupported: this.isPackageSupported(1),
                    },
                    {
                      label: "Flits",
                      value: 5,
                      isPackageSupported: this.isPackageSupported(5),
                    },
                    {
                      label: "Growave",
                      value: 6,
                      isPackageSupported: this.isPackageSupported(6),
                    },
                  ]}
                  isPackageSupportControl
                  style={{}}
                  label=""
                  placeholder="Select Plugin"
                  value={this.state.selectedLoyalty}
                  onChangeHandler={(val: React.ReactText) =>
                    this.handlePropertyChange(val, "selectedLoyalty")
                  }
                />
              </div>
            </div>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type="button"
                className="Ghost"
                width="48%"
                onClick={this.closeAllModals}
                disabled={this.state.formVariableLoading}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="Primary"
                width="48%"
                onClick={this.submitDropdownVariable}
                disabled={this.state.formVariableLoading}
              >
                Create
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          show={this.state.subscriptionDropdownVisible}
          width="477"
          style={{
            alignItems: "flex-start",
            overflowY: "inherit",
            overflowX: "inherit",
          }}
        >
          <Spinner.Default isLoading={this.state.formVariableLoading} />
          <div className={classes.ModalTitle}>
            <h5 className={classes.ModalTitleText}>
              Product Subscription Option
            </h5>
          </div>

          <div className={`${classes.ModalBody}`} style={{ marginTop: "15px" }}>
            <div>
              <p>Please select the product subscription option you prefer.</p>

              <div className={classes.ModalForm}>
                <Dropdown
                  id=""
                  options={[
                    {
                      label: "Recharge",
                      value: 3,
                      isPackageSupported: this.isPackageSupported(59, true),
                    },
                  ]}
                  isPackageSupportControl
                  style={{}}
                  label=""
                  placeholder="Select product subscription option"
                  value={this.state.selectedSubscription}
                  onChangeHandler={(val: React.ReactText) =>
                    this.handlePropertyChange(val, "selectedSubscription")
                  }
                />
              </div>
            </div>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type="button"
                className="Ghost"
                width="48%"
                onClick={this.closeSubscriptionVariableModal}
                disabled={this.state.formVariableLoading}
              >
                Cancel
              </Button>
              <Button
                disableButton={this.state.selectedSubscription === 0}
                type="button"
                className="Primary"
                width="48%"
                onClick={this.submitSubscriptionDropdownVariable}
                disabled={this.state.formVariableLoading}
              >
                Create
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          show={this.state.productPreviewModalVisible}
          width="477"
          style={{
            alignItems: "flex-start",
            overflowY: "inherit",
            overflowX: "inherit",
          }}
        >
          <Spinner.Default isLoading={this.state.formVariableLoading} />
          <div className={classes.ModalTitle}>
            <h5 className={classes.ModalTitleText}>Product Reviews Option</h5>
          </div>

          <div className={`${classes.ModalBody}`} style={{ marginTop: "15px" }}>
            <div>
              <p>Please select the product subscription option you prefer.</p>

              <div className={classes.ModalForm}>
                <Dropdown
                  id=""
                  options={[
                    {
                      label: "JudgeMe",
                      value: 11,
                      isPackageSupported: this.isPackageSupported(77, true),
                    },
                  ]}
                  isPackageSupportControl
                  style={{}}
                  label=""
                  placeholder="Select product reviews option"
                  value={this.state.selectedProductReviewOption}
                  onChangeHandler={(val: React.ReactText) =>
                    this.handlePropertyChange(
                      val,
                      "selectedProductReviewOption"
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type="button"
                className="Ghost"
                width="48%"
                onClick={this.closeProductReviewVariableModal}
                disabled={this.state.formVariableLoading}
              >
                Cancel
              </Button>
              <Button
                disableButton={this.state.selectedProductReviewOption === 0}
                type="button"
                className="Primary"
                width="48%"
                onClick={this.submitProductReviewDropdownVariable}
                disabled={this.state.formVariableLoading}
              >
                Create
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          show={this.state.formJudgeMeVariableIsModalVisible}
          width="477"
          style={{ alignItems: "flex-start" }}
        >
          <Spinner.Default isLoading={this.state.formVariableLoading} />
          <div className={classes.ModalTitle}>
            <h5 className={classes.ModalTitleText}>
              How to access your URL key?
            </h5>
          </div>

          <div className={classes.Close} onClick={this.closeAllModals}>
            <img src={close} />
          </div>

          <div className={`${classes.ModalBody}`} style={{ marginTop: "15px" }}>
            <div>
              <div style={{ maxHeight: 350, overflow: "auto" }}>
                <p>1. Go to Judge.me settings in the Shopify admin panel.</p>
                <div className={classes.ImgSmall}>
                  <div>
                    <img src={judgeme1} alt="JudgeMe" />
                  </div>
                </div>
                <p>2. Go to the Integrations page.</p>
                <div className={classes.ImgSmall}>
                  <div style={{ width: "70%" }}>
                    <img src={judgeme2} alt="JudgeMe" />
                  </div>
                </div>
                <p>
                  3. Click on Automatic Integrations. Get the API token here and
                  paste it into the relevant field.
                </p>
                <div className={classes.ImgSmall}>
                  <div style={{ width: "100%" }}>
                    <img src={judgeme3} alt="JudgeMe" />
                  </div>
                </div>
              </div>
              <div className={classes.ModalForm}>
                <Input
                  id=""
                  label=""
                  disabled={this.state.formVariableLoading}
                  placeholder={"Please enter API key information"}
                  value={formJudgeMeVariableItems?.[formJudgeMeVariable.Label]}
                  onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({
                      formJudgeMeVariableItems: {
                        ...formJudgeMeVariable,
                        [formJudgeMeVariable.variable.Label]: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type="button"
                className="Ghost"
                width="48%"
                onClick={this.closeAllModals}
                disabled={this.state.formVariableLoading}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="Primary"
                width="48%"
                onClick={this.submitJudgeMeFormVariable}
                disabled={this.state.formVariableLoading}
              >
                Create
              </Button>
            </div>
          </div>
        </Modal>

        {/**
         * Smileio Variable Modal
         * You can add new variable for new integrations
         */}
        <Modal
          show={this.state.formSmiloIOVariableIsModalVisible}
          width="477"
          style={{ alignItems: "flex-start" }}
        >
          <Spinner.Default isLoading={this.state.formVariableLoading} />
          <div className={classes.ModalTitle}>
            <h5 className={classes.ModalTitleText}>
              How to access your URL key?
            </h5>
          </div>
          <div className={classes.Close} onClick={this.closeAllModals}>
            <img src={close} />
          </div>

          <div className={`${classes.ModalBody}`} style={{ marginTop: "15px" }}>
            <div>
              <p>
                1.{" "}
                <span className={classes.blueText}>
                  Go to your Shopify admin panel
                </span>{" "}
                then go to settings in the Smile.io app and then the platform
                page
              </p>
              <div className={classes.ImgFlex}>
                <div>
                  <img src={smileio1} alt="SmileIO" />
                </div>
                <div>
                  <img src={smileio2} alt="SmileIO" />
                </div>
                <div>
                  <img src={smileio3} alt="SmileIO" />
                </div>
              </div>
              <p>
                2. Click <span className={classes.blueText}>“Show more”</span>
              </p>
              <div className={classes.ImgFlex}>
                <div>
                  <img src={smileio4} alt="SmileIO" />
                </div>
              </div>
              <p>
                3. Click the{" "}
                <span className={classes.blueText}>Copy button</span> in the
                Private Click .
              </p>
              <div className={classes.ImgFlex}>
                <div>
                  <img src={smileio5} alt="SmileIO" />
                </div>
              </div>
              <div className={classes.ModalForm}>
                <Input
                  id=""
                  label=""
                  disabled={this.state.formVariableLoading}
                  placeholder={loyaltyVariable?.variable?.Label || "secretKey"}
                  value={
                    smileioFormVariableItems?.[loyaltyVariable.variable.Label]
                  }
                  onChangeHandler={
                    (e: React.ChangeEvent<HTMLInputElement>) => {
                      this.setState({
                        smileioFormVariableItems: {
                          ...smileioFormVariableItems,
                          [loyaltyVariable.variable.Label]: e.target.value,
                        },
                      });
                    }
                    //setMenuItemName(e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type="button"
                className="Ghost"
                width="48%"
                onClick={this.closeAllModals}
                disabled={this.state.formVariableLoading}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="Primary"
                width="48%"
                onClick={this.submitSmileIOFormVariable}
                disabled={this.state.formVariableLoading}
              >
                Create
              </Button>
            </div>
          </div>
        </Modal>
        {/**
         * Flits Variable Modal
         * You can add new variable for new integrations
         */}
        <Modal
          show={this.state.formFlitsVariableIsModalVisible}
          width="467"
          style={{ alignItems: "flex-start" }}
        >
          <Spinner.Default isLoading={this.state.formVariableLoading} />
          <div className={classes.ModalTitle}>
            <h5 className={classes.ModalTitleText}>
              How to access your Flits User ID and Flits Token?
            </h5>
          </div>
          <div className={classes.Close} onClick={this.closeAllModals}>
            <img src={close} />
          </div>

          <div className={`${classes.ModalBody}`} style={{ marginTop: "15px" }}>
            <div>
              <p>
                You can get Flits User ID and Token information from Flits
                support team. You can reach Flits support team at
                <span className={classes.blueText}>
                  {" "}
                  &quot;support@getflits.com&quot;{" "}
                </span>
                e-mail address or live chat in Flits admin panel.
              </p>
              <div className={classes.ModalForm}>
                <Input
                  id=""
                  label=""
                  disabled={this.state.formVariableLoading}
                  placeholder={"Flits User ID"}
                  value={formFlitsInput.userId}
                  onChangeHandler={
                    (e: React.ChangeEvent<HTMLInputElement>) => {
                      this.setState({
                        formFlitsInput: {
                          ...formFlitsInput,
                          userId: e.target.value,
                        },
                      });
                    }
                    //setMenuItemName(e.target.value)
                  }
                />
                <Input
                  id=""
                  label=""
                  disabled={this.state.formVariableLoading}
                  placeholder={"Flits Token"}
                  value={formFlitsInput.token}
                  onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({
                      formFlitsInput: {
                        ...formFlitsInput,
                        token: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type="button"
                className="Ghost"
                width="48%"
                onClick={this.closeAllModals}
                disabled={this.state.formVariableLoading}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="Primary"
                width="48%"
                onClick={() => this.submitFlitsFormVariable()}
                disableButton={
                  formFlitsInput.userId === "" || formFlitsInput.token === ""
                }
              >
                Create
              </Button>
            </div>
          </div>
        </Modal>
        {/**
         * Growave Variable Modal
         * You can add new variable for new integrations
         */}
        <Modal
          show={this.state.formGrowaveVariableIsModalVisible}
          width="467"
          style={{ alignItems: "flex-start", maxHeight: "70vh" }}
        >
          <Spinner.Default isLoading={this.state.formVariableLoading} />
          <div className={classes.ModalTitle}>
            <h5 className={classes.ModalTitleText}>
              Enter your Growave information
            </h5>
          </div>
          <div className={classes.Close} onClick={this.closeAllModals}>
            <img src={close} />
          </div>

          <div className={`${classes.ModalBody}`} style={{ marginTop: "15px" }}>
            <div>
              <p>
                1. Go to shopify admin panel {`>`} growave.io {`>`} settings.
              </p>
              <div className={classes.ImgSmall}>
                <div>
                  <img src={growave1} alt="Growave" />
                </div>
              </div>
              <p>
                2. <span className={classes.blueText}>Click to API.</span>
              </p>
              <div className={classes.ImgSmall}>
                <div style={{ width: "70%" }}>
                  <img src={growave2} alt="Growave" />
                </div>
              </div>
              <p>3. Copy API key and API secret.</p>
              <div className={classes.ImgSmall}>
                <div style={{ width: "100%" }}>
                  <img src={growave3} alt="Growave" />
                </div>
              </div>
              <div className={classes.ModalForm}>
                <Input
                  id=""
                  label=""
                  disabled={this.state.formVariableLoading}
                  placeholder="API Key"
                  value={formGrowaveInput.apiKey}
                  onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({
                      formGrowaveInput: {
                        ...formGrowaveInput,
                        apiKey: e.target.value,
                      },
                    });
                  }}
                />
                <Input
                  id=""
                  label=""
                  disabled={this.state.formVariableLoading}
                  placeholder="API Secret"
                  value={formGrowaveInput.apiSecret}
                  onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({
                      formGrowaveInput: {
                        ...formGrowaveInput,
                        apiSecret: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type="button"
                className="Ghost"
                width="48%"
                onClick={this.closeAllModals}
                disabled={this.state.formVariableLoading}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="Primary"
                width="48%"
                onClick={() => this.submitGrowaveFormVariable()}
                disableButton={
                  formGrowaveInput.apiKey === "" ||
                  formGrowaveInput.apiSecret === ""
                }
              >
                Create
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          show={this.state.formRechargeVariableIsModalVisible}
          width="477"
          style={{ alignItems: "flex-start" }}
        >
          <Spinner.Default isLoading={this.state.formVariableLoading} />
          <div className={classes.ModalTitle}>
            <h5 className={classes.ModalTitleText}>
              How to access your URL key?
            </h5>
          </div>
          <div className={classes.Close} onClick={this.closeAllModals}>
            <img src={close} />
          </div>

          <div className={`${classes.ModalBody}`} style={{ marginTop: "15px" }}>
            <div>
              <p>
                1. Go to your
                <span className={classes.blueText}>
                  ‘’Recharge admin panel‘’
                </span>{" "}
                and open Api Token from Apps page there.
              </p>
              <div>
                <img src={recharge1} alt="Recharge" height={60} />
              </div>
              <p>
                2. Go to{" "}
                <span className={classes.blueText}>“Create an API Token”</span>
                and enable Read and Write access for all services.
              </p>
              <div className={classes.ImgFlex}>
                <img src={recharge2} alt="Recharge" />
              </div>
              <p>
                3. <span className={classes.blueText}>“Copy”</span>
                the Api key.
              </p>
              <div className={classes.ImgFlex}>
                <img src={recharge3} alt="Recharge" />
              </div>

              <div className={classes.ModalForm}>
                <Input
                  id=""
                  label=""
                  disabled={this.state.formVariableLoading}
                  placeholder={"Please enter API key information"}
                  value={
                    formRechargeVariableItems?.[formRechargeVariable.Label]
                  }
                  onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({
                      formRechargeVariableItems: {
                        ...formRechargeVariable,
                        [formRechargeVariable.variable.Label]: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type="button"
                className="Ghost"
                width="48%"
                onClick={this.closeAllModals}
                disabled={this.state.formVariableLoading}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="Primary"
                width="48%"
                onClick={this.submitRechargeFormVariable}
                disabled={this.state.formVariableLoading}
              >
                Create
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          show={this.state.multiLanguageDropdownVisible}
          width="477"
          style={{
            alignItems: "flex-start",
            overflowY: "inherit",
            overflowX: "inherit",
          }}
        >
          <Spinner.Default isLoading={this.state.formVariableLoading} />
          <div className={classes.ModalTitle}>
            <h5 className={classes.ModalTitleText}>Multi Language Option</h5>
          </div>

          <div className={`${classes.ModalBody}`} style={{ marginTop: "15px" }}>
            <div>
              <p>Please select the multi language option you prefer.</p>

              <div className={classes.ModalForm}>
                <Dropdown
                  id=""
                  options={[
                    {
                      label: "Multi language",
                      value: 9,
                      isPackageSupported: this.isPackageSupported(9),
                    },
                    {
                      label: "Langify",
                      value: 10,
                      isPackageSupported: this.isPackageSupported(10),
                    },
                  ]}
                  isPackageSupportControl
                  style={{}}
                  label=""
                  placeholder="Select multi language option"
                  value={this.state.selectedMultiLanguage}
                  onChangeHandler={(val: React.ReactText) =>
                    this.handlePropertyChange(val, "selectedMultiLanguage")
                  }
                />
              </div>
            </div>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type="button"
                className="Ghost"
                width="48%"
                onClick={this.closeMultiLanguageVariableModal}
                disabled={this.state.formVariableLoading}
              >
                Cancel
              </Button>
              <Button
                disableButton={this.state.selectedMultiLanguage === 0}
                type="button"
                className="Primary"
                width="48%"
                onClick={this.submitMultiLanguageDropdownVariable}
                disabled={this.state.formVariableLoading}
              >
                Create
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          show={this.state.augmentedRealityDropdownVisible}
          width="477"
          style={{
            alignItems: "flex-start",
            overflowY: "inherit",
            overflowX: "inherit",
          }}
        >
          <Spinner.Default isLoading={this.state.formVariableLoading} />
          <div className={classes.ModalTitle}>
            <h5 className={classes.ModalTitleText}>Augmented Reality Option</h5>
          </div>

          <div className={`${classes.ModalBody}`} style={{ marginTop: "15px" }}>
            <div>
              <p>Please select the augmented reality option you prefer.</p>

              <div className={classes.ModalForm}>
                <Dropdown
                  id=""
                  options={[
                    {
                      label: "Roomvo Visualizer",
                      value: 8,
                      isPackageSupported: this.isPackageSupported(8),
                    },
                  ]}
                  isPackageSupportControl
                  style={{}}
                  label=""
                  placeholder="Select augmented reality option"
                  value={this.state.selectedAugmentedReality}
                  onChangeHandler={(val: React.ReactText) =>
                    this.handlePropertyChange(val, "selectedAugmentedReality")
                  }
                />
              </div>
            </div>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type="button"
                className="Ghost"
                width="48%"
                onClick={this.closeAugmentedRealityVariableModal}
                disabled={this.state.formVariableLoading}
              >
                Cancel
              </Button>
              <Button
                disableButton={this.state.selectedAugmentedReality === 0}
                type="button"
                className="Primary"
                width="48%"
                onClick={this.submitAugmentRealityDropdownVariable}
                disabled={this.state.formVariableLoading}
              >
                Create
              </Button>
            </div>
          </div>
        </Modal>
        <Modal show={this.state.isShowSubmitModal} width="448">
          <div className={classes.Close} onClick={this.closeSubmitModal}>
            <img src={close} />
          </div>
          <div className={`${classes.ModalBody} ${classes.ModalBodyConfirm}`}>
            <span className={classes.ModalBodyText}>
              Your changes will be saved and updated on your application.
            </span>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type="button"
                className="Ghost"
                width="192px"
                onClick={this.closeSubmitModal}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="Primary"
                width="192px"
                onClick={this.submitClickHandler}
              >
                Save and Update
              </Button>
            </div>
          </div>
        </Modal>
        <UpgradeModal
          setIsOpenModal={(e) => this.setState({ isUpgradePopup: false })}
          isOpenModal={this.state.isUpgradePopup}
        />
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(clientConfigListActions, dispatch),
    packageFeatureActions: bindActionCreators(packageFeatureActions, dispatch),
  };
}

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    emailAddress: state.userInfo.emailAddress,
    platformId:
      state.manageApp.setPlatformObject &&
      state.manageApp.setPlatformObject.platformId,
    configListItems: state.clientConfigList.configList,
    isSetAppStepCompleted: state.userInfo.selectedApp.IsStep1Completed,
    IsStep13Completed: state.userInfo.selectedApp.IsStep13Completed,
    selectedAppPlatformId: state.userInfo.selectedApp.PlatformId,
    packageFeatures:
      state.packageFeature?.featureData?.PackageFeature?.Features,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigSettings);
