import React, { FC } from 'react';
import styled from 'styled-components';
import LeftIcon from '../../_components/common/ChooseLogo/Icons/LeftIcon';
import CenterIcon from '../../_components/common/ChooseLogo/Icons/CenterIcon';
import EmptyIcon from '../../_components/common/ChooseLogo/Icons/EmptyIcon';

type MenuEditorAlignmentProps = {
  value?: string;
  setValue: (value: string) => void; 
};

const Container = styled.div`
  background-color: #f3f5f6;
  padding: 16px;
  border-radius: 4px;
`;

const Title = styled.h3`
  font-size: 14px;
  color: #3e5e6e;
`;

const Controls = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

const ControlItem = styled.div<{ isSelected?: boolean }>`
  color: #3e5e6e;
  width: 33.3%;
  cursor: pointer;
  &:hover {
    color: #3196f6;
    .icon {
      border-color: #3196f6;
    }
  }  
  ${({ isSelected }) => isSelected && `
    color: #3196f6;
    .icon {
      border-color: #3196f6;
    }
  `}
`;

const ControlIcon = styled.div`
  background-color: #fff;
  border-radius: 4px;
  border-color: #fff;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
`;

const ControlTitle = styled.div`
  font-size: 14px;
  margin-top: 8px;
  font-size: 12px;
  text-align: center;
`;

const options = [
  { name: 'Left', value: 'left' },
  { name: 'Center', value: 'center' },
  { name: 'Empty Icon', value: 'empty' },
];

const MenuEditorAlignment: FC<MenuEditorAlignmentProps> = ({ value, setValue }) => {
  return (
    <Container>
      <Title>Name Alignment</Title>
      <Controls>
        {options.map((item, idx) => (
          <ControlItem
            key={idx}
            isSelected={item.value === value}
            onClick={() => setValue(item.value)}
          >
            <ControlIcon className="icon">
              {item.value === 'left' && <LeftIcon />}
              {item.value === 'center' && <CenterIcon />}
              {item.value === 'empty' && <EmptyIcon />}
            </ControlIcon>
            <ControlTitle>{item.name}</ControlTitle>
          </ControlItem>
        ))}
      </Controls>
    </Container>
  );
};

export default MenuEditorAlignment;
