import { Dimensions, PixelRatio, Platform } from "react-native";

// replaceAll polyfill
if (!String.prototype.replaceAll) {
  // @ts-ignore
	String.prototype.replaceAll = function(str: string | RegExp, newStr: string){
		if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
      // @ts-ignore
			return this.replace(str, newStr);
		}
    // @ts-ignore
		return this.replace(new RegExp(str, 'g'), newStr);
	};
}

const deviceWidth = Dimensions.get("window").width;
const deviceHeight = Dimensions.get("window").height;
const designWidth = 320;

function dimenWidth(size: number) {
  return Math.round(deviceWidth * (size / designWidth));
}

function dimenHeight(size: number) {
  return Math.round(deviceWidth * (size / designWidth));
}

function dimenFont(size: number) {
  if (Platform.OS === "ios") {
    return PixelRatio.roundToNearestPixel(dimenWidth(size));
  } else {
    return dimenWidth(size) - 1;
  }
}

const OtfFonts = ["HelveticaNeue", "RedHatDisplay"];

const generateAssetsFontCss = (fontFamily: string) => {
  const fileFormat = OtfFonts.some((s) => fontFamily.includes(s))
    ? "otf"
    : "ttf";

  const fileName = Platform.select({
    ios: `${fontFamily}.${fileFormat}`,
    android: `file:///android_asset/fonts/${fontFamily}.${fileFormat}`,
  });

  return `
	@font-face {
        	font-family: '${fontFamily}';
        src: local('${fontFamily}'), url('${fileName}') format('${
    fileFormat === "ttf" ? "truetype" : "opentype"
  }');
	}
	`;
};

const regex = /style="(.*?)"/gm;

const generateHtmlWithBody = (htmlContent: string = '', fonts: any, fontFamily: any, colors: any, padding = false) => `
  <!DOCTYPE html>
  <html>
    <head>
      <title>Web View</title>
      <meta http-equiv="content-type" content="text/html; charset=utf-8">
      <meta name="viewport" content="width=320, user-scalable=no">
      <style type="text/css">
        ${fonts}
        *, *::after, *::before {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        *{
          font-family: '${fontFamily?.Regular}' !important;
          font-size: ${dimenFont(12)}px;
          color:${colors ? colors?.[0]?.colorCode : "#000"}
        }

        body {
          margin: 0;
          padding: 0;
          ${padding && `padding: ${dimenWidth(16)}px`}
        }

        strong,b{
          font-family: '${fontFamily?.Bold}' !important;
        }

        h1 {
          font-size: ${dimenFont(17)}px;
          font-family: '${fontFamily?.Bold}' !important;
        }

        h2 {
          font-size: ${dimenFont(14)}px;
          font-family: '${fontFamily?.Bold}' !important;
        }

        h1 *,h2 *{
          font-family: '${fontFamily?.Bold}' !important;
        }

        ol, ul {
          padding-left: 3em;
        }

        blockquote {
          border-left: 4px solid #ccc;
          margin-bottom: 5px;
          margin-top: 5px;
          padding-left: 16px;
        }

        a {
          text-decoration: underline;
        }

        img {
          max-width:100%;
        }

        video {
          max-width:100%;
        }

        iframe{
          max-width:100%;
          width: 100%;
        }

      </style>
    </head>
    <body>
      ${htmlContent.replaceAll ? htmlContent?.replaceAll(regex, '') : htmlContent}
    </body>
  </html>
`;

export {
  deviceWidth,
  deviceHeight,
  designWidth,
  dimenWidth,
  dimenHeight,
  dimenFont,
  generateAssetsFontCss,
  generateHtmlWithBody,
};
