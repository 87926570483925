import React from "react";
import { IconType } from "../../../utils/enums/iconType";

interface IProps {
  isSmall?: boolean;
  iconType: string;
}

const NotificationIcon = (props: IProps) => {
  let width = null;
  let height = null;
  let fillColor = null;

  if (props.isSmall) width = height = "20";
  else width = height = "32";

  switch (props.iconType) {
    case IconType.Disabled:
      fillColor = "#6D8694";
      break;
    case IconType.Active:
      fillColor = "#3196F6";
      break;
    case IconType.Enabled:
      fillColor = "#FFFFFF";
      break;
    default:
      fillColor = "#6D8694";
      break;
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.77 15.471c-.622-1.039-1.37-3.015-1.37-6.508V8.24c0-4.512-3.736-8.21-8.336-8.24H10a8.656 8.656 0 0 0-3.217.623 8.434 8.434 0 0 0-2.727 1.768 8.125 8.125 0 0 0-1.82 2.642A7.909 7.909 0 0 0 1.6 8.148v.815c0 3.493-.747 5.47-1.368 6.508a1.59 1.59 0 0 0-.01 1.635c.148.25.36.456.618.6.256.144.548.22.844.22H5.79c0 1.08.443 2.117 1.233 2.88A4.283 4.283 0 0 0 10 22c1.117 0 2.188-.43 2.978-1.193a4.008 4.008 0 0 0 1.233-2.881h4.106c.296.002.588-.073.845-.216a1.61 1.61 0 0 0 .607-2.239zM10 20a2.008 2.008 0 0 1-2-2h4a2.008 2.008 0 0 1-2 2z"
        fill={fillColor}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default NotificationIcon;
