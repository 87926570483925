import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import EngageNavigation from "panel/src/_components/primitives/EngageNavigation";
import classes from "./bannerSettings.module.scss";
import Button from "../../../_components/common/Button";
import ConfirmButton from "panel/src/_components/common/Button";

// COMPONENTS START
import Content from "./Content";
import { IComponent } from "panel/src/Components/DragnDrop/Components";
import Emulator from "panel/src/Components/DragnDrop/Emulator";
// COMPONENTS END

//REDUX ACTIONS START
import {
  getBannerData,
  resetBannerData,
  discardChanges,
  resetHasChange,
} from "panel/src/redux/actions/bannerSettings";
import {
  saveScreenData,
  resetSaveScreenData,
} from "panel/src/redux/actions/SaveScreen";

//MODELS START
import IGetBannerDataRequest from "panel/src/models/Engage/bannerSettings/IGetBannerDataRequest";
import { IBannerData } from "panel/src/models/Engage/bannerSettings/IGetBannerDataResponse";
import { AppList } from "panel/src/models/getUserInfoResponseModel";

import ISaveScreenModel, {
  ISaveScreenComponent,
} from "panel/src/models/SaveScreenModel";
import {
  IColorData,
  ColorList,
} from "../../../models/Design/colors/IGetColorsPageDataResponse";
//MODELS END
import Modal from "panel/src/_components/common/Modal";
import { PageType } from "panel/src/utils/enums/pageType";
import { ComponentType } from "panel/src/utils/enums/componentType";
import close from "panel/src/assets/images/close.svg";
import tick from "panel/src/assets/images/tick.svg";
import { getComponent } from "./componentHandler";

interface IBannerSettings {
  appId: string;
  platformId: number;
  userId: string;
  bannerData: IBannerData["data"]["ResultData"];
  categories: { label: string; value: string }[];
  saveScreenRes: boolean | number;
  _getHomeData: Function;
  _resetHomeData: Function;
  _saveScreenData: Function;
  _resetSaveScreenData: Function;
  getComponent: Function;
  hasChange: boolean;
  bannerChange: boolean;
  discardChanges: Function;
  colors: any;
  fonts: any;
  _resetChanges: Function;
}

export const requiredEditForms = [
  ComponentType.SmallBanner,
  ComponentType.MediumBanner,
  ComponentType.LargeBanner,
];

const BannerSettings = ({
  appId,
  platformId,
  userId,
  bannerData,
  categories,
  _getHomeData,
  _resetHomeData,
  _saveScreenData,
  _resetSaveScreenData,
  saveScreenRes,
  hasChange,
  discardChanges,
  bannerChange,
  colors,
  fonts,
  _resetChanges,
}: IBannerSettings) => {
  useEffect(() => {
    getScreenComponentsData();
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [isShowConfirmModal, setShowConfirm] = useState(false);
  const [update, setUpdate] = useState(false);

  const [selectedComponents, setSelectedComponents] = useState<
    (IComponent | undefined)[]
  >([]);

  const [componentsData, setComponentsData] = useState<
    IBannerData["data"]["ResultData"]["Components"] | undefined
  >(undefined);

  const [selectedColorList, setSelectedColorList] = useState<ColorList[]>([]);
  const [selectedFont, setSelectedFont] = useState("");

  useEffect(() => {
    if (bannerData) {
      const colorList = colors.selectedColorModel
        ? colors.selectedColorModel.colorList
        : [];

      setSelectedColorList(colorList);
      const selectedFontFamily = fonts.find((opt: any) => opt.IsUsed);
      const selectedFontName = selectedFontFamily
        ? selectedFontFamily.Name
        : "Helvetica Neue";
      setSelectedFont(selectedFontName);

      const componentsData = bannerData.Components;
      setComponentsData(componentsData);
    }
  }, [bannerData]);

  useEffect(() => {
    if (componentsData) {
      const selectedComponentsList = bannerData.SelectedComponents.map(
        (obj) => {
          return getComponent(
            selectedFont,
            selectedColorList,
            obj.ComponentId,
            JSON.parse(obj.ResponseJSON),
            categories
          );
        }
      ).filter((obj) => obj !== undefined);

      setSelectedComponents(selectedComponentsList);
      setIsLoading(false);
    }
  }, [componentsData]);

  useEffect(() => {
    if (saveScreenRes) {
      _resetSaveScreenData();
      getScreenComponentsData();
    }
  }, [saveScreenRes]);

  useEffect(() => {
    return () => {
      _resetHomeData();
      _resetSaveScreenData();
    };
  }, []);

  const getScreenComponentsData = () => {
    const homeComponentsDataReqObj: IGetBannerDataRequest = {
      applicationId: appId,
      userId: userId,
      platformId: platformId,
    };

    _getHomeData(homeComponentsDataReqObj);
  };

  const saveScreenComponents = () => {
    setIsLoading(true);
    const _selectedComponents: ISaveScreenComponent[] = selectedComponents.map(
      (obj, index) => {
        return {
          ComponentId: obj!.componentId,
          PageId: obj!.pageId,
          DisplayOrder: index + 1,
          JSON: obj!.editForm
            ? JSON.stringify(obj!.editForm.editFormData)
            : bannerData.SelectedComponents.map((a: any) => {
                if (a.ComponentId === obj!.componentId) {
                  return a.ResponseJSON;
                }
              })[index],
        };
      }
    );

    if (handleEmptyEditForms(_selectedComponents)) {
      setIsLoading(false);
      return;
    }

    const saveScreenDataReqObj: ISaveScreenModel = {
      ApplicationId: appId,
      PageId: PageType.Home,
      IsFromEngagementPage: true,
      Components: _selectedComponents,
    };
    _saveScreenData(saveScreenDataReqObj);

    setShowConfirm(true);
    setUpdate(true);
  };
  const discardBannerChanges = () => {
    setSelectedComponents([]);

    const selectedComponentsList = bannerData.SelectedComponents.map((obj) => {
      return getComponent(
        selectedFont,
        selectedColorList,
        obj.ComponentId,
        JSON.parse(obj.ResponseJSON),
        categories
      );
    });
    setSelectedComponents(selectedComponentsList);
    discardChanges();
  };

  const handleEmptyEditForms = (
    saveScreenComponents: ISaveScreenComponent[]
  ) => {
    const _saveScreenComponents = saveScreenComponents
      .filter((obj) => obj.JSON === null)
      .filter((obj) => requiredEditForms.includes(obj.ComponentId));

    if (_saveScreenComponents.length) return true;

    return false;
  };

  useEffect(() => {
    if (bannerChange) {
      const _selectedComponents: any = selectedComponents.map((obj, index) => {
        return {
          Id: obj!.Id,
          componentId: obj!.componentId,
          component: obj!.component,
          PageId: obj!.pageId,
          DisplayOrder: index + 1,
          JSON:
            [8, 27, 26, 3].includes(obj!.componentId) === true && obj!.editForm
              ? obj!.editForm.editFormData
              : bannerData.SelectedComponents.map((a: any) => {
                  if (a.ComponentId === obj!.componentId) {
                    return JSON.parse(a.ResponseJSON);
                  }
                })[index],
          ref: obj!.ref,
        };
      });

      const selectedComponentsList = _selectedComponents.map((obj: any) => {
        return getComponent(
          selectedFont,
          selectedColorList,
          obj.componentId,
          obj.JSON,
          categories
        );
      });

      setSelectedComponents(selectedComponentsList);
      _resetChanges();
    }
  }, [bannerChange]);

  return (
    <React.Fragment>
      <EngageNavigation />
      <div className={classes.contentContainer}>
        <div className={classes.EngageSubRouteContainer}>
          <h3 className={classes.Heading}>Banner Settings</h3>
          <p className={classes.Info}>
            You may easily edit the banners shown on the homepage here.
          </p>
          <div className={classes.MobileScreen}>
            <Content>
              {!isLoading ? (
                <Emulator
                  fontFamily={selectedFont}
                  screen="Banner Settings"
                  appScreen={{
                    screen: "Banner Settings",
                    selectedComponents: selectedComponents,
                    setSelectedComponents: setSelectedComponents,
                    requiredEdit: requiredEditForms,
                    editFormPlacement: "left",
                    showOptionsMenu: false,
                    applyOpacity: true,
                  }}
                  navigation={{
                    navigationType:
                      bannerData.Navigation.SelectedNavigationType,
                    navigationBarIcon: bannerData.Navigation.NavigationBarIcon,
                  }}
                />
              ) : null}
            </Content>
          </div>
        </div>
        <div className={classes.ButtonGroup}>
          {hasChange && (
            <div className={classes.DiscardButton}>
              <Button
                width="160px"
                height="40px"
                type="button"
                className="Ghost"
                onClick={() => discardBannerChanges()}
              >
                Discard
              </Button>
            </div>
          )}
          <Button
            width="160px"
            height="40px"
            type="Primary"
            disableButton={!hasChange}
            onClick={() => saveScreenComponents()}
          >
            Save and Update
          </Button>
        </div>
      </div>
      <Modal show={!isLoading && isShowConfirmModal} width="448">
        <div className={classes.Close} onClick={() => setShowConfirm(false)}>
          <img src={close} />
        </div>
        <div className={`${classes.ModalBody} ${classes.ModalBodyConfirm}`}>
          <img
            className={classes.ConfirmIcon}
            src={tick}
            width={48}
            height={48}
          />
          <span className={classes.ModalBodyText}>
            The changes you made are successfully saved and updated in your
            application.
          </span>
        </div>
        <div className={classes.ModalFooter}>
          <div className={classes.ModalButtonContainer}>
            <ConfirmButton
              type="button"
              className="Primary"
              width="192px"
              onClick={() => setShowConfirm(false)}
            >
              OK
            </ConfirmButton>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    platformId: state.userInfo.selectedApp.PlatformId,
    userId: state.userInfo.userId,
    bannerData: state.bannerSettings.data.home,
    categories: state.bannerSettings.data.home?.Categories,
    colors: state.bannerSettings.data.colors,
    fonts: state.bannerSettings.data.fonts,
    hasChange: state.bannerSettings.changes,
    bannerChange: state.bannerSettings.bannerChange,
    saveScreenRes: state.saveScreen.data,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _getHomeData: (reqObject: IGetBannerDataRequest) =>
      dispatch(getBannerData(reqObject)),
    discardChanges: () => dispatch(discardChanges()),
    _resetHomeData: () => dispatch(resetBannerData()),
    _saveScreenData: (reqObject: ISaveScreenModel) =>
      dispatch(saveScreenData(reqObject)),
    _resetSaveScreenData: () => dispatch(resetSaveScreenData()),
    _resetChanges: () => dispatch(resetHasChange()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerSettings);
