import React, { useState } from "react";
import classes from "./completion.module.scss";
import Button from "../../../_components/common/Button";
import WatchVideoIcon from "../../../_components/primitives/WatchVideoIcon";
import { History } from "history";
import WatchVideoModal from "../../../_components/primitives/WatchVideoModal";
import { YOUTUBE_LINKS } from "../../../Components/YoutubeTutorial";
import BackIcon from "../../../Components/DragnDrop/Emulator/BackIcon";

interface ICompletionProps {
    history: History;
    navigateBack: () => void;
}

const Completion = (props: ICompletionProps) => {
    const [isOpen, setOpen] = useState(false);

    const buttonClickHandler = () => {
        props.history.push("/publish/qr");
    };

    const watchVideoClickHandler = () => {
        setOpen(!isOpen);
    };

    return (
        <div className="completion">
            <BackIcon navigateBack={props.navigateBack} relative backTo="Font Type" />
            <div className={classes.Completion}>
                <h2 className={classes.Heading}>Next step: Publish your app</h2>
                <p className={classes.InfoText}>
                    You have successfully designed your app. You may go back and redesign
                    anytime.
                </p>
                <div className={classes.ButtonContainer}>
                    <Button
                        width="192px"
                        className="Ghost"
                        type="button"
                        onClick={watchVideoClickHandler}
                    >
                        <WatchVideoIcon hasMarginRight /> Watch Tutorial
                    </Button>
                    <Button
                        width="208px"
                        type="button"
                        className="Primary"
                        onClick={buttonClickHandler}
                    >
                        Continue
                    </Button>
                </div>
                <WatchVideoModal
                    watchVideoClickHandler={watchVideoClickHandler}
                    show={isOpen}
                    videoUrl={YOUTUBE_LINKS.PUBLISH}
                />
            </div>
        </div>
    );
};

export default Completion;
