import React, { useState, useEffect, useRef } from "react";
import classes from "./select.module.scss";
import Icon from "../../../_components/common/Icon";

interface ISelectProps {
  options: any;
  changeHandler: any;
  value: string;
  placeholder: string;
  field?: any;
  form?: any;
  name?: string;
  isUsedEnable?: boolean;
  label?: string;
  isTrial?: boolean;
  containerMarginBottom?: string;
  fontFamily?: boolean;
}

const Select = (props: ISelectProps) => {
  const selectNode: any = useRef();
  const [isOpen, setOpen] = useState(false);
  const selectedOption = props.options.find(
    (option: any) => option.value.toString() === props.value.toString()
  );

  const selectOption = (option: any) => {
    changeHandler({ target: option });
    setOpen(false);
  };

  const clickOutside = (e: any) => {
    if (selectNode.current && !selectNode.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", clickOutside);

    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);

  const changeHandler = (e: any) => {
    props.changeHandler(e);
    setOpen(false);
  };

  const attachedClasses = [classes.menuActivator];

  const fieldName = props.field && props.field.name;
  const isError =
    props.form && props.form.touched
      ? props.form.touched[fieldName]
      : undefined;

  if (isError) {
    attachedClasses.push(classes.Invalid);
  }

  return (
    <div
      style={{ marginBottom: props.containerMarginBottom }}
      className={classes.HeaderDropdown}
      ref={selectNode}
    >
      <label className={classes.BlockLabel}>{props.label}</label>

      <div
        className={attachedClasses.join(" ")}
        onClick={() => setOpen(!isOpen)}
      >
        {selectedOption ? (
          <span
            style={{
              fontFamily: `${
                props.fontFamily ? props.fontFamily : "Red Hat Display"
              }`,
            }}
            className={`${classes.SelectInput} ${classes.displayValue}`}
          >
            {selectedOption.displayValue}
          </span>
        ) : (
          <span className={classes.Empty}>{props.placeholder}</span>
        )}
        <Icon
          className={`${classes.arrowIcon} ${isOpen && classes.rotateIcon}`}
          type="arrowDown"
          fillColor="#3e5e6e"
          width="16"
          height="16"
        />
      </div>

      {isOpen && (
        <div className={classes.menuWrapper}>
          <ul className={classes.menu}>
            {props.options.map((option: any) => (
              <li key={option.value}>
                <button
                  style={{
                    fontFamily: `${
                      props.fontFamily ? props.fontFamily : "Red Hat Display"
                    }`,
                  }}
                  type="button"
                  className={`${classes.menuBtn} ${
                    selectedOption?.value === option.value && classes.selected
                  }`}
                  onClick={(e) => {
                    props.isUsedEnable && option.isUsed
                      ? e.preventDefault()
                      : selectOption(option);
                  }}
                >
                  <span className={classes.displayValue}>
                    {option.displayValue}
                  </span>
                  <span className={classes.time}>{option.time}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Select;
