import React from "react";
import RadioButton from "../../../../../_components/common/RadioButton";
import infoIcon from "panel/src/assets/images/information.svg";
import classes from "./HybridCheckoutItem.module.scss";

const hybridCheckoutItem = () => {
  return (
    <div className={classes.optionItem}>
      <h5 className={classes.optionItemTitle}>Check-out Option</h5>
      <RadioButton
        label="Hybrid checkout"
        checked={true}
        changeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
          console.log(e)
        }
      />
      <div className={classes.optionItemDescriptionArea}>
        <img src={infoIcon} className={classes.infoIcon} />
        <p className={classes.optionItemDescription}>
          As you selected Hybrid Checkout in the previous step, the below
          options are not available.
        </p>
      </div>
    </div>
  );
};

export default hybridCheckoutItem;
