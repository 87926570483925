import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { dimenHeight } from 'shared-components/src/utils/helper';

export default styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${Platform.OS === 'web' ? '10.4' : dimenHeight(16)}px 0;
`;
