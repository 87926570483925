import React from "react";
import styled from "styled-components/native";
import { ColorListData } from "shared-components/src/models/colorListData";
import { colors } from "shared-components/src/utils/constants";

interface IFieldMedium {
  primary?: string;
  fontFamily: string;
}
interface IHtmlView {
  colorList?: ColorListData[];
  description: string;
  fontFamily: string;
  height: number;
}

const BlockGroup = styled.View`
  width: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const FieldMedium = styled.Text<IFieldMedium>`
  font-size: 10.1px;
  font-family: ${(props) => props.fontFamily};
  font-weight: 500;
  align-items: center;
  color: ${(props) => props.primary};
  overflow: hidden;
`;

const HtmlView = ({
  description,
  colorList,
  fontFamily,
  height,
}: IHtmlView) => (
  <BlockGroup>
    <FieldMedium
      fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
      primary={colorList ? colorList[0].colorCode : colors.darkSlateBlue!}
    >
      <div
        style={{ height }}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </FieldMedium>
  </BlockGroup>
);

export default HtmlView;
