import React from 'react';

const Visible = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <g fill="none" fill-rule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"><path d="M8.518 5.482 5.482 8.518a2.146 2.146 0 1 1 3.036-3.036Z"/><path d="M10.492 3.262C9.442 2.47 8.242 2.038 7 2.038c-2.118 0-4.092 1.248-5.466 3.408-.54.846-.54 2.268 0 3.114a8.596 8.596 0 0 0 1.626 1.902M4.852 11.518c.684.288 1.41.444 2.148.444 2.118 0 4.092-1.248 5.466-3.408.54-.846.54-2.268 0-3.114a9.733 9.733 0 0 0-.636-.882"/>
        <path d="M9.106 7.42a2.139 2.139 0 0 1-1.692 1.692M5.482 8.518 1 13M13 1 8.518 5.482"/>
      </g>
    </svg>
  );
};

export default Visible;
