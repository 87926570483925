import styled from "styled-components";

interface IComponentsListContainer {
  disableEvents?: boolean;
}

export default styled.div<IComponentsListContainer>`
  padding: 0 16px 16px 16px;
  overflow-y: ${(props) => (props.disableEvents ? "hidden" : "auto")};
  height: 100%;
  ${(props) => props.disableEvents && "pointer-events: none;"}

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
