import styled from "styled-components";

interface IImageContainer {
  height: string;
}

export default styled.img<IImageContainer>`
  object-fit: cover;
  height: ${(props) => props.height}px;
  width: 100%;
`;
