import React from "react";

interface IProps {
    width: string;
    height: string;
    fillColor: string;
    type: string;
}

const Sort = ({ width, height, fillColor }: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
        <path fill={fillColor} fillRule="evenodd" d="M11.333 1.333c.34 0 .62.364.662.833l.005.12v11.428c0 .136-.02.265-.056.381l3.05-2.629c.254-.219.638-.19.858.063.195.225.195.553.014.777l-.077.079-3.999 3.446c-.233.2-.566.223-.822.066l-.093-.067-3.998-3.445c-.254-.22-.282-.603-.062-.856.195-.226.52-.273.77-.126l.088.063 3.05 2.63c-.025-.078-.041-.162-.05-.249l-.006-.133V2.286c0-.526.298-.953.666-.953zm-6.3-1.23l.092.068 3.998 3.456c.254.22.282.604.062.858-.195.226-.52.273-.77.127l-.088-.064L5.27 1.904c.027.085.046.177.056.274l.007.148v11.348c0 .548-.298.993-.666.993-.34 0-.62-.38-.662-.869L4 13.674V2.326c0-.151.023-.295.064-.423L1.007 4.548c-.255.22-.639.191-.859-.063-.195-.226-.195-.555-.014-.78l.077-.078L4.207.172c.234-.204.57-.226.826-.069z" />
    </svg>

);

export default Sort;
