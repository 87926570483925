import React from 'react';

const Handler = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16">
      <g fill="currentColor" fill-rule="evenodd" stroke="currentColor">
        <path d="M1.357 2a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM7.357 2a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM1.357 8a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM7.357 8a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM1.357 14a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM7.357 14a1 1 0 1 0 2 0 1 1 0 0 0-2 0Z"/>
      </g>
    </svg>
  );
};

export default Handler;
