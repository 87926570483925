import React, { Component } from "react";
import * as H from "history";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cmsActions from "panel/src/redux/actions/cmsKeys";
import { GetCmsKeyValuesResponseModel } from "panel/src/models/Manage/getCmsKeyValuesResponseModel";
import { ContentTableDataModel } from "panel/src/models/Manage/contentTableDataModel";
import EngageNavigation from "panel/src/_components/primitives/EngageNavigation";
import EditContentLanguageContent from "./EditContentLanguageContent";

interface MatchParams {
  rLanguageId: number;
  rLanguageName: string;
  cLanguageId: number;
  cLanguageName: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  cmsActions: any;
  appId: string;
  cmsKeyValues: GetCmsKeyValuesResponseModel["ResultData"];
}

// from typings
interface RouteComponentProps<P> {
  match: match<P>;
  location: H.Location;
  history: H.History;
  staticContext?: any;
}

interface match<P> {
  params: P;
  isExact: boolean;
  path: string;
  url: string;
}

interface IState {
  languageContents: ContentTableDataModel[];
  hasChange: boolean;
  isShowDiscardModal: boolean;
  isShowConfirmModal: boolean;
  totalPageCount: number;
  pageIndex: number;
  searchText: string;
}

class EditContentLanguage extends Component<IProps, IState> {
  contentTable: any;
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const { match } = this.props;
    return (
      <React.Fragment>
        <EngageNavigation />
        <EditContentLanguageContent
          cLanguageId={match.params.cLanguageId}
          cLanguageName={match.params.cLanguageName}
          rLanguageName={match.params.rLanguageName}
          rLanguageId={match.params.rLanguageId}
          onPressGoBack={() => this.props.history?.goBack()}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    cmsActions: bindActionCreators(cmsActions, dispatch),
  };
};

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    cmsKeys: state.cmsKeys.cmsKeys,
    cmsKeyValues: state.cmsKeys.cmsKeyValues,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditContentLanguage);
