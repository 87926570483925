import React from "react";
import { FieldProps, getIn } from "formik";
import classes from "./textField.module.scss";
import ErrorField from "../ErrorField";

interface IProps extends FieldProps {
    placeholder: string;
}

const TextFiled = ({ field, form: { errors }, placeholder }: IProps) => {
    const errorMessage = getIn(errors, field.name);

    return (
        <div className={classes.Container}>
            <label className={classes.BlockLabel}>{}</label>
            <input className={`${classes.BlockInput} ${errorMessage ? classes.Invalid : classes.Valid}`}
                {...field}
                placeholder={placeholder} />
            {errorMessage && <ErrorField text={errorMessage} />}
        </div>
    );
};

export default TextFiled;

