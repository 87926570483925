import styled from 'styled-components/native';

export default styled.View`
  position: absolute;
  flex-direction: row;
  right: 0;
  bottom: 10px;
  left: 0;
  justify-content: center;
`;
