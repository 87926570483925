import {
  GET_NAVIGATION_MENU,
  GET_NAVIGATION_MENU_SUCCESS,
  GET_NAVIGATION_MENU_FAILED,
  UPDATE_NAVIGATION_MENU,
  UPDATE_NAVIGATION_MENU_SUCCESS,
  UPDATE_NAVIGATION_MENU_FAILED,
  SET_NAVIGATION_MENU
} from "panel/src/redux/constants/navigationMenu";

const initialState = {
  isLoading: false,
  isLoadedOnce: false,
  MenuItems: [],
  NameAlignment: '',
};

const navigationMenuReducer = (state: Object = initialState, action: any) => {
  switch (action.type) {
    case GET_NAVIGATION_MENU:
    case UPDATE_NAVIGATION_MENU:
      return { ...state, isLoading: true };
    case GET_NAVIGATION_MENU_SUCCESS:
      return { ...state, ...action.data, isLoading: false, isLoadedOnce: true };
    case SET_NAVIGATION_MENU:
      return { ...state, ...action.data, isLoading: false };
    case GET_NAVIGATION_MENU_FAILED:
      return { ...state, isLoading: false, MenuItems: [] };
    case UPDATE_NAVIGATION_MENU_SUCCESS:
    case UPDATE_NAVIGATION_MENU_FAILED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}

export default navigationMenuReducer;