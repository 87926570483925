import { toast } from "react-toastify";
import { history } from "../redux/store";

const error = (message: string, redirect?: string) => {
  toast.error(message, {
    position: "top-center",
    autoClose: false,
    hideProgressBar: false,
    pauseOnHover: true,
    progress: undefined,
  });

  if (redirect) {
    history.push(redirect);
  }
};

const success = (message: string, redirect?: string) => {
  toast.success(message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: true,
    progress: undefined,
  });

  if (redirect) {
    history.push(redirect);
  }
};

export default {
  error,
  success,
};
