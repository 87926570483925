import React, { useEffect, useRef, useState } from "react";
import { Form, Field, Formik, FieldArray, FormikProps } from "formik";
import { connect } from "react-redux";
import Input from "panel/src/Components/Input";
import Dropdown from "panel/src/Components/Dropdown";
import Button from "panel/src/Components/Button";
import ImageResizeType, {
  TImageResizeType,
  TResizeTypeValue,
} from "panel/src/Components/ImageResizeType";
import Separator from "panel/src/Components/Separator";
import DynamicToolsBar from "panel/src/Components/DynamicToolsBar";
import FormikErrorFieldFocus from "panel/src/Components/FormikErrorFieldFocus";
import ErrorField from "panel/src/_components/common/ErrorField";
import { componentChange } from "panel/src/redux/actions/Home";
import { usePrevious, flattenObject } from "panel/src/utils/helper";
import validationSchema from "./validationSchema";

interface ICategorySlider {
  hideComponentEdit?: Function;
  editFormClickedOutside?: number;
  setComponentFormData?: Function;
  setImageResizeType?: Function;
  Id?: string;
  formData?: ICategorySliderFormData;
  rawFormData?: any;
  categories: { label: string; value: string }[];
  setInfoPopup?: Function;
  componentChange: Function;
  appId: string;
  index?: Number;
}

type ICategorySliderFormData = {
  ComponentTitle: string;
  Items: { CategoryId: string; Title: string }[];
  isEdited: boolean;
  imageResizeType: TResizeTypeValue;
};

const CategorySlider = ({
  hideComponentEdit,
  editFormClickedOutside,
  setComponentFormData,
  setImageResizeType,
  setInfoPopup,
  Id,
  formData,
  rawFormData,
  categories,
  componentChange,
  appId,
  index,
}: ICategorySlider) => {
  const formikRef = useRef<FormikProps<ICategorySliderFormData>>(null);
  const [isSaveRequired, setIsSaveRequired] = useState(false);

  const prevProps = usePrevious({ formData });
  useEffect(() => {
    if (prevProps && formData) {
      if (prevProps.formData !== formData) {
        const componentObj = {
          applicationId: appId,
          componentId: 7,
          formData: JSON.stringify(formData),
          index: index,
        };
        componentChange(componentObj);
      }
    }
  });

  useEffect(() => {
    if (editFormClickedOutside !== 0) {
      formikRef.current?.validateForm().then(() => {
        const isFormValid = formikRef.current?.isValid;

        if (isFormValid && !formData!.ComponentTitle) {
          setIsSaveRequired(true);
          const errorElement = document.querySelector(
            "[id='save-changes-error']"
          );
          if (errorElement) {
            errorElement.scrollIntoView({ behavior: "smooth" });
          }
        } else if (isFormValid || formData!.ComponentTitle) {
          hideComponentEdit && hideComponentEdit(Id, !formData!.ComponentTitle);
        } else {
          const errorKeys = flattenObject(formikRef.current?.errors);
          Object.keys(errorKeys).forEach((key: any) => {
            formikRef.current?.setFieldTouched(key);
          });
        }
      });
    }
  }, [editFormClickedOutside]);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={formData!}
      onSubmit={(values) => {
        setComponentFormData && setComponentFormData(Id, values);
        setInfoPopup && setInfoPopup(true);
      }}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, setFieldValue, errors, touched }) => {
        return (
          <Form>
            <Field
              id="ComponentTitle"
              name={"ComponentTitle"}
              style={{ marginBottom: "1rem" }}
              label="Component Title"
              placeholder="Title"
              value={values.ComponentTitle}
              handleError={errors}
              handleTouched={touched}
              onChangeHandler={handleChange}
              component={Input}
            />
            <FieldArray name="Items">
              {({ push, remove }) => (
                <React.Fragment>
                  {values.Items.map((formValue, index) => (
                    <React.Fragment key={index}>
                      <Field
                        id={`Items[${index}].CategoryId`}
                        name="category"
                        style={{ marginBottom: "1rem" }}
                        label="Categories"
                        placeholder="Select Category"
                        options={categories}
                        value={formValue.CategoryId}
                        onChangeHandler={handleChange}
                        handleTouched={touched}
                        component={Dropdown}
                      />
                      <Field
                        id={`Items[${index}].Title`}
                        label="Category Title"
                        placeholder="Title"
                        handleError={errors}
                        handleTouched={touched}
                        value={formValue.Title}
                        onChangeHandler={handleChange}
                        component={Input}
                      />
                      {values.Items.length > 1 &&
                        values.Items.length - 1 !== index && <Separator />}
                    </React.Fragment>
                  ))}
                  <DynamicToolsBar
                    push={() => {
                      push(values.Items[0]);
                    }}
                    remove={() => remove(values.Items.length - 1)}
                    isAddable
                    isRemovable={values.Items.length > 1}
                  />
                  <Field
                    id="imageResizeType"
                    name="imageResizeType"
                    style={{ marginTop: 16 }}
                    resizeTypeValue={values.imageResizeType}
                    typeOnClickHandler={(item: TImageResizeType) => {
                      setFieldValue(`imageResizeType`, item.mode);
                      const editedFormData = {
                        ...rawFormData,
                        imageResizeType: item.mode,
                      };
                      setImageResizeType &&
                        setImageResizeType(Id, editedFormData);
                    }}
                    component={ImageResizeType}
                  />
                  <FormikErrorFieldFocus />
                  <Button
                    id="apply-button"
                    type="submit"
                    style={{ marginTop: "1.5rem" }}
                    buttonText="Apply"
                  />
                  {isSaveRequired && (
                    <ErrorField
                      id="save-changes-error"
                      text="Please save the changes"
                    />
                  )}
                  <Button
                    id="cancel-button"
                    type="button"
                    style={{ marginTop: "0.5rem" }}
                    theme="white"
                    buttonText={!formData!.ComponentTitle ? "Delete" : "Cancel"}
                    onClick={() =>
                      hideComponentEdit &&
                      hideComponentEdit(Id, !formData!.ComponentTitle)
                    }
                  />
                </React.Fragment>
              )}
            </FieldArray>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    hasChange: state.bannerSettings.changes,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    componentChange: (val: any) => dispatch(componentChange(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategorySlider);
