import React from 'react';

const Edit = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
      <path fill="currentColor" fill-rule="evenodd" d="M6.34.238a.813.813 0 0 1 1.15 0L9.762 2.51a.813.813 0 0 1 0 1.15L8.367 5.058a.41.41 0 0 1-.152.152L3.661 9.762a.814.814 0 0 1-.033.03.415.415 0 0 1-.429.202L3.086 10H.813A.813.813 0 0 1 0 9.187V6.915c0-.036.002-.072.007-.107L0 6.73a.414.414 0 0 1 .209-.36l.03-.032L4.79 1.784a.41.41 0 0 1 .152-.151ZM.812 7.716v1.47h1.47l-1.47-1.47Zm4.34-5.142L.999 6.728 3.271 9l4.154-4.154-2.272-2.272ZM6.914.814 5.732 1.994l2.272 2.272 1.182-1.182L6.914.813Z"/>
    </svg>
  );
};

export default Edit;
