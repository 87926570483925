import React, { useRef, useEffect, useState } from "react";
import Wrapper from "./Wrapper";
import Container from "./Container";
import ImageInput from "./ImageInput";
import Span from "./Span";
import ImageContainer from "./ImageContainer";
import ChangeImageContainer from "./ChangeImageContainer";
import ErrorMessage from "./ErrorMessage";
import Icon from "../../_components/common/Icon";
import FormikError from "../FormikError";
// Helper
interface IImageUpload {
  id: string;
  style?: object;
  height: string;
  editFormHeight: string;
  defaultValue: {
    height: number;
    img: string;
    supportedHeight: number;
    type: string;
    width: number;
  };
  onChangeHandler: Function;
  handleError?: any;
  handleTouched?: any;
}

const ImageUpload = ({
  id,
  style,
  height,
  editFormHeight,
  defaultValue,
  onChangeHandler,
  handleError,
  handleTouched,
}: IImageUpload) => {
  const errorMessage = FormikError(handleError, handleTouched, id);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const imageContainer = useRef<HTMLImageElement>(null);
  const [showClickableContainer, setShowClickableContainer] = useState(false);
  const [currentImg, setCurrentImg] = useState<string | ArrayBuffer>(
    defaultValue.img
  );
  const [imageObject, setImageObject] = useState<File>();
  const handleImageUpload = () => {
    imageInputRef.current!.click();
  };

  const imageOnLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    onChangeHandler({
      target: {
        name: id,
        value: {
          img: e.currentTarget.src,
          type: getImageMime(e.currentTarget.src),
          height: imageContainer.current!.naturalHeight,
          width: imageContainer.current!.naturalWidth,
          supportedHeight: parseInt(height),
        },
      },
    });
  };

  const getImageMime = (img: string) => {
    if (imageObject) return imageObject.type;
    try {
      let base64ContentArray = img.split(",");
      return base64ContentArray[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)![0];
    } catch (error) {
      let extension = img.split(".").pop();
      return extension;
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const img = e.target.files![0];
    if (img === undefined) return;
    let reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onloadend = () => {
      if (reader.result) {
        setCurrentImg(reader.result);
        setImageObject(img);
      }
    };
  };

  const wrapperOnMouseEnter = () => {
    if (currentImg === "") return;
    setShowClickableContainer(true);
  };

  const wrapperOnMouseLeave = () => {
    if (currentImg === "") return;
    setShowClickableContainer(false);
  };

  return (
    <Wrapper
      style={style}
      onMouseEnter={() => wrapperOnMouseEnter()}
      onMouseLeave={() => wrapperOnMouseLeave()}
    >
      {currentImg === "" ? (
        <Container height={height} onClick={() => handleImageUpload()}>
          <Span>Add image</Span>
          <Icon type="addIcon" fillColor="#9fafb6" width="16" height="16" />
        </Container>
      ) : (
        <React.Fragment>
          <ImageContainer
            id={id}
            ref={imageContainer}
            height={editFormHeight}
            onLoad={(e) => imageOnLoad(e)}
            src={currentImg as string}
            alt="selected-img"
          />
          {showClickableContainer && (
            <ChangeImageContainer
              // height={height}
              onClick={() => handleImageUpload()}
            >
              <Span isChange>Change image</Span>
              <Icon type="addIcon" fillColor="#fff" width="16" height="16" />
            </ChangeImageContainer>
          )}
        </React.Fragment>
      )}
      {handleError && handleTouched && (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      )}
      <ImageInput
        ref={imageInputRef}
        onChange={(event) => handleImageChange(event)}
      />
    </Wrapper>
  );
};

export default ImageUpload;
