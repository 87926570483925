import React, { useState, useEffect, useRef } from "react";
import SelectModel from "../../../models/Design/selectModel";
import classes from "./screenSelection.module.scss";
import Icon from "panel/src/_components/common/Icon";
import WatchVideoModal from "../../../_components/primitives/WatchVideoModal";
import { YOUTUBE_LINKS } from "../../../Components/YoutubeTutorial";
interface ISelectionProps {
  options: SelectModel[];
  selectedValue: string;
  selectChangeHandler: Function;
}

const ScreenSelection = (props: ISelectionProps) => {
  const selectNode: any = useRef();
  const [isOpen, setOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const toggleShowTutorial = () => {
        setShowModal(prevShowModal => !prevShowModal);
    }
  const selectedOption = props.options.find(
    (option: any) => option.value === props.selectedValue
  );

  const selectOption = (option: any) => {
    changeHandler({ target: option });
    setOpen(false);
  };

  const clickOutside = (e: any) => {
    if (selectNode.current && !selectNode.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", clickOutside);

    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);

  const changeHandler = (e: any) => {
    props.selectChangeHandler(e);
    setOpen(false);
  };

  const attachedClasses = [classes.menuActivator];
  return (
    <React.Fragment>
      <div className={classes.DesignConfigContainer}>
        <p className={classes.Label}>Notification options</p>
        <div className={classes.HeaderDropdown} ref={selectNode}>
          <div
              className={attachedClasses.join(" ")}
              onClick={() => setOpen(!isOpen)}
          >
            {selectedOption ? (
                <span className={`${classes.SelectInput}`}>
        {selectedOption.displayValue}
      </span>
            ) : (
                <span className={classes.empty}></span>
            )}

            <div className={classes.RightSection}>
      <span
          className={classes.tutorialButton}
          onClick={toggleShowTutorial}
          style={{ cursor: "pointer", marginRight: "10px" }}
      >
        <Icon
            className=""
            type="watchVideo"
            fillColor="#e66565"
            width="16"
            height="16"
        />
      </span>

              <Icon
                  className={`${classes.arrowIcon} ${
                      isOpen && classes.rotateIcon
                  }`}
                  type="arrowDown"
                  fillColor="#3e5e6e"
                  width="16"
                  height="16"
              />
            </div>

              <WatchVideoModal
                  watchVideoClickHandler={toggleShowTutorial}
                  show={showModal}
                  videoUrl={YOUTUBE_LINKS.PUSH_NOTIFICATION}
              />
          </div>

          {isOpen && (
            <div className={classes.menuWrapper}>
              <ul className={classes.menu}>
                {props.options.map((option: any) => (
                  <li key={option.value}>
                    <button
                      type="button"
                      disabled={
                        props.selectedValue === option.value ||
                        option.isDisabled
                      }
                      className={`${classes.menuBtn} ${
                        !option.isComplated || option.isDisabled
                          ? classes.Disabled
                          : ""
                      } ${
                        option.value === props.selectedValue
                          ? classes.ActiveItem
                          : ""
                      }`}
                      onClick={() => {
                        if (option.isComplated) selectOption(option);
                      }}
                    >
                      {option.displayValue}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <hr className={classes.Seperator} />
      </div>
    </React.Fragment>
  );
};

export default ScreenSelection;
