import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import classes from "./welcome.module.scss";
import Button from "../../_components/common/Button";
import WatchVideoIcon from "../../_components/primitives/WatchVideoIcon";
import ManageIcon from "../../_components/primitives/ManageIcon";
import DesignIcon from "../../_components/primitives/DesignIcon";
import EngageIcon from "../../_components/primitives/EngageIcon";
import { IconType } from "../../utils/enums/iconType";
import { History } from "history";
import { connect } from "react-redux";
import * as actions from "panel/src/redux/actions/apps";
import * as modalActions from "panel/src/redux/actions/modal";
import { bindActionCreators } from "redux";
import PublishIcon from "../../_components/primitives/PublishIcon";
import WatchVideoModal from "../../_components/primitives/WatchVideoModal";
import Modal from "panel/src/_components/common/Modal";
import Input from "panel/src/_components/common/Input";
import { createAppStart } from "panel/src/redux/actions/apps";
import { YOUTUBE_LINKS } from "../../Components/YoutubeTutorial";
import Intercom from "../../Components/Intercom/Intercom";

interface IWelcomeProps {
  history: History;
  actions?: any;
  modalActions?: any;
  isExistApp: boolean;
  IsStep1Completed: boolean;
  IsStep2Completed: boolean;
  IsStep3Completed: boolean;
  IsStep4Completed: boolean;
  IsStep5Completed: boolean;
  IsStep6Completed: boolean;
  IsStep7Completed: boolean;
  IsStep8Completed: boolean;
  IsStep9Completed: boolean;
  IsStep10Completed: boolean;
  IsStep11Completed: boolean;
  IsStep13Completed: boolean;
  _createApp: (name: string) => void;
}

interface IWelcomeState {
  showVideoTutorialModal: boolean;
  appName: string;
  isCreateAppModalVisible: boolean;
}

class Welcome extends Component<IWelcomeProps, IWelcomeState> {
  constructor(props: IWelcomeProps) {
    super(props);
    this.state = {
      showVideoTutorialModal: false,
      appName: "",
      isCreateAppModalVisible: false,
    };
  }

  welcomeClickHandler = () => {
    this.props.isExistApp
      ? this.props.IsStep1Completed
        ? this.props.history.push("/manage/config-settings")
        : this.props.history.push("/manage/web-service-control/begin")
      : this.showCreateAppModal();
  };
  designClickHandler = () => {
    this.props.IsStep4Completed && this.props.history.push("/design");
  };
  publishClickHandler = () => {
    this.props.IsStep11Completed && this.props.history.push("/publish");
  };
  engageClickHandler = () => {
    this.props.IsStep13Completed && this.props.history.push("/engage");
  };
  watchVideoClickHandler = () => {
    this.setState({
      showVideoTutorialModal: !this.state.showVideoTutorialModal,
    });
  };

  getInitialValues = () => {
    return {
      appName: this.state.appName,
    };
  };

  showCreateAppModal = () => {
    this.setState({
      isCreateAppModalVisible: true,
    });
  };

  hideCreateAppModal = () => {
    this.setState({
      isCreateAppModalVisible: false,
      appName: "",
    });
  };

  handleCreateApp = (values: any) => {
    this.props._createApp(values.appName);
    this.hideCreateAppModal();
  };

  appNameInputChangeHandler = (e: any) => {
    this.setState({ appName: e.target.value });
  };

  render() {
    return (
      <div className={classes.WelcomeContainer}>
        <Intercom />
        <h1 className={classes.WelcomeHeading}>Welcome to Mowico</h1>
        <p className={classes.WelcomeParagraph}>
          We help you to build the best mobile app for your e-commerce business.
        </p>
        <div className={classes.BoxContainer}>
          <div className={classes.Box} onClick={this.welcomeClickHandler}>
            <ManageIcon iconType={IconType.Active} />
            <h2>1. Connect</h2>
            <p>Integrate your store and set the features. </p>
          </div>
          <div className={classes.Box} onClick={this.designClickHandler}>
            <DesignIcon iconType="active" />
            <h2>2. Design</h2>
            <p>Customize your app without any coding.</p>
          </div>
          <div className={classes.Box} onClick={this.publishClickHandler}>
            <PublishIcon iconType="active" />
            <h2>3. Publish</h2>
            <p>Get your demo apps in less than an hour.</p>
          </div>

          <div className={classes.Box} onClick={this.engageClickHandler}>
            <EngageIcon iconType="active" />
            <h2>4. Manage your app</h2>
            <p>Make instant changes on your app easily.</p>
          </div>
        </div>
        <div className={classes.ButtonContainer}>
          <Button
            type="button"
            className="Ghost"
            width="192px"
            onClick={this.watchVideoClickHandler}
          >
            <WatchVideoIcon hasMarginRight /> Watch tutorial
          </Button>
          <Button
            type="button"
            className="Primary"
            width="192px"
            onClick={this.welcomeClickHandler}
          >
            Let's start
          </Button>
        </div>
        <Modal show={this.state.isCreateAppModalVisible} width="448">
          <Formik
            enableReinitialize={true}
            onSubmit={(values) => this.handleCreateApp(values)}
            initialValues={this.getInitialValues()}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {(formik) => (
              <Form>
                <div className={classes.ModalTitle}>
                  <span className={classes.ModalTitleText}>Project Name</span>
                </div>
                <div className={classes.ModalBody}>
                  <span className={classes.ModalBodyText}>
                    Please give your project a name. You can create as many
                    projects as you wish from scratch.
                  </span>
                  <Field
                    value={this.state.appName}
                    name="appName"
                    elementType="input"
                    placeholder="My Project"
                    type="text"
                    changeHandler={this.appNameInputChangeHandler}
                    component={Input}
                  />
                </div>
                <div className={classes.ModalFooter}>
                  <div className={classes.ButtonContainer}>
                    <Button
                      type="button"
                      className="Ghost"
                      width="192px"
                      onClick={() => this.hideCreateAppModal()}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" width="192px">
                      Create
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        <WatchVideoModal
          watchVideoClickHandler={this.watchVideoClickHandler}
          show={this.state.showVideoTutorialModal}
          videoUrl={YOUTUBE_LINKS.WELCOME}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    _createApp: (name: string) => dispatch(createAppStart({ Name: name })),
  };
};

const mapStateToProps = (state: any) => {
  return {
    isExistApp:
      state.userInfo.apps && state.userInfo.apps.length > 0 ? true : false,
    IsStep1Completed: state.userInfo.selectedApp.IsStep1Completed,
    IsStep2Completed: state.userInfo.selectedApp.IsStep2Completed,
    IsStep3Completed: state.userInfo.selectedApp.IsStep3Completed,
    IsStep4Completed: state.userInfo.selectedApp.IsStep4Completed,
    IsStep5Completed: state.userInfo.selectedApp.IsStep5Completed,
    IsStep6Completed: state.userInfo.selectedApp.IsStep6Completed,
    IsStep7Completed: state.userInfo.selectedApp.IsStep7Completed,
    IsStep8Completed: state.userInfo.selectedApp.IsStep8Completed,
    IsStep9Completed: state.userInfo.selectedApp.IsStep9Completed,
    IsStep10Completed: state.userInfo.selectedApp.IsStep10Completed,
    IsStep11Completed: state.userInfo.selectedApp.IsStep11Completed,
    IsStep13Completed: state.userInfo.selectedApp.IsStep13Completed,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
