import React from "react";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
  type: string;
}

const ShowEye = ({ width, height, fillColor }: IProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0002 13.42C14.5725 13.42 13.4202 14.5723 13.4202 16C13.4202 17.4277 14.5725 18.58 16.0002 18.58C17.4279 18.58 18.5802 17.4277 18.5802 16C18.5802 14.5723 17.4279 13.42 16.0002 13.42ZM11.4202 16C11.4202 13.4677 13.4679 11.42 16.0002 11.42C18.5325 11.42 20.5802 13.4677 20.5802 16C20.5802 18.5323 18.5325 20.58 16.0002 20.58C13.4679 20.58 11.4202 18.5323 11.4202 16Z"
      fill={fillColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.04675 12.8626C8.47131 9.05143 12.0461 6.71997 16.0001 6.71997C19.9543 6.71997 23.5293 9.0517 25.9538 12.8632C26.5305 13.7674 26.7851 14.9115 26.7851 15.995C26.7851 17.0787 26.5304 18.2231 25.9534 19.1274C23.5289 22.9385 19.9541 25.27 16.0001 25.27C12.0459 25.27 8.4709 22.9382 6.04633 19.1267C5.4697 18.2226 5.21509 17.0784 5.21509 15.995C5.21509 14.9112 5.46982 13.7668 6.04675 12.8626ZM16.0001 8.71997C12.8943 8.71997 9.88928 10.5482 7.73385 13.9367L7.73301 13.938C7.41023 14.4437 7.21509 15.189 7.21509 15.995C7.21509 16.801 7.41023 17.5462 7.73301 18.0519L7.73385 18.0532C9.88928 21.4417 12.8943 23.27 16.0001 23.27C19.1059 23.27 22.1109 21.4417 24.2663 18.0532L24.2672 18.0519C24.5899 17.5462 24.7851 16.801 24.7851 15.995C24.7851 15.189 24.5899 14.4437 24.2672 13.938L24.2663 13.9367C22.1109 10.5482 19.1059 8.71997 16.0001 8.71997Z"
      fill={fillColor}
    />
  </svg>
);

export default ShowEye;
