export const screenNumbers = {
  generalSettings: "1",
  splashScreen: "2",
  navigationType: "3",
  categoryLayout: "4",
  home: "5",
  productList: "6",
  productDetail: "7",
  color: "8",
  fontType: "9",
};
