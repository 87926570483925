import React, { ChangeEventHandler } from "react";
import { Field } from "formik";
import classes from "./toggleSwitch.module.scss";
import CheckBox from "../Checkbox";
interface IToggleSwitchProps {
  checked: boolean;
  label: string;
  changeHandler?: ChangeEventHandler;
  type?: any;
  name?: string;
  labelMarginLeft?: string;
  marginBottom?: string;
  disabled?: boolean;
}

const toggleSwitch = (props: IToggleSwitchProps) => {
  const spanClasses = [classes.Slider, classes.Round];
  return (
    <div className={classes.Container}>
      <Field
        changeHandler={props.changeHandler}
        checked={props.checked}
        label={props.label}
        name={props.name}
        component={CheckBox}
        labelMarginLeft={props.labelMarginLeft}
        marginBottom={props.marginBottom}
        disabled={props.disabled}
      />
    </div>
  );
};

export default toggleSwitch;
