import {
  SHOW_COMPONENTS_MENU_INFO_CARD,
  HIDE_COMPONENTS_MENU_INFO_CARD,
  SHOW_TOGGLE_OS_INFO_CARD,
  HIDE_TOGGLE_OS_INFO_CARD,
  RESET_INFO_CARD,
} from "panel/src/redux/constants";

const initialState = {
  componentsMenuInfoCard: false,
  toggleOSInfoCardVisible: false,
};

const componentsMenuReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SHOW_COMPONENTS_MENU_INFO_CARD:
      return {
        ...state,
        componentsMenuInfoCard: true,
      };
    case HIDE_COMPONENTS_MENU_INFO_CARD:
      return {
        ...state,
        componentsMenuInfoCard: false,
        toggleOSInfoCardVisible: true,
      };
    case SHOW_TOGGLE_OS_INFO_CARD:
      return {
        ...state,
        toggleOSInfoCardVisible: true,
      };
    case HIDE_TOGGLE_OS_INFO_CARD:
      return {
        ...state,
        toggleOSInfoCardVisible: false,
      };
    case RESET_INFO_CARD:
      return initialState;
    default:
      return state;
  }
};

export default componentsMenuReducer;
