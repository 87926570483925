import React, { Component } from "react";
import { connect } from "react-redux";
import { History } from "history";
import { bindActionCreators } from "redux";
import classes from "./configSettings.module.scss";
import Button from "../../../_components/common/Button";
import Checkbox from "../../../_components/common/Checkbox";
import RadioButton from "../../../_components/common/RadioButton";
import { updateObject } from "../../../utils/helper";
import * as clientConfigListActions from "panel/src/redux/actions/clientConfigList";
import {
  Config,
  ConfigGroup,
} from "../../../models/Manage/configSettingsResponseModel";
import Modal from "../../../_components/common/Modal";
import close from "../../../assets/images/close.svg";
import tick from "../../../assets/images/tick.svg";
import { AppList } from "../../../models/getUserInfoResponseModel";
import EngageNavigation from "../../../_components/primitives/EngageNavigation";
import Tooltip from "panel/src/Components/Tooltip";
import { platformList } from "panel/src/utils/constants";

interface IProps {
  onSetConfigSettingsCompleted: Function;
  startPageLoading: Function;
  finishPageLoading: Function;
  appId: string;
  platformId: string;
  history: History;
  actions?: any;
  configListItems?: ConfigGroup[];
  saveConfigResult?: number;
  loading?: Boolean;
  selectedAppPlatformId: keyof typeof platformList;
}

interface IState {
  ConfigGroups: ConfigGroup[];
  isLoading: boolean;
  isShowConfirmModal: boolean;
  hasChange: boolean;
}

class ConfigSettings extends Component<IProps, IState> {
  state: IState = {
    ConfigGroups: [],
    isLoading: false,
    hasChange: false,
    isShowConfirmModal: false,
  };

  async componentDidMount() {
    const { platformId, appId, actions } = this.props;
    const configListObj = {
      applicationId: appId,
      platformId: platformId,
    };
    actions.clientConfigListStart(configListObj);
  }

  componentWillReceiveProps = (nextProps: IProps, nextState: IState) => {
    if (this.props.configListItems != nextProps.configListItems) {
      this.setState({ ConfigGroups: nextProps.configListItems! });
    }
    if (nextProps.saveConfigResult === 108 && this.state.hasChange) {
      this.setState({ hasChange: false, isShowConfirmModal: true });
    }
  };

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.appId !== this.props.appId) {
      const { platformId, appId, actions } = this.props;
      const configListObj = {
        applicationId: appId,
        platformId: platformId,
      };
      actions.clientConfigListStart(configListObj);
    }
  }

  submitHandler = async () => {
    const { actions } = this.props;
    const data = {
      applicationId: this.props.appId,
      ConfigGroups: this.state.ConfigGroups,
      IsFromEngagementPage: true,
    };

    try {
      await actions.saveAppConfigListStart(data);
    } catch (error) {}
  };

  checkboxChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    configGroupId: string,
    configId: string
  ) => {
    const configGroupIndex = this.state.ConfigGroups.findIndex(
      (configGroup: ConfigGroup) => configGroup.Id === configGroupId
    );
    const configIndex = this.state.ConfigGroups[
      configGroupIndex
    ].Configs.findIndex((config: Config) => config.Id === configId);

    const updatedConfig = updateObject(
      this.state.ConfigGroups[configGroupIndex].Configs[configIndex],
      {
        IsChecked: e.target.checked,
      }
    );

    const updatedConfigs = [
      ...this.state.ConfigGroups[configGroupIndex].Configs,
    ];
    updatedConfigs[configIndex] = updatedConfig;

    const updatedConfigGroup = updateObject(
      this.state.ConfigGroups[configGroupIndex],
      {
        Configs: updatedConfigs,
      }
    );

    const updatedConfigGroups = [...this.state.ConfigGroups];
    updatedConfigGroups[configGroupIndex] = updatedConfigGroup;

    this.setState({ ConfigGroups: updatedConfigGroups, hasChange: true });
  };

  radioChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    configGroupId: string,
    configId: string
  ) => {
    const configGroupIndex = this.state.ConfigGroups.findIndex(
      (configGroup: ConfigGroup) => configGroup.Id === configGroupId
    );
    const configIndex = this.state.ConfigGroups[
      configGroupIndex
    ].Configs.findIndex((config: Config) => config.Id === configId);

    const updatedConfigs = [
      ...this.state.ConfigGroups[configGroupIndex].Configs,
    ];

    updatedConfigs.map((item: Config) => {
      item.IsChecked = false;
    });

    const updatedConfig = updateObject(
      this.state.ConfigGroups[configGroupIndex].Configs[configIndex],
      {
        IsChecked: e.target.checked,
      }
    );

    updatedConfigs[configIndex] = updatedConfig;

    const updatedConfigGroup = updateObject(
      this.state.ConfigGroups[configGroupIndex],
      {
        Configs: updatedConfigs,
      }
    );

    const updatedConfigGroups = [...this.state.ConfigGroups];
    updatedConfigGroups[configGroupIndex] = updatedConfigGroup;

    this.setState({ ConfigGroups: updatedConfigGroups, hasChange: true });
  };

  closeConfirmModal = async () => {
    this.setState({ isShowConfirmModal: false, hasChange: false });
  };
  discardHandler = () => {
    const { platformId, appId, actions } = this.props;
    const configListObj = {
      applicationId: appId,
      platformId: platformId,
    };
    actions.clientConfigListStart(configListObj);

    this.setState({ hasChange: false });
  };

  renderNotSupportedText = () => {
    let initialWords;
    if (this.props.selectedAppPlatformId === 36) {
      initialWords = "Mowico demo commerce platform";
    } else {
      initialWords = `Your e-commerce platform ${
        platformList[this.props.selectedAppPlatformId]
      }`;
    }

    return `${initialWords} does not support these features;`;
  };

  render() {
    const { ConfigGroups } = this.state;

    let formContent = null;
    if (this.state.isLoading) {
      formContent = null;
    } else {
      formContent =
        !!ConfigGroups &&
        ConfigGroups.map((configGroup: ConfigGroup) => {
          let notSupportedConfigs;
          const filteredConfigs = configGroup.Configs.filter(
            (config: Config) => !config.IsHidden
          );

          const configs = filteredConfigs.map(
            (config: Config, index: number) => {
              let input = null;
              if (config.ConfigType.Name === "Checkbox") {
                input = (
                  <div
                    key={index}
                    style={{ marginBottom: 12 }}
                    className={
                      config.Description
                        ? classes.ConfigWrapperWithDescription
                        : classes.ConfigWrapper
                    }
                  >
                    <Checkbox
                      marginBottom='0px'
                      key={`${configGroup.Id}_${config.Id}`}
                      label={config.Name}
                      checked={config.IsChecked}
                      disabled={config.IsDisabled}
                      changeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.checkboxChangeHandler(e, configGroup.Id, config.Id)
                      }
                      isCustom={config.IsCustom}
                      description={config?.Description}
                      containerMarginLeft='15px'
                    />
                    {!config.IsSupported && (
                      <Tooltip text='Your e-commerce platform does not support this feature' />
                    )}
                  </div>
                );
              } else if (config.ConfigType.Name === "RadioButton") {
                input = (
                  <div className={classes.ConfigWrapper} key={index}>
                    <RadioButton
                      key={`${configGroup.Id}_${config.Id}`}
                      label={config.Name}
                      checked={config.IsChecked}
                      disabled={config.IsDisabled}
                      changeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.radioChangeHandler(e, configGroup.Id, config.Id)
                      }
                    />
                    {!config.IsSupported && (
                      <Tooltip text='Your e-commerce platform does not support this feature' />
                    )}
                  </div>
                );
              }

              return input;
            }
          );

          if (configGroup.NotSupportedConfigs !== null) {
            const filteredNotSupportedConfigs =
              configGroup.NotSupportedConfigs.filter(
                (config: any) => !config.IsHidden
              );

            notSupportedConfigs = filteredNotSupportedConfigs.map(
              (config: any, index: number) => {
                let input = null;
                if (config.ConfigType.Name === "Checkbox") {
                  input = (
                    <div
                      key={index}
                      style={{ marginBottom: 12 }}
                      className={
                        config.Description
                          ? classes.ConfigWrapperWithDescription
                          : classes.ConfigWrapper
                      }
                    >
                      <Checkbox
                        marginBottom='0px'
                        key={`${configGroup.Id}_${config.Id}`}
                        label={config.Name}
                        checked={config.IsChecked}
                        disabled={config.IsDisabled}
                        changeHandler={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          this.checkboxChangeHandler(
                            e,
                            configGroup.Id,
                            config.Id
                          )
                        }
                        isCustom={config.IsCustom}
                        description={config?.Description}
                        containerMarginLeft='15px'
                      />
                    </div>
                  );
                } else if (config.ConfigType.Name === "RadioButton") {
                  input = (
                    <div className={classes.ConfigWrapper} key={index}>
                      <RadioButton
                        key={`${configGroup.Id}_${config.Id}`}
                        label={config.Name}
                        checked={config.IsChecked}
                        disabled={config.IsDisabled}
                        changeHandler={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          this.radioChangeHandler(e, configGroup.Id, config.Id)
                        }
                      />
                    </div>
                  );
                }

                return input;
              }
            );
          }

          return (
            <fieldset key={configGroup.Id} className={classes.FieldSet}>
              <legend className={classes.Legend}>{configGroup.Name}</legend>
              <p className={classes.Description}>{configGroup.Description}</p>
              <div className={classes.ConfigContainer}>{configs}</div>
              {notSupportedConfigs !== undefined && (
                <>
                  <p className={classes.NotSupportedConfigDescription}>
                    {this.renderNotSupportedText()}
                  </p>
                  <div className={classes.ConfigContainer}>
                    {notSupportedConfigs}
                  </div>
                </>
              )}
            </fieldset>
          );
        });
    }

    return (
      <React.Fragment>
        <EngageNavigation />
        <div className={classes.contentContainer}>
          <div className={classes.EngageSubRouteContainer}>{formContent}</div>
          <div className={classes.ButtonGroup}>
            <div className={classes.DiscardButton}>
              {this.state.hasChange && (
                <Button
                  width='160px'
                  height='40px'
                  type='button'
                  className='Ghost'
                  onClick={this.discardHandler}
                >
                  Discard
                </Button>
              )}
            </div>
            <Button
              width='160px'
              height='40px'
              type='Primary'
              onClick={this.submitHandler}
              disableButton={!this.state.hasChange}
            >
              Save and Update
            </Button>
          </div>
        </div>
        <Modal
          show={!this.props.loading && this.state.isShowConfirmModal}
          width='448'
        >
          <div className={classes.Close} onClick={this.closeConfirmModal}>
            <img src={close} />
          </div>
          <div className={`${classes.ModalBody} ${classes.ModalBodyConfirm}`}>
            <img
              className={classes.ConfirmIcon}
              src={tick}
              width={48}
              height={48}
            />
            <span className={classes.ModalBodyText}>
              The changes you made are successfully saved and updated in your
              application.
            </span>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type='button'
                className='Primary'
                width='192px'
                onClick={this.closeConfirmModal}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(clientConfigListActions, dispatch),
  };
}

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    platformId:
      state.manageApp.setPlatformObject &&
      state.manageApp.setPlatformObject.platformId,
    configListItems: state.clientConfigList.configList,
    saveConfigResult: state.clientConfigList.saveConfigResult,
    loading: state.modal.isPageLoading,
    selectedAppPlatformId: state.userInfo.selectedApp.PlatformId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigSettings);
