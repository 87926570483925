import axios from "panel/src/services/api/api";
import { LOGOUT_START } from "panel/src/redux/constants/logout";
import { takeLatest, put, call } from "redux-saga/effects";
import * as logoutActions from "panel/src/redux/actions/logout";
import { push } from "react-router-redux";
import * as pageActions from "panel/src/redux/actions/page";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";

function* getLogoutProcess() {
  yield put(startAppLoading("GET_LOGOUT_PROCESS"));
  try {
    yield call(axios.post, "/api/login/logout");

    yield localStorage.removeItem("AUTH_TOKEN");
    yield localStorage.removeItem("AUTH_EXP_DATE");
    delete axios.defaults.headers.common["Authorization"];
    // window.HubSpotConversations.clear({ resetWidget: true });
    yield put(logoutActions.logoutSuccess());
    yield put(pageActions.pageUpdated(false));
    yield put(push("/login"));
  } catch (error) {
    yield put(logoutActions.logoutRejected());
  }
  yield put(endAppLoading("GET_LOGOUT_PROCESS"));
}

export default function* logoutProcessSaga() {
  yield takeLatest(LOGOUT_START, getLogoutProcess);
}
