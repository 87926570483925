import styled from "styled-components";

interface IInput {
  hasError: boolean;
}

export default styled.input<IInput>`
  height: 44px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 24px;
  padding: 12px 24px;
  font-family: Poppins;
  font-size: 14px;
  color: #1d5993;
  font-weight: 500;
  border: 2px solid #98cafa;

  &::placeholder {
    color: #98cafa;
  }
`;
