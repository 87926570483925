import {
  SET_PAYMENT_OPTIONS_START,
  SET_PAYMENT_OPTIONS_SUCCESS,
  SET_PAYMENT_OPTIONS_FAIL,
} from "panel/src/redux/constants/setPaymentOptions";

interface paymentOptionsAction {
  type: string;
  token?: any;
  error?: any;
  loading?: any;
}

export const setPaymentOptionsStart = ( data: any ) => {
  return {
    type: SET_PAYMENT_OPTIONS_START,
    data
  }
};

export const setPaymentOptionsSuccess = () => {
  return {
    type: SET_PAYMENT_OPTIONS_SUCCESS
  }
};

export const setPaymentOptionsRejected = ( error: any ) => {
  return {
    type: SET_PAYMENT_OPTIONS_FAIL,
    error: false,
    loading: false
  }
};
