import React from "react";
import { IconType } from "../../../utils/enums/iconType";

interface IManageProps {
  isSmall?: boolean;
  iconType: string;
}

const ManageIcon = (props: IManageProps) => {
  let width = null;
  let height = null;
  let fillColor = null;

  if (props.isSmall) {
    width = height = "20";
  } else {
    width = height = "32";
  }

  switch (props.iconType) {
    case IconType.Disabled:
      fillColor = "#6D8694";
      break;
    case IconType.Active:
      fillColor = "#3196F6";
      break;
    case IconType.Enabled:
      fillColor = "#FFFFFF";
      break;
    default:
      fillColor = "#6D8694";
      break;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M1.594 13.895H8.51c.843 0 1.516.625 1.588 1.447l.006.147v6.917c0 .843-.625 1.515-1.447 1.588L8.511 24H1.594a1.577 1.577 0 0 1-1.588-1.447L0 22.406V15.49c0-.843.626-1.516 1.447-1.588l.147-.006H8.51zm20.812 0c.86 0 1.594.701 1.594 1.594v6.917C24 23.3 23.299 24 22.406 24H15.49a1.578 1.578 0 0 1-1.594-1.594V15.49c0-.893.701-1.594 1.594-1.594zM8.511 0c.843 0 1.516.654 1.588 1.452l.006.142V8.51c0 .843-.625 1.516-1.447 1.588l-.147.006H1.594A1.577 1.577 0 0 1 .006 8.658L0 8.511V1.594C0 .75.626.079 1.447.006L1.594 0H8.51zm13.895 0c.813 0 1.512.654 1.587 1.452l.007.142V8.51c0 .843-.626 1.516-1.447 1.588l-.147.006H15.49A1.577 1.577 0 0 1 13.9 8.658l-.006-.147V1.594c0-.843.625-1.515 1.447-1.588L15.489 0h6.917zm.011 1.263h-6.94c-.164 0-.281.094-.311.242l-.008.078v6.94c0 .164.094.281.242.311l.078.008h6.94c.164 0 .281-.094.311-.242l.008-.078v-6.94c0-.19-.128-.319-.32-.319z"
      />
    </svg>
  );
};

export default ManageIcon;
