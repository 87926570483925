import { fork } from "redux-saga/effects";
import getHomeDataSaga from "./Home";
import getProductListSaga from "./productList";
import getProductDetailSaga from "./productDetail";
import getBannerSettingsSaga from "./bannerSettings";
import getColorsPageSaga from "./colorsPage";
import getFontTypeSaga from "./fontType";
import { setFontTypeSaga } from "./setFontType";
import loginProcessSaga from "./login";
import logoutProcessSaga from "./logout";
import getUserInfoProcess from "./userInfo";
import platformListProcessSaga from "./platformList";
import { askDemoLinkSaga } from "./askDemo";
import resendDemoLinkSaga from "./resendDemo";
import getComponentJSONSaga from "./getComponentJSON";
import {
  manageAppSaga,
  setAppShopify,
  setAppGoogleMerchant,
  selectChangeAppSaga,
  checkAppInfoSaga,
} from "./manageApp";
import {
  clientConfigListProcessSaga,
  saveAppConfigListProcessSaga,
} from "./clientConfigList";
import paymentOptionsProcessSaga from "./paymentOptions";
import setPaymentOptionsProcessSaga from "./setPaymentOptions";
import {
  getEngagementInfoProcessSaga,
  setEngagementInfoProcessSaga,
} from "./getEngagementInfo";
import {
  getGeneralSettingsSaga,
  setGeneralSettingsSaga,
  getAllLanguageSaga,
  setInsertLanguageSaga,
} from "./generalSettings";
import { getSplashScreenSaga, setSplashScreenSaga } from "./splashScreen";
import { getNavigationTypeSaga, setNavigationTypeSaga } from "./navigationType";
import { getLogoPositionSaga, updateLogoPositionSaga } from "./logoPosition";
import { saveComponentsSaga } from "./saveComponents";
import saveScreenSaga from "./SaveScreen";
import { setColorsSaga } from "./colors";
import { createAppSaga, deleteAppSaga, renameAppSaga } from "./apps";
import {
  getCmsKeysSaga,
  getCmsKeyValuesSaga,
  updateCmsKeyValuesSaga,
} from "./cmsKeys";
import demoInfoSaga from "./demoInfo";
import setInstagramUserNameSaga from "./setInstagramUser";
import { setInstagramTokenSaga } from "./setInstagramToken";
import { forgotPasswordSaga } from "./ForgotPassword";
import { setNewPasswordSaga } from "./SetNewPassword";
import { qrSaga } from "./Qr";
import notificationSaga from "./Notification";
import categorySaga from "./Category";
import {getPackageFeatureProcessSaga} from "./getPackageFeature";
import designSaga from "./Design";
import { getNavigationMenuSaga, updateNavigationMenuSaga } from "./navigationMenu";

export default function* rootSaga() {
  yield fork(getHomeDataSaga);
  yield fork(getFontTypeSaga);
  yield fork(setFontTypeSaga);
  yield fork(getProductListSaga);
  yield fork(getProductDetailSaga);
  yield fork(getBannerSettingsSaga);
  yield fork(getColorsPageSaga);
  yield fork(getComponentJSONSaga);
  yield fork(demoInfoSaga);
  yield fork(manageAppSaga);
  yield fork(loginProcessSaga);
  yield fork(logoutProcessSaga);
  yield fork(getUserInfoProcess);
  yield fork(platformListProcessSaga);
  yield fork(clientConfigListProcessSaga);
  yield fork(getPackageFeatureProcessSaga);
  yield fork(saveAppConfigListProcessSaga);
  yield fork(paymentOptionsProcessSaga);
  yield fork(setPaymentOptionsProcessSaga);
  yield fork(getEngagementInfoProcessSaga);
  yield fork(setEngagementInfoProcessSaga);
  yield fork(getGeneralSettingsSaga);
  yield fork(getAllLanguageSaga);
  yield fork(setInsertLanguageSaga);
  yield fork(setGeneralSettingsSaga);
  yield fork(getSplashScreenSaga);
  yield fork(setSplashScreenSaga);
  yield fork(getNavigationTypeSaga);
  yield fork(setNavigationTypeSaga);
  yield fork(getLogoPositionSaga);
  yield fork(updateLogoPositionSaga);
  yield fork(setAppShopify);
  yield fork(setAppGoogleMerchant);
  yield fork(setColorsSaga);
  yield fork(saveComponentsSaga);
  yield fork(saveScreenSaga);
  yield fork(askDemoLinkSaga);
  yield fork(resendDemoLinkSaga);
  yield fork(createAppSaga);
  yield fork(deleteAppSaga);
  yield fork(renameAppSaga);
  yield fork(getCmsKeysSaga);
  yield fork(getCmsKeyValuesSaga);
  yield fork(updateCmsKeyValuesSaga);
  yield fork(selectChangeAppSaga);
  yield fork(setInstagramUserNameSaga);
  yield fork(setInstagramTokenSaga);
  yield fork(forgotPasswordSaga);
  yield fork(setNewPasswordSaga);
  yield fork(checkAppInfoSaga);
  yield fork(notificationSaga);
  yield fork(categorySaga);
  yield fork(qrSaga);
  yield fork(designSaga);
  yield fork(getNavigationMenuSaga);
  yield fork(updateNavigationMenuSaga);
}
