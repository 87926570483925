import React from "react";
import YoutubeTutorialModal from "panel/src/Components/YoutubeTutorial/Modal";

interface IProps {
  show: boolean;
  watchVideoClickHandler: any;
  videoUrl: string;
}

const WatchVideoModal = (props: IProps) => {
  return (
    <YoutubeTutorialModal
      isModalOpen={props.show}
      setIsModalOpen={props.watchVideoClickHandler}
      youtubeVideUrl={props.videoUrl}
    />
  );
};

export default WatchVideoModal;
