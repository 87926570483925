import React from "react";
import HelpIcon from "panel/src/assets/images/icons-dark-gray-help.svg";

import Popup from "reactjs-popup";
import Card from "./Card";
import IconContainer from "./IconContainer";
import Image from "./Image";
import { PopupPosition } from "reactjs-popup/dist/types";

interface ITooltip {
  text: string;
  position?: PopupPosition;
}

const Tooltip = ({ text, position }: ITooltip) => {
  return (
    <Popup
      trigger={() => (
        <IconContainer>
          <Image src={HelpIcon} />
        </IconContainer>
      )}
      position={position || "bottom center"}
      on={["hover"]}
      arrow
      arrowStyle={{ color: "#3e5e6e", marginTop: "1px" }}
    >
      <Card>{text}</Card>
    </Popup>
  );
};

export default Tooltip;
