import React, { FC, useState } from 'react';
import Button from 'panel/src/Components/Button';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import IconUpload from './IconUpload';
import informationIcon from '../../assets/images/information.svg';

import changePngColor from '../../utils/pngColorHelper';

type IconEditorProps = {
  saveIcon: (iconUrl: string) => void;
  cancel: () => void;
  selectedIconUrl: string;
  itemName: string;
};

const Container = styled.div``;

const Heading = styled.div`
  color: #3e5e6e;
  font-size: 14px;
  font-weight: 700;
`;

const MenuItemName = styled.p`
  font-weight: 500;
  color: #3e5e6e;
  font-size: 14px;
  margin-top: 8px;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  background-color: #f3f5f6;
  border-radius: 4px;
  flex-shrink: 0;
  padding: 2px;
`;

type IconItemProps = {
  isSelected?: boolean;
}

const selectedIconStyles = `
  border-color: #3196f6;
  background-color: rgba(49, 150, 246, 0.08);
`

const IconItem = styled.div<IconItemProps>`
  width: 32px;
  height: 32px;
  border: 1px solid #f3f5f6;
  margin: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  color: #0f3649;
  &:hover {
    ${selectedIconStyles}
  }
  ${(props) => props?.isSelected && selectedIconStyles}
`;

type IconFilterProps = {
  filter?: string;
}

//
// Icons must be black in color and have a transparent background.
// You can use `changePngColor('#0f3649')` helper to get css filters.
//

const iconColorFilter = `invert(15%) sepia(46%) saturate(903%) hue-rotate(156deg) brightness(96%) contrast(92%);`;

const Icon = styled.img<IconFilterProps>`
  width: 24px;
  height: 24px;
  object-fit: contain;
  filter: ${iconColorFilter};
`;

const InfoMessage = styled.div`
  margin-top: 12px;
  color: #3e5e6e;
  font-size: 12px;
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
`;
const Actions = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 16px;
`;

const CancelButton = styled(Button)`
  border: 2px solid #3196f6;
  &:hover {
    border: 2px solid #3196f6;
  }
`;

const IconEditor: FC<IconEditorProps> = ({ saveIcon, cancel, selectedIconUrl, itemName }) => {
  const icons = useSelector((state: any) => state.navigationType?.navigationType?.NavigationBarIconList);
  const [selected, setSelected] = useState(selectedIconUrl);
  const onSelectIcon = (iconUrl: string) => {
    setSelected(iconUrl);
  }

  const onClickSaveButton = () => {
    saveIcon(selected);
  }
  
  return (
    <Container>
      <Heading>Select icon</Heading>
      <MenuItemName>Item name: {itemName}</MenuItemName>
      <IconsContainer>
        {icons?.map((iconUrl: string, idx: number) => (
          <IconItem
            key={idx}
            isSelected={iconUrl === selected}
            onClick={() => onSelectIcon(iconUrl)}
          >
            <Icon src={iconUrl} />
          </IconItem>
        ))}
      </IconsContainer>
      <IconUpload />
      <InfoMessage>
        <img
          src={informationIcon}
          alt="information"
        />
        Icons should be in png format, contain only black color and have a transparent background. Aspect ratio 1:1.
      </InfoMessage>
      <Actions>
        <CancelButton buttonText="Cancel" theme="white" onClick={cancel} />
        <Button buttonText="Save" onClick={onClickSaveButton} />
      </Actions>
    </Container>
  );
};

export default IconEditor;
