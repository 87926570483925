import React, { MouseEventHandler } from "react";
import ReactDOM from "react-dom";
import Button from "panel/src/Components/Button";
import Container from "./Container";
import Modal from "./Modal";
import ContentContainer from "./ContentContainer";
import WarningIcon from "./WarningIcon";
import ContentText from "./ContentText";
import ButtonContainer from "./ButtonContainer";

interface IDialog {
  showModal: boolean;
  backdropClick: MouseEventHandler;
  contentText: string;
  cancelHandler: MouseEventHandler;
  confirmHandler: MouseEventHandler;
}

const Dialog = ({
  showModal,
  contentText,
  cancelHandler,
  confirmHandler,
}: IDialog) => {
  return showModal
    ? ReactDOM.createPortal(
        <Container onClick={cancelHandler}>
          <Modal onClick={(e) => e.stopPropagation()}>
            <ContentContainer>
              <WarningIcon width={48} height={48} />
              <ContentText>{contentText}</ContentText>
            </ContentContainer>
            <ButtonContainer>
              <Button
                buttonText="Cancel"
                theme="whiteWithBlueBorder"
                width="192px"
                onClick={cancelHandler}
              />
              <Button
                buttonText="Remove"
                width="192px"
                onClick={confirmHandler}
              />
            </ButtonContainer>
          </Modal>
        </Container>,
        document.getElementById("root") as Element
      )
    : null;
};

export default Dialog;
