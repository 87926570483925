import React, { FC } from "react";
import Modal from "./Modal";
import Container from "./Container";
import ModalTitle from "./ModalTitle";
import ReactDOM from "react-dom";
import styles from "./helperModal.module.scss";
import Button from "../../_components/common/Button";

import shopifySearch from "../../assets/images/helper-shopify-1.png";
import shopifyChannel from "../../assets/images/helper-shopify-2.png";
import shopifyCopy from "../../assets/images/helper-shopify-3.png";

import woocommerceMenu from "../../assets/images/helper-woocommerce-1.png";
import woocommerceMowico from "../../assets/images/helper-woocommerce-2.png";
import woocommerceCopy from "../../assets/images/helper-woocommerce-3.png";

interface IContactModal {
  isOpenModal: boolean;
  onCloseModal: () => void;
  platformId?: number;
}

interface IModalContent {
  [key: string]: {
    title: string;
    image: {
      src: string;
      width: number;
      borderHide?: boolean;
    }[];
  }[];
}

const modalContent: IModalContent = {
  shopify: [
    {
      title:
        '1. Go to your Shopify admin panel search for "Mowico App Builder" and install it on your sales channels.',
      image: [
        {
          src: shopifySearch,
          width: 381,
        },
      ],
    },
    {
      title: '2. Click "Mowico - Mobile App Builder.',
      image: [
        {
          src: shopifyChannel,
          width: 142,
        },
      ],
    },
    {
      title:
        '3. You’re in "Mowico App key" and then click "Copy" button for the copying.',
      image: [
        {
          src: shopifyCopy,
          width: 381,
        },
      ],
    },
  ],
  woocommerce: [
    {
      title: "1. Go to your Woocommerce admin panel and click Woocommerce.",
      image: [],
    },
    {
      title: "2.  On the settings screen click advanced tab and then rest api.",
      image: [
        {
          src: woocommerceMenu,
          width: 67,
        },
      ],
    },
    {
      title:
        "3. Give an name, select user and select read/write for permissions then click Generate API Key buton.",
      image: [
        {
          src: woocommerceMowico,
          width: 202,
        },
      ],
    },
    {
      title: "4. Copy consumer key and secret key to Mowico Wizard.",
      image: [
        {
          src: woocommerceCopy,
          width: 270,
          borderHide: true,
        },
      ],
    },
  ],
};

const HelperModal: FC<IContactModal> = ({
  isOpenModal,
  onCloseModal,
  platformId,
}) => {
  const getModalContent = (platformId: number | undefined) => {
    return platformId
      ? modalContent[platformId === 23 ? "shopify" : "woocommerce"].map(
          (item, index) => (
            <div key={index}>
              <p className={styles.text}>{item.title}</p>
              {item.image &&
                item.image.map((img, idx) => {
                  return (
                    <img
                      key={`${index}-${idx}`}
                      className={
                        img.borderHide
                          ? `${styles.imageStyle} ${styles.imageBorderNone}`
                          : styles.imageStyle
                      }
                      width={img.width}
                      src={img.src}
                      alt="mowico"
                    />
                  );
                })}
            </div>
          )
        )
      : null;
  };

  return ReactDOM.createPortal(
    <Container display={isOpenModal ? "flex" : "none"}>
      <Modal onClick={(e) => e.stopPropagation()} className={styles.modal}>
        <div className={styles.modalBody}>
          <ModalTitle>How to access your URL key?</ModalTitle>
          {getModalContent(platformId)}
        </div>
        <div className={styles.modalFooter}>
          <Button width="84px" type="Primary" onClick={onCloseModal}>
            Okay
          </Button>
        </div>
      </Modal>
    </Container>,
    document.getElementById("root") as Element
  );
};

export default HelperModal;
