import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Default from "./Default";

interface ISpinner {
  application?: any;
}

/**
 *
 * @param param0
 * @returns  auto popup based on redux data
 */
const SpinnerContainer = ({ application }: ISpinner) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const _isLoading = Object.keys(application).some((serviceState) => {
      return application[serviceState]?.isLoading === true;
    });
    setIsLoading(_isLoading);
  }, [application]);

  return <Default isLoading={isLoading} />;
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application,
  };
};

export default connect(mapStateToProps, null)(SpinnerContainer);
