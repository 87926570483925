import styled from "styled-components";

// interface IChangeImageContainer {
//   height: string;
// }

export default styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgba(15, 54, 73, 0.6);
`;
