import styled from "styled-components";

interface IBrandLogo {
  centered: boolean;
  isDrawer: boolean;
}

export default styled.img<IBrandLogo>`
  height: 18px;
  ${(props) =>
    props.centered && `
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
  `}

  ${(props) => (props.isDrawer && !props.centered) && `
    position: absolute;
    left: 40px;
  `}
`;
