import { Platform } from "react-native";
import styled from "styled-components/native";
import { dimenHeight } from "shared-components/src/utils/helper";

export default styled.View`
  position: relative;
  height: ${Platform.OS === "web" ? "165" : dimenHeight(220)}px;
  width: 100%;
  margin-bottom: 16px;
`;
