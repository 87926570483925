import React, { MouseEventHandler } from "react";
import classes from "./backdrop.module.scss";

interface IBackdropProps {
  show: boolean;
  clickHandler?: MouseEventHandler;
}

const Backdrop = (props: IBackdropProps) => {
  return props.show ? (
    <div className={classes.Backdrop} onClick={props.clickHandler}></div>
  ) : null;
};

export default Backdrop;
