import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Formik, Form, Field } from "formik";
import Input from "panel/src/screens/Login/Input";
import Button from "panel/src/_components/common/Button";
import { forgotPassword } from "panel/src/redux/actions/ForgotPassword";
import logo from "panel/src/assets/images/mobile-logo.svg";
import errorIcon from "panel/src/assets/images/red-warning.svg";
import forgotPasswordValidation from "./validationSchema";
import classes from "./forgotpassword.module.scss";
import useIntercomChat from "../../Components/Intercom/useIntercomChat";

interface IForgotPasswordProps {
  loading: boolean;
  error: string;
  _forgotPassword: Function;
}

const ForgotPassword = ({
  loading,
  error,
  _forgotPassword,
}: IForgotPasswordProps) => {
  useIntercomChat({});
  const history = useHistory();

  const onSubmitForm = (username: string) => {
    _forgotPassword(username);
  };

  return (
    <div className={classes.bg}>
      <img src={logo} className={classes.logo} />
      <div className={classes.formWrapper}>
        <div className={classes.forgotPasswordFormContainer}>
          <div className={classes.title}>Forgot your password?</div>
          <div className={classes.subTitle}>Let's get back you on track.</div>
          <Formik
            enableReinitialize={true}
            initialValues={{ username: "" }}
            validationSchema={forgotPasswordValidation}
            onSubmit={(values) => onSubmitForm(values.username)}
          >
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <Field
                  id="username"
                  name="username"
                  style={{ marginBottom: 40, width: 307 }}
                  component={Input}
                  value={values.username}
                  onChangeHandler={handleChange}
                  label="Email"
                  placeholder="your.name@mail.com"
                  handleError={errors}
                  handleTouched={touched}
                />
                <Button
                  color="Auth"
                  width="256px"
                  height="44px"
                  loadingSpinner={loading}
                  type={loading ? "button" : "submit"}
                  fontSize="14px"
                >
                  Reset Password
                </Button>
                <div className={classes.backContainer}>
                  <span
                    className={classes.hyperLink}
                    onClick={() => history.goBack()}
                  >
                    Back to sign in
                  </span>
                </div>
                {error && (
                  <div className={classes.errorContainer}>
                    <img src={errorIcon} className={classes.errorIcon} />
                    <span className={classes.errorText}>{error}</span>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.forgotPassword.loading,
    error: state.forgotPassword.error,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _forgotPassword: (email: string) => dispatch(forgotPassword(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
