import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Emulator from "panel/src/Components/DragnDrop/Emulator";
import ScreenSelection from "../components/ScreenSelection";
import classes from "./generalSettings.module.scss";
import Input from "../../../_components/common/Input";
import GeneralSettingsFormModel from "../../../models/Design/generalSettings/generalSettingsFormModel";
import informationIcon from "../../../assets/images/information.svg";
import AppIcon from "./components/AppIcon";
import Select from "../../../_components/common/Select";
import SelectModel from "../../../models/Design/selectModel";
import { Field, Formik, Form, FormikProps } from "formik";
import generalValidation from "./validationSchema";
import ErrorField from "../../../_components/common/ErrorField";
import * as generalActions from "panel/src/redux/actions/generalSettings";
import AndroidIcon from "../../../_components/primitives/AndroidIcon";
import IOSIcon from "../../../_components/primitives/IOSIcon";
import { devices } from "../../../utils/constants";
import { IconType } from "../../../utils/enums/iconType";
import {
  fileToBase64,
  getFileExtension,
  resizeImage,
} from "../../../utils/helper";
import { validURL } from "../../../utils/regex";
import { GetGeneralSettingsRequestModel } from "../../../models/Design/generalSettings/getGeneralSettingsRequestModel";
import { DeviceType } from "../../../utils/enums/deviceType";
import {
  GetGeneralSettingsResponseModel,
  Language,
} from "../../../models/Design/generalSettings/getGeneralSettingsResponse";
import { DeviceModel } from "../../../models/Design/deviceModel";
import BlockingForm from "../../../_components/common/BlockingForm";
import { ScreenType } from "../../../utils/enums/screenType";
import { screenNumbers } from "../constants";
import { setChangedSthDesignPage } from "panel/src/redux/actions/manageApp";
import { appIcon } from "../../../utils/initialImages";
import Button from "../../../_components/common/Button";
import EditContentLanguageContent from "../../Engage/ContentSettings/EditContent/EditContentLanguage/EditContentLanguageContent";
import WarningModal from "../../../_components/common/WarningModal";

interface IProps {
  changeHandler: Function;
  generalSettings?: GetGeneralSettingsResponseModel["ResultData"];
  appId?: string;
  actions?: any;
  selectChange?: any;
  deviceType: DeviceType;
  deviceTypeHandler: Function;
  isCompletion: boolean;
  selectedValue: string;
  designScreens: SelectModel[];
  designScreensChange: Function;
  history: any;
  isOnModalSubmit: boolean;
  _setChangedSthDesign: Function;
  isModalSubmitted: boolean;
  packageFeatures: any;
}

interface IState {
  form: GeneralSettingsFormModel;
  options: SelectModel[];
  iosIcon: any;
  androidIcon: any;
  iosIconExtension: string;
  androidIconExtension: string;
  logo: any;
  selectedValue: string;
  selectedLanguageName: string;
  appName: string;
  navigationLogo: any;
  activeIndex: string;
  devices: DeviceModel[];
  navigationBarIconFileExtension: string;
  iosIconWidth: number;
  iosIconHeight: number;
  androidIconWidth: number;
  androidIconHeight: number;
  isBlocking: boolean;
  editLanguage: boolean;
  errorVisible: boolean;
}

class GeneralSettings extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      form: {
        appname: {
          label: "App Name",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Mowico App",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
        },
      },
      options: [],
      iosIcon: appIcon,
      androidIcon: appIcon,
      logo: "",
      selectedValue: "",
      selectedLanguageName: "",
      appName: "",
      navigationLogo: "",
      activeIndex: DeviceType.IOS,
      devices: devices,
      navigationBarIconFileExtension: "",
      iosIconExtension: "image/png",
      androidIconExtension: "image/png",
      iosIconWidth: 512,
      iosIconHeight: 512,
      androidIconWidth: 512,
      androidIconHeight: 512,
      isBlocking: false,
      editLanguage: false,
      errorVisible: false,
    };
  }
  formikRef: FormikProps<any> | null = null;

  packageFeaturesLimited =
    (this.props.packageFeatures?.find((s: any) => s.Id === 1)?.MaxCount || -1) >
    -1;

  componentDidMount = async () => {
    this.state.devices.map((item) => {
      item.type = IconType.Disabled;
      if (item.index === DeviceType.IOS) {
        item.type = IconType.Active;
      }
    });

    if (this.props.appId) {
      const requestObj: GetGeneralSettingsRequestModel = {
        ApplicationId: this.props.appId,
      };
      await this.props.actions.getGeneralSettingsStart(requestObj);
    }
  };

  componentDidUpdate = (prevProps: any) => {
    if (this.props.appId != prevProps.appId) {
      const requestObj: GetGeneralSettingsRequestModel = {
        ApplicationId: this.props.appId,
      };
      this.props.actions.getGeneralSettingsStart(requestObj);
    }

    if (this.props.generalSettings != prevProps.generalSettings) {
      prevProps = this.props;
      const {
        AndroidIconUrl,
        AppName,
        IOSIconUrl,
        LanguageId,
        LanguageList,
        NavigationBarIconUrl,
      } = prevProps.generalSettings;

      const selectedOption = LanguageList ? LanguageList.SelectedLanguage : "";
      const selectedValue = selectedOption ? selectedOption.LanguageID : "";

      const iosExtension = getFileExtension(IOSIconUrl);
      const androidExtension = getFileExtension(AndroidIconUrl);

      const logoExtension = getFileExtension(NavigationBarIconUrl);

      if (IOSIconUrl) {
        this.setState({
          iosIcon: IOSIconUrl,
          iosIconExtension: `image/${iosExtension}`,
        });
      }
      if (AndroidIconUrl) {
        this.setState({
          androidIcon: AndroidIconUrl,
          androidIconExtension: `image/${androidExtension}`,
        });
      }
      this.setState({
        selectedValue,
        appName: AppName,
        navigationLogo: NavigationBarIconUrl,
        navigationBarIconFileExtension: `image/${logoExtension}`,
      });

      let languageOptionList: any = [];
      LanguageList &&
        LanguageList.Languages.sort((a: Language, b: Language) =>
          a.OrderIndex > b.OrderIndex ? 1 : -1
        ).map((item: any, index: number) => {
          if (selectedValue == item.value) {
            languageOptionList.push({
              value: item.LanguageID.toString(),
              displayValue: item.Name,
              isSelected: true,
              isComplated: false,
            });
          } else {
            languageOptionList.push({
              value: item.LanguageID.toString(),
              displayValue: item.Name,
              isSelected: false,
              isComplated: false,
            });
          }
        });
      this.setState({ options: languageOptionList });
    }
    if (
      this.props.isOnModalSubmit &&
      this.props.selectedValue === screenNumbers.generalSettings
    ) {
      this.formikRef?.submitForm();
    }
  };

  getInitialValues = () => {
    return {
      appName: this.state.appName,
      iosIcon: this.state.iosIcon,
      androidIcon: this.state.androidIcon,
      logo: this.state.navigationLogo,
      iosIconExtension: this.state.iosIconExtension,
      androidIconExtension: this.state.androidIconExtension,
      logoExtension: this.state.navigationBarIconFileExtension,
      iosIconWidth: this.state.iosIconWidth,
      iosIconHeight: this.state.iosIconHeight,
      androidIconWidth: this.state.androidIconWidth,
      androidIconHeight: this.state.androidIconHeight,
    };
  };

  onSubmitForm = async (values: any) => {
    this.setState({ isBlocking: false }, async () => {
      this.props._setChangedSthDesign(false);
      if (!this.props.appId) return;
      try {
        let settingsObj: any = {
          ApplicationId: this.props.appId,
          AppName: values.appName,
          IOSIconFile: !validURL(this.state.iosIcon)
            ? this.state.iosIcon.split(";base64,").pop()
            : null,
          AndroidIconFile: !validURL(this.state.androidIcon)
            ? this.state.androidIcon.split(";base64,").pop()
            : null,
          LanguageId: +this.state.selectedValue,
          NavigationBarIconFile: null,
          NavigationBarIconFileExtension: null,
        };

        await this.props.actions.setGeneralSettingsStart(settingsObj);

        this.props.selectChange(ScreenType.SplashScreen.toString());
      } catch (error) {
        console.log("error", error);
      }
    });
  };

  appNameInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isBlocking: true, appName: e.target.value });
    this.props._setChangedSthDesign(true);
  };

  selectChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { options } = this.state;
    const updatedOptions = options.map((option) => {
      return {
        ...option,
        isSelected: option.value === e.target.value,
      };
    });

    const findLanguage = options.find((item) => item.value === e.target.value);

    this.setState({
      options: updatedOptions,
      selectedValue: e.target.value,
      selectedLanguageName: findLanguage?.displayValue || "",
      isBlocking: true,
    });
    this.props._setChangedSthDesign(true);
  };

  onChangeFileInput = async (fileInput: any, target: string) => {
    const MAX_WIDTH = 512;
    var file = fileInput.files[0];
    if (file) {
      var _URL = window.URL || window.webkitURL;
      var img = new Image();
      img.onload = async () => {
        if (img.width !== img.height || img.width < MAX_WIDTH) {
          this.setState({ errorVisible: true });
          return;
        } else {
          let res = (await fileToBase64(file)) as string;
          if (img.width > MAX_WIDTH) {
            res = (await resizeImage(res, MAX_WIDTH, MAX_WIDTH)) as string;
          }
          if (target === "ios") {
            this.setState({
              iosIcon: res,
              iosIconExtension: file.type,
              iosIconWidth: img.width,
              iosIconHeight: img.height,
              isBlocking: true,
            });
          }
          if (target === "android") {
            this.setState({
              androidIcon: res,
              androidIconExtension: file.type,
              androidIconWidth: img.width,
              androidIconHeight: img.height,
              isBlocking: true,
            });
          }

          this.props.changeHandler(res);
        }
      };
      img.onerror = function () {
        console.log("not a valid file: " + file.type);
      };
      img.src = _URL.createObjectURL(file);
    }
  };

  handleClick = (device: any) => {
    this.setState({ activeIndex: device.index });

    this.state.devices.map((item) => {
      item.type = IconType.Disabled;

      if (device.index === DeviceType.IOS) {
        device.type = IconType.Active;
      } else if (device.index === DeviceType.Android) {
        device.type = IconType.Active;
      }
    });
    this.props.deviceTypeHandler(device.index);
  };

  languageEditToggle = () => {
    const { editLanguage } = this.state;
    this.setState({ editLanguage: !editLanguage });
  };

  render() {
    const toggleDevices = this.state.devices.map((item) => {
      let navIcon = null;
      if (item.index === DeviceType.IOS) {
        navIcon = <IOSIcon iconType={item.type} />;
      } else if (item.index === DeviceType.Android) {
        navIcon = <AndroidIcon iconType={item.type} />;
      }

      let navLabel = null;

      const attachedClasses = [classes.Label];
      const borderClasses = [];
      if (item.type === "active") {
        attachedClasses.push(classes.Active);
        borderClasses.push(classes.ActiveBorder);
      }

      navLabel = (
        <span className={attachedClasses.join(" ")}>{item.label}</span>
      );

      return (
        <li
          className={borderClasses.join(" ")}
          key={item.name}
          onClick={() => this.handleClick(item)}
        >
          {navIcon}
          {navLabel}
        </li>
      );
    });

    return (
      <React.Fragment>
        <div className={classes.DesignConfigContainer}>
          {!this.props.isCompletion && (
            <ScreenSelection
              options={this.props.designScreens}
              selectedValue={this.props.selectedValue}
              selectChangeHandler={this.props.designScreensChange}
            />
          )}
          <WarningModal
            isVisible={this.state.errorVisible}
            setIsVisible={() => this.setState({ errorVisible: false })}
            text={`The image’s aspect ratio is not 1:1 or an edges higher than 512 px. Please upload an image with an aspect ratio of 1:1 and an edges higher than 512 px. (Recommended 512*512 px)`}
          />
          <div className={classes.componentsContainer}>
            <Formik
              enableReinitialize={true}
              initialValues={this.getInitialValues()}
              validationSchema={generalValidation}
              validateOnChange={false}
              validateOnBlur={false}
              innerRef={(r) => (this.formikRef = r)}
              onSubmit={this.onSubmitForm}
            >
              {(formik) => (
                <Form>
                  <BlockingForm isBlocking={this.state.isBlocking} />
                  <div className={classes.AppNameContainer}>
                    <Field
                      elementType={this.state.form.appname.elementType}
                      elementConfig={this.state.form.appname.elementConfig}
                      value={this.state.appName}
                      label={this.state.form.appname.label}
                      name="appName"
                      placeholder="Mowico App"
                      changeHandler={this.appNameInputChangeHandler}
                      component={Input}
                    />
                    <div className={classes.InfoGroup}>
                      <img
                        src={informationIcon}
                        alt="information"
                        className={classes.Information}
                      />
                      <span className={classes.InfoText}>
                        Maximum 30 characters.
                      </span>
                    </div>
                  </div>
                  <div className={classes.AppIconContainer}>
                    <span className={classes.BlockLabel}>App Icon</span>
                    <div className={classes.UploadContainer}>
                      <div className={classes.AppIconsUpload}>
                        <Field
                          title="Add Image"
                          appIconType="ios"
                          appIcon={this.state.iosIcon}
                          onChange={(e: any) => {
                            this.onChangeFileInput(e, "ios");
                            formik.setFieldValue("iosIcon", e.files[0]);
                          }}
                          name="iosIcon"
                          component={AppIcon}
                        />
                        <div className={classes.AppIconText}>
                          <span className={classes.AppIconTextTitle}>
                            IOS App Icon
                          </span>
                          <p className={classes.AppIconTextDesc}>
                            Please upload an image with an aspect ratio of 1:1
                            and an edges higher than 512 px.
                          </p>
                        </div>
                      </div>
                      {formik.errors["iosIcon"] && (
                        <ErrorField
                          type={"relative"}
                          text={formik.errors["iosIcon"]}
                        />
                      )}
                      {!formik.errors["iosIcon"] &&
                        formik.errors["iosIconExtension"] && (
                          <ErrorField
                            type={"relative"}
                            text={formik.errors["iosIconExtension"]}
                          />
                        )}
                      {!formik.errors["iosIcon"] &&
                        (formik.errors["iosIconWidth"] ||
                          formik.errors["iosIconHeight"]) && (
                          <ErrorField
                            type={"relative"}
                            text={formik.errors["iosIconWidth"]}
                          />
                        )}
                    </div>

                    <div className={classes.UploadContainer}>
                      <div className={classes.AppIconsUpload}>
                        <Field
                          title="Add Image"
                          appIconType="android"
                          appIcon={this.state.androidIcon}
                          onChange={(e: any) => {
                            this.onChangeFileInput(e, "android");
                            formik.setFieldValue("androidIcon", e.files[0]);
                          }}
                          name="androidIcon"
                          component={AppIcon}
                        />
                        <div className={classes.AppIconText}>
                          <span className={classes.AppIconTextTitle}>
                            Android App Icon
                          </span>
                          <p className={classes.AppIconTextDesc}>
                            Please upload an image with an aspect ratio of 1:1
                            and an edges higher than 512 px.
                          </p>
                        </div>
                      </div>
                      {formik.errors["androidIcon"] && (
                        <ErrorField
                          type={"relative"}
                          text={formik.errors["androidIcon"]}
                        />
                      )}
                      {!formik.errors["androidIcon"] &&
                        formik.errors["androidIconExtension"] && (
                          <ErrorField
                            type={"relative"}
                            text={formik.errors["iosIconExtension"]}
                          />
                        )}
                      {!formik.errors["androidIcon"] &&
                        (formik.errors["androidIconWidth"] ||
                          formik.errors["androidIconHeight"]) && (
                          <ErrorField
                            type={"relative"}
                            text={formik.errors["androidIconWidth"]}
                          />
                        )}
                    </div>
                  </div>

                  <div className={classes.LanguageContainer}>
                    <span className={classes.BlockLabel}>Languages</span>
                    {!this.state.editLanguage && (
                      <>
                        <Field
                          options={this.state.options}
                          value={this.state.selectedValue}
                          placeholder="Choose..."
                          name="language"
                          changeHandler={this.selectChangeHandler}
                          component={Select}
                        />
                        <div className={classes.InfoGroup}>
                          <img
                            src={informationIcon}
                            alt="information"
                            className={classes.Information}
                          />
                          {this.packageFeaturesLimited ? (
                            <p className={classes.GrowthInfoText}>
                              Only one language can be used in the Startup
                              package. To use the multi-language feature,
                              upgrade to the
                              <a
                                href="https://mowico.com/pricing/"
                                target="_blank"
                              >
                                {` Growth `}
                              </a>
                              package.
                            </p>
                          ) : (
                            <span className={classes.InfoText}>
                              This change will require an update.
                            </span>
                          )}
                        </div>
                      </>
                    )}
                    <Button
                      height="40px"
                      onClick={() => this.languageEditToggle()}
                    >
                      {this.state.editLanguage
                        ? "Language Select"
                        : "Edit Content"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className={classes.MobileScreen}>
          {this.state.editLanguage ? (
            <EditContentLanguageContent
              cLanguageId={Number(this.state.selectedValue) || 2}
              cLanguageName={this.state.selectedLanguageName || "English"}
              rLanguageName={"English"}
              rLanguageId={2}
              onPressGoBack={() => this.setState({ editLanguage: false })}
            />
          ) : (
            <Emulator
              screen="General Settings"
              navigateBack={this.props.history.push}
              backTo={"/manage/completion"}
              osScreen={{
                appName: this.state.appName,
                iosIcon: this.state.iosIcon,
                androidIcon: this.state.androidIcon,
              }}
              saveScreen={() => this.formikRef?.submitForm()}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators(generalActions, dispatch),
    _setChangedSthDesign: (value: boolean) =>
      dispatch(setChangedSthDesignPage(value)),
  };
};

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    generalSettings: state.generalSettings.generalSettings,
    isModalSubmitted: state.manageApp.isModalSubmitted,
    packageFeatures:
      state.packageFeature?.featureData?.PackageFeature?.Features,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);
