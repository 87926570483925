import React from "react";
import { NodeModel } from "@minoru/react-dnd-treeview";
import ArrowDownIcon from "panel/src/assets/images/icons-dark-gray-arrow-down.svg";
import TrashIcon from "panel/src/assets/images/icons-trash.svg";
import EditIcon from "panel/src/assets/images/icons-edit.svg";
import styles from "./CustomNode.module.scss";
import { TNode } from "..";

type Props = {
  node: TNode;
  depth: number;
  isOpen: boolean;
  onToggle: (id: NodeModel["id"]) => void;
  toggleEditModal: (node: TNode) => void;
  onRemoveMenuItem: (node: TNode) => void;
};

export const CustomNode: React.FC<Props> = (props) => {
  const { toggleEditModal, onRemoveMenuItem, node } = props;
  const { data, id } = node;
  const indent = props.depth * 24;

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onToggle(id);
  };

  return (
    <div
      className={`tree-node ${styles.root}`}
      style={{ paddingInlineStart: indent }}
    >
      <div
        className={`${styles.expandIconWrapper} ${
          props.isOpen ? styles.isOpen : ""
        }`}
      >
        {props.node.data?.hasChild && (
          <div onClick={handleToggle}>
            <img src={ArrowDownIcon} alt="Mowico" />
          </div>
        )}
      </div>
      <div className={styles.labelGridItem}>
        <p>{props.node.text}</p>
        {data?.categoryId === null && (
          <div className={styles.categoryHoverList}>
            <button
              className={styles.button}
              onClick={() => toggleEditModal(node)}
            >
              <img src={EditIcon} alt="Mowico" />
            </button>
            <button
              className={styles.button}
              onClick={() => onRemoveMenuItem(node)}
            >
              <img src={TrashIcon} alt="Mowico" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
