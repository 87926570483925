import {
  SET_INSTAGRAM_TOKEN,
  SET_INSTAGRAM_TOKEN_SUCCESS,
  SET_INSTAGRAM_TOKEN_FAIL,
  RESET_INSTAGRAM_TOKEN,
} from "panel/src/redux/constants/setInstagramToken";

interface BaseAction {
  type: string;
  data?: Object;
  error?: string;
  requestObj?: any;
  code?: string;
  appId?: string;
}

export const setInstagramToken = (code: string, appId: string): BaseAction => ({
  type: SET_INSTAGRAM_TOKEN,
  code,
  appId,
});

export const setInstagramTokenSuccess = (data: Object): BaseAction => ({
  type: SET_INSTAGRAM_TOKEN_SUCCESS,
  data,
});

export const setInstagramTokenFail = (error: string): BaseAction => ({
  type: SET_INSTAGRAM_TOKEN_FAIL,
  error,
});

export const resetInstagramToken = (): BaseAction => ({
  type: RESET_INSTAGRAM_TOKEN,
});
