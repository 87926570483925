import React from "react";
import TargetIcon from "panel/src/assets/images/target.svg";
import CloseIcon from "panel/src/assets/images/close.svg";
import classes from "./UpgradeWidget.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { pricingBannerToggle } from "../../../redux/actions/userInfo";

export default function UpgradeWidget() {
  const dispatch = useDispatch();
  const { userInfo }: any = useSelector((state) => state);

  const onClose = () => {
    dispatch(pricingBannerToggle());
  };

  if (!(userInfo.isShowPricingBanner && userInfo.IsTrial)) return null;
  return (
    <div className={classes.alert}>
      <img className={classes.alertIcon} src={TargetIcon} alt="upgrade-now" />
      <div className={classes.alertBody}>
        <p className={classes.alertDescription}>
          Ready to launch your new app?
        </p>
        <a
          href="https://mowico.com/pricing/"
          target="_blank"
          className={classes.alertTitleButton}
        >
          Upgrade
        </a>
      </div>
      <button className={classes.alertClose} onClick={onClose}>
        <img
          className={classes.closeIcon}
          src={CloseIcon}
          alt="upgrade-now-close"
        />
      </button>
    </div>
  );
}
