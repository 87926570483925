import { put, call, takeLatest } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import * as dataActions from "panel/src/redux/actions/splashScreen";
import * as userInfoActions from "panel/src/redux/actions/userInfo";
import * as pageActions from "panel/src/redux/actions/page";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import {
  GET_SPLASH_SCREEN_START,
  SET_SPLASH_SCREEN_START,
} from "panel/src/redux/constants/splashScreen";
import { SetSplashModule } from "../../models/Design/Splash";
import { clearDesignError, setDesignError } from "../actions/DesignError";

function* getSplashScreen(payload: { appId: string; type: string }) {
  yield put(startAppLoading(GET_SPLASH_SCREEN_START));

  try {
    const { data } = yield call(axios.post, "/api/Design/GetSplashImages", {
      applicationId: payload.appId,
    });
    yield put(clearDesignError());

    yield put(dataActions.getSplashScreenFinish(data.ResultData));
  } catch (error) {
    const err = error as any;
    yield put(setDesignError(`/api/Design/GetSplashImages - ${err?.response?.status}`));
    yield put(dataActions.getSplashScreenRejected(err.message));
  }
  yield put(endAppLoading(GET_SPLASH_SCREEN_START));
}

function* setSplashScreen(action: {
  splashScreenObj: SetSplashModule.Request;
  type: string;
  changeScreen: any;
}) {
  yield put(startAppLoading(SET_SPLASH_SCREEN_START));
  try {
    const { data } = yield call(axios.post, "/api/Design/SetSplashImages", {
      ...action.splashScreenObj,
    });

    yield put(clearDesignError());

    if (data.ResultCode === 118) {
      yield put(userInfoActions.getUserInfo());
      yield call(action.changeScreen);
      yield put(dataActions.setSplashScreenFinish());
      yield put(pageActions.pageUpdated(true));
    }
  } catch (error) {
    const err = error as any;
    yield put(setDesignError(`/api/Design/SetSplashImages - ${err?.response?.status}`));
    yield put(dataActions.setSplashScreenRejected(err.message));
  }
  yield put(endAppLoading(SET_SPLASH_SCREEN_START));
}

export function* getSplashScreenSaga() {
  yield takeLatest(GET_SPLASH_SCREEN_START, getSplashScreen);
}

export function* setSplashScreenSaga() {
  yield takeLatest(SET_SPLASH_SCREEN_START, setSplashScreen);
}
