import styled from "styled-components";

interface IContainer {}

export default styled.div<IContainer>`
  position: relative;
  &:hover {
    .editMenuItem,
    .trashMenuItem {
      transform: all 0.2s ease;
      display: block;
    }
  }
  .editMenuItem {
    display: none;
    position: relative;
    z-index: 99;
    .editIcon {
      color: #000;
      transition: all 0.2s ease;
    }
    &:hover {
      background-color: #0f3649;
      .editIcon {
        color: #fff;
      }
    }
  }
  .trashMenuItem {
    display: none;

    .trashIcon {
      color: #e66565;
      transition: all 0.2s ease;
    }
    &:hover {
      background-color: #e66565;
      .trashIcon {
        color: #fff;
      }
    }
  }
`;
