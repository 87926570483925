import IGetDemoInfo from "../../models/Publish/demoInfoRequest";

import {
  DEMO_INFO,
  DEMO_INFO_SUCCESS,
  DEMO_INFO_FAIL,
  RESET_DEMO_INFO,
} from "panel/src/redux/constants/demoInfo";

interface BaseAction {
  type: string;
  data?: Object;
  error?: string;
  requestObj?: IGetDemoInfo;
}

export const demoInfo = (requestObj: IGetDemoInfo): BaseAction => ({
  type: DEMO_INFO,
  requestObj,
});

export const demoInfoSuccess = (data: Object): BaseAction => ({
  type: DEMO_INFO_SUCCESS,
  data,
});

export const demoInfoFail = (error: string): BaseAction => ({
  type: DEMO_INFO_FAIL,
  error,
});
export const resetDemoInfo = () => ({
  type: RESET_DEMO_INFO,
});
