export const GET_COLORS_START: string = "GET_COLORS_START";
export const GET_COLORS_FINISH: string =
    "GET_COLORS_FINISH";
export const GET_COLORS_REJECTED: string =
    "GET_COLORS_REJECTED";

export const SET_COLORS_START: string = "SET_COLORS_START";
export const SET_COLORS_FINISH: string =
    "SET_COLORS_FINISH";
export const SET_COLORS_REJECTED: string =
    "SET_COLORS_REJECTED";
