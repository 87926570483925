import React, { useEffect, useState, cloneElement, createRef } from "react";
import { Container, Draggable, DropResult } from "react-smooth-dnd";
import { applyDrag } from "panel/src/Components/DragnDrop/utils";
import ClickGuard from "panel/src/Components/DragnDrop/ClickGuard";
import DropContainer from "./DropContainer";
import OptionsMenu from "./OptionsMenu";
import EditCard, { IEditCard } from "./EditCard";
import InfoPopUp from "./InfoPopUp";
import { IComponent } from "panel/src/Components/DragnDrop/Components/index";
import Text from "./EndOfPage/Text";
import EndOfPage from "./EndOfPage";
import Hover from "./EndOfPage/Hover";
import { useDispatch } from "react-redux";
import { setChangedSthDesignPage } from "panel/src/redux/actions/manageApp";
import { ComponentType } from "panel/src/utils/enums/componentType";

export interface IEmulatorApp {
  selectedComponents: (IComponent | any)[];
  setSelectedComponents: Function;
  requiredEdit?: Array<number>;
  itemDropped?: Function;
  editFormPlacement?: IEditCard["editCardPlacement"];
  showOptionsMenu?: boolean;
  screen: string;
  applyOpacity?: boolean;
}

export interface IEmulatorAppProps extends IEmulatorApp {
  containerRef: React.MutableRefObject<null>;
}

const ComponentWrapper = ({
  preventEvents,
  isProductSlider,
  children,
}: {
  preventEvents?: boolean;
  isProductSlider?: boolean;
  children: React.ReactNode;
}) => {
  if (preventEvents) {
    return (
      <div>
        {children}
      </div>
    );
  } else {
    return <Draggable>{children}</Draggable>;
  }
};

const EmulatorApp = ({
  selectedComponents,
  setSelectedComponents,
  requiredEdit,
  itemDropped,
  editFormPlacement,
  showOptionsMenu = true,
  containerRef,
  screen,
  applyOpacity,
}: IEmulatorAppProps) => {
  const [isInformed, setInformed] = useState(false);
  const [isEndVisible, setEndVisible] = useState(false);
  const [isDropReady, setDropReady] = useState(false);
  const [componentIndex, setComponentIndex] = useState<number | null>(null);
  const [editFormClickedOutside, setEditFormClickedOutside] = useState(0);
  const [index, setIndex] = useState<any>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const hasUndefinedId = selectedComponents.find(
      (component) => component.Id === undefined
    );

    if (hasUndefinedId) {
      setComponentId();
    }
  }, [selectedComponents]);

  // useEffect(() => {
  //   if (index !== null && selectedComponents) {
  //     const findItemIndex = selectedComponents.findIndex(
  //       (item) => item.Id === index
  //     );
  //     if (index === selectedComponents.length - 1) {
  //       const lastItemIndex = selectedComponents.length - 1;
  //       selectedComponents[lastItemIndex].ref.current.scrollIntoView({
  //         behaviour: "smooth",
  //       });
  //     } else {
  //       selectedComponents[findItemIndex]?.ref?.current.scrollIntoView({
  //         behaviour: "smooth",
  //         block: "end",
  //         inline: "nearest",
  //       });
  //     }
  //   }
  // }, [index, selectedComponents]);

  const setComponentId = () => {
    const tempArr = [...selectedComponents];
    tempArr.map((obj) => {
      if (obj!.Id === undefined) {
        obj!.Id = getUniqueId();
        obj!.ref = createRef();
      }
    });
    setSelectedComponents(tempArr);
  };

  const handleComponentRemove = (Id: string | undefined) => {
    var tempArr = [...selectedComponents];
    const filteredItems = tempArr.filter((item) => item!.Id !== Id);

    setSelectedComponents(filteredItems);
  };

  const showComponentEdit = (Id: string | undefined) => {
    let newArr = [...selectedComponents];
    newArr.map((obj, i) => {
      if (obj!.editForm && obj!.Id === Id) {
        return (obj!.editForm!.isShown = true);
      } else if (obj!.editForm) return (obj!.editForm!.isShown = false);
      else return (obj!.editForm = null);
    });
    setEditFormClickedOutside(0);
    setSelectedComponents(newArr);
  };

  const hideComponentEdit = (
    Id: string | undefined,
    isRequiredComponent: boolean = false
  ) => {
    if (isRequiredComponent) {
      handleComponentRemove(Id);
      return;
    }
    let newArr = [...selectedComponents];
    newArr.map((obj) => {
      return obj!.Id === Id && (obj!.editForm!.isShown = false);
    });
    setSelectedComponents(newArr);
  };

  const setInfoPopup = (val: boolean) => {
    setInformed(val);
  };

  const getUniqueId = () => Math.random().toString(36).substr(2, 9);

  const handleOnDrop = (
    e: DropResult,
    componentsArr: (IComponent | undefined)[] | boolean = false
  ) => {
    let dragResult;

    if (e === undefined) {
      dragResult = e;
    } else {
      dragResult = {
        removedIndex: isDropReady ? componentIndex : e.removedIndex,
        addedIndex: isDropReady ? selectedComponents.length + 1 : e.addedIndex,
        payload: {
          Id: getUniqueId(),
          ref: createRef(),
          componentId: e.payload.componentId,
          pageId: e.payload.pageId,
          componentTitle: e.payload.componentTitle,
          component: e.payload.component,
          isNewAdded: e.payload?.isNewAdded,
          editForm: e.payload.editForm
            ? {
                editCardPlacement: e.payload.editForm?.editCardPlacement,
                editCardWidth: e.payload.editForm?.editCardWidth,
                component: e.payload.editForm.component,
                isShown: handleEditRequired(e.payload.componentId),
                reRenderComponent: e.payload.editForm.reRenderComponent,
                updateImageResizeType: e.payload.editForm.reRenderComponent,
                editFormData: e.payload.editForm.editFormData,
                rawFormData: e.payload.editForm.rawFormData,
              }
            : null,
          preventEvents: e.payload.preventEvents,
        },
      };
      setIndex(dragResult.payload.Id);
      dispatch(setChangedSthDesignPage(true));
    }

    if (
      selectedComponents.length === 0 &&
      !dragResult.payload.editForm?.isShown &&
      screen === "Home" &&
      dragResult.payload.editForm != null
    ) {
      setInformed(true);
    } else {
      setInformed(false);
    }
    setEditFormClickedOutside(0);

    setSelectedComponents(
      applyDrag(
        componentsArr ? (componentsArr as IComponent[]) : selectedComponents,
        dragResult
      )
    );
  };

  const handleEditRequired = (componentId: number) => {
    if (requiredEdit) {
      return requiredEdit!.includes(componentId);
    }
  };

  const setComponentFormData = (
    Id: string | undefined,
    formData: object,
    reRenderComponentData: boolean = false
  ) => {
    let newArr = [...selectedComponents];

    newArr.map((obj) => {
      if (obj!.Id === Id) {
        if (obj!.editForm?.reRenderComponent && reRenderComponentData) {
          obj.component = obj!.editForm.reRenderComponent(formData).component;
        }

        obj!.editForm.editFormData = formData;

        return obj;
      }
    });

    setSelectedComponents(newArr);
    hideComponentEdit(Id);
  };

  const setImageResizeType = (Id: string | undefined, formData: object) => {
    let newArr = [...selectedComponents];
    newArr.map((obj) => {
      if (obj!.Id === Id) {
        obj.component = obj!.editForm.reRenderComponent(formData).component;
        return obj;
      }
    });

    setSelectedComponents(newArr);
  };

  /**
   * Function to run when component data changes
   * @param Id Component Id
   * @param formData  Form Data
   */
  const reRenderComponent = (Id: string | undefined, formData: object) => {
    let newArr = [...selectedComponents];
    newArr.map((obj) => {
      if (obj!.Id === Id) {
        obj.component = obj!.editForm.reRenderComponent(formData).component;
        return obj;
      }
    });
    setSelectedComponents(newArr);
  };

  const selectedComponentsContains = (
    component: IComponent[],
    componentId: number
  ) => {
    var i = component.length;

    while (i--) {
      if (component[i].componentId === componentId) {
        return true;
      }
    }

    return false;
  };

  const selectedComponentHandle = selectedComponents.filter(
    (s) => s.componentId !== ComponentType.ProductPrice
  );

  return (
    <React.Fragment>
      {/* {selectedComponentsContains(
        selectedComponentHandle,
        ComponentType.ProductSlider
      ) ? (
        <div>
          {
            selectedComponentHandle.find(
              (item) => item.componentId === ComponentType.ProductSlider
            ).component
          }
        </div>
      ) : null} */}
      <DropContainer>
        <Container
          autoScrollEnabled={!isDropReady}
          style={{ width: "100%", height: "100%" }}
          groupName="1"
          lockAxis="y"
          dropPlaceholder={{
            animationDuration: 150,
            showOnTop: true,
            className: "component-drop-preview",
          }}
          onDragStart={() => setEndVisible(true)}
          onDragEnd={(e) => {
            setEndVisible(false);
            setDropReady(false);
            setComponentIndex(null);
          }}
          getChildPayload={(i) => {
            setComponentIndex(i);
            return selectedComponentHandle[i];
          }}
          onDrop={(e) => {
            itemDropped ? itemDropped(e, handleOnDrop) : handleOnDrop(e);
          }}
        >
          {selectedComponentHandle!.map((item, index) => {
            const tempComponent = item!.component;
            const component = React.cloneElement(tempComponent, {
              ...tempComponent.props,
              isInEmulator: true,
            });
            return (
              <ComponentWrapper
                preventEvents={item!.preventEvents}
                isProductSlider={
                  item.componentId === ComponentType.ProductSlider
                }
                key={index.toString()}
              >
                {showOptionsMenu ? (
                  <OptionsMenu
                    componentIndex={index}
                    isEditable={item!.editForm != null}
                    editComponent={() => showComponentEdit(item!.Id)}
                    removeComponent={() => handleComponentRemove(item!.Id)}
                    preventRemoval={item!.preventRemoval}
                  >
                    <ClickGuard ref={item.ref}>{component}</ClickGuard>
                  </OptionsMenu>
                ) : (
                  <div
                    onContextMenu={(e) => {
                      e.preventDefault();
                      showComponentEdit(item!.Id);
                    }}
                    onClick={() => {
                      if (!item?.editForm?.showOnRightClick) {
                        showComponentEdit(item!.Id);
                      }
                    }}
                    style={{
                      cursor: item?.editForm != null ? "pointer" : "normal",
                      opacity:
                        item?.editForm !== null || !applyOpacity
                          ? "100%"
                          : "50%",
                    }}
                  >
                    <ClickGuard ref={item.ref}>{component}</ClickGuard>
                  </div>
                )}
                {item!.editForm && item!.editForm.component && (
                  <EditCard
                    editCardWidth={item.editForm?.editCardWidth}
                    showEditCard={item!.editForm.isShown}
                    containerRef={containerRef}
                    targetRef={item!.ref}
                    editCardPlacement={
                      editFormPlacement ?? item.editForm?.editCardPlacement
                    }
                    onHideEditCard={() =>
                      setEditFormClickedOutside((prev) => prev + 1)
                    }
                  >
                    {cloneElement(item!.editForm.component, {
                      index: index,
                      hideComponentEdit,
                      editFormClickedOutside,
                      setInfoPopup,
                      selectedComponents,
                      setComponentFormData,
                      setImageResizeType,
                      reRenderComponent,
                      Id: item!.Id,
                      formData: item!.editForm!.editFormData,
                      rawFormData: item!.editForm!.rawFormData,
                    })}
                  </EditCard>
                )}
                <InfoPopUp
                  selectedComponents={selectedComponents}
                  setInfoPopup={setInfoPopup}
                  showEditCard={isInformed}
                  containerRef={containerRef}
                  targetRef={item!.ref}
                  editCardPlacement={editFormPlacement}
                />
              </ComponentWrapper>
            );
          })}
          {/* {isInformed &&
            ReactDOM.createPortal(
              <Background onClick={() => setInformed(false)} />,
              document.getElementById("root") as Element
            )} */}
        </Container>
      </DropContainer>
      {isEndVisible && screen != "Product List" && (
        <Container
          behaviour={"drop-zone"}
          onDropReady={() => setDropReady(true)}
          onDragLeave={() => setDropReady(false)}
          onDragEnter={() => setDropReady(true)}
          groupName="1"
          onDrop={(e) => {
            isDropReady && handleOnDrop(e);
          }}
          lockAxis="y"
          style={{
            alignItems: "center",
            borderRadius: "0px 0px 20px 20px",
            backgroundColor: "#fff",
            position: "absolute",
            bottom: "12px",
            width: "240px",
            height: "125px",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text>-------------- End of Page --------------</Text>
          {isDropReady ? <Hover /> : <EndOfPage screen={screen} />}
        </Container>
      )}
    </React.Fragment>
  );
};

export default EmulatorApp;
