import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Container } from "react-smooth-dnd";
import ComponentsListContainer from "./ComponentsListContainer";
import Header from "./Header";
import InfoText from "./InfoText";
import ErrorMessage from "./ErrorMessage";
import ComponentsList from "./ComponentsList";
import Component from "./Component";
import ClickGuard from "../ClickGuard";
import TitleWrapper from "./TitleWrapper";
import Title from "./Title";
import ContactContainer, {
  CONTACT_SCREENS,
} from "panel/src/screens/Design/ContactContainer";
import YoutubeTutorial, {
  YOUTUBE_LINKS,
} from "panel/src/Components/YoutubeTutorial";
import { ComponentType } from "panel/src/utils/enums/componentType";
import { OverlayProps } from "react-overlays/esm/Overlay";
import UpgradeComponent from "../../../_components/common/UpgradeComponent";
import { IProductDetailData } from "../../../models/Design/productDetail/IGetProductDetailDataResponse";
import { IProductListData } from "../../../models/Design/productList/IGetProductListDataResponse";
import { IHomeData } from "../../../models/Design/home/IGetHomeDataResponse";
import { getPackageFeatureListStart } from "../../../redux/actions/getPackageFeature";
import UpgradeTooltip from "../../UpgradeTooltip";
import { NewBadge, NewBadgeTitle } from "./NewBadge";
interface IYoutubeComponent {
  componentId?: number;
}

const YoutubeComponent = ({ componentId }: IYoutubeComponent) => {
  let url;

  switch (componentId) {
    case ComponentType.HomeSlider:
      url = YOUTUBE_LINKS.SLIDERS_BANNERS;
      break;
    case ComponentType.LargeBanner:
      url = YOUTUBE_LINKS.SLIDERS_BANNERS;
      break;
    case ComponentType.MediumBanner:
      url = YOUTUBE_LINKS.SLIDERS_BANNERS;
      break;
    case ComponentType.CustomHtml:
      url = YOUTUBE_LINKS.CUSTOM_COMPONENT;
      break;
    case ComponentType.SmallBanner:
      url = YOUTUBE_LINKS.SLIDERS_BANNERS;
      break;
    case ComponentType.CategorySlider:
    case ComponentType.LargeCategoryView:
    case ComponentType.SmallCategoryView:
      url = YOUTUBE_LINKS.RAYON_CATEGORIES;
      break;
    case ComponentType.LinkListView:
      url = YOUTUBE_LINKS.LINK_LIST_VIEW;
      break;
    case ComponentType.SocialMedia:
      url = YOUTUBE_LINKS.SOCIAL_MEDIA;
      break;
    case ComponentType.Rayon: 
      url = YOUTUBE_LINKS.RAYON_CATEGORIES;
      break;
    default:
      url = null;
      break;
  }

  if (url === null) {
    return null;
  }

  return <YoutubeTutorial videoUrl={url} iconSize={16} />;
};

export interface IComponent {
  Id?: string;
  componentId: number;
  pageId: number;
  componentTitle: string;
  component: JSX.Element;
  ref?: HTMLDivElement | null;
  editForm: {
    editCardWidth?: string;
    editCardPlacement?: OverlayProps["placement"];
    component: JSX.Element;
    isShown: boolean;
    reRenderComponent?: Function;
    updateImageResizeType?: Function;
    rawFormData?: object | null;
    editFormData: object | null;
    showOnRightClick?: boolean;
  } | null;
  preventEvents?: boolean;
  preventRemoval?: boolean;
  editable?: boolean;
  IsNewBadge?: boolean;
}

export interface IComponents {
  title?: string;
  components: (IComponent | undefined)[];
  hasError: boolean;
  infoText?: string;
  errorMessage?: string;
  selectedApp?: {
    AppId: string;
    AppName: string;
    CreateDate: string;
    IsSelected: boolean;
    IsStep1Completed: boolean;
    IsStep2Completed: boolean;
    IsStep3Completed: boolean;
    IsStep4Completed: boolean;
    IsStep5Completed: boolean;
    IsStep6Completed: boolean;
    IsStep7Completed: boolean;
    IsStep8Completed: boolean; // Bu kontrol edilecek.
    IsStep9Completed: boolean;
    IsStep10Completed: boolean;
    IsStep11Completed: boolean;
    IsStep12Completed: boolean;
    IsStep13Completed: boolean;
    PlatformId: number;
  };
  productDetailData: IProductDetailData["data"]["ResultData"];
  productListData: IProductListData["data"]["ResultData"];
  homeData: IHomeData["data"]["ResultData"];
  packageFeature?: any;
  componentTotalCount?: number;
  userPackage?: any;
  packageFeatures: any;
}

const Components = ({
  title = "Components",
  components,
  hasError,
  infoText,
  errorMessage,
  selectedApp,
  packageFeature,
  componentTotalCount,
  userPackage,
  packageFeatures,
}: IComponents) => {
  const firstComponentRef = useRef(null);

  const [designPackageFeature, setDesignPackageFeature] = useState<any>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const packageFeatureObj = {
      applicationId: selectedApp?.AppId,
    };
    dispatch(getPackageFeatureListStart(packageFeatureObj));
  }, []);

  useEffect(() => {
    let tmpIndex = packageFeature?.PackageFeature?.Features.findIndex(
      (obj: { Name: string }) => obj.Name === "Design Components"
    );
    let currentFeature = packageFeature?.PackageFeature?.Features[tmpIndex];
    setDesignPackageFeature(currentFeature);
  }, [packageFeature]);

  const packageFeaturesLimited = useMemo(
    () => (packageFeatures?.find((s: any) => s.Id === 1)?.MaxCount || -1) > -1,
    [packageFeatures]
  );

  return (
    <>
      <ComponentsListContainer>
        <ComponentsList>
          <Header>{title}</Header>
          <InfoText>{infoText}</InfoText>
          {hasError && <ErrorMessage>{errorMessage} </ErrorMessage>}
          {designPackageFeature?.MaxCount > -1 && (
            <UpgradeComponent
              description={`${userPackage.PackageName} accounts are limited to
              ${designPackageFeature?.MaxCount}
              components.`}
              maxCount={designPackageFeature?.MaxCount}
              usedCount={componentTotalCount}
              pageType={"Category"}
            />
          )}
          <Container
            style={{ flex: 1 }}
            groupName="1"
            behaviour="copy"
            dragClass="border-ghost"
            getChildPayload={(i) => components[i]}
          >
            {components.map((item, index) => {
              const tempComponent = item!.component;
              const component = React.cloneElement(tempComponent, {
                ...tempComponent.props,
                isInEmulator: false,
              });
              return (
                <Component
                  key={index.toString()}
                  className={`Component-${index}`}
                >
                  <div ref={index === 0 ? firstComponentRef : null}>
                    <TitleWrapper className="ghost-title">
                      <Title>
                        {item!.componentTitle}
                        {item?.componentId === ComponentType.CustomHtml &&
                          packageFeaturesLimited && (
                            <UpgradeTooltip
                              info
                              label={"HTML"}
                              customStyle={{ width: 12, height: 12 }}
                            />
                          )}
                      </Title>
                        <YoutubeComponent componentId={item?.componentId} />
                      {item?.IsNewBadge && (
                        <NewBadge>
                          <NewBadgeTitle>NEW!</NewBadgeTitle>
                          <span>🥳</span>
                        </NewBadge>
                      )}
                    </TitleWrapper>
                    <ClickGuard>{component}</ClickGuard>
                  </div>
                </Component>
              );
            })}
          </Container>
        </ComponentsList>
        <ContactContainer isInList screen={CONTACT_SCREENS.DESIGN} />
      </ComponentsListContainer>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    selectedApp: state.userInfo.selectedApp,
    productDetailData: state.productDetail.data.productDetails,
    productListData: state.productList.data.productList,
    homeData: state.home.data.home,
    packageFeature: state.packageFeature?.featureData,
    userPackage: state.userInfo.UserPackage,
    packageFeatures:
      state.packageFeature?.featureData?.PackageFeature?.Features,
  };
};

export default connect(mapStateToProps)(Components);
