import { setChangedSthDesignPage } from "panel/src/redux/actions/manageApp";
import Icon from "panel/src/_components/common/Icon";
import React from "react";
import { ContextMenuTrigger } from "react-contextmenu";
import { useDispatch } from "react-redux";
import Container from "./Container";
import Menu from "./Menu";
import MenuItem from "./MenuItem";
import MenuWrapper from "./MenuWrapper";

export interface IOptionsMenu {
  children: React.ReactNode;
  componentIndex: number;
  editComponent: Function;
  removeComponent: Function;
  isEditable: boolean;
  preventRemoval: boolean;
}

// TODO
// react-contextmenu kullanmak yerine portal ile menu componenti oluşturmak gerekecek.
const OptionsMenu = ({
  children,
  componentIndex,
  editComponent,
  removeComponent,
  isEditable,
  preventRemoval,
}: IOptionsMenu) => {
  const dispatch = useDispatch();

  return (
    <Container>
      <ContextMenuTrigger id={`options-menu-${componentIndex}`}>
        {children}
      </ContextMenuTrigger>
      <MenuWrapper>
        {isEditable && (
          <MenuItem
            data={{ componentIndex }}
            onClick={() => {
              editComponent();
              dispatch(setChangedSthDesignPage(true));
            }}
            className='editMenuItem'
          >
            <Icon type='editIcon' width='16' height='16' className='editIcon' />
          </MenuItem>
        )}
        {!preventRemoval && (
          <MenuItem
            data={{ componentIndex }}
            onClick={() => {
              removeComponent();
              dispatch(setChangedSthDesignPage(true));
            }}
            className='trashMenuItem'
          >
            <Icon
              type='trashIcon'
              width='16'
              height='16'
              className='trashIcon'
            />
          </MenuItem>
        )}
      </MenuWrapper>
    </Container>
  );
};

export default OptionsMenu;
