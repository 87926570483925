import { baseUrl } from "./config";

import axios from "axios";

const instance = axios.create({
  baseURL: baseUrl,
});

const token = localStorage.getItem("AUTH_TOKEN");

if (token)
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

instance.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("AUTH_TOKEN");
      localStorage.removeItem("AUTH_EXP_DATE");
      delete instance.defaults.headers.common["Authorization"];
    }

    return Promise.reject(error);
  }
);

export default instance;
