import axios from "panel/src/services/api/api";
import * as setPaymentOptionsActions from "panel/src/redux/actions/setPaymentOptions";
import { SET_PAYMENT_OPTIONS_START } from "panel/src/redux/constants/setPaymentOptions";
import * as userInfoActions from "panel/src/redux/actions/userInfo";
import { takeLatest, put, call } from "redux-saga/effects";
import * as pageActions from "panel/src/redux/actions/page";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";

function* setPaymentOptionsProcess({
  data,
  type,
}: {
  data: { ApplicationId: string };
  type: string;
}) {
  yield put(startAppLoading(SET_PAYMENT_OPTIONS_START));
  try {
    yield call(axios.post, "/api/Manage/SetPaymentOptions", data);
    yield put(pageActions.pageUpdated(true));
  } catch (error) {
    yield put(setPaymentOptionsActions.setPaymentOptionsRejected(error));
  }

  yield put(userInfoActions.getUserInfo());
  yield put(endAppLoading(SET_PAYMENT_OPTIONS_START));
}

export default function* setPaymentOptionsProcessSaga() {
  yield takeLatest(SET_PAYMENT_OPTIONS_START, setPaymentOptionsProcess);
}
