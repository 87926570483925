import { useEffect } from "react";
import { useFormikContext } from "formik";
import { flattenObject } from "panel/src/utils/helper";

const FormikErrorFieldFocus = () => {
  const { errors, touched } = useFormikContext();

  useEffect(() => {
    const keyPaths = flattenObject(errors);
    const keys = Object.keys(keyPaths);
    const errorElement = document.querySelector(`[id="${keys[0]}"]`);

    if (errorElement) {
      errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [touched]);

  return null;
};

export default FormikErrorFieldFocus;
