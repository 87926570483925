import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import IGetProductDetailDataRequest from "../../models/Design/productDetail/IGetProductDetailDataRequest";
import { IProductDetailData } from "../../models/Design/productDetail/IGetProductDetailDataResponse";
import {
  getProductDetailDataSuccess,
  getProductDetailDataFail,
} from "panel/src/redux/actions/productDetail";
import { GET_PRODUCT_DETAIL_DATA } from "panel/src/redux/constants/productDetail";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import { getCategories } from "../actions/category";

function* getProductDetail(payload: {
  requestObj: IGetProductDetailDataRequest;
  type: string;
}) {
  yield put(startAppLoading("GET_PRODUCT_DETAIL"));
  try {
    const request = {
      ApplicationId: payload.requestObj.applicationId,
      SsoUserId: payload.requestObj.userId,
      PlatformId: payload.requestObj.platformId,
    };

    const [productDetailDataResponse, colorsData, fontTypeData]: [
      IProductDetailData,
      any,
      any
    ] = yield all([
      call(
        axios.post,
        "/Component/api/Component/GetProductDetailPageData",
        request
      ),
      call(axios.post, "/api/Design/GetColors", {
        ApplicationId: request.ApplicationId,
      }),
      call(axios.post, "/api/Manage/GetFonts", {
        ApplicationId: request.ApplicationId,
      }),
      put(getCategories()),
    ]);

    const productDetailPageData = {
      productDetails: productDetailDataResponse.data.ResultData,
      colors: colorsData.data.ResultData,
      fonts: fontTypeData.data.ResultData,
    };

    yield put(getProductDetailDataSuccess(productDetailPageData));
  } catch (error) {
    yield put(getProductDetailDataFail(error.message));
  }
  yield put(endAppLoading("GET_PRODUCT_DETAIL"));
}

export default function* getProductListSaga() {
  yield takeLatest(GET_PRODUCT_DETAIL_DATA, getProductDetail);
}
