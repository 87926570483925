import {
    GET_COLORS_START,
    GET_COLORS_FINISH,
    GET_COLORS_REJECTED,
    SET_COLORS_START,
    SET_COLORS_FINISH,
    SET_COLORS_REJECTED,
} from "panel/src/redux/constants/colors";

const initialState = {
    colors: [],
};

interface BaseAction {
    type: string;
    colors?: any;
    error?: any;
    appId?: any;
}

const colorsReducer = (state: Object = initialState, action: BaseAction) => {
    switch (action.type) {
        case GET_COLORS_START:
            return {
                ...state,
            };

        case GET_COLORS_FINISH:
            return {
                ...state,
                colors: action.colors,
            };
        case GET_COLORS_REJECTED:
            return {
                ...state,
                error: action.error,
            };

        case SET_COLORS_START:
            return {
                ...state,
            };

        case SET_COLORS_FINISH:
            return {
                ...state,
            };
        case SET_COLORS_REJECTED:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};

export default colorsReducer;
