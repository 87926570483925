import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
} from "panel/src/redux/constants/login";

interface BaseLoginAction {
  type: string;
  token?: any;
  error?: any;
  loading?: any;
}

export const loginStart = ( values: any ) => {
  return {
    type: LOGIN_START,
    values
  }
};

export const loginSuccess = ( token: any ) => {
  return {
    type: LOGIN_SUCCESS,
    token
  }
};

export const loginRejected = ( error: any ) => {
  return {
    type: LOGIN_FAIL,
    error: true,
  }
};
