export const GET_DESIGN_DATA: string = "GET_DESIGN_DATA";
export const GET_HOME_DATA: string = "GET_HOME_DATA";
export const GET_HOME_DATA_SUCCESS: string = "GET_HOME_DATA_SUCCESS";
export const GET_HOME_DATA_FAIL: string = "GET_HOME_DATA_FAIL";
export const RESET_HOME_DATA: string = "RESET_HOME_DATA";

export const SAVE_SCREEN_DATA: string = "SAVE_SCREEN_DATA";
export const SAVE_SCREEN_DATA_SUCCESS: string = "SAVE_SCREEN_DATA_SUCCESS";
export const SAVE_SCREEN_DATA_FAIL: string = "SAVE_SCREEN_DATA_FAIL";
export const RESET_SAVE_SCREEN_DATA: string = "RESET_SCREEN_DATA";

export const COMPONENT_CHANGE: string = "COMPONENT_CHANGE";
export const RESET_CHANGES: string = "RESET_CHANGES";

export const SET_EMULATOR_OS: string = "SET_EMULATOR_OS";

export const START_APP_LOADING: string = "START_APP_LOADING";
export const END_APP_LOADING: string = "END_APP_LOADING";
export const SET_APP_ERROR: string = "SET_APP_ERROR";
export const SET_APP_RESET: string = "SET_APP_RESET";
export const FORGOT_PASSWORD: string = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS: string = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL: string = "FORGOT_PASSWORD_FAIL";
export const SET_NEW_PASSWORD: string = "SET_NEW_PASSWORD";
export const SET_NEW_PASSWORD_SUCCESS: string = "SET_NEW_PASSWORD_SUCCESS";
export const SET_NEW_PASSWORD_FAIL: string = "SET_NEW_PASSWORD_FAIL";
export const SHOW_COMPONENTS_MENU_INFO_CARD: string =
  "SHOW_COMPONENTS_MENU_INFO_CARD";
export const HIDE_COMPONENTS_MENU_INFO_CARD: string =
  "HIDE_COMPONENTS_MENU_INFO_CARD";
export const SHOW_TOGGLE_OS_INFO_CARD: string = "SHOW_TOGGLE_OS_INFO_CARD";
export const HIDE_TOGGLE_OS_INFO_CARD: string = "HIDE_TOGGLE_OS_INFO_CARD";
export const RESET_INFO_CARD: string = "RESET_INFO_CARD";
