import React, { useState } from "react";
import styles from './chooseLogo.module.scss';
import LeftIcon from "./Icons/LeftIcon";
import CenterIcon from "./Icons/CenterIcon";
import EmptyIcon from "./Icons/EmptyIcon";
import { useDispatch, useSelector } from "react-redux";
import { setLogoPosition } from "../../../redux/actions/logoPosition";

type LogoType = {
    id: number;
    name: string;
    value: string;
}

export const LOGO_ALIGNS: LogoType[] = [
    {
        id: 2,
        name: 'Left',
        value: 'left',
    },
    {
        id: 1,
        name: 'Center',
        value: 'center',
    },
    {
        id: 3,
        name: 'No Logo',
        value: 'none',
    },
]


const ChooseLogo = () => {
    const dispatch = useDispatch();
    const { position } = useSelector((state: any) => state.logoPosition);

    const handleClick = (logo: LogoType) => {
        dispatch(setLogoPosition(logo.value))
    };

    return (
        <div className={styles.container}>
            {LOGO_ALIGNS.map((item, index) => (
                <div
                    key={index}
                    className={`${styles.box} ${position === item.value ? styles.selected : ""}`}
                    onClick={() => handleClick(item)}
                >
                    {item.value === 'left' && <LeftIcon />}
                    {item.value === 'center' && <CenterIcon />}
                    {item.value === 'none' && <EmptyIcon />}
                    <div className={styles.text}>
                        {item.name}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ChooseLogo;
