import React, { ChangeEvent, useState } from 'react';
import UploadIcon from './Icons/Upload';
import axios from 'panel/src/services/api/api';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { isImageContainsJustBlackColor } from '../../utils/pngColorHelper';
import { addNavigationTypeIcon } from '../../redux/actions/navigationType';

const Container = styled.div<{ isSelected?: boolean }>`
  position: relative;
  height: 58px;
  ${({ isSelected }) => !isSelected ? `
    border: 1px dashed #3196f6;
  ` : `
    box-shadow: 0 2px 15px 0 rgba(22,33,43,0.12);
  `}
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;
  margin-top: 12px;
  background-color: #dff0ff;
`;

const UploadMessage = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-size: 12px;
  color: #3196f6;
  pointer-events: none;
  user-select: none;
  display: flex;
  svg {
    margin-left: 12px;
  }
`;

const FileInput = styled.input`
  position: relative;
  z-index: 50;
  border: 1px solid red;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

const SelectedFileState = styled.div<{ hasError?: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 16px;
  z-index: 5;
  color: #0f3649;
  border: 1px solid white;
  border-radius: 4px;
  overflow: hidden;
  ${({ hasError }) => hasError && `border-color: #e66565;`}
`;

const FileName = styled.div`
  font-size: 14px;
  flex-grow: 1;
  margin-right: 11px;
`;

const ErrorMessage = styled.div`
  color: #e66565;
  font-size: 12px;
  margin: 10px 0;
`;

const LoadingElement = styled.div`
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 4px;
    border: 2px solid #0f3649;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0f3649 transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loading = () => (
  <LoadingElement>
    {Array(4).fill('').map((_, idx) => <div key={idx} />)}
  </LoadingElement>
)


const IconUpload = () => {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const validateIcon = async (file: File) => {
    if (file?.type !== 'image/png') {
      setErrorMessage('File must be png!');
      return false;
    }

    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = async function() {
        const _this: any = this;
        let isValid = true;
        if (_this?.width < 64 || _this?.height < 64) {
          setErrorMessage('The width and height of the image must be at least 64px.');
          isValid = false;
        }
        if (_this?.width !== _this?.height && isValid) {
          setErrorMessage('The image aspect ratio must be 1:1(Square).');
          isValid = false;
        }
        resolve(isValid);
      }

      img.onerror = () => resolve(false);
    });
  }

  const getBase64StringFromFile = async (file: File) => new Promise<string>(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => {
      resolve('')
    };
  });

  const uploadIconFile = async (base64: string, iconFileName: string) => {
    setIsLoading(true);
    try {
      const finalIcon = base64?.replace(/data:image\/.*?;base64,/gm, '');
      const { data } = await axios.post('/api/Design/UploadNavigationBarIcon', {
        NavigationBarIconFileExtension: 'png',
        NavigationBarIconFile: finalIcon,
        IconFileName: iconFileName,
      });
      const iconUrl = data?.ResultData?.NavigationBarIconUrl;
      if (iconUrl) {
        dispatch(addNavigationTypeIcon(iconUrl));
        setFileName('');
      }
    } catch (error) {
      const message = (error as any)?.response?.data?.ResultMesage;
      setErrorMessage(message as string);
    } finally {
      setIsLoading(false);
    }
  }


  const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    // CLEAR INPUT VALUE
    /* eslint-disable */
    // @ts-ignore
    e.target.value = null;
    if (file) {
      setErrorMessage('');
      setFileName(file?.name);
      const isValid = await validateIcon(file);
      
      if (isValid) {
        const base64string = await getBase64StringFromFile(file);
        const isBlack = await isImageContainsJustBlackColor(base64string);
        if (!isBlack) {
          setErrorMessage('The image must contain only black color.');
          return;
        }
        await uploadIconFile(base64string, file?.name);
      }
    }
  }

  return (
    <>
      <Container isSelected={!!fileName}>
        {!!fileName && (
          <SelectedFileState hasError={!!errorMessage}>
            <FileName>{fileName}</FileName>
            {isLoading ? <Loading /> : <UploadIcon />}
          </SelectedFileState>
        )}
        <FileInput type="file" accept="image/png" onChange={onChangeFile} />
        <UploadMessage>
          Add new custom icon
          <UploadIcon />
        </UploadMessage>
      </Container>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </>
  );
};

export default IconUpload;
