import styled from "styled-components";

export default styled.label`
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
  color: #3e5e6e;
`;
