import {
  CREATE_APP_START,
  CREATE_APP_FINISH,
  CREATE_APP_REJECTED,
  DELETE_APP_START,
  DELETE_APP_FINISH,
  DELETE_APP_REJECTED,
  RENAME_APP_START,
  RENAME_APP_FINISH,
  RENAME_APP_REJECTED,
} from "panel/src/redux/constants/apps";

import { CreateAppRequestModel } from "../../models/Manage/createAppRequestModel";
import { CreateAppResponseModel } from "../../models/Manage/createAppResponseModel";
import { DeleteAppRequestModel } from "../../models/Manage/deleteAppRequestModel";
import { DeleteAppResponseModel } from "../../models/Manage/deleteAppResponseModel";
import { RenameAppRequestModel } from "../../models/Manage/renameAppRequestModel";
import { RenameAppResponseModel } from "../../models/Manage/renameAppResponseModel";

export const createAppStart = (requestObj: CreateAppRequestModel) => {
  return {
    type: CREATE_APP_START,
    requestObj,
  };
};

export const createAppFinish = (result: CreateAppResponseModel) => {
  return {
    type: CREATE_APP_FINISH,
    result,
  };
};

export const createAppRejected = (error: string) => ({
  type: CREATE_APP_REJECTED,
  error,
});

export const deleteAppStart = (requestObj: DeleteAppRequestModel) => {
  return {
    type: DELETE_APP_START,
    requestObj,
  };
};

export const deleteAppFinish = (result: DeleteAppResponseModel) => {
  return {
    type: DELETE_APP_FINISH,
    result,
  };
};

export const deleteAppRejected = (error: string) => ({
  type: DELETE_APP_REJECTED,
  error,
});

export const renameAppStart = (requestObj: RenameAppRequestModel) => {
  return {
    type: RENAME_APP_START,
    requestObj,
  };
};

export const renameAppFinish = (result: RenameAppResponseModel) => {
  return {
    type: RENAME_APP_FINISH,
    result,
  };
};

export const renameAppRejected = (error: string) => ({
  type: RENAME_APP_REJECTED,
  error,
});
