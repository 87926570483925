import {
  LOGOUT_START, LOGOUT_SUCCESS, LOGOUT_FAIL,
} from "panel/src/redux/constants/logout";

export type LoginStateTypes = Readonly<{
  loading?: boolean,
  error?: boolean,
}>;

const initialState: LoginStateTypes = {
  loading: false,
  error: false,
}

const logoutReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGOUT_START:
      return {
        ...state,
        loading: true,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        error: false,
        loading: true
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        error: true,
        loading: false
      };
    default:
      return state;
  }
};

export default logoutReducer;


