import React from "react";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
  type: string;
}

const HideEye = ({ width, height, fillColor }: IProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 13.42a2.576 2.576 0 0 0-2.39 3.555l3.365-3.364c-.3-.124-.629-.191-.975-.191zM11.42 16a4.576 4.576 0 0 1 7.817-3.237 1 1 0 0 1 0 1.414l-5.06 5.06a1 1 0 0 1-1.414 0A4.56 4.56 0 0 1 11.42 16z"
      fill={fillColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.047 12.873C8.47 9.06 12.046 6.73 16 6.73c2.31 0 4.517.805 6.422 2.242a1 1 0 0 1-1.204 1.596C19.623 9.365 17.83 8.73 16 8.73c-3.106 0-6.11 1.828-8.266 5.217l-.001.001c-.323.506-.518 1.251-.518 2.057s.195 1.551.518 2.057c.742 1.165 1.6 2.159 2.519 2.95a1 1 0 0 1-1.304 1.516c-1.08-.929-2.063-2.075-2.901-3.39-.577-.905-.832-2.05-.832-3.133 0-1.084.255-2.228.832-3.132zM23.423 11.15a1 1 0 0 1 1.406.153c.389.483.772 1.004 1.125 1.561.577.904.831 2.048.831 3.131 0 1.084-.255 2.228-.832 3.132C23.53 22.94 19.954 25.27 16 25.27c-1.372 0-2.713-.29-3.968-.818a1 1 0 0 1 .776-1.844A8.215 8.215 0 0 0 16 23.27c3.106 0 6.11-1.828 8.266-5.217l.001-.001c.323-.506.518-1.251.518-2.057s-.195-1.551-.518-2.057l-.001-.002a15.211 15.211 0 0 0-.995-1.38 1 1 0 0 1 .152-1.405z"
      fill={fillColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.691 15.717a1 1 0 0 1 .802 1.164 4.565 4.565 0 0 1-3.622 3.622 1 1 0 1 1-.362-1.966 2.565 2.565 0 0 0 2.018-2.018 1 1 0 0 1 1.164-.802zM14.177 17.823a1 1 0 0 1 0 1.414l-7.47 7.47a1 1 0 1 1-1.414-1.414l7.47-7.47a1 1 0 0 1 1.414 0zM26.707 5.293a1 1 0 0 1 0 1.414l-7.47 7.47a1 1 0 0 1-1.414-1.414l7.47-7.47a1 1 0 0 1 1.414 0z"
      fill={fillColor}
    />
  </svg>
);

export default HideEye;
