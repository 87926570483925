import {
  SET_INSTAGRAM_USER_DATA,
  SET_INSTAGRAM_USER_DATA_SUCCESS,
  SET_INSTAGRAM_USER_DATA_FAIL,
  RESET_INSTAGRAM_USER_DATA,
} from "panel/src/redux/constants/setInstagramUser";

interface BaseAction {
  type: string;
  data?: Object;
  error?: string;
  requestObj?: any;
}

export const setInstagramUserData = () => ({
  type: SET_INSTAGRAM_USER_DATA,
});

export const setInstagramUserDataSuccess = (data: Object): BaseAction => ({
  type: SET_INSTAGRAM_USER_DATA_SUCCESS,
  data,
});

export const setInstagramUserDataFail = (error: string): BaseAction => ({
  type: SET_INSTAGRAM_USER_DATA_FAIL,
  error,
});

export const resetInstagramUserData = (): BaseAction => ({
  type: RESET_INSTAGRAM_USER_DATA,
});
