import styled from "styled-components";
import { IEditCard } from "./index";

interface IArrow {
  placement: IEditCard["editCardPlacement"];
}

export default styled.div<IArrow>`
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: -1;

  &::before {
    content: "";
    position: absolute;
    transform: rotate(45deg);
    background: #fff;
    width: 15px;
    height: 15px;
    top: 0;
    left: 0;
  }

  ${(props) =>
    ({
      left: "right: -4px;",
      right: "left: -4px;",
      top: "bottom: -4px;",
      bottom: "top: -4px;",
      auto: "top: -4px;",
      // Bu noktadan sonrası kullanılmayacaktır ama ts
      // bunlarından tanımlamalarını zorunlu kılıyor.
      // tyvm
      "auto-start": "top: -4px;",
      "auto-end": "top: -4px;",
      "top-start": "top: -4px;",
      "top-end": "top: -4px;",
      "bottom-start": "top: -4px;",
      "bottom-end": "top: -4px;",
      "right-start": "top: -4px;",
      "right-end": "top: -4px;",
      "left-start": "top: -4px;",
      "left-end": "top: -4px;",
    }[props.placement!])}
`;
