import React from "react";
import Svg, { Path } from "react-native-svg";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
}

const UnratedRating = ({ width, height, fillColor }: IProps) => (
  <Svg width={width} height={height} viewBox="0 0 10 10">
    <Path
      fill={fillColor}
      fillRule="evenodd"
      d="M11.9935744,0.126418727 C12.2723981,0.126418727 12.5278173,0.284877842 12.6552788,0.5369325 L16.0604588,7.2323625 L23.3615175,8.441475 C23.6402748,8.48304103 23.8720182,8.68108619 23.9601678,8.9530744 C24.0483175,9.22506261 23.9777492,9.52432777 23.7778725,9.72615 L18.57345,15.076425 L19.6886813,22.5275625 C19.7292733,22.8068336 19.6134402,23.0858333 19.3882799,23.2511197 C19.1631197,23.416406 18.866127,23.4404547 18.61806,23.3134875 L12.0010088,19.912875 L5.38396125,23.3134875 C5.25092213,23.3820419 5.10068112,23.4083684 4.9527375,23.38905 C4.75475334,23.362744 4.57548126,23.2566559 4.45523288,23.0946411 C4.3349845,22.9326263 4.2838531,22.7282837 4.31333625,22.5275625 L5.4285675,15.076425 L0.22414875,9.72615 C0.0248174526,9.52641123 -0.0473475794,9.22986004 0.0373928636,8.95870519 C0.122133307,8.68755034 0.349436674,8.48768551 0.62563125,8.441475 L7.92669,7.2323625 L11.33187,0.5369325 C11.4593315,0.284877842 11.7147506,0.126418727 11.9935744,0.126418727 Z"
    />
  </Svg>
);

export default UnratedRating;
