import { NotificationModule } from "../../../models/Notification";
import api from "../api";

export const sendNotificationService = async (
  request: NotificationModule.NotificationRequest
) => {
  return await api.post("/api/Manage/SendNotification", request);
};

export const getNotificationsService = async (appId: string) => {
  return await api.get(`/api/Manage/GetPastNotificationByAppId?AppId=${appId}`);
};
