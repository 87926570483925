import {
  START_APP_LOADING,
  END_APP_LOADING,
  SET_APP_ERROR,
  SET_APP_RESET,
} from "panel/src/redux/constants";

export var startAppLoading = (id: string) => {
  return {
    type: START_APP_LOADING,
    id,
  };
};

export var endAppLoading = (id: string) => {
  return {
    type: END_APP_LOADING,
    id,
  };
};

export const setAppError = (errorMessage: string) => ({
  type: SET_APP_ERROR,
  errorMessage,
});

export const setAppReset = () => ({
  type: SET_APP_RESET,
});
