import styled from "styled-components";

interface ITabText {
  isActive?: boolean;
  fontFamily?: string;
  activeColor?: string;
}

export default styled.div<ITabText>`
  font-size: 9px;
  font-family: ${(props) =>
    props.fontFamily ? `${props.fontFamily} Bold` : "Helvetica Neue Bold"};
  text-align: center;
  color: ${(props) => (props.isActive ? props.activeColor : "#0f3649")};
`;
