import styled from "styled-components/native";
import { colors } from "shared-components/src/utils/constants";
import { Platform } from "react-native";

export default styled.TouchableOpacity`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${colors.white};
  box-shadow: 0px 0px 5px rgba(15, 54, 73, 0.25);
  ${Platform.OS === "web" && `flex-shrink: initial !important;`}
`;
