import React, { useEffect, useRef } from "react";
import validationSchema from "./validationSchema";
import { Form, Field, Formik } from "formik";
import Input from "panel/src/Components/Input";
import Dropdown from "panel/src/Components/Dropdown";
import Button from "panel/src/Components/Button";
import ImageResizeType, {
  TImageResizeType,
  TResizeTypeValue,
} from "panel/src/Components/ImageResizeType";
import { connect } from "react-redux";
import { componentChange } from "panel/src/redux/actions/Home";
import { usePrevious } from "panel/src/utils/helper";
interface ILargeCategoryView {
  hideComponentEdit?: Function;
  editFormClickedOutside?: number;
  setComponentFormData?: Function;
  setImageResizeType?: Function;
  Id?: string;
  formData?: ILargeCategoryViewFormData;
  rawFormData?: any;
  categories: { label: string; value: string }[];
  setInfoPopup?: Function;
  componentChange: Function;
  appId: string;
  index?: Number;
}

type ILargeCategoryViewFormData = {
  Title: string;
  CategoryId: string;
  imageResizeType: TResizeTypeValue;
};

const LargeCategoryView = ({
  hideComponentEdit,
  editFormClickedOutside,
  setComponentFormData,
  setImageResizeType,
  Id,
  formData,
  rawFormData,
  categories,
  setInfoPopup,
  componentChange,
  appId,
  index,
}: ILargeCategoryView) => {
  const prevProps = usePrevious({ formData });

  useEffect(() => {
    if (prevProps && formData) {
      if (prevProps.formData !== formData) {
        const componentObj = {
          applicationId: appId,
          componentId: 6,
          formData: JSON.stringify(formData),
          index: index,
        };
        componentChange(componentObj);
      }
    }
  });

  useEffect(() => {
    if (editFormClickedOutside !== 0) {
      hideComponentEdit && hideComponentEdit(Id);
    }
  }, [editFormClickedOutside]);

  return (
    <Formik
      enableReinitialize
      initialValues={formData!}
      onSubmit={(values) => {
        setComponentFormData && setComponentFormData(Id, values);
        setInfoPopup && setInfoPopup(true);
      }}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, setFieldValue, errors, touched }) => {
        return (
          <Form>
            <Field
              id="Title"
              name="Title"
              style={{ marginBottom: "1rem" }}
              label="Title"
              placeholder="Title"
              value={values.Title}
              onChangeHandler={handleChange}
              component={Input}
            />
            <Field
              id="CategoryId"
              name="CategoryId"
              label="Categories"
              placeholder="Select Category"
              options={categories}
              value={values.CategoryId}
              onChangeHandler={handleChange}
              handleError={errors}
              handleTouched={touched}
              component={Dropdown}
            />
            <Field
              id="imageResizeType"
              name="imageResizeType"
              style={{ marginTop: 16 }}
              resizeTypeValue={values.imageResizeType}
              typeOnClickHandler={(item: TImageResizeType) => {
                setFieldValue(`imageResizeType`, item.mode);
                const editedFormData = {
                  ...rawFormData,
                  imageResizeType: item.mode,
                };
                setImageResizeType && setImageResizeType(Id, editedFormData);
              }}
              component={ImageResizeType}
            />
            <Button
              id="apply-button"
              type="submit"
              style={{ marginTop: "1.5rem" }}
              buttonText="Apply"
            />
            <Button
              id="cancel-button"
              type="button"
              style={{ marginTop: "0.5rem" }}
              theme="white"
              buttonText="Cancel"
              onClick={() => hideComponentEdit && hideComponentEdit(Id)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  return {
    hasChange: state.bannerSettings.changes,
    appId: state.userInfo.selectedAppId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    componentChange: (val: any) => dispatch(componentChange(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LargeCategoryView);
