import axios from "axios";

const instance = axios.create({
  baseURL: "http://99.81.90.51:8081",
});

// Logging for development only
// Should be deleted in production

instance.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("AUTH_TOKEN");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
