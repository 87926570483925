import React from "react";
import Container from "./Container";
import Text from "./Text";

interface IDiscountPercentage {
  fontFamily?: any;
  secondary: string;
  percentage: string;
  style?: any;
}

const DiscountPercentage = ({
  fontFamily,
  secondary,
  percentage,
  style,
}: IDiscountPercentage) => {
  return (
    <Container backgroundColor={secondary} style={style}>
      <Text fontFamily={fontFamily}>{percentage}</Text>
    </Container>
  );
};

export default DiscountPercentage;
