import styled from "styled-components";

interface ISpan {
  isChange?: boolean;
}

export default styled.span<ISpan>`
  color: ${(props) => (props.isChange ? "#ffffff" : "#9fafb6")};
  font-size: 12px;
  font-weight: bold;
`;
