import React, { useState, useEffect } from "react";
import { GestureResponderEvent, View } from "react-native";
import { ColorListData } from "shared-components/src/models/colorListData";
import { dimenWidth } from "shared-components/src/utils/helper";
import { colors } from "shared-components/src/utils/constants";
import SoldOutText from "shared-components/src/components/ProductCard/SoldOutText";
import DiscountPercentage from "shared-components/src/components/ProductCard/DiscountPercentage";
import Image from "./Image";
import ProductInfo from "./ProductInfo";
import InfoWrapper from "./InfoWrapper";
import ProductName from "./ProductName";
import PriceContainer from "./PriceContainer";
import ProductPrice from "./ProductPrice";
import {
  AddToCartButton,
  AddToCartButtonText,
} from "../ProductCard/AddToCartButton";
import { ProductQty } from "../ProductQty";
import { FavoriteButton } from "../ProductCard/FavoriteButton";
import InfoView from "./InfoView";
import Wrapper from "./Wrapper";
import ProductWrapper from "./ProductWrapper";
import { IProduct } from "../../models/Product";
import ProductContent from "../ListView/ProductContent";
import { IProductCardProps } from "../ProductCard";

const SmallListView = ({
  product,
  handleProductSearch,
  isWishList,
  setFavorite,
  removeFavorite,
  colorList,
  fontFamily,
  imageResizeType,
  isFavorite,
  soldOutText = "SOLD OUT",
  isAddToCart = false,
  addToCartTitle = "Add to Cart",
  addToCart,
  onPressMinus,
  onPressPlus,
  qty = 0,
}: IProductCardProps) => {
  const [_product, setProduct] = useState(product);
  const isFavoriteTemp = isFavorite || _product.IsFavorite;

  useEffect(() => {
    setProduct(product);
  }, [product]);

  const handleSetFavorite = (item: IProduct) => {
    const _item = {
      ...item,
      IsFavorite: true,
    };
    setProduct(_item);
  };

  const handleRemoveFavorite = (item: IProduct) => {
    const _item = {
      ...item,
      IsFavorite: false,
    };
    setProduct(_item);
  };

  const toggleFavorite = () => {
    if (setFavorite && !isFavoriteTemp) {
      setFavorite(_product.ProductId, () => handleSetFavorite(_product));
    } else if (removeFavorite && isFavoriteTemp) {
      removeFavorite(_product.ProductId, () => handleRemoveFavorite(_product));
    }
  };

  return (
    <Wrapper
      onPress={() =>
        handleProductSearch && handleProductSearch(_product.ProductId)
      }
    >
      <Image
        source={{ uri: _product.Images[0].ImgUrl }}
        resizeMode={imageResizeType}
      />
      <ProductWrapper>
        <ProductInfo>
          <ProductContent>
            <ProductName
              fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
              primary={
                colorList ? colorList[0].colorCode : colors.darkSlateBlue
              }
            >
              {_product.ProductName}
            </ProductName>
            <PriceContainer>
              {_product.StrikeoutPrice?.Price !== 0 && (
                <ProductPrice
                  fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                  tertiary={
                    colorList ? colorList[2].colorCode : colors.darkSlateBlue
                  }
                  hasdiscount={_product.StrikeoutPrice?.Price}
                >
                  {_product.StrikeoutPrice?.PriceDescription}
                </ProductPrice>
              )}
              <ProductPrice
                fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                tertiary={
                  colorList ? colorList[2].colorCode : colors.darkSlateBlue
                }
              >
                {_product.Price.PriceDescription}
              </ProductPrice>
            </PriceContainer>
          </ProductContent>
          <InfoWrapper>
            <InfoView>
              {_product.HasStock ? null : (
                <SoldOutText
                  fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                  soldOutText={soldOutText}
                />
              )}
              {_product.RebatePercentage ? (
                <DiscountPercentage
                  fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                  secondary={
                    colorList ? colorList[1].colorCode : colors.darkSkyBlue
                  }
                  percentage={_product.RebatePercentage}
                  style={{ marginHorizontal: 2 }}
                />
              ) : null}
            </InfoView>
            {isWishList && (
              <FavoriteButton
                isFavorite={isFavoriteTemp}
                toggleFavorite={toggleFavorite}
                fillColor={
                  colorList ? colorList[0].colorCode : colors.darkSlateBlue
                }
              />
            )}
          </InfoWrapper>
        </ProductInfo>
        {isAddToCart &&
          _product.HasStock &&
          qty <= 0 &&
          !_product.HasVariant && (
            <AddToCartButton
              bgColor={colorList ? colorList[1].colorCode : colors.darkSkyBlue}
              onPress={() => addToCart?.(_product)}
              style={{ marginTop: 4 }}
            >
              <AddToCartButtonText
                fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
              >
                {addToCartTitle}
              </AddToCartButtonText>
            </AddToCartButton>
          )}
        {isAddToCart &&
          _product.HasStock &&
          qty > 0 &&
          !_product.HasVariant && (
            <ProductQty
              onPressMinus={onPressMinus}
              onPressPlus={onPressPlus}
              qty={qty}
              color={colorList ? colorList[1].colorCode : colors.darkSkyBlue}
              style={{ marginTop: 4 }}
            />
          )}
      </ProductWrapper>
    </Wrapper>
  );
};

export default SmallListView;
