import styled from "styled-components";

export default styled.div`
  display: flex;
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: rgba(15, 54, 73, 0.5);
  top: 0;
  left: 0;
  z-index: 104;
`;
