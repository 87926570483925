import React, { Component } from "react";
import SelectModel from "../../models/Design/selectModel";
import { designScreens, routeNames } from "../../utils/constants";
import GeneralSettings from "./GeneralSettings";
import { connect } from "react-redux";
import classes from "./design.module.scss";
import SplashScreen from "./SplashScreen";
import NavigationType from "./NavigationType";
import Categories from "./Categories";
import Home from "./Home";
import ProductList from "./ProductList";
import ProductDetail from "./ProductDetail";
import Color from "./Color";
import FontType from "./FontType";
import { DeviceType } from "../../utils/enums/deviceType";
import Modal from "../../_components/common/Modal";
import Button from "../../_components/common/Button";
import close from "../../assets/images/close.svg";
import Completion from "./Completion";
import { History } from "history";
import { resetHomeData } from "panel/src/redux/actions/Home";
import { Helmet } from "react-helmet";
import { setChangedSthDesignPage } from "panel/src/redux/actions/manageApp";
import { screenNumbers } from "./constants";
import Intercom from "../../Components/Intercom/Intercom";
import { EPlatform } from "../../utils/enums/manage";

interface IDesignProps {
  IsStep1Completed: boolean;
  IsStep2Completed: boolean;
  IsStep3Completed: boolean;
  IsStep4Completed: boolean;
  IsStep5Completed: boolean;
  IsStep6Completed: boolean;
  IsStep7Completed: boolean;
  IsStep8Completed: boolean;
  IsStep9Completed: boolean;
  IsStep10Completed: boolean;
  IsStep11Completed: boolean;
  IsStep13Completed: boolean;
  IsStep14Completed: boolean;
  isBlocking: boolean;
  history: History;
  _resetHomeData: Function;
  _getColorsPageData: Function;
  appId: string;
  platformId: number;
  _setChangedSthDesign: Function;
  _setModalSubmit: Function;
  packageFeatures: any;
}

interface IDesignState {
  options: SelectModel[];
  appIcon: string;
  deviceType: DeviceType;
  isBlocking: boolean;
  isModalVisible: boolean;
  navigateOption: string;
  isCompletion: boolean;
  selectedValue: string | undefined;
  url: string;
  isOnModalSubmit: boolean;
  error: boolean;
}
class Design extends Component<IDesignProps, IDesignState> {
  constructor(props: IDesignProps) {
    super(props);

    const hideCategoryStepPlatforms = [EPlatform.WooCommerce, EPlatform.Prestashop]

    // Hide category layout step if platform is WooCommerce, PrestaShop
    const designScreenList = hideCategoryStepPlatforms.includes(this.props.platformId)
      ? designScreens.filter(item => item.value !== '4')
      : designScreens;

    this.state = {
      options: designScreenList,
      appIcon: "",
      deviceType: DeviceType.IOS,
      isBlocking: false,
      isModalVisible: false,
      navigateOption: "",
      isCompletion: false,
      selectedValue: "1",
      url: "",
      isOnModalSubmit: false,
      error: false,
    };
  }

  packageFeaturesLimited =
    (this.props.packageFeatures?.find((s: any) => s.Id === 1)?.MaxCount || -1) >
    -1;

  static getDerivedStateFromProps(props: IDesignProps, state: IDesignState) {
    const {
      IsStep5Completed,
      IsStep6Completed,
      IsStep7Completed,
      IsStep8Completed,
      IsStep9Completed,
      IsStep10Completed,
      IsStep11Completed,
      IsStep13Completed,
      IsStep14Completed,
      packageFeatures,
    } = props;
    let screenOptions = state.options;

    const packageFeaturesLimited =
      (packageFeatures?.find((s: any) => s.Id === 1)?.MaxCount || -1) > -1;

    screenOptions.map((option) => {
      switch (option.value) {
        case screenNumbers.generalSettings:
          option.isComplated = IsStep5Completed;
          break;
        case screenNumbers.splashScreen:
          option.isComplated = IsStep6Completed;
          break;
        case screenNumbers.navigationType:
          option.isComplated = IsStep7Completed;
          break;
        case screenNumbers.categoryLayout:
          option.isComplated = IsStep14Completed;
          option.isPackageSupported = packageFeaturesLimited;
          break;
        case screenNumbers.home:
          option.isComplated = IsStep8Completed;
          break;
        case screenNumbers.productList:
          option.isComplated = IsStep9Completed;
          break;
        case screenNumbers.productDetail:
          option.isComplated = IsStep10Completed;
          break;
        case screenNumbers.color:
          option.isComplated = IsStep11Completed;
          break;
        case screenNumbers.fontType:
          option.isComplated = IsStep13Completed;
          option.isDisabled = packageFeaturesLimited;
          option.isPackageSupported = packageFeaturesLimited;
          break;
        default:
          option.isComplated = false;
          break;
      }
    });
    return {
      options: screenOptions,
    };
  }

  componentDidMount = () => {
    const {
      IsStep5Completed,
      IsStep6Completed,
      IsStep7Completed,
      IsStep8Completed,
      IsStep9Completed,
      IsStep10Completed,
      IsStep11Completed,
      IsStep13Completed,
      IsStep14Completed,
    } = this.props;
    let screenOptions = this.state.options;
    screenOptions.map((option) => {
      switch (option.value) {
        case screenNumbers.generalSettings:
          option.isComplated = IsStep5Completed;
          break;
        case screenNumbers.splashScreen:
          option.isComplated = IsStep6Completed;
          break;
        case screenNumbers.navigationType:
          option.isComplated = IsStep7Completed;
          break;
        case screenNumbers.categoryLayout:
          option.isComplated = IsStep14Completed;
          option.isPackageSupported = this.packageFeaturesLimited;
          break;
        case screenNumbers.home:
          option.isComplated = IsStep8Completed;
          break;
        case screenNumbers.productList:
          option.isComplated = IsStep9Completed;
          break;
        case screenNumbers.productDetail:
          option.isComplated = IsStep10Completed;
          break;
        case screenNumbers.color:
          option.isComplated = IsStep11Completed;
          break;
        case screenNumbers.fontType:
          option.isComplated = IsStep13Completed;
          option.isDisabled = this.packageFeaturesLimited;
          option.isPackageSupported = this.packageFeaturesLimited;
          break;
        default:
          option.isComplated = false;
          break;
      }

      const unCompletedSteps = screenOptions.filter(
        (opt) => opt.isComplated && opt.value != "9"
      );

      if (unCompletedSteps.length) {
        const completedSteps = unCompletedSteps.map((a) => Number(a.value));
        const lastCompletedStep = Math.max(...completedSteps);
        const selectedOption = screenOptions.find(
          (opt) => opt.value === (lastCompletedStep + 1).toString()
        );
        const selectedValue = selectedOption && selectedOption.value;
        this.setState({ options: screenOptions, selectedValue: selectedValue });
      } else {
        const selectedOption = this.state.options.find((opt) => opt.isSelected);
        const selectedValue = selectedOption && selectedOption.value;
        this.setState({ selectedValue: selectedValue });
      }
    });
  };

  componentDidUpdate(prevProps: any) {
    if (this.state.isOnModalSubmit) {
      this.setState({ isOnModalSubmit: false });
    }
    if (this.props.appId != prevProps.appId) {
      const {
        IsStep5Completed,
        IsStep6Completed,
        IsStep7Completed,
        IsStep8Completed,
        IsStep9Completed,
        IsStep10Completed,
        IsStep11Completed,
        IsStep13Completed,
        IsStep14Completed,
      } = this.props;
      let screenOptions = this.state.options;
      screenOptions.map((option) => {
        switch (option.value) {
          case screenNumbers.generalSettings:
            option.isComplated = IsStep5Completed;
            break;
          case screenNumbers.splashScreen:
            option.isComplated = IsStep6Completed;
            break;
          case screenNumbers.navigationType:
            option.isComplated = IsStep7Completed;
            break;
          case screenNumbers.categoryLayout:
            option.isComplated = IsStep14Completed;
            option.isPackageSupported = this.packageFeaturesLimited;
            break;
          case screenNumbers.home:
            option.isComplated = IsStep8Completed;
            break;
          case screenNumbers.productList:
            option.isComplated = IsStep9Completed;
            break;
          case screenNumbers.productDetail:
            option.isComplated = IsStep10Completed;
            break;
          case screenNumbers.color:
            option.isComplated = IsStep11Completed;
            break;
          case screenNumbers.fontType:
            option.isComplated = IsStep13Completed;
            option.isDisabled = this.packageFeaturesLimited;
            break;
          default:
            option.isComplated = false;
            break;
        }

        const unCompletedSteps = screenOptions.filter(
          (opt) => opt.isComplated && opt.value != "9"
        );

        if (unCompletedSteps.length) {
          const completedSteps = unCompletedSteps.map((a) => Number(a.value));
          const lastCompletedStep = Math.max(...completedSteps);
          const selectedOption = screenOptions.find(
            (opt) => opt.value === (lastCompletedStep + 1).toString()
          );
          const selectedValue = selectedOption && selectedOption.value;
          this.setState({
            options: screenOptions,
            selectedValue: selectedValue,
          });
        } else {
          const selectedOption = this.state.options.find(
            (opt) => opt.isSelected
          );
          const selectedValue = selectedOption && selectedOption.value;
          this.setState({ selectedValue: selectedValue });
        }
      });
    }
  }

  selectChangeHandler = (e: any) => {
    if (this.props.isBlocking) {
      this.setState({
        isModalVisible: true,
        navigateOption: e.target.value,
      });
    } else {
      const updatedOptions = this.state.options.map((option) => {
        return {
          ...option,
          isSelected: option.value === e.target.value,
        };
      });
      const selectedOption = updatedOptions.find((opt) => opt.isSelected);
      const selectedValue = selectedOption ? selectedOption.value : "";

      this.setState({
        options: updatedOptions,
        navigateOption: "",
        selectedValue: selectedValue,
      });
    }
  };

  appIconHandler = (appIcon: string) => {
    this.setState({ appIcon });
  };

  deviceTypeHandler = (deviceType: DeviceType) => {
    this.setState({ deviceType: DeviceType.IOS });
  };
  setError = (errorValue: boolean) => {
    this.setState({ error: errorValue });
  };

  selectChange = (optionIndex: string) => {
    const updatedOptions = this.state.options.map((option) => {
      return {
        ...option,
        isSelected: option.value === optionIndex,
      };
    });

    const selectedOption = updatedOptions.find((opt) => opt.isSelected);
    const selectedValue = selectedOption ? selectedOption.value : "";
    this.setState({ options: updatedOptions, selectedValue: selectedValue });
  };

  closeModal = (callback = () => {}) =>
    this.setState(
      {
        isModalVisible: false,
      },
      callback
    );

  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const updatedOptions = this.state.options.map((option) => {
        return {
          ...option,
          isSelected: option.value === this.state.navigateOption,
        };
      });
      this.setState({
        selectedValue: this.state.navigateOption,
        options: updatedOptions,
        navigateOption: "",
      });
      this.props._setChangedSthDesign(false);
    });

  handleSaveAndContinue = () => {
    if (this.state.error) {
      this.closeModal(() => {
        this.setState({ error: false });
        this.setState({
          isOnModalSubmit: false,
        });
      });
      return;
    }
    this.closeModal(() => {
      const updatedOptions = this.state.options.map((option) => {
        return {
          ...option,
          isSelected: option.value === this.state.navigateOption,
        };
      });

      this.setState({
        selectedValue: this.state.navigateOption,
        options: updatedOptions,
        navigateOption: "",
      });
      this.props._setChangedSthDesign(false);

      this.setState({
        isOnModalSubmit: false,
      });
    });
  };

  componentWillMount() {
    const selectedOption = this.state.options.find((opt) => opt.isSelected);
    const selectedValue = selectedOption ? selectedOption.value : "";
    this.setState({ selectedValue: selectedValue });
  }

  backToDesignStepFromPublish = () => {
    this.setState(prev => ({ ...prev, selectedValue: "9" }));
  }

  setHubspotPath = (path: string) => {
    return `/design${path}`;
  };



  render() {
    const { history } = this.props;
    const { deviceType, isCompletion, isOnModalSubmit } = this.state;

    let component = null;
    let path = "";
    let desingCategory = "General Settings";
    switch (this.state.selectedValue) {
      case screenNumbers.generalSettings:
        path = this.setHubspotPath("/general-settings");
        component = (
          <GeneralSettings
            history={history}
            selectChange={this.selectChange}
            changeHandler={this.appIconHandler}
            deviceType={deviceType}
            deviceTypeHandler={this.deviceTypeHandler}
            isCompletion={isCompletion}
            selectedValue={this.state.selectedValue}
            designScreens={this.state.options}
            designScreensChange={this.selectChangeHandler}
            isOnModalSubmit={isOnModalSubmit}
          />
        );
        break;
      case screenNumbers.splashScreen:
        desingCategory = "Splash Screen";
        path = this.setHubspotPath("/splash-screen");
        component = (
          <SplashScreen
            selectChange={this.selectChange}
            deviceType={deviceType}
            deviceTypeHandler={this.deviceTypeHandler}
            isCompletion={isCompletion}
            selectedValue={this.state.selectedValue}
            designScreens={this.state.options}
            designScreensChange={this.selectChangeHandler}
            isOnModalSubmit={isOnModalSubmit}
            setError={this.setError}
          />
        );
        break;
      case screenNumbers.navigationType:
        desingCategory = "Navigation Type";
        path = this.setHubspotPath("/navigation-type");
        component = (
          <NavigationType
            selectChange={this.selectChange}
            deviceType={deviceType}
            deviceTypeHandler={this.deviceTypeHandler}
            isCompletion={isCompletion}
            selectedValue={this.state.selectedValue}
            designScreens={this.state.options}
            designScreensChange={this.selectChangeHandler}
            isOnModalSubmit={isOnModalSubmit}
            setError={this.setError}
          />
        );
        break;
      case screenNumbers.categoryLayout:
        desingCategory = "Categories";
        path = this.setHubspotPath("/categories");
        component = (
          <Categories
            selectChange={this.selectChange}
            isCompletion={isCompletion}
            selectedValue={this.state.selectedValue}
            designScreens={this.state.options}
            designScreensChange={this.selectChangeHandler}
            isOnModalSubmit={isOnModalSubmit}
            setError={this.setError}
          />
        );
        break;
      case screenNumbers.home:
        desingCategory = "Home";
        path = this.setHubspotPath("/home");
        component = (
          <Home
            selectChange={this.selectChange}
            isCompletion={isCompletion}
            selectedValue={this.state.selectedValue}
            designScreens={this.state.options}
            designScreensChange={this.selectChangeHandler}
            isOnModalSubmit={isOnModalSubmit}
            setError={this.setError}
          />
        );
        break;
      case screenNumbers.productList:
        desingCategory = "Product List";
        path = this.setHubspotPath("/product-list");
        component = (
          <ProductList
            selectChange={this.selectChange}
            isCompletion={isCompletion}
            selectedValue={this.state.selectedValue}
            designScreens={this.state.options}
            designScreensChange={this.selectChangeHandler}
            isOnModalSubmit={isOnModalSubmit}
            setError={this.setError}
          />
        );
        break;
      case screenNumbers.productDetail:
        desingCategory = "Product Detail";
        path = this.setHubspotPath("/product-detail");
        component = (
          <ProductDetail
            selectChange={this.selectChange}
            isCompletion={isCompletion}
            selectedValue={this.state.selectedValue}
            designScreens={this.state.options}
            designScreensChange={this.selectChangeHandler}
            isOnModalSubmit={isOnModalSubmit}
          />
        );
        break;
      case screenNumbers.color:
        desingCategory = "Color";
        path = this.setHubspotPath("/color");
        component = (
          <Color
            selectChange={this.selectChange}
            deviceTypeHandler={this.deviceTypeHandler}
            isCompletion={isCompletion}
            selectedValue={this.state.selectedValue}
            designScreens={this.state.options}
            designScreensChange={this.selectChangeHandler}
            isModalOnSubmit={isOnModalSubmit}
          />
        );
        break;
      case screenNumbers.fontType:
        desingCategory = "Font Type";
        path = this.setHubspotPath("/font-type");
        component = (
          <FontType
            selectChange={this.selectChange}
            deviceTypeHandler={this.deviceTypeHandler}
            isCompletion={isCompletion}
            selectedValue={this.state.selectedValue}
            designScreens={this.state.options}
            designScreensChange={this.selectChangeHandler}
            isOnModalSubmit={isOnModalSubmit}
          />
        );
        break;
      default:
        desingCategory = "Completion";
        path = this.setHubspotPath("/design-completed");
        component = <Completion history={history} navigateBack={this.backToDesignStepFromPublish} />;
    }

    var _hsq = (window._hsq = window._hsq || []);

    const designStatus = JSON.stringify(
      [...this.state.options].map(item => ({ name: item.displayValue, isCompleted: item.isComplated }))
    );

    return (
      <React.Fragment>
        <Helmet
          onChangeClientState={() => {
            _hsq.push(["setPath", `/design${path}`]);
            _hsq.push(["trackPageView"]);
          }}
        >
          <title>{`Mowico Wizard Panel | ${routeNames[path]}`}</title>
        </Helmet>
        <Intercom
          designCategory={desingCategory}
          designState={designStatus}
        />
        <div className={classes.DesignContainer}>{component}</div>

        <Modal
          backdropClickedHandler={() => this.closeModal(() => {})}
          show={this.state.isModalVisible}
          width="448"
        >
          <div
            className={classes.Close}
            onClick={() => this.closeModal(() => {})}
          >
            <img src={close} />
          </div>
          <div className={classes.ModalBody}>
            <span className={classes.ModalBodyText}>
              You are about to leaving the current page. <br />
              Do you want to save your changes before continuing? <br />
              If you don't save your changes will be lost.
            </span>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonContainer}>
              <Button
                type="button"
                className="Ghost"
                width="192px"
                onClick={this.handleConfirmNavigationClick}
              >
                Discard Changes
              </Button>
              <Button
                type="button"
                className="Primary"
                width="192px"
                onClick={() => {
                  this.setState({
                    isOnModalSubmit: true,
                  });
                  setTimeout(() => {
                    this.handleSaveAndContinue();
                  }, 1);
                }}
              >
                Save and Continue
              </Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    _resetHomeData: () => dispatch(resetHomeData()),
    _setChangedSthDesign: (value: boolean) =>
      dispatch(setChangedSthDesignPage(value)),
  };
};
const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    platformId: state.userInfo.UserPackage.PlatformId,
    IsStep1Completed: state.userInfo.selectedApp.IsStep1Completed,
    IsStep2Completed: state.userInfo.selectedApp.IsStep2Completed,
    IsStep3Completed: state.userInfo.selectedApp.IsStep3Completed,
    IsStep4Completed: state.userInfo.selectedApp.IsStep4Completed,
    IsStep5Completed: state.userInfo.selectedApp.IsStep5Completed,
    IsStep6Completed: state.userInfo.selectedApp.IsStep6Completed,
    IsStep7Completed: state.userInfo.selectedApp.IsStep7Completed,
    IsStep8Completed: state.userInfo.selectedApp.IsStep8Completed,
    IsStep9Completed: state.userInfo.selectedApp.IsStep9Completed,
    IsStep10Completed: state.userInfo.selectedApp.IsStep10Completed,
    IsStep11Completed: state.userInfo.selectedApp.IsStep11Completed,
    IsStep13Completed: state.userInfo.selectedApp.IsStep13Completed,
    IsStep14Completed: state.userInfo.selectedApp.IsStep14Completed,
    isBlocking: state.manageApp.isDesignPageChange,
    packageFeatures:
      state.packageFeature?.featureData?.PackageFeature?.Features,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Design);
