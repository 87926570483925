import {
  GET_PACKAGE_FEATURE_LIST_START,
  GET_PACKAGE_FEATURE_LIST_SUCCESS,
  GET_PACKAGE_FEATURE_LIST_FAIL,
  SET_PACKAGE_FEATURE_LIST_START,
  SET_PACKAGE_FEATURE_LIST_SUCCESS,
  SET_PACKAGE_FEATURE_LIST_FAIL
} from "panel/src/redux/constants/getPackageFeature";


export type getEngagementInfoStateTypes = Readonly<{
  error?: boolean;
  loading?: boolean;
}>;

const initialState: getEngagementInfoStateTypes = {
  error: false,
  loading: false,
}


const getFeatureListReducer = ( state = initialState, action: any ) => {
  switch ( action.type ) {
    case GET_PACKAGE_FEATURE_LIST_START:
      return {
        ...state,
        data: action.data
      };

    case GET_PACKAGE_FEATURE_LIST_SUCCESS:
      return {
        ...state,
        featureData: action.featureData
      };
    case GET_PACKAGE_FEATURE_LIST_FAIL:
      return {
        ...state,
        error: true,
      };
    case SET_PACKAGE_FEATURE_LIST_START:
      return {
        ...state,
        data: action.data
      };

    case SET_PACKAGE_FEATURE_LIST_SUCCESS:
      return {
        ...state,
      };
    case SET_PACKAGE_FEATURE_LIST_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default getFeatureListReducer;
