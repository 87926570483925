import axios from "panel/src/services/api/api";
import * as userInfoActions from "panel/src/redux/actions/userInfo";
import {
  FETCH_USERINFO_START,
  GET_USER_INFO,
} from "panel/src/redux/constants/userInfo";
import { takeLatest, put, call } from "redux-saga/effects";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import { push } from "connected-react-router";
import { navigateAway } from "../../utils/helper";
import { setAppId } from "../actions/manageApp";

function* getUserInfoProcess({ token, type }: { token: string; type: string }) {
  //GetUserInfo post request to get user details and apps
  yield put(startAppLoading(FETCH_USERINFO_START));
  try {
    const userInfoRequestObj = {
      UserToken: token,
    };

    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const userInfoResponse = yield call(
      axios.post,
      "/api/login/GetUserInfo",
      userInfoRequestObj
    );

    const {
      name,
      surname,
      userId,
      AppList: apps,
      IsTrial: IsTrial,
      IsExpired,
      ExpireDate,
      emailAddress,
      RemainingDays,
      HubSpotToken,
      UserPackage
    } = userInfoResponse.data.ResultData;

    // window.hsConversationsSettings = {
    //   identificationEmail: emailAddress,
    //   identificationToken: HubSpotToken,
    // };

    // window.HubSpotConversations.widget.load();

    yield put(
      userInfoActions.fetchUserInfoSuccess({
        userName: `${name} ${surname ? surname : ""}`,
        userId,
        apps,
        IsTrial,
        IsExpired,
        emailAddress,
        ExpireDate,
        RemainingDays,
        UserPackage
      })
    );
    const selectedApp = apps.find((app: any) => app.IsSelected);
    const selectAppId = selectedApp?.AppId
    if (selectAppId) {
      yield put(setAppId(selectAppId))
    }

  } catch (error) {
    yield put(userInfoActions.fetchUserInfoFail(error));
  }
  yield put(endAppLoading(FETCH_USERINFO_START));
}

function* onGetUserInfo(payload: {
  type: string;
  data?: {
    redirect?: string;
    navigateAway?: boolean;
  };
}) {
  try {
    const token = localStorage.getItem("AUTH_TOKEN");
    const userInfoRequestObj = {
      UserToken: token,
    };

    const userInfoResponse = yield call(
      axios.post,
      "/api/login/GetUserInfo",
      userInfoRequestObj
    );

    const {
      name,
      surname,
      userId,
      AppList: apps,
      IsTrial: IsTrial,
      IsExpired,
      ExpireDate,
      emailAddress,
      RemainingDays,
      UserPackage
    } = userInfoResponse.data.ResultData;

    yield put(
      userInfoActions.fetchUserInfoSuccess({
        userName: `${name} ${surname ? surname : ""}`,
        userId,
        apps,
        IsTrial,
        IsExpired,
        emailAddress,
        ExpireDate,
        RemainingDays,
        UserPackage
      })
    );

    if (payload.data?.navigateAway) {
      const selectedApp = apps.find((app: any) => app.IsSelected);
      yield put(push(navigateAway(selectedApp)));
    }

    if (payload.data?.redirect) {
      yield put(push(payload.data?.redirect));
    }
  } catch (error) {
    yield put(userInfoActions.fetchUserInfoFail(error));
  }
}

export default function* getUserInfoProcessSaga() {
  yield takeLatest(FETCH_USERINFO_START, getUserInfoProcess);
  yield takeLatest(GET_USER_INFO, onGetUserInfo);
}
