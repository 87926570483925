import Header from "./Header";
import TabNavigation from "./TabNavigation";
import ProductListHeader from "./ProductListHeader";
import StatusBar from "./StatusBar";
import ProductDetailHeader from "./ProductDetailHeader";
import ProductDetailHeaderBackground from "./ProductDetailHeaderBackground";

export enum NavigationEnum {
  tabNavigation = 1,
  drawerNavigation,
}

export default {
  Header,
  TabNavigation,
  ProductListHeader,
  StatusBar,
  ProductDetailHeader,
  ProductDetailHeaderBackground,
};
