import React from "react";

interface ICloseProps {
  width: string;
  height: string;
  fillColor: string;
}

const Close = ({ width, height, fillColor }: ICloseProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m7.828 2.879-2.122 2.12 2.122 2.122-.707.707L5 5.706 2.88 7.828l-.707-.707L4.292 5l-2.12-2.12.707-.707 2.12 2.12 2.122-2.12.707.707z"
      fill={fillColor}
      fillRule="evenodd"
    />
  </svg>
);

export default Close;
