import React, { FC } from "react";
import { GestureResponderEvent } from "react-native";
import {
  QtyIcon,
  MinusButton,
  PlusButton,
  QtyText,
  QuantityWrapper,
  MinusButtonIcon,
} from "./ProductQty.styles";

interface IProductQty {
  onPressMinus?: (event: GestureResponderEvent) => void;
  onPressPlus?: (event: GestureResponderEvent) => void;
  qty: number;
  color?: string;
  style?: any;
}

const ProductQty: FC<IProductQty> = ({
  onPressMinus = () => {},
  onPressPlus = () => {},
  qty,
  color,
  style,
}) => {
  return (
    <QuantityWrapper style={style}>
      <MinusButton onPress={onPressMinus} color={color}>
        <MinusButtonIcon color={color}>-</MinusButtonIcon>
      </MinusButton>
      <QtyText>{qty}</QtyText>
      <PlusButton onPress={onPressPlus} color={color}>
        <QtyIcon>+</QtyIcon>
      </PlusButton>
    </QuantityWrapper>
  );
};

export default ProductQty;
