import styled from "styled-components";

export default styled.div`
  width: 448px;
  height: 228px;
  background: #fff;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  align-items: center;
  margin: auto;
`;
