import React from "react";

interface IProps {
    width: string;
    height: string;
    fillColor: string;
    type: string;
}

const Remove = ({ width, height, fillColor }: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
        <path fill={fillColor} fillRule="evenodd" d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm3.3 4.7c-.326-.325-.853-.325-1.179 0L8 6.821l-2.121-2.12c-.326-.326-.853-.326-1.179 0-.325.325-.325.852 0 1.178L6.821 8l-2.12 2.121c-.326.326-.326.853 0 1.179.325.325.852.325 1.178 0l2.12-2.121 2.122 2.12c.326.326.853.326 1.179 0 .325-.325.325-.852 0-1.178L9.179 8l2.12-2.121c.326-.326.326-.853 0-1.179z" />
    </svg>
);

export default Remove;
