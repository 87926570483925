import {
  SET_CATEGORIES,
  SET_EDIT_CATEGORIES,
} from "panel/src/redux/constants/category";

const initialState = {
  categories: [],
  editCategories: [],
};

const categoriesReducer = (state: Object = initialState, action: any) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case SET_EDIT_CATEGORIES:
      return {
        ...state,
        editCategories: action.payload,
      };
    default:
      return state;
  }
};

export default categoriesReducer;
