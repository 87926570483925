export enum ScreenType {
  GeneralSettings = 1,
  SplashScreen = 2,
  NavigationType = 3,
  CategoryLayout = 4,
  Home = 5,
  ProductList = 6,
  ProductDetail = 7,
  Color = 8,
  FontType = 9,
}
