import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Form, Field, Formik, FormikProps } from "formik";
import ImageUpload from "panel/src/Components/ImageUpload";
import Input from "panel/src/Components/Input";
import Dropdown from "panel/src/Components/Dropdown";
import Button from "panel/src/Components/Button";
import ImageResizeType, {
  TImageResizeType,
  TResizeTypeValue,
} from "panel/src/Components/ImageResizeType";
import FormikErrorFieldFocus from "panel/src/Components/FormikErrorFieldFocus";
import ErrorField from "panel/src/_components/common/ErrorField";
import Text from "./Text";
import { usePrevious, flattenObject } from "panel/src/utils/helper";
import { hasChange } from "panel/src/redux/actions/bannerSettings";
import validationSchema from "./validationSchema";
import { ComponentProductModule } from "../../../../../models/Design/home/ComponentProduct";
import {
  getProductListByCategoryId,
  resetProductListByCategoryIdProducts,
} from "../../../../../redux/actions/productList";
import { ELinkTypes } from "../../../../../utils/enums/design";
import InfoPopup from "../../../../../Components/InfoPopup";

interface IBanner {
  hideComponentEdit?: Function;
  editFormClickedOutside?: number;
  setComponentFormData?: Function;
  setImageResizeType?: Function;
  Id?: string;
  formData?: IBannerFormData;
  rawFormData?: any;
  height: string;
  linkTypes: { label: string; value: number }[];
  setInfoPopup?: Function;
  hasChangeReq: Function;
  _resetProductListByCategoryIdProducts: Function;
  _getProductListByCategoryId: (
    data: ComponentProductModule.TGetProductListByCategoryIdData
  ) => void;
  productListLoading: boolean;
  productList: ComponentProductModule.IProduct[];
  categories: { label: string; value: string }[];
}

type IBannerFormData = {
  Image: {
    ImgUrl: string;
  };
  Link: {
    Type: number;
    LinkTo: string;
    CategoryId: string;
    CategoryName: string;
    ProductId: string;
    ProductName: string;
    Search: string;
  };
  isEdited: boolean;
  imageResizeType: TResizeTypeValue;
};

let timeout: ReturnType<typeof setTimeout> = setTimeout(() => "", 1000);

const Banner = ({
  hideComponentEdit,
  editFormClickedOutside,
  setInfoPopup,
  setComponentFormData,
  setImageResizeType,
  Id,
  formData,
  rawFormData,
  height,
  linkTypes,
  hasChangeReq,
  categories,
  _getProductListByCategoryId,
  productListLoading,
  productList,
  _resetProductListByCategoryIdProducts,
}: IBanner) => {
  const formikRef = useRef<FormikProps<any>>(null);
  const [isSaveRequired, setIsSaveRequired] = useState(false);
  const checkIsRequired = () => {
    return !formData?.isEdited;
  };

  const prevProps = usePrevious({ formData });

  useEffect(() => {
    if (prevProps && formData) {
      if (prevProps.formData !== formData) {
        hasChangeReq();
      }
    }
  });

  useEffect(() => {
    if (editFormClickedOutside !== 0) {
      formikRef.current?.validateForm().then(() => {
        const isFormValid = formikRef.current?.isValid;

        if (isFormValid && checkIsRequired()) {
          setIsSaveRequired(true);
          const errorElement = document.querySelector(
            "[id='save-changes-error']"
          );
          if (errorElement) {
            errorElement.scrollIntoView({ behavior: "smooth" });
          }
        } else if (isFormValid || !checkIsRequired()) {
          hideComponentEdit && hideComponentEdit(Id, checkIsRequired());
        } else {
          const errorKeys = flattenObject(formikRef.current?.errors);
          Object.keys(errorKeys).forEach((key: any) => {
            formikRef.current?.setFieldTouched(key);
          });
        }
      });
    }
  }, [editFormClickedOutside]);

  const getInitialValue = () => {
    const _formData = {
      Image: {
        ImgUrl: {
          height: null,
          img: formData?.Image.ImgUrl,
          supportedHeight: null,
          type: null,
          width: null,
        },
      },
      Link: {
        Type: formData?.Link.Type,
        LinkTo: formData?.Link.LinkTo,
        CategoryId: formData?.Link?.CategoryId || '',
        CategoryName: formData?.Link?.CategoryName || '',
        ProductId: formData?.Link?.ProductId || '',
        ProductName: formData?.Link?.ProductName || '',
        Search: formData?.Link?.Search || '',
      },
      isEdited: false,
      imageResizeType: formData?.imageResizeType,
    };

    return _formData;
  };

  const getProductList = (search: string) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      _getProductListByCategoryId({
        CategoryId: formikRef.current?.values.Link.CategoryId,
        SearchWord: search,
      });
    }, 500);
  };

  const productListHandled = productList.map((product) => ({
    label: product.ProductName,
    value: product.Id,
  }));

  const categoriesHandled = categories?.sort((a, b) =>
    a.label > b.label ? 1 : b.label > a.label ? -1 : 0
  );

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={getInitialValue()}
      onSubmit={(values) => {
        const formData = {
          Image: { ImgUrl: values.Image.ImgUrl.img },
          Link: { ...values.Link },
          isEdited: true,
          imageResizeType: values.imageResizeType,
        };
        setComponentFormData && setComponentFormData(Id, formData);
        setInfoPopup && setInfoPopup(true);
      }}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, setFieldValue, errors, touched }) => {
        console.log('ERROR',errors)
        return (
          <Form>
            <Text>
              The image should be minimum 960x{height} px and 1 mb maximum size.
            </Text>
            <Field
              id="Image.ImgUrl"
              name="Image.ImgUrl"
              deneme="deneme"
              style={{ marginBottom: "1rem" }}
              height={height}
              editFormHeight={(Number(height) / 4)?.toString()}
              defaultValue={values.Image.ImgUrl}
              onChangeHandler={handleChange}
              handleError={errors}
              handleTouched={touched}
              component={ImageUpload}
            />
            <InfoPopup
              field={
                <Field
                  id="Link.Type"
                  name="Link.Type"
                  style={{ marginBottom: "1rem" }}
                  label="Link Type"
                  placeholder="Select Link Type"
                  options={linkTypes}
                  value={values.Link.Type}
                  onChangeHandler={handleChange}
                  handleError={errors}
                  handleTouched={touched}
                  component={Dropdown}
                />
              }
              descriptions={[
                {
                  key: "Category",
                  value: `Select a specific category to redirect the related banner in your app.`,
                },
                {
                  key: "Product",
                  value: `Select a category & product name to redirect the related banner in your app.`,
                },
                {
                  key: "Web View",
                  value: `Type a URL to redirect the related banner in a WebView.`,
                },
                {
                  key: "Search",
                  value: `Type a text to redirect the related banner to search result page in your app.`,
                },
              ]}
            />
            {(values.Link.Type === ELinkTypes.Category ||
              values.Link.Type === ELinkTypes.Product) && (
              <Field
                id="Link.CategoryId"
                name="Link.CategoryId"
                label="Link to"
                placeholder="Select a category"
                options={categoriesHandled}
                value={values.Link.CategoryId}
                onChangeHandler={(e: any) => {
                  handleChange(e);
                  _resetProductListByCategoryIdProducts();
                  setFieldValue("Link.CategoryName", e.target.label);
                  setFieldValue("Link.ProductId", "");
                  setFieldValue("Link.ProductName", "");
                }}
                handleError={errors}
                handleTouched={touched}
                component={Dropdown}
              />
            )}

            {values.Link.Type === ELinkTypes.Product && (
              <Field
                id="Link.ProductName"
                name="Link.ProductName"
                placeholder="Type product name"
                value={values.Link.ProductName}
                onChangeHandler={(e: any) => {
                  setFieldValue("Link.ProductName", e.target.label);
                  setFieldValue("Link.ProductId", e.target.value);
                }}
                handleError={errors}
                handleTouched={touched}
                component={Dropdown}
                options={productListHandled}
                disabled={!values.Link.CategoryId}
                loading={productListLoading}
                searchable={true}
                onSearchValueChange={(e: any) => {
                  getProductList(e.target.value);
                  setFieldValue("Link.ProductName", "");
                  setFieldValue("Link.ProductId", "");
                }}
              />
            )}

            {values.Link.Type === ELinkTypes.Search && (
              <Field
                id={"Link.Search"}
                name={"Link.Search"}
                label="Link to"
                placeholder="Type a word"
                value={values.Link.Search}
                onChangeHandler={handleChange}
                handleError={errors}
                handleTouched={touched}
                component={Input}
              />
            )}

            {(values.Link.Type === ELinkTypes.Href ||
              values.Link.Type === ELinkTypes.WebViewURL) && (
              <Field
                id="Link.LinkTo"
                name="Link.LinkTo"
                label="Link to"
                placeholder="Type or paste a URL"
                value={values.Link.LinkTo}
                onChangeHandler={handleChange}
                handleError={errors}
                handleTouched={touched}
                component={Input}
              />
            )}
            <Field
              id="imageResizeType"
              name="imageResizeType"
              style={{ marginTop: 16 }}
              resizeTypeValue={values.imageResizeType}
              typeOnClickHandler={(item: TImageResizeType) => {
                setFieldValue(`imageResizeType`, item.mode);
                const editedFormData = {
                  ...rawFormData,
                  imageResizeType: item.mode,
                };
                setImageResizeType && setImageResizeType(Id, editedFormData);
              }}
              component={ImageResizeType}
            />
            <FormikErrorFieldFocus />
            <Button
              id="apply-button"
              type="submit"
              style={{ marginTop: "1.5rem" }}
              buttonText="Apply"
            />
            {isSaveRequired && (
              <ErrorField
                id="save-changes-error"
                text="Please save the changes"
              />
            )}
            <Button
              id="cancel-button"
              type="button"
              style={{ marginTop: "0.5rem" }}
              theme="white"
              buttonText={checkIsRequired() ? "Delete" : "Cancel"}
              onClick={() =>
                hideComponentEdit && hideComponentEdit(Id, checkIsRequired())
              }
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  return {
    productList: state.productList.productList,
    productListLoading: state.productList.productListLoading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _getProductListByCategoryId: (
      data: ComponentProductModule.TGetProductListByCategoryIdData
    ) => dispatch(getProductListByCategoryId(data)),
    _resetProductListByCategoryIdProducts: () =>
      dispatch(resetProductListByCategoryIdProducts()),
    hasChangeReq: () => dispatch(hasChange()),
    disCardChanges: () => dispatch(hasChange()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
