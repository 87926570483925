import React, { useCallback, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Formik, Form, Field } from "formik";
import Button from "panel/src/_components/common/Button";
import { loginStart } from "panel/src/redux/actions/login";
import logo from "panel/src/assets/images/mobile-logo.svg";
import successIcon from "panel/src/assets/images/auth-success.svg";
import Input from "./Input";
import classes from "./login.module.scss";
import loginValidation from "./validationSchema";
import Icon from "panel/src/_components/common/Icon";
import useIntercomChat from "../../Components/Intercom/useIntercomChat";

interface ILoginProps {
  loading: boolean;
  error: string;
  signin: Function;
}

interface ILocationState {
  successMessage: string;
}

const Login = ({ loading, error, signin }: ILoginProps) => {
  const history = useHistory();
  const location = useLocation<ILocationState>();
  const selectButton = useRef<HTMLButtonElement>();
  useIntercomChat({});

  const onSubmitForm = async (values: any) => {
    signin(values);
  };

  const [showPassword, setShowPassword] = useState(false);

  const onKeyPress: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key === "Enter") {
        const inputs = Array.from(
          // Get table or tbody whatever that contains all inputs. The number of parentElements depends on the structure of your html
          e.currentTarget?.parentElement?.parentElement?.parentElement?.querySelectorAll(
            "input",
          ) ?? []
        ).filter((e) => !e.disabled)
        const index = inputs.indexOf(e.currentTarget)
        if(index === inputs.length - 1)
        {
          selectButton.current?.focus()
        }
        else
        {
        inputs[index + 1]?.focus()
        e.preventDefault()
        }
      }
    },
    []
  )


  return (
    <div className={classes.bg}>
      <div className={classes.mainContainer}>
        <div className={classes.card}>
          <div className={classes.logoWrapper}>
            <img src={logo} className={classes.logo} />
          </div>
          {location.state && location.state.successMessage && (
            <div className={classes.successContainer}>
              <img src={successIcon} className={classes.successIcon} />
              <span className={classes.successText}>
                {location.state.successMessage}
              </span>
            </div>
          )}
          <Formik
            enableReinitialize
            initialValues={{ username: "", password: "" }}
            validationSchema={loginValidation}
            onSubmit={(values) => onSubmitForm(values)}
          >
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <Field
                  id="username"
                  name="username"
                  style={{ marginBottom: 24 }}
                  component={Input}
                  value={values.username}
                  onChangeHandler={handleChange}
                  label="Business Email"
                  placeholder="your.name@mail.com"
                  handleError={errors}
                  handleTouched={touched}
                  onKeyPress={onKeyPress}
                />
                <div className={classes.inputWrapper}>
                  <Field
                    id="password"
                    name="password"
                    style={{ marginBottom: 8 }}
                    component={Input}
                    value={values.password}
                    onChangeHandler={handleChange}
                    label="Password"
                    placeholder="Your password"
                    type={!showPassword ? "password" : "text"}
                    handleError={errors}
                    handleTouched={touched}
                    onKeyPress={onKeyPress}
                  />
                  <span onClick={() => setShowPassword(!showPassword)}>
                    <Icon
                      type={showPassword ? "hideEye" : "showEye"}
                      fillColor="#3196f6"
                      width="24px"
                      height="24px"
                    />
                  </span>
                </div>
                <div className={classes.forgotPasswordContainer}>
                  <div
                    className={classes.hyperLink}
                    onClick={() => history.push("forgotpassword")}
                  >
                    Forgot Password?
                  </div>
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    customRef={selectButton}
                    color="Auth"
                    width="176px"
                    height="44px"
                    loadingSpinner={loading}
                    type={loading ? "button" : "submit"}
                    fontSize="14px"
                  >
                    Login
                  </Button>
                </div>
                {error && (
                  <p className={classes.errorMessage}>
                    Your email or password is incorrect. Please try again.
                  </p>
                )}
                <div className={classes.signUpText}>
                  Don't have an account?&nbsp;
                  <a
                    className={classes.hyperLink}
                    href="https://mowico.com/start-free-trial/"
                    target="_blank"
                  >
                    Sign Up
                  </a>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.login.loading,
    error: state.login.error,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    signin: (val: any) => dispatch(loginStart(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
