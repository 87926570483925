import React, { Component, useRef } from "react";
import classes from "./appIcon.module.scss";
import plus from "../../../../../assets/images/plus.svg";
interface IAppIconProps {
  title: string;
  appIcon?: string;
  description?: string;
  appIconType: string;
  onChange: Function;
  field?: any;
  form?: any;
  errorMessage?: string;
  touched?: boolean;
}

class AppIconUpload extends Component<IAppIconProps> {
  fileInput: any;

  constructor(props: IAppIconProps) {
    super(props);
  }

  openFileInput = (e: any) => {
    e.preventDefault();
    this.fileInput.click();
  };

  render() {
    const { appIconType, title, appIcon, description, onChange, form } =
      this.props;

    const fieldName: string = this.props.field.name;

    const isErrorField =
      form && form.errors && form.errors[fieldName] && form.touched[fieldName];
    let isIosErrorIcon = false;
    if (form && form.errors) {
      isIosErrorIcon =
        form.errors["iosIcon"] ||
        form.errors["iosIconHeight"] ||
        form.errors["iosIconWidth"] ||
        form.errors["iosFileFormat"];
    }

    let isAndroidErrorIcon = false;
    if (form && form.errors) {
      isAndroidErrorIcon =
        form.errors["androidIcon"] ||
        form.errors["androidIconHeight"] ||
        form.errors["androidIconWidth"] ||
        form.errors["androidFileFormat"];
    }

    const errorMessage = form.errors ? form.errors[fieldName] : undefined;

    const androidClasses = [classes.AndroidAppIcon];
    if (isErrorField || isAndroidErrorIcon) {
      if (appIconType === "android") {
        androidClasses.push(classes.Invalid);
      }
    }

    const iosClasses = [classes.IOSAppIcon];
    if (isErrorField || isIosErrorIcon) {
      if (appIconType === "ios") {
        iosClasses.push(classes.Invalid);
      }
    }

    const imgContent = (
      <a
        className={classes.inputZone}
        href='#'
        onClick={(e) => this.openFileInput(e)}
      >
        <div className={classes.imgWrapper}>
          <img src={appIcon} alt='uploading-file' />
          <div className={classes.hoverContent}>
            <div className={classes.hoverActions}>
              <p className={classes.hoverText}>Edit</p>
            </div>
            <small className={classes.description}>{description}</small>
          </div>
        </div>
      </a>
    );
    const emptyContent = (
      <a
        className={classes.inputZone}
        href='#'
        onClick={(e) => this.openFileInput(e)}
      >
        <div className={classes.textWrapper}>
          <span className={classes.text}>{title}</span>
          <img className={classes.addIcon} src={plus} />
          <small className={classes.description}>{description}</small>
        </div>
      </a>
    );

    return appIconType == "android" ? (
      <div className={androidClasses.join(" ")}>
        <input
          ref={(element) => (this.fileInput = element)}
          className={classes.input}
          type='file'
          accept='image/*'
          name='androidIcon'
          onChange={(e: any) => {
            onChange(this.fileInput);
          }}
        />
        {appIcon ? imgContent : emptyContent}
      </div>
    ) : (
      <div className={iosClasses.join(" ")}>
        <input
          ref={(element) => (this.fileInput = element)}
          className={classes.input}
          type='file'
          accept='image/*'
          name='iosIcon'
          onChange={(e: any) => {
            onChange(this.fileInput);
          }}
        />
        {appIcon ? imgContent : emptyContent}
      </div>
    );
  }
}

export default AppIconUpload;
