import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "../../common/Modal";
import classes from "./refreshPage.module.scss";
import close from "../../../assets/images/close.svg";
import Button from "../../common/Button";

const RefreshPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeunload", pageUnload);

    return () => window.removeEventListener("beforeunload", pageUnload);
  }, []);

  const pageUnload = (e: any) => {
    e.preventDefault();
    e.returnValue =
      "Are you sure you like to leave this page? The changes you made will be lost.";
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const reloadPage = () => {
    setIsModalVisible(false);
    window.location.reload();
  };

  return (
    <React.Fragment>
      <Modal backdropClickedHandler={closeModal} show={false} width="448">
        <div className={classes.Close} onClick={closeModal}>
          <img src={close} />
        </div>
        <div className={classes.ModalBody}>
          <span className={classes.ModalBodyText}>
            Are you sure you like to leave this page? <br />
            The changes you made will be lost.
          </span>
        </div>
        <div className={classes.ModalFooter}>
          <div className={classes.ButtonContainer}>
            <Button
              type="button"
              className="Ghost"
              width="192px"
              onClick={reloadPage}
            >
              Leave
            </Button>
            <Button
              type="button"
              className="Primary"
              width="192px"
              onClick={closeModal}
            >
              Stay
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isBlocked: state.page.isBlocked,
  };
};

export default connect(mapStateToProps)(RefreshPage);
