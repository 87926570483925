import IGetHomeDataRequest from "../../models/Design/home/IGetHomeDataRequest";
import {
  GET_HOME_DATA,
  GET_HOME_DATA_SUCCESS,
  GET_HOME_DATA_FAIL,
  RESET_HOME_DATA,
  COMPONENT_CHANGE,
  RESET_CHANGES,
} from "panel/src/redux/constants";

interface BaseAction {
  type: string;
  data?: Object;
  error?: string;
  requestObj?: IGetHomeDataRequest;
  componentObj?: any;
}

export const getHomeData = (requestObj: IGetHomeDataRequest): BaseAction => ({
  type: GET_HOME_DATA,
  requestObj,
});

export const getHomeDataSuccess = (data: Object): BaseAction => ({
  type: GET_HOME_DATA_SUCCESS,
  data,
});

export const getHomeDataFail = (error: string): BaseAction => ({
  type: GET_HOME_DATA_FAIL,
  error,
});

export const resetHomeData = () => ({
  type: RESET_HOME_DATA,
});

export const componentChange = (componentObj: any): BaseAction => ({
  type: COMPONENT_CHANGE,
  componentObj,
});

export const resetChange = () => ({
  type: RESET_CHANGES,
});
