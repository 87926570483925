import { put, call, takeLatest } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import IAskDemoRequest from "../../models/Publish/askDemo";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import {
  askDemoLinkSuccess,
  askDemoLinkFail,
} from "panel/src/redux/actions/askDemo";
import * as userInfoActions from "panel/src/redux/actions/userInfo";
import { ASK_DEMO } from "panel/src/redux/constants/askDemo";

function* askDemoLink(payload: { requestObj: IAskDemoRequest; type: string }) {
  yield put(startAppLoading(ASK_DEMO));
  try {
    const askDemoResponse: IAskDemoRequest = yield call(
      axios.post,
      "api/Manage/AskDemo",
      {
        ApplicationId: payload.requestObj.applicationId,
        IsAndroidBuild: payload.requestObj.IsAndroidBuild,
        IsIosBuild: payload.requestObj.IsIosBuild,
        EmailAddresses: payload.requestObj.EmailAdresses,
      }
    );

    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push(["email_request_for_demo_app", payload.requestObj.EmailAdresses]);

    yield put(userInfoActions.getUserInfo());
    yield put(askDemoLinkSuccess(askDemoResponse));
  } catch (error) {
    yield put(askDemoLinkFail(error.message));
  }
  yield put(endAppLoading(ASK_DEMO));
}

export function* askDemoLinkSaga() {
  yield takeLatest(ASK_DEMO, askDemoLink);
}
