import React, { useEffect } from "react";
import { Form, Field, Formik } from "formik";
import Button from "panel/src/Components/Button";
import InputGroup from "panel/src/Components/RadioButtonGroup";
import Checkbox from "../../../../../_components/common/Checkbox";
import ImageResizeType, {
  TImageResizeType,
  TResizeTypeValue,
} from "panel/src/Components/ImageResizeType";
import Tooltip from "../../../../../Components/Tooltip";

interface ICoreEditFormProps {
  hideComponentEdit?: Function;
  editFormClickedOutside?: number;
  setComponentFormData?: Function;
  setImageResizeType?: Function;
  Id?: string;
  formData?: ICoreEditFormData;
}

type ICoreEditFormData = {
  ImageResizeType: TResizeTypeValue;
  IsAddToCart: boolean;
};

const CoreEditForm = ({
  hideComponentEdit,
  editFormClickedOutside,
  setComponentFormData,
  setImageResizeType,
  Id,
  formData,
}: ICoreEditFormProps) => {
  useEffect(() => {
    if (editFormClickedOutside !== 0) {
      hideComponentEdit && close();
    }
  }, [editFormClickedOutside]);

  const close = () => {
    hideComponentEdit?.(Id);
    setComponentFormData && setComponentFormData(Id, formData);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formData!}
      onSubmit={(values) => {
        setComponentFormData && setComponentFormData(Id, values, true);
      }}
    >
      {({ values, setFieldValue, errors, touched }) => {
        return (
          <Form>
            <Field
              id="ImageResizeType"
              name="ImageResizeType"
              style={{ marginTop: 16 }}
              resizeTypeValue={values.ImageResizeType}
              typeOnClickHandler={(item: TImageResizeType) => {
                setFieldValue(`ImageResizeType`, item.mode);
                setImageResizeType &&
                  setImageResizeType(Id, {
                    ...values,
                    ImageResizeType: item.mode,
                  });
              }}
              component={ImageResizeType}
            />
            <InputGroup
              flexDirection="column"
              title="CTA"
              style={{ marginTop: "1rem" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Field
                  name={"IsAddToCart"}
                  label={"Add to cart"}
                  value={values.IsAddToCart}
                  changeHandler={() => {
                    setFieldValue(`IsAddToCart`, !values.IsAddToCart);
                  }}
                  checked={values.IsAddToCart}
                  component={Checkbox}
                  marginBottom={"3px"}
                />
                <Tooltip text="To add the product to the cart without going to the details of the products without variant" />
              </div>
            </InputGroup>
            <Button
              id="apply-button"
              type="submit"
              style={{ marginTop: "1.1rem" }}
              buttonText="Apply"
            />
            <Button
              id="cancel-button"
              type="button"
              style={{ marginTop: "0.5rem" }}
              theme="white"
              buttonText="Cancel"
              onClick={() => hideComponentEdit && hideComponentEdit(Id)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default CoreEditForm;
