import React from 'react';

const Visible = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
      <g fill="currentColor" fill-rule="nonzero">
        <path d="M6.003 7.6a2.466 2.466 0 0 1 0-4.93 2.466 2.466 0 0 1 0 4.93Zm0-4.076c-.888 0-1.611.723-1.611 1.61 0 .889.723 1.612 1.61 1.612.889 0 1.612-.723 1.612-1.611S6.89 3.524 6.003 3.524Z"/><path d="M6.003 10.27c-2.14 0-4.161-1.253-5.55-3.427-.604-.94-.604-2.471 0-3.416C1.847 1.252 3.868 0 6.003 0s4.155 1.252 5.544 3.427c.604.94.604 2.47 0 3.416-1.389 2.174-3.41 3.426-5.544 3.426Zm0-9.416c-1.839 0-3.598 1.104-4.827 3.034-.427.666-.427 1.827 0 2.493 1.23 1.93 2.988 3.035 4.827 3.035S9.6 8.31 10.83 6.38c.427-.666.427-1.827 0-2.493C9.6 1.958 7.842.854 6.003.854Z"/>
      </g>
    </svg>
  );
};

export default Visible;
