import React from "react";

interface IProps {
    width: string;
    height: string;
    fillColor: string;
    type: string;
}

const Plus = ({ width, height, fillColor }: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
        <path fill={fillColor} fillRule="evenodd" d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm.974 3.19H7.026v3.837H3.19v1.947h3.837v3.836h1.947V8.974h3.836V7.026H8.973V3.19z" />
    </svg>

);

export default Plus;
