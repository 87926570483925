import { Platform } from "react-native";
import styled from "styled-components/native";
import { dimenHeight } from "shared-components/src/utils/helper";
import FastImage from "react-native-fast-image-web-support";

export default styled(FastImage).attrs({ resizeMode: "contain" })`
  height: ${Platform.OS === "web" ? "32" : dimenHeight(48)}px;
  width: ${Platform.OS === "web" ? "32" : dimenHeight(48)}px;
  border-radius: ${Platform.OS === "web" ? "16" : dimenHeight(24)}px;
`;
