
import { CHANGE_VISIBILITY } from "panel/src/redux/constants/liveChat";

export const showChat = () => {
  return {
    type: CHANGE_VISIBILITY,
    payload: { data: "maximized" },
  };
};

export const hideChat = () => {
  return {
    type: CHANGE_VISIBILITY,
    payload: { data: "minimized" },
  };
};

export const changeVisibility = (val: any) => {
  return {
    type: CHANGE_VISIBILITY,
    payload: { data: val },
  };
};
