import * as Yup from "yup";
import {
  emailErrorMessage,
  webServiceRequiredErrorMessage,
} from "panel/src/utils/validationMessage";

const Schema = Yup.object().shape({
  GoogleMerchantEmail: Yup.string()
    .email(emailErrorMessage)
    .required(webServiceRequiredErrorMessage),
  GoogleMerchantID: Yup.string().required(webServiceRequiredErrorMessage),
  GoogleMerchantName: Yup.string().required(webServiceRequiredErrorMessage),
  GoogleMerchantKeyFile: Yup.string().required(webServiceRequiredErrorMessage),
});

export default Schema;
