import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import html2canvas from "html2canvas";
import Emulator from "panel/src/Components/DragnDrop/Emulator";
import ScreenSelection from "../components/ScreenSelection";
import informationIcon from "../../../assets/images/information.svg";
import classes from "./splashScreen.module.scss";
import Select from "../../../_components/common/Select";
import RadioButton from "../../../_components/common/RadioButton";
import Button from "../../../_components/common/Button";
import SelectModel from "../../../models/Design/selectModel";
import splashValidation from "./validationSchema";
import { Formik, Field, Form, FieldArray, getIn, FormikProps } from "formik";
import { validURL } from "../../../utils/regex";
import { splashBackgroundOptions, devices } from "../../../utils/constants";
import {
  dataURLtoFile,
  fileToBase64,
  getFileExtension,
  updateObject,
} from "../../../utils/helper";
import Upload from "../../../_components/common/Upload";
import ErrorField from "../../../_components/common/ErrorField";
import { IconType } from "../../../utils/enums/iconType";
import ColorPalettePicker from "../../../_components/common/ColorPalette";
import * as splashScreenActions from "panel/src/redux/actions/splashScreen";
import { DeviceType } from "../../../utils/enums/deviceType";
import { SplashType } from "../../../utils/enums/splashType";
import { DeviceModel } from "../../../models/Design/deviceModel";
import {
  colorHexPattern,
  colorSpecialCharacterPattern,
} from "../../../utils/regex";
import {
  hexColorNotValidMessage,
  colorSpecialCharacterValidMessage,
} from "../../../utils/validationMessage";
import BlockingForm from "../../../_components/common/BlockingForm";
import { ScreenType } from "../../../utils/enums/screenType";
import {
  GetSplashScreenResponseModel,
  AdvancedSplashList,
} from "../../../models/Design/splash/getSplashScreenResponseModel";
import Modal from "../../../_components/common/Modal";
import close from "../../../assets/images/close.svg";
import warning from "../../../assets/images/warning.svg";
import { NavigationEnum } from "../../../Components/DragnDrop/Emulator/Components";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import { SET_SPLASH_SCREEN_START } from "panel/src/redux/constants/splashScreen";
import { screenNumbers } from "../constants";
import { setChangedSthDesignPage } from "panel/src/redux/actions/manageApp";
import { splashScreenLogo } from "../../../utils/initialImages";
import UpgradeComponent from "../../../_components/common/UpgradeComponent";
import UpgradeGuide from "../../../Components/UpgradeGuide";
import UpgradeModal from "../../../Components/UpgradeModal";

const dummyImg = {
  name: "Splash Screen.png",
  type: "image/png",
};

interface IProps {
  appId?: string;
  actions?: any;
  selectChange?: any;
  splashScreenData?: GetSplashScreenResponseModel["ResultData"];
  deviceType: DeviceType;
  deviceTypeHandler: Function;
  isCompletion: boolean;
  selectedValue: string;
  designScreens: SelectModel[];
  designScreensChange: Function;
  _startAppLoading: Function;
  _endAppLoading: Function;
  isOnModalSubmit: boolean;
  _setChangedSthDesign: Function;
  setError: Function;
  isCompleted: boolean;
  packageFeatures: any;
}

interface IState {
  options: SelectModel[];
  activeIndex: string;
  devices: DeviceModel[];
  splashLogo?: any;
  splashNavigationLogo?: any;
  displayColorPicker?: boolean;
  backgroundColor?: string;
  linearGradientStartPicker?: boolean;
  linearGradientStart?: string;
  linearGradientEndPicker?: boolean;
  linearGradientEnd?: string;
  radialGradientStartPicker?: boolean;
  radialGradientStart?: string;
  radialGradientEndPicker?: boolean;
  radialGradientEnd?: string;
  basicRadioChecked?: any;
  isHextColorValid?: boolean;
  isSpacialCharacterError: boolean;
  isBlocking: boolean;
  logoExtension: string;
  logoWidth: number;
  logoHeight: number;
  advancedSplashList: SplashList[];
  isShowModal: boolean;
  splashImages: Array<string>;
  isUpgradeModal: boolean;
  isUpgradePopup: boolean;
  isUpgradeModalShown: boolean;
}

interface SplashList extends AdvancedSplashList {
  splashImageName: string;
  splashImage: string;
  logoExtension: string;
  width: number;
  height: number;
}

class SplashScreen extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      options: splashBackgroundOptions,
      activeIndex: DeviceType.IOS,
      devices: devices,
      basicRadioChecked: true,
      splashLogo: dummyImg,
      splashNavigationLogo: splashScreenLogo,
      backgroundColor: "#3196F6",
      linearGradientStart: "#3196F6",
      linearGradientEnd: "#0F3649",
      radialGradientStart: "#3196F6",
      radialGradientEnd: "#0F3649",
      isHextColorValid: true,
      isSpacialCharacterError: false,
      isBlocking: false,
      logoExtension: "image/png",
      logoWidth: 1024,
      logoHeight: 1024,
      advancedSplashList: [],
      isShowModal: false,
      splashImages: [],
      isUpgradeModal: false,
      isUpgradePopup: false,
      isUpgradeModalShown: false,
    };
  }
  basicFormikRef: FormikProps<any> | null = null;
  customFormikRef: FormikProps<any> | null = null;

  packageFeaturesLimited =
    (this.props.packageFeatures?.find((s: any) => s.Id === 1)?.MaxCount || -1) >
    -1;

  componentDidMount = () => {
    this.state.devices.map((item) => {
      item.type = IconType.Disabled;

      if (item.index === DeviceType.IOS) {
        item.type = IconType.Active;
      }
    });

    this.props.actions.getSplashScreenStart(this.props.appId);
  };

  componentDidUpdate = () => {
    if (
      this.props.isOnModalSubmit &&
      this.props.selectedValue === screenNumbers.splashScreen
    ) {
      this.handleFormSubmit();
    }
  };

  componentWillReceiveProps = async (nextProps: any) => {
    if (this.props.splashScreenData != nextProps.splashScreenData) {
      const {
        isAdvanced,
        selectedSplashType,
        splashGradientStart,
        splashGradientEnd,
        splashIconUrl,
        advancedSplashList,
        splashTypeList,
      } = nextProps.splashScreenData;

      if (splashIconUrl) {
        const img = new Image();
        const base64Url = "data:image/png;base64," + splashIconUrl;
        const file = dataURLtoFile(base64Url, "logo.png");
        // const _URL = window.URL || window.webkitURL;
        // const url = _URL.createObjectURL(file);

        img.onload = () => {
          this.setState({
            splashLogo: file,
            splashNavigationLogo: base64Url,
            logoExtension: file.type,
            logoWidth: img.width,
            logoHeight: img.height,
          });
        };

        img.onerror = function () {
          console.log("not a valid file: ");
        };
        img.src = base64Url;
      }

      const logoExtension = getFileExtension(splashIconUrl);

      const updatedSelectedOption = this.state.options.map((option) => {
        return {
          ...option,
          isSelected: option.value == selectedSplashType.splashTypeId,
        };
      });

      if (logoExtension) {
        this.setState({ logoExtension: `image/${logoExtension}` });
      }

      this.setState({
        options: updatedSelectedOption,
        advancedSplashList,
        basicRadioChecked: !isAdvanced,
      });

      if (
        selectedSplashType.splashTypeId == SplashType.Color &&
        splashGradientEnd
      ) {
        this.setState({ backgroundColor: splashGradientEnd });
      } else if (selectedSplashType.splashTypeId == SplashType.Linear) {
        this.setState({
          linearGradientStart: splashGradientStart,
          linearGradientEnd: splashGradientEnd,
        });
      } else if (selectedSplashType.splashTypeId == SplashType.Radial) {
        this.setState({
          radialGradientStart: splashGradientStart,
          radialGradientEnd: splashGradientEnd,
        });
      }
      // if (splashIconUrl) {
      //   this.setState({
      //     splashNavigationLogo: splashIconUrl,
      //     splashLogo: splashIconUrl,
      //   });
      // }
    }
    if (
      !this.state.basicRadioChecked &&
      this.packageFeaturesLimited &&
      !this.state.isUpgradePopup
    ) {
      this.setState({ isUpgradeModal: true });
    }
  };

  selectChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const updatedOptions = this.state.options.map((option) => {
      return {
        ...option,
        isSelected: option.value === e.target.value,
      };
    });

    this.setState({ options: updatedOptions, isBlocking: true });
    this.props._setChangedSthDesign(true);
  };

  handleClick = (device: any) => {
    this.setState({ activeIndex: device.index });

    this.state.devices.map((item) => {
      item.type = IconType.Disabled;

      if (device.index === DeviceType.IOS) {
        device.type = IconType.Active;
      } else if (device.index === DeviceType.Android) {
        device.type = IconType.Active;
      }
    });
    this.props.deviceTypeHandler(device.index);
  };

  inputRadioChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      basicRadioChecked: !this.state.basicRadioChecked,
      isBlocking: true,
    });
    this.props._setChangedSthDesign(true);
  };

  getBasicInitialValues = () => {
    return {
      logo: this.state.splashLogo,
      logoExtension: this.state.logoExtension,
      logoWidth: this.state.logoWidth,
      logoHeight: this.state.logoHeight,
    };
  };

  getAdvancedInitialValues = () => {
    return {
      advancedSplashList:
        this.state.advancedSplashList[this.state.advancedSplashList.length - 1],
    };
  };

  changeScreen = () => {
    this.props.selectChange(ScreenType.NavigationType.toString());
  };

  onSubmitBasicForm = async () => {
    this.setState({ isBlocking: false }, async () => {
      this.props._setChangedSthDesign(false);
      // if (!this.props.isOnModalSubmit) {
      //   this.changeScreen();
      // }
      const selectedOption: any = this.state.options.find(
        (opt) => opt.isSelected
      );
      const selectedOptionValue: any = selectedOption
        ? selectedOption.value
        : "";

      let splashGradientStart: any;
      let splashGradientEnd: any;
      if (selectedOptionValue == 1) {
        splashGradientStart = this.state.backgroundColor as string;
        splashGradientEnd = this.state.backgroundColor as string;
      } else if (selectedOptionValue == 2) {
        splashGradientStart = this.state.backgroundColor as string;
        splashGradientEnd = this.state.backgroundColor as string;
      } else if (selectedOptionValue == 3) {
        splashGradientStart = this.state.linearGradientStart as string;
        splashGradientEnd = this.state.linearGradientEnd as string;
      } else if (selectedOptionValue == 4) {
        splashGradientStart = this.state.radialGradientStart as string;
        splashGradientEnd = this.state.radialGradientEnd as string;
      }

      const imageType = !validURL(this.state.splashNavigationLogo)
        ? this.state.splashLogo.type.replace("image/", "")
        : this.state.splashNavigationLogo;

      const sendingSplashImage = !validURL(this.state.splashNavigationLogo)
        ? this.state.splashNavigationLogo.split(";base64,").pop()
        : null;

      const splashGradientStartValidation =
        colorHexPattern.test(splashGradientStart);
      const splashGradientEndValidation =
        colorHexPattern.test(splashGradientEnd);
      const start = colorSpecialCharacterPattern.test(splashGradientStart);
      const end = colorSpecialCharacterPattern.test(splashGradientEnd);
      const specialCharacterError = !!start && !!end;
      this.setState({ isSpacialCharacterError: !specialCharacterError });
      const hexColorValid =
        !!splashGradientStartValidation && !!splashGradientEndValidation;
      if (hexColorValid) {
        this.setState({ isHextColorValid: true });
      } else {
        this.setState({ isHextColorValid: false });
      }

      if (hexColorValid) {
        this.props._startAppLoading(SET_SPLASH_SCREEN_START);

        this.createSplashResolutions(selectedOptionValue).then(
          (splashResolutions) => {
            const splashScreenObject = {
              ApplicationId: this.props.appId,
              splashGradientStart: splashGradientStart,
              splashGradientEnd: splashGradientEnd,
              splashTypeId: selectedOptionValue,
              IsCustom: !this.state.basicRadioChecked,
              ...(sendingSplashImage && {
                LogoFile: {
                  FileContent: sendingSplashImage,
                  FileExtension: imageType,
                },
              }),
              Files: splashResolutions,
            };
            this.props._endAppLoading(SET_SPLASH_SCREEN_START);

            this.props.actions.setSplashScreenStart(splashScreenObject, () =>
              this.changeScreen()
            );
          }
        );
      }
    });
  };

  createSplashResolutions = async (selectedOptionValue: string) => {
    const expectedResolutions = this.state.advancedSplashList.map(
      (splashDimension) => {
        return {
          width: splashDimension.ImageWidth,
          height: splashDimension.ImageHeight,
        };
      }
    );

    let splashBackgroundCSS: string;
    let splashResolutions: {
      FileContent: string;
      FileExtension: string;
    }[] = [];

    switch (selectedOptionValue) {
      case "2":
        splashBackgroundCSS = `background: ${this.state.backgroundColor};`;
        break;

      case "3":
        splashBackgroundCSS = `background: linear-gradient(${this.state.linearGradientStart}, ${this.state.linearGradientEnd});`;
        break;

      case "4":
        splashBackgroundCSS = `background: radial-gradient(circle,${this.state.radialGradientStart}, ${this.state.radialGradientEnd});`;
        break;

      default:
        splashBackgroundCSS = "background: white;";
        break;
    }

    for (const resolution of expectedResolutions) {
      const containerId = `canvas_container${resolution.width}_${resolution.height}`;
      const divId = `convertable_to_canvas_${resolution.width}_${resolution.height}`;
      const containerDiv = document.createElement("div");
      const tempDiv = document.createElement("div");
      containerDiv.id = containerId;
      tempDiv.id = divId;
      tempDiv.style.cssText = `
          display: flex;
          justify-content: center;
          align-items: center;
          ${splashBackgroundCSS}
          height: ${resolution.height}px;
          width: ${resolution.width}px;
        `;
      containerDiv.style.cssText = "width: 0; height: 0; overflow: hidden;";
      containerDiv.appendChild(tempDiv);
      tempDiv.innerHTML = `<img style="width: 75%;" src="${this.state.splashNavigationLogo}" />`;
      document.body.appendChild(containerDiv);
      const selectTempDiv = document!.querySelector(`#${divId}`) as HTMLElement;
      await this.processSplashImage(selectTempDiv, resolution).then(
        (splash) => {
          if (splash) splashResolutions.push(splash);
        }
      );
      document.body.removeChild(containerDiv);
    }
    return splashResolutions;
  };

  processSplashImage = (
    selectTempDiv: HTMLElement,
    resolution: { width: number; height: number }
  ) =>
    html2canvas(selectTempDiv, {
      logging: false,
      backgroundColor: null,
      width: resolution.width,
      height: resolution.height,
      scale: 1,
    })
      .then((canvas) => {
        let base64ContentArray = canvas.toDataURL().split(",");
        let fileType = base64ContentArray[0]!.match(/[^:/]\w+(?=;|,)/)![0];
        let base64Data = base64ContentArray[1];
        return {
          FileContent: base64Data,
          FileExtension: fileType,
        };
      })
      .catch(console.log);

  onSubmitAdvancedForm = () => {
    const { advancedSplashList, basicRadioChecked } = this.state;

    if (basicRadioChecked) return;
    const extension = advancedSplashList[0].logoExtension
      ? advancedSplashList[0].logoExtension.replace("image/", "")
      : getFileExtension(advancedSplashList[0].ImageUrl);

    // if (!this.props.isOnModalSubmit) {
    //   this.props.selectChange(ScreenType.NavigationType.toString());
    // }

    let isDiffExtension = false;

    const item = advancedSplashList[0];

    const imageType = item.logoExtension
      ? item.logoExtension.replace("image/", "")
      : getFileExtension(item.ImageUrl);

    if (imageType !== extension) {
      this.setState({ isShowModal: true });
      isDiffExtension = true;
      return;
    }

    if (!isDiffExtension) {
      this.setState({ isBlocking: false }, async () => {
        this.props._setChangedSthDesign(false);
        let advancedImageList: string[] = [];

        if (item.splashImage) {
          const splashImage = item.splashImage.split(";base64,").pop();
          advancedImageList.push(splashImage!);
        }

        const files = advancedImageList.map((file) => {
          return {
            FileContent: file,
            FileExtension: extension,
          };
        });
        const splashScreenObject = {
          ApplicationId: this.props.appId,
          IsCustom: !this.state.basicRadioChecked,
          Files: files,
        };

        this.props.actions.setSplashScreenStart(splashScreenObject, () =>
          this.changeScreen()
        );
      });
    }
  };

  onChangeFileInput = async (fileInput: any) => {
    const { advancedSplashList } = this.state;
    let advancedSplashItem = advancedSplashList && advancedSplashList[0];

    var file = fileInput.files[0];
    if (file) {
      const res = await fileToBase64(file);
      var _URL = window.URL || window.webkitURL;
      setTimeout(() => {
        var img = new Image();
        img.onload = () => {
          const updatedSplahFile = updateObject(advancedSplashItem, {
            splashImageName: file.name,
            splashImage: res,
            logoExtension: file.type,
            width: img.width,
            height: img.height,
          });

          const splashList = [...advancedSplashList];
          splashList[0] = updatedSplahFile;
          this.setState({ advancedSplashList: splashList });
        };
        img.onerror = function () {
          console.log("not a valid file: " + file.type);
        };
        img.src = _URL.createObjectURL(file);
      }, 500);
    }
  };

  onChangeHandler = async (fileInput: any) => {
    var file = fileInput.files[0];
    if (file) {
      const res = await fileToBase64(file);
      var _URL = window.URL || window.webkitURL;
      var img = new Image();
      img.onload = () => {
        this.setState({
          splashLogo: file,
          splashNavigationLogo: res,
          logoExtension: file.type,
          logoWidth: img.width,
          logoHeight: img.height,
        });
      };
      img.onerror = function () {
        console.log("not a valid file: " + file.type);
      };
      img.src = _URL.createObjectURL(file);
    }
    this.setState({ isBlocking: true });
    this.props._setChangedSthDesign(true);
  };

  setPickedColor = (color: string) => {
    this.setState({ backgroundColor: color, isBlocking: true });
    this.props._setChangedSthDesign(true);
  };

  setPickedLinearStartColor = (color: string) => {
    this.setState({ linearGradientStart: color, isBlocking: true });
    this.props._setChangedSthDesign(true);
  };

  setPickedLinearEndColor = (color: string) => {
    this.setState({ linearGradientEnd: color, isBlocking: true });
    this.props._setChangedSthDesign(true);
  };

  setPickedRadialStartColor = (color: string) => {
    this.setState({ radialGradientStart: color, isBlocking: true });
    this.props._setChangedSthDesign(true);
  };

  setPickedRadialEndColor = (color: string) => {
    this.setState({ radialGradientEnd: color, isBlocking: true });
    this.props._setChangedSthDesign(true);
  };
  handleSetPickedColor = (e: any) => {
    this.setState({
      backgroundColor: e.target.value,
      isBlocking: true,
      isHextColorValid: true,
    });
    this.props._setChangedSthDesign(true);
  };

  handleSetPickedLinearStartColor = (e: any) => {
    this.setState({
      linearGradientStart: e.target.value,
      isBlocking: true,
      isHextColorValid: true,
    });
    this.props._setChangedSthDesign(true);
  };

  handleSetPickedLinearEndColor = (e: any) => {
    this.setState({
      linearGradientEnd: e.target.value,
      isBlocking: true,
      isHextColorValid: true,
    });
    this.props._setChangedSthDesign(true);
  };

  handleSetPickedRadialStartColor = (e: any) => {
    this.setState({
      radialGradientStart: e.target.value,
      isBlocking: true,
      isHextColorValid: true,
    });
    this.props._setChangedSthDesign(true);
  };

  handleSetPickedRadialEndColor = (e: any) => {
    this.setState({
      radialGradientEnd: e.target.value,
      isBlocking: true,
      isHextColorValid: true,
    });
    this.props._setChangedSthDesign(true);
  };

  validate = (file: SplashList) => {
    let error;

    if (!file.ImageUrl && !file.splashImage) {
      error = "All specified sizes of the splash must be added";
    }
    if (!file.ImageUrl && file.splashImage) {
      if (file.ImageWidth !== file.width || file.ImageHeight !== file.height) {
        error = `Splash logo must be ${file.ImageWidth} x ${file.ImageHeight}`;
      }
    }
    if (file.ImageUrl && file.splashImage) {
      if (file.ImageWidth !== file.width || file.ImageHeight !== file.height) {
        error = `Splash logo must be ${file.ImageWidth} x ${file.ImageHeight}`;
      }
    }
    if (file.ImageUrl && !file.splashImage) {
      error = "";
    }

    if (!!error) {
      this.props.setError(true);
    }

    return error;
  };

  closeModal = () => {
    this.setState({ isShowModal: false });
  };

  closeUpgradeModal = () => {
    this.setState({ isUpgradeModal: false });
  };

  handleFormSubmit = () => {
    if (this.state.basicRadioChecked) {
      this.basicFormikRef?.submitForm();
    } else {
      if (this.packageFeaturesLimited) {
        if (!this.state.isUpgradeModalShown) {
          this.setState({ isUpgradeModalShown: true, isUpgradePopup: true });
        } else {
          this.changeScreen();
        }
      } else {
        this.customFormikRef?.values.advancedSplashList &&
          this.customFormikRef?.validateField(`advancedSplashList`);
        this.customFormikRef?.submitForm();
      }
    }
  };

  renderAdvancedFiles = () => {
    const { advancedSplashList } = this.state;
    if (advancedSplashList?.length > 0) {
      const file = advancedSplashList[advancedSplashList.length - 1];

      return (
        <div>
          <label
            className={classes.advancedSplashLabel}
          >{`File: ${file.ImageWidth} x ${file.ImageHeight}`}</label>
          <Field
            placeholder={`File: ${file.ImageWidth} x ${file.ImageHeight}`}
            name={`advancedSplashList`}
            onChange={(e: any) => {
              this.onChangeFileInput(e);
              this.props._setChangedSthDesign(true);
              this.customFormikRef?.setFieldValue(
                `advancedSplashList`,
                e.files[0]
              );
            }}
            // validate={() => this.validate(file)}
            logoName={
              file.splashImageName
                ? file.splashImageName
                : validURL(file.ImageUrl)
                ? file.ImageUrl.toString()
                    .substring(file.ImageUrl.toString().lastIndexOf("/") + 1)
                    .slice(0, -1)
                : ""
            }
            component={Upload}
          />
          {getIn(this.customFormikRef?.errors, `advancedSplashList`) && (
            <ErrorField
              type={"relative"}
              text={getIn(this.customFormikRef?.errors, `advancedSplashList`)}
            />
          )}
        </div>
      );
    }

    return <></>;
  };

  render() {
    const { advancedSplashList } = this.state;
    let logoName = this.state.splashLogo ? this.state.splashLogo.name : "";
    if (validURL(this.state.splashNavigationLogo)) {
      logoName = this.state.splashNavigationLogo
        .toString()
        .substring(
          this.state.splashNavigationLogo.toString().lastIndexOf("/") + 1
        )
        .slice(0, -1);
    }

    let splashImage;
    if (advancedSplashList && advancedSplashList.length > 0) {
      const tempSplashImage = advancedSplashList[0];
      if (tempSplashImage.splashImage)
        splashImage = tempSplashImage.splashImage;
      else if (tempSplashImage.ImageUrl) splashImage = tempSplashImage.ImageUrl;
    }

    const selectedOption: any = this.state.options.find(
      (opt) => opt.isSelected
    );
    const selectedOptionValue: any = selectedOption ? selectedOption.value : "";

    return (
      <React.Fragment>
        <div className={classes.DesignConfigContainer}>
          {!this.props.isCompletion && (
            <ScreenSelection
              options={this.props.designScreens}
              selectedValue={this.props.selectedValue}
              selectChangeHandler={this.props.designScreensChange}
            />
          )}
          <div className={classes.componentsContainer}>
            {this.state.basicRadioChecked ? (
              <Formik
                enableReinitialize={true}
                initialValues={this.getBasicInitialValues()}
                validationSchema={splashValidation}
                validateOnChange={true}
                validateOnBlur={false}
                innerRef={(r) => (this.basicFormikRef = r)}
                onSubmit={this.onSubmitBasicForm}
              >
                {(formik) => {
                  if (
                    formik.errors["logoExtension"] ||
                    formik.errors["logo"] ||
                    formik.errors["logoWidth"] ||
                    formik.errors["logoHeight"]
                  ) {
                    this.props.setError(true);
                  } else {
                    this.props.setError(false);
                  }
                  return (
                    <Form>
                      <BlockingForm isBlocking={this.state.isBlocking} />
                      <div className={classes.SplashContainer}>
                        <span className={classes.BlockLabel}>Splash</span>
                        <div className={classes.SplashOption}>
                          <Field
                            checked={this.state.basicRadioChecked}
                            label="Basic"
                            name="basic"
                            changeHandler={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => this.inputRadioChangeHandler(e)}
                            component={RadioButton}
                          />
                          <Field
                            checked={!this.state.basicRadioChecked}
                            label="Custom"
                            name="advanced"
                            changeHandler={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => this.inputRadioChangeHandler(e)}
                            component={RadioButton}
                          />
                        </div>

                        <div>
                          <p className={classes.CustomInfoText}>
                            You can add your logo compatible with the required
                            size.
                          </p>
                          <div style={{ marginBottom: "8px" }}>
                            <label className={classes.advancedSplashLabel}>
                              Logo
                            </label>
                          </div>
                          <Field
                            placeholder="File"
                            name="logo"
                            onChange={(e: any) => {
                              this.onChangeHandler(e);
                              formik.setFieldValue("logo", e.files[0]);
                            }}
                            logoName={logoName}
                            component={Upload}
                          />
                          {this.state.splashLogo &&
                            formik.errors["logoExtension"] && (
                              <ErrorField
                                type={"relative"}
                                text={formik.errors["logoExtension"]}
                              />
                            )}
                          {(formik.errors["logoWidth"] ||
                            formik.errors["logoHeight"]) && (
                            <ErrorField
                              type={"relative"}
                              text={formik.errors["logoWidth"]}
                            />
                          )}
                          <div className={classes.InfoGroup}>
                            <img
                              src={informationIcon}
                              alt="information"
                              className={classes.Information}
                            />
                            <span className={classes.InfoText}>
                              1024x1024. PNG, JPEG, or GIF.
                            </span>
                          </div>
                          <div className={classes.ColorPickerWrapper}>
                            <label className={classes.advancedSplashLabel}>
                              Background Colour
                            </label>
                            <Select
                              options={this.state.options}
                              value={selectedOptionValue}
                              placeholder="Background"
                              changeHandler={this.selectChangeHandler}
                            />

                            {selectedOptionValue == 2 && (
                              <ColorPalettePicker
                                pickedColor={this.state.backgroundColor}
                                onSelectedColorPicker={this.setPickedColor}
                                onChangeColorInput={this.handleSetPickedColor}
                                pickerRight={false}
                              />
                            )}
                          </div>
                          {selectedOptionValue == 3 && (
                            <div>
                              <div
                                className={classes.linearGradientDisplay}
                                style={{
                                  background: `linear-gradient(to right,${this.state.linearGradientStart}, ${this.state.linearGradientEnd})`,
                                }}
                              ></div>
                              <div className={classes.linearContainer}>
                                <div>
                                  <ColorPalettePicker
                                    pickedColor={this.state.linearGradientStart}
                                    onSelectedColorPicker={
                                      this.setPickedLinearStartColor
                                    }
                                    onChangeColorInput={
                                      this.handleSetPickedLinearStartColor
                                    }
                                    pickerRight={false}
                                  />
                                </div>
                                <div>
                                  <ColorPalettePicker
                                    pickedColor={this.state.linearGradientEnd}
                                    onSelectedColorPicker={
                                      this.setPickedLinearEndColor
                                    }
                                    onChangeColorInput={
                                      this.handleSetPickedLinearEndColor
                                    }
                                    pickerRight={true}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          {selectedOptionValue == 4 && (
                            <div>
                              <div
                                className={classes.linearGradientDisplay}
                                style={{
                                  background: `linear-gradient(to right,${this.state.radialGradientStart}, ${this.state.radialGradientEnd})`,
                                }}
                              ></div>
                              <div className={classes.linearContainer}>
                                <div>
                                  <ColorPalettePicker
                                    pickedColor={this.state.radialGradientStart}
                                    onSelectedColorPicker={
                                      this.setPickedRadialStartColor
                                    }
                                    onChangeColorInput={
                                      this.handleSetPickedRadialStartColor
                                    }
                                    pickerRight={false}
                                  />
                                </div>
                                <div>
                                  <ColorPalettePicker
                                    pickedColor={this.state.radialGradientEnd}
                                    onSelectedColorPicker={
                                      this.setPickedRadialEndColor
                                    }
                                    onChangeColorInput={
                                      this.handleSetPickedRadialEndColor
                                    }
                                    pickerRight={true}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {!this.state.isHextColorValid && (
                            <ErrorField
                              type={"relative"}
                              text={
                                this.state.isSpacialCharacterError
                                  ? colorSpecialCharacterValidMessage
                                  : hexColorNotValidMessage
                              }
                            />
                          )}
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <Formik
                enableReinitialize={true}
                initialValues={this.getAdvancedInitialValues()}
                validateOnChange={true}
                validateOnBlur={false}
                innerRef={(r) => (this.customFormikRef = r)}
                onSubmit={this.onSubmitAdvancedForm}
              >
                {(formik) => {
                  if (Object.keys(formik.errors).length > 0) {
                    this.props.setError(true);
                  } else {
                    this.props.setError(false);
                  }
                  return (
                    <Form>
                      <BlockingForm isBlocking={this.state.isBlocking} />
                      <div className={classes.SplashContainer}>
                        <span className={classes.BlockLabel}>Splash</span>
                        <div className={classes.SplashOption}>
                          <Field
                            checked={this.state.basicRadioChecked}
                            label="Basic"
                            name="basic"
                            changeHandler={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => this.inputRadioChangeHandler(e)}
                            component={RadioButton}
                          />
                          <Field
                            checked={!this.state.basicRadioChecked}
                            label="Custom"
                            name="advanced"
                            changeHandler={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => this.inputRadioChangeHandler(e)}
                            component={RadioButton}
                          />
                        </div>
                        <div>
                          {this.packageFeaturesLimited && (
                            <UpgradeComponent pageType="Splash Screen" />
                          )}
                          <p className={classes.CustomInfoText}>
                            If you want to display your splash screen visual in
                            accordance with the different mobile screen sizes
                            without any distortion, please add your visuals
                            compatible with the required sizes.
                          </p>
                          <div>{this.renderAdvancedFiles()}</div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </div>
        </div>
        <div className={classes.MobileScreen}>
          <Modal show={this.state.isUpgradeModal} width="270">
            <UpgradeGuide
              screen="splash screen"
              setIsOpenModal={() => this.closeUpgradeModal()}
            />
          </Modal>
          <Emulator
            navigateBack={this.props.selectChange}
            screen="Splash Screen"
            backTo={ScreenType.GeneralSettings.toString()}
            splashScreen={{
              isBasic: this.state.basicRadioChecked,
              splashImage,
              splashLogo: this.state.splashNavigationLogo,
              backgroundType: selectedOptionValue,
              backgroundColor: this.state.backgroundColor,
              linearColorStart: this.state.linearGradientStart,
              linearColorEnd: this.state.linearGradientEnd,
              radialColorStart: this.state.radialGradientStart,
              radialColorEnd: this.state.radialGradientEnd,
            }}
            saveScreen={this.handleFormSubmit}
          />
        </div>

        <Modal show={this.state.isShowModal} width="448">
          <div className={classes.Close} onClick={this.closeModal}>
            <img src={close} />
          </div>
          <div className={`${classes.ModalBody} ${classes.ModalBodyConfirm}`}>
            <img
              className={classes.ConfirmIcon}
              src={warning}
              width={48}
              height={48}
            />
            <span className={classes.ModalBodyText}>
              Uploaded files must be in the same format.
            </span>
          </div>
          <div className={classes.ModalFooter}>
            <div className={classes.ButtonGroup}>
              <Button
                type="button"
                className="Primary"
                width="192px"
                onClick={this.closeModal}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal>
        <UpgradeModal
          setIsOpenModal={(e) => this.setState({ isUpgradePopup: false })}
          isOpenModal={this.state.isUpgradePopup}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators(splashScreenActions, dispatch),
    _startAppLoading: (id: string) => dispatch(startAppLoading(id)),
    _endAppLoading: (id: string) => dispatch(endAppLoading(id)),
    _setChangedSthDesign: (value: boolean) =>
      dispatch(setChangedSthDesignPage(value)),
  };
};

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    splashScreenData: state.splashScreen.splashScreenData,
    isBlocking: state.manageApp.isDesignPageChange,
    isCompleted: state.userInfo.selectedApp.IsStep6Completed,
    packageFeatures:
      state.packageFeature?.featureData?.PackageFeature?.Features,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen);
