import {
    SAVE_COMPONENTS_START,
    SAVE_COMPONENTS_FINISH,
    SAVE_COMPONENTS_REJECTED
} from "panel/src/redux/constants/saveComponents";

const initialState = {
    saveComponentsResult: {}
};

const saveComponentsReducer = (state: Object = initialState, action: any) => {
    switch (action.type) {
        case SAVE_COMPONENTS_START:
            return {
                ...state,
            };

        case SAVE_COMPONENTS_FINISH:
            return {
                ...state,
                saveComponentsResult: action.result
            };
        case SAVE_COMPONENTS_REJECTED:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};

export default saveComponentsReducer;
