import { put, call, takeLatest } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import {
  setInstagramUserDataSuccess,
  setInstagramUserDataFail,
} from "panel/src/redux/actions/setInstagramUser";
import { SET_INSTAGRAM_USER_DATA } from "panel/src/redux/constants/setInstagramUser";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";

function* setInstagramUsername() {
  yield put(startAppLoading(SET_INSTAGRAM_USER_DATA));
  try {
    const setInstagramDataResponse = yield call(
      axios.get,
      "/api/Manage/GetInstagramAuthUrl"
    );

    yield put(
      setInstagramUserDataSuccess(setInstagramDataResponse.data.ResultMesage)
    );
  } catch (error) {
    yield put(setInstagramUserDataFail(error.message));
  }
  yield put(endAppLoading(SET_INSTAGRAM_USER_DATA));
}

export default function* setInstagramUserNameSaga() {
  yield takeLatest(SET_INSTAGRAM_USER_DATA, setInstagramUsername);
}
