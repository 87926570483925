import axios from "panel/src/services/api/api";
import axiosG from "panel/src/services/api/googleMerchantApi/";
import * as manageAppActions from "panel/src/redux/actions/manageApp";
import * as userInfoActions from "panel/src/redux/actions/userInfo";
import {
  startAppLoading,
  endAppLoading,
  setAppError,
} from "panel/src/redux/actions/App";
import {
  SET_APP_START,
  SET_APP_SHOPIFY,
  SET_APP_ID,
  SET_APP_GOOGLE_MERCHANT,
  CHECK_APP_INFO,
} from "panel/src/redux/constants/manageApp";
import { takeLatest, put, call } from "redux-saga/effects";
import * as pageActions from "panel/src/redux/actions/page";
import * as modalActions from "panel/src/redux/actions/modal";
import { push } from "connected-react-router";

function* manageAppProcess({ setPlatformObject }: any) {
  yield put(startAppLoading("MANAGE_APP_PROCESS"));
  try {
    const setAppResponse = yield call(axios.post, "/api/Manage/SetApp", {
      ...setPlatformObject,
    });

    if (
      (setAppResponse.data && setAppResponse.data.ResultCode === 106) ||
      105
    ) {
      try {
        yield put(
          userInfoActions.getUserInfo({
            redirect: "/manage/config-settings",
          })
        );
        yield put(pageActions.pageUpdated(true));
      } catch (error) {
        yield put(userInfoActions.fetchUserInfoFail(error));
      }
    }
  } catch (error) {
    yield put(setAppError(error.response.data.ResultMesage));
  } finally {
    yield put(modalActions.finishLoading());
  }
  yield put(endAppLoading("MANAGE_APP_PROCESS"));
}

function* checkAppInfoProcess(payload: {
  setPlatformObject: any;
  platformId: any;
  appUrl: any;
  appId: any;
  type: string;
}) {
  yield put(startAppLoading("CHECK_APP_INFO_PROCESS"));

  try {
    const checkAppInfo = yield call(axios.post, "/api/Manage/CheckAccessKey", {
      ...payload.setPlatformObject,
    });

    if (checkAppInfo.data.ResultCode === 200) {
      yield put(
        manageAppActions.setAppInputs(
          payload.setPlatformObject,
          payload.platformId,
          payload.appUrl,
          payload.appId
        )
      );

      yield put(
        push({
          pathname: "/manage/web-service-control/verify",
          state: {
            platformId: payload.platformId,
            url: payload.appUrl,
          },
        })
      );
    } else {
      yield put(setAppError("Bir hata oluştu"));
    }
  } catch (error) {
    yield put(modalActions.finishLoading());
    yield put(setAppError(error.response.data.ResultMesage));
  }

  yield put(endAppLoading("CHECK_APP_INFO_PROCESS"));
}

function* setShopifyProcess({ shopifyObj }: any) {
  yield put(startAppLoading("SET_SHOPIFY_PROCESS"));
  try {
    yield call(axios.post, "/api/Manage/SetShopifyParameters", {
      ...shopifyObj,
    });
    yield put(pageActions.pageUpdated(true));
  } catch (error) {
    yield put(manageAppActions.setAppFail(error));
  }
  yield put(endAppLoading("SET_SHOPIFY_PROCESS"));
}

function* setGoogleMerchantProcess({ googleMerchantObj, onSubmitForm }: any) {
  yield put(startAppLoading("SET_GOOGLE_MERCHANT_PROCESS"));
  try {
    const response = yield call(axiosG.post, "/api/v1/productparse", {
      ...googleMerchantObj,
    });
    if (response && response.data.succeeded) {
      yield put(manageAppActions.setAppGoogleMerchantSuccess(response.data));
      yield call(onSubmitForm);
    } else {
      yield put(
        manageAppActions.setAppGoogleMerchantFail(response.data.errors)
      );
    }
  } catch (error) {
    yield put(manageAppActions.setAppGoogleMerchantFail(error));
  }
  yield put(endAppLoading("SET_GOOGLE_MERCHANT_PROCESS"));
}

function* setSelectChangeApp({ appId, type }: { appId: string; type: string }) {
  yield put(startAppLoading("SET_SELECT_CHANGE_APP"));
  try {
    const selectRes = yield call(axios.post, "/api/Manage/SelectApp", { ApplicationId: appId });
    yield put(
      userInfoActions.getUserInfo({
        navigateAway: true,
      })
    );
    yield put(userInfoActions.setSelectedApp(selectRes.data?.ResultData));
    yield put(modalActions.finishLoading());
  } catch (error) {}
  yield put(endAppLoading("SET_SELECT_CHANGE_APP"));
}

export function* setAppGoogleMerchant() {
  yield takeLatest(SET_APP_GOOGLE_MERCHANT, setGoogleMerchantProcess);
}

export function* setAppShopify() {
  yield takeLatest(SET_APP_SHOPIFY, setShopifyProcess);
}

export function* manageAppSaga() {
  yield takeLatest(SET_APP_START, manageAppProcess);
}

export function* checkAppInfoSaga() {
  yield takeLatest(CHECK_APP_INFO, checkAppInfoProcess);
}

export function* selectChangeAppSaga() {
  yield takeLatest(SET_APP_ID, setSelectChangeApp);
}
