import React, { useState, useEffect } from "react";
import {
  Platform,
  StyleProp,
  View,
  ViewStyle,
  GestureResponderEvent,
} from "react-native";
import { colors, shadow } from "shared-components/src/utils/constants";
import ProductWrapper from "./ProductWrapper";
import Image from "./Image";
import ProductInfo from "./ProductInfo";
import ProductName from "./ProductName";
import PriceContainer from "./PriceContainer";
import ProductPrice from "./ProductPrice";
import InfoWrapper from "./InfoWrapper";
import SoldOutText from "./SoldOutText";
import DiscountPercentage from "./DiscountPercentage";
import { ColorListData } from "shared-components/src/models/colorListData";
import { ProductQty } from "../ProductQty";
import { AddToCartButton, AddToCartButtonText } from "./AddToCartButton";
import { IProduct } from "../../models/Product";
import { FavoriteButton } from "./FavoriteButton";

export interface IProductCardProps {
  colorList?: ColorListData[];
  style?: StyleProp<ViewStyle>;
  product: IProduct;
  handleProductSearch?: Function;
  isWishList?: boolean;
  setFavorite?: Function;
  removeFavorite?: Function;
  isFavorite?: boolean;
  fontFamily?: any;
  imageResizeType?: "cover" | "contain";
  soldOutText?: string;
  isAddToCart?: boolean;
  addToCartTitle?: string;
  addToCart?: (product: any) => void;
  onPressPlus?: (event: GestureResponderEvent) => void;
  onPressMinus?: (event: GestureResponderEvent) => void;
  qty?: number;
}

const ProductCard = ({
  style,
  product,
  handleProductSearch,
  isWishList,
  setFavorite,
  removeFavorite,
  colorList,
  isFavorite,
  fontFamily,
  imageResizeType = "contain",
  soldOutText = "SOLD OUT",
  isAddToCart = false,
  addToCartTitle = "Add to Cart",
  addToCart,
  onPressMinus,
  onPressPlus,
  qty = 0,
}: IProductCardProps) => {
  const [_product, setProduct] = useState(product);
  const isFavoriteTemp = isFavorite || _product.IsFavorite;

  useEffect(() => {
    setProduct(product);
  }, [product]);

  const handleSetFavorite = (item: IProduct) => {
    const _item = {
      ...item,
      IsFavorite: true,
    };
    setProduct(_item);
  };

  const handleRemoveFavorite = (item: IProduct) => {
    const _item = {
      ...item,
      IsFavorite: false,
    };
    setProduct(_item);
  };

  const toggleFavorite = () => {
    if (setFavorite && !isFavoriteTemp) {
      setFavorite(_product.ProductId, () => handleSetFavorite(_product));
    } else if (removeFavorite && isFavoriteTemp) {
      removeFavorite(_product.ProductId, () => handleRemoveFavorite(_product));
    }
  };

  return (
    <ProductWrapper
      style={[Platform.OS !== "web" && shadow, style]}
      onPress={() =>
        handleProductSearch && handleProductSearch(_product.ProductId)
      }
    >
      <View>
        <Image
          source={{ uri: _product.Images[0].ImgUrl }}
          resizeMode={imageResizeType}
        />
        {isWishList &&
          isAddToCart &&
          _product.HasStock &&
          !_product.HasVariant && (
            <FavoriteButton
              isFavorite={isFavoriteTemp}
              toggleFavorite={toggleFavorite}
              fillColor={
                colorList ? colorList[0].colorCode : colors.darkSlateBlue
              }
              style={{ position: "absolute", right: 7, top: 7 }}
            />
          )}
      </View>

      <ProductInfo>
        <ProductName
          fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
          primary={colorList ? colorList[0].colorCode : colors.darkSlateBlue}
        >
          {_product.ProductName}
        </ProductName>
        <PriceContainer>
          <View>
            {_product.StrikeoutPrice?.Price !== 0 && (
              <ProductPrice
                fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                tertiary={
                  colorList ? colorList[2].colorCode : colors.darkSlateBlue
                }
                hasdiscount={_product.StrikeoutPrice?.Price}
              >
                {_product.StrikeoutPrice?.PriceDescription}
              </ProductPrice>
            )}
            <ProductPrice
              fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
              tertiary={
                colorList ? colorList[2].colorCode : colors.darkSlateBlue
              }
            >
              {_product.Price.PriceDescription}
            </ProductPrice>
          </View>
          {_product.RebatePercentage && isAddToCart && _product.HasVariant && (
            <DiscountPercentage
              fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
              secondary={
                colorList ? colorList[1].colorCode : colors.darkSkyBlue
              }
              percentage={_product.RebatePercentage}
            />
          )}
        </PriceContainer>
        <InfoWrapper>
          {isAddToCart && _product.HasStock && !_product.HasVariant ? (
            <View style={{ flex: 1 }}>
              {qty > 0 ? (
                <ProductQty
                  onPressMinus={onPressMinus}
                  onPressPlus={onPressPlus}
                  qty={qty}
                  color={
                    colorList ? colorList[1].colorCode : colors.darkSkyBlue
                  }
                  style={{ maxWidth: "100%" }}
                />
              ) : (
                <AddToCartButton
                  bgColor={
                    colorList ? colorList[1].colorCode : colors.darkSkyBlue
                  }
                  onPress={() => addToCart?.(_product)}
                  style={{ maxWidth: "100%" }}
                >
                  <AddToCartButtonText
                    fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                  >
                    {addToCartTitle}
                  </AddToCartButtonText>
                </AddToCartButton>
              )}
            </View>
          ) : (
            <View>
              {_product.HasStock ? null : (
                <SoldOutText
                  fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                  soldOutText={soldOutText}
                />
              )}
              {_product.RebatePercentage &&
                (!isAddToCart || _product.HasVariant) && (
                  <DiscountPercentage
                    fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
                    secondary={
                      colorList ? colorList[1].colorCode : colors.darkSkyBlue
                    }
                    percentage={_product.RebatePercentage}
                  />
                )}
            </View>
          )}
          {isWishList &&
            (!isAddToCart || !_product.HasStock || _product.HasVariant) && (
              <FavoriteButton
                isFavorite={isFavoriteTemp}
                toggleFavorite={toggleFavorite}
                fillColor={
                  colorList ? colorList[0].colorCode : colors.darkSlateBlue
                }
              />
            )}
        </InfoWrapper>
      </ProductInfo>
    </ProductWrapper>
  );
};

export default ProductCard;
