import React, { Component } from "react";
import { Formik, Form, FormikProps } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "panel/src/_components/common/Button";
import { updateObject } from "panel/src/utils/helper";
import * as getEngagementReqAction from "panel/src/redux/actions/getEngagementInfo";
import BlockingForm from "panel/src/_components/common/BlockingForm";
import SubNavigation from "panel/src/_components/primitives/SubNavigation";
import classes from "./engagementOptions.module.scss";
import PushNotificationOptions from "./components/PushNotificationOptions";
import SocialMediaOption from "./components/SocialMediaOption";
import AnalyticsOption from "./components/AnalyticsOption";
import YoutubeTutorial, {
  YOUTUBE_LINKS,
} from "panel/src/Components/YoutubeTutorial";
import Intercom from "../../../Components/Intercom/Intercom";

interface IEngagementProps {
  actions?: any;
  history?: any;
  appId?: any;
  manageApp?: any;
  userInfo?: any;
  getEngagementData?: any;
  getEngagementInfo?: any;
  platformId?: number;
  isTrial?: boolean;
}

interface IEngagementState {
  isLoading?: boolean;
  error?: any;
  initialValues?: Object;
  analyticsOptionChecked?: boolean;
  socialMediaOptionChecked?: boolean;
  pushNotificationOptions?: any;
  socialMediaOption?: any;
  analyticsOption?: any;
  isFormValid?: boolean;
  ApplicationId: any;
  fileIOSPushNotificationFile?: any;
  fileAndroidPushNotificationFile?: any;
  fileIOSPushNotificationFileName?: any;
  fileAndroidPushNotificationFileName?: any;
  isShowSocialMediaOption: boolean;
  isBlocking: boolean;
}

class EngagementOptions extends Component<IEngagementProps, IEngagementState> {
  formikRef: FormikProps<any> | null = null;
  state: any = {
    isLoading: false,
    error: null,
    analyticsOptionChecked: false,
    socialMediaOptionChecked: false,
    pushNotificationOptions: [
      {
        key: "ios",
        name: "IsIosNotification",
        label: "IOS",
        isChecked: false,
        description: "IOS Push Notification",
        fileName: null,
        base64String: null,
      },
      {
        key: "android",
        name: "IsAndroidNotification",
        label: "Android",
        isChecked: false,
        description: "Android Push Notification",
        fileName: null,
        base64String: null,
      },
    ],
    socialMediaOption: {
      label: "Facebook Login",
      description:
        "Please fill in the following required fields to activate Facebook Login on your app. You can go to developers.facebook.com/apps to register for a Facebook App ID. Then, return to the App Wizard to enter your Facebook App ID.",
      isChecked: false,
      form: {
        FacebookAppId: {
          label: "Facebook App ID",
          elementType: "input",
          name: "FacebookAppId",
          elementConfig: {
            type: "text",
            placeholder: "Facebook App ID",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
        },
        FacebookAppSecret: {
          label: "Facebook App secret",
          elementType: "input",
          name: "FacebookAppSecret",
          elementConfig: {
            type: "text",
            placeholder: "Facebook App secret",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
        },
      },
    },
    analyticsOption: {
      label: "Google Analytics",
      description:
        "Please fill in the following required fields to activate the Google Analytics integration for your app.",
      isChecked: false,
      form: {
        GoogleAnalyticsAndroidPhoneId: {
          label: "Android ID",
          elementType: "input",
          name: "GoogleAnalyticsAndroidPhoneId",
          elementConfig: {
            type: "text",
            placeholder: "Android ID",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
        },
        GoogleAnalyticsIPhoneId: {
          label: "IOS ID",
          elementType: "input",
          name: "GoogleAnalyticsIPhoneId",
          elementConfig: {
            type: "text",
            placeholder: "IOS ID",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
        },
      },
    },
    isFormValid: true,
    initialValues: {
      FacebookAppId: "",
      FacebookAppSecret: "",
      GoogleAnalyticsAndroidPhoneId: "",
      GoogleAnalyticsIPhoneId: "",
    },
    isSocialMediaOption: false,
    isBlocking: false,
    formikRef: React.createRef(),
  };

  async componentDidMount() {
    this.setState({ isLoading: false });
    const { actions } = this.props;
    const data = {
      ApplicationId: this.props.appId,
    };

    try {
      actions.getEngagementInfoStart(data);
    } catch (error) {
      this.setState({ error: error, isLoading: false });
    }
  }

  componentWillReceiveProps = (nextProps: any) => {
    if (this.props.platformId != nextProps.platformId) {
      if ([27, 23, 16, 21, 29].includes(this.props.platformId!)) {
        this.setState({ isShowSocialMediaOption: false });
      } else {
        this.setState({ isShowSocialMediaOption: true });
      }
    }
    if (this.props.getEngagementData != nextProps.getEngagementData) {
      this.setState({ initialValues: nextProps.getEngagementData });
      const updatedPushNotificationOptions =
        this.state.pushNotificationOptions.map((pushNotOption: any) => {
          return {
            ...pushNotOption,
            isChecked:
              pushNotOption.key === "ios"
                ? nextProps.getEngagementData.IsIosNotification
                : nextProps.getEngagementData.IsAndroidNotification,
          };
        });

      //Social media init
      let updatedSocialMediaForm = { ...this.state.socialMediaOption.form };
      let isSocialMediaChecked = true;
      Object.keys(this.state.socialMediaOption.form).forEach((key) => {
        isSocialMediaChecked =
          nextProps.getEngagementData[key] && isSocialMediaChecked;
        updatedSocialMediaForm[key] = updateObject(
          updatedSocialMediaForm[key],
          {
            value: nextProps.getEngagementData[key],
          }
        );
      });
      const updatedSocialMediaOption = updateObject(
        this.state.socialMediaOption,
        {
          isChecked: isSocialMediaChecked,
          form: updatedSocialMediaForm,
        }
      );
      //Analytics option init
      let updatedAnalyticsOptionForm = { ...this.state.analyticsOption.form };
      let isAnalyticsOptionChecked = true;
      Object.keys(this.state.analyticsOption.form).forEach((key) => {
        isAnalyticsOptionChecked =
          nextProps.getEngagementData[key] && isAnalyticsOptionChecked;
        updatedAnalyticsOptionForm[key] = updateObject(
          updatedAnalyticsOptionForm[key],
          {
            value: nextProps.getEngagementData[key],
          }
        );
      });
      const updatedAnalyticsOption = updateObject(this.state.analyticsOption, {
        isChecked: isAnalyticsOptionChecked,
        form: updatedAnalyticsOptionForm,
      });
      this.setState({
        isLoading: false,
        pushNotificationOptions: updatedPushNotificationOptions,
        socialMediaOption: updatedSocialMediaOption,
        analyticsOption: updatedAnalyticsOption,
      });
    }
  };

  getInitialValues = () => {
    return this.state.initialValues;
  };

  fileHandler = (key: any, file: any) => {
    this.setState({ isBlocking: true });
    const index = this.state.pushNotificationOptions.findIndex(
      (option: any) => option.key === key
    );

    const base64String = file.base64.split("base64,")[1];

    const updatedOption = updateObject(
      this.state.pushNotificationOptions[index],
      {
        fileName: file.name,
        base64String,
      }
    );

    const updatedOptions = [...this.state.pushNotificationOptions];
    updatedOptions[index] = updatedOption;

    this.setState({
      pushNotificationOptions: updatedOptions,
    });
  };

  checkBoxChangeHandler = (e: any, key: any) => {
    this.setState({ isBlocking: true });
    const index = this.state.pushNotificationOptions.findIndex(
      (option: any) => option.key === key
    );
    const updatedOption = updateObject(
      this.state.pushNotificationOptions[index],
      {
        isChecked: e.target.checked,
      }
    );
    const updatedOptions = [...this.state.pushNotificationOptions];
    updatedOptions[index] = updatedOption;

    this.setState({
      pushNotificationOptions: updatedOptions,
      isFormValid: true,
    });
  };

  socialMediaToggleSwitchHandler = (e: any) => {
    this.setState({ isBlocking: true });
    const updatedSocialMediaOption = updateObject(
      this.state.socialMediaOption,
      {
        isChecked: e.target.checked,
      }
    );

    this.setState({
      socialMediaOption: updatedSocialMediaOption,
      socialMediaOptionChecked: !this.state.socialMediaOptionChecked,
      isFormValid: true,
    });
  };

  analyticsToggleSwitchHandler = (e: any) => {
    this.setState({ isBlocking: true });
    const updatedAnalyticsOption = updateObject(this.state.analyticsOption, {
      isChecked: e.target.checked,
    });

    this.setState({
      analyticsOption: updatedAnalyticsOption,
      analyticsOptionChecked: !this.state.analyticsOptionChecked,
      isFormValid: true,
    });
  };

  onSubmitForm = async (values: any) => {
    this.setState({ isBlocking: false }, async () => {
      const analyticsOptionForm = { ...this.state.analyticsOption.form };
      const socialMediaOptionForm = { ...this.state.socialMediaOption.form };
      const GoogleAnalyticsAndroidPhoneId =
        analyticsOptionForm["GoogleAnalyticsAndroidPhoneId"].value;
      const GoogleAnalyticsIPhoneId =
        analyticsOptionForm["GoogleAnalyticsIPhoneId"].value;
      const FacebookAppId = socialMediaOptionForm["FacebookAppId"].value;
      const FacebookAppSecret =
        socialMediaOptionForm["FacebookAppSecret"].value;

      let getFacebookAppId;
      let getFacebookSecretId;

      let getGoogleAnalyticsAndroidPhoneId;
      let getGoogleAnalyticsIPhoneId;

      let isSocialFormValid = true;
      let isAnalyticsFormValid = true;

      if (this.state.socialMediaOption.isChecked) {
        getFacebookAppId = FacebookAppId;
        getFacebookSecretId = FacebookAppSecret;

        isSocialFormValid = getFacebookAppId && getFacebookSecretId;
      }

      if (this.state.analyticsOption.isChecked) {
        getGoogleAnalyticsAndroidPhoneId = GoogleAnalyticsAndroidPhoneId;
        getGoogleAnalyticsIPhoneId = GoogleAnalyticsIPhoneId;

        isAnalyticsFormValid =
          getGoogleAnalyticsAndroidPhoneId && getGoogleAnalyticsIPhoneId;
      }

      let formValid = !!isSocialFormValid && !!isAnalyticsFormValid;
      this.setState({ isFormValid: formValid });

      if (formValid) {
        const engagementSendingObj = {
          ApplicationId: this.props.appId,
          IsIosNotification: this.state.pushNotificationOptions[0].isChecked,
          IsAndroidNotification:
            this.state.pushNotificationOptions[1].isChecked,
          FacebookAppId: getFacebookAppId,
          FacebookAppSecret: getFacebookSecretId,
          GoogleAnalyticsAndroidPhoneId: getGoogleAnalyticsAndroidPhoneId,
          GoogleAnalyticsIPhoneId: getGoogleAnalyticsIPhoneId,
        };

        try {
          await this.props.actions.setEngagementInfoStart(engagementSendingObj);
          this.props.history.push("/manage/completion");
        } catch (error) {
          console.log("error");
        }
      }
    });
  };

  changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedAnalyticsOptionForm = { ...this.state.analyticsOption.form };
    let updatedSocialMediaForm = { ...this.state.socialMediaOption.form };

    let key = "";
    switch (e.target.name) {
      case "GoogleAnalyticsAndroidPhoneId":
        key = "GoogleAnalyticsAndroidPhoneId";
        updatedAnalyticsOptionForm[key] = updateObject(
          updatedAnalyticsOptionForm[key],
          {
            value: e.target.value,
          }
        );
        break;
      case "GoogleAnalyticsIPhoneId":
        key = "GoogleAnalyticsIPhoneId";
        updatedAnalyticsOptionForm[key] = updateObject(
          updatedAnalyticsOptionForm[key],
          {
            value: e.target.value,
          }
        );
        break;
      case "FacebookAppId":
        key = "FacebookAppId";
        updatedSocialMediaForm[key] = updateObject(
          updatedSocialMediaForm[key],
          {
            value: e.target.value,
          }
        );
        break;
      case "FacebookAppSecret":
        key = "FacebookAppSecret";
        updatedSocialMediaForm[key] = updateObject(
          updatedSocialMediaForm[key],
          {
            value: e.target.value,
          }
        );
        break;
      default:
        break;
    }

    const updatedAnalyticsOption = updateObject(this.state.analyticsOption, {
      form: updatedAnalyticsOptionForm,
    });

    const updatedSocialMediaOption = updateObject(
      this.state.socialMediaOption,
      {
        form: updatedSocialMediaForm,
      }
    );

    this.setState({
      analyticsOption: updatedAnalyticsOption,
      socialMediaOption: updatedSocialMediaOption,
      isFormValid: true,
    });
  };

  render() {
    let formContent: any;
    if (this.state.isLoading) {
      formContent = null;
    } else {
      const socialMediaFormElements = [];
      for (let key in this.state.socialMediaOption.form) {
        socialMediaFormElements.push({
          id: key,
          config: this.state.socialMediaOption.form[key],
        });
      }

      const analyticsFormElements = [];
      for (let key in this.state.analyticsOption.form) {
        analyticsFormElements.push({
          id: key,
          config: this.state.analyticsOption.form[key],
        });
      }

      formContent = (
        <React.Fragment>
          <PushNotificationOptions
            pushNotificationOptions={this.state.pushNotificationOptions}
            checkBoxChangeHandler={this.checkBoxChangeHandler}
            fileHandler={this.fileHandler}
            disabled={this.props.isTrial}
            isTrial={this.props.isTrial}
          />
          {this.state.isShowSocialMediaOption ? (
            <SocialMediaOption
              label={this.state.socialMediaOption.label}
              checked={this.state.socialMediaOption.isChecked}
              description={this.state.socialMediaOption.description}
              toggleSwitchHandler={this.socialMediaToggleSwitchHandler}
              formElements={socialMediaFormElements}
              disabled={this.props.isTrial}
              isTrial={this.props.isTrial}
            />
          ) : null}
          <AnalyticsOption
            label={this.state.analyticsOption.label}
            checked={this.state.analyticsOption.isChecked}
            description={this.state.analyticsOption.description}
            toggleSwitchHandler={this.analyticsToggleSwitchHandler}
            inputChangeHandler={this.changeHandler}
            formElements={analyticsFormElements}
            isValid={this.state.isFormValid}
            // disabled={this.props.isTrial}
            isTrial={this.props.isTrial}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <SubNavigation />
        <Intercom />
        <div className={classes.contentContainer}>
          <div className={classes.ManageSubRouteContainer}>
            <div className={classes.HeadingWrapper}>
              <h3 className={classes.Heading}>Engagement options</h3>
              <YoutubeTutorial
                videoUrl={YOUTUBE_LINKS.ENGAGEMENT_OPTIONS}
                iconSize={16}
              />
            </div>
            <Formik
              innerRef={(r) => (this.formikRef = r)}
              enableReinitialize={true}
              initialValues={this.getInitialValues()}
              onSubmit={this.onSubmitForm}
            >
              {(formik) => (
                <Form className={classes.engagementFormContainer}>
                  {formContent}
                </Form>
              )}
            </Formik>
          </div>
          <div className={classes.SaveButtonContainer}>
            <Button
              width="160px"
              type="Primary"
              onClick={() => this.formikRef?.submitForm()}
            >
              Continue
            </Button>
          </div>
        </div>
        <BlockingForm isBlocking={this.state.isBlocking} />
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(getEngagementReqAction, dispatch),
  };
}

const mapStateToProps = (state: IEngagementProps) => {
  return {
    appId: state.userInfo.selectedAppId,
    getEngagementData: state.getEngagementInfo.engagementData,
    platformId: state.userInfo.selectedApp.PlatformId,
    isTrial: state.userInfo.IsTrial,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EngagementOptions);
