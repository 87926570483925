import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classes from "./bottomNavigation.module.scss";
import needHelpIcon from "../../../assets/images/need-help.svg";
import Icon from "../../common/Icon";
import { History } from "history";
import moment from "moment";
import TargetIcon from "panel/src/assets/images/target.svg";
interface IBottomProps {
  isSideMenuFull: boolean;
  userName: string;
  history?: History;
  logoutClickHandler: Function;
  isChatOpen: boolean;
  expireDate: string;
  isShowPricingBanner: boolean;
  isTrial: boolean;
  UserPackage: any;
}

const BottomNavigation = (props: IBottomProps) => {
  const logOutNode = useRef<HTMLDivElement>(null);
  const helpNode = useRef<HTMLDivElement>(null);

  const [isLogOutOpen, setLogOutOpen] = useState(false);
  const [isHelpOpen, setHelpOpen] = useState(false);

  const clickOutside = (e: any) => {
    if (logOutNode.current && !logOutNode.current.contains(e.target)) {
      setLogOutOpen(false);
    }
    if (helpNode.current && !helpNode.current.contains(e.target)) {
      setHelpOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", clickOutside);

    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);

  const sendMail = () => {
    window.open("mailto:support@mowico.com");
  };

  const watchVideoClickHandler = () => {
    window.open(
      "https://www.youtube.com/watch?v=Vml3IDa-U9Y&list=PLMimttjnzmpkSfscdkGynmSm9EI_Gccm0"
    );
  };

  return (
    <React.Fragment>
      <ul className={classes.BottomNav}>
        {props.isShowPricingBanner &&
          props.UserPackage?.IsUpgradePossible &&
          props.isSideMenuFull && (
            <li>
              <a
                href="https://mowico.com/pricing/"
                target="_blank"
                className={
                  props.isSideMenuFull
                    ? classes.FullBottomNavItem
                    : classes.BottomNavItem
                }
              >
                <img
                  className={classes.pricingBannerIcon}
                  src={TargetIcon}
                  alt="upgrade-now"
                />
                <p className={classes.pricingBannerLabel}>Upgrade now!</p>
              </a>
            </li>
          )}
        {props.isSideMenuFull && (
          <li>
            <div className={classes.expireDate}>
              <span className={classes.planLabel}>
                <span className={classes.planLabelDesc}>Your</span>
                {props.isTrial
                  ? ` free trial plan `
                  : ` ${props.UserPackage.PackageName} Plan `}
                <span className={classes.planLabelDesc}>will expire on</span>
                {` ${moment(props.expireDate).format("DD.MM.YYYY")}`}.
              </span>
            </div>
          </li>
        )}

        {props.isSideMenuFull && <p className={classes.Line} />}
        <li>
          <div
            className={
              props.isSideMenuFull
                ? classes.FullBottomNavItem
                : classes.BottomNavItem
            }
            ref={logOutNode}
            onClick={() => watchVideoClickHandler()}
          >
            <Icon
              type="watchVideo"
              fillColor="#fecc0f"
              width="16"
              height="16"
            />
            {props.isSideMenuFull ? (
              <span className={classes.Label} style={{ color: "#fecc0f" }}>
                Watch Tutorials
              </span>
            ) : null}
          </div>
        </li>

        {/*  <li>
          <div
            className={
              props.isSideMenuFull
                ? classes.FullBottomNavItem
                : classes.BottomNavItem
            }
            ref={helpNode}
            onClick={() => {
              setHelpOpen(!isHelpOpen);
            }}
          >
            <img src={needHelpIcon} alt="need-help" />
            {props.isSideMenuFull ? (
              <span className={classes.Label}>Help Center</span>
            ) : null}
          </div>

          {isHelpOpen && (
            <div
              style={{ left: props.isSideMenuFull ? 100 : 30 }}
              className={classes.helpContextMenu}
            >
              <span className={classes.helpMenuTitle}>Help</span>
                <div
                className={classes.contextMenuItem}
                onClick={() => watchVideoClickHandler()}
              >
                <button className={classes.menuItemBtn}>
                  <Icon
                    type='watchVideo'
                    fillColor='#3e5e6e'
                    width='16'
                    height='16'
                  />
                  Watch Video Tutorials
                </button>
              </div>
              <div className={classes.contextMenuItem}>
                <button
                  className={classes.menuItemBtn}
                  onClick={() => sendMail()}
                >
                  <Icon
                    type="mail"
                    fillColor="#3e5e6e"
                    width="17"
                    height="17"
                  />
                  Email Us
                </button>
              </div>
            </div>
          )}
        </li>*/}
        {/*  <li>
          <div
            className={
              props.isSideMenuFull
                ? classes.FullBottomNavItem
                : classes.BottomNavItem
            }
            ref={logOutNode}
            onClick={() => {
              setLogOutOpen(!isLogOutOpen);
            }}
          >
            <img src={profileIcon} alt='log out' />
            {props.isSideMenuFull ? (
              <span className={classes.Label}>{props.userName}</span>
            ) : null}
          </div>
          {isLogOutOpen && (
            <div
              style={{ left: props.isSideMenuFull ? 100 : 30 }}
              className={classes.logOutContextMenu}
              onClick={() => {
                props.logoutClickHandler();
              }}
            >
              <div className={classes.logOutMenuWrapper}>
                <button className={classes.logOutBtn}>
                  <Icon
                    type='logOut'
                    fillColor='#0F3649'
                    width='20'
                    height='20'
                  />
                  Log Out
                </button>
              </div>
            </div>
          )}
        </li> */}

        <li>
          <div
            className={
              props.isSideMenuFull
                ? classes.FullBottomNavItem
                : classes.BottomNavItem
            }
            ref={logOutNode}
            onClick={() => {
              props.logoutClickHandler();
            }}
          >
            <Icon type="logOut" fillColor="#fff" width="16" height="16" />
            {props.isSideMenuFull ? (
              <span className={classes.Label}> Log Out</span>
            ) : null}

            <div className={classes.versionContainer}>
              <span className={classes.versionLabel}>
                v{process.env.REACT_APP_VERSION}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userName: state.userInfo.userName,
    expireDate: state.userInfo.ExpireDate,
    isShowPricingBanner: state.userInfo.isShowPricingBanner,
    isTrial: state.userInfo.IsTrial,
    UserPackage: state.userInfo?.UserPackage,
  };
};

export default withRouter<any, any>(
  connect(mapStateToProps, null)(BottomNavigation)
);
