import * as Yup from "yup";

import {
  requiredMessage,
  emailErrorMessage,
  minMessage,
  maxMessage,
  appNameMaxMessage,
  appNameRequiredMessage
} from "./validationMessage";
import { email as emailRegEx, password as passwordRegEx } from "./regex";

//Define your rules
export const requiredString = Yup.string()
  .required(requiredMessage);

// EMAIL SHAPE - REQUIRED
export const email = Yup.string()
  .matches(emailRegEx, emailErrorMessage)
  .required(requiredMessage);

// PASSWORD SHAPE - REQUIRED
export const password = Yup.string()
  .min(8, minMessage)
  .max(12, maxMessage)
  .matches(passwordRegEx, emailErrorMessage)
  .required(requiredMessage);

// GENERAL SETTINGS APP NAME SHAPE 
export const appName = Yup.string()
  .max(30, appNameMaxMessage)
  .required(appNameRequiredMessage)
  .nullable();


const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = [
  "image/png"
];

export const androidAppIcon = Yup.object().shape({
  text: Yup.string().required("A text is required"),
  file: Yup
    .mixed()
    .required("Please add IOS or Android app icon")
    .test(
      "fileSize",
      "File too large",
      value => value && value.size <= FILE_SIZE
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      value => value && SUPPORTED_FORMATS.includes(value.type)
    )
});