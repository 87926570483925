import React, { Component, MouseEventHandler } from "react";
import classes from "./modal.module.scss";
import Backdrop from "../Backdrop";

interface IModalProps {
  show?: any;
  children: any;
  backdropClickedHandler?: MouseEventHandler;
  width?: string;
  style?: React.CSSProperties;
}
class Modal extends Component<IModalProps> {
  shouldComponentUpdate(nextProps: IModalProps) {
    return (
      nextProps.show !== this.props.show ||
      nextProps.children !== this.props.children
    );
  }

  render() {
    return (
      <React.Fragment>
        <Backdrop
          show={this.props.show}
          clickHandler={this.props.backdropClickedHandler}
        />
        <div
          className={classes.Modal}
          style={{
            ...this.props?.style,
            display: this.props.show ? "flex" : "none",
            width: `${this.props.width}px`,
          }}
        >
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}

export default Modal;
