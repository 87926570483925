import styled from "styled-components";

interface IType {
  isActive: boolean;
}

export default styled.div<IType>`
  display: flex;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: #f3f5f6;
  border-radius: 4px;
  border: 2px solid ${(props) => (props.isActive ? "#3e5e6e" : "#cfd7db")};
  overflow: hidden;
`;
