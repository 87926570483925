import React, { CSSProperties } from "react";
import { OS } from "panel/src/Components/DragnDrop/Emulator/ToggleOS";
import Container from "./Container";
import IOSTime from "./IOSTime";
import IOSNotch from "./IOSNotch";
import AndroidStatusbar from "panel/src/assets/images/android-statusbar.svg";
import IOSStatusbar from "panel/src/assets/images/ios-statusbar.svg";

interface IStatusBar {
  platform: OS.Android | OS.IOS;
  hideStatusbar: boolean;
}

interface IPlatforms {
  [key: number]: {
    height: number;
    style: CSSProperties;
    statusBar: string;
  };
}

const Platforms: IPlatforms = {
  [OS.Android]: {
    height: 17,
    style: {
      backgroundColor: "#f3f5f6",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    },
    statusBar: AndroidStatusbar,
  },
  [OS.IOS]: {
    height: 7,
    statusBar: IOSStatusbar,
    style: {
      position: "absolute",
      height: 19,
      alignItems: "flex-end",
      justifyContent: "space-between",
      top: 10,
      right: 20,
      left: 33,
    },
  },
};

const StatusBar = ({ platform, hideStatusbar }: IStatusBar) => {
  return (
    <Container style={Platforms[platform].style}>
      {platform === OS.IOS && !hideStatusbar && <IOSTime>9:41</IOSTime>}
      {platform === OS.IOS && <IOSNotch />}
      {!hideStatusbar && (
        <img
          src={Platforms[platform].statusBar}
          height={Platforms[platform].height}
        />
      )}
    </Container>
  );
};

export default StatusBar;
