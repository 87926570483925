import {
    SAVE_COMPONENTS_START,
    SAVE_COMPONENTS_FINISH,
    SAVE_COMPONENTS_REJECTED,
} from "panel/src/redux/constants/saveComponents";
import { SaveComponentRequestModel } from "../../models/Design/saveComponentRequestModel";
import { SaveComponentsResponseModel } from "../../models/Design/saveComponentsResponseModel";

export const saveComponentsStart = (requestObj: SaveComponentRequestModel) => {
    return {
        type: SAVE_COMPONENTS_START,
        requestObj,
    };
};

export const saveComponentsFinish = (result: SaveComponentsResponseModel) => {
    return {
        type: SAVE_COMPONENTS_FINISH,
        result,
    };
};

export const saveComponentsRejected = (error: string) => ({
    type: SAVE_COMPONENTS_REJECTED,
    error,
});


