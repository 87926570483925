import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Begin from "./WebServiceControl/Begin";
import Verify from "./WebServiceControl/Verify";
import ConfigSettings from "./ConfigSettings";
import PaymentOptions from "./PaymentOptions";
import EngagementOptions from "./EngagementOptions";
import Completion from "../Manage/Completion";

class Manage extends Component {
  render() {
    const routes = (
      <Switch>
        <Route
          exact
          path='/manage/web-service-control/begin'
          component={Begin}
        />
        <Route
          exact
          path='/manage/web-service-control/verify'
          component={Verify}
        />
        <Route
          exact
          path='/manage/config-settings'
          component={ConfigSettings}
        />
        <Route
          exact
          path='/manage/payment-options'
          component={PaymentOptions}
        />
        <Route
          path='/manage/engagement-options'
          component={EngagementOptions}
        />
        <Route exact path='/manage/completion' component={Completion} />
        <Redirect exact to='/manage/web-service-control/begin' />
      </Switch>
    );

    return <React.Fragment>{routes}</React.Fragment>;
  }
}

export default Manage;
