import styled from "styled-components";

export default styled.div`
  margin-top: 12px;
  display: flex;
  width: 224px;
  height: 64px;
  background-color: #f3f5f6;
  align-items: center;
  justify-content: flex-start;
`;
