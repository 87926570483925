import {
  CLIENT_CONFIG_LIST_START,
  CLIENT_CONFIG_LIST_SUCCESS,
  CLIENT_CONFIG_LIST_FAIL,
  SAVE_APP_CONFIG_LIST_START,
  SAVE_APP_CONFIG_LIST_SUCCESS,
  SAVE_APP_CONFIG_LIST_FAIL,
} from "panel/src/redux/constants/clientConfigList";

export type clientConfigListStateTypes = Readonly<{
  loading?: boolean;
  error?: boolean;
  saveConfigResult: number;
  success?: boolean;
}>;

const initialState: clientConfigListStateTypes = {
  loading: false,
  error: false,
  saveConfigResult: 0,
};

const clientConfigListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLIENT_CONFIG_LIST_START:
      return {
        ...state,
        configListObj: action.configListObj,
      };

    case CLIENT_CONFIG_LIST_SUCCESS:
      return {
        ...state,
        configList: action.configList,
        saveConfigResult: 0,
      };
    case CLIENT_CONFIG_LIST_FAIL:
      return {
        ...state,
      };
    case SAVE_APP_CONFIG_LIST_START:
      return {
        ...state,
        data: action.data,
      };

    case SAVE_APP_CONFIG_LIST_SUCCESS:
      return {
        ...state,
        configList: action.configList,
        saveConfigResult: action.saveConfigResult,
      };
    case SAVE_APP_CONFIG_LIST_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default clientConfigListReducer;
