import {
    SET_LOGO_POSITION,
    GET_LOGO_POSITION_START,
    GET_LOGO_POSITION_SUCCESS,
    GET_LOGO_POSITION_FAILED,
    UPDATE_LOGO_POSITION_START,
    UPDATE_LOGO_POSITION_SUCCESS,
    UPDATE_LOGO_POSITION_FAILED,
} from "panel/src/redux/constants/logoPosition";

const initialState = {
    position: 'left',
    isLoading: false,
};

const logoPositionReducer = (state: Object = initialState, action: any) => {
    switch (action.type) {
        case SET_LOGO_POSITION:
            return {
                ...state,
                position: action.payload,
            };
        case GET_LOGO_POSITION_START:
            return {
                ...state,
                isLoading: true,
            };
        case GET_LOGO_POSITION_SUCCESS:
            return {
                ...state,
                position: action.payload,
                isLoading: false,
            };
        case GET_LOGO_POSITION_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case UPDATE_LOGO_POSITION_START:
        case UPDATE_LOGO_POSITION_SUCCESS:
        case UPDATE_LOGO_POSITION_FAILED:
        default:
            return state;
    }
};

export default logoPositionReducer;
