import { Platform } from "react-native";
import styled from "styled-components/native";
import { dimenWidth } from "shared-components/src/utils/helper";
import { colors } from "shared-components/src/utils/constants";

export default styled.View`
  background-color: ${colors.lightBlueGrey};
  padding: ${Platform.OS === "web" ? "2" : dimenWidth(2)}px;
  border-radius: ${Platform.OS === "web" ? "2" : dimenWidth(2)}px;
  padding-left: 3px;
  padding-right: 3px;
`;
