import React, { FC } from "react";
import styled from "styled-components";
import Icon from "panel/src/_components/common/Icon";
import SidebarMenuItem from "./SidebarMenuItem";
import { useSelector } from "react-redux";

export type StyleProps = {
  fontFamily?: string;
  isAndroid?: boolean;
  toggleSidebar?: () => void;
  isCentered?: boolean;
}

const MainContainer = styled.div``;

const Container = styled.div<StyleProps>`
  width: 170px;
  position: absolute;
  z-index: 99;
  display: flex;
  border-bottom-left-radius: 50px;
  font-family: ${(props) => `${props.fontFamily};`};
  ${({ isAndroid }) => isAndroid ? `
    top: 45px;
    height: 497px;
  ` : `
    top: 33px;
    height: 494px;
  `}
`;

const SidebarWrapper = styled.div<StyleProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  z-index: 10;
  border-bottom-left-radius: 26px;
  overflow: auto;
`;

const OverlayContainer = styled.div<StyleProps>`
  /* background-color: rgba(0, 0, 0, 0.05); */
  position: absolute;
  left: 170px;
  overflow: hidden;
  border-bottom-right-radius: 25px;
  cursor: pointer;
  ${({ isAndroid }) => isAndroid ? `
    top: 45px;
    width: 77px;
    height: 497px;
  ` : `
    top: 33px;
    height: 494px;
    width: 82px;
  `}
`

const Shadow = styled.div<StyleProps>`
  width: 20px;
  height: 456px;
  box-shadow: 0 2px 15px 0 #cdcdcd;
  background-color: #fff;
  top: 16px;
  position: absolute;
  left: ${({ isAndroid }) => !isAndroid ? `-8px` : `-14px`};
`;

const LoginWrapper = styled.div<StyleProps>`
  margin-top: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #3196f6;
  font-size: 14px;
  padding: 0 14px;
  ${({ isCentered }) => isCentered && `padding-left: 40px`};
  font-family: ${(props) => `${props.fontFamily} Bold;`};
`;

const EditButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: #3196f6;
  font-family: inherit;
  color: #fff;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const MenuWrapper = styled.div`
  margin-top: 20px;
`;

type SidebarProps = {
  editSidebar?: () => void;
} & StyleProps;

export const filterActiveMenuItems = (item: any) => !!item?.IsActive && !!item?.IsEnabled;

const Sidebar: FC<SidebarProps> = ({ editSidebar, fontFamily = 'Helvetica Neue', toggleSidebar }) => {
  const emulatorOs = useSelector((state: any) => state.emulator.os);
  const isAndroid = emulatorOs === 2;
  const {
    MenuItems: items,
    NameAlignment: menuItemPosition
  } = useSelector((state: any) => state.navigationMenu);

  const isCentered = menuItemPosition === 'center';
  const withoutIcon = menuItemPosition === 'empty';

  return (
    <MainContainer className="sidebar">
      <Container
        fontFamily={fontFamily}
        isAndroid={isAndroid}
      >
        <SidebarWrapper>
          <LoginWrapper
            fontFamily={fontFamily}
            isCentered={isCentered}
          >
            <div>Login</div>
            <EditButton onClick={editSidebar}>
              <Icon
                type="editIcon"
                width="17"
                height="17"
                className="editIcon"
              />
            </EditButton>
          </LoginWrapper>
          <MenuWrapper>
            {items?.filter(filterActiveMenuItems)?.map((item: any, idx: number) => (
              <SidebarMenuItem
                key={idx}
                index={idx}
                name={item?.Name}
                iconUrl={item?.IconUrl}
                fontFamily={fontFamily}
                isCentered={isCentered}
                withoutIcon={withoutIcon}
              />
            ))}
          </MenuWrapper>
        </SidebarWrapper>
      </Container>
      <OverlayContainer
        onClick={toggleSidebar}
        isAndroid={isAndroid}
      >
        <Shadow isAndroid={isAndroid} />
      </OverlayContainer>
    </MainContainer>
  );
}

export default Sidebar;