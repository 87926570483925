import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
} from "panel/src/redux/constants/login";


export type LoginStateTypes = Readonly<{
  loading?: boolean,
  error?: boolean,
}>;

const initialState: LoginStateTypes = {
  loading: false,
  error: false,
}


const loginReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_START:
      return {
        ...state,
        loading: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        error: false,
        loading: false
      };
    case LOGIN_FAIL:
      return {
        ...state,
        error: true,
        loading: false
      };
    default:
      return state;
  }
};

export default loginReducer;
