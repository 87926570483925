import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { dimenFont } from 'shared-components/src/utils/helper';

interface IProductName {
  primary?: string;
  fontFamily?: any;
}

export default styled.Text.attrs({ numberOfLines: 1 })<IProductName>`
  font-size: ${Platform.OS === 'web' ? '9' : dimenFont(12)}px;
  font-family: ${(props) =>
    Platform.OS === 'web' ? props.fontFamily : props.fontFamily!.Regular};
  color: ${(props) => props.primary};
  ${Platform.OS === "web" && `flex-shrink: initial !important;`}
`;
