import styled from 'styled-components';

interface ISplashImage {
  imgUrl: string;
}

export default styled.div<ISplashImage>`
  height: 100%;
  width: 100%;
  background: url(${(props) => props.imgUrl}) no-repeat center center;
  background-size: auto 100%;
`;
