import styled from "styled-components";
import Span from "./Span";

interface IContainer {
  height: string;
}

export default styled.div<IContainer>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: ${(props) => props.height}px;
  width: 100%;
  background-color: #f3f5f6;

  &:hover {
    background-color: #6d8694;
  }

  &:hover ${Span} {
    color: #ffffff;
  }
`;
