import React from "react";
import Container from "./Container";
import Text from "./Text";

interface ISoldOutText {
  fontFamily?: any;
  soldOutText: string;
}

const SoldOutText = ({ fontFamily, soldOutText }: ISoldOutText) => {
  return (
    <Container>
      <Text fontFamily={fontFamily}>{soldOutText}</Text>
    </Container>
  );
};

export default SoldOutText;
