import React, { Component } from "react";
import { Platform, TouchableOpacity } from "react-native";
import styled from "styled-components/native";
import Heart from "../../icons/Heart";
import HeartFill from "../../icons/HeartFill";
import { ColorListData } from "shared-components/src/models/colorListData";
import {
  dimenFont,
  dimenHeight,
  dimenWidth,
} from "shared-components/src/utils/helper";
import { colors, shadow } from "shared-components/src/utils/constants";

interface IProps {
  name: string;
  isInWishList?: boolean;
  isWishList?: boolean;
  setFavorite?: Function;
  removeFavorite?: Function;
  colorList?: ColorListData[];
  fontFamily?: any;
}

interface IState {
  isFavoriteItem?: boolean;
  primary?: string;
  secondary?: string;
  tertiary?: string;
}

interface IElementStyleProps {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  fontFamily?: any;
}

const Container = styled.View`
  ${Platform.OS === "web" ? `padding: 5px 10px;` : `margin: 8px 16px;`}
`;

const Wrapper = styled.View`
  ${Platform.OS === "web"
    ? `box-shadow: 0px 0px 5px rgba(15, 54, 73, 0.25);`
    : ``}
`;

const BlockGroup = styled.View`
  width: 100%;
  background-color: ${colors.white};
  padding: ${Platform.OS === "web" ? "12" : dimenWidth(16)}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Field = styled.Text<IElementStyleProps>`
  font-size: ${Platform.OS === "web" ? "10.4" : dimenFont(14)}px;
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
  color: ${(props) => props.primary};
`;

class ProductName extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      primary: "#0F3649",
      secondary: "#3196F6",
      tertiary: "#0F3649",
      isFavoriteItem: this.props.isInWishList,
    };
  }

  componentDidUpdate(
    _prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    _snapshot?: any
  ): void {
    if (prevState.isFavoriteItem !== this.props.isInWishList) {
      this.setState({ isFavoriteItem: this.props.isInWishList });
    }
  }

  handleSetFavorite = () => {
    this.setState({ isFavoriteItem: true });
  };

  handleRemoveFavorite = () => {
    this.setState({ isFavoriteItem: false });
  };

  toggleFavorite = () => {
    const { setFavorite, removeFavorite } = this.props;
    const { isFavoriteItem } = this.state;
    if (setFavorite && !isFavoriteItem) {
      setFavorite(() => this.handleSetFavorite());
    } else if (removeFavorite && isFavoriteItem) {
      removeFavorite(() => this.handleRemoveFavorite());
    }
  };

  render() {
    const { isFavoriteItem, primary, secondary, tertiary } = this.state;
    const { isWishList, colorList, fontFamily } = this.props;

    return (
      <Container>
        <Wrapper style={Platform.OS === "web" ? {} : shadow}>
          <BlockGroup>
            <Field
              style={isWishList && { width: "75%" }}
              primary={colorList ? colorList[0].colorCode : primary}
              fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
            >
              {this.props.name}
            </Field>
            {isWishList && (
              <TouchableOpacity onPress={() => this.toggleFavorite()}>
                {isFavoriteItem ? (
                  <HeartFill
                    fillColor={
                      colorList ? colorList[0].colorCode : colors.darkSlateBlue
                    }
                    height={
                      Platform.OS === "web" ? "13" : dimenHeight(20).toString()
                    }
                    width={
                      Platform.OS === "web" ? "13" : dimenWidth(20).toString()
                    }
                  />
                ) : (
                  <Heart
                    fillColor={
                      colorList ? colorList[0].colorCode : colors.darkSlateBlue
                    }
                    height={
                      Platform.OS === "web" ? "13" : dimenHeight(20).toString()
                    }
                    width={
                      Platform.OS === "web" ? "13" : dimenWidth(20).toString()
                    }
                  />
                )}
              </TouchableOpacity>
            )}
          </BlockGroup>
        </Wrapper>
      </Container>
    );
  }
}

export default ProductName;
