import React from "react";
import styled, { keyframes } from "styled-components";

const StyledButton = styled.button`
  margin:12px 0;
  background-color: #3196f6;
  color: #fff;
  border: 2px solid #3196f6;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  border-radius: 5px;
  padding: 10px 25px;
  cursor: pointer;
  width: 187px;
  ${(props) =>
    !props.disabled
      ? `&:hover {
      background-color: #1f81df;
      border: 2px solid #1f81df;
      cursor: pointer;
        }`
      : "cursor: not-allowed;"}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;

  &:after {
    content: " ";
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 4px solid #fff;
    border-color: #fff #fff #fff transparent;
    animation: ${rotate} 1.2s linear infinite;
  }
`;

interface IButton {
  children: React.ReactNode;
  onClick: Function;
  disabled?: boolean | undefined;
}

const Button = ({ onClick, disabled, children }: IButton) => {
  return (
    <StyledButton onClick={() => onClick()} disabled={disabled}>
      {disabled ? <Loader /> : children}
    </StyledButton>
  );
};

export default Button;
