export const CREATE_APP_START: string = "CREATE_APP_START";
export const CREATE_APP_FINISH: string =
    "CREATE_APP_FINISH";
export const CREATE_APP_REJECTED: string =
    "CREATE_APP_REJECTED";

export const DELETE_APP_START: string = "DELETE_APP_START";
export const DELETE_APP_FINISH: string =
    "DELETE_APP_FINISH";
export const DELETE_APP_REJECTED: string =
    "DELETE_APP_REJECTED";

export const RENAME_APP_START: string = "RENAME_APP_START";
export const RENAME_APP_FINISH: string =
    "RENAME_APP_FINISH";
export const RENAME_APP_REJECTED: string =
    "RENAME_APP_REJECTED";