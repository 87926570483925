export const GET_NAVIGATION_TYPE_START: string = "GET_NAVIGATION_TYPE_START";
export const GET_NAVIGATION_TYPE_FINISH: string =
    "GET_NAVIGATION_TYPE_FINISH";
export const GET_NAVIGATION_TYPE_REJECTED: string =
    "GET_NAVIGATION_TYPE_REJECTED";

export const SET_NAVIGATION_TYPE_START: string = "SET_NAVIGATION_TYPE_START";
export const SET_NAVIGATION_TYPE_FINISH: string =
    "SET_NAVIGATION_TYPE_FINISH";
export const SET_NAVIGATION_TYPE_REJECTED: string =
    "SET_NAVIGATION_TYPE_REJECTED";
export const ADD_NAVIGATION_TYPE_ICON: string = "ADD_NAVIGATION_TYPE_ICON";