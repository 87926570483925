import React, { Component } from "react";
import classes from "./installToStore.module.scss";
import Icon from "../../../_components/common/Icon";
import { History } from "history";
import Button from "../../../_components/common/Button";
import { schedulerTimeFormat } from "../../../utils/helper";
import { connect } from "react-redux";
import IResendDemoLinkRequest from "../../../models/Publish/resendDemo";
import { resetAskDemo } from "panel/src/redux/actions/askDemo";
import { resendDemoLink } from "panel/src/redux/actions/resendDemo";
import IDemoInfoRequest from "../../../models/Publish/demoInfoRequest";
import { demoInfo, resetDemoInfo } from "panel/src/redux/actions/demoInfo";
import rocketIcon from "panel/src/assets/images/rocket.png";
import infoIcon from "panel/src/assets/images/information.svg";
import settingsIcon from "panel/src/assets/images/settings.png";
import cancelIcon from "panel/src/assets/images/close.svg";
import Modal from "../../../_components/common/Modal";

interface IProps {
  history: History;
  _resendDemoLink: Function;
  _resetAskDemo: Function;
  _resetDemoInfo: Function;
  _demoInfo: Function;
  appId: string;
  demoTime: any;
  demoInfoSuccess: boolean;
  demoData: any;
  isMailSend: boolean;
  IsStep13Completed: boolean;
}

interface IState {
  running: boolean;
  count: number;
  isDisable: boolean;
  seconds: string;
  isModalVisible: boolean;
}

class InstallToStore extends Component<IProps, IState> {
  timer: NodeJS.Timeout | undefined;
  constructor(props: IProps) {
    super(props);
    this.state = {
      running: false,
      count: 0,
      isDisable: true,
      seconds: "",
      isModalVisible: false,
    };
  }

  componentDidMount = () => {
    const demoInfoRequestObject: IDemoInfoRequest = {
      applicationId: this.props.appId,
    };
    this.props._demoInfo(demoInfoRequestObject);
    if (!this.props.IsStep13Completed && !this.props.isMailSend) {
      this.props._resetAskDemo();
      this.props.history.push("/publish/qr");
    }
  };
  //for fix conflicts
  componentWillReceiveProps = (nextProps: any) => {
    if (nextProps.demoTime) {
      var hms = nextProps.demoTime; // your input string
      var a = hms.split(":"); // split it at the colons
      var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      this.handleCountdown(parseInt(seconds.toString(), 10));
      this.setState({
        seconds: seconds.toString(),
      });
    }
  };
  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.state.running !== prevState.running) {
      switch (this.state.running) {
        case true:
          this.handleStart();
      }
    }
  }

  componentWillUnmount = () => {
    this.props._resetAskDemo();
    this.handleReset;
    const demoInfoRequestObject: IDemoInfoRequest = {
      applicationId: this.props.appId,
    };
    this.props._demoInfo(demoInfoRequestObject);
  };

  handleStart() {
    this.timer = setInterval(() => {
      const newCount = this.state.count - 1;
      this.setState({
        count: newCount >= 0 ? newCount : 0,
        isDisable: newCount >= 0 ? true : false,
      });
    }, 1000);
  }

  handleStop() {
    if (this.timer) {
      clearInterval(this.timer);
      this.setState({ running: false, isDisable: false });
    }
  }

  resendDemoRequest = () => {
    const askDemoLinkReqObject: IResendDemoLinkRequest = {
      applicationId: this.props.appId,
    };
    this.props._resendDemoLink(askDemoLinkReqObject);
    this.handleReset();
  };

  handleReset = () => {
    this.handleCountdown(parseInt(this.state.seconds, 10));
  };

  handleCountdown(seconds: number) {
    this.setState({
      count: seconds,
      running: true,
      isDisable: true,
    });
  }

  backButton = () => {
    this.props._resetAskDemo();
    this.props.history.push("/publish/qr");
  };

  _goToStepHelper = () => {
    this.props.history.push("/publish/step-helper");
  };

  render() {
    const { count, isDisable } = this.state;
    return (
      <React.Fragment>
        <Modal
          backdropClickedHandler={() =>
            this.setState({ isModalVisible: false })
          }
          show={this.state.isModalVisible}
          width="568"
        >
          <div className={classes.modal}>
            <div className={classes.modalHead}>
              <h4>Basic informations</h4>
              <button onClick={() => this.setState({ isModalVisible: false })}>
                <img src={cancelIcon} className={classes.cancelIcon} />
              </button>
            </div>
            <ul>
              <li>
                <p className={classes.ParagraphText}>
                  You may change your mobile app easily even after it is
                  published.
                </p>
              </li>
              <li>
                <p className={classes.ParagraphText}>
                  If you are an IOS user and get an error while opening the demo
                  app, please go to{" "}
                  <img src={settingsIcon} className={classes.settingsIcon} />{" "}
                  Settings → General → Device Management and click "Trust
                  Developer".
                </p>
              </li>
            </ul>
          </div>
        </Modal>
        <div className={classes.Container}>
          <div className={classes.head}>
            <div
              className={classes.backIconContainer}
              onClick={this.backButton}
            >
              <Icon
                className={classes.backIcon}
                type="arrowLeft"
                width="16"
                height="16"
                fillColor="#3196f6"
              />
              <span className={classes.backIconLabel}>Back</span>
            </div>

            <div className={classes.Heading}>
              <img src={rocketIcon} className={classes.rocketIcon} />
              <div className={classes.HeadingTextArea}>
                <h3 className={classes.HeadingText}>
                  You’ve successfully requested your demo app.
                </h3>
                <p className={classes.Paragraph}>
                  Your demo app will be delivered to your e-mail address within
                  an hour.
                  <img
                    src={infoIcon}
                    className={classes.infoIcon}
                    onClick={() => this.setState({ isModalVisible: true })}
                  />
                </p>
              </div>
            </div>

            <div className={classes.schedulerContainer}>
              <div className={classes.time}>
                <Icon
                  className={classes.timeIcon}
                  type="time"
                  width="16"
                  height="16"
                  fillColor="#3e5e6e"
                />
                <span className={classes.timeLabel}>
                  {schedulerTimeFormat(count)}
                </span>
              </div>

              <Button
                type="button"
                width="120px"
                height="40px"
                className="Primary"
                disableButton={isDisable}
                onClick={this.resendDemoRequest}
              >
                Resend
              </Button>
            </div>

            <p className={classes.ParagraphText}>
              <span
                onClick={this._goToStepHelper}
                className={classes.paragraphLink}
              >
                Click Here
              </span>{" "}
              to learn more about how to install your demo app step by step.
            </p>
            <p className={classes.ParagraphText}>
              If you have any questions, please contact us directly via live
              chat, or mail to
              <a
                href="mailto:support@mowico.com"
                className={classes.ParagraphTextBold}
              >
                support@mowico.com
              </a>
            </p>
          </div>
          <p className={classes.ParagraphText}>
            While you're waiting for your demo app, check out our
            <a
              href="https://mowico.com/category/insights/"
              target="_blank"
              className={classes.ParagraphTextBold}
            >
              Insights
            </a>
            blog for mobile commerce trends and tips!
          </p>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    isMailSend: state.askDemo.success,
    demoData: state.demoInfo.data,
    demoTime: state.demoInfo.data.ElapsedTime,
    demoInfoSuccess: state.demoInfo.success,
    IsStep13Completed: state.userInfo.selectedApp.IsStep13Completed,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _resendDemoLink: (resendRequestObj: IResendDemoLinkRequest) =>
      dispatch(resendDemoLink(resendRequestObj)),
    _resetAskDemo: () => dispatch(resetAskDemo()),
    _resetDemoInfo: () => dispatch(resetDemoInfo()),
    _demoInfo: (reqObject: IDemoInfoRequest) => dispatch(demoInfo(reqObject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallToStore);
