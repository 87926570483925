import React, { FC } from 'react';
import Icon from "panel/src/_components/common/Icon";
import styled from 'styled-components';

const Button = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3196f6;
  border-radius: 4px;
  border: 0;
  position: absolute;
  right: 16px;
  top: -20px;
  overflow: hidden;
  color: white;
`;

type EditButtonProps = {
  onClick?: () => void;
};

const EditButton: FC<EditButtonProps> = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <Icon type="editIcon" width="17" height="17" className="editIcon" />
    </Button>
  );
};

export default EditButton;
