import { Platform } from "react-native";
import styled from "styled-components/native";
import { colors } from "../../utils/constants";
import { dimenHeight, dimenWidth, dimenFont } from "../../utils/helper";

interface IDiscountCard {
  fontFamily?: any;
}

export const DiscountCard = styled.Text<IDiscountCard>`
  color: ${colors.white};
  font-size: ${dimenFont(12)}px;
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
`;

interface IDiscountContainer {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  fontFamily?: any;
}

export const DiscountContainer = styled.View<IDiscountContainer>`
  background-color: ${(props) => props.secondary};
  align-items: center;
  justify-content: center;
  padding-vertical: ${dimenHeight(8)}px;
  padding-horizontal: ${dimenWidth(4)}px;
  margin-right: ${dimenWidth(16)}px;
`;

interface IDiscountAmount {
  fontFamily?: any;
}

export const DiscountAmount = styled.Text<IDiscountAmount>`
  color: ${colors.white};
  font-size: ${dimenFont(12)}px;
  font-family: ${(props) =>
    Platform.OS === "web"
      ? `${props.fontFamily} Bold`
      : props.fontFamily!.Bold};
`;
