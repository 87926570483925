import { put, call, takeEvery } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import * as dataActions from "panel/src/redux/actions/generalSettings";
import * as userInfoActions from "panel/src/redux/actions/userInfo";
import * as pageActions from "panel/src/redux/actions/page";
import {
  GET_GENERAL_SETTINGS_START,
  SET_GENERAL_SETTINGS_START,
  GET_ALL_LANGUAGE_START,
  SET_INSERT_LANGUAGE_START,
} from "panel/src/redux/constants/generalSettings";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import { clearDesignError, setDesignError } from "../actions/DesignError";

function* getGeneralSettings({ requestObj }: any) {
  yield put(startAppLoading(GET_GENERAL_SETTINGS_START));
  try {
    const response = yield call(
      axios.post,
      "/api/Design/GetGeneralSettings",
      requestObj
    );
    yield put(clearDesignError());
    yield put(dataActions.getGeneralSettingsFinish(response.data.ResultData));
  } catch (error) {
    const err = error as any;
    yield put(setDesignError(`/api/Design/GetGeneralSettings - ${err?.response?.status}`));
    yield put(dataActions.getGeneralSettingsRejected(err.message));
  }
  yield put(endAppLoading(GET_GENERAL_SETTINGS_START));
}

function* setGeneralSettings({ generalObj }: any) {
  yield put(startAppLoading(SET_GENERAL_SETTINGS_START));
  try {
    yield call(axios.post, "/api/Design/SetGeneralSettings", { ...generalObj });
    yield put(clearDesignError());
    yield put(dataActions.setGeneralSettingsFinish());
    yield put(pageActions.pageUpdated(true));
  } catch (error) {
    const err = error as any;
    yield put(setDesignError(`/api/Design/SetGeneralSettings - ${err?.response?.status}`));
    yield put(dataActions.getGeneralSettingsRejected(err.message));
  }

  yield put(userInfoActions.getUserInfo());
  yield put(endAppLoading(SET_GENERAL_SETTINGS_START));
}

function* getAllLanguage() {
  yield put(startAppLoading(GET_ALL_LANGUAGE_START));
  try {
    const response = yield call(
        axios.get,
        "/api/Design/GetAllLanguage",
    );
    yield put(clearDesignError());
    yield put(dataActions.getAllLanguageFinish(response.data.ResultData));
  } catch (error) {
    const err = error as any;
    yield put(setDesignError(`/api/Design/GetAllLanguage - ${err?.response?.status}`));
    yield put(dataActions.getAllLanguageRejected(err.message));
  }
  yield put(endAppLoading(GET_ALL_LANGUAGE_START));
}

function* setInsertLanguage({ generalObj }: any) {
  yield put(startAppLoading(SET_INSERT_LANGUAGE_START));
  try {
    const response = yield call(
        axios.post,
        "/api/Design/InsertLanguage",
        { ...generalObj }
    );
    yield put(clearDesignError());
    yield put(dataActions.setInsertLanguageFinish(response.data.ResultMesage));

  } catch (error) {
    const err = error as any;
    yield put(setDesignError(`/api/Design/InsertLanguage - ${err?.response?.status}`));
    yield put(dataActions.setInsertLanguageRejected(err.message));
  }
  yield put(endAppLoading(SET_INSERT_LANGUAGE_START));
}

export function* getGeneralSettingsSaga() {
  yield takeEvery(GET_GENERAL_SETTINGS_START, getGeneralSettings);
}

export function* setGeneralSettingsSaga() {
  yield takeEvery(SET_GENERAL_SETTINGS_START, setGeneralSettings);
}

export function* getAllLanguageSaga() {
  yield takeEvery(GET_ALL_LANGUAGE_START, getAllLanguage);
}

export function* setInsertLanguageSaga() {
  yield takeEvery(SET_INSERT_LANGUAGE_START, setInsertLanguage);
}
