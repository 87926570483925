import React from "react";

interface IProps {
    width: string;
    height: string;
    fillColor: string;
    type: string;
}

const Share = ({ width, height, fillColor }: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 19">
        <path fill={fillColor} fillRule="evenodd" d="M10.918 7.275V8.36h2.945v8.555H4.137V8.36h2.945V7.275H3V18h12V7.275h-4.082zM8.506 3.711v9.339h1.16V3.711l1.6 1.594.82-.817-3-2.988-3 2.988.82.817 1.6-1.594z" />
    </svg>
);

export default Share;
