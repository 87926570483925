import React, { useState } from "react";
import classes from "./completion.module.scss";
import Button from "../../../_components/common/Button";
import WatchVideoIcon from "../../../_components/primitives/WatchVideoIcon";
import { History } from "history";
import WatchVideoModal from "../../../_components/primitives/WatchVideoModal";
import { YOUTUBE_LINKS } from "../../../Components/YoutubeTutorial";
import useIntercomChat from "../../../Components/Intercom/useIntercomChat";

interface ICompletionProps {
  history: History;
}

const Completion = (props: ICompletionProps) => {
  useIntercomChat({});
  const [isOpen, setOpen] = useState(false);

  const buttonClickHandler = () => {
    props.history.push("/design");
  };
  const watchVideoClickHandler = () => {
    setOpen(!isOpen);
  };

  return (
    <div className={classes.Completion}>
      <h2 className={classes.Heading}>Next step: Design</h2>
      <p className={classes.InfoText}>
        You have successfully integrated and configurated your app. You may go
        back and change the settings anytime.
      </p>
      <div className={classes.ButtonContainer}>
        <Button
          width="192px"
          className="Ghost"
          type="button"
          onClick={watchVideoClickHandler}
        >
          <WatchVideoIcon hasMarginRight /> Watch Tutorial
        </Button>
        <Button
          width="208px"
          type="button"
          className="Primary"
          onClick={buttonClickHandler}
        >
          Continue
        </Button>
      </div>

      <WatchVideoModal
        watchVideoClickHandler={watchVideoClickHandler}
        show={isOpen}
        videoUrl={YOUTUBE_LINKS.GENERAL_SETTINGS}
      />
    </div>
  );
};

export default Completion;
