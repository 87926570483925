import { Platform, Dimensions } from "react-native";
import styled from "styled-components/native";
import { dimenHeight } from "shared-components/src/utils/helper";
import FastImage from "react-native-fast-image-web-support";

const screenWidth = Math.round(Dimensions.get("window").width);

export default styled(FastImage)`
  height: ${Platform.OS === "web" ? "165" : dimenHeight(220)}px;
  width: ${Platform.OS === "web" ? "100%" : `${screenWidth}px`};
`;
