import styled from "styled-components";

interface IText {
  fontFamily?: string;
}

export default styled.span<IText>`
  color: #0f3649;
  font-family: ${(props) =>
    props.fontFamily ? `${props.fontFamily} Bold` : "Helvetica Neue Bold"};
  font-size: 12px;
  margin-left: 8px;
`;
