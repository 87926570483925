export const GET_SPLASH_SCREEN_START: string = "GET_SPLASH_SCREEN_START";
export const GET_SPLASH_SCREEN_FINISH: string =
  "GET_SPLASH_SCREEN_FINISH";
export const GET_SPLASH_SCREEN_REJECTED: string =
  "GET_SPLASH_SCREEN_REJECTED";

export const SET_SPLASH_SCREEN_START: string = "SET_SPLASH_SCREEN_START";
export const SET_SPLASH_SCREEN_FINISH: string =
  "SET_SPLASH_SCREEN_FINISH";
export const SET_SPLASH_SCREEN_REJECTED: string =
  "SET_SPLASH_SCREEN_REJECTED";
