import React, { ChangeEventHandler, useEffect, useRef, useState } from "react";
import classes from "./input.module.scss";
import ErrorField from "../ErrorField";
import Picker from "emoji-picker-react";
import Smile from "../../../assets/images/smile.svg";
interface IInputProps {
  invalid?: boolean;
  shouldValidate?: any;
  touched?: boolean;
  elementConfig?: any;
  elementType?: string;
  value?: string;
  label?: string;
  changeHandler?: ChangeEventHandler;
  errorMessage?: string;
  large?: boolean;
  className?: string;
  placeholder?: string;
  name?: string;
  type?: string;
  disabled?: boolean;
  field?: any;
  form?: any;
  containerMarginBottom?: string;
  labelMarginBottom?: string;
  fontSize?: string;
  error?: boolean;
  minHeight?: number;
  emoji?: boolean;
}

const pickerStyle = {
  marginBottom: "15px",
  width: "100%",
  position: "absolute",
  top: "105%",
  zIndex: "99",
};

const Input = (props: IInputProps) => {
  let inputElement = null;
  const nodeRef: any = useRef();
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRefCursorPoint = useRef<number>(0);

  const [emojiField, setEmojiField] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener("click", clickOutside);

    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);

  const clickOutside = (e: any) => {
    if (nodeRef.current && !nodeRef.current.contains(e.target)) {
      setEmojiField(false);
    }
  };

  const fieldName = props.field && props.field.name;
  const isError =
    props.form && props.form.touched
      ? props.form.touched[fieldName]
      : undefined;
  const errorMessage =
    props.form && props.form.errors ? props.form.errors[fieldName] : undefined;

  if (props.elementType === "input") {
    inputElement = (
      <input
        ref={inputRef}
        style={{ fontSize: props.fontSize }}
        disabled={props.disabled}
        className={`${classes.BlockInput} ${
          props.form &&
          props.form.errors &&
          props.form.errors[fieldName] &&
          props.form.touched[fieldName]
            ? classes.Invalid
            : props.error
            ? classes.Invalid
            : classes.Valid
        }`}
        {...props.elementConfig}
        type={props.type}
        {...props.field}
        placeholder={props.placeholder}
        onChange={props.changeHandler}
        onBlur={props.field ? props.field.onBlur : () => {}}
        value={props.value}
        spellCheck="false"
        autoComplete="off"
      />
    );
  } else if (props.elementType === "textarea") {
    inputElement = (
      <textarea
        ref={inputRef}
        style={{ fontSize: props.fontSize, minHeight: props?.minHeight }}
        disabled={props.disabled}
        className={`${classes.BlockInput} ${classes.Textarea} ${
          props.form &&
          props.form.errors &&
          props.form.errors[fieldName] &&
          props.form.touched[fieldName]
            ? classes.Invalid
            : props.error
            ? classes.Invalid
            : classes.Valid
        }`}
        {...props.elementConfig}
        type={props.type}
        {...props.field}
        placeholder={props.placeholder}
        onChange={props.changeHandler}
        onBlur={props.field ? props.field.onBlur : () => {}}
        spellCheck="false"
        autoComplete="off"
      >
        {props.value}
      </textarea>
    );
  } else if (props.elementType === "select") {
    inputElement = (
      <select
        onChange={props.changeHandler}
        className={classes.Select}
        value={props.value}
      >
        {props.elementConfig.options.map((option: any) => {
          return (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          );
        })}
      </select>
    );
  }

  const emojiToggle = () => {
    setEmojiField((prevState) => !prevState);
  };

  return (
    <div
      ref={nodeRef}
      style={{ marginBottom: props.containerMarginBottom }}
      className={`${classes.Container} ${
        props.form &&
        props.form.errors &&
        props.form.errors[fieldName] &&
        props.form.touched[fieldName]
          ? ""
          : classes.inValidContainer
      } `}
    >
      <label
        style={{
          fontSize: props.fontSize,
          marginBottom: props.labelMarginBottom,
        }}
        className={classes.BlockLabel}
      >
        {props.label}
      </label>
      {inputElement}
      {props.emoji && (
        <div
          className={classes.emojiToggle}
          onClick={emojiToggle}
          style={
            props?.form?.touched?.[fieldName] &&
            props?.form?.errors?.[fieldName]
              ? { bottom: "21px" }
              : {}
          }
        >
          <img src={Smile} width={12} height={12} alt="Smile" />
        </div>
      )}
      {props.emoji && emojiField && (
        <Picker
          disableSkinTonePicker={true}
          onEmojiClick={(_: any, emojiObject: any) => {
            const cursorPosition = inputRef.current?.selectionStart || 0;
            if (cursorPosition > 0) {
              inputRefCursorPoint.current = cursorPosition;
            }
            const before = inputRef.current?.value.substring(
              0,
              inputRefCursorPoint.current
            );
            const after = inputRef.current?.value.substring(
              inputRefCursorPoint.current,
              inputRef.current?.value.length
            );
            const value: string = before + emojiObject.emoji + after;
            // @ts-ignore
            inputRef.current.value = value;
            // @ts-ignore
            props?.changeHandler({
              target: {
                // @ts-ignore
                name: fieldName,
                value,
              },
            });
            inputRefCursorPoint.current =
              inputRefCursorPoint.current + emojiObject.emoji.length;
            inputRef.current?.focus();
            // @ts-ignore
            inputRef.current.selectionStart = inputRefCursorPoint.current;
            // @ts-ignore
            inputRef.current.selectionEnd = inputRefCursorPoint.current;
          }}
          pickerStyle={pickerStyle}
        />
      )}
      {isError && errorMessage && <ErrorField text={errorMessage} />}
      {props.error && <ErrorField text={props.errorMessage} />}
    </div>
  );
};

export default Input;
