import { put, call, takeEvery } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import * as dataActions from "panel/src/redux/actions/apps";
import * as userInfoActions from "panel/src/redux/actions/userInfo";
import * as manageActions from "panel/src/redux/actions/manageApp";
import * as pageActions from "panel/src/redux/actions/page";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import {
  CREATE_APP_START,
  DELETE_APP_START,
  RENAME_APP_START,
} from "panel/src/redux/constants/apps";
import { navigateAway } from "panel/src/utils/helper";
import { push } from "connected-react-router";

function* createApp(payload: {
  requestObj: { ApplicationId: string; Name: string };
  type: string;
}) {
  yield put(startAppLoading(CREATE_APP_START));
  try {
    const response = yield call(axios.post, "/api/Manage/CreateApp", {
      ...payload.requestObj,
    });
    yield put(dataActions.createAppFinish(response.data));
    yield put(
      userInfoActions.createAppSuccess({
        ...payload.requestObj,
        AppId: response.data.ResultData,
      })
    );
    yield put(manageActions.setAppId(response.data.ResultData));
    yield put(pageActions.pageUpdated(true));
  } catch (error) {
    yield put(dataActions.createAppRejected(error.message));
  }

  try {
    const token = localStorage.getItem("AUTH_TOKEN");
    const userInfoRequestObj = {
      UserToken: token,
    };

    const userInfoResponse = yield call(
      axios.post,
      "/api/login/GetUserInfo",
      userInfoRequestObj
    );

    const {
      name,
      surname,
      userId,
      AppList: apps,
      IsTrial: IsTrial,
      IsExpired,
      emailAddress,
      RemainingDays,
      UserPackage,
    } = userInfoResponse.data.ResultData;

    let selectedApp = apps.find((app: any) => app.IsSelected);
    if (!selectedApp) {
      selectedApp = apps?.[0];
      yield put(push(navigateAway(selectedApp)));
    }

    yield put(
      userInfoActions.fetchUserInfoSuccess({
        userName: `${name} ${surname ? surname : ""}`,
        userId,
        apps,
        IsTrial,
        IsExpired,
        emailAddress,
        RemainingDays,
        UserPackage
      })
    );
    yield put(push(navigateAway(selectedApp)));
  } catch (error) {
    yield put(userInfoActions.fetchUserInfoFail(error));
  }
  yield put(endAppLoading(CREATE_APP_START));
}

function* deleteApp(payload: {
  requestObj: { ApplicationId: string; isSelected: boolean };
  type: string;
}) {
  yield put(startAppLoading(DELETE_APP_START));
  try {
    const response = yield call(axios.post, "/api/Manage/DeleteApp", {
      ...payload.requestObj,
    });

    yield put(
      userInfoActions.deleteAppSuccess(payload.requestObj.ApplicationId)
    );

    yield put(dataActions.deleteAppFinish(response.data));
    yield put(pageActions.pageUpdated(true));
  } catch (error) {
    yield put(dataActions.deleteAppRejected(error.message));
  }

  try {
    const token = localStorage.getItem("AUTH_TOKEN");
    const userInfoRequestObj = {
      UserToken: token,
    };

    const userInfoResponse = yield call(
      axios.post,
      "/api/login/GetUserInfo",
      userInfoRequestObj
    );

    const {
      name,
      surname,
      userId,
      AppList: apps,
      IsTrial: IsTrial,
      IsExpired,
      emailAddress,
      RemainingDays,
      UserPackage
    } = userInfoResponse.data.ResultData;

    let selectedApp = apps.find((app: any) => app.IsSelected);

    if (!selectedApp && apps.length !== 0) {
      apps[0].IsSelected = true;
      selectedApp = apps[0];
      const selectRes = yield call(axios.post, "/api/Manage/SelectApp", {
        ApplicationId: apps[0].AppId,
      });
      yield put(userInfoActions.setSelectedApp(selectRes.data?.ResultData));
    } else {
      yield put(push("/welcome"));
    }

    yield put(
      userInfoActions.fetchUserInfoSuccess({
        userName: `${name} ${surname ? surname : ""}`,
        userId,
        apps,
        IsTrial,
        IsExpired,
        emailAddress,
        RemainingDays,
        UserPackage
      })
    );

    if (payload.requestObj.isSelected) {
      yield put(push(navigateAway(selectedApp)));
    }
  } catch (error) {
    yield put(userInfoActions.fetchUserInfoFail(error));
  }
  yield put(endAppLoading(DELETE_APP_START));
}

function* renameApp(payload: {
  requestObj: { ApplicationId: string; Name: string };
  type: string;
}) {
  yield put(startAppLoading(RENAME_APP_START));
  try {
    const response = yield call(axios.post, "/api/Manage/RenameApp", {
      ...payload.requestObj,
    });
    yield put(userInfoActions.renameAppSuccess({ ...payload.requestObj }));
    yield put(dataActions.renameAppFinish(response.data));
    yield put(pageActions.pageUpdated(true));
  } catch (error) {
    yield put(dataActions.deleteAppRejected(error.message));
  }

  yield put(userInfoActions.getUserInfo());
  yield put(endAppLoading(RENAME_APP_START));
}

export function* renameAppSaga() {
  yield takeEvery(RENAME_APP_START, renameApp);
}

export function* deleteAppSaga() {
  yield takeEvery(DELETE_APP_START, deleteApp);
}

export function* createAppSaga() {
  yield takeEvery(CREATE_APP_START, createApp);
}
