import styled from "styled-components/native";
import { colors } from "shared-components/src/utils/constants";
import { Platform } from "react-native";

export default styled.TouchableOpacity`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${colors.white};
  padding: 5px 0;
  ${Platform.OS === "web" && `flex-shrink: initial !important;`}
`;
