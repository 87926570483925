import {
    UPDATE_PAGE
} from "panel/src/redux/constants/page";


const defaultState = {
    isBlocked: false,
};

export default function (state = defaultState, action: any) {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_PAGE: {
            return {
                ...state,
                isBlocked: action.isBlocked,
            };
        }

        default:
            return state;
    }
}
