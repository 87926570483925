import IGetHomeDataRequest from "../../models/Design/home/IGetHomeDataRequest";
import {
  GET_BANNER_DATA,
  GET_BANNER_DATA_SUCCESS,
  GET_BANNER_DATA_FAIL,
  RESET_BANNER_DATA,
  HAS_CHANGE,
  DISCARD_CHANGES,
  RESET_HAS_CHANGE,
} from "panel/src/redux/constants/bannerSettings";

interface BaseAction {
  type: string;
  data?: Object;
  error?: string;
  requestObj?: IGetHomeDataRequest;
}

export const getBannerData = (requestObj: IGetHomeDataRequest): BaseAction => ({
  type: GET_BANNER_DATA,
  requestObj,
});

export const getBannerDataSuccess = (data: Object): BaseAction => ({
  type: GET_BANNER_DATA_SUCCESS,
  data,
});

export const getBannerDataFail = (error: string): BaseAction => ({
  type: GET_BANNER_DATA_FAIL,
  error,
});

export const resetBannerData = () => ({
  type: RESET_BANNER_DATA,
});

export const hasChange = () => ({
  type: HAS_CHANGE,
});

export const resetHasChange = () => ({
  type: RESET_HAS_CHANGE,
});

export const discardChanges = (): BaseAction => ({
  type: DISCARD_CHANGES,
});
