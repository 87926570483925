import React from "react";
import { IconType } from "../../../utils/enums/iconType";

interface IAndroidProps {
  iconType: string;
}

const AndroidIcon = (props: IAndroidProps) => {
  let width = "24";
  let height = "24";
  let fillColor = null;

  switch (props.iconType) {
    case IconType.Disabled:
      fillColor = "#cfd7db";
      break;
    case IconType.Active:
      fillColor = "#3E5E6E";
      break;
    case IconType.Enabled:
      fillColor = "#FFFFFF";
      break;
    default:
      fillColor = "#3E5E6E";
      break;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M18.036 7.923c.24 0 .438.174.479.403l.008.088v10.21a.49.49 0 0 1-.4.482l-.087.008h-2.182v3.44A1.44 1.44 0 0 1 14.421 24a1.438 1.438 0 0 1-1.427-1.306l-.007-.14v-3.44h-2.004v3.44A1.44 1.44 0 0 1 9.549 24a1.438 1.438 0 0 1-1.427-1.306l-.006-.14v-3.44H5.964a.488.488 0 0 1-.479-.402l-.008-.089V8.413c0-.24.172-.44.4-.482l.087-.008h12.072zM3.433 7.921c.745 0 1.358.573 1.427 1.306l.007.14v5.87a1.44 1.44 0 0 1-1.434 1.446 1.438 1.438 0 0 1-1.426-1.306L2 15.237v-5.87A1.44 1.44 0 0 1 3.433 7.92zm17.134 0c.745 0 1.357.573 1.426 1.306l.007.14v5.87a1.44 1.44 0 0 1-1.433 1.446 1.438 1.438 0 0 1-1.427-1.306l-.007-.14v-5.87a1.44 1.44 0 0 1 1.434-1.446zM16.104.032a.26.26 0 0 1 .125.287l-.025.065-1.013 1.843a6.6 6.6 0 0 1 3.195 4.388.491.491 0 0 1-.394.586l-.082.006H6.061a.49.49 0 0 1-.476-.592A6.602 6.602 0 0 1 8.541 2.37l.24-.142L7.766.384a.26.26 0 0 1 .1-.352.255.255 0 0 1 .307.046l.041.055L9.236 1.99a6.47 6.47 0 0 1 2.75-.61c.873 0 1.706.173 2.467.486l.282.124L15.756.133a.255.255 0 0 1 .348-.1zM14.97 3.905a.553.553 0 0 0-.55.556c0 .307.246.555.55.555a.553.553 0 0 0 .551-.555.553.553 0 0 0-.55-.556zm-5.969 0a.553.553 0 0 0-.55.556c0 .307.246.555.55.555a.553.553 0 0 0 .551-.555.553.553 0 0 0-.551-.556z"
      />
    </svg>
  );
};

export default AndroidIcon;
