import React from 'react';

interface IProps {
    width: string,
    height: string,
    fillColor: string,
    type: string
}

const ArrowUp = ({ width, height, fillColor }: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 7 5">
        <path fill={fillColor} fillRule="evenodd" d="M3.5.458c-.152-.003-.305.054-.422.17L.175 3.523c-.23.23-.234.607-.007.841.226.235.597.239.827.008L3.5 1.874l2.505 2.497c.23.23.601.227.827-.008.227-.234.223-.61-.007-.84L3.922.628C3.805.512 3.652.455 3.5.459z" />
    </svg>
);

export default ArrowUp;
