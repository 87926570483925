import { CHANGE_VISIBILITY } from "panel/src/redux/constants/liveChat";

const defaultState = {
  visibility: "minimized",
};

export default function (state = defaultState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_VISIBILITY: {
      return {
        ...state,
        visibility: payload.data,
      };
    }

    default:
      return state;
  }
}
