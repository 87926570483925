import React from 'react';

interface IProps {
    width: string,
    height: string,
    fillColor: string,
    type: string
}

const ArrowLeft = ({ width, height, fillColor }: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 24">
        <path fill={fillColor} fillRule="evenodd" d="M0 12a1.984 1.984 0 0 1 .584-1.448l9.92-9.95A2.063 2.063 0 0 1 13.39.573c.804.777.816 2.047.027 2.839L4.855 12l8.561 8.587a1.985 1.985 0 0 1-.027 2.839 2.063 2.063 0 0 1-2.884-.027L.584 13.448A1.984 1.984 0 0 1 0 12z" />
    </svg>

);

export default ArrowLeft;
