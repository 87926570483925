import Button from "panel/src/_components/common/Button";
import React, { FC, useState } from "react";
import classes from "./upgradeComponent.module.scss";
import { useSelector } from "react-redux";
import TargetIcon from "panel/src/assets/images/target.svg";
import UpgradeModal from "../../../Components/UpgradeModal";
import ProgressBar from "./ProgressBar";
import close from "../../../assets/images/close.svg";
import UpgradeTooltip from "../../../Components/UpgradeTooltip";

interface IUpgradeComponent {
  pageType?: string;
  description?: string;
  maxCount?: any;
  usedCount?: any;
}

const UpgradeComponent: FC<IUpgradeComponent> = ({
  description,
  maxCount,
  usedCount,
  pageType,
}) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [display, setDisplay] = useState<string>("flex");
  const { userInfo }: any = useSelector((state) => state);
  const onOpenModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setDisplay("none");
  };
  const renderComponent = () => {
    switch (pageType) {
      case "Push Notification":
        return (
          <div className={classes.item}>
            <div className={classes.topRow}>
              <img
                className={classes.icon}
                src={TargetIcon}
                alt="upgrade-now"
              />
            </div>
            <div className={classes.topContainer}>
              <p className={classes.leftText}>Components</p>
              <p className={classes.rightText}>
                <span className={classes.span}>daily/</span>
                <span>{usedCount}</span> of {maxCount} used
              </p>
            </div>
            <ProgressBar completed={(usedCount * 100) / maxCount} />
            <p className={classes.desc}>{description}</p>
            <Button
              onClick={onOpenModal}
              backgroundColor="#ff8360"
              borderColor="transparent"
              //color="Ghost"
              width="87px"
              height="32px"
              fontSize="13px"
            >
              <p className={classes.btnText}>Upgrade</p>
            </Button>
          </div>
        );
      case "Splash Screen":
        return (
          <>
            <div className={classes.topRow}>
              <img
                className={classes.icon}
                src={TargetIcon}
                alt="upgrade-now"
              />
            </div>
            <p className={classes.splashScreen}>
              Custom Splash Screen is only available in our
              <span className={classes.growthText}> Growth </span>plan.
            </p>
            <p className={classes.seePlanPrice}>
              <a
                style={{ color: "#1f81df" }}
                href="https://mowico.com/pricing/"
                target="_blank"
              >
                <span className={classes.priceText}>See our </span>plans &
                pricing page
              </a>
            </p>
            <Button
              onClick={onOpenModal}
              backgroundColor="#ff8360"
              borderColor="transparent"
              //color="Ghost"
              width="87px"
              height="32px"
              fontSize="13px"
            >
              <p className={classes.btnText}>Upgrade</p>
            </Button>
          </>
        );
      case "Category Layout":
        return (
          <>
            <div
              style={{
                flexDirection: "row",
                flex: 1,
                display: "flex",
                position: "relative",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div style={{ alignSelf: "center" }}>
                <img
                  className={classes.categoryIcon}
                  src={TargetIcon}
                  alt="upgrade-now"
                />
              </div>
              <div>
                <p className={classes.categoryLayout}>
                  Category editing is only available in our
                  <span className={classes.growthText}>
                    {" "}
                    <a
                      style={{ color: "#ff8360" }}
                      href="https://mowico.com/pricing/"
                      target="_blank"
                    >
                      Growth{" "}
                    </a>
                  </span>
                  plan.
                </p>
                <p className={classes.seeCategoryLayoutPlanPrice}>
                  <a
                    style={{ color: "#1f81df" }}
                    href="https://mowico.com/pricing/"
                    target="_blank"
                  >
                    <span className={classes.priceText}>See our </span>plans &
                    pricing page{" "}
                    <span className={classes.priceText}>
                      to find out what suits you the best.
                    </span>
                  </a>
                </p>
              </div>

              <div style={{ alignSelf: "center" }}>
                <Button
                  onClick={onOpenModal}
                  backgroundColor="#ff8360"
                  borderColor="transparent"
                  width="87px"
                  height="32px"
                  fontSize="13px"
                >
                  <p className={classes.btnText}>Upgrade</p>
                </Button>
              </div>
              <div className={classes.Close} onClick={closeModal}>
                <img src={close} />
              </div>
            </div>
          </>
        );
      case "Category":
        return (
          <>
            <div className={classes.topRow}>
              <img
                className={classes.icon}
                src={TargetIcon}
                alt="upgrade-now"
              />
            </div>
            <div className={classes.topContainer}>
              <p className={classes.leftText}>Components</p>

              <div className={classes.flex}>
                <UpgradeTooltip
                  info={true}
                  customDescription="The product image is a required component on the Product Detail Page. It comes as selected. Therefore, the number starts as 1/7."
                  label=""
                />
                <p className={classes.rightText}>
                  <span>{usedCount}</span> of {maxCount} used
                </p>
              </div>
            </div>
            <ProgressBar completed={(usedCount * 100) / maxCount} />
            <p className={classes.desc}>{description}</p>
            <Button
              onClick={onOpenModal}
              backgroundColor="#ff8360"
              borderColor="transparent"
              width="87px"
              height="32px"
              fontSize="13px"
            >
              <p className={classes.btnText}>Upgrade</p>
            </Button>
          </>
        );
    }
  };

  return (
    <>
      {userInfo?.UserPackage?.IsUpgradePossible && (
        <div
          style={{
            width: "auto",
            height: "auto",
            display: display,
          }}
          id={classes.upgradeComponent}
        >
          <UpgradeModal
            setIsOpenModal={setIsOpenModal}
            isOpenModal={isOpenModal}
          />
          <div className={classes.upgradeComponent}>{renderComponent()}</div>
        </div>
      )}
    </>
  );
};

export default UpgradeComponent;
