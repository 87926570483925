import * as Yup from "yup";

enum RayonTypes {
  Category = 1,
  ProductGroup,
}

export default Yup.object().shape({
  rayonType: Yup.number().required("Rayon type is required."),
  CategoryId: Yup.string().when("rayonType", {
    is: RayonTypes.Category,
    then: Yup.string().required("Category can't be blank."),
  }),
  GroupId: Yup.string().when("rayonType", {
    is: RayonTypes.ProductGroup,
    then: Yup.string().required("Product Group can't be blank."),
  }),
});
