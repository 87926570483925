import { MenuItem } from "react-contextmenu";
import styled from "styled-components";

export default styled(MenuItem)`
  font-size: 10px;
  background-color: #fff;
  padding: 3px 4px;
  border-radius: 2px;
  margin-right: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 15px 0 rgba(15, 54, 73, 0.1);
`;
