export const GET_PRODUCT_LIST_DATA: string = "GET_PRODUCT_LIST_DATA";
export const GET_PRODUCT_LIST_DATA_SUCCESS: string =
  "GET_PRODUCT_LIST_DATA_SUCCESS";
export const GET_PRODUCT_LIST_DATA_FAIL: string = "GET_PRODUCT_LIST_DATA_FAIL";
export const RESET_PRODUCT_LIST_DATA: string = "RESET_PRODUCT_LIST_DATA";

export const GET_PRODUCT_LIST_BY_CATEGORY_ID: string =
  "GET_PRODUCT_LIST_BY_CATEGORY_ID";
export const GET_PRODUCT_LIST_BY_CATEGORY_ID_SUCCESS: string =
  "GET_PRODUCT_LIST_BY_CATEGORY_ID_SUCCESS";
export const GET_PRODUCT_LIST_BY_CATEGORY_ID_FAIL: string =
  "GET_PRODUCT_LIST_BY_CATEGORY_ID_FAIL";
export const RESET_PRODUCT_LIST_BY_CATEGORY_ID_PRODUCTS: string =
  "RESET_PRODUCT_LIST_BY_CATEGORY_ID_PRODUCTS";
