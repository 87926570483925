import styled from "styled-components";

interface IIconWrapper {
  isActiveOS: boolean;
}

export default styled.li<IIconWrapper>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 72px;
  height: 72px;
  border-radius: 4px;
  margin-bottom: 7px;
  border: 1px solid ${(props) => (props.isActiveOS ? "#3e5e6e" : "#e7eaec")};
  cursor: pointer;
`;
