import React from "react";
import Button from "./Button";

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: "blue" | "white" | "whiteWithBlueBorder" | undefined;
  buttonText: string;
  width?: string;
  height?: string;
}

const ButtonComponent = ({
  width,
  height,
  theme,
  buttonText,
  ...props
}: IButton) => {
  return (
    <Button width={width} theme={theme} {...props}>
      {buttonText}
    </Button>
  );
};

export default ButtonComponent;
