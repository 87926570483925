import React, { useEffect, useRef, useState } from "react";
import FormikError from "../FormikError";
import {
  BadgeItem,
  BadgeItemButton,
  BadgeText,
  DropdownContainer,
  DropdownIcon,
  DropdownInput,
  DropdownItem,
  DropdownMenu,
  ErrorMessage,
  Label,
} from "./Dropdown.styles";
import Icon from "../../_components/common/Icon";
interface IDropdown {
  id: string;
  style: object;
  label: string;
  placeholder: string;
  options: { label: string; value: number }[];
  value?: number | string;
  onChangeHandler: Function;
  handleError?: any;
  handleTouched?: any;
  multiple?: boolean;
}

const DropdownMultiple = ({
  id,
  style,
  label,
  placeholder,
  options,
  value,
  onChangeHandler,
  handleError,
  handleTouched,
  multiple = false,
}: IDropdown) => {
  const selectNode: any = useRef();

  useEffect(() => {
    document.addEventListener("click", clickOutside);

    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);

  const clickOutside = (e: any) => {
    if (selectNode.current && !selectNode.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const errorMessage = FormikError(handleError, handleTouched, id);
  const [isOpen, setIsOpen] = useState(false);

  const selectedValues = value?.toString().split(",") || [];

  const toggling = () => setIsOpen(!isOpen);

  const onItemClick = (valueTemp: number) => {
    if (multiple) {
      let valueList = [...selectedValues];
      let newValue = valueTemp.toString();

      const isExits = valueList.includes(newValue);
      if (isExits) valueList = valueList.filter((s) => s !== newValue);
      else valueList.push(newValue);

      onChangeHandler({ target: { name: id, value: valueList } });

      if (isExits) setIsOpen(false);
    } else {
      onChangeHandler({ target: { name: id, value: valueTemp } });
      setIsOpen(false);
    }
  };

  const selectedOptionsFilter: {
    label: string;
    value: number;
  }[] =
    selectedValues
      .map((selectedValue) => {
        const option = options.find(
          (s) => s.value.toString() === selectedValue
        );
        return option!;
      })
      .filter(Boolean) || [];

  const renderSelectedOption = () => {
    if (value === "" || value === null || value === undefined)
      return placeholder;

    return options.find((obj) => obj.value === value)!.label;
  };

  const multipleRender =
    value === "" || value === null || value === undefined
      ? placeholder
      : selectedOptionsFilter.map((s, key) => (
          <BadgeItem key={`${s.value}_${key}`}>
            <BadgeText>{s.label.toUpperCase()}</BadgeText>
            <BadgeItemButton
              onClick={() => {
                onItemClick(s.value);
              }}
            >
              <Icon type="closeIcon" fillColor="#fff" width="10" height="10" />
            </BadgeItemButton>
          </BadgeItem>
        ));

  return (
    <DropdownContainer style={style} ref={selectNode}>
      <Label htmlFor={id}>{label}</Label>
      <DropdownInput
        id={id}
        onClick={() => toggling()}
        isSelected={value !== ""}
        hasError={errorMessage ? true : false}
        multiple={multiple}
      >
        {multiple ? multipleRender : renderSelectedOption()}
        {!multiple && (
          <DropdownIcon
            isOpen={isOpen}
            type="arrowDown"
            fillColor="#3e5e6e"
            width="16"
            height="16"
          />
        )}
      </DropdownInput>
      {isOpen && (
        <DropdownMenu>
          {options
            .filter((s) => !selectedValues.includes(s.value.toString()))
            .map((item, index) => (
              <DropdownItem
                key={index.toString()}
                onClick={() => onItemClick(item.value)}
              >
                {item.label}
              </DropdownItem>
            ))}
        </DropdownMenu>
      )}
      {handleError && handleTouched && (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      )}
    </DropdownContainer>
  );
};

export default DropdownMultiple;
