import { v4 as uuidv4 } from "uuid";
import { TNode } from ".";

export const convertCategoriesForRequest = (
  categories: TNode[],
  parent: string | number = 0
) => {
  let tempCategories: any[] = [];
  const parentCategories = categories.filter((s) => s.parent === parent);
  parentCategories.forEach((category) => {
    const children = categories.filter((s) => s.parent === category.id);
    if (children && children.length > 0) {
      tempCategories.push({
        id: category.data?.categoryId,
        name: category.text,
        menuItemId: category.data?.menuItemId,
        translatableContents: category.data?.translatableContents,
        children: convertCategoriesForRequest(categories, category.id),
      });
    } else {
      tempCategories.push({
        id: category.data?.categoryId,
        name: category.text,
        menuItemId: category.data?.menuItemId,
        translatableContents: category.data?.translatableContents,
        children: [],
      });
    }
  });
  return tempCategories;
};

export const convertCategoriesForTreeView = (
  categories: any[],
  parentIndex: string | number = 0
) => {
  let tempCategories: any[] = [];

  for (let index = 0; index < categories.length; index++) {
    const element = categories[index];
    const tempLastIndex = uuidv4();
    const existChildren =
      element.Children && element.Children.length > 0 ? true : false;

    const tempElement = {
      id: tempLastIndex,
      parent: parentIndex,
      droppable: true,
      text: element.Name,
      data: {
        categoryId: element.Id,
        hasChild: existChildren,
        translatableContents: element.translatableContents,
        menuItemId:
          element.Id === null ? element?.MenuItemId ?? tempLastIndex : null,
      },
    };
    tempCategories.push(tempElement);

    if (existChildren) {
      tempCategories = [
        ...tempCategories,
        ...convertCategoriesForTreeView(element.Children, tempLastIndex),
      ];
    }
  }

  return tempCategories;
};

export const convertCategoriesForSource = (
  categories: any[],
  targetCategories: any[],
  parent : any = "mowico_source_category"
) => {
  return categories.map((category) => {
    let id = uuidv4();
    if (targetCategories.length) {
      const item = targetCategories.find(
        (s) => s.data.categoryId === category.value
      );
      if (item) id = item.id;
    }
    return {
      id,
      parent,
      droppable: true,
      text: category.label,
      data: {
        categoryId: category.value,
        translatableContents: category?.translatableContents,
        hasChild: false,
        menuItemId: id,
        isChecked: false,
      },
    };
  });
};

export const isCategoryParent = (
  categories: any[],
  categoryId: string
): boolean => {
  return categories.some((s) => s.parent === categoryId);
};

/**
 *
 * @param categories
 * @returns
 */
export const treeViewHandleByParent = (categories: any[]): any[] => {
  for (let i = 0; i < categories.length; i++) {
    const element = categories[i];
    categories[i].data.hasChild = isCategoryParent(categories, element.id);
  }
  return categories;
};

/**
 *
 * @param arr
 * @param node
 * @returns
 */
export const removeMenuItem = (arr: TNode[], node: TNode) => {
  const categories = [...arr];
  const children = categories.filter((s) => s.parent === node.id);
  if (children && children.length > 0) {
    children.forEach((child) => {
      const childIndex = categories.findIndex((s) => s.id === child.id);
      if (childIndex > -1) categories[childIndex].parent = node.parent;
    });
  }
  const list = categories.filter((s) => s.id !== node.id);
  return treeViewHandleByParent(list);
};

/**
 *
 * @param arr
 * @param menuItemName
 * @returns
 */
export const addMenuItem = (arr: TNode[], menuItemName: string): TNode[] => {
  const list = Array.from(arr);
  const id = uuidv4();
  list.push({
    id,
    text: menuItemName,
    droppable: true,
    parent: 0,
    data: {
      categoryId: null,
      menuItemId: id,
      hasChild: false,
    },
  });
  return list;
};

/**
 *
 * @param arr
 * @param node
 * @param newName
 * @returns
 */
export const editMenuItem = (arr: TNode[], node: TNode, newName: any) => {
  const categoryArray = [...arr];

  const categoryIndex = categoryArray.findIndex((s) => s.id === node.id);
  categoryArray[categoryIndex].text = newName;

  return categoryArray;
};

const removeChild = (categories: any[], id: any) => {
  let tempCategories = [...categories];
  const items = tempCategories.filter((s) => s.parent === id);
  if (items.length) {
    items.forEach((item) => {
      tempCategories = removeChild(tempCategories, item.id);
    });
  }
  tempCategories = tempCategories.filter(
    (item) => item.parent !== id && item.id !== id
  );
  return tempCategories;
};

export const removeFromTargetCategory = (
  categories: any[],
  id: number | string
) => {
  return removeChild(categories, id) ?? [];
};

export const getChildIdListByParentId = (categories: any[], parentId: any) => {
  let tempChildList = [];
  let childList = categories
    .filter((s) => s.parent === parentId)
    .map((s) => s.id);
  tempChildList = [...childList];

  while (childList.length > 0) {
    childList = categories
      .filter((s) => childList.includes(s.parent))
      .map((s) => s.id);
    tempChildList = childList.concat(tempChildList);
  }

  return tempChildList;
};
