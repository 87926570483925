import React, { useEffect } from "react";
import { Form, Field, Formik } from "formik";
import Button from "panel/src/Components/Button";
import ImageResizeType, {
  TImageResizeType,
  TResizeTypeValue,
} from "panel/src/Components/ImageResizeType";

interface IProductSlider {
  hideComponentEdit?: Function;
  editFormClickedOutside?: number;
  setComponentFormData?: Function;
  setImageResizeType?: Function;
  Id?: string;
  formData?: IProductSliderFormData;
  rawFormData?: any;
}

type IProductSliderFormData = {
  imageResizeType: TResizeTypeValue;
};

const ProductSlider = ({
  hideComponentEdit,
  editFormClickedOutside,
  setComponentFormData,
  setImageResizeType,
  Id,
  formData,
  rawFormData,
}: IProductSlider) => {
  useEffect(() => {
    if (editFormClickedOutside !== 0) {
      hideComponentEdit && hideComponentEdit(Id);
    }
  }, [editFormClickedOutside]);

  const onPressCancel = () => {
    setImageResizeType && setImageResizeType(Id, rawFormData);
    hideComponentEdit && hideComponentEdit(Id);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formData!}
      onSubmit={(values) => {
        setComponentFormData && setComponentFormData(Id, values);
      }}
    >
      {({ values, setFieldValue, errors, touched }) => {
        return (
          <Form>
            <Field
              id="imageResizeType"
              name="imageResizeType"
              style={{ marginTop: 16 }}
              resizeTypeValue={values.imageResizeType}
              typeOnClickHandler={(item: TImageResizeType) => {
                setFieldValue(`imageResizeType`, item.mode);
                const editedFormData = {
                  ...rawFormData,
                  imageResizeType: item.mode,
                };
                setImageResizeType && setImageResizeType(Id, editedFormData);
              }}
              component={ImageResizeType}
            />
            <Button
              id="apply-button"
              type="submit"
              style={{ marginTop: "1.5rem" }}
              buttonText="Apply"
            />
            <Button
              id="cancel-button"
              type="button"
              style={{ marginTop: "0.5rem" }}
              theme="white"
              buttonText="Cancel"
              onClick={onPressCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProductSlider;
