import axios from "panel/src/services/api/api";
import * as getPackageFeatureActions from "panel/src/redux/actions/getPackageFeature";
import {
  GET_PACKAGE_FEATURE_LIST_START,
  SET_PACKAGE_FEATURE_LIST_START,
} from "panel/src/redux/constants/getPackageFeature";
import { takeLatest, put, call } from "redux-saga/effects";
import * as userInfoActions from "panel/src/redux/actions/userInfo";

function* getPackageFeature(data: any) {
  //yield put(startAppLoading(SET_PACKAGE_FEATURE_LIST_START));
  try {
    const response = yield call(axios.post, "/ConfigService/api/Home/GetPackageFeatureList", {
      ...data.data,
    });
    yield put(
        getPackageFeatureActions.getPackageFeatureListSuccess(
        response.data.ResultData
      )
    );
  } catch (error) {
    yield put(getPackageFeatureActions.getPackageFeatureListRejected(error));
  }
  //yield put(endAppLoading(GET_PACKAGE_FEATURE_LIST_START));
}

function* setPackageFeature({ data }: any) {
  //yield put(startAppLoading(SET_PACKAGE_FEATURE_LIST_START));
  try {
    yield call(axios.post, "/ConfigService/api/Home/GetPackageFeatureList", { ...data });
    yield put(getPackageFeatureActions.setPackageFeatureListSuccess());
   // yield put(pageActions.pageUpdated(true));
  } catch (error) {
    yield put(getPackageFeatureActions.setPackageFeatureListRejected(error));
  }

  yield put(userInfoActions.getUserInfo());
  //yield put(endAppLoading(SET_PACKAGE_FEATURE_LIST_START));
}

export function* setPackageFeatureProcessSaga() {
  yield takeLatest(SET_PACKAGE_FEATURE_LIST_START, setPackageFeature);
}

export function* getPackageFeatureProcessSaga() {
  yield takeLatest(GET_PACKAGE_FEATURE_LIST_START, getPackageFeature);
}
