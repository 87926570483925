import React from "react";

interface IProps {
    children: Function;
    onDrag: Function;
}

const Draggable = ({ children, onDrag }: IProps) => {
    const [dragging, setDragging] = React.useState(false);

    const handleMouseDown = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        setDragging(true);
        handleDrag(event);
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLElement>) => {
        if (!dragging) {
            return;
        }

        handleDrag(event);
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const handleDrag = (event: React.MouseEvent<HTMLElement>) => {
        onDrag({
            x: event.clientX,
            y: event.clientY
        });
    };

    return (
        <div
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
        >
            {children({ dragging })}
        </div>
    );
}

export default Draggable;