import { put, call, takeEvery } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import { LOGO_ALIGNS } from "panel/src/_components/common/ChooseLogo";
import * as logoPositionActions from "panel/src/redux/actions/logoPosition"
import { GET_LOGO_POSITION_START, UPDATE_LOGO_POSITION_START } from "panel/src/redux/constants/logoPosition";

function* getLogoPosition(requestObj: any) {
  try {
    const { data } = yield call(
      axios.post,
      "/api/Design/GetLogoAlign",
      requestObj.values
    );
    const { tabBarIconAlign } = JSON.parse(data?.ResultData);
    const value = LOGO_ALIGNS.find(item => item.value === tabBarIconAlign)?.value;
    
    yield put(logoPositionActions.getLogoPositionSuccess(value));
  } catch (error) {
    yield put(logoPositionActions.getLogoPositionFailed()); 
  }
}

function* updateLogoPosition(requestObj: any) {
  console.log('update', requestObj)
  try {
    const { data } = yield call(
      axios.post,
      "/api/Design/SetLogoAlign",
      requestObj.values
    );
    const { tabBarIconAlign } = JSON.parse(data?.ResultData);
    const value = LOGO_ALIGNS.find(item => item.value === tabBarIconAlign)?.value;
    
    yield put(logoPositionActions.updateLogoPositionSuccess());
  } catch (error) {
    yield put(logoPositionActions.updateLogoPositionFailed()); 
  }
}

export function* getLogoPositionSaga() {
  yield takeEvery(GET_LOGO_POSITION_START, getLogoPosition);
}

export function* updateLogoPositionSaga() {
  yield takeEvery(UPDATE_LOGO_POSITION_START, updateLogoPosition);
}
