import React, { useEffect, useRef } from "react";
import { Form, Field, Formik, FormikProps } from "formik";
import { connect } from "react-redux";
import Button from "panel/src/Components/Button";
import RichTextEditor from "../../../../../_components/common/RichTextEditor";
import InputTextarea from "../../../../../_components/common/Input";
import Input from "panel/src/Components/Input";
import validationSchema from "./validationSchema";
import Dropdown from "../../../../../Components/Dropdown";
import { ComponentProductModule } from "../../../../../models/Design/home/ComponentProduct";
import { ELinkTypes } from "../../../../../utils/enums/design";
import InfoPopup from "../../../../../Components/InfoPopup";
import {
  getProductListByCategoryId,
  resetProductListByCategoryIdProducts,
} from "../../../../../redux/actions/productList";

interface IHtmlEditFormProps {
  hideComponentEdit?: Function;
  editFormClickedOutside?: number;
  setComponentFormData?: Function;
  Id?: string;
  formData?: IHtmlEditFormFormData;
  rawFormData?: any;
  fontFamily?: any;
  categories: any[];
  productList: ComponentProductModule.IProduct[];
  productListLoading: boolean;
  _resetProductListByCategoryIdProducts: Function;
  _getProductListByCategoryId: (
    data: ComponentProductModule.TGetProductListByCategoryIdData
  ) => void;
}

type IHtmlEditFormFormData = {
  HtmlContent: string;
  HtmlType: string;
  Link: {
    Type: number;
    LinkTo: string;
    CategoryId: string;
    CategoryName: string;
    ProductId: string;
    ProductName: string;
    Search: string;
  };
};

const getBlobURL = (code: string, type: string) => {
  const blob = new Blob([code], { type });
  return URL.createObjectURL(blob);
};

const linkTypes = [
  { label: "No Action", value: ELinkTypes.None },
  { label: "Category", value: ELinkTypes.Category },
  { label: "Product", value: ELinkTypes.Product },
  { label: "Href", value: ELinkTypes.Href },
  { label: "Web View URL", value: ELinkTypes.WebViewURL },
  { label: "Search", value: ELinkTypes.Search },
];

let timeout: ReturnType<typeof setTimeout> = setTimeout(() => "", 1000);

const HtmlEditForm = ({
  hideComponentEdit,
  editFormClickedOutside,
  setComponentFormData,
  Id,
  formData,
  rawFormData,
  fontFamily,
  categories,
  productList,
  productListLoading,
  _resetProductListByCategoryIdProducts,
  _getProductListByCategoryId,
}: IHtmlEditFormProps) => {
  const formikRef = useRef<FormikProps<any>>(null);

  useEffect(() => {
    if (editFormClickedOutside !== 0) {
      hideComponentEdit && hideComponentEdit(Id);
    }
  }, [editFormClickedOutside]);

  const getInitialValue = () => {
    const _formData = {
      Link: {
        Type: formData?.Link?.Type,
        LinkTo: formData?.Link?.LinkTo,
        CategoryId: formData?.Link?.CategoryId,
        CategoryName: formData?.Link?.CategoryName,
        ProductId: formData?.Link?.ProductId,
        ProductName: formData?.Link?.ProductName,
        Search: formData?.Link?.Search,
      },
      HtmlContent: formData?.HtmlContent,
      HtmlType: formData?.HtmlType,
    };

    return _formData;
  };

  const getProductList = (search: string) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      _getProductListByCategoryId({
        CategoryId: formikRef.current?.values.Link.CategoryId,
        SearchWord: search,
      });
    }, 500);
  };

  const categoriesHandled = categories.sort((a, b) =>
    a.label > b.label ? 1 : b.label > a.label ? -1 : 0
  );

  const productListHandled = productList.map((product) => ({
    label: product.ProductName,
    value: product.Id,
  }));

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValue()}
      onSubmit={(values) => {
        const formValues = { ...values };
        if (!values.HtmlContent) formValues.HtmlContent = "";
        setComponentFormData &&
          setComponentFormData(Id, formValues, formValues);
      }}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, handleChange, errors, touched }) => {
        return (
          <Form>
            <Field
              id="HtmlType"
              name="HtmlType"
              style={{ marginBottom: "1rem" }}
              label="Html Type"
              placeholder="Select Link Type"
              options={[
                { label: "Text Editor", value: "1" },
                { label: "Custom Html", value: "2" },
              ]}
              value={values.HtmlType}
              onChangeHandler={handleChange}
              handleError={errors}
              handleTouched={touched}
              component={Dropdown}
            />
            {values.HtmlType === "1" && (
              <Field
                id="HtmlContent"
                name="HtmlContent"
                style={{ marginBottom: "1rem" }}
                label="HTML"
                placeholder="html"
                value={values.HtmlContent}
                fontFamily={fontFamily}
                onChangeHandler={(htmlContent: string) => {
                  setFieldValue("HtmlContent", htmlContent);
                }}
                component={RichTextEditor}
              />
            )}
            {values.HtmlType === "2" && (
              <>
                <Field
                  id="HtmlContent"
                  name="HtmlContent"
                  style={{ marginBottom: "1rem" }}
                  label="HTML"
                  elementType={"textarea"}
                  placeholder="html"
                  value={values.HtmlContent}
                  fontFamily={fontFamily}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue("HtmlContent", e.target.value);
                  }}
                  minHeight={180}
                  component={InputTextarea}
                />
                {values.HtmlContent && (
                  <iframe
                    src={getBlobURL(values.HtmlContent, "text/html")}
                    style={{ width: "100%", borderWidth: 0 }}
                  />
                )}
              </>
            )}
            <InfoPopup
              field={
                <Field
                  id="Link.Type"
                  name="Link.Type"
                  style={{ marginBottom: "1rem" }}
                  label="Link Type"
                  placeholder="Select Link Type"
                  options={linkTypes}
                  value={values.Link.Type}
                  onChangeHandler={handleChange}
                  handleError={errors}
                  handleTouched={touched}
                  component={Dropdown}
                />
              }
              descriptions={[
                {
                  key: "Category",
                  value: `Select a specific category to redirect the related banner in your app.`,
                },
                {
                  key: "Product",
                  value: `Select a category & product name to redirect the related banner in your app.`,
                },
                {
                  key: "Web View",
                  value: `Type a URL to redirect the related banner in a WebView.`,
                },
                {
                  key: "Search",
                  value: `Type a text to redirect the related banner to search result page in your app.`,
                },
              ]}
              style={{ marginTop: 20 }}
            />
            {(values.Link.Type === ELinkTypes.Category ||
              values.Link.Type === ELinkTypes.Product) && (
              <Field
                id="Link.CategoryId"
                name="Link.CategoryId"
                label="Link to"
                placeholder="Select a category"
                options={categoriesHandled}
                value={values.Link.CategoryId}
                onChangeHandler={(e: any) => {
                  handleChange(e);
                  _resetProductListByCategoryIdProducts();
                  setFieldValue("Link.CategoryName", e.target.label);
                  setFieldValue("Link.ProductId", "");
                  setFieldValue("Link.ProductName", "");
                }}
                handleError={errors}
                handleTouched={touched}
                component={Dropdown}
              />
            )}

            {values.Link.Type === ELinkTypes.Product && (
              <Field
                id="Link.ProductName"
                name="Link.ProductName"
                placeholder="Type product name"
                value={values.Link.ProductName}
                onChangeHandler={(e: any) => {
                  setFieldValue("Link.ProductName", e.target.label);
                  setFieldValue("Link.ProductId", e.target.value);
                }}
                handleError={errors}
                handleTouched={touched}
                component={Dropdown}
                options={productListHandled}
                disabled={!values.Link.CategoryId}
                loading={productListLoading}
                searchable={true}
                onSearchValueChange={(e: any) => {
                  getProductList(e.target.value);
                  setFieldValue("Link.ProductName", "");
                  setFieldValue("Link.ProductId", "");
                }}
              />
            )}

            {values.Link.Type === ELinkTypes.Search && (
              <Field
                id={"Link.Search"}
                name={"Link.Search"}
                label="Link to"
                placeholder="Type a word"
                value={values.Link.Search}
                onChangeHandler={handleChange}
                handleError={errors}
                handleTouched={touched}
                component={Input}
              />
            )}

            {(values.Link.Type === ELinkTypes.Href ||
              values.Link.Type === ELinkTypes.WebViewURL) && (
              <Field
                id="Link.LinkTo"
                name="Link.LinkTo"
                label="Link to"
                placeholder="Type or paste a URL"
                value={values.Link.LinkTo}
                onChangeHandler={handleChange}
                handleError={errors}
                handleTouched={touched}
                component={Input}
              />
            )}
            <Button
              id="apply-button"
              type="submit"
              style={{ marginTop: "1.5rem" }}
              buttonText="Apply"
            />
            <Button
              id="cancel-button"
              type="button"
              style={{ marginTop: "0.5rem" }}
              theme="white"
              buttonText="Cancel"
              onClick={() => hideComponentEdit && hideComponentEdit(Id)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  return {
    productList: state.productList.productList,
    categories: state.category.categories,
    productListLoading: state.productList.productListLoading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _getProductListByCategoryId: (
      data: ComponentProductModule.TGetProductListByCategoryIdData
    ) => dispatch(getProductListByCategoryId(data)),
    _resetProductListByCategoryIdProducts: () =>
      dispatch(resetProductListByCategoryIdProducts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HtmlEditForm);
