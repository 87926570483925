import React from "react";
import { TouchableOpacity } from "react-native";
import { colors } from "shared-components/src/utils/constants";
import HeaderContainer from "./HeaderContainer";
import Header from "./Header";
import Card from "./Card";
import Content from "./Content";
import Image from "./Image";

interface IBrandList {
  title?: string;
  colorList?: any;
  brands: {
    Id: string;
    Name: string;
    LogoImgUrl: string;
  }[];
  navigate?: (route: string, params: Record<string, any>) => void;
  fontFamily?: any;
}

const BrandList = ({
  title,
  brands,
  colorList,
  fontFamily,
  navigate,
}: IBrandList) => {
  return (
    <Card>
      <HeaderContainer>
        <Header
          fontFamily={fontFamily ? fontFamily : "Helvetica Neue"}
          primary={colorList ? colorList[0].colorCode : colors.darkSlateBlue}
        >
          {title ? title : "Brands"}
        </Header>
      </HeaderContainer>
      <Content>
        {brands.map((item, index) => (
          <TouchableOpacity
            key={index.toString()}
            onPress={() =>
              item?.Id && navigate && navigate('ProductList', {
                title: item.Name || title,
                brandId: item.Id
              })
            }
          >
            <Image source={{ uri: item.LogoImgUrl }} />
          </TouchableOpacity>
        ))}
      </Content>
    </Card>
  );
};

export default BrandList;
