import React, { useEffect } from "react";
import validationSchema from "./validationSchema";
import { Form, Field, Formik } from "formik";
import Input from "panel/src/Components/Input";
import { DropdownMultiple } from "panel/src/Components/Dropdown";
import Button from "panel/src/Components/Button";
import { connect } from "react-redux";
import { componentChange } from "panel/src/redux/actions/Home";
import { usePrevious } from "panel/src/utils/helper";

interface IRayon {
  hideComponentEdit?: Function;
  editFormClickedOutside?: number;
  setComponentFormData?: Function;
  setImageResizeType?: Function;
  Id?: string;
  formData?: IRayonFormData;
  rawFormData?: any;
  categories: { label: string; value: string }[];
  setInfoPopup?: Function;
  componentChange: Function;
  appId: string;
  index?: Number;
}

type IRayonFormData = {
  Name?: string;
  Categories?: string[];
  SelectedCategories?: string[];
};

const Rayon = ({
  hideComponentEdit,
  editFormClickedOutside,
  setComponentFormData,
  Id,
  formData,
  categories,
  setInfoPopup,
  componentChange,
  appId,
  index,
}: IRayon) => {
  const prevProps = usePrevious({ formData });

  useEffect(() => {
    if (prevProps && formData) {
      if (prevProps.formData !== formData) {
        const componentObj = {
          applicationId: appId,
          componentId: 25,
          formData: JSON.stringify({
            SelectedCategories: formData.SelectedCategories,
            Name: formData.Name,
          }),
          index: index,
        };
        componentChange(componentObj);
      }
    }
  });

  useEffect(() => {
    if (editFormClickedOutside !== 0) {
      hideComponentEdit && hideComponentEdit(Id);
    }
  }, [editFormClickedOutside]);

  const initialSelectedCategories = formData?.Categories
    ? formData?.Categories?.map((s: any) => s.Id)
    : categories?.map((s: any) => s.value);

  return (
    <Formik
      enableReinitialize
      initialValues={Object.assign({}, formData, {
        Name: formData?.Name ? formData.Name : "Categories",
        SelectedCategories: initialSelectedCategories,
      })}
      onSubmit={(values) => {
        setComponentFormData && setComponentFormData(Id, values);
        setInfoPopup && setInfoPopup(true);
      }}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, setFieldValue, submitForm }) => {
        return (
          <Form>
            <Field
              id="Name"
              name="Name"
              style={{ marginBottom: "1rem" }}
              label="Component Name"
              placeholder="Component Name"
              value={values.Name}
              onChangeHandler={handleChange}
              component={Input}
            />
            <Field
              options={categories}
              id="SelectedCategories"
              value={values.SelectedCategories}
              placeholder="Add category"
              name="SelectedCategories"
              onChangeHandler={(values: any) =>
                setFieldValue("SelectedCategories", values.target.value)
              }
              multiple={true}
              component={DropdownMultiple}
              label="Add category"
            />
            <Button
              id="apply-button"
              type="submit"
              style={{ marginTop: "1.5rem" }}
              buttonText="Apply"
              onClick={submitForm}
            />
            <Button
              id="cancel-button"
              type="button"
              style={{ marginTop: "0.5rem" }}
              theme="white"
              buttonText="Cancel"
              onClick={() => hideComponentEdit && hideComponentEdit(Id)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  return {
    appId: state.userInfo.selectedAppId,
    hasChange: state.bannerSettings.changes,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    componentChange: (val: any) => dispatch(componentChange(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rayon);
