import {
  GET_PLATFORM_LIST_START,
  GET_PLATFORM_LIST_SUCCESS,
  GET_PLATFORM_LIST_FAIL,
} from "panel/src/redux/constants/platformList";


export type PlatformListStateTypes = Readonly<{
  error?: boolean;
  loading?: boolean;
}>;

const initialState: PlatformListStateTypes = {
  error: false,
  loading: false,
}


const platformListReducer = ( state = initialState, action: any ) => {
  switch ( action.type ) {
    case GET_PLATFORM_LIST_START:
      return {
        ...state,
      };

    case GET_PLATFORM_LIST_SUCCESS:
      return {
        ...state,
        PlatformAdaptors: action.PlatformAdaptors
      };
    case GET_PLATFORM_LIST_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default platformListReducer;
