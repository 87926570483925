import React from "react";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
  type: string;
}

const ArrowDown = ({ width, height, fillColor }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 17"
  >
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M8 .563c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm.974 3.19H7.026V7.59H3.19v1.947h3.837v3.836h1.947V9.536h3.836V7.589H8.973V3.753z"
    />
  </svg>
);

export default ArrowDown;
