import React, { ChangeEventHandler } from "react";
import Input from "panel/src/_components/common/Input";
import classes from "./PaymentOption.module.scss";
import CheckBox from "panel/src/_components/common/Checkbox";

interface IProps {
  checkBoxChangeHandler?: ChangeEventHandler;
  checked: boolean;
  description: string;
  disableCheckbox: boolean;
  formElements: FormElement[];
  inputChangeHandler?: Function;
  label: string;
  error?: boolean;
}

interface FormElement {
  config: Config;
  id: string;
}

interface Config {
  elementConfig: ElementConfig;
  elementType: string;
  label: string;
  touched: boolean;
  valid: boolean;
  validation: Validation;
  value?: string;
}

interface ElementConfig {
  placeholder: string;
  type: string;
}

interface Validation {
  required: boolean;
}

const paymentOption = (props: IProps) => {
  const inputs = props.formElements.map((formElement: FormElement) => {
    return (
      <Input
        key={formElement.id}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        value={formElement.config.value}
        invalid={false}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changeHandler={(e: any) => props.inputChangeHandler!(e, formElement.id)}
        label={formElement.config.label}
        error={props.error && !formElement.config.value}
        errorMessage={"Cannot be left blank."}
      />
    );
  });

  let details = null;
  if (props.checked) {
    details = (
      <div className={classes.Details}>
        <p className={classes.Description}>{props.description}</p>
        {inputs}
      </div>
    );
  }

  return (
    <div className={classes.PaymentOption}>
      <CheckBox
        label={props.label}
        checked={props.checked}
        changeHandler={props.checkBoxChangeHandler}
        disabled={props.disableCheckbox}
        marginBottom={"20px"}
        labelMarginLeft={"16px"}
      />
      {details}
    </div>
  );
};

export default paymentOption;
