import { CLEAR_DESIGN_ERROR, SET_DESIGN_ERROR } from "../constants/DesignError";

const initialState = {
  error: null,
}

function designErrorReducer(state = initialState, action: any) {
  switch(action.type) {
    case SET_DESIGN_ERROR: {
      return {
        ...state, 
        error: action.payload,
      };
    }
    case CLEAR_DESIGN_ERROR:
    default:
      return state;
  }
}

export default designErrorReducer;