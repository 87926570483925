import { Platform } from "react-native";
import styled from "styled-components/native";
import { dimenHeight, dimenWidth } from "shared-components/src/utils/helper";
import FastImage from "react-native-fast-image-web-support";

export default styled(FastImage)`
  position: relative;
  height: ${Platform.OS === "web" ? "30" : dimenHeight(40)}px;
  width: ${Platform.OS === "web" ? "30" : dimenHeight(40)}px;
  margin-right: ${Platform.OS === "web" ? "10" : dimenWidth(16)}px;
  ${Platform.OS === "web" && `flex-shrink: 0 !important;`}
`;
