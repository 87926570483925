import { put, call, takeLatest, all } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import { IHomeData } from "../../models/Design/home/IGetHomeDataResponse";
import {
    getHomeDataSuccess,
    getHomeDataFail,
} from "panel/src/redux/actions/Home";
import {GET_DESIGN_DATA} from "panel/src/redux/constants";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import notification from "panel/src/utils/notification";
import { getCategories } from "../actions/category";
import {getProductListDataSuccess} from "../actions/productList";
import {getProductDetailDataSuccess} from "../actions/productDetail";
import { clearDesignError, setDesignError } from "../actions/DesignError";

function* getDesignData(payload: {
    requestObj: any;
    type: string;
}) {
    yield put(startAppLoading(GET_DESIGN_DATA));
    yield put(clearDesignError());
    try {
        const request = {
            ApplicationId: payload.requestObj.applicationId,
            SsoUserId: payload.requestObj.userId,
            PlatformId: payload.requestObj.platformId,
        };

        const [homePageData,productListData,productDetailData, colorsData, fontTypeData]: [IHomeData, any, any, any, any] =
            yield all([
                call(axios.post, "/Component/api/Component/GetHomePageData", request),
                call(axios.post, "/Component/api/Component/GetProductPageData", request),
                call(axios.post, "/Component/api/Component/GetProductDetailPageData", request),
                call(axios.post, "/api/Design/GetColors", {
                    ApplicationId: request.ApplicationId,
                }),
                call(axios.post, "/api/Manage/GetFonts", {
                    ApplicationId: request.ApplicationId,
                }),
                put(getCategories()),
            ]);

        const homePage = {
            home: homePageData.data.ResultData,
            colors: colorsData.data.ResultData,
            fonts: fontTypeData.data.ResultData,
        };

        const productListPageData = {
            productList: productListData.data.ResultData,
            colors: colorsData.data.ResultData,
            fonts: fontTypeData.data.ResultData,
        };

        const productDetailPageData = {
            productDetails: productDetailData.data.ResultData,
            colors: colorsData.data.ResultData,
            fonts: fontTypeData.data.ResultData,
        };
        yield all([put(getHomeDataSuccess(homePage)),put(getProductListDataSuccess(productListPageData)),put(getProductDetailDataSuccess(productDetailPageData))])
    } catch (error) {
        notification.error(
            "We are temporarily unable to process transactions at this time. We apologize for the inconvenience.",
            "/manage/config-settings"
        );
        yield put(setDesignError("We are temporarily unable to process transactions at this time. We apologize for the inconvenience."));
        yield put(getHomeDataFail(error.message));
    }
    yield put(endAppLoading(GET_DESIGN_DATA));
}

export default function* getDesignDataSaga() {
    yield takeLatest(GET_DESIGN_DATA, getDesignData);
}
