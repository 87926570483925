import React from 'react';

interface IProps {
    width: string,
    height: string,
    fillColor: string,
    type: string
}

const LiveChat = ({ width, height, fillColor }: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <path fill={fillColor} fillRule="evenodd" d="M15.637 14.973H9.34c-.38.428-.831.903-1.235 1.379-.95 1.069-3.16 1.948-3.493 2.043-.333.095-1.188.048-.665-.546.522-.594 1.045-2.139 1.045-2.852H3.495A2.495 2.495 0 0 1 1 12.502V4.495A2.46 2.46 0 0 1 3.472 2h12.165a2.495 2.495 0 0 1 2.495 2.495v7.984a2.51 2.51 0 0 1-2.495 2.494zm-1.853-7.817c-.666 0-1.212.547-1.212 1.212 0 .665.546 1.188 1.212 1.188.665 0 1.212-.547 1.212-1.188 0-.69-.547-1.212-1.212-1.212zm-4.135.119c-.665 0-1.211.546-1.211 1.212 0 .665.546 1.212 1.211 1.212.666 0 1.212-.547 1.212-1.212 0-.666-.546-1.212-1.212-1.212zm-4.158.142c-.665 0-1.212.547-1.212 1.212 0 .666.547 1.212 1.212 1.212.665 0 1.212-.546 1.212-1.212 0-.689-.547-1.212-1.212-1.212zm14.542-1.259h-.357c.024.095.024.19.024.31v7.603a2.332 2.332 0 0 1-2.328 2.328H10.03c-.476.523-1.284 1.378-1.284 1.378s.262.642 1.07.642h6.439c.237.784.903 1.425 1.33 1.782.476.356 1.307.855 1.996.903.69.024.76-.19.642-.357-.119-.142-.475-.974-.594-1.33-.095-.238-.071-.69-.071-.998h.475a2.332 2.332 0 0 0 2.328-2.329V8.487a2.332 2.332 0 0 0-2.328-2.329z" />
    </svg>

);

export default LiveChat;
