import { TouchableOpacity, Platform, ViewStyle } from "react-native";
import React from "react";
import HeartFill from "../../../icons/HeartFill";
import { dimenHeight, dimenWidth } from "../../../utils/helper";
import Heart from "../../../icons/Heart";

interface IFavoriteButton {
  toggleFavorite: Function;
  isFavorite?: boolean;
  fillColor: string;
  style?: ViewStyle;
}

const FavoriteButton = ({
  toggleFavorite,
  isFavorite = false,
  fillColor,
  style,
}: IFavoriteButton) => {
  return (
    <TouchableOpacity
      onPress={() => toggleFavorite()}
      style={{ marginLeft: 7, ...style }}
    >
      {isFavorite ? (
        <HeartFill
          fillColor={fillColor}
          height={Platform.OS === "web" ? "15" : dimenHeight(20).toString()}
          width={Platform.OS === "web" ? "15" : dimenWidth(20).toString()}
        />
      ) : (
        <Heart
          fillColor={fillColor}
          height={Platform.OS === "web" ? "15" : dimenHeight(20).toString()}
          width={Platform.OS === "web" ? "15" : dimenWidth(20).toString()}
        />
      )}
    </TouchableOpacity>
  );
};

export default FavoriteButton;
