import React from "react";

interface IProps {
  width: string;
  height: string;
  fillColor: string;
  type: string;
  className?: string;
}

const TrashIcon = ({ width, height, fillColor, ...props }: IProps) => (
  <svg
    width={width}
    height={height}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h16v16H0z' />
      <path
        d='M9.833 1.333c.82 0 1.485.73 1.5 1.636v.364H13.5c.276 0 .5.299.5.667 0 .36-.214.653-.481.666h-.186v8.97c0 .56-.434 1.016-.974 1.03H4.333c-.543 0-.986-.447-1-1.003V4.666H2.5c-.276 0-.5-.298-.5-.666 0-.36.214-.653.481-.666h2.852V3c0-.91.657-1.65 1.473-1.666h3.027zm2.5 3.333h-8v8.97h8v-8.97zm-5.666 2c.36 0 .653.229.666.514v4.287c0 .294-.298.533-.666.533-.36 0-.653-.228-.667-.513V7.2c0-.295.298-.533.667-.533zm3.333 0c.36 0 .653.229.666.514v4.287c0 .294-.298.533-.666.533-.36 0-.653-.228-.666-.513V7.2c0-.295.298-.533.666-.533zm-.148-4.221H6.833c-.27 0-.49.237-.5.534v.354h4V3c0-.3-.213-.544-.48-.555z'
        fill='currentColor'
      />
    </g>
  </svg>
);

export default TrashIcon;
