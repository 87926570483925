import styled from "styled-components";
interface Content {
  isHalf: boolean;
}

export default styled.div<Content>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 11px 0 rgba(15, 54, 73, 0.1);
  ${(props) => props.isHalf && "width: 50%;"}
`;
