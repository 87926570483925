import {
  GET_CATEGORIES,
  GET_EDIT_CATEGORIES,
  SAVE_CATEGORIES,
  SET_CATEGORIES,
  SET_EDIT_CATEGORIES,
} from "../constants/category";

export const getCategories = () => ({
  type: GET_CATEGORIES,
});

export const setCategories = (list: any[]) => ({
  type: SET_CATEGORIES,
  payload: list,
});

export const getEditCategories = () => ({
  type: GET_EDIT_CATEGORIES,
});

export const setEditCategories = (list: any[]) => ({
  type: SET_EDIT_CATEGORIES,
  payload: list,
});

export const saveCategories = ({
  categories,
  callback,
}: {
  categories: any[];
  callback?: Function;
}) => ({
  type: SAVE_CATEGORIES,
  payload: {
    categories,
    callback,
  },
});
