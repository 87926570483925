import {
    CREATE_APP_START,
    CREATE_APP_FINISH,
    CREATE_APP_REJECTED,
    DELETE_APP_START,
    DELETE_APP_FINISH,
    DELETE_APP_REJECTED,
    RENAME_APP_START,
    RENAME_APP_FINISH,
    RENAME_APP_REJECTED,
} from "panel/src/redux/constants/apps";

const initialState = {
    app: '',
    deleteAppResult: false,
};

const appsReducer = (state: Object = initialState, action: any) => {
    switch (action.type) {
        case CREATE_APP_START:
            return {
                ...state,
            };

        case CREATE_APP_FINISH:
            return {
                ...state,
                app: action.result
            };
        case CREATE_APP_REJECTED:
            return {
                ...state,
                error: action.error,
            };
        case DELETE_APP_START:
            return {
                ...state,
            };

        case DELETE_APP_FINISH:
            return {
                ...state,
                deleteAppResult: action.result

            };
        case DELETE_APP_REJECTED:
            return {
                ...state,
                error: action.error,
            };
        case RENAME_APP_START:
            return {
                ...state,
            };

        case RENAME_APP_FINISH:
            return {
                ...state,
                app: action.result
            };
        case RENAME_APP_REJECTED:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};

export default appsReducer;
