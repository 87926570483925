import React, { useMemo } from "react";
import { connect, useSelector } from "react-redux";
import Container from "./Container";
import TabItem from "./TabItem";
import TabImage from "./TabImage";
import TabText from "./TabText";
import EditButton from "./EditButton";
import Icon from "panel/src/_components/common/Icon";
import categories from "panel/src/assets/images/emulated-app/bottomTabBar/categories.svg";
import profile from "panel/src/assets/images/emulated-app/bottomTabBar/profile.svg";
import more from "panel/src/assets/images/emulated-app/bottomTabBar/more.svg";
import { filterActiveMenuItems } from "../../../../Sidebar/Sidebar";
import TabNavigationItemV2 from "./TabNavigationItemV2";
import changePngColor from "../../../../../utils/pngColorHelper";

interface ITabNavigation {
  fontFamily?: string;
  selectedApp?: any;
  canEdit?: boolean;
  onClickEditButton?: () => void;
}

const TabNavigation = ({
  fontFamily,
  selectedApp,
  canEdit,
  onClickEditButton
}: ITabNavigation) => {
  const { MenuItems: items } = useSelector((state: any) => state.navigationMenu);
  const menuItems = items?.filter(filterActiveMenuItems)?.slice(0, 5) || [];
  const showDynamicMenu = !!menuItems?.length;
  const finalFontFamily = fontFamily || selectedApp.FontName;

  // Colors

  const { colorList } = useSelector((state: any) => state.userInfo?.selectedApp?.SelectedColor);
  const defaultColor = colorList?.[0]?.colorCode;
  const activeColor = colorList?.[1]?.colorCode;

  const defaultColorFilter = useMemo(() => {
    console.log('calculate');
    return changePngColor(defaultColor);
  }, [colorList]);

  const activeColorFilter = useMemo(() => {
    return changePngColor(activeColor);
  },[colorList]);
  
  const renderDefaultMenu = () => (
    <>
      <TabItem>
        <Icon
          type="home"
          fillColor={
            colorList
              ? colorList[1].colorCode
              : selectedApp.SelectedColor.colorList[1].colorCode
          }
        />
        <TabText
          fontFamily={finalFontFamily}
          activeColor={
            colorList
              ? colorList[1].colorCode
              : selectedApp.SelectedColor.colorList[1].colorCode
          }
          isActive
        >
          Home
        </TabText>
      </TabItem>
      <TabItem>
        <TabImage src={categories} alt="categories" />
        <TabText fontFamily={finalFontFamily}>
          Categories
        </TabText>
      </TabItem>
      <TabItem>
        <TabImage src={profile} alt="profile" />
        <TabText fontFamily={finalFontFamily}>
          Profile
        </TabText>
      </TabItem>
      <TabItem>
        <TabImage src={more} alt="more" />
        <TabText fontFamily={finalFontFamily}>Other</TabText>
      </TabItem>
    </>
  )

  const renderDynamicMenu = () => {
    return menuItems.map((item: any, idx: number) => (
      <TabNavigationItemV2
        key={idx}
        name={item?.Name}
        iconUrl={item?.IconUrl}
        color={idx === 0 ? activeColor : defaultColor}
        iconColorFilter={idx ? defaultColorFilter : activeColorFilter}
        isActive={idx === 0}
        fontFamily={finalFontFamily}
        itemSize={menuItems.length}
      />
    ))
  }

  return (
    <Container itemSize={menuItems?.length}>
      {canEdit && <EditButton onClick={onClickEditButton} />}
      {showDynamicMenu ? renderDynamicMenu() : renderDefaultMenu()}
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    selectedApp: state.userInfo.selectedApp,
  };
};

export default connect(mapStateToProps, null)(TabNavigation);
