import React from "react";
import ToggleSwitch from "panel/src/_components/common/ToggleSwitch";
import classes from "./pushNotificationOption.module.scss";

interface IPushOptionProps {
  checked: boolean;
  label: string;
  description?: string;
  checkBoxChangeHandler?: (e: any) => void;
  onDone?: () => void;
  name?: string;
  type?: any;
  disabled?: boolean;
}

const PushNotificationOption = (props: IPushOptionProps) => {
  let fileUpload = null;
  if (props.checked) {
    //fileUpload = <FileBase64 onDone={props.onDone} />;
  }

  return (
    <div>
      <ToggleSwitch
        label={props.label}
        checked={props.checked}
        name={props.name}
        changeHandler={props.checkBoxChangeHandler}
        labelMarginLeft={"16px"}
        disabled={props.disabled}
        marginBottom={"0px"}
      />
      {/* <p className={classes.Description}>{props.description}</p> */}
      {fileUpload}
    </div>
  );
};

export default PushNotificationOption;
