import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { dimenFont } from 'shared-components/src/utils/helper';
import { colors } from 'shared-components/src/utils/constants';

interface IText {
  primary?: string;
  fontFamily?: any;
}

export default styled.Text<IText>`
  font-size: ${Platform.OS === 'web' ? '9' : dimenFont(12)}px;
  font-family: ${(props) =>
    Platform.OS === 'web' ? props.fontFamily : props.fontFamily!.Bold};
  color: ${colors.white};
`;
