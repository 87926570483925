import React from 'react';

interface IProps {
    width: string,
    height: string,
    fillColor: string,
    type: string
}

const LogOut = ({ width, height, fillColor }: IProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <path fill={fillColor} fillRule="evenodd" d="M16 1v8.067h-2.5V3.444h-10v17.112h10v-5.623H16V23H1V1h15zm2.55 5L24 12l-5.45 6v-4.356H9v-3.288h9.55V6z" />
    </svg>
);

export default LogOut;
