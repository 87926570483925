import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Formik, Form, Field } from "formik";
import Input from "panel/src/screens/Login/Input";
import Button from "panel/src/_components/common/Button";
import { setNewPassword } from "panel/src/redux/actions/SetNewPassword";
import logo from "panel/src/assets/images/mobile-logo.svg";
import infoIcon from "panel/src/assets/images/blue-information.svg";
import errorIcon from "panel/src/assets/images/red-warning.svg";
import newPasswordValidation from "panel/src/screens/NewPassword/validationSchema";
import classes from "./newpassword.module.scss";
import Icon from "panel/src/_components/common/Icon";
import { useQuery } from "../../hooks";
import useIntercomChat from "../../Components/Intercom/useIntercomChat";

interface INewPasswordProps {
  loading: boolean;
  error: string;
  _setNewPassword: Function;
}

const NewPassword = ({
  loading,
  error,
  _setNewPassword,
}: INewPasswordProps) => {
  useIntercomChat({});
  const history = useHistory();
  const [activationCode, setActivationCode] = useState("");
  const [isNewUser, setIsNewUser] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const onSubmitForm = (password: string) => {
    _setNewPassword(activationCode, password, isNewUser);
  };

  const url = useQuery();
  const queryParamForResetPassword = url.get("ac");
  const queryParamForCreatePassword = url.get("acCreatePassword");

  useEffect(() => {
    if (queryParamForResetPassword) {
      setActivationCode(queryParamForResetPassword);
    } else if (queryParamForCreatePassword) {
      setActivationCode(queryParamForCreatePassword);
      setIsNewUser(true);
    } else {
      /*   history.push("login"); */
    }
  }, []);

  return (
    <div className={classes.bg}>
      <div className={classes.mainContainer}>
        <div className={classes.newPasswordCard}>
          <div className={classes.logoWrapper}>
            <img src={logo} className={classes.logo} />
          </div>
          <div className={classes.infoContainer}>
            <img src={infoIcon} className={classes.infoIcon} />
            <span className={classes.infoText}>
              Password must be 8-20 characters Contain at least 1 uppercase, 1
              lowercase 1 number
            </span>
            <ul>
              <li></li>
            </ul>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={{ password: "", rePassword: "" }}
            validationSchema={newPasswordValidation}
            onSubmit={(values) => onSubmitForm(values.password)}
          >
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <div className={classes.inputWrapper}>
                  <span onClick={() => setShowPassword(!showPassword)}>
                    <Icon
                      type={showPassword ? "hideEye" : "showEye"}
                      fillColor="#3196f6"
                      width="24px"
                      height="24px"
                    />
                  </span>
                  <Field
                    id="password"
                    name="password"
                    style={{ marginBottom: 24 }}
                    component={Input}
                    value={values.password}
                    onChangeHandler={handleChange}
                    label="Password"
                    placeholder="Password"
                    type={!showPassword ? "password" : "text"}
                    handleError={errors}
                    handleTouched={touched}
                  />
                </div>
                <div className={classes.inputWrapper}>
                  <span onClick={() => setShowRePassword(!showRePassword)}>
                    <Icon
                      type={showRePassword ? "hideEye" : "showEye"}
                      fillColor="#3196f6"
                      width="24px"
                      height="24px"
                    />
                  </span>
                  <Field
                    id="rePassword"
                    name="rePassword"
                    style={{ marginBottom: 82 }}
                    component={Input}
                    value={values.rePassword}
                    onChangeHandler={handleChange}
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    type={!showRePassword ? "password" : "text"}
                    handleError={errors}
                    handleTouched={touched}
                  />
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    color="Auth"
                    width="256px"
                    height="44px"
                    loadingSpinner={loading}
                    type={loading ? "button" : "submit"}
                    fontSize="14px"
                  >
                    Set New Password
                  </Button>
                </div>
                <div className={classes.backContainer}>
                  <span
                    className={classes.hyperLink}
                    onClick={() => history.replace("login")}
                  >
                    Back to sign in
                  </span>
                </div>
                {error && (
                  <div className={classes.errorContainer}>
                    <img src={errorIcon} className={classes.errorIcon} />
                    <span className={classes.errorText}>{error}</span>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.setNewPassword.loading,
    error: state.setNewPassword.error,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    _setNewPassword: (
      activationCode: string,
      newPassword: string,
      isNewUser: boolean
    ) => dispatch(setNewPassword(activationCode, newPassword, isNewUser)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
