import {
  SET_PLATFORM_INFO,
  SET_APP_START,
  SET_APP_SUCCESS,
  SET_APP_FAIL,
  SET_CONFIG_SETTINGS_COMPLETED,
  SET_ENGAGEMENT_OPTIONS_COMPLETED,
  SET_PAYMENT_OPTIONS_COMPLETED,
  SET_APP_ID,
  SET_APP_SHOPIFY,
  SET_APP_INPUTS,
  SET_APP_GOOGLE_MERCHANT,
  SET_APP_GOOGLE_MERCHANT_SUCCESS,
  SET_APP_GOOGLE_MERCHANT_FAIL,
  CHECK_APP_INFO,
  SET_CHANGE_STH_DESIGN_PAGE,
} from "panel/src/redux/constants/manageApp";
export const setPlatformInfoStart = () => {
  return {
    type: SET_PLATFORM_INFO,
  };
};

export const setAppStart = (
  setPlatformObject: any,
  platformId: any,
  appUrl: any,
  appId: any
) => {
  return {
    type: SET_APP_START,
    setPlatformObject,
    platformId,
    appUrl,
    appId,
  };
};

export const checkAppInfo = (
  setPlatformObject: any,
  platformId: any,
  appUrl: any,
  appId: any
) => {
  return {
    type: CHECK_APP_INFO,
    setPlatformObject,
    platformId,
    appUrl,
    appId,
  };
};

export const setAppInputs = (
  setPlatformObject: any,
  platformId: any,
  appUrl: any,
  appId: any
) => {
  return {
    type: SET_APP_INPUTS,
    setPlatformObject,
    platformId,
    appUrl,
    appId,
  };
};

export const setAppSuccess = (appId: any) => {
  return {
    type: SET_APP_SUCCESS,
    appId,
  };
};

export const setAppFail = (error: any) => {
  return {
    type: SET_APP_FAIL,
    error,
  };
};

export const setAppShopify = (shopifyObj: any) => {
  return {
    type: SET_APP_SHOPIFY,
    shopifyObj,
  };
};
export const setAppGoogleMerchant = (
  googleMerchantObj: Object,
  onSubmitForm: Function
) => {
  return {
    type: SET_APP_GOOGLE_MERCHANT,
    googleMerchantObj,
    onSubmitForm,
  };
};
export const setAppGoogleMerchantSuccess = (googleMerchantStatus: any) => {
  return {
    type: SET_APP_GOOGLE_MERCHANT_SUCCESS,
    googleMerchantStatus,
  };
};
export const setAppGoogleMerchantFail = (error: any) => {
  return {
    type: SET_APP_GOOGLE_MERCHANT_FAIL,
    error,
  };
};

export const setConfigSettingsCompleted = () => {
  return {
    type: SET_CONFIG_SETTINGS_COMPLETED,
  };
};

export const setPaymentOptionsCompleted = () => {
  return {
    type: SET_PAYMENT_OPTIONS_COMPLETED,
  };
};

export const setEngagementOptionsCompleted = () => {
  return {
    type: SET_ENGAGEMENT_OPTIONS_COMPLETED,
  };
};

export const setAppId = (appId: string) => {
  return {
    type: SET_APP_ID,
    appId,
  };
};

export const setChangedSthDesignPage = (changedValue: boolean) => {
  return {
    type: SET_CHANGE_STH_DESIGN_PAGE,
    changedValue,
  };
};
