import React from "react";
import { OS } from "panel/src/Components/DragnDrop/Emulator/ToggleOS";
import AppIconContainer from "./AppIconContainer";
import AppIconPlaceholder from "./AppIconPlaceholder";
import AppIcon from "./AppIcon";
import AppName from "./AppName";

export interface IEmulatorOS {
  appName: string;
  iosIcon: string;
  androidIcon: string;
}

export interface IEmulatorOSProps extends IEmulatorOS {
  activeOS: OS.IOS | OS.Android;
}

const EmulatorOS = ({
  activeOS,
  appName,
  iosIcon,
  androidIcon,
}: IEmulatorOSProps) => {
  const isIconSelectedIOS = () => iosIcon !== null && iosIcon !== "";

  const isIconSelectedAndroid = () =>
    androidIcon !== null && androidIcon !== "";

  return (
    <AppIconContainer>
      {activeOS === OS.IOS && isIconSelectedIOS() ? (
        <AppIcon src={iosIcon} activeOS={activeOS} />
      ) : activeOS === OS.Android && isIconSelectedAndroid() ? (
        <AppIcon src={androidIcon} activeOS={activeOS} />
      ) : (
        <AppIconPlaceholder activeOS={activeOS} />
      )}
      <AppName>{appName}</AppName>
    </AppIconContainer>
  );
};

export default EmulatorOS;
