import styled from "styled-components";
import { OS } from "../../ToggleOS";

interface IContainer {
  currentOS: OS;
}

export default styled.div<IContainer>`
  position: absolute;
  ${(props) =>
    props.currentOS === OS.IOS &&
    "border-radius: 25px 25px 0px 0px; -webkit-border-radius: 25px 25px 0px 0px;"}
  width: 240px;
  height: ${(props) => (props.currentOS === OS.IOS ? "50" : "40")}px;
  z-index: 1;
  pointer-events: none;
`;
