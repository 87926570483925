import React from "react";
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";
import { connect } from "react-redux";
import RefreshPage from "panel/src/_components/primitives/RefreshPage";
import { getToken } from "panel/src/utils/helper";
import Layout from "panel/src/_components/layout";
import TrialExpired from "panel/src/screens/TrialExpired";

interface IProps extends RouteProps {
  isAuthenticated: boolean;
  isUserExpired: boolean;
  userId: string;
  Component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  selectedAppId: string;
}

const ProtectedRoute = ({
  isAuthenticated,
  isUserExpired,
  userId,
  Component,
  selectedAppId,
  ...rest
}: IProps) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          if (userId) {
            return isUserExpired ? (
              <TrialExpired />
            ) : (
              <Layout>
                <RefreshPage />
                <Component {...rest} {...props} />
              </Layout>
            );
          }
        } else {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
};

const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: !!getToken(),
    isUserExpired: state.userInfo.IsExpired,
    userId: state.userInfo.userId,
    selectedAppId: state.userInfo.selectedAppId,
  };
};
export default connect(mapStateToProps)(ProtectedRoute);
