import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import classes from "./mainNavigation.module.scss";
import ManageIcon from "../ManageIcon";
import DesignIcon from "../DesignIcon";
import EngageIcon from "../EngageIcon";
import PublishIcon from "../PublishIcon";
import tick from "../../../assets/images/tick.svg";
import NotificationIcon from "../NotificationIcon";

interface IMainProps {
  location?: any;
  isSideMenuFull: boolean;
  isExistApp: boolean;
  IsStep1Completed: boolean;
  IsStep2Completed: boolean;
  IsStep3Completed: boolean;
  IsStep4Completed: boolean;
  IsStep5Completed: boolean;
  IsStep6Completed: boolean;
  IsStep7Completed: boolean;
  IsStep8Completed: boolean;
  IsStep9Completed: boolean;
  IsStep10Completed: boolean;
  IsStep11Completed: boolean;
  IsStep12Completed: boolean;
  IsStep13Completed: boolean;
  isPublishAgain: boolean;
  history: any;
}

interface IMainState {
  mainNavItems: {
    name: string;
    label: string;
    iconType: string;
    isActive: boolean;
    isShowIcon: boolean;
    isPublishAgain: boolean;
    linkUrl: string;
  }[];
  currentPathName: string;
}

class MainNavigation extends Component<IMainProps, IMainState> {
  state = {
    mainNavItems: [
      {
        name: "manage",
        label: "Connect",
        iconType: "disabled",
        isActive: false,
        isShowIcon: false,
        isPublishAgain: false,
        linkUrl: "/manage/web-service-control/begin",
      },
      {
        name: "design",
        label: "Design",
        iconType: "disabled",
        isActive: false,
        isShowIcon: false,
        isPublishAgain: false,
        linkUrl: "/design",
      },
      {
        name: "publish",
        label: "Publish",
        iconType: "disabled",
        isActive: false,
        isShowIcon: false,
        isPublishAgain: false,
        linkUrl: "/publish",
      },
      {
        name: "line",
        label: "",
        iconType: "disabled",
        isActive: false,
        isShowIcon: false,
        isPublishAgain: false,
        linkUrl: "",
      },
      {
        name: "engage",
        label: "Manage Your App",
        iconType: "disabled",
        isActive: false,
        isShowIcon: false,
        isPublishAgain: false,
        linkUrl: "/engage",
      },
      {
        name: "pushNotification",
        label: "Push Notification",
        iconType: "enabled",
        isActive: false,
        isShowIcon: false,
        isPublishAgain: false,
        linkUrl: "/push-notification",
      },

    ],
    currentPathName: "",
  };

  static getDerivedStateFromProps(props: IMainProps, state: IMainState) {
    const currentPath = props.history.location.pathname;
    const {
      IsStep1Completed,
      IsStep2Completed,
      IsStep3Completed,
      IsStep4Completed,
      IsStep5Completed,
      IsStep6Completed,
      IsStep7Completed,
      IsStep8Completed,
      IsStep9Completed,
      IsStep10Completed,
      IsStep11Completed,
      IsStep12Completed,
      IsStep13Completed,
    } = props;
    const itemIndex = state.mainNavItems.findIndex((item: any) => {
      return props.location.pathname.includes(item.name);
    });
    const newItem = { ...state.mainNavItems[itemIndex] };
    newItem.iconType = "active";

    const newItems = [...state.mainNavItems];
    //Reset styling for all items
    newItems.forEach((item) => {
      item.iconType = "disabled";
      item.isActive = false;
      item.isShowIcon = false;
      item.isPublishAgain = false;
      switch (item.name) {
        case "manage":
          item.isActive = props.isExistApp;
          item.isActive === true
            ? (item.iconType = "enabled")
            : (item.iconType = "disabled");

          item.isShowIcon =
            IsStep1Completed &&
            IsStep2Completed &&
            IsStep3Completed &&
            IsStep4Completed;
          item.linkUrl = IsStep1Completed
            ? "/manage/config-settings"
            : "/manage/web-service-control/begin";
          if (newItem.name === "manage") {
            newItem.isActive = props.isExistApp;
            newItem.isShowIcon =
              IsStep1Completed &&
              IsStep2Completed &&
              IsStep3Completed &&
              IsStep4Completed;
            newItem.linkUrl = IsStep1Completed
              ? "/manage/config-settings"
              : "/manage/web-service-control/begin";
          }
          break;
        case "design":
          item.isActive =
            IsStep1Completed &&
            IsStep2Completed &&
            IsStep3Completed &&
            IsStep4Completed;
          item.isActive === true
            ? (item.iconType = "enabled")
            : (item.iconType = "disabled");

          item.isShowIcon =
            IsStep5Completed &&
            IsStep6Completed &&
            IsStep7Completed &&
            IsStep8Completed &&
            IsStep9Completed &&
            IsStep10Completed &&
            IsStep11Completed &&
            IsStep13Completed;
          if (newItem.name === "design") {
            newItem.isActive =
              IsStep1Completed &&
              IsStep2Completed &&
              IsStep3Completed &&
              IsStep4Completed;
            newItem.isShowIcon =
              IsStep5Completed &&
              IsStep6Completed &&
              IsStep7Completed &&
              IsStep8Completed &&
              IsStep9Completed &&
              IsStep10Completed &&
              IsStep11Completed &&
              IsStep13Completed;
          }
          break;
        case "publish":
          item.isActive = IsStep13Completed && currentPath != "/publish/qr";
          item.isActive === true
            ? (item.iconType = "enabled")
            : (item.iconType = "disabled");

          item.isShowIcon = IsStep12Completed && IsStep13Completed;
          item.isPublishAgain = false;
          if (newItem.name === "publish") {
            newItem.isActive =
              IsStep13Completed && currentPath != "/publish/qr";
            newItem.isShowIcon = IsStep12Completed && IsStep13Completed;
            newItem.isPublishAgain = false;
          }
          break;

        case "engage":
          item.isActive = IsStep13Completed === true;
          item.isActive === true
            ? (item.iconType = "enabled")
            : (item.iconType = "disabled");

          item.isShowIcon = false;
          break;

        case "pushNotification":
          item.isActive = IsStep13Completed;
          item.iconType =
            currentPath === "/push-notification"
              ? "active"
              : item.isActive
              ? "enabled"
              : "disabled";
          item.isShowIcon = false;
          break;
      }
    });
    newItems[itemIndex] = newItem;

    return {
      mainNavItems: newItems,
      currentPathName: props.location.pathname,
    };
  }

  render() {
    const mainNav = this.state.mainNavItems.map((item) => {
      let navIcon = null;
      if (item.name === "manage") {
        navIcon = <ManageIcon isSmall iconType={item.iconType} />;
      } else if (item.name === "design") {
        navIcon = <DesignIcon isSmall iconType={item.iconType} />;
      } else if (item.name === "publish") {
        navIcon = <PublishIcon isSmall iconType={item.iconType} />;
      } else if (item.name === "engage") {
        navIcon = <EngageIcon isSmall iconType={item.iconType} />;
      } else if (item.name === "pushNotification") {
        navIcon = <NotificationIcon isSmall iconType={item.iconType} />;
      } else if (item.name === "line") {
        navIcon = "";
      }

      let navLabel = null;
      const attachedClasses = [classes.Label];
      if (item.iconType === "active") {
        attachedClasses.push(classes.Active);
      }

      navLabel =
        item.name === "line" ? (
            <div className={classes.titleContainer}>
              <p className={classes.Line}/>
              <p className={classes.AppSettingsText}>App Settings</p>
           </div>
        ) : (
          <span className={attachedClasses.join(" ")}>{item.label}</span>
        );
      return (
        <li key={item.name}>
          {item.name !== "line" ? (
            <Link
              className={`${
                !item.isActive ? classes.disableLink : classes.link
              }`}
              to={item.linkUrl}
              id={`${item.name}-link`}
            >
              <div className={classes.navIcon}>
                {item.isPublishAgain && <div className={classes.publish} />}
                {item.isShowIcon && (
                  <img className={classes.tickIcon} src={tick} />
                )}
                {navIcon}
              </div>

              {this.props.isSideMenuFull && navLabel}
            </Link>
          ) : (
            <React.Fragment>
              {navIcon}
              {navLabel}
            </React.Fragment>
          )}
        </li>
      );
    });

    return (
      <nav className={classes.Nav}>
        <ul>{mainNav}</ul>
      </nav>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isExistApp:
      state.userInfo.apps && state.userInfo.apps.length > 0 ? true : false,
    IsStep1Completed: state.userInfo.selectedApp.IsStep1Completed,
    IsStep2Completed: state.userInfo.selectedApp.IsStep2Completed,
    IsStep3Completed: state.userInfo.selectedApp.IsStep3Completed,
    IsStep4Completed: state.userInfo.selectedApp.IsStep4Completed,
    IsStep5Completed: state.userInfo.selectedApp.IsStep5Completed,
    IsStep6Completed: state.userInfo.selectedApp.IsStep6Completed,
    IsStep7Completed: state.userInfo.selectedApp.IsStep7Completed,
    IsStep8Completed: state.userInfo.selectedApp.IsStep8Completed,
    IsStep9Completed: state.userInfo.selectedApp.IsStep9Completed,
    IsStep10Completed: state.userInfo.selectedApp.IsStep10Completed,
    IsStep11Completed: state.userInfo.selectedApp.IsStep11Completed,
    IsStep12Completed: state.userInfo.selectedApp.IsStep12Completed,
    IsStep13Completed: state.userInfo.selectedApp.IsStep13Completed,
  };
};

export default withRouter<any, any>(connect(mapStateToProps)(MainNavigation));
