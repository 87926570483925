import React from "react";
import IconContainer from "./IconContainer";
import Icon from "./Icon";
import Text from "./Text";

import Back from "../../../../assets/images/back.svg";

interface IBackIcon {
  navigateBack: Function;
  backTo: string;
  relative?: boolean;
}

const BackIcon = ({ navigateBack, backTo, relative = false }: IBackIcon) => {
  return (
    <IconContainer onClick={() => navigateBack(backTo)} relative={relative}>
      <Icon src={Back} />
      <Text>Back</Text>
    </IconContainer>
  );
};

export default BackIcon;
