import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
} from "panel/src/redux/constants";

export const forgotPassword = (email: string) => ({
  type: FORGOT_PASSWORD,
  email,
});

export const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS,
});

export const forgotPasswordFail = (payload: any) => ({
  type: FORGOT_PASSWORD_FAIL,
  payload,
});
