import IResendDemoRequest from "../../models/Publish/resendDemo";

import {
  RESET_ASK_DEMO,
  RESEND_DEMO,
  RESEND_DEMO_FAIL,
  RESEND_DEMO_SUCCESS,
} from "panel/src/redux/constants/askDemo";

interface BaseAction {
  type: string;
  data?: Object;
  error?: string;
  requestObj?: IResendDemoRequest;
}

export const resendDemoLink = (requestObj: IResendDemoRequest): BaseAction => ({
  type: RESEND_DEMO,
  requestObj,
});

export const resendDemoLinkSuccess = (data: Object): BaseAction => ({
  type: RESEND_DEMO_SUCCESS,
  data,
});

export const resendDemoLinkFail = (error: string): BaseAction => ({
  type: RESEND_DEMO_FAIL,
  error,
});
