import React from "react";
const ProgressBar = (props:any) => {
    const { completed} = props;
    const bgcolor='#ff8360'
    const containerStyles = {
        height: 6,
        width: '100%',
        backgroundColor: "#fff",
        borderRadius: 50,
        margin:5,
        marginLeft:0
    }

    const fillerStyles = {
        height: "100%",
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: "4px",
    }



    return (
        <div style={containerStyles}>
            <div style={fillerStyles}>
            </div>
        </div>
    );
};

export default ProgressBar;
