import styled from "styled-components";

interface IContainer {
  flexEnd?: boolean;
}

export default styled.div<IContainer>`
  position: relative;
  display: flex;
  justify-content: ${(props) =>
    props?.flexEnd ? "flex-end" : "space-between"};
  padding: 10px 12px 7px 12px;
  box-shadow: 0 10px 15px -4px rgba(15, 54, 73, 0.1);
  z-index: 9;
`;
