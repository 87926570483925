import { Platform } from "react-native";
import styled from "styled-components/native";
import { dimenHeight } from "shared-components/src/utils/helper";

interface IImageWrapper {
  isLast?: boolean;
}

export default styled.TouchableOpacity<IImageWrapper>`
  ${(props) => !props.isLast && "padding-right: 8px;"}
  height: ${Platform.OS === "web" ? "90" : dimenHeight(90)}px;
  flex: 1;
`;
