import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "panel/src/services/api/api";

import * as dataActions from "panel/src/redux/actions/cmsKeys";
import {
  GET_CMS_KEYS_START,
  GET_CMS_KEY_VALUES_START,
  UPDATE_CMS_KEY_VALUES_START,
} from "panel/src/redux/constants/cmsKeys";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";

function* getCmsKeys() {
  yield put(startAppLoading(GET_CMS_KEYS_START));
  try {
    const response = yield call(axios.get, "api/Manage/GetCmsKeys", {});

    yield put(dataActions.getCmsKeysFinish(response.data.ResultData));
  } catch (error) {
    yield put(dataActions.getCmsKeysRejected(error.message));
  }
  yield put(endAppLoading(GET_CMS_KEYS_START));
}

function* getCmsKeyValues(payload: {
  requestObj: {
    ApplicationId: string;
    LanguageId: number;
    LanguageRefId: number;
    PageIndex: string;
    SearchCriteria: string;
  };
  type: string;
}) {
  yield put(startAppLoading(GET_CMS_KEY_VALUES_START));
  try {
    const response = yield call(axios.post, "api/Manage/GetCmsKeyValues", {
      ...payload.requestObj,
    });

    yield put(dataActions.getCmsKeyValuesFinish(response.data.ResultData));
  } catch (error) {
    yield put(dataActions.getCmsKeyValuesRejected(error.message));
  }
  yield put(endAppLoading(GET_CMS_KEY_VALUES_START));
}

function* updateCmsKeyValues(payload: {
  requestObj: {
    ApplicationId: string;
    LanguageId: number;
    KeyValues: { [key: string]: string };
  };
  type: string;
}) {
  yield put(startAppLoading(UPDATE_CMS_KEY_VALUES_START));
  try {
    const response = yield call(axios.post, "api/Manage/UpdateCmsKeyValues", {
      ...payload.requestObj,
    });

    yield put(dataActions.updateCmsKeyValuesFinish(response.data.ResultData));
  } catch (error) {
    yield put(dataActions.updateCmsKeyValuesRejected(error.message));
  }
  yield put(endAppLoading(UPDATE_CMS_KEY_VALUES_START));
}

export function* getCmsKeysSaga() {
  yield takeEvery(GET_CMS_KEYS_START, getCmsKeys);
}

export function* getCmsKeyValuesSaga() {
  yield takeLatest(GET_CMS_KEY_VALUES_START, getCmsKeyValues);
}
export function* updateCmsKeyValuesSaga() {
  yield takeEvery(UPDATE_CMS_KEY_VALUES_START, updateCmsKeyValues);
}
