import { put, call, takeLatest, select, all } from "redux-saga/effects";
import axios from "panel/src/services/api/api";
import IGetProductListDataRequest from "../../models/Design/productList/IGetProductListDataRequest";
import { IProductListData } from "../../models/Design/productList/IGetProductListDataResponse";
import {
  getProductListDataSuccess,
  getProductListDataFail,
  getProductListByCategoryIdSuccess,
  getProductListByCategoryIdFail,
} from "panel/src/redux/actions/productList";
import {
  GET_PRODUCT_LIST_BY_CATEGORY_ID,
  GET_PRODUCT_LIST_DATA,
} from "panel/src/redux/constants/productList";
import { startAppLoading, endAppLoading } from "panel/src/redux/actions/App";
import { ComponentProductModule } from "../../models/Design/home/ComponentProduct";
import { getProductListByCategoryIdService } from "../../services/api/design";
import { AxiosResponse } from "axios";
import { resetHomeData } from "panel/src/redux/actions/productList";
import { resetSaveScreenData } from "panel/src/redux/actions/SaveScreen";
import { getCategories } from "../actions/category";

function* getProductList(payload: {
  requestObj: IGetProductListDataRequest;
  type: string;
}) {
  yield put(startAppLoading(GET_PRODUCT_LIST_DATA));
  try {
    const request = {
      ApplicationId: payload.requestObj.applicationId,
      SsoUserId: payload.requestObj.userId,
      PlatformId: payload.requestObj.platformId,
    };

    yield put(resetHomeData());
    yield put(resetSaveScreenData());

    const [productListDataResponse, colorsData, fontTypeData]: [
      IProductListData,
      any,
      any
    ] = yield all([
      call(axios.post, "/Component/api/Component/GetProductPageData", request),
      call(axios.post, "/api/Design/GetColors", {
        ApplicationId: request.ApplicationId,
      }),
      call(axios.post, "/api/Manage/GetFonts", {
        ApplicationId: request.ApplicationId,
      }),
      put(getCategories()),
    ]);

    const productListPageData = {
      productList: productListDataResponse.data.ResultData,
      colors: colorsData.data.ResultData,
      fonts: fontTypeData.data.ResultData,
    };

    yield put(getProductListDataSuccess(productListPageData));
  } catch (error) {
    yield put(getProductListDataFail(error.message));
  }
  yield put(endAppLoading(GET_PRODUCT_LIST_DATA));
}

function* getProductListByCategoryId(payload: {
  request: ComponentProductModule.TGetProductListByCategoryIdData;
  type: string;
}) {
  const { selectedAppId, userId, selectedApp } = yield select(
    (state) => state.userInfo
  );
  try {
    const {
      data,
    }: AxiosResponse<ComponentProductModule.IGetProductListByCategoryIdResponse> =
      yield call(getProductListByCategoryIdService, {
        ApplicationId: selectedAppId,
        SsoUserId: userId,
        PlatformId: selectedApp.PlatformId,
        CategoryId: payload.request.CategoryId,
        SearchWord: payload.request.SearchWord,
      } as ComponentProductModule.IGetProductListByCategoryIdRequest);

    yield put(getProductListByCategoryIdSuccess(data?.ResultData?.Products));
  } catch (error) {
    yield put(getProductListByCategoryIdFail());
  }
}

export default function* getProductListSaga() {
  yield takeLatest(GET_PRODUCT_LIST_DATA, getProductList);
  yield takeLatest(GET_PRODUCT_LIST_BY_CATEGORY_ID, getProductListByCategoryId);
}
