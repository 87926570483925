import React from "react";

interface IMonitorProps {
  width: string;
  height: string;
  fillColor: string;
  type: string;
}

const Monitor = ({ width, height, fillColor }: IMonitorProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M22.008 0H1.991C.892 0 0 1.033 0 2.302V15.82c0 1.269.893 2.302 1.991 2.302h6.57v1.428c-1.265.159-2.257 1.387-2.257 2.891v.857c0 .387.271.701.606.701h10.18c.334 0 .606-.314.606-.701v-.857c0-1.504-.992-2.732-2.258-2.89v-1.43h6.57c1.1 0 1.992-1.032 1.992-2.301V2.3C24 1.033 23.107 0 22.008 0zm-.846 2.478c.188 0 .341.177.341.395V15.25c0 .218-.153.395-.341.395H2.837c-.189 0-.342-.177-.342-.395V2.873c0-.218.153-.395.342-.395h18.325zm-9.774 1.83a.605.605 0 0 0-.33.14h-.01a.892.892 0 0 0-.285.46 1.05 1.05 0 0 0-.033.111l-1.722 5.622-1.14-4.352c-.12-.469-.516-.726-.885-.573a.78.78 0 0 0-.41.447L5.357 9.246h-.964a.57.57 0 0 0-.066 0c-.388.02-.69.437-.673.93.016.493.343.876.73.856H5.51c.28.135.598.028.79-.265a.804.804 0 0 0 .044-.056v-.014a.972.972 0 0 0 .065-.14l.011-.014v-.014l.033-.07c.009-.027.016-.055.022-.083l.592-1.507 1.229 4.673c.125.467.524.715.89.556.194-.085.35-.272.425-.514l1.755-5.733 1.491 5.691c.122.468.52.721.887.566.195-.082.352-.268.43-.51l1.424-4.547.34 1.144c.024.47.331.837.702.837h1.436c.388.003.704-.395.707-.887.002-.494-.31-.896-.698-.899h-.919l-.888-2.999c-.14-.46-.546-.688-.907-.51a.816.816 0 0 0-.398.496l-1.392 4.436-1.48-5.622V5.02a.99.99 0 0 0-.044-.153.94.94 0 0 0-.242-.39l-.021-.014h-.011l-.022-.014a.656.656 0 0 0-.1-.07.553.553 0 0 0-.197-.056h-.01a.592.592 0 0 0-.066-.014z"
    />
  </svg>
);

export default Monitor;
