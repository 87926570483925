import styled from "styled-components/native";

export default styled.View`
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  padding-top: 16px;
  padding-right: 8px;
  padding-left: 8px;
`;
