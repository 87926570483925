import styled from "styled-components";

export default styled.div`
  height: 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: cover;
`;
